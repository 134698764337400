import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { Instance } from 'pspdfkit'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { UploadedFileToJSON } from 'openapi/models/UploadedFile'
import Services from 'services'
import { useExampleStore } from 'stores/example-store'
import { Maybe } from 'types'
import { TaskType } from 'types/task'
import { Source } from 'types/task'

import { feedbackClosedState } from 'utils/feedback'
import { exportWordWithQuery } from 'utils/markdown'
import { useNavigateWithQueryParams } from 'utils/routing'
import { displayErrorMessage } from 'utils/toast'
import useHarveySocket from 'utils/use-harvey-socket'
import {
  HarveySocketCompletionStatus,
  UPLOAD_ERROR,
  hasCompletedStreaming,
} from 'utils/use-harvey-socket-utils'
import { cn } from 'utils/utils'
import { isUserInputEmpty } from 'utils/utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeader } from 'components/common/app-header'
import { AppHeaderActions } from 'components/common/app-header-actions'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import ExportDialog from 'components/common/export/export-dialog'
import { ExportOptionValues } from 'components/common/export/types'
import { FeedbackResult } from 'components/common/feedback'
import FullscreenLoading from 'components/common/fullscreen-loading'
import { useProductTourStore } from 'components/common/product-tours/product-tour-store'
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
  ImperativeResizablePanelGroupHandle,
} from 'components/ui/resizable'

import AssistantInputPanel from './assistant-input-panel'
import AssistantResponsePanel from './assistant-response-panel'
import {
  ASSISTANT_RESPONSE_PANEL_PERCENT_DEFAULT,
  useAssistantStore,
} from './assistant-store'
import {
  AssistantDocument,
  CORPUS_QA_TASK_TYPE,
  DOC_QA_TASK_TYPE,
  HistoryItem,
  INTERNET_BROWSING_TASK_TYPE,
  MAX_FILES,
  MULTI_DOC_FILE_LIMIT,
  MULTI_DOC_QA_TASK_TYPE,
  OPEN_ENDED_QUERY_LIMIT,
  OPEN_ENDED_TASK_TYPE,
  isQueryValid,
} from './assistant-utils'
import { applyAnnotations } from './pspdfkit-helpers'

function determineTaskType(
  internetBrowsing: boolean,
  uploadedFiles: Maybe<AssistantDocument[]>
): string {
  if (internetBrowsing) {
    return INTERNET_BROWSING_TASK_TYPE
  }

  if (_.isEmpty(uploadedFiles)) {
    return OPEN_ENDED_TASK_TYPE
  }

  const numUploadedFiles = uploadedFiles?.length ?? 0
  if (numUploadedFiles === 1) {
    return DOC_QA_TASK_TYPE
  } else if (numUploadedFiles <= MULTI_DOC_FILE_LIMIT) {
    return MULTI_DOC_QA_TASK_TYPE
  } else if (numUploadedFiles <= MAX_FILES) {
    return CORPUS_QA_TASK_TYPE
  }

  return OPEN_ENDED_TASK_TYPE
}

const Assistant = () => {
  const [
    query,
    queryId,
    uploadedFiles,
    internetBrowsing,
    sources,
    activeDocument,
    selectedSource,
    response,
    isLoading,
    completionStatus,
    setTask,
    setUploadedFiles,
    setQuery,
    setResponse,
    setQueryId,
    setSources,
    setAnnotations,
    setHeaderText,
    setFeedback,
    setProgress,
    setIsLoading,
    setActiveDocument,
    setInternetBrowsing,
    setSelectedSource,
  ] = useAssistantStore(
    useShallow((s) => [
      s.query,
      s.queryId,
      s.uploadedFiles,
      s.internetBrowsing,
      s.sources,
      s.activeDocument,
      s.selectedSource,
      s.response,
      s.isLoading,
      s.completionStatus,
      s.setTask,
      s.setUploadedFiles,
      s.setQuery,
      s.setResponse,
      s.setQueryId,
      s.setSources,
      s.setAnnotations,
      s.setHeaderText,
      s.setFeedback,
      s.setProgress,
      s.setIsLoading,
      s.setActiveDocument,
      s.setInternetBrowsing,
      s.setSelectedSource,
    ])
  )
  const [selectedClientMatter, setClientMatterSelectDisabled] =
    useClientMattersStore(
      useShallow((s) => [
        s.selectedClientMatter,
        s.setClientMatterSelectDisabled,
      ])
    )
  const examples = useExampleStore((s) => s.examples)
  const isProductTourActive = useProductTourStore((s) => s.isTourActive)

  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const { trackEvent } = useAnalytics()

  const { id: historyId } = useParams()
  const [historyLoading, setHistoryLoading] = useState(false)

  const [error, setError] = useState<{
    message: string
    cta: { redirectUri: string; message: string }
  } | null>(null)

  const {
    setQueryViewed,
    recordExport,
    recordQuerySubmitted,
    recordQueryCompletion,
    recordQueryCancel,
    recordQueryError,
    recordReset,
  } = useQueryAnalytics(EventKind.ASSISTANT)

  const pspdfkitInstanceRef = useRef<Instance>(null)
  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle>(null)

  const setFeedbackAndTrack = useCallback(
    (feedbackResult: FeedbackResult) => {
      setFeedback(feedbackResult)
      trackEvent('Feedback Submitted', {
        event_id: String(queryId),
        event_kind: EventKind.ASSISTANT,
        sentiment: feedbackResult.sentiment,
        comments: feedbackResult.comments,
        closed: feedbackResult.closed,
      })
    },
    [queryId, setFeedback, trackEvent]
  )

  const fetchTaskById = useCallback(
    async function (): Promise<void> {
      try {
        setHistoryLoading(true)
        const result = await Services.Backend.Get<HistoryItem>(
          `user/history/${historyId}`
        )
        if (_.isEmpty(result)) {
          setError({
            message: `The requested history item /${historyId} you are trying to access does not exist.\nContact support@harvey.ai if this issue persists.`,
            cta: { redirectUri: '/assistant', message: 'Back to Assistant' },
          })
          return
        }
        setAnnotations(result.annotations ?? {})
        setQuery(result.query)
        setResponse(result.response)
        setQueryId(result.id)
        setInternetBrowsing(result.kind === INTERNET_BROWSING_TASK_TYPE)
        setUploadedFiles(result.documents ?? [])
        setSources(result.sources ?? [])
        setFeedbackAndTrack(feedbackClosedState)
        setProgress(0)
        setActiveDocument(null)
        setQueryViewed()
      } catch (e) {
        Sentry.captureException(e)
        Services.HoneyComb.RecordError(e)
      } finally {
        setHistoryLoading(false)
      }
    },
    [
      historyId,
      setActiveDocument,
      setAnnotations,
      setFeedbackAndTrack,
      setInternetBrowsing,
      setProgress,
      setQuery,
      setQueryId,
      setQueryViewed,
      setResponse,
      setSources,
      setUploadedFiles,
    ]
  )

  useEffect(() => {
    if (historyId === String(queryId)) return

    if (!_.isNil(historyId)) {
      const hasExample = examples.some((e) => e.id === parseInt(historyId))

      if (
        userInfo.IsHistoryUser ||
        (userInfo.IsExamplesReadUser && (_.isEmpty(examples) || hasExample)) ||
        userInfo.IsLibraryUser
      ) {
        void fetchTaskById()
        return
      }
      navigate(`/assistant`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyId])

  useEffect(() => {
    if (
      userInfo.IsHistoryUser &&
      queryId &&
      hasCompletedStreaming(completionStatus)
    ) {
      navigate(`/assistant/${queryId}`, { replace: true })
    }
  }, [
    queryId,
    completionStatus,
    navigate,
    userInfo,
    internetBrowsing,
    uploadedFiles,
  ])

  const route =
    _.isEmpty(uploadedFiles) || internetBrowsing ? 'completion' : 'document_qa'

  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: route,
    setter: setTask,
    endCallback: () => {
      setClientMatterSelectDisabled(false)
    },
  })

  const onAskHarvey = async function (prompt?: string): Promise<void> {
    const documents: AssistantDocument[] = []
    setIsLoading(true)
    setHeaderText('Setting up task')
    setFeedback(null)
    setProgress(0)
    setResponse('')
    setSources([])
    if (!_.isEmpty(uploadedFiles) && !internetBrowsing) {
      setHeaderText('Uploading documents')
      const batches = _.chunk(uploadedFiles, 10)
      for (const batch of batches) {
        const batchResults = await Promise.all(
          batch.map(async (doc) => {
            if (!doc.uploadPromise) return doc
            return await doc.uploadPromise
          })
        )
        documents.push(...batchResults)
      }
      if (
        documents.some((doc) => {
          return _.isEmpty(doc.path) && _.isEmpty(doc.pdfkitId) // this is returned empty on retry click history fetch on ask harvey
        })
      ) {
        displayErrorMessage(UPLOAD_ERROR)
        Services.HoneyComb.RecordError(UPLOAD_ERROR)
        Sentry.captureException(UPLOAD_ERROR)
        setHeaderText('')
        setIsLoading(false)
        return
      }
    }
    const task_type = determineTaskType(internetBrowsing, documents)
    const client_matter_id = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null

    setClientMatterSelectDisabled(true)

    const initialRecordFields = {
      event_kind: determineTaskType(internetBrowsing, uploadedFiles),
      query_length: prompt?.length ?? query.length,
      num_documents: uploadedFiles?.length ?? 0,
    }

    initSocketAndSendQuery({
      query: prompt || query,
      additionalAuthParams: { task_type, client_matter_id },
      additionalRequestParams: {
        // adding source_event_id if we have a query id
        // https://www.notion.so/harveyai/Make-sure-we-keep-honoring-event_source_id-04edcaaae0d8440a9b51808aef7af349?pvs=4
        source_event_id: queryId,
        task_type,
        documents: documents.map(UploadedFileToJSON),
      },
      recordFields: initialRecordFields,
      recordQuerySubmitted,
      recordQueryCompletion,
      recordQueryCancel,
      recordQueryError,
    })
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useMount(async () => {
    // Automatically run a query for a prompt provided in query params
    const prompt = searchParams.get('prompt')

    if (prompt === null) return

    // setTimeout is needed in development due to StrictMode. The second call to useMount interrupts the first call's
    // webSocket connection, and the first setQuery(prompt) call is cleared out by the second component mount.
    timeoutRef.current = setTimeout(async () => {
      searchParams.delete('prompt')
      setSearchParams(searchParams)

      if (!isQueryValid(prompt, OPEN_ENDED_QUERY_LIMIT)) {
        setQuery(prompt)
        return
      }

      await onAskHarvey(prompt)
    }, 100)
  })

  useUnmount(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  })

  const resetState = (withNavigate: boolean) => {
    setQuery('')
    setResponse('')
    setIsLoading(false)
    setQueryId(null)
    setActiveDocument(null)
    setProgress(0)
    setInternetBrowsing(false)
    setUploadedFiles([])
    setAnnotations({})
    setSources([])
    setSelectedSource(null)
    setFeedback(null)
    setHeaderText('')
    sendCancelRequest()
    if (withNavigate) {
      navigate('/assistant')
    }
  }

  useUnmount(() => {
    resetState(false)
  })

  const showHeaderActions =
    !_.isEmpty(response) && !_.isNil(queryId) && !isLoading

  const emptyUserInput = isUserInputEmpty([query, uploadedFiles])
  const saveExampleDisabled =
    !showHeaderActions ||
    completionStatus === HarveySocketCompletionStatus.Cancelled
  const resetDisabled = emptyUserInput || isLoading

  const handleExportInner = async (
    sourcesArg: Source[],
    includeAnnotation: boolean
  ) => {
    await exportWordWithQuery({
      query,
      response,
      sources: sourcesArg,
      files: uploadedFiles?.map((x) => x.name),
      taskType: TaskType.ASSISTANT,
      includeAnnotation,
      queryId: String(queryId),
    })
    recordExport(String(queryId), 'word', includeAnnotation)
  }

  const handleExport = async (exportValues: ExportOptionValues) => {
    if (exportValues.includeAnnotation) {
      await handleExportInner(sources, true)
    } else {
      await handleExportInner([], false)
    }
  }

  const sourcesExist = !_.isNil(sources) && !_.isEmpty(sources)

  const handleReset = () => {
    resetState(true)
    recordReset()
  }

  const defaultResizablePanelSizes = [
    100 - ASSISTANT_RESPONSE_PANEL_PERCENT_DEFAULT,
    ASSISTANT_RESPONSE_PANEL_PERCENT_DEFAULT,
  ]
  const defaultResizablePanelMinSize = 25

  const [setAssistantResponsePanelPercent, resetAssistantResponsePanelPercent] =
    useAssistantStore(
      useShallow((s) => [
        s.setAssistantResponsePanelPercent,
        s.resetAssistantResponsePanelPercent,
      ])
    )

  const handleResize = (percent: number) => {
    setAssistantResponsePanelPercent(percent)
  }

  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
    resetAssistantResponsePanelPercent()
  }

  if (error) {
    return (
      <ErrorPage
        title={ErrorPageTitle.PAGE_NOT_FOUND}
        description={error.message}
        primaryCTA={{
          text: error.cta.message,
          onClick: () => {
            setError(null)
            navigate(error.cta.redirectUri)
          },
        }}
      />
    )
  }

  if (!userInfo.isDocumentUser && !userInfo.IsBaseUser) return null

  return (
    <AppMain id="assistant-container" className="flex h-full flex-row">
      <AppHeader
        title="Assistant"
        subtitle="Ask complex questions to your professional AI assistant"
        actions={
          <div className="flex grow justify-end space-x-2">
            <AppHeaderActions
              handleReset={handleReset}
              resetDisabled={resetDisabled}
              saveExample={{
                show: userInfo.canSeeSaveExample,
                disabled: saveExampleDisabled,
                params: {
                  eventId: String(queryId),
                },
              }}
            />
            <ExportDialog
              hasSources={sourcesExist}
              onExport={handleExport}
              disabled={!showHeaderActions}
            />
          </div>
        }
      />
      <FullscreenLoading isLoading={historyLoading} />

      <ResizablePanelGroup
        direction="horizontal"
        autoSaveId="assistant"
        ref={resizablePanelGroupRef}
      >
        <ResizablePanel
          defaultSize={defaultResizablePanelSizes[0]}
          minSize={defaultResizablePanelMinSize}
        >
          <AssistantInputPanel
            onAskHarvey={onAskHarvey}
            pspdfkitInstanceRef={pspdfkitInstanceRef}
            applyAnnotations={async () =>
              await applyAnnotations(
                activeDocument,
                sources,
                pspdfkitInstanceRef.current,
                selectedSource
              )
            }
          />
        </ResizablePanel>
        <ResizableHandle
          withHandle
          disabled={isProductTourActive}
          onDoubleClick={resetLayout}
          className={cn({ hidden: historyLoading })}
        />
        <ResizablePanel
          defaultSize={defaultResizablePanelSizes[1]}
          minSize={defaultResizablePanelMinSize}
          onResize={handleResize}
        >
          <AssistantResponsePanel
            onCancel={sendCancelRequest}
            pspdfInstanceRef={pspdfkitInstanceRef}
          />
        </ResizablePanel>
      </ResizablePanelGroup>
    </AppMain>
  )
}

export default Assistant
