import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import _ from 'lodash'
import { ArrowLeft } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { DiligenceSectionFromJSON } from 'openapi/models/DiligenceSection'
import { WorkflowType } from 'types/workflows'

import { DOCUMENTS_UPLOADING_HELP_TEXT } from 'utils/tooltip-texts'
import useHarveySocket from 'utils/use-harvey-socket'

import { AppHeaderActions } from 'components/common/app-header-actions'
import AskHarveyButton from 'components/common/ask-harvey-button'
import ExportDialog from 'components/common/export/export-dialog'
import {
  CHECKBOX_ALL_VALUE,
  ExportOptionGroup,
  ExportOptionValues,
} from 'components/common/export/types'
import LoadingScreen from 'components/common/loading-screen'
import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'
import * as config from 'components/workflows/workflow/discovery/config'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import { handleExport } from 'components/workflows/workflow/discovery/export'
import { DiligenceDocumentsPageInner } from 'components/workflows/workflow/discovery/flow/add-documents/documents-page-inner'
import { sectionsToTaxonomy } from 'components/workflows/workflow/discovery/util'
import {
  taxonomiesWithTasks,
  taxonomyToSections,
} from 'components/workflows/workflow/discovery/util'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import { ReportsBox } from './report'
import { SelectDetailsForm } from './select-details'

export const DiligenceFlow = () => {
  const [
    availableSections,
    documents,
    reset,
    sections,
    selectedTaxonomies,
    setDocuments,
    setSections,
    setSelectedTaxonomies,
    setViewingDocument,
    followUpQAPairs,
  ] = useDiligenceStore(
    useShallow((s) => [
      s.availableSections,
      s.documents,
      s.reset,
      s.sections,
      s.selectedTaxonomies,
      s.setDocuments,
      s.setSections,
      s.setSelectedTaxonomies,
      s.setViewingDocument,
      s.followUpQAPairs,
    ])
  )
  // Actions for running the report
  const [setter, runProcessSections, runSectionsCompletedCallback] =
    useDiligenceStore(
      useShallow((s) => [
        s.setter,
        s.runProcessSections,
        s.runSectionsCompletedCallback,
      ])
    )

  const { id: historyId } = useParams<{ id: string }>()
  const { historyItem } = useHistoryItemQuery({ id: historyId ?? null })

  const isAnySectionLoading = sections.some((section) => section.isLoading)

  const sectionsWithAnswers = sections.filter((section) =>
    section.tasks.some((task) => task.answer)
  )
  const isAnySectionHaveAnswers = sectionsWithAnswers.length > 0

  const { initSocketAndSendQuery } = useHarveySocket({
    path: config.ROUTE,
    setter,
    endCallback: runSectionsCompletedCallback,
    closeOnUnmount: false,
  })

  const selectedTaxonomiesWithTasks = taxonomiesWithTasks(selectedTaxonomies)

  const runReport = () => {
    if (!selectedTaxonomiesWithTasks.length) return

    // Reset sections and answers when re-running report
    setSections(availableSections)
    setViewingDocument(null)
    const selectedSections = taxonomyToSections(
      selectedTaxonomiesWithTasks,
      availableSections
    )
    runProcessSections(selectedSections, initSocketAndSendQuery)
    setShowReport(true)
  }

  const [showReport, setShowReport] = useState(false)
  const [isReportInProgress, setIsReportInProgress] = useState(false)

  const handleSubmit = () => {
    if (isReportInProgress) {
      setShowReport(true)
    } else {
      runReport()
      setIsReportInProgress(true)
    }
  }

  // When this component initially loads, it displays the report selection screen. We want to hide this if the component is accessed with a historyId, so show
  // a loading screen before displaying either the report selection screen or the report itself.
  const [showLoading, setShowLoading] = useState(true)

  useEffect(() => {
    if (_.isNil(historyItem)) {
      return
    }

    const metadata = historyItem.metadata as {
      processedSections: { [key: string]: string }
    }

    const processedSections = Object.values(metadata.processedSections)
      .map((str) => {
        const json = JSON.parse(str)
        return DiligenceSectionFromJSON(json)
      })
      .sort(
        (a, b) =>
          availableSections.findIndex((s) => s.title === a.title) -
          availableSections.findIndex((s) => s.title === b.title)
      )

    const diligenceDocuments = historyItem.documents?.map((doc) => {
      return {
        file: doc,
        isLoading: false,
      }
    })

    setSections(processedSections)
    setSelectedTaxonomies(sectionsToTaxonomy(processedSections))
    setDocuments(diligenceDocuments ?? [])
    setShowLoading(false)
  }, [
    availableSections,
    historyItem,
    setDocuments,
    setSections,
    setSelectedTaxonomies,
  ])

  useMount(() => {
    if (historyId) {
      setShowReport(true)
    } else {
      setShowLoading(false)
    }
  })

  const tooltip = documents.some((doc) => doc.isLoading)
    ? DOCUMENTS_UPLOADING_HELP_TEXT
    : documents.length === 0
    ? 'No documents uploaded'
    : selectedTaxonomiesWithTasks.length === 0
    ? 'No details selected'
    : undefined

  const getIsExportDisabled = (exportValues: ExportOptionValues) => {
    const sectionsValue = exportValues.sections
    const selectedSections = Array.isArray(sectionsValue) ? sectionsValue : []
    return selectedSections.length === 0
  }

  const onExport = async (exportValues: ExportOptionValues) => {
    const sectionsValue = exportValues.sections
    const selectedSections = Array.isArray(sectionsValue) ? sectionsValue : []
    const includeFollowUps = Array.isArray(exportValues.includeFollowUps)
      ? exportValues.includeFollowUps.includes('includeFollowUps')
      : false

    if (selectedSections.length) {
      const sectionsToExport = sections.filter((section) =>
        selectedSections.includes(section.title)
      )
      void handleExport({
        sections: sectionsToExport,
        documents,
        followUpQAPairs: includeFollowUps ? followUpQAPairs : [],
      })
    }
  }

  const exportOptions: ExportOptionGroup[] = [
    {
      id: 'sections',
      name: 'Choose sections to include in export',
      options: [
        {
          label: 'All sections',
          value: CHECKBOX_ALL_VALUE,
        },
        ...selectedTaxonomiesWithTasks.map((taxonomy) => ({
          label: taxonomy.title,
          value: taxonomy.title,
        })),
      ],
      type: 'checkbox',
      defaultValue: selectedTaxonomiesWithTasks.map(
        (taxonomy) => taxonomy.title
      ),
    },
    // TODO(Adam): Special casing follow ups for now, maybe do something different later
    {
      id: 'includeFollowUps',
      name: 'Include follow-up Q&As',
      options: [
        {
          label: 'Include follow-up questions and answers',
          value: 'includeFollowUps',
        },
      ],
      type: 'checkbox',
      defaultValue: ['includeFollowUps'],
    },
  ]

  const numberOfSelectedSubsections = selectedTaxonomiesWithTasks.reduce(
    (acc, curr) => acc + curr.rows.length,
    0
  )

  const handleReset = () => {
    setShowReport(false)
    setIsReportInProgress(false)
    reset()
  }

  const handleBack = () => {
    setShowReport(false)
  }

  const handleSelectionChanged = () => {
    setViewingDocument(null)
    setIsReportInProgress(false)
  }

  const isResetDisabled =
    selectedTaxonomiesWithTasks.length === 0 && documents.length === 0

  const buttonTitle = isReportInProgress
    ? 'Return to Report'
    : `Run Selected (${numberOfSelectedSubsections})`

  const showLoadingSpinner = showReport && isAnySectionLoading

  if (showLoading) {
    return <LoadingScreen />
  }

  return (
    <WorkflowLayout
      workflowType={WorkflowType.DILIGENCE}
      title="Discovery"
      childClassName="space-y-4 h-full"
      appHeaderActions={
        <>
          {showLoadingSpinner && (
            <div className="flex items-center pr-4">
              <Spinner className="h-3 w-3" />
              <p className="grow text-xs">Loading…</p>
            </div>
          )}
          {showReport && !historyItem && (
            <Button variant="outline" onClick={handleBack}>
              <ArrowLeft className="mr-1 h-4 w-4" />
              Back
            </Button>
          )}
          <AppHeaderActions
            handleReset={handleReset}
            resetDisabled={isResetDisabled}
            showReset={!historyItem}
          />
          {showReport && (
            <ExportDialog
              onExport={onExport}
              optionGroups={exportOptions}
              disabled={!isAnySectionHaveAnswers || isAnySectionLoading}
              getIsSubmitDisabled={getIsExportDisabled}
            />
          )}
        </>
      }
      renderChildrenDirectly={showReport}
    >
      {showReport ? (
        <ReportsBox />
      ) : (
        <>
          <div className="flex h-full flex-col overflow-auto lg:flex-row lg:space-x-2">
            <div className="h-1/2 lg:h-full lg:w-1/3">
              <DiligenceDocumentsPageInner
                handleDocumentsChanged={handleSelectionChanged}
              />
            </div>
            <div className="mt-4 overflow-y-scroll rounded-lg border p-4 lg:mt-0 lg:w-2/3">
              <SelectDetailsForm
                handleSelectionChanged={handleSelectionChanged}
              />
            </div>
          </div>
          <div className="mt-4 flex flex-col gap-3 rounded-lg bg-muted px-4 py-3 lg:flex-row lg:items-center lg:justify-between">
            <p className="font-semibold">
              Harvey will run the Discovery report based on the selected fields
            </p>
            <div className="flex items-center">
              <AskHarveyButton
                buttonTitle={buttonTitle}
                disabled={!!tooltip}
                handleSubmit={handleSubmit}
                tooltip={tooltip}
              />
            </div>
          </div>
        </>
      )}
    </WorkflowLayout>
  )
}
