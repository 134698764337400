import React from 'react'

import { HrvyInfoElement } from 'utils/source'
import { cn } from 'utils/utils'

export interface CitationProps {
  className?: string
  children: React.ReactNode
  href?: string
  isSelected?: boolean
  onClick?: (e: React.MouseEvent) => void
  citationRef?: (el: HrvyInfoElement) => void
}

const Citation = ({
  className,
  children,
  href,
  isSelected,
  onClick,
  citationRef,
  ...props
}: CitationProps) => {
  const cx = cn(
    'source inline-flex min-w-3.5 h-3.5 box-border px-0.5 shrink-0 items-center justify-center rounded border-[0.5px] bg-muted text-2xs text-muted font-medium focus:outline-none focus-visible:ring-1 focus-visible:ring-ring',
    className,
    {
      'cursor-pointer transition hover:bg-border': href || onClick,
      'not-italic no-underline': href,
      'border-background-interactive bg-interactive hover:bg-interactive text-primary-inverse':
        isSelected,
    },
    '[del_&]:bg-destructive [del_&]:border-destructive-foreground/50 [del_&]:text-destructive/50 [del_&]:hover:bg-red-50'
  )

  if (href) {
    return (
      <a
        href={href}
        className={cx}
        onClick={onClick}
        target="_blank"
        rel="noreferrer"
        ref={citationRef}
        {...props}
      >
        {children}
      </a>
    )
  }
  if (onClick) {
    return (
      <button className={cx} onClick={onClick} ref={citationRef} {...props}>
        {children}
      </button>
    )
  }

  return (
    <span className={cx} ref={citationRef} {...props}>
      {children}
    </span>
  )
}

export default Citation
