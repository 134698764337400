import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Trash } from 'lucide-react'
import pluralize from 'pluralize'

import { getTableDateString } from 'utils/date-utils'
import { useNavigateWithQueryParams } from 'utils/routing'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'

import { ApiKey } from './workspace-details-api'

interface WorkspaceDetailsApiTableProps {
  apiKeys: ApiKey[]
  onDeleteApiKey: (apiKeyId: string) => void
}

const WorkspaceDetailsApiTable = ({
  apiKeys,
  onDeleteApiKey,
}: WorkspaceDetailsApiTableProps) => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()

  const onApiCellClick = (email: string) => {
    navigate(`/settings/internal_admin/user-inspector`, {
      state: { email },
    })
  }

  const columns: ColumnDef<ApiKey>[] = [
    {
      accessorKey: 'id',
    },
    {
      accessorKey: 'userId',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Service User" />
      },
      cell: ({ getValue, row }) => (
        <div
          className="ml-3 text-sm"
          onClick={() => onApiCellClick(row.getValue('userId'))}
          onKeyDown={() => row.getValue('userEmail')}
          tabIndex={0}
          role="button"
        >
          {String(getValue())}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'token',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Token" />
      },
      cell: ({ getValue, row }) => (
        <div
          className="ml-3 text-sm"
          onClick={() => onApiCellClick(row.getValue('userId'))}
          onKeyDown={() => row.getValue('userEmail')}
          tabIndex={0}
          role="button"
        >
          {String(getValue())}
        </div>
      ),
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Created" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'actions',
      header: '',
      size: 1,
      cell: ({ row }) => {
        if (!userInfo?.IsInternalAdminWriter) return null
        return (
          <div className="float-right flex justify-end">
            <Button
              className="flex items-center justify-center rounded hover:bg-neutral-200"
              variant="ghost"
              size="xsIcon"
              tooltip={`Remove ${row.getValue('userId')}`}
              tooltipSide="left"
              onClick={() => onDeleteApiKey(row.getValue('id'))}
            >
              <Trash className="h-4 w-4 stroke-destructive" />
            </Button>
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [filter, setFilter] = useState<string>('')

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: apiKeys,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        id: false,
      },
    },
    onPaginationChange: setTablePaginationState,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'key',
    table.getFilteredRowModel().rows.length
  )}`

  return (
    <div>
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
      />
      <div className="mt-2">
        <DataTable
          table={table}
          className="min-h-[420px]"
          emptyStateText="No API keys found"
        />
      </div>
      <DataTableFooter table={table}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
    </div>
  )
}

export default WorkspaceDetailsApiTable
