import React, { useState, useEffect } from 'react'

import { LucideIcon, AlignLeft, Book } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { UserInfo } from 'models/user-info'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'

import {
  createLibraryItemStore,
  useExampleItemsStore,
  usePromptItemsStore,
} from './library-items-store'
import { useLibraryMetadataStore } from './library-metadata-store'
import LibraryPreview from './library-preview'
import { LibraryItem, LibraryItemKind } from './library-types'
import { sortByStarred } from './library.helpers'
import { useLibraryDataProvider } from './use-library-data-provider'

interface LibraryItemKindDetails {
  title: string
  path: string
  action: string
  icon: LucideIcon
  description: (userInfo: UserInfo) => React.ReactNode
}

const LibraryItemKindToDetails: Record<
  LibraryItemKind,
  LibraryItemKindDetails
> = {
  [LibraryItemKind.PROMPT]: {
    title: 'Prompts',
    path: `/library/prompts`,
    action: 'View all prompts',
    icon: AlignLeft,
    description: (userInfo: UserInfo) => (
      <p className="text-xs">
        A prompt is the question that you ask Harvey. Prompts help you easily
        perform common tasks. You can view prompts created by your admin and by
        Harvey
        {userInfo.IsLibraryPrivatePromptUser &&
          ', or save your own personal prompts'}
        .
      </p>
    ),
  },
  [LibraryItemKind.EXAMPLE]: {
    title: 'Examples',
    path: `/library/examples`,
    action: 'View all examples',
    icon: Book,
    description: () => (
      <p className="text-xs">
        An example is a completed Harvey query, including the prompt, document,
        and response. Examples show what Harvey can accomplish. You can view
        examples created by your admin and by Harvey.
      </p>
    ),
  },
}

const Library: React.FC = () => {
  const userInfo = useAuthUser()
  return (
    <AppMain
      id="library-available-items"
      data-testid="library-available-items"
      hasContainer
      containerClassName="space-y-12 flex flex-col"
    >
      <AppHeader title="Library" subtitle="Browse prompts and examples" />

      <div className="flex flex-wrap gap-4">
        {[LibraryItemKind.PROMPT, LibraryItemKind.EXAMPLE].map(
          (itemKind: LibraryItemKind) => {
            const details = LibraryItemKindToDetails[itemKind]
            return (
              <Link
                key={itemKind}
                className="group block w-1/2 grow basis-96 rounded-lg"
                id={`library-${itemKind.toLowerCase()}-card`}
                to={details.path}
              >
                <Card className="h-full shadow-sm group-hover:border-input-focused">
                  <CardHeader>
                    <Icon icon={details.icon} size="largeWithBackground" />
                  </CardHeader>
                  <CardContent className="mt-4">
                    {details.title}
                    <div className="mt-1 space-y-3">
                      {details.description(userInfo)}
                    </div>
                  </CardContent>
                </Card>
              </Link>
            )
          }
        )}
      </div>
      <LibrarySection
        useItemStore={usePromptItemsStore}
        itemKind={LibraryItemKind.PROMPT}
      />
      <LibrarySection
        useItemStore={useExampleItemsStore}
        itemKind={LibraryItemKind.EXAMPLE}
      />
    </AppMain>
  )
}

export default Library

const LibrarySection = <T extends LibraryItem>({
  useItemStore,
  itemKind,
}: {
  useItemStore: ReturnType<typeof createLibraryItemStore<T>>
  itemKind: LibraryItemKind
}) => {
  const [
    isLoading,
    items,
    lastUpdatedTime,
    setIsLoading,
    setItems,
    updateLastUpdatedTime,
  ] = useItemStore(
    useShallow((s) => [
      s.isLoading,
      s.items,
      s.lastUpdatedTime,
      s.setIsLoading,
      s.setItems,
      s.updateLastUpdatedTime,
    ])
  )
  const getFavoriteStatus = useLibraryMetadataStore((s) => s.getFavoriteStatus)
  useLibraryDataProvider({
    setIsLoading,
    setItems,
    updateLastUpdatedTime,
    type: itemKind,
  })

  const [isInitialLoading, setInitialLoading] = useState(true)
  useEffect(() => {
    setInitialLoading((prevLoading) => {
      if (!isLoading && lastUpdatedTime) return false
      return prevLoading
    })
  }, [isLoading, lastUpdatedTime])

  const defaultItems = Object.values(items).filter(
    (item) => !!getFavoriteStatus(item)
  )
  const sortedItems = defaultItems.sort((a, b) => {
    return sortByStarred(a, b, getFavoriteStatus)
  })

  const details = LibraryItemKindToDetails[itemKind]
  const itemNoun = details.title.toLowerCase()

  return (
    <div>
      <div className="border-b pb-4">
        <h4 className="text-sm font-semibold">Starred {itemNoun}</h4>
      </div>
      <LibraryPreview items={sortedItems} isLoading={isInitialLoading} />
      {!isInitialLoading && !sortedItems.length && (
        <div className="flex flex-col items-center space-y-2 py-10">
          <Icon icon={details.icon} size="large" />
          <div className="text-sm text-muted">
            Your starred {itemNoun} will appear here
          </div>
        </div>
      )}
    </div>
  )
}
