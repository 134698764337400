import React, { useState } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'

import { TranscriptType } from 'openapi/models/TranscriptType'

import { Avatar, AvatarFallback } from 'components/ui/avatar'
import Link from 'components/ui/link/link'
import PaginationButtons from 'components/workflows/workflow/transcripts/common/pagination-buttons'
import { getTranscriptsWitnessViewPage } from 'components/workflows/workflow/transcripts/pages'
import { Witness } from 'components/workflows/workflow/transcripts/util/witness-util'

type Props = {
  witnesses: Witness[]
  type: TranscriptType
}

export const WitnessCards: React.FC<Props> = ({ type, witnesses }) => {
  const [paginationState, setPaginationState] = useState({
    pageIndex: 0,
    pageSize: 12,
  })

  const witnessesPaginated = React.useMemo(() => {
    const start = paginationState.pageIndex * paginationState.pageSize
    const end = start + paginationState.pageSize
    return witnesses.slice(start, end)
  }, [witnesses, paginationState])

  const hasNextPage = () => {
    return (
      (paginationState.pageIndex + 1) * paginationState.pageSize <
      witnesses.length
    )
  }

  const handlePageChange = (newPageIndex: number) => {
    setPaginationState((prev) => ({
      ...prev,
      pageIndex: newPageIndex,
    }))
  }

  const generateWitnessClickLink = (witnessName: string) =>
    getTranscriptsWitnessViewPage(type, witnessName)

  const getInitialsFromName = (name: string) => {
    const [first, last] = name.split(' ')
    return `${first[0]}${last ? last[0] : ''}`
  }

  return (
    <div className="">
      <p className="mb-2 pl-1 text-xl">Explore by witness</p>
      <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-5">
        {witnessesPaginated.map((w, i) => (
          <Link key={i} to={generateWitnessClickLink(w.name)}>
            <div className="flex items-center space-x-2 rounded-lg px-4 py-3 transition hover:bg-neutral-50">
              <Avatar>
                <AvatarFallback>{getInitialsFromName(w.name)}</AvatarFallback>
              </Avatar>

              <div>
                <p className="font-semibold">
                  {_.startCase(_.toLower(w.name))}
                </p>
                <p className="text-muted">
                  {w.topics.length} {pluralize('Topic', w.topics.length)}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <PaginationButtons
        pageIndex={paginationState.pageIndex}
        onPageChange={handlePageChange}
        canNextPage={hasNextPage()}
      />
    </div>
  )
}
