/* eslint-disable max-params */
// disabling max-params to keep the interface simple
import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation as useReactRouterLocation } from 'react-router-dom'

import { SHA256, enc } from 'crypto-js'
import { capitalize, lowerCase } from 'lodash'
import mixpanel, { Dict } from 'mixpanel-browser'

import { isMixpanelLoggingEnabled } from 'utils/env'
import { getItem, setItem } from 'utils/local-store'
import { backendRestUrl, mixpanelToken } from 'utils/server-data'

import { useAuthUser } from 'components/common/auth-context'

import EngagementTracker from './engagement-tracker'

export type AnalyticsProperties = Dict
export type TrackEventFunction = (
  eventName: string,
  properties?: AnalyticsProperties
) => void

// Public, non-sensitive token
const MIXPANEL_PROXY_ADDR = `${backendRestUrl}/mp`

// How long between events before starting a new session = 30 minutes
const MAX_SESSION_LENGTH_MS = 30 * 60 * 1000

const LS_SESSION_ID_KEY = 'session_id'
const LS_LAST_EVENT_KEY = 'session_last_event'

const HISTORY_ID_REGEX = /^\d+$/
const UUID_REGEX =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

const SUPPRESS_PAGE_VIEW_DURATION_MILIS = 1000
export const PAGE_VIEW_EVENT_NAME = 'Page Viewed'
export const FILTER_CHANGED_EVENT_NAME = 'Filter Changed'

interface AnalyticsProviderProps {
  children: React.ReactNode
}

interface AnalyticsContextType {
  trackEvent: (
    eventName: string,
    properties?: Dict,
    suppressPageView?: boolean,
    omitSessionId?: boolean
  ) => void
}

export const AnalyticsContext = createContext<AnalyticsContextType | null>(null)

// add a way to force disable MP
declare global {
  interface Window {
    disableMP?: () => void
  }
}

export const useOptionalLocation = () => {
  try {
    const location = useReactRouterLocation()
    return location
  } catch (e) {
    return { pathname: window.location.pathname }
  }
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const userInfo = useAuthUser()
  const { pathname } = useOptionalLocation()
  const isInitialized = useRef(false)
  const [isLoaded, setLoaded] = useState(false)
  const eventBuffer = useRef<Array<{ eventName: string; properties?: Dict }>>(
    []
  )
  const suppressPageViewRef = useRef(false)
  const suppressPageViewTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const lastSessionId = useRef<string>('')
  const lastEventTimestamp = useRef<number>(0)
  const [mixpanelEnabled, setMixpanelEnabled] = useState(
    isMixpanelLoggingEnabled
  )

  const setSuppressPageView = useCallback((value: boolean) => {
    suppressPageViewRef.current = value
    if (suppressPageViewTimeoutRef.current) {
      clearTimeout(suppressPageViewTimeoutRef.current)
    }
    if (value) {
      suppressPageViewTimeoutRef.current = setTimeout(() => {
        suppressPageViewRef.current = false
      }, SUPPRESS_PAGE_VIEW_DURATION_MILIS)
    }
  }, [])

  useEffect(() => {
    if (!isInitialized.current) {
      if (mixpanelEnabled) {
        mixpanel.init(mixpanelToken, {
          loaded: () => {
            setLoaded(true)
            // Flush the event buffer
            if (eventBuffer.current.length > 0) {
              eventBuffer.current.forEach(({ eventName, properties }) => {
                mixpanel.track(eventName, properties)
              })
              eventBuffer.current = []
              console.info('mp event buffer flushed')
            }
          },
          api_host: MIXPANEL_PROXY_ADDR,
          // Prevent calls to Mixpanel by setting a garbage value for the cdn parameter
          cdn: 'https://nx.harvey.ai',
        })
        mixpanel.identify(userInfo.nonDbId)
        mixpanel.identify(userInfo.id)
        mixpanel.people.set({
          user_id: userInfo.id,
          pseudonymized_id: userInfo.pseudonymizedId,
          workspace_id: userInfo.workspace.id,
          workspace_slug: userInfo.workspace.slug,
          is_eyes_off: userInfo.workspace.isEyesOff,
        })
      } else {
        setLoaded(true)
      }
      isInitialized.current = true

      window.disableMP = () => {
        if (!userInfo?.IsInternalUser) {
          return
        }
        if (mixpanelEnabled) {
          setMixpanelEnabled(false)
          console.info('MP has been disabled.')
        } else {
          console.info('MP is already disabled.')
        }
      }
    }
  }, [userInfo, mixpanelEnabled])

  useEffect(() => {
    // Populate these once from localStorage on first load
    lastSessionId.current = getItem(LS_SESSION_ID_KEY) || ''
    lastEventTimestamp.current = getItem(LS_LAST_EVENT_KEY) || 0
  }, [])

  // If the last event was recorded < MAX_SESSION_LENGTH_MS ago, use lastSessionId.
  // Otherwise, generate a new sessionId from hash of their user id + the current time.
  const generateSessionId = useCallback(
    (now: number) => {
      let sessionId = lastSessionId.current
      if (
        !sessionId ||
        now - lastEventTimestamp.current > MAX_SESSION_LENGTH_MS
      ) {
        sessionId = SHA256(`${userInfo.nonDbId}${now}`).toString(enc.Hex)
        lastSessionId.current = sessionId
        setItem(LS_SESSION_ID_KEY, sessionId)
      }
      return sessionId
    },
    [userInfo]
  )
  const trackEvent = useCallback(
    (
      eventName: string,
      properties?: Dict,
      suppressPageView?: boolean,
      omitSessionId?: boolean
    ): void => {
      const now = Date.now()
      const eventSessionId = generateSessionId(now)
      const pathParts = pathname.split('/').filter(filterIdPathPart)
      const pageTitle = pathParts
        .map((part) => capitalize(lowerCase(part)))
        .join(' ')

      const eventProperties: Record<string, unknown> = {
        path: pathname,
        page: pageTitle,
        user_email: userInfo.nonDbId,
        workspace_id: userInfo.workspace.id,
        workspace_slug: userInfo.workspace.slug,
        parent_workspace_id:
          userInfo.workspace.parentId ?? userInfo.workspace.id,
        time: now,
        ...properties,
      }

      // Include sessionID for events that should count in native session calculation
      if (!omitSessionId) {
        eventProperties.session_id = eventSessionId
      }
      if (suppressPageView === true) {
        setSuppressPageView(true)
      }

      if (suppressPageViewRef.current && eventName === PAGE_VIEW_EVENT_NAME) {
        return
      }

      if (!isMixpanelLoggingEnabled) {
        console.info('MP Event:', eventName, 'Properties:', eventProperties)
      } else if (!isLoaded) {
        console.info('event log attempted before mp loaded')
        try {
          eventBuffer.current.push({ eventName, properties: eventProperties })
          if (eventBuffer.current.length > 10) {
            eventBuffer.current.shift()
          }
          console.info('mp event buffered:', eventName)
        } catch (error) {
          console.info('mp event failed to buffer', error)
        }
      } else {
        mixpanel.track(eventName, eventProperties)
      }

      lastEventTimestamp.current = now
      setItem(LS_LAST_EVENT_KEY, now)
    },
    [generateSessionId, isLoaded, pathname, userInfo, setSuppressPageView]
  )

  return (
    <AnalyticsContext.Provider value={{ trackEvent }}>
      {isLoaded && <EngagementTracker />}
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  const analyticsContext = useContext(AnalyticsContext)
  if (!analyticsContext) {
    throw new Error(
      'useAnalytics must be called within <AnalyticsContext.Provider>'
    )
  }
  return analyticsContext
}

const filterIdPathPart = (part: string) => {
  return part && !HISTORY_ID_REGEX.test(part) && !UUID_REGEX.test(part)
}
