import React, { useState } from 'react'

import { Book, Ellipsis, Pencil, Star } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import Services from 'services'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant-v2/hooks/use-assistant-analytics'
import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import * as API from 'components/assistant-v2/utils/assistant-api'
import { getMessageQuery } from 'components/assistant-v2/utils/assistant-helpers'
import { useAuthUser } from 'components/common/auth-context'
import { useHistoryMetadataStore } from 'components/history/history-metadata-store'
import { LibrarySaveExample } from 'components/library/library-save-example'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'

const AssistantHeaderDropdown = ({ disabled }: { disabled?: boolean }) => {
  const userInfo = useAuthUser()
  const [eventId, folderId, setFolderId, isEventOwner] = useAssistantStore(
    useShallow((s) => [s.eventId, s.folderId, s.setFolderId, s.isEventOwner])
  )
  const trackEvent = useAssistantAnalytics()

  const favoriteFolder = useHistoryMetadataStore((s) => s.favoriteFolder)
  const isStarred = folderId && folderId === favoriteFolder?.id

  const handleFavorite = async (e: Event) => {
    e.preventDefault()
    const folderId = isStarred ? null : favoriteFolder?.id
    setFolderId(folderId)

    await Services.Backend.Patch<boolean>(
      `user/history/${eventId}`,
      {
        id: eventId,
        folderId,
      },
      {
        throwOnError: true,
      }
    )
      .then(() => {
        displaySuccessMessage(
          folderId ? 'Added star in History' : 'Removed star from History'
        )
        trackEvent(folderId ? 'Thread Starred' : 'Thread Unstarred')
      })
      .catch(() => {
        setFolderId(folderId ? null : favoriteFolder?.id)
        displayErrorMessage('Failed to update starred')
      })
  }

  const [showEditCaption, setShowEditCaption] = useState(false)
  const handleEditCaption = () => {
    setShowEditCaption(true)
    trackEvent('Edit Caption Clicked')
  }

  const [showSaveExample, setShowSaveExample] = useState(false)
  const handleSaveExample = () => {
    setShowSaveExample(true)
    trackEvent('Save Example Clicked')
  }
  const handleExampleDialogChange = () => {
    setShowSaveExample(false)
  }

  const handleMenuOpenChange = (open: boolean) => {
    if (open) {
      trackEvent('Assistant Header Actions Menu Opened')
    }
  }

  const showEditTitle = !!isEventOwner
  const showStarHistory = !!isEventOwner && !!userInfo?.IsHistoryUser
  const showMenu =
    showEditTitle || showStarHistory || userInfo.canSeeSaveExample

  return (
    <>
      {showMenu && (
        <DropdownMenu onOpenChange={handleMenuOpenChange}>
          <DropdownMenuTrigger asChild disabled={disabled}>
            <Button
              variant="ghost"
              size="smIcon"
              data-testid="ellipsis-dropdown"
            >
              <Icon icon={Ellipsis} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-52" align="end">
            {showStarHistory && (
              <DropdownMenuItem onSelect={handleFavorite}>
                <Icon
                  className={cn('mr-2', {
                    'fill-primary': isStarred,
                  })}
                  icon={Star}
                  size="small"
                />{' '}
                {isStarred ? 'Remove star from History' : 'Star in History'}
              </DropdownMenuItem>
            )}
            {showEditTitle && (
              <DropdownMenuItem onSelect={handleEditCaption}>
                <Icon className="mr-2" icon={Pencil} size="small" />
                Edit title…
              </DropdownMenuItem>
            )}
            {userInfo.canSeeSaveExample && (
              <DropdownMenuItem onSelect={handleSaveExample}>
                <Icon className="mr-2" icon={Book} size="small" /> Save as
                example…
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {showSaveExample && eventId && (
        <LibrarySaveExample
          eventId={eventId}
          dialogOnly
          onOpenChange={handleExampleDialogChange}
        />
      )}
      {showEditCaption && eventId && (
        <SetCaptionDialog
          eventId={eventId}
          showEditCaption={showEditCaption}
          setShowEditCaption={setShowEditCaption}
        />
      )}
    </>
  )
}

const SetCaptionDialog = (props: {
  eventId: string
  showEditCaption: boolean
  setShowEditCaption: (show: boolean) => void
}) => {
  const { eventId, showEditCaption, setShowEditCaption } = props
  const [getCurrentThreadMessages, userCaption] = useAssistantStore(
    useShallow((s) => [s.getCurrentThreadMessages, s.userCaption])
  )
  const trackEvent = useAssistantAnalytics()
  const currentThreadMessages = getCurrentThreadMessages()
  const [caption, setCaption] = useState(
    userCaption ||
      currentThreadMessages[0]?.caption ||
      getMessageQuery(currentThreadMessages[0]) ||
      ''
  )
  const MAX_CAPTION_LENGTH = 100

  const saveCaption = async (caption: string) => {
    if (!eventId || !caption.trim().length) return
    trackEvent('Edit Caption Saved')
    API.setCaption(eventId, caption.trim())
    useAssistantStore.setState({ userCaption: caption.trim() })
    displaySuccessMessage('Thread title updated')
  }

  const handleCancel = () => {
    trackEvent('Edit Caption Canceled')
    setShowEditCaption(false)
  }

  return (
    <Dialog open={showEditCaption} onOpenChange={setShowEditCaption}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit title</DialogTitle>
        </DialogHeader>
        <Input
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              saveCaption(e.currentTarget.value)
              setShowEditCaption(false)
            }
          }}
          placeholder="New title"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          maxLength={MAX_CAPTION_LENGTH}
        />
        <DialogFooter>
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              saveCaption(caption)
              setShowEditCaption(false)
            }}
            disabled={!caption.length}
          >
            Update
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AssistantHeaderDropdown
