import React, { useCallback, useMemo, useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams, useSearchParams } from 'react-router-dom'

import { groupBy, isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { MessageFeedback } from 'openapi/models/MessageFeedback'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { useAnnotationCaches } from 'hooks/use-annotation-caches'
import { Source, TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import { EM_DASH, cn } from 'utils/utils'

import {
  applyAnnotations,
  applyFrontendAnnotations,
} from 'components/assistant/pspdfkit-helpers'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { FeedbackButton } from 'components/common/feedback/feedback'
import { PdfViewerPushSheet } from 'components/common/pdf-viewer/pdf-viewer-push-sheet'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'
import { FileControls } from 'components/vault/components/vault-app-header/file-controls'
import Footnote from 'components/vault/components/vault-footnote'
import { useUpsertEventFeedback } from 'components/vault/hooks/use-feedback'
import {
  EXPIRATION_URL_KEY,
  fileIdSearchParamKey,
  sourceIdSearchParamKey,
  questionIdSearchParamKey,
  PUSHSHEET_REVIEW_PDF_PERCENTAGE,
  PUSHSHEET_REVIEW_ANSWERS_PERCENTAGE,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import useVaultFeedbackStore from 'components/vault/utils/vault-feedback-store'
import { FetchVaultFile } from 'components/vault/utils/vault-fetcher'
import {
  getDisplayAnswer,
  isAnswerEmpty,
  isUrlExpired,
} from 'components/vault/utils/vault-helpers'
import {
  useVaultStore,
  VaultReviewSocketState,
} from 'components/vault/utils/vault-store'

const PushSheetHeader = ({
  activeFile,
  currentFileIndex,
  fileIds,
  previousFileId,
  nextFileId,
  setSearchParamsFileId,
  resetPushSheet,
}: {
  activeFile: UploadedFile | null
  currentFileIndex: number | undefined
  fileIds: string[]
  previousFileId: string | undefined
  nextFileId: string | undefined
  setSearchParamsFileId: (fileId: string) => void
  resetPushSheet: () => void
}) => {
  return (
    <div className="flex h-[49px] w-full shrink-0 items-center justify-between border-b px-4">
      <p className="truncate font-semibold">
        {!activeFile?.name ? 'Loading…' : activeFile.name}
      </p>
      <div className="flex items-center gap-2">
        <FileControls
          currentFileIndex={currentFileIndex}
          fileIds={fileIds}
          previousFileId={previousFileId}
          nextFileId={nextFileId}
          onNavigateToFile={setSearchParamsFileId}
          onExit={resetPushSheet}
        />
      </div>
    </div>
  )
}

const QueryReviewAnswers = ({
  activeFile,
  reviewData,
  sources,
}: {
  activeFile: VaultFile
  reviewData: VaultReviewSocketState
  sources: Source[]
}) => {
  const { projectId, queryId } = useParams()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const queryParamSourceId = searchParams.get(sourceIdSearchParamKey)
  const queryParamFileId = searchParams.get(fileIdSearchParamKey)

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const feedbacks = useVaultFeedbackStore((s) => s.feedbacks)
  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)
  const clearDocumentAnnotation = usePDFViewerStore(
    (s) => s.clearDocumentAnnotation
  )

  const isWorkflowRepsWarranties = reviewData.isWorkflowRepsWarranties
  const questions = reviewData.questions
  const columnHeaders = reviewData.columnHeaders
  const fileAnswers = reviewData.answers[activeFile.id] || []
  const groupedFileAnswers = groupBy(fileAnswers, 'columnId')
  const isEmptyGroupedFileAnswers = useMemo(
    () => isEmpty(groupedFileAnswers),
    [groupedFileAnswers]
  )

  const scrollAreaRef = useRef<HTMLDivElement>(null)

  const onDocumentSourceClick = (source: Source) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set(sourceIdSearchParamKey, source.id)
      return newParams
    })
    setActiveDocument(activeFile)
  }

  useEffect(() => {
    clearDocumentAnnotation()
  }, [activeFile, clearDocumentAnnotation])

  const { upsertEventFeedback } = useUpsertEventFeedback()

  const handleSubmitFeedback = async (
    questionId: string,
    newFeedback: MessageFeedback
  ) => {
    if (!queryId || !projectId || !queryParamFileId) return false
    const task_type = TaskType.VAULT_REVIEW
    trackEvent('Feedback Submitted', {
      event_id: queryId,
      event_kind: task_type,
      sentiment: newFeedback.sentiment,
      comments: newFeedback.selectedComments,
      vaultFolderId: projectId,
      fileId: queryParamFileId,
      questionId: questionId,
    })
    const submittedFeedback = await upsertEventFeedback({
      eventId: Number(queryId),
      vaultFolderId: projectId,
      fileId: queryParamFileId,
      questionId: questionId,
      feedbackData: newFeedback,
    })
    return submittedFeedback !== null
  }

  return (
    <ScrollArea ref={scrollAreaRef} className="h-full">
      <div className="h-full space-y-4 divide-y p-8">
        {isEmptyGroupedFileAnswers && (
          <div className="flex h-full items-center justify-center">
            <Spinner className="h-8 w-8" />
          </div>
        )}
        {Object.entries(groupedFileAnswers).map(([key, value]) => {
          const question = questions.find((question) => question.id === key)
          const columnHeader = columnHeaders.find((header) => header.id === key)
          const shortAnswer = value.find((answer) => !answer.long)
          const longAnswer = value.find((answer) => answer.long)
          const questionSources = sources.filter(
            (source) => source.questionId === key
          )
          const shouldShowQuestion = !(
            isWorkflowRepsWarranties &&
            (isEmpty(columnHeader?.text) ||
              columnHeader?.text === question?.text)
          )
          const shouldShowShortAnswer = !(
            isWorkflowRepsWarranties &&
            (!shortAnswer || isAnswerEmpty(shortAnswer.text))
          )

          return (
            <div
              key={key}
              id={key}
              className="flex flex-col gap-2 pt-4 first:pt-0 last:pb-8"
            >
              <div>
                <div className="flex">
                  <h3 className="mr-2 text-sm font-semibold">
                    {columnHeader?.text ?? question?.text}
                  </h3>
                  {questionSources.length > 0 && (
                    <div className="flex flex-wrap items-center space-x-2">
                      {questionSources.map((source) => (
                        <Footnote
                          isCurrentSource={queryParamSourceId === source.id}
                          key={`${key}-${source.id}`}
                          text={source.footnote.toString()}
                          onClick={() => onDocumentSourceClick(source)}
                        />
                      ))}
                    </div>
                  )}
                </div>
                {shouldShowQuestion && (
                  <p className="text-muted">{question?.text}</p>
                )}
              </div>
              {shouldShowShortAnswer && (
                <div className="mt-2 grid grid-cols-6 gap-2">
                  <p className="col-span-1 text-muted">Short</p>
                  <p
                    className="col-span-5"
                    dangerouslySetInnerHTML={{
                      __html: getDisplayAnswer(shortAnswer).replace(
                        /\n/g,
                        '<br />'
                      ),
                    }}
                  />
                </div>
              )}
              <div className="grid grid-cols-6 gap-2">
                <p className="col-span-1 text-muted">
                  {shouldShowShortAnswer ? 'Long' : 'Answer'}
                </p>
                <p
                  className="col-span-5"
                  dangerouslySetInnerHTML={{
                    __html:
                      longAnswer?.text.toLocaleLowerCase() ===
                      shortAnswer?.text.toLocaleLowerCase()
                        ? EM_DASH
                        : getDisplayAnswer(longAnswer).replace(/\n/g, '<br />'),
                  }}
                />
              </div>
              {userInfo.IsFeedbackUser && (
                <div className="flex items-center gap-1">
                  <FeedbackButton
                    hasDocuments
                    onSubmit={(feedback) => handleSubmitFeedback(key, feedback)}
                    sentiment={
                      feedbacks[queryId ?? '']?.[queryParamFileId ?? '']?.[
                        key ?? ''
                      ]?.sentiment ?? 0
                    }
                    sentimentValue={1}
                    buttonSize="xsIcon"
                  />
                  <FeedbackButton
                    hasDocuments
                    onSubmit={(feedback) => handleSubmitFeedback(key, feedback)}
                    sentiment={
                      feedbacks[queryId ?? '']?.[queryParamFileId ?? '']?.[
                        key ?? ''
                      ]?.sentiment ?? 0
                    }
                    sentimentValue={-1}
                    buttonSize="xsIcon"
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </ScrollArea>
  )
}

const PushSheet = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const sourceId = searchParams.get(sourceIdSearchParamKey)

  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )
  const activeDocument = useVaultStore(useShallow((s) => s.activeDocument))
  const queryId = useVaultStore(useShallow((s) => s.queryId))
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const queryIdToReviewState = useVaultStore(
    useShallow((s) => s.queryIdToReviewState)
  )
  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const displayedRows = useVaultDataGridFilterStore((s) => s.displayedRows)

  const {
    sourceAnnotationsRef,
    updateSourceAnnotationsRef,
    documentAnnotationsRef,
    updateDocumentAnnotationsRef,
  } = useAnnotationCaches()

  const isPdfLoading = usePDFViewerStore(useShallow((s) => s.isPdfLoading))
  const instance = usePDFViewerStore(useShallow((s) => s.instance))
  const setIsPdfLoading = usePDFViewerStore((s) => s.setIsPdfLoading)
  const isAnnotating = usePDFViewerStore(useShallow((s) => s.isAnnotating))
  const setIsAnnotating = usePDFViewerStore((s) => s.setIsAnnotating)

  const isReviewQuery =
    queryIdToState[queryId]?.taskType === TaskType.VAULT_REVIEW
  const sources = useMemo(() => {
    // queryIdToReviewState - needed for review query
    // queryIdToState - needed for ask query
    if (queryId && fileId) {
      return (
        queryIdToReviewState[queryId]?.fileIdToSources[fileId] ||
        queryIdToState[queryId]?.sources ||
        []
      )
    }
    return []
  }, [queryId, fileId, queryIdToState, queryIdToReviewState])
  const fileSources = useMemo(
    () => sources.filter((source: Source) => source.documentId === fileId),
    [sources, fileId]
  )
  const currentSource = useMemo(
    () => sources.find((source: Source) => source.id === sourceId),
    [sources, sourceId]
  )

  // because of how the fileId is set in the url - useSearchParams will always trigger the useEffect to run
  // this causes QueryReviewAnswers to flash blank and reset it's scrollbar because of the setActiveDocument in the useEffect
  // so we need to use a ref to check if the fileId has actually changed
  const fileIdRef = useRef<string | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  // putting this in useMemo because of useEffect below
  const reviewQueryState = useMemo(() => {
    if (!queryId) return undefined
    return queryIdToReviewState[queryId]
  }, [queryId, queryIdToReviewState])
  const shouldDisplayQuestionAnswerSection =
    fileId && reviewQueryState && !isEmpty(reviewQueryState.answers[fileId])

  const isLoadingUrl = useMemo(() => {
    const isActiveFile = activeDocument !== null
    const isLoadingFileUrl =
      isActiveFile &&
      isEmpty(activeDocument.url) &&
      !isEmpty(activeDocument.path)
    const isLoadingFileDocAsPdfUrl =
      isActiveFile &&
      isEmpty(activeDocument.docAsPdfUrl) &&
      isEmpty(activeDocument.docAsPdfPath)

    return isLoadingFileUrl || isLoadingFileDocAsPdfUrl
  }, [activeDocument])

  const resetPushSheet = useCallback(() => {
    setActiveDocument(null)
    fileIdRef.current = null
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(fileIdSearchParamKey)
      newParams.delete(sourceIdSearchParamKey)
      newParams.delete(questionIdSearchParamKey)
      return newParams
    })
    // we need to have setSearchParams because the prev value needs to be updated esp when we go to a subfolder
    // BUG: https://github.com/remix-run/react-router/issues/9304
    // Discussion: https://www.reddit.com/r/reactjs/comments/1brbpd3/react_routers_setsearchparams_is_broken/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveDocument, setSearchParams])

  // we have this function here to account for users that arrive directly on navigation
  const fetchFile = useCallback(async () => {
    if (!fileId) {
      setActiveDocument(null)
      fileIdRef.current = null
      return
    }

    if (fileIdRef.current === fileId) return
    // first let's check if we have the file in our store
    // if we don't have it then we should terminate early because the file does not exist (ie it was deleted)
    const existingFile = fileIdToVaultFile[fileId]
    if (isEmpty(existingFile)) {
      displayErrorMessage('This file does not exist.')
      resetPushSheet()
      return
    }
    fileIdRef.current = fileId
    // before we being to load the pdf we need to unload the pdf
    // this is because the pdf-viewer could have another pdf loaded
    // so we need to clean up before loading the new pdf
    // this is especially important when the user uses the forward/back browser buttons
    setIsPdfLoading(true)
    const existingFileUrl = existingFile.url || existingFile.docAsPdfUrl || ''
    const isUrlEmpty = isEmpty(existingFileUrl)

    const isExistingFileUrlValid =
      !isUrlEmpty && !isUrlExpired(existingFileUrl, EXPIRATION_URL_KEY)
    if (isExistingFileUrlValid) {
      setActiveDocument(existingFile)
      return
    }
    // setting activeDocument to empty values so that drawer will render
    // specifically setting the 'x' path value so that the drawer will show a loading bar
    setActiveDocument({ name: '', url: '', path: 'x' } as UploadedFile)
    const file = await FetchVaultFile(fileId)
    if (!isEmpty(file)) {
      upsertVaultFiles([file])
      setActiveDocument(file as UploadedFile)
    } else {
      resetPushSheet()
    }
  }, [
    fileIdToVaultFile,
    fileId,
    setActiveDocument,
    upsertVaultFiles,
    resetPushSheet,
    setIsPdfLoading,
  ])

  // using useEffect to account for users that navigate away or navigate back
  useEffect(() => {
    void fetchFile()
  }, [fetchFile])

  // this use effect is used to update the annotation applied to the activeDocument
  // if we have a current source, then it will scroll to the source highlight
  // if we have no current source, then it will apply the annotations
  // we use a useEffect here instead of a callback function because the user can navigate based on query params
  // by setting source_id
  const annotationsHandler = useCallback(async () => {
    const activeDocumentFile = activeDocument as VaultFile
    if (
      !activeDocument ||
      isEmpty(activeDocument.url) ||
      fileIdRef.current !== activeDocumentFile.id ||
      isPdfLoading
    )
      return

    const shouldUseBackendProvidedHighlights =
      !instance || (currentSource && currentSource.annotations.length > 0)

    if (shouldUseBackendProvidedHighlights) {
      await applyAnnotations(
        activeDocument,
        fileSources,
        instance,
        currentSource
      )
    } else {
      setIsAnnotating(true)
      await applyFrontendAnnotations({
        sources: fileSources,
        pdfkitInstance: instance,
        selectedSource: currentSource,
        cachedSourceAnnotations: sourceAnnotationsRef.current,
        updateSourceAnnotationStore: updateSourceAnnotationsRef.current,
        cachedDocumentAnnotations: documentAnnotationsRef.current,
        updateDocumentAnnotationStore: updateDocumentAnnotationsRef.current,
      })
      setIsAnnotating(false)
    }
  }, [
    activeDocument,
    currentSource,
    fileSources,
    instance,
    isPdfLoading,
    fileIdRef,
    documentAnnotationsRef,
    sourceAnnotationsRef,
    updateDocumentAnnotationsRef,
    updateSourceAnnotationsRef,
    setIsAnnotating,
  ])

  useEffect(() => {
    void annotationsHandler()
  }, [annotationsHandler])

  const fileIds = useMemo(() => {
    const fileIds: string[] = []
    displayedRows.forEach((fileId) => {
      if (fileId && fileIdToVaultFile[fileId]) {
        fileIds.push(fileId)
      }
    })
    return fileIds
  }, [displayedRows, fileIdToVaultFile])
  const previousFileId = useMemo(() => {
    if (!fileId) return undefined
    const currentIndex = fileIds.indexOf(fileId)
    if (currentIndex === -1 || currentIndex === 0) return undefined
    return fileIds[currentIndex - 1]
  }, [fileId, fileIds])
  const nextFileId = useMemo(() => {
    if (!fileId) return undefined
    const currentIndex = fileIds.indexOf(fileId)
    if (currentIndex === -1 || currentIndex === fileIds.length - 1)
      return undefined
    return fileIds[currentIndex + 1]
  }, [fileId, fileIds])
  const currentFileIndex = useMemo(() => {
    if (!fileId) return undefined
    return fileIds.indexOf(fileId)
  }, [fileId, fileIds])

  const setSearchParamsFileId = (fileId: string) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set(fileIdSearchParamKey, fileId)
      newParams.delete(sourceIdSearchParamKey)
      newParams.delete(questionIdSearchParamKey)
      return newParams
    })
  }
  useHotkeys(
    'up',
    () => {
      if (previousFileId) {
        setSearchParamsFileId(previousFileId)
      }
    },
    {
      enabled: !!previousFileId,
    }
  )
  useHotkeys(
    'down',
    () => {
      if (nextFileId) {
        setSearchParamsFileId(nextFileId)
      }
    },
    {
      enabled: !!nextFileId,
    }
  )
  // when the user presses escape key we want to hide the push sheet
  useHotkeys(
    'esc',
    () => {
      resetPushSheet()
    },
    {
      enabled: !!activeDocument && !isEmpty(activeDocument.url),
    },
    [activeDocument, resetPushSheet]
  )
  return (
    <div
      tabIndex={-1}
      // the z-10 index ensures that the border-l on the push-sheet is visible
      className={cn(`z-10 flex h-full shrink-0 flex-col border-l`, {
        hidden: !activeDocument,
        'w-1/3': activeDocument && isReviewQuery,
        'w-3/4': shouldDisplayQuestionAnswerSection,
      })}
    >
      <PushSheetHeader
        activeFile={activeDocument}
        fileIds={fileIds}
        previousFileId={previousFileId}
        nextFileId={nextFileId}
        currentFileIndex={currentFileIndex}
        resetPushSheet={resetPushSheet}
        setSearchParamsFileId={setSearchParamsFileId}
      />
      <div
        className={cn('block h-full min-h-0 grow', {
          flex: shouldDisplayQuestionAnswerSection,
        })}
      >
        <div
          className="relative h-full w-full"
          style={{
            width: shouldDisplayQuestionAnswerSection
              ? `${PUSHSHEET_REVIEW_PDF_PERCENTAGE}%`
              : '100%',
            height: '100%',
          }}
        >
          {isAnnotating && (
            <div
              className="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-white bg-opacity-75"
              style={{ pointerEvents: 'none' }}
            >
              <Spinner />
            </div>
          )}
          <PdfViewerPushSheet
            document={activeDocument}
            isLoadingUrl={isLoadingUrl}
            containerRef={containerRef}
          />
        </div>
        {activeDocument && shouldDisplayQuestionAnswerSection && (
          <div
            className="h-full border-l"
            style={{
              width: `${PUSHSHEET_REVIEW_ANSWERS_PERCENTAGE}%`,
              height: '100%',
            }}
          >
            <QueryReviewAnswers
              activeFile={activeDocument as VaultFile}
              reviewData={reviewQueryState}
              sources={fileSources}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PushSheet
