import React from 'react'

import { Row, Table } from '@tanstack/react-table'
import _ from 'lodash'
import { FileText, MoreHorizontal, Star, Users } from 'lucide-react'
import pluralize from 'pluralize'

import { Event } from 'models/event'
import { UserInfo } from 'models/user-info'
import Services from 'services'
import { Maybe } from 'types'
import { HistoryItem } from 'types/history'

import { getRouteForEvent } from 'utils/routing'
import { AssistantDocument } from 'utils/task'
import {
  useAllTaskLabelLookup,
  getQueryForDisplay,
  getFollowUpCount,
} from 'utils/task-definitions'
import { EM_DASH, backendToLocalFull, backendToReadable, cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/ui/hover-card/hover-card'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import { useHistoryMetadataStore } from './history-metadata-store'

interface TableProps {
  table: Table<Event>
}

interface CellProps {
  row: Row<Event>
  userInfo?: UserInfo
}

const BaseCell: React.FC<{
  text: string
  tooltipContent: string
  wrapInBadge?: boolean
  'data-testid'?: string
}> = ({
  text,
  tooltipContent,

  wrapInBadge,
  'data-testid': dataTestId,
}) => {
  return (
    <Tooltip>
      <TooltipTrigger className="block w-full text-left">
        {wrapInBadge ? (
          <Badge data-testid={dataTestId} variant="secondary">
            <p className="line-clamp-1 break-all text-start text-sm">{text}</p>
          </Badge>
        ) : (
          <p
            data-testid={dataTestId}
            className="line-clamp-1 break-all text-start text-sm"
          >
            {text}
          </p>
        )}
      </TooltipTrigger>
      <TooltipContent className="max-w-2xl">
        <span className="line-clamp-8 text-[length:inherit]">
          {tooltipContent}
        </span>
      </TooltipContent>
    </Tooltip>
  )
}

const HistorySelectHeader: React.FC<TableProps> = ({ table }) => {
  return (
    <div className="flex w-full justify-start">
      <Button
        variant="ghost"
        size="smIcon"
        onClick={(e) => {
          e.stopPropagation()
          table.toggleAllRowsSelected(!table.getIsAllRowsSelected())
        }}
      >
        <Checkbox
          checked={
            table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()
          }
          isIndeterminate={table.getIsSomeRowsSelected()}
          onCheckedChange={(value) => {
            table.toggleAllRowsSelected(!!value)
          }}
          aria-label="Select all"
        />
      </Button>
    </div>
  )
}

const HistorySelectCell: React.FC<CellProps> = ({ row }) => {
  return (
    <div className="flex w-full justify-start">
      <Button
        variant="ghost"
        size="smIcon"
        onClick={(e) => {
          e.stopPropagation()
          row.toggleSelected(!row.getIsSelected())
        }}
      >
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      </Button>
    </div>
  )
}

const HistoryUserNameCell: React.FC<CellProps> = ({ row }) => {
  const userName: string = row.getValue('userId')
  return (
    <BaseCell
      text={userName}
      tooltipContent={userName}
      data-testid="history-table--cell--user-name"
    />
  )
}

const HistoryTimeCell: React.FC<CellProps> = ({ row }) => {
  return (
    <BaseCell
      text={backendToReadable(row.getValue('created'))}
      tooltipContent={backendToLocalFull(row.getValue('created'))}
      data-testid="history-table--cell--time"
    />
  )
}

const HistoryFavoriteCell: React.FC<CellProps> = ({ row }) => {
  const { getFavoriteStatus, updateEventFavorite } = useHistoryMetadataStore()
  const { trackEvent } = useAnalytics()
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="smIcon"
          onClick={async (event) => {
            event.stopPropagation()
            const eventId: number = row.getValue('id')
            const newValue = !getFavoriteStatus(row.original)
            await updateEventFavorite(eventId, newValue)
            Services.HoneyComb.Record({
              metric: 'ui.history_favorite',
              event_id: eventId,
              favorite: newValue,
            })
            trackEvent(`History Item Starred`, {
              event_id: eventId,
              starred: newValue,
            })
          }}
          data-testid="history-table--cell--favorite"
        >
          {getFavoriteStatus(row.original) ? (
            <Star className="h-4 w-4 fill-gold stroke-gold" />
          ) : (
            <Star className="h-4 w-4 stroke-primary" />
          )}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {getFavoriteStatus(row.original) ? 'Starred' : 'Not starred'}
      </TooltipContent>
    </Tooltip>
  )
}

const DocumentsRow: React.FC<{ documents: AssistantDocument[] }> = ({
  documents,
}) => {
  if (_.isEmpty(documents)) {
    return null
  }

  return (
    <div className="flex space-x-1">
      {documents.slice(0, 2).map((doc) => (
        <Badge
          variant="outline"
          className="rounded px-2"
          key={doc.name}
          data-testid="history-table--cell--document"
        >
          <FileText size={12} className="mr-1.5" />
          <p
            className={cn('truncate text-sm', {
              'max-w-32': documents.length === 1,
              'max-w-24': documents.length === 2,
              'max-w-16': documents.length > 2,
            })}
            title={doc.name}
          >
            {doc.name}
          </p>
        </Badge>
      ))}
      {documents.length > 2 && (
        <Badge variant="outline" className="whitespace-nowrap rounded px-2">
          + {documents.length - 2} more
        </Badge>
      )}
    </div>
  )
}

const HistoryTitleCell: React.FC<CellProps> = ({ row }) => {
  const documents = row.original.documents ?? []
  const name = getQueryForDisplay(row.original)
  const query = row.original.query
  const { numFollowUps, followUpNoun } = getFollowUpCount(
    row.original as unknown as HistoryItem
  )
  const hasFollowUpsAndDocs = numFollowUps > 0 && documents.length > 0

  return (
    <div data-testid="history-table--cell--query">
      <HoverCard>
        <HoverCardTrigger
          className={cn('flex h-6 items-center space-x-2', {
            'mb-2': hasFollowUpsAndDocs,
          })}
        >
          <span
            data-testid="library-table--cell--name"
            className="line-clamp-1 break-all text-sm"
          >
            {name}
          </span>
          {numFollowUps > 0 && (
            <span className="shrink-0 rounded-full bg-muted px-2 py-1 text-xs text-secondary">
              {numFollowUps} {pluralize(followUpNoun, numFollowUps)}
            </span>
          )}
        </HoverCardTrigger>
        <HoverCardContent
          className="flex max-w-3xl flex-col p-0"
          align="start"
          alignOffset={-12}
        >
          <div className="p-4 pb-3 text-xs font-semibold">{name}</div>
          <ScrollArea className="flex min-h-0 grow flex-col" isFullHeight>
            <div className="whitespace-pre-wrap p-4 pt-0 text-xs">{query}</div>
          </ScrollArea>
        </HoverCardContent>
      </HoverCard>
      <DocumentsRow documents={documents} />
    </div>
  )
}

// Used by V1 users, will be removed in the future
const HistoryQueryCell: React.FC<CellProps> = ({ row }) => {
  const documents = row.original.documents ?? []
  const query = getQueryForDisplay(row.original)

  return (
    <div data-testid="history-table--cell--query">
      <Tooltip>
        <TooltipTrigger className="block w-full text-left">
          <p className="line-clamp-1 text-sm">{query}</p>
        </TooltipTrigger>
        <TooltipContent className="max-w-2xl" align="start">
          <span className="line-clamp-8 text-[length:inherit]">{query}</span>
        </TooltipContent>
      </Tooltip>
      <DocumentsRow documents={documents} />
    </div>
  )
}

const HistorySharedCell: React.FC<CellProps> = ({ row }) => {
  const isShared = row.original.isShared

  if (!isShared) return null

  const tooltipContent = 'This has been shared with others'

  return (
    <Tooltip>
      <TooltipTrigger className="flex items-center justify-center">
        <Icon icon={Users} size="default" />
      </TooltipTrigger>
      <TooltipContent className="max-w-2xl">
        <span className="line-clamp-8 text-[length:inherit]">
          {tooltipContent}
        </span>
      </TooltipContent>
    </Tooltip>
  )
}

const HistoryResponseCell: React.FC<CellProps> = ({ row }) => {
  return (
    <BaseCell
      text={row.getValue('response')}
      tooltipContent={row.getValue('response')}
      data-testid="history-table--cell--response"
    />
  )
}

const HistoryTaskTypeCell: React.FC<CellProps> = ({ row, userInfo }) => {
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const taskTypeLabel = taskLabelLookup[row.original.kind]
  return (
    <BaseCell
      text={taskTypeLabel}
      tooltipContent={taskTypeLabel}
      wrapInBadge
      data-testid="history-table--cell--task-type"
    />
  )
}

interface HistoryClientMatterCellProps extends CellProps {
  getClientMatterName: (event: Event) => Maybe<string>
}

const HistoryClientMatterCell: React.FC<HistoryClientMatterCellProps> = ({
  row,
  getClientMatterName,
}) => {
  const clientMatterName = getClientMatterName(row.original)
  return (
    <BaseCell
      text={clientMatterName ?? EM_DASH}
      tooltipContent={clientMatterName ?? 'No client matter number associated'}
      data-testid="history-table--cell--client-matter"
    />
  )
}

interface HistoryMenuCellProps extends CellProps {
  userInfo: UserInfo
  workspaceId: number
  getClientMatterName: (event: Event) => Maybe<string>
  onEditClientMatter?: (event: Event) => void
  onRemoveClientMatter?: (event: Event) => void
  onDeleteEvent?: (event: Event) => void
  onOpenEventAuditLog?: (row: Row<Event>) => Promise<void>
}

const HistoryMenuCell: React.FC<HistoryMenuCellProps> = ({
  row,
  userInfo,
  getClientMatterName,
  onEditClientMatter,
  onRemoveClientMatter,
  onDeleteEvent,
  onOpenEventAuditLog,
}) => {
  const { getRouteWithClientMatter } = useHistoryMetadataStore()
  const { trackEvent } = useAnalytics()
  const eventLink = getRouteWithClientMatter(
    row.original,
    getRouteForEvent(row.original, userInfo!)
  )
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  return (
    <DropdownMenu onOpenChange={setIsDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={cn({
            'bg-button-secondary text-primary': isDropdownOpen,
          })}
        >
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          onClick={async (event) => {
            event.stopPropagation()
            if (onOpenEventAuditLog) {
              await onOpenEventAuditLog(row)
            }
            Services.HoneyComb.Record({
              metric: 'ui.history_open_in_new_tab',
              event_id: row.original.id,
            })
            trackEvent('History Item Loaded', {
              event_id: row.original.id,
              event_kind: row.original.kind,
              in_new_tab: true,
            })
            window.open(eventLink, '_blank')
          }}
        >
          Open in new tab
        </DropdownMenuItem>
        {onEditClientMatter && (
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation()
              onEditClientMatter(row.original)
              Services.HoneyComb.Record({
                metric: 'ui.history_edit_client_matter',
                event_id: row.original.id,
              })
              trackEvent('History Item Client Matter Edited', {
                event_id: row.original.id,
              })
            }}
          >
            Edit CM#
          </DropdownMenuItem>
        )}
        {onRemoveClientMatter && getClientMatterName(row.original) && (
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation()
              onRemoveClientMatter(row.original)
              Services.HoneyComb.Record({
                metric: 'ui.history_remove_client_matter',
                event_id: row.original.id,
              })
              trackEvent('History Item Client Matter Removed', {
                event_id: row.original.id,
              })
            }}
          >
            <p>Remove CM#</p>
          </DropdownMenuItem>
        )}
        {onDeleteEvent && <DropdownMenuSeparator />}
        {onDeleteEvent && (
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation()
              onDeleteEvent(row.original)
              Services.HoneyComb.Record({
                metric: 'ui.history_delete_event',
                event_id: row.original.id,
                event_user_id: row.original.userId,
              })
              trackEvent('History Item Deleted', {
                event_id: row.original.id,
                event_user_id: row.original.userId,
              })
            }}
            className="text-destructive"
          >
            Delete
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export {
  HistorySelectHeader,
  HistorySelectCell,
  HistoryUserNameCell,
  HistoryTimeCell,
  HistoryFavoriteCell,
  HistoryTitleCell,
  HistoryQueryCell,
  HistoryResponseCell,
  HistoryTaskTypeCell,
  HistorySharedCell,
  HistoryClientMatterCell,
  HistoryMenuCell,
  DocumentsRow,
}
