import React from 'react'

import type { SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import _ from 'lodash'

import type { Event } from 'models/event'
import type { UserInfo } from 'models/user-info'
import { EventKind } from 'openapi/models/EventKind'
import { HistoryItem } from 'types/history'

import { getMessageThreadFromMessages } from 'components/assistant-v2/utils/assistant-helpers'
import { MultiSelectEntry } from 'components/ui/multi-select'

import { TaskType } from './task'

export const TaskDefinitions = (
  userInfo: UserInfo | null
): SelectProps['options'] => {
  return [
    {
      label: userInfo?.IsAssistantV2User ? 'Assist' : 'Assistant',
      value: TaskType.ASSISTANT,
      key: 'Write a question for Harvey to research…',
      metric: 'ui.ask_harvey',
      available: userInfo?.IsBaseUser,
    },
    {
      label: 'Tax',
      value: TaskType.TAX,
      key: 'Enter a question regarding tax law…',
      metric: 'ui.ask_harvey',
      available: userInfo?.IsJapanTaxQAUser,
    },
    {
      label: userInfo?.IsAssistantV2User ? 'Assist' : 'Assistant',
      value: TaskType.DOCUMENT_QA,
      key: 'Write a question related to the document uploaded…',
      metric: 'ui.document_qa',
      available: userInfo?.IsDocQaUser,
    },
    {
      label: userInfo?.IsAssistantV2User ? 'Assist' : 'Assistant',
      value: TaskType.OPEN_ENDED,
      key: 'Enter any question or task here…',
      metric: 'ui.ask_harvey',
      available: userInfo?.IsBaseUser,
    },
    {
      label: 'Help Me',
      value: TaskType.HELP_ME,
      key: 'Enter any question here about how to use Harvey…',
      metric: 'ui.ask_harvey',
      available: userInfo?.IsInternalUser,
    },
    {
      label: userInfo?.IsAssistantV2User ? 'Assist' : 'Assistant',
      value: TaskType.CREATE_OUTLINE,
      key: 'Describe the document you would like to outline…',
      metric: 'ui.ask_harvey',
      available: userInfo?.isDocumentUser || userInfo?.IsAssistantV2User,
    },
    {
      label: userInfo?.IsAssistantV2User ? 'Assist' : 'Assistant',
      value: TaskType.RESEARCH_MEMO,
      key: 'Describe the topic you would like a research memo on…',
      metric: 'ui.ask_harvey',
      available: userInfo?.isDocumentUser || userInfo?.IsAssistantV2User,
    },
    {
      label: 'EDGAR',
      value: TaskType.SEC_EDGAR_QA,
      key: 'Enter any question or task related to 10-K, DEF 14A, or 8-K filings…',
      metric: 'ui.sec_edgar_qa',
      available: userInfo?.IsEDGARQAUser,
    },
    {
      label: 'Memos',
      value: TaskType.MEMOS_QA,
      key: 'Enter any question or task related to law firm memos…',
      metric: 'ui.memos_qa',
      available: userInfo?.IsMemosUser,
    },
    {
      label: 'Company Profile',
      value: TaskType.COMPANY_PROFILE,
      key: 'Enter a stock ticker.',
      metric: 'ui.company_profile',
      available: userInfo?.IsCompanyProfileUser,
    },
    {
      label: 'Tax', // TODO: Properly rename and migrate to a TaskType.UK_TAX, rather than just reusing HMRC_QA
      value: TaskType.HMRC_QA,
      key: 'Enter a question regarding UK tax law…',
      metric: 'ui.ask_harvey',
      available: userInfo?.IsHMRCQAUser,
    },
    {
      label: 'Tax',
      value: TaskType.JAPAN_TAX_QA,
      key: 'Enter a question regarding Japanese tax law…',
      metric: 'ui.ask_harvey',
      available: userInfo?.IsJapanTaxQAUser,
    },
    {
      label: 'Redline Q&A',
      value: TaskType.REDLINES,
      key: 'Write a question related to the redline document…',
      metric: 'ui.redlines',
      available: userInfo?.IsRedlinesUser,
    },
    {
      label: 'Redline Issues List',
      value: TaskType.ISSUES_LIST,
      key: 'Provide a list of issues to examine in the redline document…',
      metric: 'ui.issues_list',
      available: userInfo?.IsRedlinesUser,
    },
    {
      label: userInfo?.IsAssistantV2User ? 'Assist' : 'Assistant',
      value: TaskType.MULTI_DOC_QA,
      key: 'Write a question related to the documents uploaded…',
      metric: 'ui.multi_doc_qa',
      available: userInfo?.IsMultiDocQaUser,
    },
    {
      label: userInfo?.IsAssistantV2User ? 'Assist' : 'Assistant',
      value: TaskType.CORPUS_QA,
      key: 'Write a question related to the corpus documents uploaded…',
      metric: 'ui.corpus_qa',
      available: userInfo?.IsCorpusQaUser,
    },
    {
      label: 'Translation',
      value: TaskType.TRANSLATION,
      key: '',
      metric: 'ui.translation',
      available: userInfo?.IsTranslationUser,
    },
    {
      label: userInfo?.IsAssistantV2User ? 'Assist' : 'Assistant',
      value: TaskType.INTERNET_BROWSING,
      key: 'Write a question for Harvey to research…',
      metric: 'ui.internet_browsing',
      available: userInfo?.IsInternetBrowsingUser,
    },
    {
      label: 'Assist',
      groupLabel: userInfo?.IsAssistantV2User ? 'Assistant' : '',
      value: TaskType.ASSISTANT_CHAT,
      key: 'Chat with Harvey…',
      metric: 'ui.assistant_chat',
      available: userInfo?.IsAssistantV2User,
    },
    {
      label: 'Draft',
      groupLabel: userInfo?.IsAssistantV2User ? 'Assistant' : '',
      value: TaskType.ASSISTANT_DRAFT,
      key: 'Draft a document with Harvey…',
      metric: 'ui.assistant_draft',
      available: userInfo?.IsAssistantV2User,
    },
    {
      label: 'OGC Contract Review',
      value: TaskType.OGC_REVIEW,
      key: '',
      metric: 'ui.ogc_review',
      available: userInfo?.IsOGCReviewUser,
      max_duration: 600,
    },
    {
      label: 'Drafting',
      value: TaskType.DRAFTING,
      key: '',
      metric: 'ui.drafting',
      available: userInfo?.IsInternalUser, // TODO: change to IsDraftingUser
    },
    {
      label: 'Document Comparison',
      value: TaskType.DOCUMENT_COMPARISON,
      key: '',
      metric: 'ui.document_comparison',
      available: userInfo?.IsDocumentComparisonUser,
    },
    {
      label: 'US Case Law',
      value: TaskType.USA_CASELAW,
      key: '',
      metric: 'ui.usa_caselaw',
      available: userInfo?.IsUsaCaseLawUser,
    },
    {
      label: 'French Case Law',
      value: TaskType.FRANCE_CASELAW,
      key: '',
      metric: 'ui.france_caselaw',
      available: userInfo?.IsFranceCaseLawUser,
    },
    {
      label: 'EUR-Lex',
      value: TaskType.EURLEX_QA,
      key: '',
      metric: 'ui.eurlex_qa',
      available: userInfo?.IsEurLexUser,
    },
    {
      label: 'Transcripts',
      value: TaskType.TRANSCRIPTS,
      key: '',
      metric: 'ui.transcripts',
      available: userInfo?.isTranscriptsUser,
    },
    {
      label: 'Australia Breach Reporting',
      value: TaskType.AUS_BREACH_REPORTING,
      key: '',
      metric: 'ui.aus_breach_reporting',
      available: userInfo?.IsAusBreachReportingUser,
    },
    {
      label: 'Vault Ask',
      groupLabel: userInfo?.IsVaultUser ? 'Vault' : '',
      value: TaskType.VAULT,
      key: 'Get a single answer on collective information across all files',
      metric: 'ui.vault',
      available: userInfo?.IsVaultUser,
    },
    {
      label: 'Vault Review',
      groupLabel: userInfo?.IsVaultReviewUser ? 'Vault' : '',
      value: TaskType.VAULT_REVIEW,
      key: 'Get individual answers for each file in a table',
      metric: 'ui.vault_review',
      available: userInfo?.IsVaultReviewUser,
    },
    {
      label: 'Cuatrecasas',
      value: TaskType.CUATRECASAS,
      key: '',
      metric: 'ui.cuatrecasas',
      available: userInfo?.IsCuatrecasasUser,
    },
    {
      label: 'From Counsel',
      value: TaskType.FROM_COUNSEL,
      key: '',
      metric: 'ui.from_counsel',
      available: userInfo?.IsFromCounselUser,
    },
    {
      label: 'Discovery',
      value: TaskType.PWC_DEALS,
      key: 'pwcdeals',
      // metric: 'ui.ask_harvey', TODO: Add metric
      available: userInfo?.isDiligenceUser,
    },
    {
      label: 'Diligence Transcripts',
      value: TaskType.DILIGENCE_TRANSCRIPTS,
      key: '',
      metric: 'ui.diligence_transcripts',
      available: userInfo?.isDiligenceUser,
    },
    {
      label: 'Competitive Analysis',
      value: TaskType.COMPETITIVE_ANALYSIS,
      key: '',
      metric: 'ui.competitive_analysis',
      available: userInfo?.isCompetitiveAnalysisUser,
    },

    // NOTE: this is a legacy product we don't offer to users anymore
    // keeping this here to provide product labels for filters & dashboards
    {
      label: 'Advanced Analytics',
      value: TaskType.ADVANCED_ANALYTICS,
      key: 'Write a query for Harvey to analyze…',
      metric: 'ui.advanced_analytics',
      available: false,
    },
  ]
}

export const taskLabelLookup = (
  taskDefs: SelectProps['options']
): Record<string, string> => {
  return (taskDefs ?? []).reduce((acc: Record<string, string>, cur) => {
    if (!_.isNil(cur.value)) {
      acc[cur.value] = cur.label?.toString() ?? ''
    }
    return acc
  }, {})
}

export const useAllTaskLabelLookup = (
  userInfo?: UserInfo
): Record<string, string> => {
  return React.useMemo(
    () => taskLabelLookup(TaskDefinitions(userInfo ?? null)),
    [userInfo]
  )
}

export const TaskDefsAvailable = (
  userInfo: UserInfo
): SelectProps['options'] => {
  return (TaskDefinitions(userInfo) ?? []).filter(
    (taskDef) => taskDef.available
  )
}

export const useAvailableTaskLabelLookup = (
  userInfo: UserInfo
): Record<string, string> => {
  return React.useMemo(
    () => taskLabelLookup(TaskDefsAvailable(userInfo)),
    [userInfo]
  )
}

export const groupTaskTypeEntries = (
  taskTypes: MultiSelectEntry[],
  userInfo?: UserInfo
) => {
  const taskDefs = TaskDefinitions(userInfo || null) || []
  const groupedEntriesMap: {
    [label: string]: { [text: string]: MultiSelectEntry }
  } = {}
  const sortedTaskTypesMap: { [text: string]: MultiSelectEntry } = {}

  taskTypes.forEach((entry: MultiSelectEntry) => {
    // TODO: FIX TYPES, why is this an antd type?
    let availableDef: DefaultOptionType | undefined
    let groupLabelDef: DefaultOptionType | undefined
    taskDefs.forEach((def) => {
      if (def.label === entry.text && def.available) {
        availableDef = def
        if (def.groupLabel) groupLabelDef = def
      }
    })

    // TODO: Simplify this.
    // This workaround associates multiple task type values with a single label. Otherwise,
    // the value will just be the first task definition with the label, and selecting
    // a separate associated value will not show the label as selected.
    if (groupLabelDef?.groupLabel) {
      const groupLabel = groupLabelDef.groupLabel
      if (!groupedEntriesMap[groupLabel]) groupedEntriesMap[groupLabel] = {}
      groupedEntriesMap[groupLabel][entry.text] = {
        ...entry,
        values: [
          ...(groupedEntriesMap[groupLabel][entry.text]?.values || []),
          entry.value,
        ],
      }
    } else if (availableDef) {
      sortedTaskTypesMap[entry.text] = {
        ...entry,
        values: [
          ...(sortedTaskTypesMap[entry.text]?.values || []),
          entry.value,
        ],
      }
    }
  })

  const sortedGroups = Object.entries(groupedEntriesMap).map(
    ([label, entriesMap]) => ({
      label,
      entries: Object.values(entriesMap),
    })
  )

  return { sortedTaskTypes: Object.values(sortedTaskTypesMap), sortedGroups }
}

export const AssistantV1TaskTypes: TaskType[] = [
  TaskType.OPEN_ENDED,
  TaskType.DOCUMENT_QA,
  TaskType.MULTI_DOC_QA,
  TaskType.CORPUS_QA,
  TaskType.INTERNET_BROWSING,
  TaskType.CREATE_OUTLINE,
  TaskType.RESEARCH_MEMO,
]

export const newTaxTaskTypes: TaskType[] = [
  TaskType.JAPAN_TAX_QA,
  TaskType.NETHERLANDS_TAX_QA,
  TaskType.INDIA_TAX_QA,
  TaskType.AUSTRALIA_TAX_QA,
  TaskType.IRELAND_TAX_QA,
  TaskType.SWEDEN_TAX_QA,
  TaskType.SWITZERLAND_TAX_QA,
  TaskType.HMRC_QA,
  TaskType.WWTS_QA,
  TaskType.PILLAR_TWO_TAX_QA,
]

export const getCleanedTaskType = (task: TaskType): TaskType => {
  if (AssistantV1TaskTypes.includes(task)) {
    return TaskType.ASSISTANT
  } else if (newTaxTaskTypes.includes(task)) {
    return TaskType.TAX
  }
  return task
}

export const getCleanedEvent = (event: Event): Event => {
  return { ...event, kind: getCleanedTaskType(event.kind) }
}

export const getQueryForDisplay = (event: Event): string => {
  if (!event.query) {
    return ''
  }
  if (event.kind == TaskType.ISSUES_LIST) {
    try {
      const listOfIssues: string[] = JSON.parse(event.query)
      return listOfIssues.join(', ')
    } catch {
      return event.query
    }
  }

  if (event.userCaption) return event.userCaption

  if (event.messages?.length) {
    if (event.messages[0]?.caption) {
      return event.messages[0]?.caption
    }
  }
  return event.query
}

export const getFollowUpCount = (event: HistoryItem) => {
  let messages = event.messages ?? []
  messages = event.currentMessageId
    ? getMessageThreadFromMessages(messages, event.currentMessageId)
    : messages
  const numFollowUps = messages.length - 1

  const followUpNoun =
    event.kind === EventKind.ASSISTANT_DRAFT ? 'revision' : 'follow-up'

  return { numFollowUps, followUpNoun }
}

export const getDocumentCount = (event: Event): number => {
  return (
    event.messages?.[0].knowledgeSources?.[0]?.fileIds?.length ||
    event.documents?.length ||
    0
  )
}
