// This is a pure function for filtering examples, copied over from event model
import { Event } from 'models/event'

// Interface for all the state information needed for examples
interface ExamplesStore {
  examples: Event[]
  useCases: string[]
  documentTypes: string[]
}

// Function to get useCases and documentTypes from examples
const getUseCasesDocTypesFromExamples = (examples: Event[]): ExamplesStore => {
  const { useCases, documentTypes } = examples.reduce(
    (acc: { useCases: string[]; documentTypes: string[] }, ev) => {
      if (ev.useCase !== undefined && !acc.useCases.includes(ev.useCase)) {
        acc.useCases.push(ev.useCase)
      }

      if (
        ev.documentType !== undefined &&
        !acc.documentTypes.includes(ev.documentType)
      ) {
        acc.documentTypes.push(ev.documentType)
      }

      return acc
    },
    {
      useCases: [],
      documentTypes: [],
    }
  )
  return {
    examples,
    useCases,
    documentTypes,
  }
}

// Function to filter events according to task label
const filterExamples: (
  taskLabelLookup: Record<string, any>,
  events: Event[]
) => Event[] = (taskLabelLookup, events) => {
  return events
    .map(
      (d: Event): Event => ({
        id: d.id,
        status: d.status,
        query: d.query ?? '',
        response: (d.response ?? '').replace(/#/g, '').trim(),
        kind: d.kind,
        created: d.created,
        updatedAt: d.updatedAt,
        useCase: d.useCase,
        documentType: d.documentType,
      })
    )
    .filter((d: Event) => d.kind in taskLabelLookup)
}

// exports
export { filterExamples, getUseCasesDocTypesFromExamples }
export type { ExamplesStore }
