import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { Transition } from '@headlessui/react'
import { Plus } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { MarkdownDiff } from 'utils/markdown-diff'
import { useNavigateWithQueryParams } from 'utils/routing'
import { AnnotationById } from 'utils/task'
import { cn } from 'utils/utils'

import AssistantChangeLog from 'components/assistant-v2/assistant-change-log'
import { AssistantCreateCopy } from 'components/assistant-v2/components/assistant-create-copy'
import AssistantHighlightTip from 'components/assistant-v2/components/assistant-highlight-tip'
import { AssistantMode } from 'components/assistant-v2/components/assistant-mode-select'
import AssistantReplyInput from 'components/assistant-v2/components/assistant-reply-input'
import AssistantSources from 'components/assistant-v2/components/assistant-sources'
import AssistantThread, {
  ThreadMessage,
} from 'components/assistant-v2/components/assistant-thread'
import {
  AssistantThreadSidebar,
  AssistantThreadSidebarSection,
  AssistantThreadSidebarSubSection,
} from 'components/assistant-v2/components/assistant-thread-layout'
import AssistantToolbar from 'components/assistant-v2/components/assistant-toolbar'
import { DraftingMarkdownWithInlineEdits } from 'components/assistant-v2/drafting-markdown-text-selection'
import { useAssistantAnalytics } from 'components/assistant-v2/hooks/use-assistant-analytics'
import { AssistantDraftStreamHandler } from 'components/assistant-v2/hooks/use-assistant-draft'
import { useRestoreAssistantHistoryItem } from 'components/assistant-v2/hooks/use-assistant-load-history-item'
import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  AssistantDraftInlineEditQuery,
  AssistantDraftMessage,
} from 'components/assistant-v2/types'
import {
  EXAMPLE_MESSAGE_ID,
  getIsFinalizingStream,
  getMessageQuery,
  REVISION_ID_PARAM,
} from 'components/assistant-v2/utils/assistant-helpers'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Switch } from 'components/ui/switch'
import { useVaultStore } from 'components/vault/utils/vault-store'

type Props = {
  useDraft: AssistantDraftStreamHandler
}

// State to manage the 'Add revision' input and transitions
type DraftRevisionFocus = { focused: boolean; visible: boolean }

const AssistantDraft = ({ useDraft }: Props) => {
  const { eventId } = useParams()
  const navigate = useNavigateWithQueryParams()

  const { error } = useRestoreAssistantHistoryItem(eventId, AssistantMode.DRAFT)
  const [
    streamingMessage,
    messages,
    documents,
    getCurrentThreadMessages,
    currentMessageId,
    deleteMessage,
    restoreState,
    userCaption,
    isEventOwner,
    vaultSource,
  ] = useAssistantStore(
    useShallow((s) => [
      s.streamingMessage as AssistantDraftMessage,
      s.messages as AssistantDraftMessage[],
      s.documents,
      s.getCurrentThreadMessages,
      s.currentMessageId,
      s.deleteMessage,
      s.restoreState,
      s.userCaption,
      s.isEventOwner,
      s.vaultSource,
    ])
  )

  const [folderIdToVaultFileIds, fileIdToVaultFile] = useVaultStore(
    useShallow((s) => [s.folderIdToVaultFileIds, s.fileIdToVaultFile])
  )

  const sourceDocuments = useMemo(() => {
    if (vaultSource?.vaultFolderId) {
      const vaultFileIds = new Set(vaultSource.fileIds)
      return (folderIdToVaultFileIds[vaultSource.vaultFolderId] ?? [])
        .map((fileId) => fileIdToVaultFile[fileId] as UploadedFile)
        .filter((file) => vaultFileIds.has(file.id))
    }
    return documents
  }, [documents, folderIdToVaultFileIds, fileIdToVaultFile, vaultSource])

  const trackEvent = useAssistantAnalytics()
  const [useDiffView, setUseDiffView] = useState(true)
  const isExample = eventId === EXAMPLE_MESSAGE_ID

  const handleShowEditsToggle = (useDiffView: boolean) => {
    setUseDiffView(useDiffView)
    trackEvent('Show Edits Toggled', {
      event_id: currentMessageId,
      thread_number: currentThreadMessages.length + 1,
      message_number: messages.length + 1,
      diff_view: useDiffView ? 'on' : 'off',
    })
  }

  const currentThreadMessages = getCurrentThreadMessages()
  const allMessages = useMemo(
    () =>
      streamingMessage
        ? [...currentThreadMessages, streamingMessage]
        : currentThreadMessages,
    [currentThreadMessages, streamingMessage]
  ) as AssistantDraftMessage[]

  const [searchParams, setSearchParams] = useSearchParams()
  const revisionId = searchParams.get(REVISION_ID_PARAM) ?? ''
  const setRevisionId = useCallback(
    (messageId: string) => {
      setSearchParams(
        (prevParams) => {
          const newParams = new URLSearchParams(prevParams)
          if (messageId) {
            newParams.set(REVISION_ID_PARAM, messageId)
          } else {
            newParams.delete(REVISION_ID_PARAM)
          }
          return newParams
        },
        { replace: true }
      )
    },
    [setSearchParams]
  )

  const handleRevisionClick = (messageId: string) => {
    const threadNumber =
      allMessages.findIndex((message) => message.messageId === messageId) + 1
    trackEvent('Revision Selected', {
      event_id: messageId,
      thread_number: threadNumber,
      message_number: messages.length + 1,
    })
    setRevisionId(messageId)
  }

  useEffect(() => {
    if (!revisionId) {
      for (let i = allMessages.length - 1; i >= 0; i--) {
        if (allMessages[i].response?.length) {
          setRevisionId(allMessages[i].messageId)
          break
        }
      }
    }
  }, [allMessages, revisionId, setRevisionId])

  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [revisionFocus, setRevisionFocus] = useState<DraftRevisionFocus>({
    focused: false,
    visible: false,
  })
  const handleAddRevisionFocus = () => {
    setRevisionId(
      currentThreadMessages[currentThreadMessages.length - 1]?.messageId
    )
    setRevisionFocus({ focused: true, visible: true })
    trackEvent('Add Revision Initiated', {
      thread_number: currentThreadMessages.length + 1,
      message_number: messages.length + 1,
    })
  }
  const handleAddRevisionBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!e.relatedTarget?.closest('#assistant-input')) {
      setRevisionFocus({ focused: true, visible: false })
      trackEvent('Add Revision Cancelled', {
        thread_number: currentThreadMessages.length + 1,
        message_number: messages.length + 1,
      })
    }
  }
  const handleAddRevisionHide = useCallback(() => {
    setRevisionFocus({ focused: false, visible: false })
  }, [])

  useEffect(() => {
    if (revisionFocus.focused) {
      setTimeout(() => inputRef.current?.focus(), 0)
    }
  }, [revisionFocus])

  const lastStreamedMessageId = useRef<string>('')
  useEffect(() => {
    if (
      streamingMessage?.messageId &&
      streamingMessage.messageId !== lastStreamedMessageId.current
    ) {
      setRevisionId(lastStreamedMessageId.current)
      lastStreamedMessageId.current = streamingMessage.messageId
    } else if (streamingMessage) {
      lastStreamedMessageId.current = streamingMessage.messageId
    }
  }, [setRevisionId, streamingMessage])

  const selectedMessage =
    allMessages.find((m) => m.messageId === revisionId) ??
    allMessages[allMessages.length - 1]

  const markdownContent = useMemo(() => {
    const prevMessage = allMessages.find(
      (m) => m.messageId && m.messageId === selectedMessage?.prevMessageId
    )
    if (useDiffView && prevMessage && selectedMessage) {
      const prevResponse = prevMessage.response
      const selectedResponse = selectedMessage.response
      let response = selectedResponse
      try {
        response = new MarkdownDiff().run(prevResponse, selectedResponse)
      } catch (e) {
        // TODO: We should tell the user we couldn't generate the diff
        console.error('Error generating markdown diff, skipping', e)
      }
      return response
    }
    return selectedMessage?.response ?? ''
  }, [allMessages, selectedMessage, useDiffView])

  const allAnnotations = useMemo(() => {
    return allMessages.reduce((acc, message) => {
      if (message.annotations) {
        Object.entries(message.annotations).forEach(([key, value]) => {
          acc[key] = value
        })
      }
      return acc
    }, {} as AnnotationById)
  }, [allMessages])

  const firstMessage = allMessages[0]
  const threadTitle =
    userCaption ||
    firstMessage?.caption ||
    (firstMessage?.isLoading ? '' : getMessageQuery(firstMessage))

  const showRevisionOption =
    revisionId === allMessages[allMessages.length - 1]?.messageId

  const handleInlineEdit = (params: AssistantDraftInlineEditQuery) => {
    useDraft.createDraftInlineEditRequest({
      query: params.query,
      selectedText: params.selectedText,
      prevMessageId: revisionId,
    })
  }
  const inlineEditDisabled = !showRevisionOption || !!streamingMessage

  const inlineEditDisabledReason = useDiffView
    ? 'Cannot edit while viewing changes'
    : !showRevisionOption
    ? 'Revisions can only be made on the latest draft'
    : undefined

  const handleCancel = () => {
    useDraft.sendCancelRequest()
    if (!messages.length) {
      restoreState()
      navigate('/assistant', {
        state: { skipReset: true, mode: AssistantMode.DRAFT },
      })
    }
  }

  const handleRegenerateMessage = (message: AssistantDraftMessage) => {
    setRevisionId('')
    useDraft.regenerateMessage(message)
  }

  const handleDeleteMessage = (message: AssistantDraftMessage) => {
    deleteMessage(message)
    setRevisionId(message.prevMessageId || '')
  }

  if (error) {
    return (
      <ErrorPage
        title={ErrorPageTitle.SOMETHING_WENT_WRONG}
        primaryCTA={{
          text: 'Back to Assistant',
          onClick: () => navigate('/assistant'),
        }}
      />
    )
  }

  const isFinalizingStream = getIsFinalizingStream(streamingMessage)

  const isLastMessage =
    revisionId === streamingMessage?.prevMessageId ||
    revisionId === streamingMessage?.messageId

  const shouldPulse =
    !!streamingMessage && isLastMessage && allMessages.length > 1

  const sidebarElement = (
    <AssistantThreadSidebar className="pb-8">
      <AssistantThreadSidebarSection title="Revisions">
        <div id="assistant-draft-revise" className="space-y-2 pb-1.5 last:pb-0">
          <div className="space-y-1">
            <AssistantChangeLog
              messages={allMessages}
              handleCancel={handleCancel}
              handleClick={handleRevisionClick}
              selectedMessageId={revisionId}
              handleRegenerateMessage={handleRegenerateMessage}
              handleDeleteMessage={handleDeleteMessage}
            />
          </div>
          {isEventOwner && (
            <AssistantDraftRevisionInput
              handleHideInput={handleAddRevisionHide}
              handleShowInput={handleAddRevisionFocus}
              revisionFocus={revisionFocus}
            >
              <AssistantReplyInput
                inputRef={inputRef}
                isStreaming={!!streamingMessage}
                onBlur={handleAddRevisionBlur}
                prevMessageId={currentMessageId || undefined}
                useDraft={useDraft}
                isFinalizingStream={isFinalizingStream}
                isRevision
                onAsk={handleAddRevisionHide}
              />
            </AssistantDraftRevisionInput>
          )}
        </div>
        {!isEventOwner && !isExample && (
          <AssistantThreadSidebarSubSection>
            <AssistantCreateCopy
              eventId={eventId || null}
              mode={AssistantMode.DRAFT}
            />
          </AssistantThreadSidebarSubSection>
        )}
        {showRevisionOption && (
          <AssistantThreadSidebarSubSection>
            <AssistantHighlightTip />
          </AssistantThreadSidebarSubSection>
        )}
        {allMessages.length > 1 && selectedMessage?.prevMessageId && (
          <AssistantThreadSidebarSubSection>
            <label
              className="flex w-full items-center justify-between"
              htmlFor="use-diff-view"
            >
              <p className="text-xs">Show edits</p>
              <Switch
                checked={useDiffView}
                onCheckedChange={() => handleShowEditsToggle(!useDiffView)}
                id="use-diff-view"
              />
            </label>
          </AssistantThreadSidebarSubSection>
        )}
      </AssistantThreadSidebarSection>
      {sourceDocuments.length > 0 &&
        selectedMessage &&
        (!selectedMessage.isLoading || isFinalizingStream) && (
          <AssistantSources
            isStreaming={selectedMessage.isLoading}
            documents={sourceDocuments}
            sources={selectedMessage.sources}
            message={selectedMessage}
          />
        )}
    </AssistantThreadSidebar>
  )

  return (
    <AssistantThread
      messages={currentThreadMessages}
      sources={selectedMessage?.sources || []}
      title={threadTitle}
    >
      <ScrollArea className="h-full grow">
        <ThreadMessage
          message={selectedMessage}
          onCancel={handleCancel}
          sidebar={sidebarElement}
          allAnnotations={allAnnotations}
        >
          {(getHrvyInfoMetadata) => (
            <div
              className={cn({
                'animate-pulse': shouldPulse,
              })}
            >
              <DraftingMarkdownWithInlineEdits
                content={markdownContent}
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                inlineEdit={{
                  onInlineEdit: handleInlineEdit,
                  disabled: inlineEditDisabled,
                  disabledReason: inlineEditDisabledReason,
                  originalMarkdown: useDiffView
                    ? selectedMessage?.response
                    : undefined,
                }}
                currentMessageId={currentMessageId || ''}
                threadNumber={currentThreadMessages.length + 1}
                messageNumber={messages.length + 1}
              />
              {selectedMessage && !selectedMessage.isLoading && (
                <div className="mt-6 w-full">
                  <AssistantToolbar
                    documents={sourceDocuments}
                    message={selectedMessage}
                    threadTitle={
                      userCaption || currentThreadMessages[0]?.caption
                    }
                    threadNumber={currentThreadMessages.length + 1}
                    messageNumber={messages.length + 1}
                  />
                </div>
              )}
            </div>
          )}
        </ThreadMessage>
      </ScrollArea>
    </AssistantThread>
  )
}

export default AssistantDraft

const AssistantDraftRevisionInput = ({
  children,
  handleHideInput,
  handleShowInput,
  revisionFocus,
}: {
  children: React.ReactNode
  handleHideInput: () => void
  handleShowInput: () => void
  revisionFocus: DraftRevisionFocus
}) => {
  const query = useAssistantStore((s) => s.query)

  return (
    <div
      className={cn('relative', {
        'min-h-8': query,
      })}
    >
      {revisionFocus.focused && (
        <Transition
          show={revisionFocus.visible}
          leave="transition-opacity duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={handleHideInput}
        >
          {children}
        </Transition>
      )}
      {!revisionFocus.visible && query && (
        <button
          className={cn(
            'transition-border absolute top-0 w-full rounded-sm border border-b-transparent p-2 text-left text-muted duration-100',
            {
              'border-border': !revisionFocus.focused,
            }
          )}
          onClick={handleShowInput}
        >
          <span className="line-clamp-1 whitespace-pre-wrap text-xs">
            {query}
          </span>
        </button>
      )}
      {!revisionFocus.focused && !query && (
        <button
          className="group inline-flex w-full items-center rounded-sm border px-2.5 py-1.5 text-xs text-muted shadow-sm hover:border-input-focused hover:text-primary"
          onClick={handleShowInput}
        >
          <Icon
            className="mr-1 text-muted group-hover:text-primary"
            icon={Plus}
            size="small"
          />
          Add revision
        </button>
      )}
    </div>
  )
}
