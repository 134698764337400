import React, { useState, useRef, useMemo } from 'react'
import { useClickAway } from 'react-use'

import { CustomCellRendererProps } from 'ag-grid-react'
import { isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { EM_DASH, cn } from 'utils/utils'

import {
  CellType,
  BASE_NUM_KEY,
} from 'components/workflows/workflow/competitive-analysis/components/results-table'
import {
  useCompetitiveAnalysisDataGridStore,
  PopoverData,
} from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-data-grid-store'

const TextCell = (props: CustomCellRendererProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLButtonElement>(null)

  const type = props.colDef?.type ?? CellType.TEXT
  const field = props.colDef?.field as string
  const isFinancial = type === CellType.FINANCIAL

  const { value } = props
  const { id, numYears, ciqFinancialData } = props.data

  const sortedYears = useMemo(() => {
    return Object.keys(ciqFinancialData[BASE_NUM_KEY])
      .filter((key) => !isEmpty(key) && key !== 'Data Unavailable')
      .sort((a, b) => {
        if (a === 'Current') return -1
        if (b === 'Current') return 1
        return a.localeCompare(b)
      })
  }, [ciqFinancialData])

  const displayValue = useMemo(() => {
    if (!isFinancial) return value
    else if (field === 'year') return sortedYears
    return ciqFinancialData?.[field]
  }, [value, field, isFinancial, ciqFinancialData, sortedYears])

  const popoverData = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.popoverData)
  )
  const setPopoverData = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.setPopoverData)
  )
  const setPopoverPosition = useCompetitiveAnalysisDataGridStore(
    (s) => s.setPopoverPosition
  )

  const shouldHighlightRow = popoverData !== null && id === popoverData.id

  const handleCellClick = () => {
    if (!isFocused) {
      setIsFocused(true)
    }
    if (!ref.current) return
    const boundingClientRect = ref.current.getBoundingClientRect()
    const isExceedingRightEdge =
      boundingClientRect.x + boundingClientRect.width > window.innerWidth

    // TODO instead, just scroll to end
    if (isExceedingRightEdge) {
      setPopoverPosition({
        left: window.innerWidth - 24 - boundingClientRect.width,
        top: boundingClientRect.y,
        right: window.innerWidth - 24,
        width: boundingClientRect.width,
      })
    } else {
      setPopoverPosition({
        left: boundingClientRect.x,
        top: boundingClientRect.y,
        right: boundingClientRect.x + boundingClientRect.width,
        width: boundingClientRect.width,
      })
    }

    const popoverData: PopoverData = {
      id: id,
      displayText: displayValue,
      numYears: numYears,
    }
    setPopoverData(popoverData)
  }

  useClickAway(ref, () => {
    setIsFocused(false)
  })

  if (isFinancial) {
    return (
      <div className="flex flex-col">
        {sortedYears.map((year) => {
          let value = year
          if (field !== 'year') {
            value = displayValue[year]
          }
          value = value === 'Data Unavailable' ? EM_DASH : value
          return (
            <div
              key={year}
              className="flex h-[30px] items-center border-b px-4 text-left"
            >
              <p className="truncate text-xs">{value}</p>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <button
      ref={ref}
      onClick={handleCellClick}
      className={cn(
        'flex h-full w-full items-start px-4 py-2 text-left text-xs',
        {
          'ring-[1px] ring-inset ring-primary': isFocused,
          'bg-muted': shouldHighlightRow,
        }
      )}
    >
      <div className="h-full w-full overflow-hidden">
        <p className="truncate whitespace-normal text-xs">{displayValue}</p>
      </div>
    </button>
  )
}

export default TextCell
