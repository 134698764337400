import React from 'react'

import { isEmpty } from 'lodash'
import { LucideIcon } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button, ButtonProps } from 'components/ui/button'
import Combobox, {
  Props as ComboboxProps,
} from 'components/ui/combobox/combobox'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './dropdown-menu'
import Icon from './icon/icon'

interface ToolbeltProps {
  children: React.ReactNode
  className?: string
}

const Toolbelt = ({ children, className }: ToolbeltProps) => {
  return (
    <div
      className={cn(
        'absolute bottom-4 left-1/2 flex -translate-x-1/2 items-center justify-center space-x-2 rounded-lg bg-neutral-900 px-2 py-1',
        className
      )}
    >
      {children}
    </div>
  )
}

export const ToolbeltText = ({ children }: { children: string }) => {
  return <div className="p-1 text-sm text-neutral-300">{children}</div>
}

export const ToolbeltButton = React.forwardRef<
  HTMLButtonElement,
  { children?: React.ReactNode; icon?: LucideIcon } & ButtonProps
>(({ children, icon, className, ...buttonProps }, ref) => {
  const iconOnly = isEmpty(children)
  return (
    <Button
      size={iconOnly ? 'xsIcon' : 'sm'}
      variant="ghost"
      className={cn('group shrink-0 hover:bg-neutral-700', className)}
      ref={ref}
      {...buttonProps}
    >
      {icon && (
        <Icon
          icon={icon}
          className={cn('mr-1 text-neutral-300 group-hover:text-neutral-100', {
            'mr-0': iconOnly,
          })}
        />
      )}
      {children && (
        <div className="text-sm text-neutral-300 group-hover:text-neutral-100">
          {children}
        </div>
      )}
    </Button>
  )
})
ToolbeltButton.displayName = 'ToolbeltButton'

export const ToolbeltCombobox = (props: ComboboxProps) => {
  const { className, popoverClassName, ...restProps } = props
  return (
    <Combobox
      className={cn('rounded-md hover:bg-neutral-700', className)}
      popoverClassName={cn(
        'mb-1 bg-neutral-900 border-neutral-900 text-neutral-300 min-w-0 group popover--dark',
        popoverClassName
      )}
      buttonClassName="max-h-6 bg-neutral-900 hover:bg-neutral-700 border-none text-neutral-300 hover:text-neutral-300"
      searchClassName="bg-neutral-900 border-neutral-700 text-neutral-300 focus-visible:ring-offset-0"
      popoverMenuItemClassName="hover:bg-neutral-700 hover:text-neutral-300 focus:bg-neutral-700"
      {...restProps}
    />
  )
}

export const ToolbeltMenu = ({
  children,
  items,
}: {
  children: React.ReactNode
  items: { onClick: () => void; label: string }[]
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="mb-2 bg-neutral-900" align="start">
        {items.map((item) => (
          <DropdownMenuItem
            key={item.label}
            className="border-neutral-900 bg-neutral-900 text-neutral-300 hover:bg-neutral-700 hover:text-neutral-300 focus:bg-neutral-700 focus:text-neutral-300"
            onClick={item.onClick}
          >
            {item.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const ToolbeltDivider = ({ className }: { className?: string }) => {
  return <div className={cn('min-h-6 w-px bg-neutral-700', className)} />
}

export default Toolbelt
