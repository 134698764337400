import React, { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { SortDirection, GridApi } from 'ag-grid-community'
import { CustomHeaderProps } from 'ag-grid-react'
import {
  Calendar,
  Clipboard,
  File,
  FileText,
  MapPin,
  User,
  Type,
  ArrowDownAZ,
  ArrowUpAZ,
  Check,
  Hash,
  GripVertical,
} from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import {
  EXCLUDED_HEADER_NAMES,
  QuestionColumnDef,
} from 'components/vault/components/data-grid/vault-query-result-table'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { getNumSelectedRows } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const iconLookup: Record<string, React.ElementType> = {
  document: FileText,
  date: Calendar,
  location: MapPin,
  person: User,
  notes: Clipboard,
  file: File,
  text: Type,
  number: Hash,
}

interface SortDropdownProps {
  sortColumnId: string
  isSortIconHovered: boolean
  setIsSortIconHovered: (isSortIconHovered: boolean) => void
  setSort: (sort: SortDirection, multiSort?: boolean | undefined) => void
}
const SortDropdown = ({
  sortColumnId,
  isSortIconHovered,
  setIsSortIconHovered,
  setSort,
}: SortDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(
    'asc'
  )

  const currentSortColumnId = useVaultDataGridFilterStore(
    (state) => state.currentSortColumnId
  )
  const setCurrentSortColumnId = useVaultDataGridFilterStore(
    (state) => state.setCurrentSortColumnId
  )

  const isCurrentSortColumn = sortColumnId === currentSortColumnId
  const isCurrentSortAsc = isCurrentSortColumn && sortDirection === 'asc'
  const isCurrentSortDesc = isCurrentSortColumn && sortDirection === 'desc'
  const isSortIconHidden =
    !isCurrentSortColumn && !isSortIconHovered && !isDropdownOpen

  const sortHandler = (newSortDirection: 'asc' | 'desc') => {
    // using props.setSort instead of props.progressSort because progressSort was not working
    // it required the user to click on the icon twice to sort
    if (isCurrentSortColumn && newSortDirection === sortDirection) return
    setCurrentSortColumnId(sortColumnId)
    setSortDirection(newSortDirection)
    setSort(newSortDirection)
  }

  return (
    <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuTrigger>
        <Button
          size="xsIcon"
          variant="ghost"
          className={cn({
            hidden: isSortIconHidden,
          })}
        >
          {isCurrentSortDesc ? (
            <ArrowUpAZ className="h-3 w-3" />
          ) : (
            <ArrowDownAZ className="h-3 w-3" />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="space-y-1">
        <DropdownMenuItem
          onClick={() => {
            sortHandler('asc')
            setIsSortIconHovered(false)
          }}
          className={cn('flex w-full min-w-48 items-center justify-between', {
            'bg-button-secondary': isCurrentSortAsc,
          })}
        >
          <div className="flex items-center gap-1">
            <ArrowDownAZ className="h-3 w-3" />
            <p className="text-xs">Sort ascending</p>
          </div>
          {isCurrentSortAsc && <Check className="h-3 w-3" />}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            sortHandler('desc')
            setIsSortIconHovered(false)
          }}
          className={cn('flex w-full min-w-48 items-center justify-between', {
            'bg-button-secondary': isCurrentSortDesc,
          })}
        >
          <div className="flex items-center gap-1">
            <ArrowUpAZ className="h-3 w-3" />
            <p className="text-xs">Sort descending</p>
          </div>
          {isCurrentSortDesc && <Check className="h-3 w-3" />}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const RowNumberColumnHeader = ({ gridApi }: { gridApi: GridApi }) => {
  const selectedRows = useVaultDataGridFilterStore(
    useShallow((state) => state.selectedRows)
  )
  const bulkAddSelectedRows = useVaultDataGridFilterStore(
    (state) => state.bulkAddSelectedRows
  )
  const bulkRemoveSelectedRows = useVaultDataGridFilterStore(
    (state) => state.bulkRemoveSelectedRows
  )

  const numRows = useMemo(() => {
    const { numRows } = getNumSelectedRows(gridApi)
    return numRows
  }, [gridApi])

  const isHeaderChecked = selectedRows.length > 0

  const onCheckedChange = () => {
    const nextChecked = !isHeaderChecked
    const rowsToUpdate: string[] = []
    gridApi.forEachNode((node) => {
      if (node.group) return
      // if the next state is checked and the node is not displayed, we don't want to select it
      // if the next state is unchecked we want to unselect it regardless of whether it is displayed
      if (nextChecked && !node.displayed) return
      node.setSelected(nextChecked)
      rowsToUpdate.push(node.data.id)
    })
    if (nextChecked) {
      bulkAddSelectedRows(rowsToUpdate)
    } else {
      bulkRemoveSelectedRows(rowsToUpdate)
    }
  }
  return (
    <div className="flex h-full w-full cursor-default items-center justify-center border-r border-neutral-200 px-4">
      <Checkbox
        checked={isHeaderChecked}
        onCheckedChange={onCheckedChange}
        isIndeterminate={selectedRows.length < numRows}
      />
    </div>
  )
}

const VaultHeaderCell = (props: CustomHeaderProps) => {
  const { queryId } = useParams()

  const colDef = props.column.getColDef() as QuestionColumnDef
  const type = colDef.type
  const originalQuestion = colDef.originalQuestion

  const queryIdToState = useVaultStore((state) => state.queryIdToState)
  const queryIdToReviewState = useVaultStore(
    (state) => state.queryIdToReviewState
  )
  const exampleProjectIds = useVaultStore(
    useShallow((s) => s.exampleProjectIds)
  )
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))

  const isExampleProject = useMemo(
    () => currentProject?.id && exampleProjectIds.has(currentProject.id),
    [currentProject?.id, exampleProjectIds]
  )
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const isSortable = props.column.isSortable()
  const isRowNumberColumn = colDef.headerName === '#'
  const isLoading = queryId ? queryIdToState[queryId]?.isLoading : false
  const columnHeaderName = useMemo(() => {
    if (EXCLUDED_HEADER_NAMES.includes(colDef.headerName))
      return colDef.headerName
    return queryId
      ? queryIdToReviewState[queryId]?.columnHeaders.find(
          (header) => header.id === colDef.questionId
        )?.text || originalQuestion
      : 'Loading…'
  }, [
    queryId,
    originalQuestion,
    colDef.headerName,
    colDef.questionId,
    queryIdToReviewState,
  ])

  const icon = iconLookup[type as string]
  const iconComponent = React.createElement(icon, {
    className: 'mr-1 size-3 shrink-0',
  })

  if (isRowNumberColumn) {
    return <RowNumberColumnHeader gridApi={props.api} />
  }

  const showGripIcon =
    isHovered &&
    type !== 'document' &&
    !isLoading &&
    !isExampleProject &&
    doesCurrentUserHaveEditPermission

  return (
    <div
      className="flex w-full items-center justify-between px-4"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Tooltip delayDuration={200}>
        <TooltipTrigger
          className="w-full truncate"
          disabled={!originalQuestion}
        >
          <div className="flex items-center text-xs">
            {showGripIcon ? (
              <Icon icon={GripVertical} size="small" className="mr-1" />
            ) : (
              iconComponent
            )}
            {columnHeaderName}
          </div>
        </TooltipTrigger>
        {originalQuestion && (
          <TooltipContent className="max-w-72 whitespace-normal" align="start">
            {originalQuestion}
          </TooltipContent>
        )}
      </Tooltip>
      {isSortable && !isLoading && (
        <SortDropdown
          sortColumnId={colDef.field}
          isSortIconHovered={isHovered}
          setIsSortIconHovered={setIsHovered}
          setSort={props.setSort}
        />
      )}
    </div>
  )
}

export default VaultHeaderCell
