import React, { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Plus } from 'lucide-react'

import { getMergedQueryPath, NAV_STATE_PARAM } from 'utils/routing'

import { useAssistantAnalytics } from 'components/assistant-v2/hooks/use-assistant-analytics'
import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  FORK_EVENT_STATE_KEY,
  REMOVE_PARAMS,
} from 'components/assistant-v2/utils/assistant-helpers'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'

const AssistantCreateThreadDropdown = ({
  disabled,
}: {
  disabled?: boolean
}) => {
  const location = useLocation()
  const { mode } = useParams()
  const eventId = useAssistantStore((s) => s.eventId)
  const trackEvent = useAssistantAnalytics()
  const documents = useAssistantStore((s) => s.documents)
  const getCurrentThread = useAssistantStore((s) => s.getCurrentThreadMessages)
  const hasDocuments = !!documents.length

  const getLinkWithMode = useCallback(
    (reuse: boolean) => {
      const state = {
        mode,
        ...(reuse ? { query: getCurrentThread()[0]?.query } : {}),
        ...(reuse && hasDocuments ? { [FORK_EVENT_STATE_KEY]: eventId } : {}),
      }

      const params = new URLSearchParams(location.search)
      params.append(NAV_STATE_PARAM, JSON.stringify(state))
      return getMergedQueryPath(
        '/assistant',
        { search: params.toString() },
        REMOVE_PARAMS
      )
    },
    [location.search, mode, getCurrentThread, eventId, hasDocuments]
  )

  const trackFork = (reuse: boolean) => {
    trackEvent('Thread Forked', {
      reuse: reuse,
      with_documents: !!documents.length,
    })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled} asChild>
        <Button variant="outline">
          <Icon icon={Plus} className="mr-2" />
          New thread
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem asChild>
          <Link
            className="flex items-center text-sm"
            onClick={() => trackFork(false)}
            to={getLinkWithMode(false)}
          >
            Empty
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link
            className="flex items-center text-sm"
            onClick={() => trackFork(true)}
            to={getLinkWithMode(true)}
          >
            Reuse {hasDocuments ? 'documents' : 'query'}
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AssistantCreateThreadDropdown
