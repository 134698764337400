import React, { useState } from 'react'

import { Form, message } from 'antd'
import emailAddresses from 'email-addresses'

import { useNavigateWithQueryParams } from 'utils/routing'

import { Button as TailwindButton } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Input } from 'components/ui/input'

const SettingsUserPermissionsCard = () => {
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [searchForm] = Form.useForm()
  const navigate = useNavigateWithQueryParams()

  const handleSubmitEmail = async (): Promise<void> => {
    setLoading(true)
    const values = await searchForm.validateFields()
    const email = values.email
    const emailValid = emailAddresses.parseOneAddress(email)
    if (!emailValid) {
      message.error('Please enter a valid email address')
      setLoading(false)
      return
    }
    navigate(`/settings/internal_admin/user-inspector`, {
      state: { email: values.email },
    })
    setLoading(false)
  }

  return (
    <Card>
      <div className="ml-6">
        <div className="mt-4">
          <h2 className="mb-4 text-lg  font-semibold">User inspector</h2>
        </div>
        <div className="pb-2">
          <Form
            form={searchForm}
            disabled={loading}
            layout="inline"
            style={{ marginBottom: '1rem', width: '100%' }}
          >
            <Form.Item
              name="email"
              label="User email"
              rules={[{ required: true }]}
            >
              <Input
                className="h-8"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <TailwindButton
                variant="default"
                onClick={() => {
                  void handleSubmitEmail()
                }}
                disabled={inputValue.trim().length === 0}
              >
                Inspect
              </TailwindButton>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Card>
  )
}

export default SettingsUserPermissionsCard
