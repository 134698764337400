import _ from 'lodash'

import { UserInfo } from 'models/user-info'
import { WelcomeScreen, WelcomeScreenType } from 'models/workspace'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'
import { FileType } from 'types/file'

import { MB_BYTES, createAcceptObject } from 'utils/file-utils'
import { AnnotationById, Source, TaskType } from 'utils/task'

import {
  ASSISTANT_OPEN_ENDED_HELP,
  ASSISTANT_WITHOUT_OPEN_ENDED_HELP,
} from './assistant-constants'

export const OPEN_ENDED_QUERY_LIMIT = 100000
export const DOC_QA_QUERY_LIMIT = 4000
export const MIN_QUERY_LENGTH = 9
export const MAX_FILES = 50
export const MAX_FILE_SIZE = 20
export const MAX_FILE_SIZE_MB = MAX_FILE_SIZE * MB_BYTES
export const MAX_ZIP_FILE_SIZE = 50
export const MAX_ZIP_FILE_SIZE_MB = MAX_ZIP_FILE_SIZE * MB_BYTES
export const DISPLAY_FILE_ERROR_COUNT = 10
export const MAX_TOTAL_FILE_SIZE = 100
export const ISSUES_LIST_REDIRECT_FILE_LIMIT = 1

// TODO: move these task type consts to own file, sync w/ server
export const OPEN_ENDED_TASK_TYPE = 'OPEN_ENDED'
export const DOC_QA_TASK_TYPE = 'DOCUMENT_QA'
export const MULTI_DOC_QA_TASK_TYPE = 'MULTI_DOC_QA'
export const CORPUS_QA_TASK_TYPE = 'CORPUS_QA'
export const INTERNET_BROWSING_TASK_TYPE = 'INTERNET_BROWSING'

export const MULTI_DOC_FILE_LIMIT = 5
export const CORPUS_FILE_LIMIT = MAX_FILES

export const HIGHLIGHT_SELECTED_OPACITY = 1
export const HIGHLIGHT_DEFAULT_OPACITY = 0.75

const AllowedFileTypes = [
  FileType.PDF,
  FileType.WORD,
  FileType.WORD_LEGACY,
  FileType.EXCEL,
  FileType.EXCEL_LEGACY,
  FileType.ZIP,
  FileType.EMAIL,
  FileType.TEXT,
  FileType.CSV,
  FileType.POWERPOINT,
  FileType.POWERPOINT_LEGACY,
  FileType.OUTLOOK,
]

export const AssistantFileTypes = createAcceptObject(AllowedFileTypes)

export interface AssistantDocument extends UploadedFile {
  mimeType?: string
  file?: File
  uploadPromise?: Promise<UploadedFile>
}

export const sumFileSizes = async (
  files: AssistantDocument[]
): Promise<number> => {
  const getFileSize = async (file: AssistantDocument) => {
    if (file.file) return file.file.size
    if (!file.url) {
      console.error(`Unable to get file size, assuming 0`)
      return 0
    }

    return fetch(file.url, { method: 'HEAD' })
      .then((response) => {
        const contentLength = response.headers.get('Content-Length')
        return contentLength ? parseInt(contentLength, 10) : 0
      })
      .catch((e: unknown) => {
        console.error(`Error while fetching file size, assuming 0`, e)
        return 0
      })
  }

  const fileSizes = await Promise.all(files.map(getFileSize))
  return fileSizes.reduce((a, b) => a + b, 0)
}

export type HistoryItem = {
  annotations: Maybe<AnnotationById>
  filterIds: Maybe<[]>
  created: string
  documentType: Maybe<string>
  documents: Maybe<AssistantDocument[]> // check typing later
  id: number
  kind: string
  query: string
  response: string
  sources?: Maybe<Source[]>
  status: string
  useCase: Maybe<string>
}

export const isDoneStreaming = (
  response: string,
  isLoading: boolean
): boolean => {
  return !_.isEmpty(response) && !isLoading
}

export const isSourcesEmpty = (sources: Maybe<Source[]>): boolean => {
  // check if every sources content includes Harvey is finding sources
  // if so, then the sources are empty
  return (
    _.isNil(sources) ||
    _.isEmpty(sources) ||
    sources.every((source) => source.text.includes('Harvey is finding sources'))
  )
}

// Copy and Paste below function
export const getHelpPanelTextAssistantV2 = (userInfo: UserInfo): string => {
  let screens: WelcomeScreen[] =
    userInfo.workspace.settings?.welcomeScreens ?? []

  screens =
    screens instanceof Object
      ? Object.entries(screens).map(([, v]) => v as WelcomeScreen)
      : screens

  const content = screens.find((data: any) => {
    return (
      data.type === WelcomeScreenType.HELP_PANEL &&
      (_.isNil(data?.taskType) || data?.taskType === 'ASSISTANT')
    )
  })?.content

  if (!_.isNil(content)) {
    return content
  }

  return ''
}

export const getHelpPanelText = (
  userInfo: UserInfo,
  defaultText?: string
): string => {
  let screens: WelcomeScreen[] =
    userInfo.workspace.settings?.welcomeScreens ?? []

  screens =
    screens instanceof Object
      ? Object.entries(screens).map(([, v]) => v as WelcomeScreen)
      : screens

  const content = screens.find((data: any) => {
    return (
      data.type === WelcomeScreenType.HELP_PANEL &&
      (_.isNil(data?.taskType) || data?.taskType === TaskType.OPEN_ENDED)
    )
  })?.content

  if (!_.isNil(content)) {
    return content
  }

  if (defaultText) {
    return defaultText
  }

  return userInfo.IsBaseUser
    ? ASSISTANT_OPEN_ENDED_HELP
    : ASSISTANT_WITHOUT_OPEN_ENDED_HELP
}

export const ASSISTANT_REDLINES_UPLOADED_METRIC =
  'ui.assistant_redlines_uploaded'
export const ASSISTANT_ISSUES_LIST_REDIRECT_CLICKED_METRIC =
  'ui.assistant_redlines_redirect_clicked'

export const isQueryValid = (query: string, queryLimit: number): boolean => {
  return (
    query.trim().length >= MIN_QUERY_LENGTH && query.trim().length <= queryLimit
  )
}
