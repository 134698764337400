// Function to remove HTML tags for displaying example responses

const htmlToPlainText = (inputHtml: string): string => {
  // Create a new DOMParser
  const parser = new DOMParser()

  // Parse the inputHtml as HTML
  const htmlDoc = parser.parseFromString(inputHtml, 'text/html')

  // Extract the text content
  const plainText = htmlDoc.body.textContent || ''

  return plainText
}

export default htmlToPlainText
