import * as React from 'react'

import * as PopoverPrimitive from '@radix-ui/react-popover'

import { cn } from 'utils/utils'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverAnchor = PopoverPrimitive.Anchor

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    container?: HTMLElement | null
    isAnimationDisabled?: boolean
  }
>(
  (
    {
      className,
      container,
      align = 'center',
      sideOffset = 4,
      isAnimationDisabled = false,
      ...props
    },
    ref
  ) => (
    <PopoverPrimitive.Portal container={container}>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          `z-50 w-72 rounded-md border bg-primary p-4 text-primary shadow-md outline-none ${
            isAnimationDisabled
              ? ''
              : 'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'
          }`,
          className
        )}
        {...props}
      />
    </PopoverPrimitive.Portal>
  )
)
PopoverContent.displayName = PopoverPrimitive.Content.displayName

const PopoverMenuItem = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'ArrowDown') {
      const nextMenuItem = e.currentTarget.nextElementSibling
      if (nextMenuItem) {
        ;(nextMenuItem as HTMLElement).focus()
        e.preventDefault()
      }
    } else if (e.key === 'ArrowUp') {
      const previousMenuItem = e.currentTarget.previousElementSibling
      if (previousMenuItem) {
        ;(previousMenuItem as HTMLElement).focus()
        e.preventDefault()
      }
    }
    if (props.onKeyDown) props.onKeyDown(e)
  }

  return (
    <button
      ref={ref}
      className={cn(
        'relative flex w-full cursor-pointer items-center space-x-2 rounded-sm px-2 py-1.5 text-left text-sm outline-none transition-colors hover:bg-secondary-hover hover:text-primary focus:bg-secondary-hover data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        className
      )}
      {...props}
      onKeyDown={handleKeyDown}
    />
  )
})
PopoverMenuItem.displayName = 'PopoverMenuItem'

export {
  Popover,
  PopoverAnchor,
  PopoverTrigger,
  PopoverContent,
  PopoverMenuItem,
}
