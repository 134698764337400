import React from 'react'

import { cn } from 'utils/utils'

interface SkeletonProps {
  className?: string
  rowHeight?: string
  rows?: number
  rowSpacing?: string
  hasDarkBackground?: boolean
}

export const SkeletonBlock: React.FC<{
  className?: string
  hasDarkBackground?: boolean
}> = ({ className = '', hasDarkBackground = false }) => (
  <div
    className={cn(
      `h-full w-full animate-pulse rounded bg-neutral-100`,
      className,
      {
        'bg-neutral-300': hasDarkBackground,
      }
    )}
  />
)

export const Skeleton: React.FC<SkeletonProps> = ({
  className,
  rowHeight = 'h-12',
  rows = 4,
  rowSpacing = 'gap-y-4',
  hasDarkBackground = false,
}) => {
  const MAX_ROWS_BEFORE_REPEAT = 4

  const repeat = Math.max(Math.ceil(rows / MAX_ROWS_BEFORE_REPEAT), 1)
  const repeatArr = Array(repeat).fill(null)

  const shouldRenderBlock = (idx: number, notch: number) => {
    return (idx + 1) * MAX_ROWS_BEFORE_REPEAT - rows <= notch
  }

  return (
    <div className={cn('flex flex-col', rowSpacing, className)}>
      {repeatArr.map((_, idx) => (
        <div
          className={cn('grid h-full w-full grid-cols-4 gap-x-4', rowSpacing)}
          key={idx}
        >
          {/* Row 1 */}
          <SkeletonBlock
            className={cn('col-span-2', rowHeight)}
            hasDarkBackground={hasDarkBackground}
          />
          <SkeletonBlock
            className={rowHeight}
            hasDarkBackground={hasDarkBackground}
          />
          <SkeletonBlock className={cn(' bg-neutral-200', rowHeight)} />

          {/* Row 2 */}
          {shouldRenderBlock(idx, 2) && (
            <>
              <SkeletonBlock
                className={rowHeight}
                hasDarkBackground={hasDarkBackground}
              />
              <SkeletonBlock
                className={cn('col-span-3 bg-neutral-200', rowHeight)}
              />
            </>
          )}

          {/* Row 3 */}
          {shouldRenderBlock(idx, 1) && (
            <>
              <SkeletonBlock className={cn('bg-neutral-200', rowHeight)} />
              <SkeletonBlock
                className={cn('col-span-2', rowHeight)}
                hasDarkBackground={hasDarkBackground}
              />
              <SkeletonBlock
                className={rowHeight}
                hasDarkBackground={hasDarkBackground}
              />
            </>
          )}

          {/* Row 4 */}
          {shouldRenderBlock(idx, 0) && (
            <>
              <SkeletonBlock
                className={cn('col-span-2', rowHeight)}
                hasDarkBackground={hasDarkBackground}
              />
              <SkeletonBlock className={cn('bg-neutral-200', rowHeight)} />

              <SkeletonBlock
                className={rowHeight}
                hasDarkBackground={hasDarkBackground}
              />
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default Skeleton
