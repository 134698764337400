import React from 'react'

import _ from 'lodash'

import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { useResearchStore } from 'components/research/research-store'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'

interface JurisdictionFilterProps {
  taxonomy: ResearchFilter[]
  isOpen: boolean
}

enum TopLevelJurisdiction {
  Federal = 'federal-courts',
  State = 'state-courts',
}

export const JurisdictionFilter: React.FC<JurisdictionFilterProps> = ({
  taxonomy,
  isOpen,
}) => {
  const { setSelectedFilters, isLoading, selectedFilters } = useResearchStore()
  const defaultValue = selectedFilters[0]?.id
  if (!isOpen) return
  if (_.isEmpty(taxonomy)) return

  const federal = taxonomy.filter(isFederal)
  const state = taxonomy.filter(isState)

  // hack because flex-wrap flex-col is broken on firefox
  const statesSplitInFour = _.chunk(state, Math.ceil(state.length / 4))

  return (
    <RadioGroup className="block" value={defaultValue}>
      <div className="mb-4 pl-1">
        <h3 className="text-lg font-semibold">Jurisdiction</h3>
        <p className="text-sm text-muted">
          Select a jurisdiction for Harvey to research
        </p>
      </div>
      <div className="flex space-x-4">
        <div className="rounded-lg bg-neutral-50 px-4 py-3">
          <h3 className="mb-2 text-sm font-semibold">State courts</h3>
          <div className="flex space-x-3">
            {statesSplitInFour.map((group, groupIdx) => (
              <div key={groupIdx} className="space-y-1">
                {group.map((child, idx) => (
                  <RadioGroupItem
                    key={idx}
                    value={child.id}
                    id={`state-checkbox-${groupIdx}-${idx}`}
                    onClick={() => setSelectedFilters([child])}
                    disabled={isLoading}
                    label={child.name}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="rounded-lg bg-neutral-50 px-4 py-3">
          <h3 className="mb-2 text-sm font-semibold">Federal courts</h3>
          <div className="space-y-1 px-2">
            {federal.map((child, idx) => (
              <div className="mr-3 flex items-center space-x-2" key={idx}>
                <RadioGroupItem
                  value={child.id}
                  onClick={() => setSelectedFilters([child])}
                  disabled={isLoading}
                  id={`federal-checkbox-${idx}`}
                />
                <label
                  className="line-clamp-1 text-sm"
                  htmlFor={`federal-checkbox-${idx}`}
                >
                  {child.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </RadioGroup>
  )
}

export const isFederal = (filter: ResearchFilter): boolean => {
  return filter.id.includes(TopLevelJurisdiction.Federal)
}

export const isState = (filter: ResearchFilter): boolean => {
  return filter.id.includes(TopLevelJurisdiction.State)
}
