import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import _ from 'lodash'
import { ChevronRight } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { cn } from 'utils/utils'

import {
  computeIsDisabled,
  getAllChildrenDeep,
  getNumSelectedChildren,
  hasAllSiblingsSelected,
  hasSelectedChild,
} from 'components/research/research-helpers'
import { useResearchStore } from 'components/research/research-store'
import { Checkbox } from 'components/ui/checkbox'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface ExplorerItemProps {
  taxonomy: ResearchFilter[]
  filter: ResearchFilter
  depth: number
}

const ExplorerItem: React.FC<ExplorerItemProps> = ({
  filter,
  depth,
  taxonomy,
}) => {
  // State
  const [
    openFilters,
    setOpenFilters,
    selectedFilters,
    setSelectedFilters,
    isLoading,
  ] = useResearchStore(
    useShallow((s) => [
      s.openFilters,
      s.setOpenFilters,
      s.selectedFilters,
      s.setSelectedFilters,
      s.isLoading,
    ])
  )

  const [isHovered, setIsHovered] = useState(false)

  const { area } = useParams<{ area: ResearchArea }>()

  // Constants
  const children = getAllChildrenDeep(filter)
  const isOpen = !_.isNil(openFilters) && filter.id === openFilters[depth]?.id
  const isChecked = selectedFilters.some((n) => n.id === filter.id)
  const parentFilter = openFilters[depth - 1]
  const isIndeterminate =
    hasSelectedChild(filter, selectedFilters) && !isChecked
  const isDisabled = computeIsDisabled({
    filter,
    selectedFilters,
    openFilters,
    taxonomy,
  })

  const isAllSiblingsChecked = hasAllSiblingsSelected(
    filter,
    selectedFilters,
    parentFilter
  )

  const numChildrenSelected = getNumSelectedChildren(filter, selectedFilters)

  // Handlers
  const handleClick = () => {
    const newSelectedFilters = [...openFilters]
    newSelectedFilters[depth] = filter
    newSelectedFilters.splice(depth + 1)
    setOpenFilters(newSelectedFilters)
  }

  const handleCheckedChange = (checked: boolean) => {
    if (checked) {
      handleChecked()
    } else {
      handleUnchecked()
    }
  }

  const handleChecked = () => {
    const selectedFiltersToSet = [...selectedFilters, filter, ...children]
    if (isAllSiblingsChecked && parentFilter) {
      selectedFiltersToSet.push(parentFilter)
    }
    setSelectedFilters(selectedFiltersToSet)
  }

  const handleUnchecked = () => {
    const selectedFiltersToSet = selectedFilters.filter((n) => {
      const isFilterOrChild =
        n.id === filter.id ||
        children.some((c) => c.id === n.id) ||
        (parentFilter && parentFilter.id === n.id)
      return !isFilterOrChild
    })
    setSelectedFilters(selectedFiltersToSet)
  }

  const maybeRenderTooltip = (text: string) => {
    if (text.length > 15) {
      return (
        <Tooltip delayDuration={300}>
          <TooltipTrigger asChild>
            <p className="line-clamp-1 w-fit text-sm">{text}</p>
          </TooltipTrigger>
          <TooltipContent>{text}</TooltipContent>
        </Tooltip>
      )
    } else {
      return <p className="line-clamp-1 text-sm">{text}</p>
    }
  }

  // Render
  return (
    <Tooltip>
      <TooltipTrigger className="block" asChild>
        <div
          role="button"
          tabIndex={isDisabled ? -1 : 0} // Only allow tab focus when not disabled
          onKeyPress={handleClick}
          className={cn(
            'mx-4 flex items-center justify-between space-x-2 rounded px-1.5 py-1.5 text-left text ring-inset focus:outline-none',
            {
              'focus:ring focus:ring-ring': !isDisabled && children.length > 0, // Apply focus ring only if not disabled and children are present
              'bg-muted': isOpen,
              'opacity-50': isDisabled,
            }
          )}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={(e) => {
            if (isDisabled) {
              e.stopPropagation()
              return
            }
            handleClick()
          }}
          data-testid={`research-filter-${filter.id}`}
        >
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={isChecked || isIndeterminate}
              isIndeterminate={isIndeterminate}
              onCheckedChange={handleCheckedChange}
              disabled={isDisabled || isLoading}
            />

            {maybeRenderTooltip(filter.name)}
          </div>
          <div className="flex shrink-0 items-center">
            {numChildrenSelected > 0 && (
              <span className="shrink-0 text-nowrap text-xs text-muted">
                {numChildrenSelected} Selected
              </span>
            )}
            {area === ResearchArea.MEMOS &&
              depth === 0 &&
              numChildrenSelected === 0 && (
                <span className="text-xs text-muted">Select at least 1</span>
              )}
            {filter.children.length > 0 && (
              <ChevronRight
                className="ml-1 inline-block shrink-0"
                data-testid="research-filter-expand-btn"
                size={16}
              />
            )}
          </div>
        </div>
      </TooltipTrigger>
      {isHovered && isDisabled && (
        <TooltipContent className="w-full">
          This filter cannot be selected
        </TooltipContent>
      )}
    </Tooltip>
  )
}

export default ExplorerItem
