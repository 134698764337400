import React, { useCallback } from 'react'

import _ from 'lodash'

import { fetchRoleByIdOrPk } from 'models/roles'

import { useNavigateWithQueryParams } from 'utils/routing'
import { displayErrorMessage } from 'utils/toast'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Input } from 'components/ui/input'

const RoleInspector = () => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const [roleId, setRoleId] = React.useState<string>('')

  const handleSubmit = useCallback(async () => {
    const cleanRoleId = roleId.trim()
    if (_.isEmpty(cleanRoleId)) {
      displayErrorMessage('Role ID cannot be empty')
      return
    }
    const role = await fetchRoleByIdOrPk(cleanRoleId)
    if (_.isNil(role)) {
      displayErrorMessage(`Role ${roleId} not found`)
      return
    }
    navigate(`${BaseAppPath.Settings}/internal_admin/role-inspector`, {
      state: {
        roleId: cleanRoleId,
      },
    })
  }, [roleId, navigate])

  if (!userInfo.IsInternalAdminReader) return <></>

  return (
    <div>
      <Card>
        <div className="ml-6">
          <div className="mt-4">
            <h2 className="mb-4 text-lg  font-semibold">Role inspector</h2>
          </div>
          <div className="mb-4 flex items-center space-x-3 p-2">
            <p className="text-muted">Role ID:</p>
            <Input
              className="h-8 w-48"
              value={roleId}
              onChange={(e) => setRoleId(e.target.value)}
            />
            <Button
              variant="default"
              className="ml-2"
              onClick={handleSubmit}
              disabled={roleId.trim().length === 0}
            >
              Inspect
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default RoleInspector
