import * as Sentry from '@sentry/browser'
import { useQuery } from '@tanstack/react-query'

import { FetchUserWorkspaces } from 'models/workspace'
import Services from 'services'

import { HarvQueryKeyPrefix } from './all-query-keys'

export const useUserWorkspacesQuery = () => {
  const { isPending, error, data } = useQuery({
    queryKey: [HarvQueryKeyPrefix.UserWorkspacesQuery],
    queryFn: () => FetchUserWorkspaces(),
    refetchOnWindowFocus: false,
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { data, isPending, error }
}
