import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'
import { AnnotationById, Source } from 'types/task'

import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'
import { HarveySocketCompletionStatus } from 'utils/use-harvey-socket-utils'

import { FeedbackResult } from 'components/common/feedback'

interface State {
  query: string
  response: string
  annotations: AnnotationById
  sources: Source[]
  isLoading: boolean
  headerText: string
  queryId: string
  activeDocument: Maybe<UploadedFile>
  feedback: FeedbackResult | null
  progress: number
  completionStatus: HarveySocketCompletionStatus | null
  hasRedlinesDocument: boolean
  metadata?: Record<string, any>
  issuesTags: string[]
}

interface Action {
  setTask: HarveySocketSetter
  setActiveDocument: (document: UploadedFile | null) => void
  setFeedback: (feedback: FeedbackResult | null) => void
  reset: () => void
  setHasRedlinesDocument: (isRedlinesDocument: boolean) => void
  setIssuesTags: (tags: string[]) => void
}

const initialState: State = {
  query: '',
  response: '',
  annotations: {},
  sources: [],
  isLoading: false,
  headerText: '',
  queryId: '',
  activeDocument: null,
  feedback: null,
  progress: 0,
  completionStatus: null,
  hasRedlinesDocument: true,
  metadata: {},
  issuesTags: [],
}

export const useRedlinesStore = create(
  devtools(
    immer<State & Action>((set) => ({
      ...initialState,
      reset: () => {
        set(() => ({
          ...initialState,
        }))
      },
      setFeedback: (feedback: FeedbackResult | null) => {
        set(() => ({
          feedback,
        }))
      },
      setTask: (socketState: Partial<HarveySocketTask>) => {
        set(() => ({
          ...socketState,
        }))
      },
      setActiveDocument: (document: UploadedFile | null) => {
        set(() => ({
          activeDocument: document,
        }))
      },
      setHasRedlinesDocument: (hasRedlinesDocument: boolean) => {
        set(() => ({
          hasRedlinesDocument,
        }))
      },
      setIssuesTags: (tags: string[]) => {
        set(() => ({
          issuesTags: tags,
        }))
      },
    }))
  )
)
