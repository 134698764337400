import React from 'react'

import { cn } from 'utils/utils'

interface HarveyLogoProps {
  className?: string
}

const HarveyLogo: React.FC<HarveyLogoProps> = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 28"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      className={cn('fill-primary-inverse', className)}
    >
      <path
        fill="#191816"
        d="M32 28H18l4-4v-8.4H10V24l4 4H0l4-4V4L0 0h14l-4 4v7.6h12V4l-4-4h14l-4 4v20l4 4Z"
      />
    </svg>
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 700"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      className={cn('fill-primary-inverse', className)}
    >
      <g clipPath="url(#a)">
        <path
          fill="#191816"
          d="M800 700H450l100-100V390H250v210l100 100H0l100-100V100L0 0h350L250 100v190h300V100L450 0h350L700 100v500l100 100Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h800v700H0z" />
        </clipPath>
      </defs>
    </svg>
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 510 152"
      preserveAspectRatio="xMidYMid meet"
      className={cn('fill-primary-inverse', className)}
    >
      <g>
        <path d="M0 115.315V0h15.66v49.604h57.165V0h15.639v115.315H72.825V64.127H15.66v51.209H0v-.021ZM108.902 93.621c0-14.36 10.697-23.278 29.061-24.72l24.587-1.91v-2.234c0-13.081-7.83-17.713-18.364-17.713-12.609 0-19.808 5.586-19.808 14.991h-13.096c0-16.27 13.422-26.955 33.535-26.955 20.112 0 32.416 10.217 32.416 31.261v48.974h-12.771l-1.607-12.594c-3.986 8.938-14.683 14.524-27.312 14.524-16.757 0-26.661-9.405-26.661-23.603l.02-.02Zm53.811-11.964v-4.144l-19.971 1.605c-13.259 1.28-18.689 6.378-18.689 14.036 0 8.288 6.06 12.431 15.801 12.431 14.216 0 22.838-9.242 22.838-23.928h.021ZM242.697 50.396h-6.711c-14.216 0-23.001 8.612-23.001 23.603v41.316h-15.008V37.477h14.052l.956 11.802c3.193-8.125 11.022-13.549 21.719-13.549 2.705 0 4.942.325 7.993.955v13.71ZM249.57 37.315h15.965l17.082 43.55c2.705 7.333 4.942 13.874 6.223 18.667 1.281-5.098 3.823-11.801 6.549-18.667l17.408-43.55h15.659l-32.579 78h-14.846l-31.461-78ZM331.791 76.396c0-24.395 15.496-41.316 38.172-41.316 22.675 0 36.728 15.316 36.728 38.432v5.586l-60.359.162c1.118 16.433 9.741 25.513 24.587 25.513 11.652 0 19.319-4.794 21.882-13.711h14.052c-3.823 16.758-16.92 26.163-36.239 26.163-23.001 0-38.803-16.596-38.803-40.829h-.02Zm14.846-7.17h45.025c0-12.92-8.46-21.532-21.72-21.532-13.259 0-21.394 7.82-23.305 21.532ZM487.162 106.073c0-6.216 5.267-11.334 11.49-11.334S510 99.837 510 106.073s-5.267 11.172-11.348 11.172c-6.08 0-11.49-5.098-11.49-11.172ZM444.292 84.034l-16.859-46.72h-15.801l25.421 66.382 1.301-3.534 5.938-16.128ZM473.272 37.315h15.497L445.981 152h-15.497l42.788-114.685Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h510v152H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HarveyLogo
