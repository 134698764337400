import React from 'react'

import { Row, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { ArrowRight } from 'lucide-react'

import { HistoryItem } from 'types/history'

import {
  calculateRouteForEvent,
  useNavigateWithQueryParams,
} from 'utils/routing'
import { TaskType } from 'utils/task'

import { useAssistantSharedQueriesHistory } from 'components/assistant-v2/hooks/use-assistant-shared-queries'
import { REMOVE_PARAMS } from 'components/assistant-v2/utils/assistant-helpers'
import { getAssistantSharingColumns } from 'components/assistant-v2/utils/assistant-sharing-columns'
import { useAuthUser } from 'components/common/auth-context'
import { DataTable } from 'components/ui/data-table/data-table'
import Skeleton from 'components/ui/skeleton'
import { TextLink } from 'components/ui/text-link'

interface AssistantSharedProps {
  isPreview?: boolean
  pageSize?: number
}

const AssistantSharedTable: React.FC<AssistantSharedProps> = ({
  isPreview = false,
  pageSize = isPreview ? 10 : 256,
}) => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const { data: historyItemData, isLoading } = useAssistantSharedQueriesHistory(
    true,
    { pageSize, offset: 0 }
  )
  const sharedHistoryItems = historyItemData || []

  const columns = getAssistantSharingColumns()

  const table = useReactTable<HistoryItem>({
    columns,
    data: sharedHistoryItems,
    getCoreRowModel: getCoreRowModel(),
  })

  const getRowHref = (row: Row<HistoryItem>) => {
    return calculateRouteForEvent(
      { id: Number(row.original.id), kind: row.original.kind as TaskType },
      userInfo
    )
  }

  const handleRowClick = (row: Row<HistoryItem>) => {
    navigate(getRowHref(row))
  }

  if (isLoading && isPreview) {
    return <Skeleton className="py-4" rows={3} rowHeight="h-4" />
  }

  if (!isLoading && !sharedHistoryItems.length) {
    return (
      <p className="pt-6 text-center text-muted">
        Recently shared queries will appear here
      </p>
    )
  }

  return (
    <>
      <DataTable
        hideTableBorder={isPreview}
        hrefForRow={getRowHref}
        hrefOptions={() => ({ removeParams: REMOVE_PARAMS })}
        onRowClick={isPreview ? handleRowClick : undefined}
        table={table}
        tableCellClassName={
          isPreview ? 'px-1 py-2 align-middle text-xs text-primary' : undefined
        }
        isLoading={isLoading && !isPreview}
        useVirtual={!isPreview && sharedHistoryItems.length > 15}
        virtualEstimateSize={65}
        marginTop={!isPreview ? 48 : undefined}
      />
      {isPreview && sharedHistoryItems.length === pageSize && (
        <TextLink
          className="mt-2 inline-block font-semibold"
          label="View all shared"
          href="/assistant/shared"
          trailingIcon={<ArrowRight />}
        />
      )}
    </>
  )
}

export default AssistantSharedTable
