import React from 'react'

import _ from 'lodash'
import { Instance } from 'pspdfkit'

import { Source } from 'types/task'

import { applyPageOnlyAnnotations } from 'components/assistant/pspdfkit-helpers'
import { PdfViewerSheet } from 'components/common/pdf-viewer/pdf-viewer-sheet'

import { useResearchStore } from './research-store'

export const onDocumentSourceClick = (
  source: Source,
  setActiveDocument: (obj: any) => void
) => {
  if (!source.documentUrl) return
  const file = {
    name: source.documentName!,
    url: source.documentUrl,
    path: '',
  }
  setActiveDocument({
    file,
    source,
  })
}

export const ResearchSheet: React.FC = () => {
  const { sources, activeDocument, setActiveDocument } = useResearchStore()
  const pdfkitSources = (sources || []).filter(
    (s) => s.documentUrl && !_.isNil(s.page) && !_.isEmpty(s.annotations)
  )

  const applyAnnotations = async (instance: Instance) => {
    if (!activeDocument) {
      return
    }
    void applyPageOnlyAnnotations(
      activeDocument.file,
      sources,
      instance,
      activeDocument.source
    )
  }

  return (
    <PdfViewerSheet
      document={activeDocument?.file ?? null}
      applyAnnotations={applyAnnotations}
      page={activeDocument?.source.page ?? 0}
      showSheet={!!activeDocument}
      setShowSheet={() => setActiveDocument(null)}
      sources={pdfkitSources}
    />
  )
}
