import React, { useCallback } from 'react'
import { useUnmount } from 'react-use'

import {
  ColumnDataType,
  GenerateNNResponseProps,
  QueryQuestions,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

import DisplayDropdown from './display-dropdown'
import ErrorsPopover from './errors-popover'
import FilterDropdown from './filter-dropdown'

const VaultDataGridHeader = ({
  columnIdToHeader,
  columnIdToDataType,
  generateNNResponse,
}: {
  columnIdToHeader: (columnId: string, question: QueryQuestions) => string
  columnIdToDataType: (columnId: string) => ColumnDataType
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const gridApi = useVaultStore((state) => state.gridApi)

  // filter dropdown
  const setIsShowingLongResponses = useVaultDataGridFilterStore(
    (state) => state.setIsShowingLongResponses
  )
  const resetFilterState = useVaultDataGridFilterStore(
    (state) => state.resetFilterState
  )

  // clean-up selected rows
  const clearSelectedRows = useVaultDataGridFilterStore(
    (state) => state.clearSelectedRows
  )

  const clearFilters = useCallback(() => {
    resetFilterState()
    gridApi?.setFilterModel(null)
  }, [gridApi, resetFilterState])

  useUnmount(() => {
    resetFilterState()
    setIsShowingLongResponses(false)
    clearSelectedRows()
  })

  // setting the min-h to 49 to align with the pdf-viewer header height
  return (
    <div className="flex max-h-[49px] min-h-[49px] w-full shrink-0 items-center justify-between gap-2 border-b px-4 py-2">
      <div className="flex items-center gap-2">
        <DisplayDropdown clearFilters={clearFilters} />
        <FilterDropdown
          clearFilters={clearFilters}
          columnIdToHeader={columnIdToHeader}
          columnIdToDataType={columnIdToDataType}
        />
      </div>
      <ErrorsPopover generateNNResponse={generateNNResponse} />
    </div>
  )
}

export default VaultDataGridHeader
