/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Research
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Filter IDs for tax research area
 * @export
 */
export const TaxCorpusFilterIDs = {
    UK_TAX: 'uk_tax',
    JAPAN_TAX: 'japan_tax',
    NETHERLANDS_TAX: 'netherlands_tax',
    INDIA_TAX: 'india_tax',
    AUSTRALIA_TAX: 'australia_tax',
    IRELAND_TAX: 'ireland_tax',
    SWEDEN_TAX: 'sweden_tax',
    SWITZERLAND_TAX: 'switzerland_tax',
    WWTS: 'wwts',
    PILLAR_TWO_TAX: 'pillar_two_tax'
} as const;
export type TaxCorpusFilterIDs = typeof TaxCorpusFilterIDs[keyof typeof TaxCorpusFilterIDs];


export function instanceOfTaxCorpusFilterIDs(value: any): boolean {
    for (const key in TaxCorpusFilterIDs) {
        if (Object.prototype.hasOwnProperty.call(TaxCorpusFilterIDs, key)) {
            if (TaxCorpusFilterIDs[key as keyof typeof TaxCorpusFilterIDs] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TaxCorpusFilterIDsFromJSON(json: any): TaxCorpusFilterIDs {
    return TaxCorpusFilterIDsFromJSONTyped(json, false);
}

export function TaxCorpusFilterIDsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxCorpusFilterIDs {
    return json as TaxCorpusFilterIDs;
}

export function TaxCorpusFilterIDsToJSON(value?: TaxCorpusFilterIDs | null): any {
    return value as any;
}

