import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  WorkspaceClientMattersStore,
  fetchWorkspaceClientMatters,
} from 'models/client-matters'
import { prefetchWrapper } from 'models/queries/lib'

import { HarvQueryKeyPrefix } from './all-query-keys'

export const useClientMattersQuery = (isEnabled: boolean = true) => {
  const clientMattersFetcher = async () => await fetchWorkspaceClientMatters()

  const {
    isPending: isCmPending,
    error: cmError,
    data: _cmData,
    isFetching: isCmFetching,
  } = useQuery({
    queryKey: [HarvQueryKeyPrefix.ClientMatters],
    queryFn: clientMattersFetcher,
    enabled: isEnabled,
  })

  const cmData =
    _cmData || ({ clientMatters: [] } as WorkspaceClientMattersStore)

  return { cmData, isCmPending, isCmFetching, cmError }
}

export const useClientMattersPrefetch = (isEnabled?: boolean) => {
  const queryClient = useQueryClient()
  const clientMattersFetcher = async () => fetchWorkspaceClientMatters

  if (isEnabled) {
    prefetchWrapper(queryClient, {
      queryKey: [HarvQueryKeyPrefix.ClientMatters],
      queryFn: clientMattersFetcher,
    })
  }

  return
}
