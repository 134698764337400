import React from 'react'
import { DateRange } from 'react-day-picker'
import useState from 'react-usestateref'

import _ from 'lodash'
import { Download } from 'lucide-react'

import {
  DownloadWorkspaceHistory,
  Export,
} from 'models/fetchers/history-fetcher'
import { type Workspace } from 'models/workspace'

import {
  convertExactSameDateAndHourFromLocalToUTC,
  getOneYearPrior,
  Timezone,
} from 'utils/date-utils'
import { displayErrorMessage } from 'utils/toast'
import { download } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'

import HistoryExportDialog from './export-dialog'

interface ExportButtonProps {
  type: Export
  workspace: Workspace
  size?: 'sm' | 'default' | 'lg'
  className?: string
}

const ExportButton: React.FC<ExportButtonProps> = ({ type, workspace }) => {
  const userInfo = useAuthUser()

  const [isExporting, setIsExporting] = useState<boolean>(false)
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >({
    from: getOneYearPrior(),
    to: new Date(),
  })

  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [selectedTimezone, setSelectedTimezone] = useState<string>(userTz)
  const timezones = [userTz]
  if (userTz !== Timezone.UTC) {
    timezones.push(Timezone.UTC)
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const onExport = async (): Promise<void> => {
    setIsExporting(true)
    try {
      let from = selectedDateRange?.from
      if (_.isNil(from)) {
        displayErrorMessage('Please select a start date')
        return
      }
      from.setHours(0, 0, 0, 0)
      let to = selectedDateRange?.to || new Date(from) // need to avoid making a shallow copy
      // can't set to 999 ms because it rounds to next day for some reason during offset conversion
      to.setHours(23, 59, 59)
      if (selectedTimezone === Timezone.UTC) {
        from = convertExactSameDateAndHourFromLocalToUTC(from)
        to = convertExactSameDateAndHourFromLocalToUTC(to)
      }
      const url = await DownloadWorkspaceHistory({
        exportType: type,
        workspaceSlug: workspace.slug,
        events: [],
        userTz: selectedTimezone,
        dateRange: {
          from: from,
          to: to,
        },
      })
      download(url)
    } catch (error) {
      displayErrorMessage(`Failed to export ${type}`)
    } finally {
      setIsExporting(false)
    }
  }

  return (
    <>
      <HistoryExportDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        dialogTitle={`Export ${type}`}
        dialogDescription="Please select the following options for export."
        onExport={onExport}
        dateRangePickerProps={{
          selectedDateRange,
          setSelectedDateRange,
        }}
        exportDisabled={!selectedDateRange?.from && !selectedDateRange?.to}
        timezones={userInfo.canSelectTimezoneOnExport ? timezones : undefined}
        selectedTimezone={
          userInfo.canSelectTimezoneOnExport ? selectedTimezone : undefined
        }
        setSelectedTimezone={
          userInfo.canSelectTimezoneOnExport ? setSelectedTimezone : undefined
        }
      />
      <Button
        variant="outline"
        disabled={isExporting}
        onClick={() => {
          setIsDialogOpen(true)
        }}
        data-testid={`export-${type}--button`}
      >
        {isExporting ? (
          <p className="flex items-center">
            <Spinner className="h-3 w-3" /> Exporting
          </p>
        ) : (
          <p className="flex items-center">
            <Download className="mr-1.5 h-4 w-4" />
            Export {type}
          </p>
        )}
      </Button>
    </>
  )
}

export default ExportButton
