import React, { useEffect } from 'react'

import { SentryAssignees, setOrClearSentryAssignee } from 'utils/sentry'

interface SentryWrapperProps {
  children: React.ReactNode
  sentryAssignee?: SentryAssignees
}

/**
 * Wraps the children in a wrapper that only sets or clears the Sentry assignee tag
 */
const SentryAssigneeWrapper: React.FC<SentryWrapperProps> = ({
  children,
  sentryAssignee,
}) => {
  useEffect(() => setOrClearSentryAssignee(sentryAssignee), [sentryAssignee])

  return children
}

export default SentryAssigneeWrapper
