import React from 'react'

import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'

type Props = {
  close: () => void
  onSourceClick: (id: string) => void
  title: string
  markdownContent: string
}

export const TranscriptsTopicView: React.FC<Props> = ({
  close,
  onSourceClick,
  title,
  markdownContent,
}) => {
  const getHrvyInfoMetadata = (id: string) => {
    return {
      onClick: () => onSourceClick(id),
    }
  }

  return (
    <div className="space-y-2">
      <div className="flex items-baseline">
        <p className="text-lg font-semibold">
          <span className="mr-2">Theme</span>
          <span className="font-normal text-muted">{title}</span>
        </p>
        <Button
          variant="text"
          onClick={close}
          className="shrink-0 text-blue-500"
        >
          View all themes
        </Button>
      </div>

      <Markdown
        getHrvyInfoMetadata={getHrvyInfoMetadata}
        content={markdownContent}
      />
    </div>
  )
}
