// File to incorporate any wrappers we create around the react query library, basically
import {
  type QueryClient,
  type FetchQueryOptions,
  FetchInfiniteQueryOptions,
} from '@tanstack/react-query'
import _ from 'lodash'

/**
 * This prefetches a query key _only_ if the query key is not yet present in the cache at all
 *
 * @remark
 * Why do we have to pass in the query client?
 * Because we get the query client from queryClient = useQueryClient(), which is a *hook*, and so we cannot be calling the hook inside of conditional logic
 * (react has to render the same number of hooks every time).  We can wrap queryClient.prefetchQuery in a conditional because it is not a hook.
 * @see https://github.com/TanStack/query/discussions/4791?sort=top
 *
 */
const prefetchWrapper = (
  queryClient: QueryClient,
  fetchQueryOptions: FetchQueryOptions,
  onlyIfEmpty: boolean = false
) => {
  // Get the data for the query key
  const data = queryClient.getQueryData(fetchQueryOptions.queryKey)
  // check if onlyIfEmpty is true and if the data is *not* undefined, so not empty
  if (onlyIfEmpty && !_.isUndefined(data)) {
    return
  } // If data is not yet in the cache OR if we have onlyIfEmpty turned off, we prefetch to fill the cache
  else {
    queryClient.prefetchQuery(fetchQueryOptions)
  }
}

const prefetchInfiniteWrapper = (
  queryClient: QueryClient,
  fetchInfiniteQueryOptions: FetchInfiniteQueryOptions,
  onlyIfEmpty: boolean = false
) => {
  // Get the data for the query key
  const data = queryClient.getQueryData(fetchInfiniteQueryOptions.queryKey)
  // check if onlyIfEmpty is true and if the data is *not* undefined, so not empty
  if (onlyIfEmpty && !_.isUndefined(data)) {
    return
  } // If data is not yet in the cache OR if we have onlyIfEmpty turned off, we prefetch to fill the cache
  else {
    queryClient.prefetchInfiniteQuery(fetchInfiniteQueryOptions)
  }
}

// exports
export { prefetchWrapper, prefetchInfiniteWrapper }
