/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Streaming Protocol (via websockets)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Annotation } from './Annotation';
import {
    instanceOfAnnotation,
    AnnotationFromJSON,
    AnnotationFromJSONTyped,
    AnnotationToJSON,
} from './Annotation';
import type { Source } from './Source';
import {
    instanceOfSource,
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
} from './Source';

/**
 * @type SocketMessageResponseAnnotationsValue
 * 
 * @export
 */
export type SocketMessageResponseAnnotationsValue = Annotation | Source;

export function SocketMessageResponseAnnotationsValueFromJSON(json: any): SocketMessageResponseAnnotationsValue {
    return SocketMessageResponseAnnotationsValueFromJSONTyped(json, false);
}

export function SocketMessageResponseAnnotationsValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocketMessageResponseAnnotationsValue {
    if (json == null) {
        return json;
    }
    if (instanceOfAnnotation(json)) {
        return AnnotationFromJSONTyped(json, true);
    }
    if (instanceOfSource(json)) {
        return SourceFromJSONTyped(json, true);
    }

    return {} as any;
}

export function SocketMessageResponseAnnotationsValueToJSON(value?: SocketMessageResponseAnnotationsValue | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfAnnotation(value)) {
        return AnnotationToJSON(value as Annotation);
    }
    if (instanceOfSource(value)) {
        return SourceToJSON(value as Source);
    }

    return {};
}

