import React from 'react'
import { useParams } from 'react-router-dom'

import { isNil } from 'lodash'

import { getSourceClicked } from 'utils/source'
import { Source } from 'utils/task'

import CitationPopover from 'components/common/citation/citation-popover'
import Response from 'components/common/response/response'
import { MAX_TOKEN_LIMIT_MESSAGE } from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'

export const VAULT_QA_HELP = `<h4>About</h4>
<p class="ql-align-justify">Harvey will answer questions or generate new content based on files in this folder.</p>`

interface VaultQueryResponseProps {
  sendCancelRequest: () => void
  onDocumentSourceClick: (source: Source) => void
}

const VaultQueryResponse: React.FC<VaultQueryResponseProps> = ({
  sendCancelRequest,
  onDocumentSourceClick,
}) => {
  const { queryId } = useParams()
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const isFromHistory = queryIdToState[queryId!]?.isFromHistory || false
  const response = queryIdToState[queryId!]?.response || ''
  const headerText = queryIdToState[queryId!]?.headerText || ''
  const isLoading = queryIdToState[queryId!]?.isLoading || false
  const annotations = queryIdToState[queryId!]?.annotations || {}
  const maxTokenLimitReached =
    queryIdToState[queryId!]?.maxTokenLimitReached || false
  const progress = queryIdToState[queryId!]?.progress || 0

  const isLoadingResponse = !!(isLoading && headerText)
  const title = response
    ? 'Response'
    : isLoadingResponse
    ? ''
    : 'Getting started'

  const getHrvyInfoMetadata = (identifier: string) => {
    const source = getSourceClicked(identifier, annotations)

    if (isNil(source)) {
      return
    }

    const hoverContent = (
      <CitationPopover
        source={source}
        onClick={() => onDocumentSourceClick(source)}
      />
    )

    return { hoverContent, onClick: () => onDocumentSourceClick(source) }
  }

  return (
    <Response
      headerText={headerText}
      handleCancel={isFromHistory ? undefined : sendCancelRequest}
      markdown={
        maxTokenLimitReached ? response + MAX_TOKEN_LIMIT_MESSAGE : response
      }
      isLoading={isLoadingResponse}
      progress={progress}
      title={title}
      emptyStateText={VAULT_QA_HELP}
      key={queryId}
      skeleton="short"
      disableSetExample
      getHrvyInfoMetadata={getHrvyInfoMetadata}
    />
  )
}

export default VaultQueryResponse
