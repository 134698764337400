import { useQuery } from '@tanstack/react-query'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'

import { fetchRecentSharedAssistantQueries } from 'components/assistant-v2/utils/assistant-api'

export const useAssistantSharedQueriesHistory = (
  isEnabled: boolean = true,
  params: {
    pageSize: number
    offset: number
  } = { pageSize: 10, offset: 0 }
) => {
  return useQuery({
    queryKey: [HarvQueryKeyPrefix.AssistantSharedQueries],
    queryFn: () => fetchRecentSharedAssistantQueries(params),
    enabled: isEnabled,
  })
}
