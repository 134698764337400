import * as Sentry from '@sentry/browser'
import { createWorkerFactory, terminate } from '@shopify/web-worker'
import _ from 'lodash'

import { Event } from 'models/event'
import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'
import { VaultExampleProjectsApiResponseData } from 'openapi/models/VaultExampleProjectsApiResponseData'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFileApiResponseData } from 'openapi/models/VaultFileApiResponseData'
import { VaultFilesApiResponseData } from 'openapi/models/VaultFilesApiResponseData'
import { VaultFilesDeleteApiResponseData } from 'openapi/models/VaultFilesDeleteApiResponseData'
import { VaultFilesRetryApiResponseData } from 'openapi/models/VaultFilesRetryApiResponseData'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderAccessPermission } from 'openapi/models/VaultFolderAccessPermission'
import { VaultFolderApiResponseData } from 'openapi/models/VaultFolderApiResponseData'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { VaultFolderPathApiResponseData } from 'openapi/models/VaultFolderPathApiResponseData'
import { VaultFolderShareStatusApiResponseData } from 'openapi/models/VaultFolderShareStatusApiResponseData'
import { VaultFoldersApiResponseData } from 'openapi/models/VaultFoldersApiResponseData'
import { VaultProjectsMetadataApiResponseData } from 'openapi/models/VaultProjectsMetadataApiResponseData'
import { VaultSetupApiResponseData } from 'openapi/models/VaultSetupApiResponseData'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

import { backendRestUrl } from 'utils/server-data'

import {
  FileToUpload,
  VaultDeleteFolder,
  JobQueueEtaApiResponseData,
  QueryQuestionsResponseData,
  GenerateNNRequestType,
} from 'components/vault/utils/vault'

const BATCH_FILE_SIZE_BYTES = 10000000 // 10MB
const BATCH_FILE_AMOUNT = 100

const uploadWorker = createWorkerFactory(
  () => import('services/workers/upload/upload-worker')
)

const FetchVaultSetup = async (): Promise<VaultSetupApiResponseData> => {
  const requestPath = 'vault/setup'
  const response =
    await Services.Backend.Get<VaultSetupApiResponseData>(requestPath)
  return response
}

const FetchVaultProjects = async (
  includeSharedFolders?: boolean,
  includeSharedWithWorkspaceFolders?: boolean
): Promise<VaultFoldersApiResponseData> => {
  const searchParams = new URLSearchParams()
  if (includeSharedFolders) {
    searchParams.append('include_shared_folders', 'true')
    // only add include_shared_with_workspace_folders param if also including shared folders
    if (includeSharedWithWorkspaceFolders) {
      searchParams.append('include_shared_with_workspace_folders', 'true')
    }
  }
  const requestPath =
    searchParams.size > 0
      ? `vault/user?${searchParams.toString()}`
      : 'vault/user'
  const response = await Services.Backend.Get<VaultFoldersApiResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

const FetchVaultExampleProjects =
  async (): Promise<VaultExampleProjectsApiResponseData> => {
    const requestPath = 'vault/example-projects'
    const response =
      await Services.Backend.Get<VaultExampleProjectsApiResponseData>(
        requestPath
      )
    return response
  }

const SetVaultExampleProject = async (projectId: string): Promise<void> => {
  const requestPath = `vault/set-example-project/${projectId}`
  await Services.Backend.Post<void>(requestPath, {})
}

const UnsetVaultExampleProject = async (projectId: string): Promise<void> => {
  const requestPath = `vault/unset-example-project/${projectId}`
  await Services.Backend.Post<void>(requestPath, {})
}

const FetchVaultProjectsMetadata = async (
  folderId: string
): Promise<VaultFolderMetadata[]> => {
  const requestPath = `vault/folders/${folderId}/project-metadata`
  const response =
    await Services.Backend.Get<VaultProjectsMetadataApiResponseData>(
      requestPath,
      {
        throwOnError: true,
      }
    )
  return response.metadata
}

const FetchVaultFolder = async (
  folderId: string,
  skipUrlSigning = false
): Promise<VaultFolderApiResponseData> => {
  const requestPath = skipUrlSigning
    ? `vault/folder/${folderId}?skip_url_signing=true`
    : `vault/folder/${folderId}`
  const response = await Services.Backend.Get<VaultFolderApiResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

const FetchVaultFolderPathById = async (
  folderId: string
): Promise<VaultFolderPathApiResponseData> => {
  const requestPath = `vault/folder-path/${folderId}`
  const response =
    await Services.Backend.Get<VaultFolderPathApiResponseData>(requestPath)
  return response
}

const FetchVaultFoldersByPath = async (
  path: string
): Promise<VaultFoldersApiResponseData> => {
  const requestPath = `vault/folders-by-path?path=${encodeURIComponent(path)}`
  const response = await Services.Backend.Get<VaultFoldersApiResponseData>(
    requestPath,
    {
      // We want to throw an error if the folder is not found by path.
      throwOnError: true,
      // We don't want to retry the request if it fails.
      maxRetryCount: 0,
    }
  )
  return response
}

const CreateVaultFolder = async (
  folderName: string,
  parentId: string | null = null
): Promise<VaultFolderApiResponseData> => {
  const requestPath = 'vault/folder'
  const response = await Services.Backend.Post<VaultFolderApiResponseData>(
    requestPath,
    {
      name: folderName,
      parentId: parentId,
    },
    // We want to throw an error if the folder is not created.
    { throwOnError: true }
  )
  return response
}

// we need the prefix here to update the file name when uploading zip folders
// for example, when uploading a file in a zip, the file name could be like: safes/discount/yc_safe.pdf
// in this example we need to remove the prefix 'safes/discount/' and rename the file to be just yc_safe.pdf
export const UploadVaultFiles = async ({
  accessToken,
  files,
  vaultFolderId,
  prefix,
  shouldSetUploadTimestamp,
}: {
  accessToken: string
  files: FileToUpload[]
  vaultFolderId: string
  prefix: string
  shouldSetUploadTimestamp: boolean
}): Promise<VaultFilesApiResponseData> => {
  if (files.length === 0) {
    throw new Error('Cannot upload files, files is not set')
  }
  const results: VaultFilesApiResponseData[] = []
  let isFirstRequest = true
  let currFileIndex = 0
  let lastUploadedFileIndex = 0
  let currFormDataSize = 0
  let currNumFiles = 0
  while (currFileIndex < files.length) {
    const currFile = files[currFileIndex]
    currFile.name = currFile.name.replace(prefix, '')
    if (
      currFormDataSize < BATCH_FILE_SIZE_BYTES &&
      currNumFiles < BATCH_FILE_AMOUNT
    ) {
      currFormDataSize += currFile.file.size
      currNumFiles += 1
      currFileIndex += 1
    } else {
      const slicedFiles = files.slice(lastUploadedFileIndex, currFileIndex)
      const worker = uploadWorker()
      const res = await worker.uploadVaultFile({
        endpoint: `${backendRestUrl}/vault/folder/${vaultFolderId}/files`,
        accessToken,
        files: slicedFiles,
        shouldSetUploadTimestamp: shouldSetUploadTimestamp && isFirstRequest,
      })
      terminate(worker)
      results.push(res)
      isFirstRequest = false
      lastUploadedFileIndex = currFileIndex
      currFormDataSize = 0
      currNumFiles = 0
    }
  }

  // upload the last files
  if (lastUploadedFileIndex < files.length) {
    const worker = uploadWorker()
    const res = await worker.uploadVaultFile({
      endpoint: `${backendRestUrl}/vault/folder/${vaultFolderId}/files`,
      accessToken,
      files: files.slice(lastUploadedFileIndex, files.length),
      shouldSetUploadTimestamp: shouldSetUploadTimestamp && isFirstRequest,
    })
    terminate(worker)
    results.push(res)
  }

  const filesApiResponseData: VaultFilesApiResponseData = {
    files: [],
    failedFilesWithErrors: [],
  }
  results.forEach((result) => {
    filesApiResponseData.files = [
      ...filesApiResponseData.files,
      ...result.files,
    ]
    if (result.failedFilesWithErrors) {
      filesApiResponseData.failedFilesWithErrors = [
        ...(filesApiResponseData.failedFilesWithErrors || []),
        ...result.failedFilesWithErrors,
      ]
    }
  })
  return filesApiResponseData
}

const FetchVaultFile = async (fileId: string) => {
  const requestPath = `vault/file/${fileId}`
  const response =
    await Services.Backend.Get<VaultFileApiResponseData>(requestPath)
  return response
}

const FetchVaultFiles = async (
  fileIds: string[]
): Promise<VaultFilesApiResponseData> => {
  const requestPath = `vault/files`
  const response = await Services.Backend.Post<VaultFilesApiResponseData>(
    requestPath,
    { file_ids: fileIds }
  )
  return response
}

const FetchJobQueueEta = async (jobType: string) => {
  try {
    const response = await Services.Backend.Get<JobQueueEtaApiResponseData>(
      `job_queue/eta?job_type=${jobType}`,
      { throwOnError: true, maxRetryCount: 0 }
    )
    return response
  } catch (e) {
    // We don't want to show an error message to the user if the job queue ETA request fails.
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return { etaInSeconds: null }
  }
}

const PatchFolder = async (
  folderId: string,
  data: Record<string, string>
): Promise<VaultFolderApiResponseData> => {
  const requestPath = `vault/folder/${folderId}`
  const response = await Services.Backend.Patch<
    VaultFolderApiResponseData | RequestError
  >(requestPath, data, { throwOnError: true })
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const PatchFile = async (fileId: string, data: Record<string, string>) => {
  const requestPath = `vault/file/${fileId}`
  const response = await Services.Backend.Patch<
    VaultFileApiResponseData | RequestError
  >(requestPath, data, { throwOnError: true })
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const BulkDeleteVaultFiles = async (fileIds: string[]) => {
  const requestPath = `vault/files`
  const response =
    await Services.Backend.Delete<VaultFilesDeleteApiResponseData>(
      requestPath,
      {
        fileIds,
      }
    )
  return response
}

const DeleteVaultFolder = async (folderId: string) => {
  const requestPath = `vault/folder/${folderId}`
  const response = await Services.Backend.Delete<VaultDeleteFolder>(requestPath)
  const fileIds = response.deletedFilesMetadata
    ? response.deletedFilesMetadata.map((file) => file.id)
    : []
  const folderIds = response.deletedFoldersMetadata
    ? response.deletedFoldersMetadata.map((folder) => folder.id)
    : []

  return { fileIds, folderIds }
}

const FetchFolderQueryQuestions = async (
  folderId: string,
  query: string
): Promise<QueryQuestionsResponseData> => {
  const requestPath = `vault/folder/${folderId}/query_questions?query=${encodeURIComponent(
    query
  )}`
  const response = await Services.Backend.Get<QueryQuestionsResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

const RetryFiles = async (fileIds: string[]) => {
  const requestPath = 'vault/files/retry'
  const response = await Services.Backend.Post<VaultFilesRetryApiResponseData>(
    requestPath,
    { fileIds },
    { throwOnError: true }
  )
  return response
}

type ClearQueryErrorsApiResponseData = {
  clearedFileIds: string[]
}

const ClearQueryErrors = async (
  queryId: string,
  fileIds: string[]
): Promise<ClearQueryErrorsApiResponseData> => {
  const requestPath = `vault/query/${queryId}/clear_errors`
  const response = await Services.Backend.Post<ClearQueryErrorsApiResponseData>(
    requestPath,
    { file_ids: fileIds },
    { throwOnError: true }
  )
  return response
}

// Args needed to fetch history
interface FetchVaultHistoryArgs {
  currentPage: number
  pageSize: number
  vaultFolderId?: string
}

const FetchVaultHistoryByPage = async ({
  currentPage,
  pageSize,
  vaultFolderId,
}: FetchVaultHistoryArgs) => {
  try {
    const params = new URLSearchParams()
    if (!_.isNil(pageSize)) {
      params.append('page_size', pageSize.toString())
    }
    if (!_.isNil(currentPage)) {
      params.append('page_number', currentPage.toString())
    }
    if (!_.isNil(vaultFolderId)) {
      params.append('vault_folder_id', vaultFolderId)
    }
    const requestPath = `vault/history?${params.toString()}`
    const result: { events: Event[]; total: number } =
      await Services.Backend.Get<{ events: Event[]; total: number }>(
        requestPath
      )
    return result
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    throw e
  }
}

type CancelVaultHistoryItemResponseData = {
  eventCancelled: boolean
}

const CancelVaultHistoryItem = async (
  queryId: string
): Promise<CancelVaultHistoryItemResponseData> => {
  const requestPath = `vault/history/${queryId}/cancel`
  return await Services.Backend.Post<CancelVaultHistoryItemResponseData>(
    requestPath,
    { throwOnError: true }
  )
}

const ReorderVaultReviewQueryColumns = async (
  queryId: string,
  columnOrder: string[]
) => {
  const requestPath = `vault/history/${queryId}/reorder_columns`
  return await Services.Backend.Post(
    requestPath,
    { columnOrder },
    { throwOnError: true }
  )
}

type VaultReviewQueryUsageApiResponseData = {
  limit: number
  usage: number
  unitLevel: QueryCapRuleUnitLevel
  timeFrame: QueryCapRuleTimeFrame
  level: QueryCapRuleLevel
}

const FetchVaultReviewQueryUsage = async () => {
  const requestPath = `vault/review_query_usage`
  try {
    const response =
      await Services.Backend.Get<VaultReviewQueryUsageApiResponseData>(
        requestPath,
        { throwOnError: true }
      )
    return response
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return {
      limit: null,
      usage: 0,
      unitLevel: QueryCapRuleUnitLevel.CELL,
      timeFrame: QueryCapRuleTimeFrame.CALENDAR_MONTH,
      level: QueryCapRuleLevel.PER_USER,
    }
  }
}

interface RerunVaultReviewQueriesParams {
  requestType: GenerateNNRequestType
  queryIds: string[]
  fileIds: string[]
  pendingQueryUsageByQueryId: Record<string, number>
  pendingFileUsageByQueryId: Record<string, number>
  projectId: string
}

const RerunVaultReviewQueries = async ({
  requestType,
  queryIds,
  fileIds,
  pendingQueryUsageByQueryId,
  pendingFileUsageByQueryId,
  projectId,
}: RerunVaultReviewQueriesParams) => {
  const requestPath = `vault/rerun_review_queries`
  const body = {
    requestType,
    queryIds,
    fileIds,
    pendingQueryUsageByQueryId,
    pendingFileUsageByQueryId,
    projectId,
  }

  try {
    const response = await Services.Backend.Post(requestPath, body, {
      throwOnError: true,
    })
    return response
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
  }
}

export type VaultFolderHistoryStatsApiResponseData = {
  totalCount: number
  inProgressCount: number
}

const FetchVaultFolderHistoryStats = async (folderId: string) => {
  const requestPath = `vault/folder/${folderId}/history_stats`
  try {
    const response =
      await Services.Backend.Get<VaultFolderHistoryStatsApiResponseData>(
        requestPath,
        { throwOnError: true }
      )
    return response
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return { totalCount: 0, inProgressCount: 0 }
  }
}

interface SemanticSearchApiResponseData {
  fileIds: string[]
}

const SemanticSearch = async (
  projectId: string,
  query: string,
  signal?: AbortSignal
): Promise<SemanticSearchApiResponseData> => {
  const requestPath = `vault/semantic_search`
  const response = await Services.Backend.Post<SemanticSearchApiResponseData>(
    requestPath,
    { projectId, query },
    { throwOnError: true, signal }
  )
  return response
}

/**
 * Sharing routes
 */

const FetchVaultFolderShareStatus = async (
  projectId: string
): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Get<VaultFolderShareStatusApiResponseData>(
      requestPath,
      { throwOnError: true }
    )
  return response
}

export type WorkspaceAndUserSharingInfo = {
  shareWithWorkspaces?: Array<{
    workspaceId: number
    permissionLevel: VaultFolderAccessPermission
  }>
  shareWithUsers?: Array<{
    userId: string
    permissionLevel: VaultFolderAccessPermission
  }>
}

export type ShareVaultFolderParams = {
  projectId: string
} & WorkspaceAndUserSharingInfo

const ShareVaultFolder = async ({
  projectId,
  shareWithWorkspaces,
  shareWithUsers,
}: ShareVaultFolderParams): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Post<VaultFolderShareStatusApiResponseData>(
      requestPath,
      {
        shareWithWorkspaces,
        shareWithUsers,
      },
      { throwOnError: true }
    )

  if (response instanceof RequestError) {
    throw response
  }
  return response
}

export type UpdatedWorkspaceAndUserSharingInfo = {
  updateShareWithWorkspaces?: Array<{
    workspaceId: number
    permissionLevel: VaultFolderAccessPermission
  }>
  removeShareWithWorkspaces?: number[]
  updateShareWithUsers?: Array<{
    userId: string
    permissionLevel: VaultFolderAccessPermission
  }>

  removeShareWithUsers?: string[]
}

export type UpdateVaultFolderShareParams = {
  projectId: string
} & UpdatedWorkspaceAndUserSharingInfo

const UpdateVaultFolderShare = async ({
  projectId,
  updateShareWithWorkspaces,
  removeShareWithWorkspaces,
  updateShareWithUsers,
  removeShareWithUsers,
}: UpdateVaultFolderShareParams): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Patch<VaultFolderShareStatusApiResponseData>(
      requestPath,
      {
        updateShareWithWorkspaces,
        removeShareWithWorkspaces,
        updateShareWithUsers,
        removeShareWithUsers,
      },
      { throwOnError: true }
    )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const UnshareVaultFolder = async (projectId: string) => {
  const requestPath = `vault/folder/${projectId}/share`
  const response = await Services.Backend.Delete(requestPath, {
    throwOnError: true,
  })
  return response
}

const UpdateVaultFileTags = async (
  fileId: string,
  oldTagIds: string[],
  newTagIds: string[]
) => {
  const requestPath = `vault/file/${fileId}/tags`
  const response = await Services.Backend.Patch(
    requestPath,
    { oldTagIds, newTagIds },
    { throwOnError: true }
  )
  return response
}

export type VaultFolderUpdateSubscriptionResponseData = {
  hasContentUpdates: boolean
  hasEventsUpdates: boolean
  hasSharingUpdates: boolean
  updatedFolders?: VaultFolder[]
  updatedFiles?: VaultFile[]
  updatedProjectMetadata?: VaultFolderMetadata[]
  updatedSharing?: VaultFolderShareStatusApiResponseData
}

const SubscribeToVaultFolderUpdates = async (
  folderId: string,
  lastUpdatedAt: Date
): Promise<VaultFolderUpdateSubscriptionResponseData> => {
  const searchParams = new URLSearchParams()
  searchParams.append('last_updated_at', lastUpdatedAt.toISOString())
  const requestPath = `vault/folder/${folderId}/subscribe_updates?${searchParams.toString()}`

  return await Services.Backend.Get<VaultFolderUpdateSubscriptionResponseData>(
    requestPath,
    {
      throwOnError: true,
    }
  )
}

export type SharingUpdateSubscriptionResponseData = {
  hasSharingUpdates: boolean
  updatedSharedProjects?: VaultFolder[]
}

const SubscribeToVaultSharingUpdates = async (
  lastUpdatedAt: Date
): Promise<SharingUpdateSubscriptionResponseData> => {
  const searchParams = new URLSearchParams()
  searchParams.append('last_updated_at', lastUpdatedAt.toISOString())
  const requestPath = `vault/shares/subscribe_updates?${searchParams.toString()}`

  return await Services.Backend.Get<SharingUpdateSubscriptionResponseData>(
    requestPath,
    {
      throwOnError: true,
    }
  )
}

export {
  FetchVaultSetup,
  FetchVaultProjects,
  FetchVaultExampleProjects,
  SetVaultExampleProject,
  UnsetVaultExampleProject,
  FetchVaultFolder,
  FetchVaultFolderPathById,
  FetchVaultFoldersByPath,
  FetchVaultProjectsMetadata,
  CreateVaultFolder,
  FetchVaultFile,
  FetchVaultFiles,
  FetchJobQueueEta,
  PatchFolder,
  PatchFile,
  BulkDeleteVaultFiles,
  DeleteVaultFolder,
  FetchFolderQueryQuestions,
  RetryFiles,
  ClearQueryErrors,
  FetchVaultHistoryByPage,
  CancelVaultHistoryItem,
  ReorderVaultReviewQueryColumns,
  FetchVaultReviewQueryUsage,
  RerunVaultReviewQueries,
  FetchVaultFolderHistoryStats,
  SemanticSearch,
  FetchVaultFolderShareStatus,
  ShareVaultFolder,
  UpdateVaultFolderShare,
  UnshareVaultFolder,
  UpdateVaultFileTags,
  SubscribeToVaultFolderUpdates,
  SubscribeToVaultSharingUpdates,
}
