/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateRange } from 'react-day-picker'

import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  format,
  getMonth,
  getYear,
  isFirstDayOfMonth,
  isMonday,
  isSameMonth,
  isSameWeek,
  isSameYear,
  isThisYear,
  isToday,
  subMonths,
  subYears,
} from 'date-fns'
import _ from 'lodash'

import { Maybe } from 'types'

import { parseIsoString } from './utils'

// TODO: Consolidate on a 3rd party date library, possibly date-fns like we use (Abhi idea) or moment.js/day.js (Nick idea).
// Many of the functions below are not pure date utils though, but involve grabbing items from an array based on date, etc.

// TODO(Adam): Move the date utils from src/utils/utils.tsx to here: src/utils/date-utils.ts

export type ISODateStringWithMicroseconds = string

export enum Timezone {
  UTC = 'UTC',
}

export enum TodayOption {
  showTime,
  showLabel,
  showDate,
}

/**
 * @returns the date of one month prior to the current date
 */
export const getOneMonthPrior = (): Date => {
  const today = new Date()
  return subMonths(today, 1)
}

/**
 * @returns the date of one year prior to the current date
 */
export const getOneYearPrior = (): Date => {
  const today = new Date()
  return subYears(today, 1)
}

/**
 * Finds the object with either the oldest or the youngest date for a given property key in an array of objects.
 * @param {Array} dataArray - The array of objects to search through.
 * @param {String} dateProperty - The property key that contains the date to compare.
 * @param {String} direction - The direction to search for: "oldest" or "youngest".
 * @return {Object|null} The object with the specified date criteria, or null if the array is empty or no valid dates are found.
 */
const findObjectByDate = (
  dataArray: any[],
  dateProperty: string,
  direction: 'oldest' | 'youngest'
) => {
  if (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !dataArray ||
    !dataArray.length ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (direction !== 'oldest' && direction !== 'youngest')
  ) {
    return null
  }

  // Determine the comparison function based on the direction
  const compareFunction = (obj: any) => {
    const dateValue = obj[dateProperty]
    // Handle both Date objects and timestamps
    return dateValue instanceof Date
      ? dateValue.getTime()
      : new Date(dateValue).getTime()
  }

  let resultObject
  if (direction === 'youngest') {
    resultObject = _.maxBy(dataArray, compareFunction)
  } else {
    // direction === "oldest"
    resultObject = _.minBy(dataArray, compareFunction)
  }

  if (!resultObject || isNaN(new Date(resultObject[dateProperty]).getTime())) {
    console.error(
      `No valid result found for findObjectByDate by ${dateProperty}. Returning null.`
    )
    return null
  }

  return resultObject
}

export const findOldestItem = (dataArray: any[], dateProperty: string) => {
  return findObjectByDate(dataArray, dateProperty, 'oldest')
}

export const findYoungestItem = (dataArray: any[], dateProperty: string) => {
  return findObjectByDate(dataArray, dateProperty, 'youngest')
}

export const findOldestDate = (dataArray: any[], dateProperty: string) => {
  const oldestItem = findOldestItem(dataArray, dateProperty)
  return oldestItem ? new Date(oldestItem[dateProperty]) : null
}

/**
 * @returns a date string in ISO 8601 format for the oldest date in the array (or whatever string format the date is in on the dataArray objects)
 */
export const findOldestDateString = (
  dataArray: any[],
  dateProperty: string
): ISODateStringWithMicroseconds | null => {
  const oldestItem = findOldestItem(dataArray, dateProperty)
  return oldestItem ? oldestItem[dateProperty] : null
}

export const findYoungestDate = (dataArray: any[], dateProperty: string) => {
  const youngestItem = findYoungestItem(dataArray, dateProperty)
  return youngestItem ? new Date(youngestItem[dateProperty]) : null
}

/**
 * @returns a date string in ISO 8601 format for the youngest date in the array (or whatever string format the date is in on the dataArray objects)
 */
export const findYoungestDateString = (
  dataArray: any[],
  dateProperty: string
): ISODateStringWithMicroseconds | null => {
  const youngestItem = findYoungestItem(dataArray, dateProperty)
  return youngestItem ? youngestItem[dateProperty] : null
}

export function readableDateRange(dateRange: DateRange): string {
  if (!dateRange.from) {
    return ''
  }

  if (!dateRange.to) {
    return readableFormat(dateRange.from, TodayOption.showLabel, 'short')
  }

  // Check for exact periods
  if (isToday(dateRange.to)) {
    if (isToday(dateRange.from)) {
      return 'Today'
    } else if (
      differenceInCalendarDays(dateRange.to, addWeeks(dateRange.from, 1)) === 0
    ) {
      return 'Past week'
    } else if (
      differenceInCalendarDays(dateRange.to, addMonths(dateRange.from, 1)) ===
        0 ||
      differenceInCalendarDays(dateRange.to, addDays(dateRange.from, 30)) === 0
    ) {
      return 'Past month'
    } else if (
      differenceInCalendarDays(dateRange.to, addQuarters(dateRange.from, 1)) ===
      0
    ) {
      return 'Past quarter'
    } else if (
      differenceInCalendarDays(dateRange.to, addQuarters(dateRange.from, 2)) ===
      0
    ) {
      return 'Past half year'
    } else if (
      differenceInCalendarDays(dateRange.to, addYears(dateRange.from, 1)) === 0
    ) {
      return 'Past year'
    } else if (
      differenceInCalendarDays(dateRange.to, addYears(dateRange.from, 3)) === 0
    ) {
      return 'Past 3 years'
    } else if (
      differenceInCalendarDays(dateRange.to, addYears(dateRange.from, 5)) === 0
    ) {
      return 'Past 5 years'
    } else if (
      isMonday(dateRange.from) &&
      isSameWeek(dateRange.to, dateRange.from)
    ) {
      return 'This week'
    } else if (
      isFirstDayOfMonth(dateRange.from) &&
      isSameMonth(dateRange.to, dateRange.from)
    ) {
      return 'This month'
    } else if (
      isFirstDayOfMonth(dateRange.from) &&
      getMonth(dateRange.from) === 0 &&
      isSameYear(dateRange.to, dateRange.from)
    ) {
      return 'This year'
    }
  }

  if (getYear(dateRange.from) === getYear(dateRange.to)) {
    if (isThisYear(dateRange.from)) {
      if (getMonth(dateRange.from) === getMonth(dateRange.to)) {
        // If the date range is within the same month of the current year, show the format
        // "MMM d-d", e.g. "Jan 1-31"
        return `${format(dateRange.from, 'MMM d')}-${format(dateRange.to, 'd')}`
      }
    } else {
      const dateTimeFormat = new Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      const dateTimeFormatWithoutYear = new Intl.DateTimeFormat(
        navigator.language,
        {
          month: '2-digit',
          day: '2-digit',
        }
      )

      // Check if the locale typically places the year at the start (e.g., East Asian formats)
      const yearFirst =
        dateTimeFormat.formatToParts(dateRange.from)[0].type === 'year'

      // If the date range is within the same year but not the current year, show the format
      if (yearFirst) {
        // Format as "YYYY/MM/dd-MM/dd"
        return `${dateTimeFormat.format(
          dateRange.from
        )}-${dateTimeFormatWithoutYear.format(dateRange.to)}`
      } else {
        // Format as "MM/dd-MM/dd/YYYY"
        return `${dateTimeFormatWithoutYear.format(
          dateRange.from
        )}-${dateTimeFormat.format(dateRange.to)}`
      }
    }
  }

  return `${readableFormat(
    dateRange.from,
    TodayOption.showLabel,
    'short'
  )} - ${readableFormat(dateRange.to, TodayOption.showLabel, 'short')}`
}

export const getDateRange = (history: Maybe<{ from: string; to: string }>) => {
  if (_.isNil(history)) {
    return undefined
  }

  return {
    from: new Date(history.from),
    to: new Date(history.to),
  }
}

// relies on browser's default language / navigator.language to determine format
// navigator.language returns locale values (e.g. en-US)
export function readableFormat(
  date: Date,
  todayOption: TodayOption = TodayOption.showTime,
  variant: 'short' | 'normal' | 'long' = 'normal'
): string {
  let readable: string
  if (isToday(date)) {
    switch (todayOption) {
      case TodayOption.showTime:
        readable = new Intl.DateTimeFormat(navigator.language, {
          hour: 'numeric',
          minute: '2-digit',
        }).format(date)
        break
      case TodayOption.showLabel:
        readable = 'Today'
        break
      case TodayOption.showDate:
        readable = format(
          date,
          variant === 'long'
            ? 'MMM d, yyyy'
            : variant === 'short'
            ? 'MMM d'
            : 'MMM dd'
        )
        break
      default:
        readable = new Intl.DateTimeFormat(navigator.language, {
          hour: 'numeric',
          minute: '2-digit',
        }).format(date)
    }
  } else if (isThisYear(date)) {
    readable = format(
      date,
      variant === 'long'
        ? 'MMM d, yyyy'
        : variant === 'short'
        ? 'MMM d'
        : 'MMM dd'
    )
  } else {
    readable = new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date)
  }

  return readable
}

// uses date-fns for browser agnostic date formatting
export const getTableDateString = (value: any) => {
  try {
    const stringDate = String(value)
    const localeDate = parseIsoString(stringDate)
    if (isToday(localeDate)) {
      return format(localeDate, 'p')
    } else {
      return format(localeDate, 'MMM dd, yyyy')
    }
  } catch (e) {
    console.error('Couldn’t transform date to localestring', e)
    return ''
  }
}

// does not preserve the moment in time, keeps the hour the same and only swaps the timezone
export const convertExactSameDateAndHourFromLocalToUTC = (date: Date) => {
  const tempDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const offset = date.getTime() - tempDate.getTime() // in milliseconds
  const newDate = new Date(date.getTime() + offset)
  return newDate
}
