import React, { useRef } from 'react'

import _ from 'lodash'
import { AlertCircle } from 'lucide-react'
import { Instance } from 'pspdfkit'

import { PdfKitSource } from 'openapi/models/PdfKitSource'
import { UploadedFile } from 'openapi/models/UploadedFile'

import * as PdfViewerUtils from 'utils/pdf-viewer'
import { Source } from 'utils/task'

import PdfViewer from 'components/common/pdf-viewer/pdf-viewer'
import { Progress } from 'components/ui/progress'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { Sheet, SheetContent, SheetHeader } from 'components/ui/sheet'

type Props = {
  document: UploadedFile | null
  sources?: PdfKitSource[]
  page?: number
  setShowSheet: (value: boolean) => void
  showSheet: boolean
  applyAnnotations?: (instance: Instance) => Promise<void>
  isLoadingUrl?: boolean
  shouldAutoFocus?: boolean
}

export const PdfViewerSheet: React.FC<Props> = ({
  document,
  sources,
  page,
  setShowSheet,
  showSheet,
  applyAnnotations,
  isLoadingUrl = false,
  shouldAutoFocus,
}) => {
  const pspdfInstanceRef = useRef<Instance | null>(null)
  const defaultResizablePanelSizes = [55, 45]
  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)
  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  if (!document) return null

  const defaultApply = () =>
    PdfViewerUtils.applyAnnotations(
      pspdfInstanceRef.current,
      document,
      sources as Source[]
    )

  const emptyState = () =>
    isLoadingUrl ? (
      <div className="flex h-full flex-col items-center justify-center bg-neutral-50">
        {/* XXX: To match the style with PSPDFKit Progress  */}
        <Progress value={50} className="my-2 h-2 w-[330px]" />
        <p className="text-[1rem] leading-[1.4rem] text-primary">
          Loading document viewer.
        </p>
      </div>
    ) : (
      <div className="flex h-full items-center justify-center gap-2">
        <AlertCircle className="h-5 w-5 text-muted" />
        <p className="text-lg font-semibold text-muted">No preview available</p>
      </div>
    )

  return (
    <Sheet
      onOpenChange={(value: boolean) => setShowSheet(value)}
      open={showSheet}
    >
      <SheetContent className="h-full w-full bg-transparent shadow-none sm:max-w-none">
        <ResizablePanelGroup
          direction="horizontal"
          ref={resizablePanelGroupRef}
        >
          <ResizablePanel
            defaultSize={defaultResizablePanelSizes[0]}
            onClick={() => setShowSheet(false)}
          />
          <ResizableHandle withHandle onDoubleClick={resetLayout} />
          <ResizablePanel
            className="shadow-lg"
            defaultSize={defaultResizablePanelSizes[1]}
            maxSize={99}
            minSize={20}
          >
            <SheetHeader>
              <p className="line-clamp-1 w-5/6 font-semibold">
                {document.name}
              </p>
            </SheetHeader>
            {/* TODO: Refactor sheet to be flex instead of fixed so we won't need to calc height */}
            <div className="h-[calc(100vh-64px)]">
              {!_.isEmpty(document.url) ? (
                <PdfViewer
                  applyAnnotations={applyAnnotations ?? defaultApply}
                  className="rounded-none border-0"
                  document={document}
                  initialPage={page}
                  pspdfInstanceRef={pspdfInstanceRef}
                  hideToolbar
                  shouldAutoFocus={shouldAutoFocus ?? true}
                />
              ) : (
                emptyState()
              )}
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </SheetContent>
    </Sheet>
  )
}
