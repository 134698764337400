import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'

import { UserInfo } from 'models/user-info'

import { waitForElement } from 'utils/utils'

import { AssistantMode } from 'components/assistant-v2/components/assistant-mode-select'
import {
  CHAT_EXAMPLE_MESSAGES,
  DRAFT_EXAMPLE_MESSAGES,
} from 'components/assistant-v2/utils/assistant-example-data'
import { TrackEventFunction } from 'components/common/analytics/analytics-context'
import { useAssistantV2TourStore } from 'components/common/product-tours/assistant-v2-tour-store'

export const runAssistantV2Tour = async (
  navigate: NavigateFunction,
  userInfo?: UserInfo,
  trackEvent?: TrackEventFunction
) => {
  const startRoute = window.location.pathname

  navigate('/assistant')
  await waitForElement('#assistant-mode-assist')

  const steps: DriveStep[] = [
    {
      element: '#assistant-mode-assist',
      popover: {
        title: userInfo?.IsAssistantDraftUser
          ? 'Select Assist mode'
          : 'Use Assist',
        description:
          'Assist mode is designed for fast tasks and lets you ask follow-up questions.',
        onNextClick: () => {
          const inputEl = document.querySelector(
            '#assistant-text-input textarea'
          )
          if (inputEl) {
            ;(inputEl as HTMLTextAreaElement).value =
              CHAT_EXAMPLE_MESSAGES[0].query
          }
          trackEvent?.('Assistant Tour Next Clicked', {
            to_step: 'ask-away',
            from_step: 'select-assist',
          })
          driverObj.moveNext()
        },
      },
    },
    {
      element: '#assistant-text-input',
      popover: {
        title: 'Ask away',
        description: `After you've written your query${
          userInfo?.isDocumentUser ? ' and uploaded files' : ''
        }, submit your query.`,
        onPrevClick: () => {
          trackEvent?.('Assistant Tour Previous Clicked', {
            to_step: 'select-assist',
            from_step: 'ask-away',
          })
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          navigate('/assistant/assist/example')
          await waitForElement(
            `#assistant-message-content-${CHAT_EXAMPLE_MESSAGES[0].messageId}`
          )
          trackEvent?.('Assistant Tour Next Clicked', {
            to_step: 'assist-response',
            from_step: 'ask-away',
          })
          driverObj.moveNext()
        },
      },
    },
    {
      element: `#assistant-message-content-${CHAT_EXAMPLE_MESSAGES[0].messageId}`,
      popover: {
        title: 'See the response',
        description:
          'Assistant will respond to your query.<br/><br/> Export, edit, or regenerate this response.',
        onPrevClick: async () => {
          navigate('/assistant')
          await waitForElement('#assistant-text-input')
          const inputEl = document.querySelector(
            '#assistant-text-input textarea'
          )
          if (inputEl) {
            ;(inputEl as HTMLTextAreaElement).value =
              CHAT_EXAMPLE_MESSAGES[0].query
          }
          trackEvent?.('Assistant Tour Previous Clicked', {
            to_step: 'ask-away',
            from_step: 'assist-response',
          })
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          if (userInfo?.IsAssistantDraftUser) {
            navigate('/assistant', { state: { mode: AssistantMode.DRAFT } })
            await waitForElement('#assistant-mode-draft')
            trackEvent?.('Assistant Tour Next Clicked', {
              to_step: 'select-draft',
              from_step: 'assist-response',
            })
            driverObj.moveNext()
          } else {
            handleComplete()
          }
        },
      },
    },
  ]

  if (userInfo?.IsAssistantDraftUser) {
    steps.push(
      {
        element: '#assistant-mode-draft',
        popover: {
          title: 'Select Draft mode',
          description:
            'Draft mode is designed for generating and revising content.',
          onPrevClick: async () => {
            navigate('/assistant/assist/example')
            await waitForElement(
              `#assistant-message-content-${CHAT_EXAMPLE_MESSAGES[0].messageId}`
            )
            trackEvent?.('Assistant Tour Previous Clicked', {
              to_step: 'assist-response',
              from_step: 'select-draft',
            })
            driverObj.movePrevious()
          },
          onNextClick: () => {
            const inputEl = document.querySelector(
              '#assistant-text-input textarea'
            )
            if (inputEl) {
              ;(inputEl as HTMLTextAreaElement).value =
                DRAFT_EXAMPLE_MESSAGES[0].query
            }
            trackEvent?.('Assistant Tour Next Clicked', {
              to_step: 'begin-drafting',
              from_step: 'select-draft',
            })
            driverObj.moveNext()
          },
        },
      },
      {
        element: '#assistant-text-input',
        popover: {
          title: 'Begin drafting',
          description: `After you've written your query${
            userInfo?.isDocumentUser ? ' and uploaded files' : ''
          }, submit your query to begin drafting.`,
          onPrevClick: () => {
            trackEvent?.('Assistant Tour Previous Clicked', {
              to_step: 'select-draft',
              from_step: 'begin-drafting',
            })
            driverObj.movePrevious()
          },
          onNextClick: async () => {
            navigate('/assistant/draft/example')
            await waitForElement('#assistant-draft-revise')
            trackEvent?.('Assistant Tour Next Clicked', {
              to_step: 'draft-revise',
              from_step: 'begin-drafting',
            })
            driverObj.moveNext()
          },
        },
      },
      {
        element: '#assistant-draft-revise',
        popover: {
          title: 'Revise your draft',
          description:
            'If you’d like to make edits to the draft, provide instructions here. You can regenerate or delete individual revisions.',
          onPrevClick: async () => {
            navigate('/assistant', { state: { mode: AssistantMode.DRAFT } })
            await waitForElement('#assistant-text-input')
            const inputEl = document.querySelector(
              '#assistant-text-input textarea'
            )
            if (inputEl) {
              ;(inputEl as HTMLTextAreaElement).value =
                DRAFT_EXAMPLE_MESSAGES[0].query
            }
            trackEvent?.('Assistant Tour Previous Clicked', {
              to_step: 'begin-drafting',
              from_step: 'draft-revise',
            })
            driverObj.movePrevious()
          },
          onNextClick: async () => {
            await waitForElement('#assistant-draft-highlight')
            const highlightContent = document.querySelector(
              '#assistant-draft-highlight'
            )
            if (highlightContent) {
              highlightContent.className += 'bg-highlight'
            }
            trackEvent?.('Assistant Tour Next Clicked', {
              to_step: 'draft-highlight',
              from_step: 'draft-revise',
            })
            driverObj.moveNext()
          },
        },
      },
      {
        element: '#assistant-draft-highlight',
        popover: {
          title: 'Revise a specific section',
          description:
            'If you’d only like to change a specific section of the draft, highlight it and then provide instructions.',
          onPrevClick: () => {
            trackEvent?.('Assistant Tour Previous Clicked', {
              to_step: 'draft-revise',
              from_step: 'draft-highlight',
            })
            driverObj.movePrevious()
          },
          onNextClick: () => {
            handleComplete()
          },
        },
      }
    )
  }

  const closeAssistantV2Tour = () => {
    useAssistantV2TourStore.getState().endTour()
    driverObj.destroy()
    navigate(startRoute)
  }

  const handleComplete = () => {
    closeAssistantV2Tour()
    trackEvent?.('Assistant Tour Completed')
  }

  const handleClose = () => {
    closeAssistantV2Tour()
    trackEvent?.('Assistant Tour Closed')
  }

  const driverObj = driver({
    showProgress: true,
    steps,
    disableActiveInteraction: true,
    onCloseClick: handleClose,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        closeAssistantV2Tour()
      }
    },
    popoverClass: 'assistant-tour',
  })

  driverObj.drive()
  useAssistantV2TourStore.getState().startTour()
}
