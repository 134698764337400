import React, { useRef, useState } from 'react'
import { DateRange } from 'react-day-picker'

import { isSameDay, subYears } from 'date-fns'
import { CalendarRange, ChevronRight } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { readableDateRange } from 'utils/date-utils'

import { useResearchStore } from 'components/research/research-store'
import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { DateInput } from 'components/ui/date-input'
import {
  Popover,
  PopoverContent,
  PopoverMenuItem,
  PopoverTrigger,
} from 'components/ui/popover'

export const ResearchDatePicker: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [setDateRange, dateRange] = useResearchStore(
    useShallow((s) => [s.setDateRange, s.dateRange])
  )

  // Define time periods and their respective calculation for the date range
  const timePeriods = [
    { label: 'All time', getDateRange: () => null },
    {
      label: 'Past year',
      getDateRange: () => ({ from: subYears(new Date(), 1), to: new Date() }),
    },
    {
      label: 'Past 3 years',
      getDateRange: () => ({ from: subYears(new Date(), 3), to: new Date() }),
    },
    {
      label: 'Past 5 years',
      getDateRange: () => ({ from: subYears(new Date(), 5), to: new Date() }),
    },
  ]

  const timePeriodMenuRefs = useRef<(HTMLButtonElement | null)[]>([])

  const onDayClick = (date: Date) => {
    if (dateRange?.from && dateRange.to) {
      if (isSameDay(dateRange.from, date) || isSameDay(dateRange.to, date)) {
        // Reset date if either end is selected again
        setDateRange({ from: undefined, to: undefined })
      } else {
        setDateRange({
          from: date < dateRange.from ? date : dateRange.from,
          to: date > dateRange.from ? date : dateRange.from,
        })
      }
    } else {
      // Set both dates if no date is selected
      setDateRange({ from: date, to: date })
    }
  }

  const onFromDateChange = (date: Date) => {
    let to = dateRange?.to
    if (!to) to = new Date()
    if (date > to) to = date
    setDateRange({ from: date, to })
  }

  const onToDateChange = (date: Date) => {
    let from = dateRange?.from
    if (!from) from = new Date()
    if (date < from) from = date
    setDateRange({ from, to: date })
  }

  return (
    <Popover open={isMenuOpen} onOpenChange={setMenuOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary" size="sm">
          <CalendarRange size={16} className="mr-1" />
          <span className="text-xs">
            {dateRange ? formatDateRange(dateRange) : 'All time'}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="w-54 p-1"
        onOpenAutoFocus={(e) => {
          // Popover auto-focuses the first focusable element by default.
          // This custom logic focuses the active time period, if one is set.
          if (dateRange) {
            const formattedRange = formatDateRange(dateRange)
            let periodIndex = timePeriods.findIndex(
              (period) => period.label === formattedRange
            )
            // Custom time period should be last ref in the array
            if (periodIndex === -1) periodIndex = timePeriods.length
            const periodRef = timePeriodMenuRefs.current[periodIndex]
            if (periodRef) {
              periodRef.focus()
              e.preventDefault()
            }
          }
        }}
      >
        {timePeriods.map((period, index) => (
          <PopoverMenuItem
            key={index}
            className="cursor-pointer"
            onClick={() => {
              setDateRange(period.getDateRange())
              setMenuOpen(false)
            }}
            ref={(el) => (timePeriodMenuRefs.current[index] = el)}
          >
            {period.label}
          </PopoverMenuItem>
        ))}
        <Popover>
          <PopoverTrigger asChild>
            <PopoverMenuItem
              ref={(el) =>
                (timePeriodMenuRefs.current[timePeriods.length] = el)
              }
            >
              Custom date range <ChevronRight className="h-4 w-4" />
            </PopoverMenuItem>
          </PopoverTrigger>
          <PopoverContent
            className="w-64 p-2"
            align="start"
            side="right"
            alignOffset={-12}
          >
            <div className="flex flex-col gap-1">
              <div className="flex justify-between gap-1">
                <DateInput
                  value={dateRange?.from}
                  onChange={onFromDateChange}
                />
                <div className="py-1 text-muted">–</div>
                <DateInput value={dateRange?.to} onChange={onToDateChange} />
              </div>
              <Calendar
                mode="range"
                defaultMonth={dateRange?.to}
                selected={dateRange ?? undefined}
                numberOfMonths={1}
                onDayClick={onDayClick}
              />
            </div>
          </PopoverContent>
        </Popover>
      </PopoverContent>
    </Popover>
  )
}

const formatDateRange = (dateRange: DateRange | null) => {
  if (!dateRange?.from) {
    return 'Date range'
  } else {
    return readableDateRange(dateRange)
  }
}
