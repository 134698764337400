import React, { useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { Alert, Card, List, Space, Spin, Typography, message } from 'antd'
import classnames from 'classnames'
import { format } from 'date-fns'
import emailAddresses, { ParsedMailbox } from 'email-addresses'
import _ from 'lodash'
import { UploadCloud } from 'lucide-react'

import {
  CreateUsersResult,
  ExistingUserResponse,
  UserAddedSuccess,
  createNewPwcUsers,
} from 'models/users'
import { Maybe } from 'types'

import { MB_BYTES } from 'utils/file-utils'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import CsvDownload from 'components/ui/csv-download'

export interface PWCCsvUser {
  email: string
  territory: string
}

const PWCUserAdd: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [existingUsers, setExistingUsers] = useState<ExistingUserResponse[]>([])
  const [failedUsers, setFailedUsers] = useState<string[]>([])
  const [successUsers, setSuccessUsers] = useState<UserAddedSuccess[]>([])
  const [duplicateUsers, setDuplicateUsers] = useState<string[]>([])
  const [deleteUsers, setDeleteUsers] = useState<string[]>([])
  const [permsUsers, setPermsUsers] = useState<string[]>([])
  const [error, setError] = useState<string>('')
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [addCSVUsers, setAddCSVUsers] = useState<PWCCsvUser[]>([])
  const [failedCSVUsers, setFailedCSVUsers] = useState<string[]>([])

  const cleanState = (): void => {
    setExistingUsers([])
    setFailedUsers([])
    setSuccessUsers([])
    setDuplicateUsers([])
    setDeleteUsers([])
    setPermsUsers([])
  }

  const onDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ): void => {
    cleanState()
    if (fileRejections.length > 0) {
      setError(fileRejections[0].errors[0].message)
      return
    } // add toast here
    if (acceptedFiles.length > 0) {
      setUploadedFile(acceptedFiles[0])
    }
    const fileReader = new FileReader()
    fileReader.readAsText(acceptedFiles[0], 'UTF-8')
    fileReader.onload = function (evt) {
      const csv = evt?.target?.result as string
      if (csv) {
        const lines = csv.split('\n')
        const users: PWCCsvUser[] = []
        const failedUsers: string[] = []
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].split(',')
          if (line.length < 2) {
            failedUsers.push(line.join(','))
            continue
          }
          const email = line[0].trim().toLowerCase().replace(/"/g, '')
          const territory = line[1].trim()
          const parsedEmail: Maybe<ParsedMailbox> =
            emailAddresses.parseOneAddress(email) as Maybe<ParsedMailbox>
          if (parsedEmail && !_.isNil(parsedEmail.parts.address)) {
            users.push({ email: parsedEmail.address, territory })
          } else {
            failedUsers.push(email)
          }
        }
        setAddCSVUsers(users)
        setFailedCSVUsers(failedUsers)
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // eslint-disable-next-line
    onDrop,
    multiple: false,
    maxSize: 50 * MB_BYTES,
    maxFiles: 5,
    accept: {
      'text/csv': ['.csv', '.CSV'],
    },
  })

  const onPwcCsvSubmit = async (): Promise<void> => {
    try {
      cleanState()
      setLoading(true)

      const response: CreateUsersResult = await createNewPwcUsers(addCSVUsers)
      setExistingUsers(response.usersAlreadyExist)
      setFailedUsers(response.usersFailed)
      setSuccessUsers(response.usersAdded)
      setDuplicateUsers(response.usersDuplicate ?? [])
      setDeleteUsers(response.deleteUsers ?? [])
      setPermsUsers(response.permsUsers ?? [])
      setLoading(false)
      if (
        _.isEmpty(response) ||
        (_.isEmpty(response.usersAdded) &&
          _.isEmpty(response.usersAlreadyExist) &&
          _.isEmpty(response.usersFailed) &&
          _.isEmpty(response.usersDuplicate) &&
          _.isEmpty(response.deleteUsers) &&
          _.isEmpty(response.permsUsers))
      ) {
        await message.error(response.message || 'Error adding users')
        return
      } else {
        message.success('Users added successfully')
      }
    } catch (errorInfo) {
      await message.error('Error adding users')
    }
  }

  const resultEmpty =
    _.isEmpty(existingUsers) &&
    _.isEmpty(failedUsers) &&
    _.isEmpty(successUsers) &&
    _.isEmpty(duplicateUsers) &&
    _.isEmpty(deleteUsers) &&
    _.isEmpty(permsUsers)

  const formattedNow = format(new Date(), 'yyyyMMdd')

  return (
    <Accordion type="single" collapsible className="rounded-md border">
      <AccordionItem value="user-add">
        <AccordionTrigger className="p-4">
          <div className="flex justify-between">
            <h2 className="text-lg font-semibold">Bulk PWC user add</h2>
          </div>
        </AccordionTrigger>
        <AccordionContent className="p-5 pb-0">
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Typography>Upload a CSV of PWC users.</Typography>
            <Typography>
              Please ensure the CSV only contains 2 columns, no headers or extra
              rows
            </Typography>
            <Typography>Format: Email , Territory</Typography>
            {!_.isEmpty(error) && (
              <Alert
                type="error"
                showIcon
                closable
                message={<Typography>{error}</Typography>}
              />
            )}
            {uploadedFile ? (
              <Card
                type="inner"
                title={
                  <Typography.Title level={5}>
                    {uploadedFile.name}
                  </Typography.Title>
                }
                extra={
                  <Button
                    onClick={() => {
                      setUploadedFile(null)
                    }}
                    disabled={loading}
                  >
                    Clear
                  </Button>
                }
              >
                {resultEmpty ? (
                  <Spin spinning={loading}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <List>
                        <List.Item>
                          <Typography>
                            Users parsed: {addCSVUsers.length}
                          </Typography>
                        </List.Item>
                        <List.Item>
                          <Typography>
                            Users incorrectly formatted:{' '}
                            {failedCSVUsers.join(', ')}{' '}
                          </Typography>
                        </List.Item>
                      </List>
                      <Button
                        onClick={onPwcCsvSubmit}
                        style={{ marginTop: '16px' }}
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </Spin>
                ) : (
                  <div className="mb-2">
                    <List>
                      <List.Item
                        actions={
                          _.isEmpty(successUsers)
                            ? []
                            : [
                                <CsvDownload
                                  key="successUsers"
                                  data={successUsers}
                                  buttonText="Download added users"
                                  filename={`PWC_new_users_${formattedNow}.csv`}
                                />,
                              ]
                        }
                      >
                        Successfully added users: {successUsers.length}
                      </List.Item>
                      <List.Item
                        actions={
                          _.isEmpty(deleteUsers)
                            ? []
                            : [
                                <CsvDownload
                                  key="deleteUsers"
                                  data={deleteUsers.map((email) => ({ email }))}
                                  buttonText="Download to delete users"
                                  filename={`PWC_to_delete_users_${formattedNow}.csv`}
                                  headers={['email']}
                                />,
                              ]
                        }
                      >
                        To delete users: {deleteUsers.length}
                      </List.Item>
                      <List.Item>
                        Users added but need permissions:
                        {permsUsers.length
                          ? _.sortBy(permsUsers).join(', ')
                          : '-'}
                      </List.Item>
                      <List.Item>
                        Existing users:{' '}
                        {existingUsers.length
                          ? _.sortBy(
                              existingUsers.map((user) => user.email)
                            ).map((email, index) => (
                              <Badge key={index} variant="ghost">
                                {email}
                              </Badge>
                            ))
                          : '-'}
                      </List.Item>
                      <List.Item>
                        Duplicate users in CSV:{' '}
                        {duplicateUsers.length
                          ? _.sortBy(duplicateUsers).join(', ')
                          : '-'}
                      </List.Item>
                      <List.Item>
                        Failed users:{' '}
                        {failedUsers.length
                          ? _.sortBy(failedUsers).join(', ')
                          : '-'}
                      </List.Item>
                    </List>
                  </div>
                )}
              </Card>
            ) : (
              <div
                className={classnames({
                  'relative mb-4 flex h-full cursor-pointer items-center justify-center rounded-lg border border-dashed bg-neutral-50 py-8 shadow-inner transition-colors hover:bg-neutral-50':
                    true,
                  isDragging: isDragActive,
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="flex h-full w-full flex-col items-center justify-center space-y-2 text-muted">
                  <UploadCloud />
                  {isDragActive ? (
                    <span>Drop the file here</span>
                  ) : (
                    <div className="flex flex-col items-center">
                      <div className="mt-2 block font-semibold">
                        Click to upload or drag and drop.
                      </div>
                      <div className="mt-2 block">Max file size: 50MB</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Space>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default PWCUserAdd
