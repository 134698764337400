import React, { useState } from 'react'

import { PlusCircle } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { Checkbox, CheckboxVariant } from 'components/ui/checkbox'
import { Input } from 'components/ui/input'
import { GroupedTerms } from 'components/workflows/workflow/contracts/utils/utils'

interface TermGroupProps {
  type: string
  terms: string[]
  selectedTerms: GroupedTerms
  setSelectedTerms: (terms: GroupedTerms) => void
  handleTermAdd: (type: string, term: string) => Promise<boolean>
  handleTermCheckedChange: (type: string, term: string) => void
}

const TermGroup: React.FC<TermGroupProps> = ({
  type,
  terms,
  selectedTerms,
  setSelectedTerms,
  handleTermAdd,
  handleTermCheckedChange,
}) => {
  const [isAddTermOpen, setIsAddTermOpen] = useState<boolean>(false)
  const [newTerm, setNewTerm] = useState<string>('')
  const addTermInputRef = React.useRef<HTMLInputElement>(null)

  const isAllSelected = terms.every(
    (term) => selectedTerms[type]?.includes(term)
  )

  const isAtLeastOneSelected = terms.some(
    (term) => selectedTerms[type]?.includes(term)
  )

  const toggleSelectAll = () => {
    if (isAllSelected) {
      const updatedTerms = { ...selectedTerms }
      delete updatedTerms[type]
      setSelectedTerms(updatedTerms)
    } else {
      setSelectedTerms({
        ...selectedTerms,
        [type]: terms,
      })
    }
  }

  const handleAddTermOpen = () => {
    addTermInputRef.current?.focus()
    setIsAddTermOpen(true)
  }

  const internalTermAdd = (type: string, term: string) => {
    handleTermAdd(type, term)
    setIsAddTermOpen(false)
    setNewTerm('')
  }

  const toggleSelectAllText = isAllSelected ? 'Deselect all' : 'Select all'

  return (
    <div className="overflow-clip rounded-lg border bg-neutral-50">
      <div className="relative flex items-baseline justify-between border-b bg-muted px-4 py-3">
        <p className="font-semibold">{type}</p>
        <div>
          <Button variant="outline" size="sm" onClick={handleAddTermOpen}>
            <PlusCircle className="mr-1 h-3 w-3" />
            Term
          </Button>
          <div
            className={cn(
              'absolute right-2 top-2 origin-top-right scale-0 transform rounded-lg bg-white/70 opacity-0 backdrop-blur transition',
              { 'scale-100 opacity-100': isAddTermOpen }
            )}
          >
            <div className="relative flex w-fit items-center">
              <Input
                placeholder="Add term"
                className="h-8 w-64 pr-28"
                value={newTerm}
                onChange={(e) => setNewTerm(e.target.value)}
                ref={addTermInputRef}
              />
              <div className="absolute right-1 flex items-center space-x-1">
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setIsAddTermOpen(false)
                    setNewTerm('')
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => internalTermAdd(type, newTerm)}
                  disabled={!newTerm.trim().length}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2 mt-4 px-4">
        <Checkbox
          id={`${type}-select-all`}
          label={toggleSelectAllText}
          checked={isAtLeastOneSelected}
          onClick={toggleSelectAll}
          isIndeterminate={isAtLeastOneSelected && !isAllSelected}
          variant={CheckboxVariant.SELECT_ALL}
        />
      </div>
      <div className="grid grid-cols-2 gap-1 px-4 pb-3">
        {terms.map((term) => (
          <Checkbox
            key={term}
            label={term}
            value={term}
            checked={selectedTerms[type]?.includes(term) ?? false}
            onClick={() => handleTermCheckedChange(type, term)}
            id={term}
          />
        ))}
      </div>
    </div>
  )
}

export default TermGroup
