/* tslint:disable */
/* eslint-disable */
/**
 * PwC Deals API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiligenceDocument } from './DiligenceDocument';
import {
    DiligenceDocumentFromJSON,
    DiligenceDocumentFromJSONTyped,
    DiligenceDocumentToJSON,
} from './DiligenceDocument';

/**
 * 
 * @export
 * @interface DiligenceFollowUpQuestionRequest
 */
export interface DiligenceFollowUpQuestionRequest {
    /**
     * 
     * @type {string}
     * @memberof DiligenceFollowUpQuestionRequest
     */
    query: string;
    /**
     * 
     * @type {string}
     * @memberof DiligenceFollowUpQuestionRequest
     */
    reportSelectedText: string;
    /**
     * 
     * @type {Array<DiligenceDocument>}
     * @memberof DiligenceFollowUpQuestionRequest
     */
    documents: Array<DiligenceDocument>;
}

/**
 * Check if a given object implements the DiligenceFollowUpQuestionRequest interface.
 */
export function instanceOfDiligenceFollowUpQuestionRequest(value: object): value is DiligenceFollowUpQuestionRequest {
    if (!('query' in value) || value['query'] === undefined) return false;
    if (!('reportSelectedText' in value) || value['reportSelectedText'] === undefined) return false;
    if (!('documents' in value) || value['documents'] === undefined) return false;
    return true;
}

export function DiligenceFollowUpQuestionRequestFromJSON(json: any): DiligenceFollowUpQuestionRequest {
    return DiligenceFollowUpQuestionRequestFromJSONTyped(json, false);
}

export function DiligenceFollowUpQuestionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiligenceFollowUpQuestionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
        'reportSelectedText': json['report_selected_text'],
        'documents': ((json['documents'] as Array<any>).map(DiligenceDocumentFromJSON)),
    };
}

export function DiligenceFollowUpQuestionRequestToJSON(value?: DiligenceFollowUpQuestionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
        'report_selected_text': value['reportSelectedText'],
        'documents': ((value['documents'] as Array<any>).map(DiligenceDocumentToJSON)),
    };
}

