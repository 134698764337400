import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { ConfigProvider } from 'antd'
import _ from 'lodash'

import { initDatadog } from 'utils/datadog'
import { isSentryLoggingEnabled } from 'utils/env'
import { initSentry } from 'utils/sentry'

import Auth0ProviderWithRedirect from 'components/auth/auth-provider-with-redirect'

import App from './app'
import './index.css'
import reportWebVitals from './report-web-vitals'
import Services from './services'
import { environment, site } from './utils/server-data'

// We have to use an env var here so that the library gets tree-shaken
// out of the build. NODE_ENV is set to "development" in dev builds
// and "production" in prod builds.
if (process.env.NODE_ENV !== 'production') {
  import('@axe-core/react')
    .then(async (axe) => {
      await axe.default(React, ReactDOM, 1000)
    })
    .catch((error) => {
      Sentry.captureException(error)
    })
}

if (isSentryLoggingEnabled) {
  initSentry()
}

initDatadog()

Services.HoneyComb.HandleUncaughtErrors()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ConfigProvider theme={{ token: { colorPrimary: '#1452F1' } }}>
      <BrowserRouter>
        <Auth0ProviderWithRedirect>
          <App />
        </Auth0ProviderWithRedirect>
      </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>
)

console.info(
  `initialized app with service: ${process.env.REACT_APP_SERVICE}, at revision: ${process.env.REACT_APP_REVISION}, in environment: ${environment}, on site: ${site}, with locale: ${navigator.language}`
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

setInterval(() => {
  changeLoadingText(
    '.PSPDFKit-Progress > div:last-child',
    'Loading document viewer.'
  )
}, 100)

function changeLoadingText(querySelector: string, newText: string): void {
  const iframe = document.querySelector(
    // eslint-disable-next-line prefer-smart-quotes/prefer
    'iframe[title="PSPDFKit"]'
  ) as HTMLIFrameElement
  const iframeContentDoc = iframe?.contentDocument

  if (!_.isNil(iframeContentDoc)) {
    const targetElement = iframeContentDoc.querySelector(querySelector)
    if (!_.isNil(targetElement)) {
      targetElement.textContent = newText
    }
  }
}
