import React, { useCallback, useEffect, useRef, useState } from 'react'

import { PopoverContent } from '@radix-ui/react-popover'
import { isEqual } from 'lodash'

import { cn, isElementInView } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Popover, PopoverMenuItem, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'

export type NavigationMessage = {
  id: string
  title: string
}

export type Padding = {
  top?: number
  bottom?: number
}

export const useNavigation = (
  messages: NavigationMessage[],
  getPadding?: (index: number) => Padding
) => {
  const [isNavOpen, setNavOpen] = useState(false)
  const { trackEvent } = useAnalytics()

  const handleNavOpenChange = (open: boolean) => {
    setNavOpen(open)
    if (open) {
      trackEvent('Table of Contents Viewed', {
        thread_length: messages.length,
      })
    }
  }

  const [selectedMessageId, setSelectedMessageId] = useState('')
  const [visibleMessageIds, setVisibleMessageIds] = useState<string[]>([])

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const messageRefs = useRef<(HTMLDivElement | null)[]>([])

  const findVisibleMessages = useCallback(() => {
    if (!scrollRef.current) return
    const scrollEl = scrollRef.current as HTMLDivElement
    const scrollRect = scrollEl.getBoundingClientRect()

    const visibleIds: string[] = []
    messageRefs.current.forEach((ref, i) => {
      if (!ref) return

      const messageId = ref.id
      const messageRect = ref.getBoundingClientRect()
      const { top, bottom } = getPadding?.(i) || {}

      if (isElementInView(messageRect, scrollRect, { top, bottom })) {
        visibleIds.push(messageId)
      }
    })
    setVisibleMessageIds((prevIds) => {
      if (!isEqual(prevIds, visibleIds)) return visibleIds
      return prevIds
    })
  }, [getPadding])

  useEffect(() => {
    findVisibleMessages()
  }, [findVisibleMessages, messages])

  const scrollToMessageIndex = (index: number) => {
    messageRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    trackEvent('Table of Contents Clicked', {
      thread_length: messages.length,
      thread_number_clicked: index + 1,
    })
  }

  const navigationEl = (
    <div className="absolute bottom-0 left-0 top-0 z-10 flex items-center py-4">
      <Popover open={isNavOpen} onOpenChange={handleNavOpenChange}>
        <PopoverTrigger onMouseEnter={() => handleNavOpenChange(true)} asChild>
          <button
            className={cn(
              'flex flex-col space-y-1 p-2 opacity-100 outline-none transition-opacity duration-300',
              {
                'opacity-0': isNavOpen,
              }
            )}
          >
            {messages.map((message) => {
              const isVisible = visibleMessageIds.indexOf(message.id) > -1

              return (
                <div
                  className={cn(
                    'text-transparent h-0.5 w-[10px] overflow-hidden rounded-full bg-interactive/30',
                    {
                      'bg-interactive': isVisible,
                    }
                  )}
                  key={message.id}
                >
                  {message.title}
                </div>
              )
            })}
          </button>
        </PopoverTrigger>
        <PopoverContent
          className="popover-animate w-60 animate-fade-in-left"
          side="right"
          sideOffset={-20}
          onOpenAutoFocus={(e) => e.preventDefault()}
          onMouseLeave={() => setNavOpen(false)}
        >
          <div className="rounded-md border bg-primary">
            {/* -200px is not scientific, just a large enough number to clear header and some padding */}
            <ScrollArea className="p-2" maxHeight="max-h-[calc(100vh-200px)]">
              {messages.map((message, i) => {
                const isSelected =
                  message.id === selectedMessageId &&
                  visibleMessageIds.indexOf(message.id) > -1
                return (
                  <PopoverMenuItem
                    key={message.id}
                    className={cn({
                      text: isSelected,
                      'text-secondary hover:text-secondary': !isSelected,
                    })}
                    onClick={() => {
                      setSelectedMessageId(message.id)
                      scrollToMessageIndex(i)
                    }}
                  >
                    <span className="truncate text-xs">{message.title}</span>
                  </PopoverMenuItem>
                )
              })}
            </ScrollArea>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )

  return {
    navigationEl: messages.length > 1 ? navigationEl : null,
    messageRefs,
    scrollRef,
    unthrottledScroll: findVisibleMessages,
  }
}
