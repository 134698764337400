import React from 'react'

import { useNavigateWithQueryParams } from 'utils/routing'

import { SettingsPath } from 'components/base-app-path'
import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import SettingsLayout from 'components/settings/settings-layout'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Button } from 'components/ui/button'
import { Separator } from 'components/ui/separator'

import ExperimentUserInspectorCard from './experiment-user-inspector-card'
import ExperimentsOverviewCard from './experiments-overview-card'
import SettingsExperimentCreatorCard from './settings-experiment-creation-card'
import SettingsExperimentInspectorCard from './settings-experiment-inspector-card'
import SettingsExperimentInspectorCardOld from './settings-experiment-inspector-card-old'
import ParticipantInspectorCard from './settings-experiment-participant-inspector'

const SettingsExperimentManager: React.FC = () => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()

  if (!userInfo.IsResponseComparisonAdmin) return <></>

  return (
    <>
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
        isInternalAdmin
      />
      <SettingsLayout>
        <Button
          className="mt-2"
          onClick={() =>
            navigate(`${SettingsPath.InternalAdminExperimentManagement}/new`)
          }
        >
          Create New Experiment
        </Button>
        <ExperimentsOverviewCard />
        <div className="mt-6">
          <ExperimentUserInspectorCard />
        </div>
        {/* NOTE: can replace the below function with search functionality in ExperimentsOverviewCard */}
        <div className="mt-6">
          <SettingsExperimentInspectorCard />
        </div>

        <Separator className="mt-5" />
        <Accordion
          type="single"
          collapsible
          className="mt-5 w-full rounded-md border"
        >
          <AccordionItem value="archive">
            <AccordionTrigger className="p-4">Old version</AccordionTrigger>
            <AccordionContent className="p-4">
              <div>
                <SettingsExperimentInspectorCardOld />
              </div>
              <div className="mt-6">
                <ParticipantInspectorCard />
              </div>
              <div className="mt-6">
                <SettingsExperimentCreatorCard />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </SettingsLayout>
    </>
  )
}

export default SettingsExperimentManager
