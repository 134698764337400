import React, { useEffect, useState } from 'react'

import { ChevronLeft, ChevronsUpDown, Link, Lock, Users } from 'lucide-react'

import { useSharingStore } from 'stores/sharing-store'
import {
  PermissionLevel,
  PermissionsByUser,
  PermissionsByWorkspace,
  SharePopoverScreens,
  ShareType,
} from 'types/sharing'

import { displaySuccessMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { UpdatedWorkspaceAndUserSharingInfo } from 'components/vault/utils/vault-fetcher'

import SharedUserAndWorkspaceList from './shared-user-and-workspace-list'
import SharingUserInput from './sharing-user-input'

interface SharePopoverProps {
  shareType: ShareType
  isReadOnly: boolean
  ownerUserEmail: string | null
  permissionsByWorkspace: PermissionsByWorkspace
  permissionsByUser: PermissionsByUser
  onChangeSharedWithWorkspace: (sharedWithWorkspace: boolean) => void
  onGrantUserPermissions: (
    userIds: string[],
    permissionLevel: PermissionLevel
  ) => Promise<void>
  // TODO: can make this more generic once Assistant is also using this popover
  onUpdateSharePermissions: (
    params: UpdatedWorkspaceAndUserSharingInfo
  ) => Promise<void>
  disabled?: boolean
  disabledTooltip?: string
}

const SharePopover = ({
  shareType,
  isReadOnly,
  ownerUserEmail,
  permissionsByWorkspace,
  permissionsByUser,
  onChangeSharedWithWorkspace,
  onGrantUserPermissions,
  onUpdateSharePermissions,
  disabled,
  disabledTooltip,
}: SharePopoverProps) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const currentScreen = useSharingStore((state) => state.currentScreen)
  const setCurrentScreen = useSharingStore((state) => state.setCurrentScreen)
  const availablePermissions = useSharingStore(
    (state) => state.availablePermissions
  )
  const [didFetchSharingStatusFail, didFetchSharingUsersForWorkspaceFail] =
    useSharingStore((state) => [
      state.didFetchSharingStatusFail,
      state.didFetchSharingUsersForWorkspaceFail,
    ])

  const [open, setOpen] = useState(false)

  const [selectedUserIdsForShare, setSelectedUserIdsForShare] = useState<
    string[]
  >([])
  const [selectedPermissionLevelForShare, setSelectedPermissionLevelForShare] =
    useState<PermissionLevel>(availablePermissions[0])

  useEffect(() => {
    setSelectedPermissionLevelForShare(availablePermissions[0])
  }, [availablePermissions, setSelectedPermissionLevelForShare])

  const isWorkspaceSharingEnabled =
    shareType === ShareType.VAULT_PROJECT
      ? userInfo.workspace.sharingSettings.vault.workspaceLevel
      : userInfo.workspace.sharingSettings.assistant.workspaceLevel

  const isSharedWithWorkspace =
    permissionsByWorkspace.length > 0 && isWorkspaceSharingEnabled
  const isSharedWithUsers = permissionsByUser.length > 0

  const isShareButtonDisabled =
    disabled ||
    didFetchSharingStatusFail ||
    didFetchSharingUsersForWorkspaceFail

  const shareButtonDisabledTooltip = disabled
    ? disabledTooltip
    : didFetchSharingStatusFail
    ? 'Failed to fetch sharing status'
    : didFetchSharingUsersForWorkspaceFail
    ? 'Failed to fetch sharing users'
    : undefined

  const ShareIcon = isSharedWithUsers
    ? Users
    : isSharedWithWorkspace
    ? Link
    : Lock

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          disabled={isShareButtonDisabled}
          tooltip={shareButtonDisabledTooltip}
          onClick={() => {
            trackEvent('Share Button Clicked')
          }}
        >
          <div className="flex items-center gap-1">
            <Icon icon={ShareIcon} />
            <span className="text-sm">Share</span>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="mr-2 w-96 p-4 pt-3"
        onOpenAutoFocus={(e) => {
          if (currentScreen === SharePopoverScreens.SHARE) {
            e.preventDefault()
          }
        }}
      >
        <SharePopoverHeader
          currentScreen={currentScreen}
          setSelectedUserIdsForShare={setSelectedUserIdsForShare}
        />
        <SharePopoverBody
          shareType={shareType}
          isReadOnly={isReadOnly}
          ownerUserEmail={ownerUserEmail}
          permissionsByWorkspace={permissionsByWorkspace}
          permissionsByUser={permissionsByUser}
          onUpdateSharePermissions={onUpdateSharePermissions}
          selectedUserIdsForShare={selectedUserIdsForShare}
          selectedPermissionLevelForShare={selectedPermissionLevelForShare}
          setSelectedUserIdsForShare={setSelectedUserIdsForShare}
          setSelectedPermissionLevelForShare={
            setSelectedPermissionLevelForShare
          }
        />
        <SharePopoverFooter
          shareType={shareType}
          isReadOnly={isReadOnly}
          selectedUserIdsForShare={selectedUserIdsForShare}
          setSelectedUserIdsForShare={setSelectedUserIdsForShare}
          selectedPermissionLevelForShare={selectedPermissionLevelForShare}
          isSharedWithWorkspace={isSharedWithWorkspace}
          onChangeSharedWithWorkspace={onChangeSharedWithWorkspace}
          onGrantUserPermissions={onGrantUserPermissions}
          onClose={() => {
            setOpen(false)
            setCurrentScreen(SharePopoverScreens.SHARE)
            setSelectedUserIdsForShare([])
          }}
        />
      </PopoverContent>
    </Popover>
  )
}

const SharePopoverHeader = ({
  currentScreen,
  setSelectedUserIdsForShare,
}: {
  currentScreen: SharePopoverScreens
  setSelectedUserIdsForShare: (userIds: string[]) => void
}) => {
  const setCurrentScreen = useSharingStore((state) => state.setCurrentScreen)

  return (
    <div className="mb-2 flex h-8 items-center">
      {currentScreen === SharePopoverScreens.SHARE ? (
        <p className="font-medium">Share</p>
      ) : (
        <div className="flex items-center gap-1">
          <Button
            variant="ghost"
            size="smIcon"
            onClick={() => {
              setCurrentScreen(SharePopoverScreens.SHARE)
              setSelectedUserIdsForShare([])
            }}
          >
            <Icon icon={ChevronLeft} />
          </Button>
          <p className="font-medium">Grant permission</p>
        </div>
      )}
    </div>
  )
}

type SharePopoverBodyProps = Pick<
  SharePopoverProps,
  | 'shareType'
  | 'isReadOnly'
  | 'ownerUserEmail'
  | 'permissionsByWorkspace'
  | 'permissionsByUser'
  | 'onUpdateSharePermissions'
> & {
  selectedUserIdsForShare: string[]
  selectedPermissionLevelForShare: PermissionLevel
  setSelectedUserIdsForShare: (userIds: string[]) => void
  setSelectedPermissionLevelForShare: (permissionLevel: PermissionLevel) => void
}

const SharePopoverBody = ({
  shareType,
  isReadOnly,
  ownerUserEmail,
  permissionsByWorkspace,
  permissionsByUser,
  onUpdateSharePermissions,
  selectedUserIdsForShare,
  selectedPermissionLevelForShare,
  setSelectedUserIdsForShare,
  setSelectedPermissionLevelForShare,
}: SharePopoverBodyProps) => {
  const currentScreen = useSharingStore((state) => state.currentScreen)
  const inputExplanationText = (() => {
    if (shareType === ShareType.VAULT_PROJECT) {
      return currentScreen === SharePopoverScreens.SHARE
        ? 'Only people you‘ve granted access will be able to access this project'
        : 'The project will appear in the “Shared with you” section in Vault for people who‘ve been granted access'
    } else {
      // TODO: replace this with the assistant sharing text
      return 'Only people you‘ve granted access will be able to access this project'
    }
  })()

  return (
    <div className="border-b pb-2">
      {!isReadOnly && (
        <SharingUserInput
          inputExplanationText={inputExplanationText}
          permissionsByUser={permissionsByUser}
          selectedUserIdsForShare={selectedUserIdsForShare}
          selectedPermissionLevelForShare={selectedPermissionLevelForShare}
          setSelectedUserIdsForShare={setSelectedUserIdsForShare}
          setSelectedPermissionLevelForShare={
            setSelectedPermissionLevelForShare
          }
        />
      )}
      {currentScreen === SharePopoverScreens.SHARE && (
        <SharedUserAndWorkspaceList
          isReadOnly={isReadOnly}
          ownerUserEmail={ownerUserEmail}
          permissionsByWorkspace={permissionsByWorkspace}
          permissionsByUser={permissionsByUser}
          onUpdateSharePermissions={onUpdateSharePermissions}
        />
      )}
    </div>
  )
}

type SharePopoverFooterProps = Pick<
  SharePopoverProps,
  | 'shareType'
  | 'isReadOnly'
  | 'onChangeSharedWithWorkspace'
  | 'onGrantUserPermissions'
> & {
  onClose: () => void
  isSharedWithWorkspace: boolean
  selectedUserIdsForShare: string[]
  setSelectedUserIdsForShare: (userIds: string[]) => void
  selectedPermissionLevelForShare: PermissionLevel
}

const SharePopoverFooter = ({
  isSharedWithWorkspace,
  onChangeSharedWithWorkspace,
  shareType,
  isReadOnly,
  selectedUserIdsForShare,
  setSelectedUserIdsForShare,
  selectedPermissionLevelForShare,
  onGrantUserPermissions,
  onClose,
}: SharePopoverFooterProps) => {
  const currentScreen = useSharingStore((state) => state.currentScreen)
  return (
    <div className="mt-4">
      {currentScreen === SharePopoverScreens.SHARE && (
        <WorkspaceSharingOptions
          shareType={shareType}
          isReadOnly={isReadOnly}
          isSharedWithWorkspace={isSharedWithWorkspace}
          onChangeSharedWithWorkspace={onChangeSharedWithWorkspace}
        />
      )}
      {currentScreen === SharePopoverScreens.GRANT_USER_PERMISSION && (
        <GrantUserPermissionsOptions
          selectedUserIdsForShare={selectedUserIdsForShare}
          setSelectedUserIdsForShare={setSelectedUserIdsForShare}
          selectedPermissionLevelForShare={selectedPermissionLevelForShare}
          onGrantUserPermissions={onGrantUserPermissions}
          onClose={onClose}
        />
      )}
    </div>
  )
}

type WorkspaceSharingOptionsProps = Pick<
  SharePopoverProps,
  'shareType' | 'isReadOnly' | 'onChangeSharedWithWorkspace'
> & { isSharedWithWorkspace: boolean }
const WorkspaceSharingOptions = ({
  shareType,
  isReadOnly,
  isSharedWithWorkspace,
  onChangeSharedWithWorkspace,
}: WorkspaceSharingOptionsProps) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const copyLinkToClipboard = async () => {
    trackEvent('Vault project share link copied')
    try {
      await navigator.clipboard.writeText(window.location.href)
      displaySuccessMessage('Link copied to clipboard')
    } catch (err) {
      console.error('Failed to copy link: ', err)
    }
  }

  const workspaceShareDisabled =
    shareType === ShareType.VAULT_PROJECT
      ? !userInfo.workspace.sharingSettings.vault.workspaceLevel
      : !userInfo.workspace.sharingSettings.assistant.workspaceLevel

  return (
    <div className="flex w-full justify-between gap-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={isReadOnly}>
          <Button
            variant="outline"
            className="flex w-full items-center justify-between"
            disabled={isReadOnly}
          >
            <div>
              <div className="flex items-center gap-1">
                {isSharedWithWorkspace ? (
                  <Icon icon={Users} />
                ) : (
                  <Icon icon={Lock} />
                )}
                <span className="text-sm">
                  {isSharedWithWorkspace
                    ? 'Anyone in your workspace'
                    : 'Restricted'}
                </span>
              </div>
            </div>
            <Icon icon={ChevronsUpDown} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-80 p-2" align="start">
          <DropdownMenuItem
            onClick={() => {
              onChangeSharedWithWorkspace(false)
              trackEvent('Vault project unshared with workspace')
            }}
            className="flex items-center gap-2"
          >
            <Icon icon={Lock} />
            <div className="flex flex-col">
              <p className="text-sm">Restricted</p>
              <p className="text-xs text-muted">
                {shareType === ShareType.VAULT_PROJECT
                  ? `This project is only accessible to you, and to people you've granted access`
                  : 'This page is only accessible to you'}
              </p>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              onChangeSharedWithWorkspace(true)
              trackEvent('Vault project shared with workspace', {
                isEnabled: true,
              })
            }}
            className="flex items-center gap-2"
            disabled={workspaceShareDisabled}
          >
            <Icon icon={Users} />
            <div className="flex flex-col">
              <p className="text-sm">Anyone in your workspace</p>
              <p className="text-xs text-muted">
                {shareType === ShareType.VAULT_PROJECT
                  ? 'This project can be accessed by anyone in your workspace who has a link to it'
                  : 'This page can be viewed by people on your team with a link to this page'}
              </p>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Button
        className="min-w-24 px-4"
        disabled={!isSharedWithWorkspace}
        onClick={copyLinkToClipboard}
      >
        <span className="whitespace-nowrap text-sm">Copy link</span>
      </Button>
    </div>
  )
}

const GrantUserPermissionsOptions = ({
  selectedUserIdsForShare,
  setSelectedUserIdsForShare,
  selectedPermissionLevelForShare,
  onGrantUserPermissions,
  onClose,
}: {
  selectedUserIdsForShare: string[]
  setSelectedUserIdsForShare: (userIds: string[]) => void
  selectedPermissionLevelForShare: PermissionLevel
  onGrantUserPermissions: (
    userIds: string[],
    permissionLevel: PermissionLevel
  ) => Promise<void>
  onClose: () => void
}) => {
  const { trackEvent } = useAnalytics()
  const isSharing = useSharingStore((state) => state.isSharing)
  const setCurrentScreen = useSharingStore((state) => state.setCurrentScreen)
  return (
    <div className="flex w-full justify-end gap-2">
      <Button
        variant="outline"
        onClick={() => {
          trackEvent('Vault project sharing cancel button clicked', {
            userCount: selectedUserIdsForShare.length,
            permissionLevel: selectedPermissionLevelForShare,
          })
          onClose()
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={selectedUserIdsForShare.length === 0}
        onClick={async () => {
          await onGrantUserPermissions(
            selectedUserIdsForShare,
            selectedPermissionLevelForShare
          )
          trackEvent('Vault project shared with users', {
            userCount: selectedUserIdsForShare.length,
            permissionLevel: selectedPermissionLevelForShare,
          })
          setCurrentScreen(SharePopoverScreens.SHARE)
          setSelectedUserIdsForShare([])
        }}
        isLoading={isSharing}
      >
        Grant permission
      </Button>
    </div>
  )
}

export default SharePopover
