import React from 'react'

import { File, Info, Trash, UploadIcon } from 'lucide-react'
import pluralize from 'pluralize'

import Services from 'services'
import { Maybe } from 'types'
import { WorkflowType } from 'types/workflows'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import Banner from 'components/ui/banner'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { ScrollArea } from 'components/ui/scroll-area'
import { TextLink } from 'components/ui/text-link'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'

import { useAssistantStore } from './assistant-store'
import {
  ASSISTANT_ISSUES_LIST_REDIRECT_CLICKED_METRIC,
  AssistantDocument,
  MAX_FILES,
} from './assistant-utils'

interface AssistantInputFilesProps {
  open: () => void
  uploadedFiles: Maybe<AssistantDocument[]>
  setUploadedFiles: (files: AssistantDocument[]) => void
}

const AssistantInputFiles = ({
  open,
  uploadedFiles,
  setUploadedFiles,
}: AssistantInputFilesProps): JSX.Element => {
  const {
    internetBrowsing,
    isLoading,
    hasRedlineDoc,
    setActiveDocument,
    setHasRedlineDoc,
  } = useAssistantStore()

  const userInfo = useAuthUser()

  const { trackEvent } = useAnalytics()

  const handleFileRemove = (name: string) => {
    setUploadedFiles((uploadedFiles ?? []).filter((file) => file.name !== name))
  }

  const handleFileClick = (file: AssistantDocument) => {
    setActiveDocument(file)
  }

  const fileUploadDisabled =
    internetBrowsing || (uploadedFiles?.length ?? 0) >= MAX_FILES || isLoading

  const handleRemoveFile = (
    e: React.MouseEvent<HTMLButtonElement>,
    fileName: string
  ) => {
    e.stopPropagation()
    handleFileRemove(fileName)
  }

  const bannerDescription = (
    <div className="*:text-sm">
      <p>
        The file {uploadedFiles?.[0]?.name} you have uploaded appears to be a
        redlined document.
      </p>
      <p>
        You can now use our{' '}
        <TextLink
          label="Redline analysis workflow"
          href={WorkflowTypeToDetails[WorkflowType.REDLINE_ANALYSIS].path}
          openInNewTab
          linkClickCallback={() => {
            setHasRedlineDoc(false)
            Services.HoneyComb.Record({
              metric: ASSISTANT_ISSUES_LIST_REDIRECT_CLICKED_METRIC,
              user: userInfo.id,
              workspace: userInfo.workspace.slug,
            })
            trackEvent('Assistant to Redlines Issues List Redirect Clicked')
          }}
        />
        to extract issues from and query your redlined documents.
      </p>
    </div>
  )

  return (
    <Card className="flex h-full flex-col overflow-clip rounded-none border-none">
      <div className="flex items-center justify-between border-b bg-muted px-4 py-3">
        <div className="flex flex-wrap items-baseline gap-2">
          <p className="font-semibold">Documents</p>
          <p className="text-xs text-muted">
            {`${uploadedFiles?.length} ${pluralize(
              'file',
              uploadedFiles?.length
            )} uploaded`}
          </p>
        </div>
        <div className="flex flex-row-reverse flex-wrap gap-2">
          <Button
            variant="outline"
            onClick={open}
            disabled={fileUploadDisabled}
            size="sm"
            data-testid="assistant--upload-files"
          >
            <UploadIcon size={12} className="mr-1" />
            Upload
          </Button>
          <Button
            variant="outline"
            onClick={() => setUploadedFiles([])}
            size="sm"
            disabled={isLoading}
            data-testid="assistant--clear-files"
          >
            Clear documents
          </Button>
        </div>
      </div>

      <ScrollArea className="h-full">
        <div
          className="h-full space-y-0.5 px-3 py-2"
          data-testid="assistant-file-list"
        >
          {(uploadedFiles ?? []).map(
            (file: AssistantDocument, index: number) => {
              return (
                <button
                  key={index}
                  className="flex w-full items-center justify-between rounded px-2 py-1 transition hover:bg-muted"
                  onClick={() => handleFileClick(file)}
                >
                  <div className="flex w-full items-center text-left">
                    <File size={14} className="mr-2 shrink-0" />
                    <Tooltip>
                      <TooltipTrigger>
                        <p className="line-clamp-1 break-all text-start">
                          {file.name}
                        </p>
                      </TooltipTrigger>
                      <TooltipContent side="top">{file.name}</TooltipContent>
                    </Tooltip>
                  </div>
                  <button
                    className="rounded p-1 transition hover:bg-button-secondary-hover"
                    disabled={isLoading}
                    onClick={(e) => handleRemoveFile(e, file.name)}
                    data-testid="assistant--delete-file"
                  >
                    <Trash size={14} />
                  </button>
                </button>
              )
            }
          )}
        </div>
      </ScrollArea>
      {userInfo.IsRedlinesUser && (
        <BasicTransition
          show={hasRedlineDoc}
          className="absolute bottom-0 z-20 -ml-[1px] w-full p-2"
          entryTransitionDuration="slow"
          exitTransitionDuration="slow"
        >
          <Banner
            title="Redlined document detected!"
            description={bannerDescription}
            leadingIcon={<Info />}
            onClose={() => setHasRedlineDoc(false)}
          />
        </BasicTransition>
      )}
    </Card>
  )
}

export default AssistantInputFiles
