import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import _ from 'lodash'

import { fetchRoleByIdOrPk, WorkspaceRole } from 'models/roles'
import { FetchWorkspace, Workspace } from 'models/workspace'
import { Maybe } from 'types'

import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import SettingsLayout from 'components/settings/settings-layout'
import Tag from 'components/ui/tag'

import RolesPermsCard from './roles-perms-card'
import RolesUsersCard from './roles-users-card'

const WorkspaceRolesInspector = () => {
  const userInfo = useAuthUser()
  const location = useLocation()
  const roleId: string = location.state?.roleId
  const [rolePermCount, setRolePermCount] = React.useState<number>(0)
  const [roleUserCount, setRoleUserCount] = React.useState<number>(0)
  const [workspaceRole, setWorkspaceRole] =
    React.useState<Maybe<WorkspaceRole>>(null)
  const [workspace, setWorkspace] = React.useState<Maybe<Workspace>>(null)

  useEffect(() => {
    const fetchWorkspaceRole = async () => {
      const role = await fetchRoleByIdOrPk(roleId)
      setWorkspaceRole(role)
      if (role) {
        const workspace = await FetchWorkspace(role.workspaceId)
        setWorkspace(workspace)
      }
    }
    fetchWorkspaceRole()
  }, [roleId])

  if (
    _.isNil(workspaceRole) ||
    _.isNil(workspace) ||
    !userInfo.IsInternalAdminReader
  ) {
    return null
  }

  return (
    <>
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
        isInternalAdmin
      />
      <SettingsLayout>
        <div className="space-y-5">
          <div className="flex flex-row space-x-3">
            <p className="font-bold text-xl text-secondary">
              {_.startCase(workspaceRole.name)}
            </p>
            <Tag text={workspaceRole.roleId} />
          </div>
          <div>
            <RolesUsersCard
              workspaceRole={workspaceRole}
              setRoleUserCount={setRoleUserCount}
              rolePermCount={rolePermCount}
              workspace={workspace}
            />
          </div>
          <div>
            <RolesPermsCard
              workspaceRole={workspaceRole}
              setRolePermCount={setRolePermCount}
              roleUserCount={roleUserCount}
              workspace={workspace}
            />
          </div>
        </div>
      </SettingsLayout>
    </>
  )
}

export default WorkspaceRolesInspector
