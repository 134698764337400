import React from 'react'

import { ChevronsUpDown } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from './button'
import { Checkbox } from './checkbox'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from './command'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { ScrollArea } from './scroll-area'

export type MultiSelectEntry = {
  text: string
  value: string
  values?: string[]
}

interface MultiSelectProps {
  placeholder: string
  sortedEntries: MultiSelectEntry[]
  sortedGroups?: { label: string; entries: MultiSelectEntry[] }[]
  selectedValues: string[]
  setSelectedValues: (selectedValues: string[]) => void
  disabled?: boolean
  className?: string
  align?: 'center' | 'start' | 'end'
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  placeholder,
  sortedEntries,
  sortedGroups,
  selectedValues: selectedValuesProp,
  setSelectedValues,
  disabled,
  className,
  align = 'center',
}) => {
  const [open, setOpen] = React.useState(false)
  const selectedValues = React.useMemo(
    () => new Set(selectedValuesProp),
    [selectedValuesProp]
  )

  const getIsSelected = React.useCallback(
    (entry: MultiSelectEntry) => {
      return !!(
        selectedValues.has(entry.value) ||
        entry.values?.some((value) => selectedValues.has(value))
      )
    },
    [selectedValues]
  )

  const handleSelect = React.useCallback(
    (entry: MultiSelectEntry, shouldClosePopover: boolean) => {
      const value = entry.value
      const isSelected = getIsSelected(entry)
      if (isSelected) {
        selectedValues.delete(value)
        entry.values?.forEach((val) => {
          selectedValues.delete(val)
        })
      } else {
        selectedValues.add(value)
      }
      setSelectedValues(Array.from(selectedValues))
      if (shouldClosePopover) {
        setOpen(false)
      }
    },
    [getIsSelected, selectedValues, setSelectedValues, setOpen]
  )

  const groupEntries = (sortedGroups || []).reduce(
    (entries: MultiSelectEntry[], group) => {
      entries = entries.concat(group.entries)
      return entries
    },
    []
  )

  const renderButtonText = React.useMemo(() => {
    if (selectedValues.size === 0) {
      return placeholder
    }

    const firstSelectedItem = [...groupEntries, ...sortedEntries].find(
      (entry) => selectedValues.has(entry.value)
    )
    if (selectedValues.size > 1) {
      return `${selectedValues.size} selected`
    }

    return firstSelectedItem?.text
  }, [placeholder, selectedValues, groupEntries, sortedEntries])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          disabled={disabled}
          className={cn(
            'justify-between disabled:cursor-not-allowed',
            className
          )}
          data-testid="multiselect-open-popover-button"
        >
          <span className="line-clamp-1 text-left text-sm">
            {renderButtonText}
          </span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-auto min-w-[180px] max-w-96 p-0"
        align={align}
      >
        <Command>
          <CommandInput
            placeholder="Search"
            data-testid="multiselect-search-input"
          />
          <CommandList>
            <ScrollArea maxHeight="max-h-56">
              <CommandEmpty>No results found.</CommandEmpty>
              {sortedGroups?.map((group) => {
                return (
                  <React.Fragment key={group.label}>
                    <CommandGroup heading={group.label}>
                      {group.entries.map((entry) => (
                        <CommandItem
                          key={entry.value}
                          onSelect={() => handleSelect(entry, true)}
                        >
                          <Checkbox
                            checked={getIsSelected(entry)}
                            // We don't want the popover to close when clicking on the checkbox
                            onClick={(e) => e.stopPropagation()}
                            onCheckedChange={() => handleSelect(entry, false)}
                            checkboxClassName="mr-2"
                            label={entry.text}
                          />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                    <CommandSeparator />
                  </React.Fragment>
                )
              })}
              <CommandGroup>
                {sortedEntries.map((entry) => (
                  <CommandItem
                    key={entry.value}
                    onSelect={() => handleSelect(entry, true)}
                  >
                    <Checkbox
                      checked={getIsSelected(entry)}
                      // We don't want the popover to close when clicking on the checkbox
                      onClick={(e) => e.stopPropagation()}
                      onCheckedChange={() => handleSelect(entry, false)}
                      checkboxClassName="mr-2"
                      label={entry.text}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
              {selectedValues.size > 0 && sortedEntries.length > 1 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => setSelectedValues([])}
                      className="justify-center text-center"
                    >
                      Clear all
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export { MultiSelect }
