import React from 'react'

import _ from 'lodash'

import {
  LIBRARY_BATCH_SIZE,
  useLibraryPollingQuery,
} from 'models/queries/use-library-polling-query'

import { LibraryItemKind } from './library-types'

type Props = {
  setIsLoading: (isLoading: boolean) => void
  setItems: (items: any) => void
  updateLastUpdatedTime: () => void
  type: LibraryItemKind
}

export const useLibraryDataProvider = ({
  setIsLoading,
  setItems,
  updateLastUpdatedTime,
  type,
}: Props) => {
  const { data, isPending, isFetchingOlder, isFetchingNewer, fetchOnePoll } =
    useLibraryPollingQuery({
      fetchLibraryArgs: {
        pageNumber: 1,
        pageSize: LIBRARY_BATCH_SIZE,
        libraryType: type,
      },
      oldestDate: new Date(0), // Returns January 1, 1970, 00:00:00 UTC
      queryOptions: {
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        select: (result) => {
          return { ...result, pages: result.pages }
        },
      },
      updateLastUpdatedTime,
    })

  const fetchUpdate = React.useCallback(() => fetchOnePoll(), [fetchOnePoll])

  const debouncedSetIsLoading = React.useMemo(
    () => _.debounce(setIsLoading, 250),
    [setIsLoading]
  )
  React.useEffect(() => {
    if (isFetchingOlder || isFetchingNewer || isPending) {
      debouncedSetIsLoading.cancel() // Cancel any pending debounced calls
      setIsLoading(true)
    } else {
      debouncedSetIsLoading(false)
    }
    return () => debouncedSetIsLoading.cancel()
  }, [
    isFetchingOlder,
    isFetchingNewer,
    setIsLoading,
    isPending,
    debouncedSetIsLoading,
  ])

  React.useEffect(() => {
    // TODO: Better pattern for optimistic deletes
    const newFilteredItems = data.filter((item) => !item.deletedAt)
    setItems(newFilteredItems)
  }, [data, setItems])

  return {
    data,
    fetchUpdate,
  }
}
