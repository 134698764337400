import React, { useState } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'

import { Workspace } from 'models/workspace'
import { Maybe } from 'types'

import { PWD_AUTH_CONN } from 'utils/routing'

import { MismatchedUserWorkspaceDomainAlert } from 'components/settings/workspace/workspace-details/mismatch-user-workspace-domain-alert'
import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'

interface BulkUpsertUserModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onUpsert: () => void
  mismatchDomainUsers: string[]
  selectedUsers: string[]
  workspace: Maybe<Workspace>
  customerFacing: boolean
  type: 'add' | 'move'
  movingWithPerms?: boolean
  orgDomains: string[]
}

const BulkUpsertUserModal = ({
  open,
  onOpenChange,
  onUpsert,
  workspace,
  mismatchDomainUsers,
  selectedUsers,
  customerFacing,
  type,
  movingWithPerms,
  orgDomains,
}: BulkUpsertUserModalProps) => {
  const workspaceIsSaml = !!workspace && workspace?.authConn !== PWD_AUTH_CONN
  const [samlAcknowledge, setSamlAcknowledge] = useState(false)
  const [domainMismatchAcknowledge, setDomainMismatchAcknowledge] =
    useState(false)
  const [moveWithPermsAcknowledge, setMoveWithPermsAcknowledge] =
    useState(false)

  const hasMismatchDomainUsers = mismatchDomainUsers.length > 0
  const workspaceCanBypassDomainCheck =
    workspace?.settings.allowBypassDomainCheck

  const hasMismatchDomainsNotAllowed =
    hasMismatchDomainUsers && !workspaceCanBypassDomainCheck

  const totalUsers = selectedUsers.length

  const getSubmitDisabledTooltip = () => {
    if (hasMismatchDomainUsers && !domainMismatchAcknowledge) {
      return 'Some users domains don’t match expected domains'
    } else if (movingWithPerms && !moveWithPermsAcknowledge) {
      return 'Acknowledgement required for moving with permissions'
    } else if (workspaceIsSaml && !samlAcknowledge) {
      return 'Acknowledgement required for SAML workspaces'
    }
    return null
  }

  const submitDisabledTooltip = getSubmitDisabledTooltip()
  const submitDisabled = submitDisabledTooltip !== null

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[600px]" showCloseIcon={false}>
        <DialogTitle>
          {type === 'add' ? 'Add' : 'Move'} {pluralize('user', totalUsers)}
        </DialogTitle>
        <p>
          {!hasMismatchDomainsNotAllowed && !!workspace && (
            <div>
              <div>
                {`The following ${totalUsers} ${pluralize(
                  'users',
                  totalUsers
                )} will be ${type === 'add' ? 'added' : 'moved'} `}
                to {_.startCase(workspace.clientName ?? workspace.slug)}
                <br />
                {type === 'move' && !movingWithPerms && (
                  <p>
                    Any permissions for users being moved will <b>not</b> be
                    moved.
                  </p>
                )}
              </div>
              <div className="mt-2 flex flex-wrap gap-1">
                {selectedUsers.map((email) => (
                  <span key={email}>
                    <Badge variant="outline">{email}</Badge>
                  </span>
                ))}
              </div>
              {type === 'move' && movingWithPerms && (
                <Alert className="mt-4" variant="destructive">
                  <AlertTitle className="-mt-1 flex justify-between">
                    <p>Moving with permissions</p>
                    <Checkbox
                      checked={moveWithPermsAcknowledge}
                      onCheckedChange={() =>
                        setMoveWithPermsAcknowledge(!moveWithPermsAcknowledge)
                      }
                    />
                  </AlertTitle>
                  <AlertDescription>
                    {`Please acknowledge you want to move ${totalUsers} ${pluralize(
                      'users',
                      totalUsers
                    )} with their current user permissions. This only copies over non-sensitive and non-internal perms and applies to all users being moved.`}
                  </AlertDescription>
                </Alert>
              )}
            </div>
          )}
        </p>

        {!!workspace && (
          <div className="space-y-4">
            {/* Don't show saml ack if hard blocked by domain mismatch */}
            {workspaceIsSaml && !hasMismatchDomainsNotAllowed && (
              <Alert className="mt-2">
                <AlertTitle className="-mt-1 flex justify-between">
                  <p>SAML workspace detected</p>
                  <Checkbox
                    checked={samlAcknowledge}
                    onCheckedChange={() => setSamlAcknowledge(!samlAcknowledge)}
                  />
                </AlertTitle>
                <AlertDescription>
                  {`Please acknowledge you want to ${
                    type === 'add' ? 'Add' : 'Move'
                  } ${totalUsers} ${pluralize(
                    'users',
                    totalUsers
                  )} to a workspace with SAML setup.`}
                </AlertDescription>
              </Alert>
            )}
            {hasMismatchDomainUsers && (
              <MismatchedUserWorkspaceDomainAlert
                mismatchDomainUsers={mismatchDomainUsers}
                workspace={workspace}
                domainMismatchAcknowledge={domainMismatchAcknowledge}
                setDomainMismatchAcknowledge={setDomainMismatchAcknowledge}
                blockSubmittingMismatch={hasMismatchDomainsNotAllowed}
                customerFacing={customerFacing}
                orgDomains={orgDomains}
              />
            )}
          </div>
        )}
        <DialogFooter>
          <Button
            variant="ghost"
            onClick={() => {
              setSamlAcknowledge(false)
              setDomainMismatchAcknowledge(false)
              setMoveWithPermsAcknowledge(false)
              onOpenChange(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setSamlAcknowledge(false)
              setDomainMismatchAcknowledge(false)
              setMoveWithPermsAcknowledge(false)
              onUpsert()
            }}
            disabled={submitDisabled}
            tooltip={submitDisabledTooltip ?? undefined}
          >
            {`${type === 'add' ? 'Add' : 'Move'} ${totalUsers} ${pluralize(
              'users',
              totalUsers
            )}`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default BulkUpsertUserModal
