import React from 'react'

import _ from 'lodash'

import {
  EMPTY_QUERY_HELP_TEXT,
  IS_LOADING_HELP_TEXT,
  UPLOAD_DOCUMENT_HELP_TEXT,
} from 'utils/tooltip-texts'

import AskHarveyButton from 'components/common/ask-harvey-button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from 'components/ui/select'

import { useTranslationStore } from './translation-store'
import { TRANSLATION_OPTIONS, labelFromCode } from './translation-utils'

type Props = {
  onSubmit: () => void
}

const PromptInput: React.FC<Props> = ({ onSubmit }) => {
  const { setTask, query, isLoading, activeDocument } = useTranslationStore()

  const onQueryChange = (value: string): void => {
    setTask({ query: value })
  }

  const isAskHarveyDisabled =
    !activeDocument || !query.trim().length || isLoading

  const getButtonTooltip = () => {
    if (!activeDocument) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (!query.trim().length) {
      return EMPTY_QUERY_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else {
      return ''
    }
  }

  return (
    <div className="space-y-2">
      <Select onValueChange={onQueryChange} value={query}>
        <SelectTrigger
          className="w-full text-sm"
          data-testid="translation-select"
        >
          {/* Note (Adam): We are not using SelectValue below because it was not resetting to the placeholder value properly */}
          {/* <SelectValue placeholder="Select language" /> */}
          {!(_.isNil(query) || query === '')
            ? labelFromCode(query)
            : 'Select language'}
        </SelectTrigger>
        <SelectContent>
          {TRANSLATION_OPTIONS.map((option) => (
            <SelectItem
              key={option.code}
              value={option.code}
              data-testid="translation-select-option"
            >
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <div className="flex flex-col">
        <AskHarveyButton
          handleSubmit={onSubmit}
          disabled={isAskHarveyDisabled}
          tooltip={getButtonTooltip()}
          size="lg"
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default PromptInput
