import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useInterval } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'
import { PermissionLevel } from 'types/sharing'

import { doesUserHavePermission } from 'utils/sharing-helpers'
import { cn } from 'utils/utils'

import VaultFileExplorerToolbelt from './components/file-explorer/vault-file-explorer-toolbelt'
import VaultCustomWorkflow from './components/vault-query-box/vault-custom-workflow'
import VaultQueryBox from './components/vault-query-box/vault-query-box'
import VaultRecentQueries from './components/vault-recent-queries'
import VaultSectionBreadcrumb from './components/vault-section-breadcrumb'
import { BaseAppPath } from 'components/base-app-path'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import VaultFileExplorerActions from 'components/vault/components/file-explorer/vault-file-explorer-actions'
import VaultFileExplorerBreadcrumbs from 'components/vault/components/file-explorer/vault-file-explorer-breadcrumbs'
import VaultProgress from 'components/vault/components/vault-progress'
import {
  GenerateN1ResponseProps,
  GenerateNNResponseProps,
  GenerateQuestionsProps,
} from 'components/vault/utils/vault'
import { FetchVaultFolder } from 'components/vault/utils/vault-fetcher'
import { fetchProjectMetadata } from 'components/vault/utils/vault-helpers'

import useSharingPermissions from './hooks/use-sharing-permissions'
import { QueryQuestionsResponseData } from './utils/vault'
import { useVaultSharingStore } from './utils/vault-sharing-store'
import { useVaultStore } from './utils/vault-store'

const VaultProjectDetail = ({
  generateQuestions,
  generateNNResponse,
  generateN1Response,
}: {
  generateQuestions: (
    props: GenerateQuestionsProps
  ) => Promise<QueryQuestionsResponseData>
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  generateN1Response: (props: GenerateN1ResponseProps) => Promise<void>
}) => {
  const { projectId } = useParams()
  const userInfo = useAuthUser()

  const currentProjectMetadata = useVaultStore(
    (state) => state.currentProjectMetadata
  )

  const isSidebarOpen = useGeneralStore(
    useShallow((state) => state.isSidebarOpen)
  )
  const requiresProjectDataRefetch = useVaultStore(
    useShallow((state) => state.requiresProjectDataRefetch)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )
  const currentUserPermissionByProjectId = useVaultSharingStore(
    useShallow((s) => s.currentUserPermissionByProjectId)
  )
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((s) => s.permissionsByProjectId)
  )
  const setCurrentProject = useVaultStore(
    useShallow((s) => s.setCurrentProject)
  )
  const upsertVaultFolders = useVaultStore(
    useShallow((s) => s.upsertVaultFolders)
  )
  const upsertVaultFiles = useVaultStore(useShallow((s) => s.upsertVaultFiles))
  const deleteVaultFolders = useVaultStore(
    useShallow((s) => s.deleteVaultFolders)
  )
  const addToProjectsMetadata = useVaultStore(
    useShallow((s) => s.addToProjectsMetadata)
  )

  const isExampleProject = useMemo(
    () => projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )
  const setRequiresProjectDataRefetch = useVaultStore(
    (state) => state.setRequiresProjectDataRefetch
  )
  const setError = useVaultStore((state) => state.setError)

  const isFilesProcessing =
    // If there are incomplete files, we want to poll for the update
    currentProjectMetadata.completedFiles !==
      currentProjectMetadata.totalFiles ||
    // If there are failed files, we want to poll for the update, because the files
    // may be re-processed
    currentProjectMetadata.failedFiles > 0

  const pollProjectData = useCallback(async () => {
    if (!projectId) return
    try {
      const project = await FetchVaultFolder(projectId, true)
      setCurrentProject(project)
      // fetch the metadata for the folder and its subfolders and files
      const projectMetadataResponse = await fetchProjectMetadata(project)
      const projectData = projectMetadataResponse[projectId]
      upsertVaultFolders(
        [project, ...(projectData.descendantFolders || [])],
        userInfo.dbId
      )
      upsertVaultFiles(projectData.descendantFiles || [])
      addToProjectsMetadata(projectMetadataResponse)
    } catch (e) {
      console.error(e)
    }
  }, [
    projectId,
    setCurrentProject,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    userInfo.dbId,
  ])

  useInterval(pollProjectData, isFilesProcessing ? 10000 : null)

  useEffect(() => {
    const refetchProjectData = async () => {
      await pollProjectData()
      setRequiresProjectDataRefetch(false)
    }
    if (requiresProjectDataRefetch) {
      void refetchProjectData()
    }
  }, [
    requiresProjectDataRefetch,
    pollProjectData,
    setRequiresProjectDataRefetch,
  ])

  useEffect(() => {
    const isCurrentUserNotProjectOwner =
      currentProjectMetadata.userId.trim() !== '' &&
      currentProjectMetadata.userId !== userInfo.dbId

    const isVaultSharingEnabled = userInfo.IsVaultSharingUser
    if (isVaultSharingEnabled) {
      // if sharing is enabled, only show error after fetching current user's project permission level
      if (
        isCurrentUserNotProjectOwner &&
        !isExampleProject &&
        projectId &&
        permissionsByProjectId[projectId] &&
        !doesUserHavePermission({
          currentPermissionLevel: currentUserPermissionByProjectId[projectId],
          requiredPermissionLevel: PermissionLevel.VIEW,
          isOwner: currentProjectMetadata.userId === userInfo.dbId,
        })
      ) {
        deleteVaultFolders([projectId])
        setError({
          message:
            'You are not authorized to access this vault project.\nContact support@harvey.ai if this issue persists.',
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
      }
    } else {
      if (isCurrentUserNotProjectOwner && !isExampleProject) {
        deleteVaultFolders([projectId!])
        setError({
          message:
            'You are not authorized to access this vault project.\nContact support@harvey.ai if this issue persists.',
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
      }
    }
  }, [
    currentProjectMetadata,
    userInfo,
    deleteVaultFolders,
    setError,
    isExampleProject,
    permissionsByProjectId,
    currentUserPermissionByProjectId,
    projectId,
  ])

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId,
  })
  const showCustomWorkflow = useMemo(() => {
    return (
      userInfo.IsVaultReviewUser &&
      userInfo.IsVaultWorkflowRepsWarrantiesUser &&
      !isExampleProject &&
      doesCurrentUserHaveEditPermission
    )
  }, [userInfo, isExampleProject, doesCurrentUserHaveEditPermission])

  return (
    <AppMain hasContainer>
      {/* Mask to hide scroll text. Required because the query box is fixed position*/}
      <div
        className={cn('fixed left-48 right-4 z-10 -mt-4 h-4 bg-primary', {
          'left-16': !isSidebarOpen,
        })}
      />
      <VaultQueryBox
        generateQuestions={generateQuestions}
        generateNNResponse={generateNNResponse}
        generateN1Response={generateN1Response}
        className={cn('fixed left-48 right-0 z-50 w-auto', {
          'left-16': !isSidebarOpen,
        })}
      />
      <div className="translate-y-[78px] space-y-10 bg-primary">
        {showCustomWorkflow && <VaultCustomWorkflow />}
        <VaultRecentQueries />
        <div>
          <VaultSectionBreadcrumb
            title={<VaultFileExplorerBreadcrumbs />}
            trailingActions={[
              <VaultFileExplorerActions key="file-explorer-actions" />,
            ]}
          />
          <VaultProgress />
          <VaultFileExplorer projectId={projectId} />
        </div>
      </div>

      <VaultFileExplorerToolbelt
        generateNNResponse={generateNNResponse}
        className={cn('fixed ml-24 max-w-[calc(100%-192px)]', {
          'ml-8 max-w-[calc(100%-64px)]': !isSidebarOpen,
        })}
      />
    </AppMain>
  )
}

export default VaultProjectDetail
