import React from 'react'

import { isNil } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { unloadPDFDiv } from 'utils/pspdfkit'
import { useNavigateWithQueryParams } from 'utils/routing'
import { getSourceClicked } from 'utils/source'
import { Source } from 'utils/task'

import { BaseAppPath } from 'components/base-app-path'
import CitationPopover from 'components/common/citation/citation-popover'
import Response from 'components/common/response/response'
import {
  REMOVE_PARAMS,
  MAX_TOKEN_LIMIT_MESSAGE,
  projectsPath,
  filesPath,
  queryIdSearchParamKey,
  sourceIdSearchParamKey,
} from 'components/vault/utils/vault'
import {
  VaultSocketTask,
  useVaultStore,
} from 'components/vault/utils/vault-store'

const AskQueryResponse = ({
  selectedQuery,
  fileId,
  projectId,
}: {
  selectedQuery: VaultSocketTask
  fileId: string | undefined
  projectId: string | undefined
}) => {
  const navigateWithQueryParams = useNavigateWithQueryParams()
  const response = selectedQuery.response
  const hasMaxTokenLimitReached = selectedQuery.maxTokenLimitReached || false
  const queryAnnotations = selectedQuery.annotations

  const setInstance = usePDFViewerStore(useShallow((s) => s.setInstance))
  const setActiveDocument = useVaultStore(
    useShallow((s) => s.setActiveDocument)
  )

  const onAskQuerySourceClick = async (source: Source) => {
    const sourceDocumentId = source.documentId
    const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${filesPath}${sourceDocumentId}`
    const searchParams = new URLSearchParams()
    searchParams.set(queryIdSearchParamKey, selectedQuery.queryId)
    searchParams.set(sourceIdSearchParamKey, source.id)
    const searchParamString = `?${searchParams.toString()}`
    if (sourceDocumentId === fileId) {
      navigateWithQueryParams(newPath + searchParamString, {}, REMOVE_PARAMS)
    } else if (projectId) {
      // let's unload the pdf and set the active document to null
      // so the pdf viewer doesn't show up when we navigate to the new file
      setInstance(null)
      unloadPDFDiv()
      setActiveDocument(null)
      navigateWithQueryParams(newPath + searchParamString, {}, REMOVE_PARAMS)
    }
  }

  const getHrvyInfoMetadata = (identifier: string) => {
    const source = getSourceClicked(identifier, queryAnnotations)

    if (isNil(source)) {
      return
    }

    const hoverContent = (
      <CitationPopover
        source={source}
        onClick={() => onAskQuerySourceClick(source)}
      />
    )

    return { hoverContent, onClick: () => onAskQuerySourceClick(source) }
  }

  return (
    <div>
      <Response
        className="-mx-4 mt-2 border-none"
        disableSetExample
        skeleton="short"
        key={selectedQuery.queryId}
        getHrvyInfoMetadata={getHrvyInfoMetadata}
        markdown={
          hasMaxTokenLimitReached
            ? response + MAX_TOKEN_LIMIT_MESSAGE
            : response
        }
      />
    </div>
  )
}

export default AskQueryResponse
