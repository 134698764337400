import React, { useRef, useState } from 'react'

import Services from 'services'

import { cn, getCurrentRouteStringFromLocation } from 'utils/utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAuthUser } from 'components/common/auth-context'
import { Button, ButtonProps } from 'components/ui/button'

import ClientMatterSelectionDialog from './client-matter-selection-dialog'
import useCommandEnter from './use-command-enter'

interface AskHarveyButtonProps extends ButtonProps {
  handleSubmit: (e: React.BaseSyntheticEvent) => void
  buttonTitle?: string
  dataTestId?: string
  inputRef?: React.MutableRefObject<
    HTMLTextAreaElement | HTMLInputElement | null
  >
  shouldEnforceClientMatterSelection?: boolean
}

const AskHarveyButton = ({
  handleSubmit,
  disabled,
  className,
  tooltipClassName,
  size = 'default',
  tooltip,
  buttonTitle = 'Ask Harvey',
  dataTestId = 'ask-harvey',
  inputRef,
  shouldEnforceClientMatterSelection = true,
  isLoading,
}: AskHarveyButtonProps) => {
  const userInfo = useAuthUser()
  const canCmUserQuery = useClientMattersStore((s) => s.canCmUserQuery)
  const [isCmSelectionDialogOpen, setIsCmSelectionDialogOpen] = useState(false)

  const userRequiresClientMatter =
    userInfo.isClientMattersReadUser && !canCmUserQuery()
  const capturedEventRef = useRef<React.BaseSyntheticEvent>()

  const internalHandleSubmit = (e: React.BaseSyntheticEvent) => {
    handleSubmit(e)
    Services.HoneyComb.Record({
      metric: 'ui.ask_harvey',
      routes: getCurrentRouteStringFromLocation(window.location.pathname),
    })
  }

  const internalSubmitWrapperWithClientMatter = (
    e: React.BaseSyntheticEvent
  ) => {
    if (shouldEnforceClientMatterSelection && userRequiresClientMatter) {
      setIsCmSelectionDialogOpen(true)
      capturedEventRef.current = e
    } else {
      internalHandleSubmit(e)
    }
  }

  useCommandEnter(
    internalSubmitWrapperWithClientMatter,
    disabled ?? false,
    inputRef
  )

  return (
    // NOTE adding a div wrapper around this component helps prevent button from expanding to full width
    // can't add it here though since some components require that behavior
    <>
      <ClientMatterSelectionDialog
        isClientMatterDialogOpen={isCmSelectionDialogOpen}
        setIsClientMatterDialogOpen={setIsCmSelectionDialogOpen}
        onContinue={(e) => {
          // capturedEventRef will not be set when triggered by cmd+enter
          internalHandleSubmit(capturedEventRef.current ?? e)
        }}
        onCancel={() => {
          capturedEventRef.current = undefined
        }}
        continueButtonText={buttonTitle}
      />
      <Button
        type="submit"
        onClick={internalSubmitWrapperWithClientMatter}
        disabled={disabled}
        data-testid={dataTestId}
        className={cn('w-full', className)}
        size={size}
        tooltipClassName={tooltipClassName}
        tooltip={tooltip}
        isLoading={isLoading}
      >
        {buttonTitle}
      </Button>
    </>
  )
}

export default AskHarveyButton
