import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLocation } from 'react-router-dom'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { startCase, isEmpty } from 'lodash'
import {
  FilePlus2,
  InfoIcon,
  MessageSquarePlus,
  Play,
  Plus,
} from 'lucide-react'
import pluralize from 'pluralize'
import queryString from 'query-string'
import { toast } from 'sonner'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { VaultFolderAccessPermission } from 'openapi/models/VaultFolderAccessPermission'
import { useSharingStore } from 'stores/sharing-store'
import {
  PermissionLevel,
  PermissionsByUser,
  PermissionsByWorkspace,
  ShareType,
} from 'types/sharing'

import { bytesToPreciseReadable } from 'utils/file-utils'
import { useNavigateWithQueryParams } from 'utils/routing'
import { SafeRecord } from 'utils/safe-types'
import { getUserPermissionLevel } from 'utils/sharing-helpers'
import { TaskType } from 'utils/task'
import {
  displayErrorMessage,
  displayInfoMessage,
  displaySuccessMessage,
  displaySuccessMessageWithCTA,
} from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import SharePopover from 'components/common/sharing/share-popover'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import SetExampleProject from 'components/vault/components/set-example-project'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  REMOVE_PARAMS,
  newProjectPathRaw,
  projectsPath,
  projectsPathRaw,
  filesPathRaw,
  queriesPathRaw,
  shouldUseJobQueueForVaultReview,
  GenerateNNResponseProps,
  DOT_SEPARATOR,
  filesPath,
} from 'components/vault/utils/vault'
import {
  CancelVaultHistoryItem,
  FetchVaultFolderHistoryStats,
  ShareVaultFolder,
  ShareVaultFolderParams,
  UpdatedWorkspaceAndUserSharingInfo,
  UpdateVaultFolderShare,
  UpdateVaultFolderShareParams,
} from 'components/vault/utils/vault-fetcher'
import {
  getOriginalQuery,
  getQuestionsLimit,
  retryReview,
} from 'components/vault/utils/vault-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { QUERY_TYPES, useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

import FileBreadcrumb from './file-breadcrumb'
import { FileControls } from './file-controls'
import QueryDetailBreadcrumb from './query-detail-breadcrumb'
import VaultExportHandler from './vault-export-handler'
import VaultHomeHeader from './vault-home-header'
import VaultProjectTabs from './vault-project-tabs'

const loadingText = 'Loading…'
interface BreadcrumbPath {
  name: string
  path: string
}

const VaultAppHeader = ({
  generateNNResponse,
  sendCancelRequestNN,
}: {
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  sendCancelRequestNN: () => void
}) => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const { trackEvent } = useAnalytics()

  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const projectsMetadata = useVaultStore(useShallow((s) => s.projectsMetadata))
  const folderIdToVaultFileIds = useVaultStore(
    useShallow((s) => s.folderIdToVaultFileIds)
  )
  const fileIdToVaultFile = useVaultStore((s) => s.fileIdToVaultFile)
  const queryId = useVaultStore((s) => s.queryId)
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const queryIdToReviewState = useVaultStore((s) => s.queryIdToReviewState)
  const exampleProjectIds = useVaultStore((s) => s.exampleProjectIds)

  const isFetchingFolderShareStatus = useVaultSharingStore(
    (s) => s.isFetchingFolderShareStatus
  )
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((state) => state.permissionsByProjectId)
  )
  const sharingUsersForWorkspace = useSharingStore(
    (s) => s.sharingUsersForWorkspace
  )

  const query = queryIdToState[queryId]?.query
  const originalQuery = query
    ? getOriginalQuery(queryId, query, queryIdToReviewState)
    : undefined
  const isLoading = queryIdToState[queryId]?.isLoading ?? false
  const isFromHistory = queryIdToState[queryId]?.isFromHistory
  const questions = queryIdToReviewState[queryId]?.questions || []
  const creatorUserEmail =
    queryIdToState[queryId]?.creatorUserEmail ?? undefined
  const taskType = queryIdToState[queryId]?.taskType
  const processedFileIds = useMemo(
    () =>
      queryIdToReviewState[queryId]?.processedFileIds.filter(
        (id) => !!fileIdToVaultFile[id]
      ),
    [queryIdToReviewState, fileIdToVaultFile, queryId]
  )
  const reviewQuestionsPerQueryLimit = useVaultUsageStore(
    (s) => s.reviewQuestionsPerQueryLimit
  )
  const reviewFilesPerQueryLimit = useVaultUsageStore(
    (s) => s.reviewFilesPerQueryLimit
  )
  const questionsLimit = getQuestionsLimit(
    queryIdToReviewState[queryId]?.questionsLimit,
    reviewQuestionsPerQueryLimit
  )
  const filesLimit =
    queryIdToReviewState[queryId]?.filesLimit ?? reviewFilesPerQueryLimit

  const fileIds = useMemo(
    () =>
      queryIdToState[queryId]?.fileIds.filter(
        (id) => !!fileIdToVaultFile[id]
      ) || [],
    [queryIdToState, fileIdToVaultFile, queryId]
  )
  const notIncludedFileIds = useMemo(() => {
    const fileIdsSet = new Set(fileIds)
    return (currentProjectMetadata.descendantFiles ?? [])
      .filter((file) => !fileIdsSet.has(file.id))
      .map((file) => file.id)
      .filter(
        (id) => !!fileIdToVaultFile[id] && fileIdToVaultFile[id]!.readyToQuery
      )
  }, [fileIds, currentProjectMetadata, fileIdToVaultFile])
  const numFiles = useMemo(
    () =>
      fileIds.length == 0 && queryIdToState[queryId]?.numFiles
        ? queryIdToState[queryId!]!.numFiles
        : fileIds.length,
    [fileIds, queryId, queryIdToState]
  )
  const startedAt = queryIdToState[queryId]?.startedAt
  const completedAt = queryIdToState[queryId]?.completedAt
  const cancelledAt = queryIdToState[queryId]?.cancelledAt
  const failedAt = queryIdToState[queryId]?.failedAt
  const eta = queryIdToReviewState[queryId]?.eta

  const isExampleProject = useMemo(
    () => currentProject && exampleProjectIds.has(currentProject.id),
    [currentProject, exampleProjectIds]
  )

  const setIsTextAreaFocused = useVaultStore(
    (state) => state.setIsTextAreaFocused
  )
  const setQueryType = useVaultStore((state) => state.setQueryType)
  const setPendingQuery = useVaultStore((state) => state.setPendingQuery)
  const setPendingQueryFileIds = useVaultStore(
    (state) => state.setPendingQueryFileIds
  )
  const setReviewTask = useVaultStore((s) => s.setReviewTask)
  const markHistoryTaskAsFromStreaming = useVaultStore(
    (s) => s.markHistoryTaskAsFromStreaming
  )
  const markInProgressTaskAsFromHistory = useVaultStore(
    (s) => s.markInProgressTaskAsFromHistory
  )
  const setIsAddFilesDialogOpen = useVaultStore(
    (s) => s.setIsAddFilesDialogOpen
  )
  const appendPermissionsForProjectId = useVaultSharingStore(
    (s) => s.appendPermissionsForProjectId
  )
  const setCurrentUserPermissionByProjectId = useVaultSharingStore(
    (s) => s.setCurrentUserPermissionByProjectId
  )
  const setIsSharing = useSharingStore((s) => s.setIsSharing)
  const removeWorkspacePermissionForProjectId = useVaultSharingStore(
    (s) => s.removeWorkspacePermissionForProjectId
  )
  const removeUserPermissionsForProjectId = useVaultSharingStore(
    (s) => s.removeUserPermissionsForProjectId
  )

  const [isSetExampleOpen, setIsSetExampleOpen] = useState<boolean>(false)
  const [lastCancelRequestedAt, setLastCancelRequestedAt] =
    useState<Date | null>(null)
  const isCancelling =
    !!lastCancelRequestedAt && !!startedAt && lastCancelRequestedAt > startedAt

  const shouldRenderVaultHomeHeader = useMemo(() => {
    return (
      location.pathname === BaseAppPath.Vault ||
      location.pathname === `${BaseAppPath.Vault}/${projectsPathRaw}` ||
      location.pathname === `${BaseAppPath.Vault}${projectsPath}`
    )
  }, [location.pathname])

  const shouldRenderNewProjectBreadcrumb = useMemo(() => {
    const paths = location.pathname.split('/').filter(Boolean)
    return paths.length === 2 && paths[1] === newProjectPathRaw
  }, [location.pathname])

  const shouldRenderQueriesBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/queries
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 4 &&
      paths[1] === projectsPathRaw &&
      paths[3] === queriesPathRaw
    )
  }, [location.pathname])

  const shouldRenderQueryDetailBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/queries/:queryId
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 5 &&
      paths[1] === projectsPathRaw &&
      paths[3] === queriesPathRaw
    )
  }, [location.pathname])

  const shouldRenderFileBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/files/:fileId
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 5 &&
      paths[1] === projectsPathRaw &&
      paths[3] === filesPathRaw
    )
  }, [location.pathname])

  const isShowingProjectPage = useMemo(() => {
    // When path is /vault/projects/:projectId
    const paths = location.pathname.split('/').filter(Boolean)

    return paths.length === 3 && paths[1] === projectsPathRaw
  }, [location.pathname])

  // we cannot get the fileId from useParams because vault-app-header is not a child of the layout
  // its in the same route as the layout
  const currentFileId = useMemo(() => {
    if (!shouldRenderFileBreadcrumb) return undefined
    const paths = location.pathname.split('/').filter(Boolean)
    return paths[paths.length - 1]
  }, [location, shouldRenderFileBreadcrumb])
  const currentFile = useMemo(() => {
    if (!currentFileId) return undefined
    return fileIdToVaultFile[currentFileId]
  }, [fileIdToVaultFile, currentFileId])

  const {
    doesCurrentUserHaveFullAccessPermission,
    doesCurrentUserHaveEditPermission,
  } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const breadcrumbPaths: BreadcrumbPath[] = useMemo(() => {
    // When path is either
    // 1. /vault/projects
    // 2. /vault/projects/:projectId
    // 3. /vault/projects/:projectId/queries
    // We should show the project name in the breadcrumbs
    const vaultDisplayNameWithPath = { name: 'Vault', path: BaseAppPath.Vault }
    const paths = location.pathname.split('/').filter(Boolean)

    if (shouldRenderNewProjectBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        { name: 'New project', path: location.pathname },
      ]
    }

    if (paths.length <= 2 || paths[1] !== projectsPathRaw) {
      // Since we are at vault app header, we should default to the vault projects path
      // if the path is not valid or too short
      return [vaultDisplayNameWithPath]
    }

    const projectId = paths[2]
    const projectDetailPath = `${BaseAppPath.Vault}${projectsPath}${projectId}`
    const projectDetailDisplayNameWithPath = {
      name: currentProject?.name || loadingText,
      path: projectDetailPath,
    }

    if (paths.length === 3) {
      return [vaultDisplayNameWithPath, projectDetailDisplayNameWithPath]
    }

    if (shouldRenderQueriesBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        { name: startCase(queriesPathRaw), path: location.pathname },
      ]
    }

    if (shouldRenderQueryDetailBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        {
          name: startCase(queriesPathRaw),
          path: `${projectDetailPath}/${queriesPathRaw}`,
        },
        { name: 'Query', path: location.pathname },
      ]
    }

    if (shouldRenderFileBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        { name: startCase(filesPathRaw), path: projectDetailPath },
        { name: 'File', path: location.pathname },
      ]
    }
    return []
  }, [
    location.pathname,
    currentProject?.name,
    shouldRenderNewProjectBreadcrumb,
    shouldRenderQueriesBreadcrumb,
    shouldRenderQueryDetailBreadcrumb,
    shouldRenderFileBreadcrumb,
  ])

  const pathForIndexFunc = useCallback(
    (index: number, pathname: string, search: string) => {
      const queryParams = queryString.parse(search)
      for (const param of REMOVE_PARAMS) {
        delete queryParams[param]
      }
      if (Object.keys(queryParams).length > 0) {
        return `${breadcrumbPaths[index].path}?${queryString.stringify(
          queryParams
        )}`
      }
      return breadcrumbPaths[index].path
    },
    [breadcrumbPaths]
  )

  const showNewQueryButton =
    taskType === TaskType.VAULT &&
    queryId &&
    !isLoading &&
    canCurrentUserEditProject
  const showCancelRequestNNButton =
    taskType === TaskType.VAULT_REVIEW &&
    questions.length > 0 &&
    isLoading &&
    (!processedFileIds || processedFileIds.length < fileIds.length) &&
    // If we are using job queue for vault review, we could trigger cancellation
    // via job queue implementation
    (!isFromHistory || shouldUseJobQueueForVaultReview(userInfo))
  // TODO: Continue review button currently only shows up for internal users
  const showContinueReviewButton =
    userInfo.IsVaultInternalOnlyUser &&
    currentProject?.id &&
    taskType === TaskType.VAULT_REVIEW &&
    questions.length > 0 &&
    !isLoading &&
    (!processedFileIds || processedFileIds.length < fileIds.length)
  const showAddFileButton =
    currentProject?.id &&
    canCurrentUserEditProject &&
    taskType === TaskType.VAULT_REVIEW &&
    questions.length > 0 &&
    !isLoading &&
    (!filesLimit || fileIds.length < filesLimit)
  const showAddColumnButton =
    currentProject?.id &&
    canCurrentUserEditProject &&
    taskType === TaskType.VAULT_REVIEW &&
    questions.length > 0 &&
    !isLoading &&
    questions.length < questionsLimit &&
    !queryIdToReviewState[queryId]?.isWorkflowRepsWarranties
  // show project sharing button if user is currently viewing a project homepage
  const showShareButton =
    userInfo.IsVaultSharingUser &&
    currentProject?.id &&
    !isExampleProject &&
    !isLoading &&
    isShowingProjectPage
  const showFileControls = !!currentFile
  const fileIdsInCurrentFolder = useMemo(() => {
    if (!currentFile?.vaultFolderId) return []
    return (folderIdToVaultFileIds[currentFile.vaultFolderId] ?? [])
      .map((id) => fileIdToVaultFile[id])
      .filter((file) => !!file)
      .sort((a, b) => a!.name.localeCompare(b!.name))
      .map((file) => file!.id)
  }, [currentFile, folderIdToVaultFileIds, fileIdToVaultFile])
  const previousFileId = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    const currentIndex = fileIdsInCurrentFolder.indexOf(currentFile.id)
    if (currentIndex === -1 || currentIndex === 0) return undefined
    return fileIdsInCurrentFolder[currentIndex - 1]
  }, [currentFile, fileIdsInCurrentFolder])
  const nextFileId = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    const currentIndex = fileIdsInCurrentFolder.indexOf(currentFile.id)
    if (
      currentIndex === -1 ||
      currentIndex === fileIdsInCurrentFolder.length - 1
    )
      return undefined
    return fileIdsInCurrentFolder[currentIndex + 1]
  }, [currentFile, fileIdsInCurrentFolder])
  const currentFileIndex = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    return fileIdsInCurrentFolder.indexOf(currentFile.id)
  }, [currentFile, fileIdsInCurrentFolder])

  const onNewQuery = () => {
    if (!showNewQueryButton) return
    trackEvent('Vault Review Query New Query Button Clicked', {
      query_id: queryId,
    })
    setIsTextAreaFocused(true)
    setQueryType(QUERY_TYPES.N1)
    setPendingQuery(query ?? '')
    setPendingQueryFileIds(fileIds.length > 0 ? fileIds : null)
  }

  const onCancelNNQuery = async () => {
    if (!showCancelRequestNNButton) return
    trackEvent('Vault Review Query Cancel Button Clicked', {
      query_id: queryId,
    })
    if (shouldUseJobQueueForVaultReview(userInfo)) {
      setLastCancelRequestedAt(new Date())
      // If we are using job queue for vault review, we could cancel the query
      // via job queue implementation
      try {
        const response = await CancelVaultHistoryItem(queryId)
        if (response.eventCancelled) {
          markInProgressTaskAsFromHistory(TaskType.VAULT_REVIEW)
          await queryClient.invalidateQueries({
            queryKey: [HarvQueryKeyPrefix.VaultHistoryItemQuery, queryId],
          })
        } else {
          displayInfoMessage('Cancelling, it will take a few seconds…', 5)
        }
      } catch (e) {
        console.error(e)
        displayErrorMessage('Failed to cancel query, please try again later')
      }
    } else {
      sendCancelRequestNN()
    }
  }

  const navigateToFile = (currentProjectId: string, fileId: string) => {
    navigate(
      `${BaseAppPath.Vault}${projectsPath}${currentProjectId}${filesPath}${fileId}`,
      {},
      REMOVE_PARAMS
    )
  }
  useHotkeys(
    'up',
    () => {
      if (previousFileId) {
        navigateToFile(currentProject!.id, previousFileId)
      }
    },
    {
      enabled: showFileControls && !!currentProject && !!previousFileId,
    }
  )
  useHotkeys(
    'down',
    () => {
      if (nextFileId) {
        navigateToFile(currentProject!.id, nextFileId)
      }
    },
    {
      enabled: showFileControls && !!currentProject && !!nextFileId,
    }
  )
  const navigateToProject = (currentProjectId: string) => {
    navigate(
      `${BaseAppPath.Vault}${projectsPath}${currentProjectId}`,
      {},
      REMOVE_PARAMS
    )
  }
  // when the user presses escape key we want to hide the document previewer
  useHotkeys(
    'esc',
    () => {
      navigateToProject(currentProject!.id)
    },
    {
      enabled: showFileControls && !!currentProject,
    },
    [navigateToProject, currentProject, currentFile]
  )

  const onContinueReview = useCallback(async () => {
    if (!showContinueReviewButton) return
    const dryRunToastId = dryRunSuccessToastIdRef.current[queryId]
    if (dryRunToastId) {
      toast.dismiss(dryRunToastId)
      dryRunSuccessToastIdRef.current[queryId] = null
    }
    trackEvent('Vault Review Query Continue Review Button Clicked', {
      query_id: queryId,
    })
    await retryReview({
      generateNNResponse,
      setReviewTask,
      markHistoryTaskAsFromStreaming,
      projectId: currentProject.id,
      queryId,
      fileIds: fileIds.filter((id) => !(processedFileIds || []).includes(id)),
      queryIdToState,
      queryIdToReviewState,
    })
  }, [
    showContinueReviewButton,
    trackEvent,
    generateNNResponse,
    setReviewTask,
    markHistoryTaskAsFromStreaming,
    currentProject?.id,
    queryId,
    fileIds,
    processedFileIds,
    queryIdToState,
    queryIdToReviewState,
  ])

  const dryRunStartToastIdRef = useRef<
    SafeRecord<string, string | number | null>
  >({})
  const dryRunSuccessToastIdRef = useRef<
    SafeRecord<string, string | number | null>
  >({})
  useEffect(() => {
    if (!queryIdToState[queryId]?.dryRun) {
      for (const toastId of Object.values({
        ...dryRunStartToastIdRef.current,
        ...dryRunSuccessToastIdRef.current,
      })) {
        if (toastId) {
          // Dismiss the previous toast if it exists
          toast.dismiss(toastId)
        }
      }
      dryRunStartToastIdRef.current = {}
      dryRunSuccessToastIdRef.current = {}
    }
  }, [queryId, queryIdToState])

  useEffect(() => {
    if (
      queryIdToState[queryId]?.dryRun &&
      queryIdToState[queryId]?.isLoading &&
      fileIds.length > 1
    ) {
      if (
        dryRunStartToastIdRef.current[queryId] ||
        dryRunSuccessToastIdRef.current[queryId]
      ) {
        // If the toast is already showing, don't show it again
        return
      }
      dryRunStartToastIdRef.current[queryId] = displayInfoMessage(
        'The review query will run the first file, you can add more columns or continue review on the remaining files once it finishes',
        10
      )
    }
  }, [fileIds.length, queryId, queryIdToState])

  useEffect(() => {
    if (
      queryIdToState[queryId]?.dryRun &&
      queryIdToState[queryId]?.completedAt &&
      showContinueReviewButton
    ) {
      if (dryRunSuccessToastIdRef.current[queryId]) {
        // If the toast is already showing, don't show it again
        return
      }
      dryRunSuccessToastIdRef.current[queryId] = displaySuccessMessageWithCTA({
        message:
          'The review query finished for the first file, you can add more columns or continue review on the remaining files by clicking on the buttons on the top right of the page',
        durationInSeconds: 60 * 60 * 24, // 1 day
        cta: {
          label: 'Continue Review',
          variant: 'outline',
          onClick: onContinueReview,
        },
      })
    }
  }, [showContinueReviewButton, onContinueReview, queryId, queryIdToState])

  const onAddFile = () => {
    if (!showAddFileButton || !currentProject.id) return
    trackEvent('Vault Review Query Add File Button Clicked', {
      query_id: queryId,
    })
    setIsAddFilesDialogOpen(true)
  }

  const onAddColumn = () => {
    if (!showAddColumnButton) return
    trackEvent('Vault Review Query Add Column Button Clicked', {
      query_id: queryId,
    })
    setIsTextAreaFocused(true)
    setQueryType(QUERY_TYPES.NN)
    setPendingQuery('')
    const pendingQueryFileIds = showContinueReviewButton
      ? processedFileIds ?? null
      : fileIds.length > 0
      ? fileIds
      : null
    setPendingQueryFileIds(pendingQueryFileIds)
  }

  const appHeaderTitle = queryIdToState[queryId]?.title || query || loadingText
  const overridePath = breadcrumbPaths.map((path) => path.name).join('/')

  useEffect(() => {
    if (isEmpty(currentProject)) {
      document.title = 'Vault'
    } else if (currentFile) {
      document.title = currentFile.name
    } else if (!isEmpty(currentProject) && !queryId) {
      document.title = currentProject.name
    } else if (!queryId) {
      document.title = overridePath[overridePath.length - 1]
    } else {
      document.title = appHeaderTitle
    }

    return () => {
      document.title = 'Harvey'
    }
  }, [appHeaderTitle, currentProject, queryId, overridePath, currentFile])

  const projectFilesCountLimitDisplayText = useMemo(() => {
    return `Each project can contain up to ${userInfo.workspace
      .getVaultFilesCountLimit(userInfo.vaultFeature)
      .toLocaleString()} files`
  }, [userInfo.vaultFeature, userInfo.workspace])

  const sizeDisplayText = useMemo(() => {
    const projectData = currentProject
      ? projectsMetadata[currentProject.id]
      : null
    if (!projectData) return 'Loading…'
    const numProjectFiles = projectData.totalFiles || 0
    const projectSize = bytesToPreciseReadable(
      projectData.folderSize || 0,
      2,
      true
    )
    if (numProjectFiles === 0) {
      return projectFilesCountLimitDisplayText
    }
    return `${pluralizeFiles(numProjectFiles)} (${projectSize})`
  }, [currentProject, projectsMetadata, projectFilesCountLimitDisplayText])

  const { data: historyStats } = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!currentProject,
    queryKey: [HarvQueryKeyPrefix.VaultHistoryStatsQuery, currentProject?.id],
    queryFn: () => FetchVaultFolderHistoryStats(currentProject?.id ?? ''),
    refetchInterval: (query) => {
      // Poll for history stats updates every 10 seconds if there are any in progress
      if (
        query.state.status === 'success' &&
        query.state.data &&
        query.state.data.inProgressCount > 0
      ) {
        return 10_000
      }
      return false
    },
  })

  const queriesDisplayText = useMemo(() => {
    if (!historyStats || historyStats.totalCount === 0) return null
    return `${historyStats.totalCount} ${pluralize(
      'query',
      historyStats.totalCount
    )}`
  }, [historyStats])

  const subtitleForProjectDetail = useMemo(() => {
    return [sizeDisplayText, queriesDisplayText]
      .filter(Boolean)
      .join(DOT_SEPARATOR)
  }, [sizeDisplayText, queriesDisplayText])

  const TitleComponent = useMemo(() => {
    if (shouldRenderVaultHomeHeader) {
      return <VaultHomeHeader />
    }

    if (shouldRenderFileBreadcrumb) {
      return <FileBreadcrumb currentFile={currentFile} />
    }

    if (shouldRenderQueryDetailBreadcrumb) {
      return (
        <QueryDetailBreadcrumb
          numFiles={numFiles}
          filesLimit={filesLimit}
          numQuestions={questions.length}
          questionsLimit={questionsLimit}
          // Only show the query limit for vault review tasks
          showQueryLimit={taskType === TaskType.VAULT_REVIEW}
          processedFileIds={processedFileIds}
          isLoading={isLoading}
          completedAt={completedAt}
          cancelledAt={cancelledAt}
          failedAt={failedAt}
          eta={eta ? parseIsoString(eta) : null}
          // Only show the processing started time if it's a review task, because
          // for other types of tasks, it's usually quick and not worth showing.
          startedAt={taskType === TaskType.VAULT_REVIEW ? startedAt : undefined}
          projectId={currentProject?.id}
          title={appHeaderTitle}
          originalQuery={originalQuery}
          creatorUserEmail={creatorUserEmail}
        />
      )
    }

    if (breadcrumbPaths.length === 0) return null
    const lastBreadcrumbName = breadcrumbPaths[breadcrumbPaths.length - 1]?.name
    const subtitle = () => {
      if (shouldRenderQueriesBreadcrumb) return 'Browse your previous queries'
      if (shouldRenderNewProjectBreadcrumb)
        return projectFilesCountLimitDisplayText
      return subtitleForProjectDetail
    }

    return (
      <>
        <div className="flex space-x-2">
          <p className="line-clamp-2 text-xl font-medium">
            {lastBreadcrumbName}
          </p>
          {isExampleProject && (
            <Badge variant="secondary" className="border-0 text-muted" isPill>
              Example
              <Tooltip delayDuration={200}>
                <TooltipTrigger className="ml-1">
                  <Icon icon={InfoIcon} size="small" />
                </TooltipTrigger>
                <TooltipContent
                  className="max-w-80 text-start"
                  align="start"
                  side="right"
                >
                  This is an example project to give you a preview of how Vault
                  works. Queries cannot be run on this project. It will not
                  count towards any limits and cannot be deleted
                </TooltipContent>
              </Tooltip>
            </Badge>
          )}
          <SetExampleProject
            isSetExampleOpen={isSetExampleOpen}
            setIsSetExampleOpen={setIsSetExampleOpen}
          />
        </div>
        <p className="truncate text-xs text-muted">{subtitle()}</p>
      </>
    )
  }, [
    currentFile,
    currentProject,
    numFiles,
    filesLimit,
    questions.length,
    questionsLimit,
    originalQuery,
    taskType,
    appHeaderTitle,
    isLoading,
    processedFileIds,
    startedAt,
    completedAt,
    cancelledAt,
    failedAt,
    eta,
    creatorUserEmail,
    shouldRenderFileBreadcrumb,
    shouldRenderQueriesBreadcrumb,
    shouldRenderNewProjectBreadcrumb,
    shouldRenderQueryDetailBreadcrumb,
    shouldRenderVaultHomeHeader,
    breadcrumbPaths,
    projectFilesCountLimitDisplayText,
    isExampleProject,
    subtitleForProjectDetail,
    isSetExampleOpen,
    setIsSetExampleOpen,
  ])

  const updateCurrentUserPermissionLevelForProject = (
    permissionsByWorkspace: PermissionsByWorkspace,
    permissionsByUser: PermissionsByUser,
    projectId: string
  ) => {
    // get the current user's permission level for the project based on share status
    const currentUserPermissionLevel = getUserPermissionLevel({
      userId: userInfo.dbId,
      workspaceId: userInfo.workspace.id,
      permissionsByWorkspace: permissionsByWorkspace,
      permissionsByUser: permissionsByUser,
    })

    if (currentUserPermissionLevel) {
      setCurrentUserPermissionByProjectId(projectId, currentUserPermissionLevel)
    }
  }

  const onChangeSharedWithWorkspace = async (sharedWithWorkspace: boolean) => {
    if (!currentProject) return
    const isWorkspaceAlreadyShared = permissionsByProjectId[
      currentProject.id
    ]?.permissionsByWorkspace?.some(
      (workspace) => workspace.workspaceId === userInfo.workspace.id
    )

    // do nothing if share setting is not changing
    if (sharedWithWorkspace === isWorkspaceAlreadyShared) {
      return
    }

    if (sharedWithWorkspace && !isWorkspaceAlreadyShared) {
      // if not previously shared, share vault folder with workspace
      try {
        setIsSharing(true)
        const response = await ShareVaultFolder({
          projectId: currentProject.id,
          shareWithWorkspaces: [
            {
              workspaceId: userInfo.workspace.id,
              permissionLevel: VaultFolderAccessPermission.VIEW,
            },
          ],
        })
        appendPermissionsForProjectId(currentProject.id, response.shareStatus)
        updateCurrentUserPermissionLevelForProject(
          response.shareStatus.permissionsByWorkspace as PermissionsByWorkspace,
          response.shareStatus.permissionsByUser as PermissionsByUser,
          currentProject.id
        )
      } catch (error) {
        displayErrorMessage(
          sharedWithWorkspace
            ? 'Failed to share project with workspace'
            : 'Failed to unshare project with workspace'
        )
      }
      setIsSharing(false)
      return
    }

    const updateParams: UpdateVaultFolderShareParams = {
      projectId: currentProject.id,
    }

    if (sharedWithWorkspace && isWorkspaceAlreadyShared) {
      updateParams.updateShareWithWorkspaces = [
        {
          workspaceId: userInfo.workspace.id,
          permissionLevel: VaultFolderAccessPermission.VIEW,
        },
      ]
    } else {
      updateParams.removeShareWithWorkspaces = [userInfo.workspace.id]
    }
    try {
      setIsSharing(true)
      const response = await UpdateVaultFolderShare(updateParams)
      if (sharedWithWorkspace) {
        appendPermissionsForProjectId(currentProject.id, response.shareStatus)
      } else {
        removeWorkspacePermissionForProjectId(
          currentProject.id,
          userInfo.workspace.id
        )
      }
      updateCurrentUserPermissionLevelForProject(
        response.shareStatus.permissionsByWorkspace as PermissionsByWorkspace,
        response.shareStatus.permissionsByUser as PermissionsByUser,
        currentProject.id
      )
    } catch (error) {
      displayErrorMessage(
        sharedWithWorkspace
          ? 'Failed to share project with workspace'
          : 'Failed to unshare project with workspace'
      )
    }
    setIsSharing(false)
  }

  const onUpdateSharePermissions = async (
    params: UpdatedWorkspaceAndUserSharingInfo
  ) => {
    if (!currentProject) return
    setIsSharing(true)
    const response = await UpdateVaultFolderShare({
      projectId: currentProject.id,
      ...params,
    })
    if (params.removeShareWithWorkspaces) {
      removeWorkspacePermissionForProjectId(
        currentProject.id,
        userInfo.workspace.id // TODO: replace with params.removeShareWithWorkspaces when sharing with multiple workspaces is enabled
      )
    }
    if (params.removeShareWithUsers) {
      removeUserPermissionsForProjectId(
        currentProject.id,
        params.removeShareWithUsers
      )
    }
    appendPermissionsForProjectId(currentProject.id, response.shareStatus)
    updateCurrentUserPermissionLevelForProject(
      response.shareStatus.permissionsByWorkspace as PermissionsByWorkspace,
      response.shareStatus.permissionsByUser as PermissionsByUser,
      currentProject.id
    )
    setIsSharing(false)
  }

  const onGrantUserPermissions = async (
    userIds: string[],
    permissionLevel: PermissionLevel
  ) => {
    if (!currentProject) return
    const params: ShareVaultFolderParams = {
      projectId: currentProject.id,
      shareWithUsers: userIds.map((userId) => ({
        userId,
        permissionLevel: permissionLevel as VaultFolderAccessPermission,
      })),
    }
    try {
      setIsSharing(true)
      const response = await ShareVaultFolder(params)
      appendPermissionsForProjectId(currentProject.id, response.shareStatus)
      updateCurrentUserPermissionLevelForProject(
        response.shareStatus.permissionsByWorkspace as PermissionsByWorkspace,
        response.shareStatus.permissionsByUser as PermissionsByUser,
        currentProject.id
      )
      displaySuccessMessage('Successfully shared project with users')
    } catch (error) {
      displayErrorMessage('Failed to grant user permissions')
    }
    setIsSharing(false)
  }

  const isShareButtonDisabled =
    isFetchingFolderShareStatus || sharingUsersForWorkspace.length === 0

  const shareButtonDisabledTooltip = isFetchingFolderShareStatus
    ? 'Fetching project share status'
    : sharingUsersForWorkspace.length === 0
    ? 'Fetching workspace users eligible for sharing'
    : undefined

  return (
    <AppHeader
      breadcrumbs={
        breadcrumbPaths.length === 0 ? null : (
          <RouterBreadcrumbs
            overridesDocumentTitle
            overridePath={overridePath}
            keepCaseStartingFromIndex={1}
            checkHistoryIdInPath={false}
            pathForIndexFunc={pathForIndexFunc}
          />
        )
      }
      titleComponent={TitleComponent}
      actions={
        <div className="ml-2 flex shrink-0 grow items-center justify-end gap-2">
          {showNewQueryButton && (
            <Button variant="outline" onClick={onNewQuery}>
              <Icon icon={Plus} className="mr-1" />
              New query
            </Button>
          )}
          {showCancelRequestNNButton && (
            <Button
              variant="outline"
              onClick={onCancelNNQuery}
              disabled={isCancelling}
              data-testid="cancel-query-button"
            >
              {isCancelling ? 'Cancelling…' : 'Cancel'}
            </Button>
          )}
          {showContinueReviewButton && (
            <Button
              variant="outline"
              onClick={onContinueReview}
              tooltip="Continue review query on remaining documents"
            >
              <Icon icon={Play} className="mr-1" />
              Continue review
            </Button>
          )}
          {showAddFileButton && (
            <Button
              variant="outline"
              onClick={onAddFile}
              disabled={notIncludedFileIds.length === 0}
              tooltip={
                notIncludedFileIds.length === 0
                  ? 'All documents have been included in the query'
                  : undefined
              }
            >
              <Icon icon={FilePlus2} className="mr-1" />
              Add document
            </Button>
          )}
          {showAddColumnButton && (
            <Button variant="outline" onClick={onAddColumn}>
              <Icon icon={MessageSquarePlus} className="mr-1" />
              Add column
            </Button>
          )}
          {taskType && <VaultExportHandler />}
          {showShareButton && (
            <SharePopover
              shareType={ShareType.VAULT_PROJECT}
              isReadOnly={!doesCurrentUserHaveFullAccessPermission}
              ownerUserEmail={
                sharingUsersForWorkspace.find(
                  (user) => user.userId === currentProject.userId
                )?.userEmail ?? null
              }
              permissionsByWorkspace={
                (permissionsByProjectId[currentProject.id]
                  ?.permissionsByWorkspace ?? []) as PermissionsByWorkspace
              }
              permissionsByUser={
                (permissionsByProjectId[currentProject.id]?.permissionsByUser ??
                  []) as PermissionsByUser
              }
              onChangeSharedWithWorkspace={onChangeSharedWithWorkspace}
              onGrantUserPermissions={onGrantUserPermissions}
              onUpdateSharePermissions={onUpdateSharePermissions}
              disabled={isShareButtonDisabled}
              disabledTooltip={shareButtonDisabledTooltip}
            />
          )}
          {showFileControls && currentProject && (
            <FileControls
              currentFileIndex={currentFileIndex}
              fileIds={fileIdsInCurrentFolder}
              previousFileId={previousFileId}
              nextFileId={nextFileId}
              onNavigateToFile={(fileId: string) => {
                navigateToFile(currentProject.id, fileId)
              }}
              onExit={() => {
                navigateToProject(currentProject.id)
              }}
            />
          )}
        </div>
      }
    >
      {userInfo.IsVaultSharingUser && shouldRenderVaultHomeHeader && (
        <div className="px-6">
          <VaultProjectTabs />
        </div>
      )}
    </AppHeader>
  )
}

export default VaultAppHeader
