import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { RawUser } from 'models/users'
import { AuditLogType } from 'openapi/models/AuditLogType'
import { useClientAdminStore } from 'stores/client-admin-store'

import { usePostAuditLog } from 'utils/audit-log'
import { PWD_AUTH_CONN } from 'utils/routing'

import { AppHeader } from 'components/common/app-header'
import FullscreenLoading from 'components/common/fullscreen-loading'
import useWorkspaceActiveUsers from 'components/settings/hooks/use-workspace-active-users'
import useWorkspacesForUser from 'components/settings/hooks/use-workspaces-for-user'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import CustomerUserAddCard from 'components/settings/user-management/customer-user-add-card'
import WorkspaceUsersTable from 'components/settings/workspace/workspace-users-table'
import { AllenOveryWorkspaceId } from 'components/special-ids'
import CsvDownload from 'components/ui/csv-download'
import SearchInput from 'components/ui/search-input'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

const WorkspaceUsers: React.FC = () => {
  const { workspaces } = useWorkspacesForUser()
  const { activeUsers, isLoadingActiveUsers } = useWorkspaceActiveUsers({
    workspaceId: workspaces.length > 0 ? workspaces[0].id : null,
  })
  const settingsUser = useSettingsState((state) => state.settingsUser)

  // only paswordless workspaces should see this flow
  const canAddUsers =
    settingsUser?.IsClientAdminAddUsers &&
    (settingsUser.workspace.authConn === PWD_AUTH_CONN ||
      settingsUser.workspace.blockNonProvisionedSamlUsers)

  const selectedWorkspace = settingsUser?.workspace ?? workspaces[0]

  const [users, fetchUsers] = useClientAdminStore(
    useShallow((s) => [s.users, s.fetchUsers])
  )
  const [workspaceUsers, setWorkspaceUsers] = useState<RawUser[]>(activeUsers)

  const [filteredUsers, setFilteredUsers] = useState<RawUser[]>(activeUsers)
  const [userFilter, setUserFilter] = useState<string>('')
  const [selectedTab, setSelectedTab] = useState<string>('users-table')
  const { postAuditLog } = usePostAuditLog()

  useEffect(() => {
    setFilteredUsers(workspaceUsers)
  }, [workspaceUsers])

  useEffect(() => {
    const fetchUserData = async () => {
      if (_.isNil(selectedWorkspace)) {
        return
      }
      await fetchUsers(selectedWorkspace.id)
    }
    void fetchUserData()
  }, [selectedWorkspace, fetchUsers])

  useEffect(() => {
    setWorkspaceUsers(users)
    setFilteredUsers(users)
    setUserFilter('')
  }, [users])

  if (
    !settingsUser?.IsClientAdminViewUsers ||
    settingsUser.workspace?.id === AllenOveryWorkspaceId
  )
    return (
      <SettingsError
        description={`You don’t have access to users for ${settingsUser?.workspace.clientName}`}
      />
    )

  const handleSearch = (value: string) => {
    value = value.trim()
    setUserFilter(value)
    if (value.length === 0) {
      setFilteredUsers(workspaceUsers)
    } else {
      setFilteredUsers(
        workspaceUsers.filter((user: RawUser) => user.email.includes(value))
      )
    }
  }

  const userSearchInput = (
    <SearchInput
      value={userFilter}
      setValue={handleSearch}
      placeholder="Search by email"
      withIcon
    />
  )

  const workspaceUsersTable = (
    <div>
      <WorkspaceUsersTable
        users={filteredUsers ?? []}
        workspace={selectedWorkspace}
      />
    </div>
  )

  const workspaceUsersTableAndBulkAdd = (
    <Tabs
      defaultValue="users-table"
      onValueChange={(value) => setSelectedTab(value)}
    >
      <div className="mb-3.5 flex flex-row items-center justify-between">
        <TabsList>
          <TabsTrigger value="users-table">Users</TabsTrigger>
          <TabsTrigger value="bulk-add-users">Add users</TabsTrigger>
        </TabsList>
        {selectedTab === 'users-table' && userSearchInput}
      </div>
      <TabsContent value="users-table">{workspaceUsersTable}</TabsContent>
      <TabsContent value="bulk-add-users">
        <CustomerUserAddCard workspace={selectedWorkspace} />
      </TabsContent>
    </Tabs>
  )

  const exportUsers = (
    <CsvDownload
      data={filteredUsers.map((user) => ({
        email: user.email,
        createdAt: user.createdAt,
        interstitial: user.settings?.welcomeScreens
          ? (
              Object.values(user.settings.welcomeScreens)[0] as string
            ).toLowerCase()
          : null,
      }))}
      buttonText="Export users"
      filename={`${selectedWorkspace.clientName}_users.csv`}
      headers={[
        { label: 'Email', key: 'email' },
        ...(selectedWorkspace.hasInterstitial
          ? [{ label: 'Terms popup', key: 'interstitial' }]
          : []),
        { label: 'Created at', key: 'createdAt' },
      ]}
      onClickCallback={() =>
        postAuditLog(
          AuditLogType.ADMINCLIENT_EXPORT_WORKSPACE_USERS,
          selectedWorkspace.id,
          { num_users: filteredUsers.length }
        )
      }
    />
  )

  return (
    <>
      <FullscreenLoading isLoading={isLoadingActiveUsers} />
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
        actions={
          <div className="flex space-x-4">
            {filteredUsers.length > 0 && exportUsers}
            {!canAddUsers && userSearchInput}
          </div>
        }
      />
      <SettingsLayout>
        {canAddUsers ? workspaceUsersTableAndBulkAdd : workspaceUsersTable}
      </SettingsLayout>
    </>
  )
}

export default WorkspaceUsers
