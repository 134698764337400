import { LucideIcon } from 'lucide-react'

import { Permission } from 'models/user-info'

import { TaskType } from 'utils/task'

// Note: We do care about order here as it's the order used on
// available workflows page.
// These strings are used in the URL, so they should be kebab-case.
export enum WorkflowType {
  REDLINE_ANALYSIS = 'redline-analysis',
  TRANSLATION = 'translation',
  COMPANY_PROFILE = 'company-profile',
  LEGACY_DRAFTING = 'drafting',
  OGC = 'ogc',
  DOCUMENT_COMPARISON = 'document-comparison',
  CONTRACTS = 'contracts',
  TRANSCRIPTS = 'transcripts',
  DILIGENCE = 'discovery',
  COMPETITIVE_ANALYSIS = 'competitive-analysis',
  DILIGENCE_TRANSCRIPTS = 'diligence-transcripts',
}

export type WorkflowConfig = {
  name: string
  path: string
  permissions: Permission[]
  type: WorkflowType
}

export type WorkflowDetails = WorkflowConfig & {
  icon: LucideIcon
  logoPath?: string
  component: React.FC | null
}

export const WorkflowTypeToTaskType: Record<WorkflowType, TaskType> = {
  [WorkflowType.COMPANY_PROFILE]: TaskType.COMPANY_PROFILE,
  [WorkflowType.REDLINE_ANALYSIS]: TaskType.REDLINES,
  [WorkflowType.TRANSLATION]: TaskType.TRANSLATION,
  [WorkflowType.OGC]: TaskType.OGC_REVIEW,
  [WorkflowType.LEGACY_DRAFTING]: TaskType.DRAFTING,
  [WorkflowType.DOCUMENT_COMPARISON]: TaskType.DOCUMENT_COMPARISON,
  [WorkflowType.CONTRACTS]: TaskType.CONTRACTS,
  [WorkflowType.TRANSCRIPTS]: TaskType.TRANSCRIPTS,
  [WorkflowType.DILIGENCE]: TaskType.PWC_DEALS,
  [WorkflowType.COMPETITIVE_ANALYSIS]: TaskType.COMPETITIVE_ANALYSIS,
  [WorkflowType.DILIGENCE_TRANSCRIPTS]: TaskType.DILIGENCE_TRANSCRIPTS,
}
