import React from 'react'

import {
  ColumnDef,
  Row,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { ArrowRight, MoreHorizontal } from 'lucide-react'

import { HistoryItem } from 'types/history'

import {
  calculateRouteForEvent,
  useNavigateWithQueryParams,
} from 'utils/routing'
import { TaskType } from 'utils/task'

import { useAssistantRecentQueriesHistory } from 'components/assistant-v2/hooks/use-assistant-recent-queries'
import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { REMOVE_PARAMS } from 'components/assistant-v2/utils/assistant-helpers'
import { useAuthUser } from 'components/common/auth-context'
import { FilterKey } from 'components/filter/filter-store'
import { DataTable } from 'components/ui/data-table/data-table'
import Icon from 'components/ui/icon/icon'
import Skeleton from 'components/ui/skeleton'
import { TextLink } from 'components/ui/text-link'

import {
  AssistantKindCell,
  AssistantQueryCell,
  AssistantSourcesCell,
  AssistantTimeCell,
} from './assistant-cells'

const AssistantQueries = () => {
  const userInfo = useAuthUser()
  const showRecentQueries = useAssistantStore((s) => s.showRecentQueries)
  const { data: historyItemData, isLoading } = useAssistantRecentQueriesHistory(
    userInfo.IsHistoryUser && showRecentQueries
  )
  const historyItems = historyItemData?.events || []
  const navigate = useNavigateWithQueryParams()

  const columns: Array<ColumnDef<HistoryItem>> = [
    {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => <AssistantQueryCell row={row} />,
      size: 'auto' as unknown as number,
    },
    {
      id: 'kind',
      header: 'Type',
      cell: ({ row }) => <AssistantKindCell row={row} />,
      size: 128,
    },
    {
      id: 'sources',
      header: 'Sources',
      cell: ({ row }) => <AssistantSourcesCell row={row} />,
      size: 160,
    },
    {
      id: 'createdAt',
      header: 'Created',
      cell: ({ row }) => <AssistantTimeCell row={row} />,
      size: 160,
    },
  ]

  const table = useReactTable<HistoryItem>({
    columns,
    data: historyItems,
    getCoreRowModel: getCoreRowModel(),
  })

  const getRowHref = (row: Row<HistoryItem>) => {
    return calculateRouteForEvent(
      { id: Number(row.original.id), kind: row.original.kind as TaskType },
      userInfo
    )
  }
  const handleRowClick = (row: Row<HistoryItem>) => {
    navigate(getRowHref(row))
  }

  if (!showRecentQueries) {
    return (
      <p className="pt-6 text-center text-muted">
        Your recent queries are hidden. To show queries, click the more (
        <Icon icon={MoreHorizontal} className="mx-1 inline" />) button.
      </p>
    )
  }

  if (isLoading) {
    return <Skeleton className="py-4" rows={3} rowHeight="h-4" />
  }

  if (!historyItems.length) {
    return (
      <p className="pt-6 text-center text-muted">
        Your recent queries will appear here
      </p>
    )
  }

  const historyFilterParams = new URLSearchParams({
    [FilterKey.TASK_TYPE]: [
      TaskType.ASSISTANT_CHAT,
      TaskType.ASSISTANT_DRAFT,
    ].join(','),
  })
  const historyUrl = `history?${historyFilterParams.toString()}`

  return (
    <>
      <DataTable
        hideTableBorder
        hrefForRow={getRowHref}
        hrefOptions={() => ({ removeParams: REMOVE_PARAMS })}
        onRowClick={handleRowClick}
        table={table}
        tableCellClassName="px-1 py-2 align-middle text-xs text-primary"
      />
      <TextLink
        className="mt-2 inline-block font-semibold"
        label="View all threads"
        href={historyUrl}
        trailingIcon={<ArrowRight />}
      />
    </>
  )
}

export default AssistantQueries
