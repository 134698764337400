import React, { useEffect, useState } from 'react'

import { Divider } from 'antd/lib'
import _ from 'lodash'

import { RawUser } from 'models/users'
import { FetchWorkspace, Workspace } from 'models/workspace'
import Services from 'services'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { Table, TableBody, TableCell, TableRow } from 'components/ui/table'
import { Textarea } from 'components/ui/text-area'

import WorkspaceDetailsApiTable from './workspace-details-api-table'

interface WorkspaceDetailsApiProps {
  workspace: Workspace
  users: RawUser[]
  fetchUserData: () => void
}

export interface ApiKey {
  id: string
  userId: string
  createdAt: string
  token: string
}

const WorkspaceDetailsApi = ({
  workspace,
  users,
  fetchUserData,
}: WorkspaceDetailsApiProps) => {
  const [apiWorkspace, setApiWorkspace] = useState<Workspace>(workspace)
  const [generatedKey, setGeneratedKey] = useState<string>('')
  const [generatedUser, setGeneratedUser] = useState<string>('')
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([])

  const userInfo = useAuthUser()

  useEffect(() => {
    // get api tokens from workspace.settings.apiTokens
    // get the user from users with same userId
    // set the apiKey array with userId, apiKeyId, created

    const apiTokens = apiWorkspace.settings.apiTokens
    if (_.isNil(apiTokens)) {
      return
    }
    const tokenMetadata = Object.keys(apiTokens).map((key: string) => {
      const token = apiTokens[key]
      const user = users.find((u) => u.id === token.userId)
      return {
        userId: user?.email ?? 'Unknown',
        id: token.id,
        createdAt: token.createdAt,
        token: key,
      }
    })
    setApiKeys(_.orderBy(tokenMetadata, ['createdAt'], ['desc']))
  }, [apiWorkspace, users])

  const generateNewApiKey = async () => {
    const newKey = await Services.Backend.Post<{ token: string; user: string }>(
      'settings/api/token',
      { workspaceId: apiWorkspace.id }
    )
    const token = newKey.token
    if (_.isNil(token)) {
      displayErrorMessage('Failed to generate new API Token', 10)
      return
    }
    const freshWorkspace = await FetchWorkspace(apiWorkspace.id)
    fetchUserData()
    setApiWorkspace(freshWorkspace)
    setGeneratedKey(token)
    setGeneratedUser(newKey.user)
    displaySuccessMessage(`API token ${newKey.user} generated successfully`, 5)
  }

  const onDeleteApiKey = async (id: string) => {
    const data = await Services.Backend.Post<{ user: string }>(
      `settings/api/token/delete`,
      {
        workspaceId: apiWorkspace.id,
        apiKeyId: id,
      }
    )
    if (_.isNil(data)) {
      displayErrorMessage('Failed to delete API Token', 10)
      return
    }
    const freshWorkspace = await FetchWorkspace(apiWorkspace.id)
    fetchUserData()
    setApiWorkspace(freshWorkspace)
    displaySuccessMessage(
      `API token and user ${data.user} deleted successfully`,
      5
    )
  }

  const cleanApiGeneratedState = () => {
    setGeneratedKey('')
    setGeneratedUser('')
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <p>
            Generate a new API token for <b>{apiWorkspace.clientName}</b>. Each
            token is tied with a new service user prefixed with <i>svc-user</i>.
            This user gets all <b>{apiWorkspace.clientName}</b> workspace perms,
            to assign more perms, click on the associated user created in the
            table below.
          </p>
        </CardHeader>
        <CardContent>
          <div className={cn('pb-1', { 'my-3': apiKeys.length > 0 })}>
            {generatedUser.length > 0 && (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <p className="font-semibold">API User</p>
                    </TableCell>
                    <TableCell>
                      <span className="pl-1">{generatedUser}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p className="font-semibold">Token hash</p>
                    </TableCell>
                    <TableCell className="">
                      <Textarea
                        placeholder="harvey-1234567890"
                        className="resize-none"
                        rows={3}
                        value={generatedKey}
                        disabled={!generatedKey.length}
                      />
                      <p className="ml-1 mt-1">
                        Note: This token will only be shown once, please copy
                        and store it securely before leaving this page.
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            <div className=" flex w-full items-center justify-end pr-4">
              {generatedKey.length > 0 ? (
                <div className="flex flex-row">
                  <Button
                    className=" mr-2"
                    onClick={cleanApiGeneratedState}
                    variant="destructive"
                  >
                    Clear
                  </Button>
                  <Button
                    onClick={() => navigator.clipboard.writeText(generatedKey)}
                  >
                    Copy
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={generateNewApiKey}
                  disabled={!userInfo.IsInternalAdminWriter}
                >
                  Create new token
                </Button>
              )}
            </div>
          </div>
          {apiKeys.length > 0 && (
            <>
              <Divider>Tokens</Divider>
              <WorkspaceDetailsApiTable
                apiKeys={apiKeys}
                onDeleteApiKey={onDeleteApiKey}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default WorkspaceDetailsApi
