import React from 'react'

import { X } from 'lucide-react'

import { cn } from 'utils/utils'

import { Badge } from './badge'

interface TagProps {
  text: string
  className?: string
  onDismiss?: () => void
  disabled?: boolean
}

const Tag = ({ text, className, onDismiss, disabled = false }: TagProps) => {
  return (
    <Badge
      variant="secondary"
      className={cn(
        'flex w-fit items-center space-x-2 bg-muted px-1.5',
        className
      )}
    >
      <span className="text-xs">{text}</span>
      {onDismiss && (
        <button
          onClick={onDismiss}
          className="flex size-4 items-center justify-center rounded transition-colors hover:bg-muted"
          disabled={disabled}
        >
          <X className="size-3" />
        </button>
      )}
    </Badge>
  )
}

export default Tag
