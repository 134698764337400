import * as Sentry from '@sentry/browser'
import { useQuery } from '@tanstack/react-query'

import { FetchDiligenceSections } from 'models/fetchers/diligence-sections-fetcher'
import Services from 'services'

import { HarvQueryKeyPrefix } from './all-query-keys'

export const diligenceSectionsQuery = () => ({
  queryKey: [HarvQueryKeyPrefix.DiligenceSections],
  queryFn: async () => FetchDiligenceSections(),
  initialData: [],
})

export const useDiligenceSectionsQuery = (isEnabled: boolean = true) => {
  const {
    isPending,
    error,
    data: sections,
    isFetching,
    isLoading,
  } = useQuery({
    enabled: isEnabled,
    staleTime: 1000 * 60 * 60, // 1 hour
    ...diligenceSectionsQuery(),
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { sections, isPending, isFetching, error, isLoading }
}
