import React from 'react'

import _ from 'lodash'

import { useAuthUser } from 'components/common/auth-context'
import ExamplesPage from 'components/examples/examples-page'

// Deprecating this
const LegacyExamples: React.FC = () => {
  const userInfo = useAuthUser()
  if (_.isNil(userInfo) || !userInfo.IsLibraryUser) {
    return null
  }

  return <ExamplesPage />
}

export default LegacyExamples
