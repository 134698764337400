import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'utils/utils'

const pillVariants = cva(
  'inline-flex truncate items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 shrink-0 min-w-12',
  {
    variants: {
      variant: {
        default: 'text-primary bg-white',
        secondary:
          'border-transparent bg-accent text-primary hover:bg-accent/80',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

enum PillColor {
  COLOR1 = 'color-1',
  COLOR2 = 'color-2',
  COLOR3 = 'color-3',
  COLOR4 = 'color-4',
  COLOR5 = 'color-5',
  COLOR6 = 'color-6',
  COLOR7 = 'color-7',
}

export interface PillProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof pillVariants> {
  isPill?: boolean
  // TODO: temporary, add wider palette to design system and then use here
  labelDot?: PillColor
}

function Dot({ labelDot }: { labelDot: PillColor }) {
  return (
    <span
      className={cn('mr-1 inline-block h-2 w-2 shrink-0 rounded-full', {
        'bg-[#ef4444]': labelDot === PillColor.COLOR1,
        'bg-[#3b82f6]': labelDot === PillColor.COLOR2,
        'bg-[#22c55e]': labelDot === PillColor.COLOR3,
        'bg-[#eab308]': labelDot === PillColor.COLOR4,
        'bg-[#a855f7]': labelDot === PillColor.COLOR5,
        'bg-[#ec4899]': labelDot === PillColor.COLOR6,
        'bg-[#737373]': labelDot === PillColor.COLOR7,
      })}
    />
  )
}

function Pill({
  className,
  variant,
  labelDot,
  ...props
}: PillProps): JSX.Element {
  return (
    <div className={cn(pillVariants({ variant }), className)} {...props}>
      {labelDot && <Dot labelDot={labelDot} />}
      {props.children}
    </div>
  )
}

export { Pill, Dot, PillColor, pillVariants }
