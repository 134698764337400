import Services from 'services'
import { TaskType } from 'types/task'

export interface UsageDataTimeseriesEntryRaw {
  timestamp: string
  queryCountByType: {
    [key in TaskType]: number
  }
  userCountByType: {
    [key in TaskType]: number
  }
  queryCountTotal: number
  userCountTotal: number
}

export interface UsageDataRaw {
  distinctTypes: TaskType[]
  aggregate: {
    userCountTotal: number // active users
    queryCountTotal: number
    userCountByType: {
      [key in TaskType]: number
    }
    queryCountByType: {
      [key in TaskType]: number
    }
  }
  // should be returned in ascending order from BE
  timeseries: UsageDataTimeseriesEntryRaw[]
  userStats: {
    // user email as key
    [key: string]: {
      // 0 value means user has access to EventKind but ran zero queries
      // no entry for event kind user doesn't have access to
      [key in TaskType]: number
    }
  }
}

// interface for post-processed FE data
export interface UsageDataTimeseriesEntry {
  timestamp: string
  parsedDateShortened: string // e.g. Jan 1
  parsedDateString: string // e.g. January 1, 2024
  queryCountByType: {
    [key: string]: number
  }
  userCountByType: {
    [key: string]: number
  }
  queryCountTotal: number
  userCountTotal: number
}

// interface for post-processed FE data
export interface UsageData {
  distinctTypes: string[] // using task definitions labels
  aggregate: {
    // for area chart
    userCountTotal: number
    queryCountTotal: number
    // for pie chart & area chart
    userCountByType: {
      [key: string]: number
    }
    queryCountByType: {
      [key: string]: number
    }
  }
  // for area chart
  timeseries: UsageDataTimeseriesEntry[]
  // for user stats table
  userStats: {
    // dictionary is now flattened with userEmail and product lines as keys
    userEmail: string
    // 0 value means user has access but ran zero queries
    // no entry for event kind user doesn't have access to
    [key: string]: number | string
  }[]
}

export interface FetchUsageDataProps {
  workspaceSlug: string
  createdAfterOrEqual: Date
  createdBeforeOrEqual: Date
  userTz?: string
}

export async function FetchUsageDataRaw(
  props: FetchUsageDataProps
): Promise<UsageDataRaw> {
  const params = new URLSearchParams()
  params.append('workspace_slug', props.workspaceSlug)
  params.append(
    'created_after_or_equal',
    props.createdAfterOrEqual.toISOString()
  )
  params.append(
    'created_before_or_equal',
    props.createdBeforeOrEqual.toISOString()
  )
  params.append(
    'user_tz',
    props.userTz ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const result = await Services.Backend.Get<UsageDataRaw>(
    `dashboard/usage?${params.toString()}`
  )
  return result
}
