import React from 'react'

import _ from 'lodash'
import { Search, X } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { InputProps } from 'components/ui/input'

import { DebouncedInput } from './debounced-input'
import Icon from './icon/icon'

interface SearchInputProps extends InputProps {
  value: string
  setValue: (value: string) => void
  withIcon?: boolean
  handleClear?: () => void
}

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ value, setValue, withIcon = false, handleClear, ...props }, ref) => {
    return (
      <div className="relative flex items-center">
        <DebouncedInput
          type="text"
          className={cn('w-58 h-8 pr-8', { 'pl-8': withIcon })}
          placeholder="Search"
          onChange={(e) => {
            const newValue = e.target.value
            if (value !== newValue) {
              setValue(newValue)
            }
          }}
          ref={ref}
          value={value}
          {...props}
        />
        {withIcon && (
          <Search size={16} className="absolute left-2 text-primary" />
        )}
        {!_.isEmpty(value) && (
          <Button
            size="xsIcon"
            className="absolute right-1"
            variant="ghost"
            onClick={handleClear ? handleClear : () => setValue('')}
          >
            <Icon icon={X} size="small" />
          </Button>
        )}
      </div>
    )
  }
)

SearchInput.displayName = 'SearchInput'

export default SearchInput
