import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import _ from 'lodash'
import { ListFilter, XCircle } from 'lucide-react'

import { Workspace } from 'models/workspace'
import Services from 'services'

import { SafeRecord } from 'utils/safe-types'
import { cn } from 'utils/utils'

import { LibraryFilterComponent } from './filter-components'
import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { RecordBase } from 'components/filter/types/record-base'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { Label } from 'components/ui/label'

import { FilterFunction } from './library-filter-store'
import { LibraryItem, LibraryItemKind } from './library-types'

interface LibraryFilterRowInternalProps<T extends RecordBase> {
  filterComponents: Array<LibraryFilterComponent<T>>
  filterKeys: string[]
  type: LibraryItemKind
  workspace: Workspace
  filterStore: {
    filterValues: SafeRecord<string, string>
    registerFilter: (
      key: string,
      filterFunction: FilterFunction<LibraryItem> | null
    ) => void
    setFilterValue: (key: string, value: string) => void
    setFilterValues: (values: SafeRecord<string, string>) => void
  }
}

const LibraryFilterRow: React.FC<
  LibraryFilterRowInternalProps<LibraryItem>
> = ({ filterStore, type, workspace, filterComponents, filterKeys }) => {
  const { registerFilter, filterValues, setFilterValue, setFilterValues } =
    filterStore

  const builtComponents = React.useMemo(
    () =>
      filterComponents.map(({ Component, filterKey, ...props }) =>
        Component({
          filterKey: filterKey,
          value: filterValues[filterKey],
          setValue: (value: string) => setFilterValue(filterKey, value),
          ...props,
        })
      ),
    [filterComponents, filterValues, setFilterValue]
  )
  const allFilterKeys = React.useMemo(() => {
    const filterKeySet = new Set(filterKeys)
    filterComponents.forEach(({ filterKey }) => filterKeySet.add(filterKey))
    return Array.from(filterKeySet)
  }, [filterComponents, filterKeys])
  const filterKeysLeading = React.useMemo(
    () =>
      new Set(
        filterComponents
          .filter(({ leading }) => leading)
          .map(({ filterKey }) => filterKey)
      ),
    [filterComponents]
  )
  const filterKeysTrailing = React.useMemo(
    () =>
      new Set(
        filterComponents
          .filter(({ trailing }) => trailing)
          .map(({ filterKey }) => filterKey)
      ),
    [filterComponents]
  )
  const filterKeysInDropdown = React.useMemo(
    () =>
      new Set(
        filterComponents
          .filter(({ leading, trailing }) => !leading && !trailing)
          .map(({ filterKey }) => filterKey)
      ),
    [filterComponents]
  )
  const hasFilterValues = React.useMemo(
    () => filterComponents.some(({ filterKey }) => filterValues[filterKey]),
    [filterComponents, filterValues]
  )
  const filterCountInDropdown = React.useMemo(() => {
    return Object.keys(filterValues).filter((key) =>
      filterKeysInDropdown.has(key)
    ).length
  }, [filterValues, filterKeysInDropdown])

  React.useEffect(() => {
    builtComponents.forEach(({ filterKey, filterLogic }) => {
      registerFilter(filterKey, filterLogic)
    })

    return () => {
      allFilterKeys.forEach((filterKey) => {
        registerFilter(filterKey, null)
      })
    }
  }, [builtComponents, registerFilter, allFilterKeys])

  const filterValuesLoadedFromUrl = useRef(false)
  React.useEffect(() => {
    if (filterValuesLoadedFromUrl.current) {
      return
    }
    filterValuesLoadedFromUrl.current = true

    const searchParams = new URLSearchParams(location.search)

    const newFilterValues = { ...filterValues }
    allFilterKeys.forEach((filterKey) => {
      const paramValue = searchParams.get(filterKey)
      if (paramValue !== null) {
        newFilterValues[filterKey] = paramValue
      }
    })
    setFilterValues(newFilterValues)
  }, [filterValuesLoadedFromUrl, filterValues, allFilterKeys, setFilterValues])

  const { trackEvent } = useAnalytics()
  const navigate = useNavigate()
  const navigateWithFilterValues = React.useCallback(
    (filterValues: SafeRecord<string, string>) => {
      const searchParams = new URLSearchParams(location.search)
      allFilterKeys.forEach((filterKey) => {
        const value = filterValues[filterKey]
        if (!value || value === '') {
          searchParams.delete(filterKey)
        } else {
          searchParams.set(filterKey, value)
        }
      })
      if (
        new URLSearchParams(location.search).toString() !==
        searchParams.toString()
      ) {
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        })
      }
    },
    [allFilterKeys, navigate]
  )

  const prevFilterValuesRef = React.useRef<SafeRecord<string, string>>({})
  React.useEffect(() => {
    if (filterValuesLoadedFromUrl.current) {
      // Only navigate if the current filterValues or grouping are different from the previous ones
      if (!_.isEqual(prevFilterValuesRef.current, filterValues)) {
        navigateWithFilterValues(filterValues)
        // Update the ref with the current filterValues for the next comparison
        prevFilterValuesRef.current = filterValues
      }
    }
  }, [
    filterValuesLoadedFromUrl,
    filterValues,
    prevFilterValuesRef,
    navigateWithFilterValues,
  ])

  return (
    <div className="flex flex-row-reverse flex-wrap items-center gap-2">
      {builtComponents
        .filter(({ filterKey }) => filterKeysLeading.has(filterKey))
        .map((component) => component.render({}))}
      {filterKeysInDropdown.size > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="data-[state=open]:bg">
            <Button
              variant="outline"
              className={cn('justify-between', {
                'text-muted': filterCountInDropdown === 0,
              })}
            >
              <ListFilter className="mr-2 h-4 w-4 opacity-50" />
              Filters
              {filterCountInDropdown > 0 && (
                <Badge variant="secondary" className="ml-3 px-2">
                  {filterCountInDropdown}
                </Badge>
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex flex-col gap-4 p-4" align="end">
            <Label className="my-0.5">Additional filters</Label>
            <div className="flex flex-col gap-2">
              {builtComponents
                .filter(({ filterKey }) => filterKeysInDropdown.has(filterKey))
                .map((component) => (
                  <div
                    key={component.filterKey}
                    className="flex h-8 items-center justify-between"
                  >
                    <p className="mr-4 text-muted">{component.displayName}</p>
                    {component.render({})}
                  </div>
                ))}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {builtComponents
        .filter(({ filterKey }) => filterKeysTrailing.has(filterKey))
        .map((component) => component.render({}))}
      {hasFilterValues && (
        <Button
          variant="dashed"
          onClick={() => {
            Services.HoneyComb.Record({
              metric: 'ui.library_table_reset_filters',
              library_item_kind: type,
              workspace_slug: workspace.slug,
              filter_values: filterValues,
            })
            trackEvent('Library Filters Reset', {
              kind: type,
            })
            trackEvent(FILTER_CHANGED_EVENT_NAME, {
              entity_name: 'library table',
              field_changed: 'all reset',
            })
            if (hasFilterValues) {
              setFilterValues({})
            }
          }}
          className="text-muted"
        >
          <XCircle className="mr-2 h-4 w-4" />
          Reset
        </Button>
      )}
    </div>
  )
}

export { LibraryFilterRow }
