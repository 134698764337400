import React, { useMemo } from 'react'

import _ from 'lodash'
import { Pie, PieChart } from 'recharts'

import { UsageData } from 'models/usage-data'

import { readableNumber } from 'utils/utils'

import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  DATA_VIZ_COLORS,
} from 'components/ui/chart-components/chart'
import Skeleton from 'components/ui/skeleton'

import { CHART_CONFIGS, ChartConfigType, TOTAL_KEY } from './dashboard-page'

export interface PieChartCardProps {
  usageData: UsageData | undefined
  isLoading: boolean
  unit: ChartConfigType
  selectedKey: string
}

const PieChartCard = ({
  usageData,
  isLoading,
  unit,
  selectedKey,
}: PieChartCardProps) => {
  const totalGraphCount = useMemo(() => {
    if (_.isNil(usageData)) return 0
    switch (unit) {
      case CHART_CONFIGS.users:
        if (selectedKey === TOTAL_KEY) {
          return usageData.aggregate.userCountTotal
        }
        return usageData.aggregate.userCountByType[selectedKey] ?? 0
      case CHART_CONFIGS.queries:
        if (selectedKey === TOTAL_KEY) {
          return usageData.aggregate.queryCountTotal
        }
        return usageData.aggregate.queryCountByType[selectedKey] ?? 0
      default:
        console.error(`Unknown unit: ${unit}`)
        return 0
    }
  }, [usageData, unit, selectedKey])

  const chartData = useMemo(() => {
    if (_.isNil(usageData)) return []
    switch (unit) {
      // map to data shape expected by pie chart
      // array of objects with name and value properties
      case CHART_CONFIGS.users:
        if (selectedKey === TOTAL_KEY) {
          return Object.entries(usageData.aggregate.userCountByType).map(
            ([product, count], index) => ({
              product,
              count,
              fill: `var(--color-${index})`,
            })
          )
        }
        return Object.entries(usageData.aggregate.userCountByType)
          .filter(([product]) => product === selectedKey)
          .map(([product, count], index) => ({
            product,
            count,
            fill: `var(--color-${index})`,
          }))
      case CHART_CONFIGS.queries:
        if (selectedKey === TOTAL_KEY) {
          return Object.entries(usageData.aggregate.queryCountByType).map(
            ([product, count], index) => ({
              product,
              count,
              fill: `var(--color-${index})`,
            })
          )
        }
        return Object.entries(usageData.aggregate.queryCountByType)
          .filter(([product]) => product === selectedKey)
          .map(([product, count], index) => ({
            product,
            count,
            fill: `var(--color-${index})`,
          }))
      default:
        console.error(`Unknown unit: ${unit}`)
        return []
    }
  }, [usageData, unit, selectedKey])

  const chartConfig = useMemo(() => {
    if (_.isNil(usageData)) return {}
    return usageData.distinctTypes.reduce(
      (acc, _, index) => {
        acc[index] = {
          color: DATA_VIZ_COLORS[index % DATA_VIZ_COLORS.length],
        }
        return acc
      },
      {} as Record<string, { color: string }>
    )
  }, [usageData])

  return (
    <Card className="rounded-lg border">
      <CardHeader>
        <CardTitle>
          <div className="pl-6">
            <span className="mr-2 text-xl">
              {readableNumber(totalGraphCount)}
            </span>
            <span className="text-base text-muted">{unit.label}</span>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="h-80">
        {isLoading && <Skeleton />}
        {!isLoading && (
          <ChartContainer config={chartConfig}>
            <PieChart width={800} height={800}>
              <Pie
                data={chartData}
                dataKey="count"
                nameKey="product"
                cx="50%"
                cy="50%"
                outerRadius={80}
              />
              <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            </PieChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  )
}

export default PieChartCard
