import React from 'react'

import { isNil } from 'lodash'

import { cn } from 'utils/utils'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  getStreamingState,
  StreamingState,
} from 'components/assistant-v2/utils/assistant-helpers'
import { Button } from 'components/ui/button'
import { Progress } from 'components/ui/progress'

interface Props {
  className?: string
  message?: string
  onCancel?: () => void
}

const AssistantLoadingState = ({ className, message, onCancel }: Props) => {
  const getStreamingMessageLoadingState = useAssistantStore(
    (s) => s.getStreamingMessageLoadingState
  )

  const loadingState = getStreamingMessageLoadingState()
  if (isNil(message)) message = loadingState?.message ?? 'Loading…'
  // TODO: Backend should format these
  if (!/…|\.\.\./.test(message) && !message.startsWith('Done')) message += '…'

  const progressPercent = loadingState?.progressPercent ?? 0
  const isProcessing =
    getStreamingState({ headerText: message, progress: progressPercent }) ===
    StreamingState.PROCESSING

  return (
    <div
      className={cn(
        'flex items-center justify-between space-x-2 rounded-md bg-secondary px-4 py-2',
        className
      )}
    >
      <p className="basis-1/4 truncate text-xs">{message}</p>
      <Progress
        value={progressPercent}
        className={cn(
          'delay-250 h-2 w-1/2 bg-button-secondary-hover opacity-0 transition-opacity duration-500',
          {
            'opacity-100': isProcessing,
          }
        )}
      />
      <div className="shrink-0 basis-1/4 text-right">
        <Button
          className="hover:bg-button-secondary-hover"
          onClick={onCancel}
          size="sm"
          variant="ghost"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default AssistantLoadingState
