import React, { useMemo, useState } from 'react'

import { Export } from 'models/fetchers/history-fetcher'
import { UserInfo } from 'models/user-info'
import { Workspace } from 'models/workspace'
import { type StatCard as StatCardType } from 'types'

import { HARVEY_START_DATE } from 'utils/utils'

import { AppHeader } from 'components/common/app-header'
import FullscreenLoading from 'components/common/fullscreen-loading'
import AreaChartCard from 'components/dashboard/area-chart-card'
import PieChartCard from 'components/dashboard/pie-chart-card'
import ExportButton from 'components/history/export-button'
import useWorkspacesForUser from 'components/settings/hooks/use-workspaces-for-user'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import useUsageData from 'components/settings/usage/use-usage-data'
import { AllenOveryWorkspaceId } from 'components/special-ids'
import DateRangePicker from 'components/ui/date-range-picker'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

import './Dashboard.css'
import StatCard from './stat-card'
import UserStatsTable from './user-stats-table'

const CARDS: Array<Pick<StatCardType, 'unit' | 'endpoint'>> = [
  {
    unit: 'Unique users',
    endpoint: 'users/graph',
  },
  {
    unit: 'Queries processed',
    endpoint: 'queries/graph',
  },
]

export const TOTAL_KEY = 'All types'

export const CHART_CONFIGS = {
  users: {
    label: 'Unique users',
    tooltipLabel: 'Users',
    areaChart: {
      title: 'Unique users over time',
    },
    pieChart: {
      title: 'Unique users by product',
    },
  },
  queries: {
    label: 'Queries processed',
    tooltipLabel: 'Queries',
    areaChart: {
      title: 'Queries processed over time',
    },
    pieChart: {
      title: 'Queries processed by product',
    },
  },
} as const

export type ChartConfigType = (typeof CHART_CONFIGS)[keyof typeof CHART_CONFIGS]

export type TimeSeriesData = {
  total: number
  [key: string]: number
} & {
  timestamp: string
}

const Dashboard = (): JSX.Element => {
  const { workspaces, isLoadingWorkspaces } = useWorkspacesForUser()
  const settingsUser = useSettingsState((s) => s.settingsUser)

  if (
    !settingsUser ||
    (!settingsUser.IsUsageDashboardViewer &&
      !settingsUser.IsUsageDashboardV2Viewer)
  ) {
    return (
      <SettingsError
        description={`You don’t have access to usage statistics for ${settingsUser?.workspace.clientName}`}
      />
    )
  }

  return (
    <DashboardContent
      settingsUser={settingsUser}
      workspaces={workspaces}
      isLoadingWorkspaces={isLoadingWorkspaces}
    />
  )
}

const DashboardContent = ({
  settingsUser,
  workspaces,
  isLoadingWorkspaces,
}: {
  settingsUser: UserInfo
  workspaces: Workspace[]
  isLoadingWorkspaces: boolean
}) => {
  const selected = settingsUser?.workspace ?? workspaces[0]

  const {
    usageData,
    isLoadingUsageData,
    selectedDateRange,
    updateSelectedDateRange,
  } = useUsageData({
    workspaceSlug: selected.slug,
  })

  // TODO: will change to multi-select in future
  const keyOptions = useMemo(
    () => [TOTAL_KEY, ...(usageData?.distinctTypes ?? [])],
    [usageData]
  )
  const [selectedKey, setSelectedKey] = useState<string>(TOTAL_KEY)

  return (
    <>
      <FullscreenLoading isLoading={isLoadingWorkspaces} />
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
        actions={
          <ExportButton
            type={Export.Usage}
            workspace={selected ?? settingsUser.workspace}
          />
        }
      />
      <SettingsLayout>
        {!settingsUser.IsUsageDashboardV2Viewer && (
          <div className="space-y-4 pb-12">
            {CARDS.map((card, idx) => (
              <StatCard
                key={idx}
                {...card}
                workspaceSlug={(selected ?? settingsUser.workspace).slug}
              />
            ))}
          </div>
        )}

        {settingsUser.IsUsageDashboardV2Viewer && (
          <>
            <div className="flex items-center justify-between">
              <h2 className="text-md font-semibold">Metrics</h2>
              <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-x-2 md:space-y-0">
                <DateRangePicker
                  selectedDateRange={selectedDateRange}
                  onSelectDateRange={updateSelectedDateRange}
                  disableFutureDates
                  disablePastDatesBeforeDate={HARVEY_START_DATE}
                />
                <Select onValueChange={setSelectedKey}>
                  <SelectTrigger className="h-8 min-w-28 lg:min-w-48">
                    <SelectValue>{selectedKey}</SelectValue>
                  </SelectTrigger>
                  <SelectContent className="max-h-60 overflow-y-auto">
                    {keyOptions.map((key) => (
                      <SelectItem key={key} value={key}>
                        {key}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="mt-2 flex flex-col space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0">
              <div className="lg:flex-grow">
                <PieChartCard
                  usageData={usageData}
                  unit={CHART_CONFIGS.users}
                  isLoading={isLoadingUsageData}
                  selectedKey={selectedKey}
                />
              </div>
              <div className="lg:flex-grow">
                <PieChartCard
                  usageData={usageData}
                  unit={CHART_CONFIGS.queries}
                  isLoading={isLoadingUsageData}
                  selectedKey={selectedKey}
                />
              </div>
            </div>
            <div className="mt-4 space-y-4">
              <AreaChartCard
                usageData={usageData}
                unit={CHART_CONFIGS.users}
                isLoading={isLoadingUsageData}
                selectedKey={selectedKey}
              />
              <AreaChartCard
                usageData={usageData}
                unit={CHART_CONFIGS.queries}
                isLoading={isLoadingUsageData}
                selectedKey={selectedKey}
              />
            </div>

            {selected.id !== AllenOveryWorkspaceId && (
              <div className="mt-4">
                <UserStatsTable
                  usageData={usageData}
                  isLoading={isLoadingUsageData}
                  selectedKey={selectedKey}
                />
              </div>
            )}
          </>
        )}
      </SettingsLayout>
    </>
  )
}

export default Dashboard
