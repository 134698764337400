import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { IRowNode } from 'ag-grid-community'
import { CustomNoRowsOverlayProps } from 'ag-grid-react'
import { X } from 'lucide-react'

import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { filtersSearchParamKey } from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'

const NoRowsOverlay = (props: CustomNoRowsOverlayProps) => {
  const { api } = props

  const [totalRowCount, setTotalRowCount] = useState<number>(0)

  const [, setSearchParams] = useSearchParams()
  const resetFilterState = useVaultDataGridFilterStore(
    (state) => state.resetFilterState
  )

  useMount(() => {
    let totalRowCount = 0
    api.forEachNode((node: IRowNode) => {
      // if the node is a group row, we don't want to count it
      if (node.group) return
      totalRowCount++
    })
    setTotalRowCount(totalRowCount)
  })

  const clearFilters = () => {
    resetFilterState()
    api.setFilterModel(null)
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(filtersSearchParamKey)
      return newParams
    })
  }

  return (
    <div role="presentation" className="flex flex-col space-y-4 text-muted">
      <p className="ml-2.5">No rows to display</p>
      {totalRowCount > 0 && (
        <div className="mx-2 flex items-center justify-between gap-4 rounded-md border px-4 py-2 sm:min-w-96">
          <p className="text-xs">{`${totalRowCount} files hidden by filters`}</p>
          <Button
            onClick={clearFilters}
            variant="ghost"
            className="flex items-center gap-2"
          >
            <p className="text-xs">Clear filters</p>
            <Icon icon={X} size="small" />
          </Button>
        </div>
      )}
    </div>
  )
}

export default NoRowsOverlay
