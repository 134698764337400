import { VaultFolderShareStatusApiResponseDataShareStatus } from 'openapi/models/VaultFolderShareStatusApiResponseDataShareStatus'

import { SafeRecord } from 'utils/safe-types'

export const isProjectShared = (
  sharedProjectIds: Set<string>,
  permissionsByProjectId: SafeRecord<
    string,
    VaultFolderShareStatusApiResponseDataShareStatus
  >,
  projectId?: string
) => {
  if (!projectId) {
    return false
  }

  if (sharedProjectIds.has(projectId)) {
    return true
  }
  // If user is the owner of the project and has shared it with others,
  // also count as shared project
  const projectPermissions = permissionsByProjectId[projectId as string]
  if (
    (projectPermissions?.permissionsByUser &&
      projectPermissions.permissionsByUser.length > 0) ||
    (projectPermissions?.permissionsByWorkspace &&
      projectPermissions.permissionsByWorkspace.length > 0)
  ) {
    return true
  }
  return false
}
