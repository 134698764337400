import { create } from 'zustand'

type AssistantV2TourState = {
  isTourActive: boolean
  startTour: () => void
  endTour: () => void
}

export const useAssistantV2TourStore = create<AssistantV2TourState>((set) => ({
  isTourActive: false,
  startTour: () => set({ isTourActive: true }),
  endTour: () => set({ isTourActive: false }),
}))
