import { useState, useCallback } from 'react'
import { DateRange } from 'react-day-picker'
import { useMount } from 'react-use'

import { FetchUsageDataRaw, UsageData, UsageDataRaw } from 'models/usage-data'
import { TaskType } from 'types/task'

import { getOneMonthPrior } from 'utils/date-utils'
import {
  getCleanedTaskType,
  useAllTaskLabelLookup,
} from 'utils/task-definitions'
import { displayErrorMessage } from 'utils/toast'
import { ERROR_DATE, HARVEY_START_DATE, parseIsoString } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'

interface UseUsageDataProps {
  workspaceSlug: string
}

// task type / event kind is returned by backend, but we want to display the task label
// some types will map to the same task label
const replaceTaskTypeWithTaskLabel = (
  taskTypeDictionary: { [key in TaskType]: number },
  taskLabelLookup: { [key: string]: string }
): { [key: string]: number } => {
  const taskLabelDictionary: { [key: string]: number } = {}

  Object.entries(taskTypeDictionary).forEach(([key, value]) => {
    // the keys in taskTypeDictionary should be TaskType, but it gets cast to string
    const cleanedKey = getCleanedTaskType(key as TaskType)
    if (!(cleanedKey in taskLabelLookup)) {
      console.warn('key not in taskLabelLookup', cleanedKey, key)
    }
    const label = taskLabelLookup[cleanedKey] ?? cleanedKey
    taskLabelDictionary[label] = (taskLabelDictionary[label] || 0) + value
  })

  return taskLabelDictionary
}

const processUsageDataRaw = (
  usageDataRaw: UsageDataRaw,
  taskLabelLookup: { [key: string]: string }
): UsageData => {
  // TODO make sure task label lookup logic is right, especially with many to many mappings
  const taskLablesSet = new Set<string>()
  usageDataRaw.distinctTypes.forEach((taskType) => {
    const cleanedKey = getCleanedTaskType(taskType)
    const label = taskLabelLookup[cleanedKey] ?? cleanedKey
    taskLablesSet.add(label)
  })

  const userCountByType = replaceTaskTypeWithTaskLabel(
    usageDataRaw.aggregate.userCountByType,
    taskLabelLookup
  )
  const queryCountByType = replaceTaskTypeWithTaskLabel(
    usageDataRaw.aggregate.queryCountByType,
    taskLabelLookup
  )

  // preserve the order for graph (in case we don't sort again)
  const timeseries = usageDataRaw.timeseries.map((timeseries) => {
    const parsedDate: Date = parseIsoString(timeseries.timestamp)
    let parsedDateShortened: string // e.g. Jan 1
    let parsedDateString: string // e.g. January 1, 2024
    if (parsedDate === ERROR_DATE) {
      // parseIsoString failed
      console.error('Failed to parse date', timeseries.timestamp)
      parsedDateShortened = timeseries.timestamp
      parsedDateString = timeseries.timestamp
    } else {
      parsedDateShortened = new Intl.DateTimeFormat(navigator.language, {
        month: 'short',
        day: 'numeric',
      }).format(parsedDate)
      parsedDateString = new Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(parsedDate)
    }
    return {
      ...timeseries,
      parsedDateShortened: parsedDateShortened,
      parsedDateString: parsedDateString,
      userCountByType: replaceTaskTypeWithTaskLabel(
        timeseries.userCountByType,
        taskLabelLookup
      ),
      queryCountByType: replaceTaskTypeWithTaskLabel(
        timeseries.queryCountByType,
        taskLabelLookup
      ),
    }
  })

  const userStats: Record<string, Record<string, number>> = {}

  Object.entries(usageDataRaw.userStats).forEach(([userEmail, countByType]) => {
    userStats[userEmail] = replaceTaskTypeWithTaskLabel(
      countByType,
      taskLabelLookup
    )
  })

  return {
    distinctTypes: Array.from(taskLablesSet).sort(),
    aggregate: {
      userCountTotal: usageDataRaw.aggregate.userCountTotal,
      queryCountTotal: usageDataRaw.aggregate.queryCountTotal,
      userCountByType: userCountByType,
      queryCountByType: queryCountByType,
    },
    timeseries: timeseries,
    userStats: Object.entries(userStats).map(([userEmail, eventKindStats]) => ({
      userEmail,
      ...eventKindStats,
    })),
  }
}

const useUsageData = ({ workspaceSlug }: UseUsageDataProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >({
    from: getOneMonthPrior(),
    to: new Date(),
  })
  const authUser = useAuthUser()

  const [isLoadingUsageData, setIsLoadingUsageData] = useState(true)
  const [usageData, setUsageData] = useState<UsageData>()

  const taskLabelLookup = useAllTaskLabelLookup()

  const fetchUsageData = useCallback(
    async (dateRange: DateRange | undefined) => {
      // NOTE: need to prevent BE call if user doesn't have perms for v2 dashboard
      if (!authUser || !authUser.IsUsageDashboardV2Viewer) {
        return
      }

      setIsLoadingUsageData(true)

      try {
        // all in user's local timezone
        const createdAfterOrEqual = dateRange?.from ?? HARVEY_START_DATE
        createdAfterOrEqual.setHours(0, 0, 0, 0)

        // set to end of day if from is set by user, otherwise set to all possible times
        const createdBeforeOrEqual =
          dateRange?.to ??
          (dateRange?.from ? new Date(createdAfterOrEqual) : new Date())
        createdBeforeOrEqual.setHours(23, 59, 59)

        const usageDataRaw = await FetchUsageDataRaw({
          workspaceSlug,
          createdAfterOrEqual,
          createdBeforeOrEqual,
          userTz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })

        const usageData = processUsageDataRaw(usageDataRaw, taskLabelLookup)
        setUsageData(usageData)
      } catch (error) {
        console.error('Failed to fetch usage data', error)
        displayErrorMessage('Failed to fetch usage data')
      } finally {
        setIsLoadingUsageData(false)
      }
    },
    [workspaceSlug, taskLabelLookup, authUser]
  )

  useMount(() => {
    void fetchUsageData(selectedDateRange)
  })

  // need function signature to match useState setter
  const updateSelectedDateRange = useCallback(
    (dateRange: DateRange | undefined) => {
      // if dateRange is undefined, user has cleared filter, so we should fetch all data
      setSelectedDateRange(dateRange)
      void fetchUsageData(dateRange)
    },
    [fetchUsageData]
  )

  return {
    usageData,
    isLoadingUsageData,
    selectedDateRange,
    updateSelectedDateRange,
  }
}

export default useUsageData
