import React, { useState } from 'react'

import pluralize from 'pluralize'

import { InitWorkspaceOffboard } from 'models/workspace'

import { displaySuccessMessage } from 'utils/toast'

import { Alert, AlertTitle } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

interface WorkspaceOffboardModalProps {
  open: boolean
  workspaceId: number
  gracePeriod: number
  onOpenChange: (open: boolean) => void
  onOffboardSuccess: () => void
}

const WorkspaceOffboardModal = ({
  open,
  workspaceId,
  gracePeriod,
  onOpenChange,
  onOffboardSuccess,
}: WorkspaceOffboardModalProps) => {
  const [offboardAcknowledge, setOffboardAcknowledge] = useState(false)
  const [isOffboarding, setIsOffboarding] = useState(false)

  const handleOffboardSubmit = async () => {
    setIsOffboarding(true)
    try {
      const res = await InitWorkspaceOffboard(workspaceId, gracePeriod)
      if (res) {
        onOffboardSuccess()
        displaySuccessMessage('Workspace offboard scheduled successfully.')
      } else {
        displaySuccessMessage(
          'Unexpected error during offboarding, please try again.'
        )
      }
    } finally {
      setIsOffboarding(false)
      setOffboardAcknowledge(false)
      onOpenChange(false)
    }
  }

  const dateText =
    gracePeriod === 0
      ? 'TODAY'
      : `in ${gracePeriod} ${pluralize('day', gracePeriod)}`

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[600px]">
        <DialogTitle>
          <div className="text-destructive">Workspace Offboard</div>
        </DialogTitle>
        <DialogDescription>
          <p>
            User and API access from this workspace will be blocked IMMEDIATELY.
            All data will be wiped out {dateText}. Once data is deleted, it
            cannot be recovered.
          </p>
          {!isOffboarding && (
            <Alert className="h-17 mt-4" variant="destructive">
              <AlertTitle className="-mt-2 flex justify-between">
                {`Please acknowledge you are very sure you want to offboard this workspace. 
                  It's users and API tokens will lose accesses immediately, 
                  all associated data to the workspace will be wiped out ${dateText} with no way to recover it.`}
                <Checkbox
                  checked={offboardAcknowledge}
                  onCheckedChange={() =>
                    setOffboardAcknowledge(!offboardAcknowledge)
                  }
                />
              </AlertTitle>
            </Alert>
          )}
          {isOffboarding && (
            <div className="mt-4 flex items-center">
              <Spinner className="mr-2" />
              <span>This operation could take up to a minute...</span>
            </div>
          )}
        </DialogDescription>
        <DialogFooter>
          <Button
            className="btn"
            variant="secondary"
            onClick={() => {
              setOffboardAcknowledge(false)
              onOpenChange(false)
            }}
            disabled={isOffboarding}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={handleOffboardSubmit}
            disabled={!offboardAcknowledge || isOffboarding}
            variant="destructive"
          >
            Offboard
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspaceOffboardModal
