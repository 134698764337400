import React, { useEffect, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { InfoIcon, UploadCloudIcon } from 'lucide-react'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { FileType } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { mbToBytes } from 'utils/file-utils'
import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant-v2/hooks/use-assistant-analytics'
import { useAssistantFileUpload } from 'components/assistant-v2/hooks/use-assistant-file-upload'
import {
  FileUploadingState,
  useAssistantStore,
} from 'components/assistant-v2/stores/assistant-store'
import {
  ACCEPTED_FILE_TYPES,
  NUM_MAX_FILES,
} from 'components/assistant-v2/utils/constants'
import {
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MAX_ZIP_FILE_SIZE,
} from 'components/assistant/assistant-utils'
import { Dropzone } from 'components/common/dropzone'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import AssistantFiles from './assistant-files'
import { AssistantMode } from './assistant-mode-select'

type Props = {
  dropzoneSources?: (JSX.Element | null)[]
  hasFilesHeader: boolean
  mode: AssistantMode
  onUpload: () => void
  onCancel: () => void
  setAskHarveyDisabled: (disabled: boolean) => void
}

const AssistantFilesInput: React.FC<Props> = ({
  dropzoneSources,
  hasFilesHeader,
  mode,
  onUpload,
  onCancel,
  setAskHarveyDisabled,
}) => {
  const { handleFileUpload, handleRemoveFile, handleRemoveAllFiles } =
    useAssistantFileUpload(mode)
  const trackEvent = useAssistantAnalytics()

  const documents = useAssistantStore((s) => s.documents)
  const documentsUploading = useAssistantStore((s) => s.documentsUploading)

  const [zipFiles, setZipFiles] = useState<File[]>([])
  const hasUploadedFiles =
    documents.length > 0 || documentsUploading.length > 0 || zipFiles.length > 0
  const filesAttachedCount = documents.length + documentsUploading.length

  const handleFileUploadWithTracking = async (files: File[]) => {
    setZipFiles([])
    await handleFileUpload(files)
  }

  const handleRemoveFileWithTracking = (
    file: UploadedFile | FileUploadingState
  ) => {
    handleRemoveFile(file)
  }

  const prevUploadedFiles = useRef(!!hasUploadedFiles)
  useEffect(() => {
    if (prevUploadedFiles.current && !hasUploadedFiles) {
      onCancel()
    } else if (!prevUploadedFiles.current && hasUploadedFiles) {
      onUpload()
    }
    prevUploadedFiles.current = hasUploadedFiles
  }, [hasUploadedFiles, onCancel, onUpload])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setAskHarveyDisabled(true)
      setZipFiles(acceptedFiles.filter((file) => file.type === FileType.ZIP))

      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: documents.length + documentsUploading.length,
        maxFiles: NUM_MAX_FILES,
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(MAX_FILE_SIZE),
        maxZipFileSize: mbToBytes(MAX_ZIP_FILE_SIZE),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE),
          currentTotalFileSize: documents.reduce(
            (total, file) => total + (file.size ?? 0),
            0
          ),
        },
        handleAcceptedFiles: handleFileUploadWithTracking,
      }).finally(() => {
        setAskHarveyDisabled(false)
        setZipFiles([])
      })
    },
    maxFiles: NUM_MAX_FILES,
    noClick: hasUploadedFiles,
  })

  const trackFileInputOpened = () => trackEvent('Upload Files Clicked')

  const handleOpen = () => {
    open()
    trackFileInputOpened()
  }

  const getRootPropsWithTracking = () => {
    const rootProps = getRootProps()
    return {
      ...rootProps,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        rootProps.onClick?.(e)
        trackFileInputOpened()
      },
    }
  }

  return (
    <div
      {...(hasUploadedFiles ? getRootProps() : {})}
      className="relative flex h-full flex-col"
    >
      {hasFilesHeader && (
        <p className="pb-2 text-xs font-medium text-secondary">Files</p>
      )}
      {hasUploadedFiles && (
        <input className="hidden" {...getRootPropsWithTracking()} />
      )}
      {hasUploadedFiles && isDragActive && (
        <div className="absolute z-10 h-full w-full bg-primary p-4">
          <div className="flex h-full w-full flex-col items-center justify-center rounded border border-dashed">
            <Icon icon={UploadCloudIcon} className="mb-2" />
            <p className="font-semibold">Drop files here</p>
            <p className="text-sm">or click to upload</p>
          </div>
        </div>
      )}
      {hasUploadedFiles ? (
        <ScrollArea className="h-full">
          <p className="px-6 pt-4 font-medium">Files</p>
          <AssistantFiles
            files={documents}
            isDragActive={isDragActive}
            uploadingFiles={documentsUploading}
            handleRemoveFile={handleRemoveFileWithTracking}
            zipFiles={zipFiles}
          />
        </ScrollArea>
      ) : (
        <Dropzone
          className={cn('h-full max-h-56', {
            'border-ring bg-secondary-hover': isDragActive,
          })}
          isLoading={false}
          applySpacing={false}
          dropzone={{ getRootProps: getRootPropsWithTracking, getInputProps }}
          description={
            <span className="mt-2 block">
              PDF, Word, Excel, and Zip documents less than 20MB are supported
              <span className="mt-2 flex items-center justify-center">
                Up to 50 files
                <Tooltip>
                  <TooltipTrigger>
                    <Icon icon={InfoIcon} size="small" className="ml-1" />
                  </TooltipTrigger>
                  <TooltipContent side="bottom">
                    Total size of all files cannot exceed 100MB
                  </TooltipContent>
                </Tooltip>
              </span>
              {!!dropzoneSources?.length && (
                <span className="mt-2 flex flex-col items-center">
                  or
                  <span className="mt-2 flex flex-wrap justify-center gap-1">
                    {dropzoneSources}
                  </span>
                </span>
              )}
            </span>
          }
        />
      )}
      {hasUploadedFiles && (
        <div className="flex items-center justify-between border-t px-4 py-3">
          <p className="text-xs">{filesAttachedCount} files attached</p>
          <div className="flex items-center space-x-2">
            <Button size="sm" variant="outline" onClick={handleOpen}>
              Attach more files
            </Button>
            <Button size="sm" variant="outline" onClick={handleRemoveAllFiles}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AssistantFilesInput
