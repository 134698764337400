import React from 'react'

import { Button } from 'components/ui/button'
import {
  MAX_QUESTION_CHAR_LENGTH,
  MIN_QUESTION_CHAR_LENGTH,
  QueryQuestions,
} from 'components/vault/utils/vault'
import { computeQueryDisabledReason } from 'components/vault/utils/vault-helpers'
import { QUERY_TYPES, useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

const VaultCustomWorkflow = () => {
  const currentProjectMetadata = useVaultStore(
    (state) => state.currentProjectMetadata
  )
  const reviewFilesPerQueryLimit = useVaultUsageStore(
    (state) => state.reviewFilesPerQueryLimit
  )
  const reviewQuestionsPerQueryLimit = useVaultUsageStore(
    (state) => state.reviewQuestionsPerQueryLimit
  )

  const setIsTextAreaFocused = useVaultStore(
    (state) => state.setIsTextAreaFocused
  )
  const setQueryType = useVaultStore((state) => state.setQueryType)
  const setPendingQuery = useVaultStore((state) => state.setPendingQuery)
  const setIsQuestionsOpen = useVaultStore((state) => state.setIsQuestionsOpen)
  const setIsWorkflowRepsWarranties = useVaultStore(
    (state) => state.setIsWorkflowRepsWarranties
  )
  const setQuestions = useVaultStore((state) => state.setQuestions)
  const setSelectedQuestions = useVaultStore(
    (state) => state.setSelectedQuestions
  )
  const setQuestionsLimit = useVaultStore((state) => state.setQuestionsLimit)
  const setFilesLimit = useVaultStore((state) => state.setFilesLimit)
  const setMaxQuestionCharacterLength = useVaultStore(
    (state) => state.setMaxQuestionCharacterLength
  )
  const setMinQuestionCharacterLength = useVaultStore(
    (state) => state.setMinQuestionCharacterLength
  )

  const queryDisabledReason = computeQueryDisabledReason(
    currentProjectMetadata,
    null
  )

  const onRunRepsWarrantiesWorkflow = () => {
    setIsTextAreaFocused(true)
    setQueryType(QUERY_TYPES.NN)
    setPendingQuery('Reps & Warranties workflow')
    setIsQuestionsOpen(true)
    setIsWorkflowRepsWarranties(true)
    const questions: QueryQuestions[] = [
      {
        id: '1',
        text: 'Parties',
      },
      {
        id: '2',
        text: 'Materiality Scrape',
      },
      {
        id: '3',
        text: 'Survival: General',
      },
      {
        id: '4',
        text: 'Survival: Fundamental Reps',
      },
      {
        id: '5',
        text: 'Survival: Tax Reps',
      },
      {
        id: '6',
        text: 'Survival: Environmental Reps',
      },
      {
        id: '7',
        text: 'Survival: Fraud Carve-Out',
      },
      {
        id: '8',
        text: 'Sandbagging',
      },
      {
        id: '9',
        text: 'Sandbagging: Governing Law',
      },
      {
        id: '10',
        text: 'Losses',
      },
      {
        id: '11',
        text: 'Financial Statements',
      },
      {
        id: '12',
        text: 'Accounts Receivable',
      },
      {
        id: '13',
        text: 'Litigation',
      },
    ]
    setQuestions(questions)
    setSelectedQuestions(
      questions.slice(0, reviewQuestionsPerQueryLimit),
      questions
    )
    // We should set the questions limit to the review questions limit to make sure the user doesn't
    // go over the limit when they copy a query to the query box.
    setQuestionsLimit(reviewQuestionsPerQueryLimit)
    // We should set the files limit to the review files limit to make sure the user doesn't
    // go over the limit when they copy a query to the query box.
    setFilesLimit(reviewFilesPerQueryLimit)
    setMaxQuestionCharacterLength(MAX_QUESTION_CHAR_LENGTH)
    setMinQuestionCharacterLength(MIN_QUESTION_CHAR_LENGTH)
  }

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="flex w-full items-center">
        <hr className="ml-16 flex-grow border-t border-muted" />
        <p className="mx-4 text-muted">OR</p>
        <hr className="mr-16 flex-grow border-t border-muted" />
      </div>
      <Button
        disabled={!!queryDisabledReason}
        tooltip={queryDisabledReason}
        onClick={() => {
          onRunRepsWarrantiesWorkflow()
        }}
      >
        Run Reps & Warranties workflow
      </Button>
    </div>
  )
}

export default VaultCustomWorkflow
