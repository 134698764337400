import React from 'react'

import { MoreHorizontal } from 'lucide-react'

import { useAssistantAnalytics } from 'components/assistant-v2/hooks/use-assistant-analytics'
import { AssistantDraftMessage } from 'components/assistant-v2/types'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'

type Props = {
  message: AssistantDraftMessage
  handleRegenerateMessage: (message: AssistantDraftMessage) => void
  handleDeleteMessage: (message: AssistantDraftMessage) => void
}

export const AssistantChangeLogMenu: React.FC<Props> = ({
  message,
  handleRegenerateMessage,
  handleDeleteMessage,
}) => {
  const trackEvent = useAssistantAnalytics()

  return (
    <DropdownMenu dir="ltr">
      <DropdownMenuTrigger asChild>
        <Button
          onClick={() => trackEvent('Revision Menu Clicked')}
          variant="ghost"
          size="xsIcon"
          className="h-5 w-5 hover:bg-neutral-300"
        >
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          onSelect={(e) => {
            e.stopPropagation()
            handleRegenerateMessage(message)
            trackEvent('Regenerate Message Clicked')
          }}
        >
          Regenerate
        </DropdownMenuItem>
        {!!message.prevMessageId && (
          <DropdownMenuItem
            onSelect={(e) => {
              e.stopPropagation()
              handleDeleteMessage(message)
              trackEvent('Delete Message Clicked')
            }}
            className="text-destructive"
          >
            Delete
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
