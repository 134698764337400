import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { CompanyProfileTaxonomiesTableSection } from 'openapi/models/CompanyProfileTaxonomiesTableSection'
import { ExternalWebSource } from 'openapi/models/ExternalWebSource'
import { AnnotationById } from 'types/task'

import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'
import { HarveySocketCompletionStatus } from 'utils/use-harvey-socket-utils'

import { FeedbackResult } from 'components/common/feedback'

import { fetchCompanyProfileTaxonomies } from './api'

interface State {
  query: string
  response: string
  sources: Array<ExternalWebSource>
  annotations: AnnotationById
  isLoading: boolean
  headerText: string
  queryId: string
  feedback: FeedbackResult | null
  progress: number
  completionStatus: HarveySocketCompletionStatus | null
  availableTaxonomies: CompanyProfileTaxonomiesTableSection[]
  selectedTaxonomies: CompanyProfileTaxonomiesTableSection[]
}

interface Action {
  setTask: HarveySocketSetter
  setFeedback: (feedback: FeedbackResult | null) => void
  setSelectedTaxonomies: (
    taxonomies: CompanyProfileTaxonomiesTableSection[]
  ) => void
  loadTaxonomies: () => void
  reset: () => void
}

const initialState: State = {
  query: '',
  response: '',
  sources: [],
  isLoading: false,
  headerText: '',
  queryId: '',
  feedback: null,
  annotations: {},
  progress: 0,
  completionStatus: null,
  availableTaxonomies: [],
  selectedTaxonomies: [],
}

export const useCompanyProfileStore = create(
  devtools(
    immer<State & Action>((set, get) => ({
      ...initialState,
      reset: () => {
        set(() => ({
          ...initialState,
          // Preserve taxonomies
          // selectedTaxonomies: get().selectedTaxonomies,
          // NOTE(Adam): Changed the reset function to _not_ preserve taxonomies below.
          selectedTaxonomies: get().availableTaxonomies,
          availableTaxonomies: get().availableTaxonomies,
        }))
      },
      setFeedback: (feedback: FeedbackResult | null) => set({ feedback }),
      setTask: (socketState: Partial<HarveySocketTask>) =>
        set({ ...socketState }),
      setSelectedTaxonomies: (
        selectedTaxonomies: CompanyProfileTaxonomiesTableSection[]
      ) => set({ selectedTaxonomies }),
      loadTaxonomies: () => {
        fetchCompanyProfileTaxonomies().then((taxonomies) => {
          set({
            availableTaxonomies: structuredClone(taxonomies),
            // Auto-select all
            selectedTaxonomies: structuredClone(taxonomies),
          })
        })
      },
    }))
  )
)
