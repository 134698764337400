import React, { useRef } from 'react'

import _ from 'lodash'
import { Instance } from 'pspdfkit'
import { useShallow } from 'zustand/react/shallow'

import { uploadFile } from 'api'
import { EventKind } from 'openapi/models/EventKind'
import { UploadedFileToJSON } from 'openapi/models/UploadedFile'
import { WorkflowType } from 'types/workflows'

import { createAcceptedFileDescription } from 'utils/dropzone'
import { useNavigateWithQueryParams } from 'utils/routing'
import { displayErrorMessage } from 'utils/toast'
import {
  IS_LOADING_HELP_TEXT,
  UPLOAD_DOCUMENT_HELP_TEXT,
} from 'utils/tooltip-texts'
import useHarveySocket from 'utils/use-harvey-socket'
import { isUserInputEmpty } from 'utils/utils'

import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeaderActions } from 'components/common/app-header-actions'
import AskHarveyButton from 'components/common/ask-harvey-button'
import DeprecatedTagInput from 'components/common/deprecated-tag-input/deprecated-tag-input'
import Response from 'components/common/response/response'
import SourcePopover from 'components/common/source-popover'
import { Textarea } from 'components/ui/text-area'
import { DILIGENCE_TRANSCRIPTS_HELP } from 'components/workflows/constants'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import WorkflowFileInput from 'components/workflows/workflow/components/workflow-file-input'
import { WorkflowsDualPaneLayout } from 'components/workflows/workflow/layouts/dual-pane'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import * as config from './config'
import useDiligenceTranscriptsStore from './diligence-transcripts-store'

const SERVER_ROUTE = 'diligence/transcripts'

const DiligenceTranscriptsWorkflow: React.FC = () => {
  const [
    activeDocument,
    isLoading,
    reset,
    response,
    sources,
    themes,
    userProvidedContext,
    setActiveDocument,
    setTask,
    setThemes,
    setUserProvidedContext,
  ] = useDiligenceTranscriptsStore(
    useShallow((s) => [
      s.activeDocument,
      s.isLoading,
      s.reset,
      s.response,
      s.sources,
      s.themes,
      s.userProvidedContext,
      s.setActiveDocument,
      s.setTask,
      s.setThemes,
      s.setUserProvidedContext,
    ])
  )

  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: SERVER_ROUTE,
    setter: setTask,
  })

  const navigate = useNavigateWithQueryParams()

  const { recordQueryCancel, recordQuerySubmitted, recordReset } =
    useQueryAnalytics(EventKind.DILIGENCE_TRANSCRIPTS)

  const pspdfInstanceRef = useRef<Instance | null>(null)

  const askHarveyButtonTooltip = () => {
    if (_.isNil(activeDocument)) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else {
      return ''
    }
  }

  // The Response component's display of a loading skeleton depends on its headerText prop being set. The diligence transcripts endpoint currently does not set
  // headerText, so we set it manually here based on query loading state.
  const headerText = isLoading ? 'Loading' : ''

  const isAskHarveyButtonDisabled = isLoading || !activeDocument

  const userInputs: unknown[] = [activeDocument, userProvidedContext, themes]
  const emptyUserInput = isUserInputEmpty(userInputs)
  const resetDisabled = emptyUserInput || isLoading

  const workflowType = WorkflowType.DILIGENCE_TRANSCRIPTS
  const workflow = WorkflowTypeToDetails[workflowType]

  const getHrvyInfoMetadata = (identifier: string) => {
    if (!activeDocument) {
      return
    }

    const source = sources.find((s) => s.id === identifier)
    if (!source) {
      console.warn('Could not find source for citation')
      displayErrorMessage('Could not find source for citation')
      return
    }

    const onClick = () => {}
    const hoverContent = <SourcePopover source={source} onClick={onClick} />

    return {
      hoverContent,
      onClick,
    }
  }

  const handleCancel = () => {
    recordQueryCancel()
    sendCancelRequest()
  }

  const handleFile = async (file: File) => {
    const response = await uploadFile(file)
    setActiveDocument(response)
  }

  const handleReset = () => {
    navigate(workflow.path, { replace: true })
    reset()
    recordReset()
  }

  const handleSubmit = async () => {
    if (!activeDocument) {
      return
    }

    recordQuerySubmitted({
      event_kind: EventKind.DILIGENCE_TRANSCRIPTS,
    })

    initSocketAndSendQuery({
      query: '',
      additionalAuthParams: {
        task_type: EventKind.DILIGENCE_TRANSCRIPTS,
      },
      additionalRequestParams: {
        context: userProvidedContext,
        documents: [UploadedFileToJSON(activeDocument)],
        themes: themes,
      },
    })
  }

  const TagInputEmptyState = () => {
    return (
      <div className="flex grow flex-col items-center justify-center">
        <p>Add a theme to get started</p>
        <p className="text-xs text-muted">
          e.g., &quot;Usage&quot;, &quot;Opinions on solutions&quot;,
          &quot;Competitors&quot;
        </p>
      </div>
    )
  }

  return (
    <WorkflowLayout
      appHeaderActions={
        <AppHeaderActions
          handleReset={handleReset}
          resetDisabled={resetDisabled}
        />
      }
      title="Diligence Transcripts"
      workflowType={WorkflowType.DILIGENCE_TRANSCRIPTS}
    >
      <WorkflowsDualPaneLayout
        input={
          <WorkflowFileInput
            eventKind={EventKind.DILIGENCE_TRANSCRIPTS}
            handleFile={handleFile}
            store={useDiligenceTranscriptsStore}
            fileTypes={config.FILE_TYPES}
            pspdfInstanceRef={pspdfInstanceRef}
            dropzoneDescription={createAcceptedFileDescription(
              config.FILE_TYPES,
              config.MAX_FILE_SIZE_MB
            )}
          >
            <div className="flex flex-col space-y-2">
              <Textarea
                className="min-h-40 resize-none"
                onChange={(e) => setUserProvidedContext(e.target.value)}
                placeholder="Enter context here"
                value={userProvidedContext}
              />
              <DeprecatedTagInput
                addButtonText="Add theme"
                className="min-h-40"
                closeableTags
                emptyState={<TagInputEmptyState />}
                inputPlaceholder="Add a theme"
                setTags={setThemes}
                tags={themes}
              />
              <AskHarveyButton
                className="w-full"
                disabled={isAskHarveyButtonDisabled}
                handleSubmit={handleSubmit}
                isLoading={false}
                size="lg"
                tooltip={askHarveyButtonTooltip()}
              />
            </div>
          </WorkflowFileInput>
        }
        output={
          <Response
            emptyStateText={DILIGENCE_TRANSCRIPTS_HELP}
            getHrvyInfoMetadata={getHrvyInfoMetadata}
            handleCancel={handleCancel}
            headerText={headerText}
            isLoading={isLoading}
            markdown={response}
          />
        }
      />
    </WorkflowLayout>
  )
}

export default DiligenceTranscriptsWorkflow
