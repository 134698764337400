import React, { useEffect, useState } from 'react'

import { Experiment } from 'openapi/models/Experiment'

import { getTableDateString } from 'utils/date-utils'
import { displayErrorMessage } from 'utils/toast'

import { SettingsPath } from 'components/base-app-path'
import BasicTransition from 'components/ui/basic-transition'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

import { FetchAllExperiments } from './utils/experiment-fetcher'

const ExperimentsOverviewCard = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [experiments, setExperiments] = useState<Experiment[]>([])

  const fetchAllExperiments = async () => {
    setIsLoading(true)
    try {
      const experiments = await FetchAllExperiments()
      setExperiments(experiments)
    } catch (error) {
      displayErrorMessage(`Failed to fetch experiments: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    void fetchAllExperiments() // NOTE can move this into hook in future
  }, [])

  return (
    <Card className="mt-6">
      <CardHeader>
        <CardTitle>Experiments overview table</CardTitle>
      </CardHeader>
      <CardContent>
        <BasicTransition show={!isLoading}>
          <ScrollArea hasHorizontalScroll className="h-[500px] w-full">
            {/* NOTE: can be replaced with data table */}
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Slug</TableHead>
                  <TableHead>Created At</TableHead>
                  {/* NOTE can add other columns like # of users, created_by, etc */}
                </TableRow>
              </TableHeader>
              <TableBody>
                {experiments.map((experiment) => (
                  <TableRow
                    key={experiment.id}
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(
                        `${SettingsPath.InternalAdminExperimentManagement}/${experiment.slug}`,
                        '_blank'
                      )
                    }}
                  >
                    <TableCell>{experiment.name}</TableCell>
                    <TableCell>{experiment.slug}</TableCell>
                    <TableCell>
                      {getTableDateString(experiment.createdAt)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        </BasicTransition>
      </CardContent>
    </Card>
  )
}

export default ExperimentsOverviewCard
