import React, { useEffect, useRef, useCallback } from 'react'

import { Copy, SquarePen, Trash } from 'lucide-react'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { PopoverContent } from 'components/ui/popover'

type Props = Pick<
  React.ComponentPropsWithoutRef<typeof PopoverContent>,
  'collisionPadding' | 'hideWhenDetached'
> & {
  hasDeletions: boolean
  inlineEditDisabledReason?: string
  onAddRevision: () => void
  onDelete: () => void
  onHide: () => void
  selectedText: string
}

export const AssistantHighlightPopover: React.FC<Props> = ({
  hasDeletions,
  inlineEditDisabledReason,
  onAddRevision,
  onDelete,
  onHide,
  selectedText,
  ...popoverContentProps
}) => {
  const popoverRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        onHide()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onHide])

  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(selectedText)
      .then(() => displaySuccessMessage('Copied text to clipboard'))
      .catch(() => displayErrorMessage('Sorry, something went wrong'))
  }, [selectedText])

  const overrideCopy = useCallback(
    (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyC') {
        e.preventDefault()
        handleCopy()
      }
    },
    [handleCopy]
  )

  useEffect(() => {
    document.addEventListener('keydown', overrideCopy, true)
    return () => document.removeEventListener('keydown', overrideCopy, true)
  }, [overrideCopy])

  const handleOpenAutoFocus = (e: Event) => {
    e.preventDefault()
    popoverRef.current?.focus()
  }

  const handleEsc = () => {
    onHide()
  }

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <PopoverContent
      className="flex h-8 w-auto items-center rounded-lg border-0 bg-interactive p-0 text-primary-inverse"
      onClick={stopPropagation}
      onEscapeKeyDown={handleEsc}
      onOpenAutoFocus={handleOpenAutoFocus}
      ref={popoverRef}
      {...popoverContentProps}
    >
      {hasDeletions ? (
        <p className="whitespace-nowrap px-2.5 text-xs text-primary-inverse/80">
          Can’t edit text that contains deletions
        </p>
      ) : (
        <Button
          className="h-full whitespace-nowrap rounded-none rounded-l-lg px-2.5"
          disabled={!!inlineEditDisabledReason}
          onClick={onAddRevision}
          size="sm"
          tooltip={inlineEditDisabledReason}
        >
          <Icon className="mr-2" icon={SquarePen} /> Add revision
        </Button>
      )}
      <div className="h-full w-px border-l border-input-focused" />
      <Button
        className="rounded-none last:rounded-r-lg"
        onClick={handleCopy}
        size="smIcon"
      >
        <Icon icon={Copy} />
      </Button>
      {!hasDeletions && (
        <Button
          className="rounded-none rounded-r-lg"
          onClick={onDelete}
          size="smIcon"
        >
          <Icon icon={Trash} />
        </Button>
      )}
    </PopoverContent>
  )
}
