import React from 'react'
import { CSVLink } from 'react-csv'
import { Headers } from 'react-csv/lib/core'

import { cn } from 'utils/utils'

// import { Maybe } from 'types'

interface CsvDownloadProps {
  data: any
  buttonText: string
  filename: string
  headers?: Headers
  onClickCallback?: () => void
  className?: string
}
const CsvDownload: React.FC<CsvDownloadProps> = ({
  data,
  buttonText,
  filename,
  headers,
  onClickCallback,
  className,
}) => {
  return (
    <CSVLink
      data={data}
      target="_blank"
      headers={headers || undefined}
      className={cn(
        'h-8 rounded-md border bg-primary px-2.5 pt-1 text-sm hover:bg-button-secondary hover:text-primary',
        className
      )}
      filename={filename}
      onClick={onClickCallback}
    >
      {buttonText}
    </CSVLink>
  )
}

export default CsvDownload
