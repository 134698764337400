import { useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import useHarveySocket from 'utils/use-harvey-socket'

import * as config from 'components/workflows/workflow/discovery/config'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'

export const useRunFollowUpQuestion = () => {
  // Actions for running the report
  const [
    setter,
    runFollowUpQuestion,
    currentQuery,
    sections,
    runFollowUpCompletedCallback,
    selectedSectionForFollowUp,
  ] = useDiligenceStore(
    useShallow((s) => [
      s.setter,
      s.runFollowUpQuestion,
      s.currentFollowUpInput,
      s.sections,
      s.runFollowUpCompletedCallback,
      s.selectedSectionForFollowUp,
    ])
  )

  const { initSocketAndSendQuery } = useHarveySocket({
    path: config.ROUTE,
    setter,
    endCallback: runFollowUpCompletedCallback,
    closeOnUnmount: false, // answer will continue streaming if the user navigates away and comes back
  })

  const selectedSectionText = useMemo(() => {
    const sectionText = sections.find(
      (section) => section.title === selectedSectionForFollowUp
    )
    const result = sectionText?.tasks
      .map((task) => task.title + '\n' + task?.answer?.response)
      .join('\n')
    return result
  }, [sections, selectedSectionForFollowUp])

  const runFollowUp = () => {
    runFollowUpQuestion(
      currentQuery,
      selectedSectionText!,
      initSocketAndSendQuery
    )
  }

  return runFollowUp
}
