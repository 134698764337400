import React from 'react'

import { Source } from 'openapi/models/Source'

import { cn } from 'utils/utils'

import Markdown from 'components/common/markdown/markdown'

const SourcePopover = ({
  source,
  onClick,
  hideText = false,
}: {
  source: Source
  onClick: (e: React.MouseEvent) => void
  hideText?: boolean
}) => {
  return (
    <div className="max-w-lg">
      <button
        className={cn(
          '-m-4 mb-3 w-[calc(100%+2rem)] border-b p-4 pb-3 text-left hover:bg-button-secondary',
          { '-mb-3': hideText }
        )}
        onClick={onClick}
      >
        <p className="not-prose font-semibold">{source.documentName}</p>
      </button>
      {!hideText && (
        <Markdown
          content={source.text}
          className="prose-xs hyphens-auto break-words"
        />
      )}
    </div>
  )
}

export default SourcePopover
