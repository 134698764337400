import * as Sentry from '@sentry/browser'
import { useQuery } from '@tanstack/react-query'
import { isNil } from 'lodash'

import {
  fetchCourtCitation,
  fetchUsaCaseLaw,
} from 'models/fetchers/usa-case-law-fetcher'
import Services from 'services'

import { HarvQueryKeyPrefix } from './all-query-keys'
import { DEFAULT_STALE_TIME } from './defaults'

export const getUsaCaseLawQuery = (id: string) => ({
  queryKey: [HarvQueryKeyPrefix.UsaCaseLaw, id],
  queryFn: async () => fetchUsaCaseLaw(id),
  initialData: [],
})

export const useCaseLawQuery = (
  id: string | undefined,
  sources?: { first: string; last: string; length: number; footnote: number }[]
) => {
  // creating the async function with a stable reference
  const {
    isPending,
    error,
    data: artifact,
  } = useQuery({
    queryKey: [HarvQueryKeyPrefix.UsaCaseLaw, id, JSON.stringify(sources)],
    staleTime: DEFAULT_STALE_TIME,
    queryFn: () => fetchUsaCaseLaw(id!, sources),
    enabled: !isNil(id),
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { artifact, isPending, error }
}

export const useCaseLawCourtCitationQuery = (courtName: string | undefined) => {
  const { isPending, error, data } = useQuery({
    queryKey: [HarvQueryKeyPrefix.UsaCaseLaw, courtName],
    queryFn: () => fetchCourtCitation(courtName ?? ''),
    enabled: !isNil(courtName),
    refetchOnWindowFocus: false,
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { data, isPending, error }
}
