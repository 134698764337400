import React, { MutableRefObject, useEffect, useRef, useState } from 'react'

import { ArrowUp } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { DiligenceSection } from 'openapi/models/DiligenceSection'
import { DiligenceTask } from 'openapi/models/DiligenceTask'
import { Source } from 'openapi/models/Source'

import { HrvyInfoElement } from 'utils/source'
import { caseInsensitiveMatch } from 'utils/string'
import { cn, findScrollingContainer } from 'utils/utils'

import Markdown from 'components/common/markdown/markdown'
import SourcePopover from 'components/common/source-popover'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import Skeleton from 'components/ui/skeleton'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import { findDocumentBySource } from 'components/workflows/workflow/discovery/util'

type Props = {
  selectedSection: DiligenceSection
  id: string
  taskRefs: MutableRefObject<Record<string, HTMLDivElement | null>>
}
const AAG_SUMMARY_TITLE = 'At-a-Glance Summary'

export const OneSection = React.forwardRef<HTMLDivElement, Props>(
  ({ selectedSection, id, taskRefs }, ref) => {
    const [documents, setViewingDocument, availableSections] =
      useDiligenceStore(
        useShallow((state) => [
          state.documents,
          state.setViewingDocument,
          state.availableSections,
        ])
      )

    const isAtAGlanceSummary = selectedSection.title === AAG_SUMMARY_TITLE

    const hasSingleTask =
      availableSections.find(
        (section) => section.title === selectedSection.title
      )?.tasks.length === 1

    const citationRefs = useRef<{
      [elementId: string]: HrvyInfoElement
    }>({})

    const [clickedCitationId, setClickedCitationId] = useState<string | null>(
      null
    )

    const [showBackToSummaryButton, setShowBackToSummaryButton] =
      useState(false)

    const getHrvyInfoMetadata = (task: DiligenceTask) => {
      return (
        identifier: string,
        position?: { line: number; column: number; offset: number }
      ) => {
        let source: Source | undefined
        let localClickedCitationId: string | null = null
        let citationRef: ((el: HrvyInfoElement) => void) | undefined
        let onClick: () => void

        if (isAtAGlanceSummary) {
          source = task.answer?.sources.find((s) => s.id === identifier)
          if (!source || !position) {
            return
          }

          localClickedCitationId = `${identifier}-${position.line}-${position.column}-${position.offset}`

          citationRef = (el: HrvyInfoElement) => {
            citationRefs.current[localClickedCitationId!] = el
          }

          onClick = () => {
            const element = taskRefs.current[source!.documentId!]
            if (!element) {
              return
            }

            const scrollingContainer = findScrollingContainer(element)
            if (scrollingContainer) {
              const yOffset = -16 // Add a little padding (1rem) above the markdown element
              scrollingContainer.scrollTo({
                top: element.offsetTop + yOffset,
                behavior: 'smooth',
              })
            }

            setShowBackToSummaryButton(true)
            setClickedCitationId(localClickedCitationId)
          }
        } else {
          const document = findDocumentBySource(documents, identifier, task)
          if (!document) {
            return
          }

          source = document.source
          onClick = () => setViewingDocument(document)
        }

        const hoverContent = (
          <SourcePopover
            source={source}
            onClick={onClick}
            hideText={isAtAGlanceSummary}
          />
        )

        return {
          onClick,
          hoverContent,
          ...(isAtAGlanceSummary && {
            isSelected: clickedCitationId === localClickedCitationId,
            ref: citationRef,
          }),
        }
      }
    }

    // Set up an intersection observer to monitor if the user scrolls back to the citation tag element.
    // If so, hide the back to summary button, since it's no longer needed.
    useEffect(() => {
      const currentRef = citationRefs.current[clickedCitationId!]

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setShowBackToSummaryButton(false)
            observer.disconnect()
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        }
      )

      // Wait for the citation tag element to be scrolled out of view before setting up the intersection observer.
      // Smooth scrolling can be quite slow.
      setTimeout(() => {
        if (currentRef) {
          observer.observe(currentRef)
        }
      }, 1000)

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef)
        }
      }
    }, [clickedCitationId, showBackToSummaryButton])

    return (
      <div
        className="border-t py-12 first-of-type:border-0 first-of-type:pt-8 last-of-type:pb-16"
        id={selectedSection.title}
        ref={ref}
      >
        <div className="mx-auto flex w-full max-w-[936px] px-8">
          <div className="prose prose-sm prose-harvey max-w-full flex-1">
            {(!isAtAGlanceSummary || selectedSection.isLoading) && (
              <h1
                id={id}
                className="grow"
                ref={(el) => {
                  if (hasSingleTask) {
                    taskRefs.current[selectedSection.tasks[0].title] = el
                  }
                }}
              >
                {selectedSection.title}
              </h1>
            )}
            <div className="mt-2 space-y-4">
              {selectedSection.tasks.map((task) => (
                <div
                  key={task.identifier}
                  className="space-y-2"
                  ref={(el) => {
                    if (!hasSingleTask) {
                      taskRefs.current[task.title] = el
                    }
                  }}
                >
                  {!caseInsensitiveMatch(task.title, selectedSection.title) &&
                    !isAtAGlanceSummary && (
                      <h4 className="text-base font-semibold">{task.title}</h4>
                    )}
                  {task.answer?.response ? (
                    <Markdown
                      content={task.answer.response}
                      expectExcelDataUri={isAtAGlanceSummary}
                      getHrvyInfoMetadata={(
                        identifier: string,
                        position?: {
                          line: number
                          column: number
                          offset: number
                        }
                      ) => getHrvyInfoMetadata(task)(identifier, position)}
                    />
                  ) : (
                    <Skeleton className="py-4" rows={3} rowHeight="h-4" />
                  )}
                  <div className="flex justify-center">
                    <Button
                      className={cn(
                        'fixed bottom-8 z-50 translate-y-20 rounded-full pl-3 pr-4 text-xs transition-transform ease-in',
                        {
                          'translate-y-0': showBackToSummaryButton,
                        }
                      )}
                      onClick={() => {
                        if (!clickedCitationId) {
                          return
                        }

                        const ref = citationRefs.current[clickedCitationId]
                        if (ref) {
                          ref.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                          setShowBackToSummaryButton(false)
                        }
                      }}
                    >
                      <Icon icon={ArrowUp} className="mr-2" /> Back to
                      At-a-Glance Summary
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

OneSection.displayName = 'OneSection'
