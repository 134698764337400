import React from 'react'

import { Source } from 'types/task'

import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'
import Link from 'components/ui/link/link'

interface Props {
  source: Source
  onClick?: () => void
}

const ReferenceButton: React.FC<Props & { children: React.ReactNode }> = ({
  source,
  onClick,
  children,
}) => {
  if (onClick) {
    return (
      <Button variant="link" size="sm" onClick={onClick}>
        {children}
      </Button>
    )
  }

  return (
    <Link to={source.documentUrl} target="_blank">
      <Button variant="link" size="sm">
        View reference &rarr;
      </Button>
    </Link>
  )
}

const CitationPopover: React.FC<Props> = ({ source, onClick }) => {
  return (
    <div className="max-w-lg">
      <p className="not-prose text-xs font-semibold text-primary">
        {source.documentName}
      </p>
      <div className="mb-1 flex space-x-2 divide-x">
        {source.metadata?.map((metadata) => (
          <div className="w-fit pl-2 text-xs first:pl-0" key={metadata}>
            {metadata}
          </div>
        ))}
      </div>
      <div className="">
        <Markdown
          content={source.text}
          className="hyphens-auto break-words *:text-xs"
        />
      </div>
      <div className="mt-2 flex justify-end">
        <ReferenceButton onClick={onClick} source={source}>
          View reference &rarr;
        </ReferenceButton>
      </div>
    </div>
  )
}

export default CitationPopover
