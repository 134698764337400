import { FileType } from 'types/file'

export const ROUTE = 'diligence'
export const MAX_FILES = 50
export const FILE_TYPES = [
  FileType.ZIP,
  FileType.PDF,
  FileType.WORD,
  FileType.WORD_LEGACY,
]
export const MAX_FILE_SIZE_MB = 100
export const MAX_TOTAL_FILE_SIZE_MB = 100
