/* tslint:disable */
/* eslint-disable */
/**
 * PwC Deals API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiligenceDocument } from './DiligenceDocument';
import {
    DiligenceDocumentFromJSON,
    DiligenceDocumentFromJSONTyped,
    DiligenceDocumentToJSON,
} from './DiligenceDocument';
import type { DiligenceSection } from './DiligenceSection';
import {
    DiligenceSectionFromJSON,
    DiligenceSectionFromJSONTyped,
    DiligenceSectionToJSON,
} from './DiligenceSection';

/**
 * 
 * @export
 * @interface DiligenceProcessSectionsRequest
 */
export interface DiligenceProcessSectionsRequest {
    /**
     * 
     * @type {Array<DiligenceDocument>}
     * @memberof DiligenceProcessSectionsRequest
     */
    documents: Array<DiligenceDocument>;
    /**
     * 
     * @type {Array<DiligenceSection>}
     * @memberof DiligenceProcessSectionsRequest
     */
    sections: Array<DiligenceSection>;
}

/**
 * Check if a given object implements the DiligenceProcessSectionsRequest interface.
 */
export function instanceOfDiligenceProcessSectionsRequest(value: object): value is DiligenceProcessSectionsRequest {
    if (!('documents' in value) || value['documents'] === undefined) return false;
    if (!('sections' in value) || value['sections'] === undefined) return false;
    return true;
}

export function DiligenceProcessSectionsRequestFromJSON(json: any): DiligenceProcessSectionsRequest {
    return DiligenceProcessSectionsRequestFromJSONTyped(json, false);
}

export function DiligenceProcessSectionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiligenceProcessSectionsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(DiligenceDocumentFromJSON)),
        'sections': ((json['sections'] as Array<any>).map(DiligenceSectionFromJSON)),
    };
}

export function DiligenceProcessSectionsRequestToJSON(value?: DiligenceProcessSectionsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'documents': ((value['documents'] as Array<any>).map(DiligenceDocumentToJSON)),
        'sections': ((value['sections'] as Array<any>).map(DiligenceSectionToJSON)),
    };
}

