import React from 'react'

import _ from 'lodash'

import { EM_DASH } from 'utils/utils'

import { AppHeader } from 'components/common/app-header'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import { Badge } from 'components/ui/badge'
import { Card } from 'components/ui/card'

const WorkspaceInfo: React.FC = () => {
  const settingsUser = useSettingsState((s) => s.settingsUser)
  if (!settingsUser?.IsWorkspaceInfoViewer)
    return (
      <SettingsError
        description={`You don't have access to workspace info for ${settingsUser?.workspace.clientName}`}
      />
    )
  const userWorkspace = settingsUser?.workspace

  return (
    <>
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
      />
      <SettingsLayout>
        <Card className="space-y-4 p-4 shadow-none">
          <div className="*:text-sm">
            <p className="text-muted">Workspace name</p>
            <p>{userWorkspace?.clientName}</p>
          </div>
          <div className="*:text-sm">
            <p className="text-muted">Connection name</p>
            <p>
              {_.isNil(userWorkspace?.authConn)
                ? EM_DASH
                : userWorkspace?.authConn}
            </p>
          </div>
          <div>
            <p className="text-sm text-muted">Domains</p>
            <div className="mt-1 flex max-w-md flex-wrap gap-x-1 gap-y-2">
              {userWorkspace?.domains.map((domain, index) => (
                <Badge key={index} variant="secondary" className="rounded">
                  {domain}
                </Badge>
              ))}
            </div>
          </div>
        </Card>
      </SettingsLayout>
    </>
  )
}

export default WorkspaceInfo
