import * as Sentry from '@sentry/browser'

import type HoneyComb from 'services/honey-comb/honey-comb'

// This is a alpha version of the HoneyComb error catcher; feel free to improve it!
// TODO: We should remove this after deprecating Honeycomb and let Sentry do its thing
export const SetErrorCatcher = (honeyComb: HoneyComb): void => {
  window.addEventListener('unhandledrejection', (event) => {
    const err = honeyComb.RecordError(event)
    Sentry.captureException(err)
  })

  window.addEventListener('error', (event) => {
    const err = honeyComb.RecordError(event)
    Sentry.captureException(err)
  })

  const originalConsoleError = console.error

  console.error = (...args: any[]) => {
    originalConsoleError(...args)

    const err = args.find((arg) => arg instanceof Error)
    if (err !== undefined) {
      const errString = honeyComb.RecordError(err)
      Sentry.captureException(errString)
    } else if (args.length === 1 && typeof args[0] === 'string') {
      const errString = honeyComb.RecordError(new Error(args[0]))
      Sentry.captureException(errString)
    } else {
      const errString = honeyComb.RecordError(new Error(JSON.stringify(args)))
      Sentry.captureException(errString)
    }
  }
}
