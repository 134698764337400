import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { VaultFolderShareStatusApiResponseDataShareStatus } from 'openapi/models/VaultFolderShareStatusApiResponseDataShareStatus'
import { PermissionLevel } from 'types/sharing'

import { SafeRecord } from 'utils/safe-types'

export interface VaultSharingState {
  permissionsByProjectId: SafeRecord<
    string,
    VaultFolderShareStatusApiResponseDataShareStatus
  >
  currentUserPermissionByProjectId: SafeRecord<string, PermissionLevel>
  isFetchingFolderShareStatus: boolean
}

interface VaultSharingAction {
  setPermissionsForAllProjects: (
    permissionsByProjectId: Record<
      string,
      VaultFolderShareStatusApiResponseDataShareStatus
    >
  ) => void
  setPermissionsForProjectId: (
    projectId: string,
    permissions: VaultFolderShareStatusApiResponseDataShareStatus | null
  ) => void
  appendPermissionsForProjectId: (
    projectId: string,
    permissions: VaultFolderShareStatusApiResponseDataShareStatus
  ) => void
  removeWorkspacePermissionForProjectId: (
    projectId: string,
    workspaceId: number
  ) => void
  removeUserPermissionsForProjectId: (
    projectId: string,
    userIds: string[]
  ) => void
  setCurrentUserPermissionByProjectId: (
    projectId: string,
    permissionLevel: PermissionLevel | null
  ) => void
  setIsFetchingFolderShareStatus: (isFetching: boolean) => void
}

export const useVaultSharingStore = create(
  devtools(
    immer<VaultSharingState & VaultSharingAction>((set) => ({
      permissionsByProjectId: {},
      currentUserPermissionByProjectId: {},
      isFetchingFolderShareStatus: false,
      setPermissionsForAllProjects: (
        permissionsByProjectId: Record<
          string,
          VaultFolderShareStatusApiResponseDataShareStatus
        >
      ) => {
        set((state) => {
          state.permissionsByProjectId = permissionsByProjectId
        })
      },
      setPermissionsForProjectId: (
        projectId: string,
        permissions: VaultFolderShareStatusApiResponseDataShareStatus | null
      ) => {
        set((state) => {
          if (permissions) {
            state.permissionsByProjectId[projectId] = permissions
          } else {
            delete state.permissionsByProjectId[projectId]
          }
        })
      },
      appendPermissionsForProjectId: (
        projectId: string,
        permissions: VaultFolderShareStatusApiResponseDataShareStatus
      ) => {
        set((state) => {
          const existingPermissions = state.permissionsByProjectId[projectId]
          const existingPermissionsByWorkspace =
            existingPermissions?.permissionsByWorkspace || []
          const existingPermissionsByUser =
            existingPermissions?.permissionsByUser || []

          const updatedPermissionsByWorkspace =
            existingPermissionsByWorkspace.filter(
              (wp) =>
                !permissions.permissionsByWorkspace?.some(
                  (p) => p.workspaceId === wp.workspaceId
                )
            )
          const updatedPermissionsByUser = existingPermissionsByUser.filter(
            (up) =>
              !permissions.permissionsByUser?.some(
                (p) => p.userId === up.userId
              )
          )

          state.permissionsByProjectId[projectId] = {
            permissionsByWorkspace: [
              ...updatedPermissionsByWorkspace,
              ...(permissions.permissionsByWorkspace || []),
            ],
            permissionsByUser: [
              ...updatedPermissionsByUser,
              ...(permissions.permissionsByUser || []),
            ],
          }
        })
      },
      removeWorkspacePermissionForProjectId: (
        projectId: string,
        workspaceId: number
      ) => {
        set((state) => {
          const existingPermissions = state.permissionsByProjectId[projectId]
          const existingPermissionsByWorkspace =
            existingPermissions?.permissionsByWorkspace || []
          const updatedPermissionsByWorkspace =
            existingPermissionsByWorkspace.filter(
              (wp) => wp.workspaceId !== workspaceId
            )
          state.permissionsByProjectId[projectId] ??= {
            permissionsByWorkspace: [],
            permissionsByUser: [],
          }
          state.permissionsByProjectId[projectId]!.permissionsByWorkspace =
            updatedPermissionsByWorkspace
        })
      },
      removeUserPermissionsForProjectId: (
        projectId: string,
        userIds: string[]
      ) => {
        set((state) => {
          const existingPermissions = state.permissionsByProjectId[projectId]
          const existingPermissionsByUser =
            existingPermissions?.permissionsByUser || []
          const updatedPermissionsByUser = existingPermissionsByUser.filter(
            (up) => up.userId && !userIds.includes(up.userId)
          )
          state.permissionsByProjectId[projectId] ??= {
            permissionsByWorkspace: [],
            permissionsByUser: [],
          }
          state.permissionsByProjectId[projectId]!.permissionsByUser =
            updatedPermissionsByUser
        })
      },
      setCurrentUserPermissionByProjectId: (
        projectId: string,
        permissionLevel: PermissionLevel | null
      ) => {
        set((state) => {
          if (permissionLevel) {
            state.currentUserPermissionByProjectId[projectId] = permissionLevel
          } else {
            delete state.currentUserPermissionByProjectId[projectId]
          }
        })
      },
      setIsFetchingFolderShareStatus: (isFetching: boolean) => {
        set((state) => {
          state.isFetchingFolderShareStatus = isFetching
        })
      },
    }))
  )
)
