import React from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { useSharingStore } from 'stores/sharing-store'
import { PermissionsByUser, PermissionsByWorkspace } from 'types/sharing'

import { getUserPermissionLevel } from 'utils/sharing-helpers'

import { BaseAppPath } from 'components/base-app-path'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import RedirectWithQuery from 'components/common/redirects/redirect-with-query'
import GeneralStoreListener from 'components/general-store-listener'

import useVaultUploadFiles from './hooks/use-vault-upload-files'
import { useVaultFolderUpdatesSubscription } from './utils/use-vault-folder-updates-subscription'
import {
  GenerateN1ResponseProps,
  GenerateNNResponseProps,
  GenerateQuestionsProps,
  QueryQuestionsResponseData,
  projectsPath,
  queriesPath,
  filesPath,
} from './utils/vault'
import { useVaultCreateProjectStore } from './utils/vault-create-project-store'
import { FetchVaultFolderShareStatus } from './utils/vault-fetcher'
import { useVaultSharingStore } from './utils/vault-sharing-store'
import { useVaultStore } from './utils/vault-store'
import VaultFilePreviewer from './vault-file-previewer'
import VaultProjectDetail from './vault-project-detail'
import VaultProjectQueries from './vault-project-queries'
import VaultQueryDetail from './vault-query-detail'

const VaultProjectLayout = ({
  generateQuestions,
  generateNNResponse,
  generateN1Response,
  sendCancelRequestN1,
}: {
  generateQuestions: (
    props: GenerateQuestionsProps
  ) => Promise<QueryQuestionsResponseData>
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  generateN1Response: (props: GenerateN1ResponseProps) => Promise<void>
  sendCancelRequestN1: () => void
}) => {
  useVaultUploadFiles()
  const { projectId } = useParams()
  const userInfo = useAuthUser()
  const projectsMetadata = useVaultStore(useShallow((s) => s.projectsMetadata))
  const folderIdToVaultFolder = useVaultStore(
    useShallow((s) => s.folderIdToVaultFolder)
  )
  const setIsProjectLayoutLoading = useVaultStore(
    (s) => s.setIsProjectLayoutLoading
  )
  const setCurrentProject = useVaultStore((s) => s.setCurrentProject)
  const setAreUploadButtonsDisabled = useVaultStore(
    (s) => s.setAreUploadButtonsDisabled
  )

  const clearFilesToUpload = useVaultCreateProjectStore(
    (s) => s.clearFilesToUpload
  )

  const setPermissionsForProjectId = useVaultSharingStore(
    (s) => s.setPermissionsForProjectId
  )
  const setIsFetchingFolderShareStatus = useVaultSharingStore(
    (s) => s.setIsFetchingFolderShareStatus
  )
  const setDidFetchFolderShareStatusFail = useSharingStore(
    (s) => s.setDidFetchSharingStatusFail
  )
  const setCurrentUserPermissionByProjectId = useVaultSharingStore(
    (s) => s.setCurrentUserPermissionByProjectId
  )

  const updateCurrentUserPermissionLevelForProject = (
    permissionsByWorkspace: PermissionsByWorkspace,
    permissionsByUser: PermissionsByUser,
    projectId: string
  ) => {
    // get the current user's permission level for the project based on share status
    const currentUserPermissionLevel = getUserPermissionLevel({
      userId: userInfo.dbId,
      workspaceId: userInfo.workspace.id,
      permissionsByWorkspace: permissionsByWorkspace,
      permissionsByUser: permissionsByUser,
    })

    if (currentUserPermissionLevel) {
      setCurrentUserPermissionByProjectId(projectId, currentUserPermissionLevel)
    }
  }

  // we need to add this useMount to update the current project
  // because the current project is cleared when the component is unmounted
  // this will ensure that the header and other components have the correct project
  useMount(async () => {
    if (!projectId) return
    setIsProjectLayoutLoading(true)
    const project = folderIdToVaultFolder[projectId]
    if (project) {
      setCurrentProject(project)
    }
    if (userInfo.IsVaultSharingUser) {
      try {
        setIsFetchingFolderShareStatus(true)
        const response = await FetchVaultFolderShareStatus(projectId)
        setDidFetchFolderShareStatusFail(false)
        setPermissionsForProjectId(projectId, response.shareStatus)
        // get the current user's permission level for the project based on share status
        updateCurrentUserPermissionLevelForProject(
          response.shareStatus.permissionsByWorkspace as PermissionsByWorkspace,
          response.shareStatus.permissionsByUser as PermissionsByUser,
          projectId
        )
      } catch (error) {
        setDidFetchFolderShareStatusFail(true)
        console.error('Error fetching vault folder share status')
      }
      setIsFetchingFolderShareStatus(false)
    }
    setIsProjectLayoutLoading(false)
  })

  useVaultFolderUpdatesSubscription(projectId!)

  useUnmount(() => {
    setAreUploadButtonsDisabled(true)
    clearFilesToUpload()
    setCurrentProject(null)
    setIsProjectLayoutLoading(false)
  })

  if (!projectId) return null

  if (!projectsMetadata[projectId]) {
    return (
      <AppMain className="flex w-full">
        <FullscreenLoading isLoading zIndex="z-50" />
      </AppMain>
    )
  }

  // if the user navigates to /files, let's redirect them to the project page
  return (
    <>
      <GeneralStoreListener />
      <Routes>
        <Route
          path="/"
          element={
            <VaultProjectDetail
              generateQuestions={generateQuestions}
              generateNNResponse={generateNNResponse}
              generateN1Response={generateN1Response}
            />
          }
        />
        <Route
          path={filesPath}
          element={
            <RedirectWithQuery
              to={`${BaseAppPath.Vault}${projectsPath}${projectId}`}
            />
          }
        />
        <Route path={`${filesPath}:fileId`} element={<VaultFilePreviewer />} />
        <Route path={queriesPath} element={<VaultProjectQueries />} />
        <Route
          path={`${queriesPath}:queryId`}
          element={
            <VaultQueryDetail
              sendCancelRequestN1={sendCancelRequestN1}
              generateQuestions={generateQuestions}
              generateNNResponse={generateNNResponse}
              generateN1Response={generateN1Response}
            />
          }
        />
      </Routes>
    </>
  )
}

export default VaultProjectLayout
