import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'

const GeneralStoreListener = () => {
  const { filesUploading } = useGeneralStore(
    useShallow((s) => ({ filesUploading: s.filesUploading }))
  )

  React.useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (filesUploading.length > 0) {
        const message =
          'Files are still uploading. Are you sure you want to leave?'
        e.preventDefault()
        e.returnValue = message // For most browsers
        return message // For some older browsers
      }
    }

    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [filesUploading.length]) // Depend on filesUploading to re-attach the listener if the state changes

  return null
}

export default GeneralStoreListener
