import { useEffect, useCallback, useRef } from 'react'

import _ from 'lodash'

import { getStatusMessageForWorkspace } from 'models/workspace'
import { useGeneralStore } from 'stores/general-store'
import { Maybe } from 'types'

import { StatusBarProps } from './common/status-bar'

const REFETCH_INTERVAL = 1000 * 60 // 1 minute

export const useStatusBarFetcher = (workspaceId: number) => {
  const setStatusBar = useGeneralStore((s) => s.setStatusBar)
  const lastStatusBarRef = useRef<Maybe<StatusBarProps>>(null)

  const fetchStatusBar = useCallback(async () => {
    try {
      const statusBar = await getStatusMessageForWorkspace(workspaceId)
      if (!_.isEqual(statusBar?.statusBarMeta, lastStatusBarRef.current)) {
        lastStatusBarRef.current = statusBar?.statusBarMeta
        setStatusBar(statusBar?.statusBarMeta)
      }
    } catch (error) {
      console.error('Failed to fetch status bar:', error)
    }
  }, [setStatusBar, workspaceId])

  useEffect(() => {
    fetchStatusBar() // Initial fetch

    const handleFocus = () => fetchStatusBar()
    const handleReconnect = () => fetchStatusBar()

    window.addEventListener('focus', handleFocus)
    window.addEventListener('online', handleReconnect)

    const intervalId = setInterval(fetchStatusBar, REFETCH_INTERVAL)

    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('online', handleReconnect)
      clearInterval(intervalId)
    }
  }, [fetchStatusBar])
}
