import React from 'react'

import { ArrowRight } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useExampleItemsStore } from 'components/library/library-items-store'
import { useLibraryMetadataStore } from 'components/library/library-metadata-store'
import LibraryPreview from 'components/library/library-preview'
import { LibraryItemKind } from 'components/library/library-types'
import { sortByStarred } from 'components/library/library.helpers'
import { useLibraryDataProvider } from 'components/library/use-library-data-provider'
import Skeleton from 'components/ui/skeleton'
import { TextLink } from 'components/ui/text-link'

interface Props {
  isLoading: boolean
}

const MAX_EXAMPLES = 10

const AssistantExamples = ({ isLoading }: Props) => {
  const [items, setIsLoading, setItems, updateLastUpdatedTime] =
    useExampleItemsStore(
      useShallow((s) => [
        s.items,
        s.setIsLoading,
        s.setItems,
        s.updateLastUpdatedTime,
      ])
    )
  const getFavoriteStatus = useLibraryMetadataStore((s) => s.getFavoriteStatus)
  useLibraryDataProvider({
    setIsLoading,
    setItems,
    updateLastUpdatedTime,
    type: LibraryItemKind.EXAMPLE,
  })

  const sortedItems = Object.values(items).sort((a, b) => {
    return sortByStarred(a, b, getFavoriteStatus)
  })

  if (isLoading) {
    return <Skeleton className="py-4" rows={3} rowHeight="h-4" />
  }

  if (!sortedItems.length) {
    return (
      <p className="pt-6 text-center text-muted">
        Example queries will appear here
      </p>
    )
  }

  return (
    <>
      <LibraryPreview
        items={sortedItems.slice(0, MAX_EXAMPLES)}
        isLoading={isLoading}
        showHeaders
      />
      <TextLink
        className="mt-2 inline-block font-semibold"
        label="View all examples"
        href="/library/examples"
        trailingIcon={<ArrowRight />}
      />
    </>
  )
}

export default AssistantExamples
