import React from 'react'
import { useState } from 'react'

import { useNavigateWithQueryParams } from 'utils/routing'

import { SettingsPath } from 'components/base-app-path'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

const SettingsExperimentInspectorCard = () => {
  const navigate = useNavigateWithQueryParams()
  const [experimentSlug, setExperimentSlug] = useState('')
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>
            <div className="font-semibold">Experiment inspector</div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Label>
            {' '}
            Experiment Slug
            <Input
              type="text"
              placeholder="e.g. gpt4o_1106"
              value={experimentSlug}
              onChange={(e) => setExperimentSlug(e.target.value)}
            />
          </Label>
          <Button
            className="mt-4"
            variant="secondary"
            onClick={() =>
              navigate(
                `${SettingsPath.InternalAdminExperimentManagement}/${experimentSlug}`
              )
            }
          >
            Inspect
          </Button>
        </CardContent>
      </Card>
    </>
  )
}

export default SettingsExperimentInspectorCard
