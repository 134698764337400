import React from 'react'

import _ from 'lodash'

import { Event } from 'models/event'
import Services from 'services'
import { Maybe } from 'types'

import { getQueryForDisplay } from 'utils/task-definitions'
import { cn } from 'utils/utils'

import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { ClientMatterRecord } from 'components/filter/types/client-matter-record'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'

import { DocumentsRow } from './history-cells'
import { useHistoryMetadataStore } from './history-metadata-store'

interface HistoryEditRemoveClientMatterDialogProps {
  type: 'edit' | 'remove'
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  event: Event
  getClientMatterName: (record: ClientMatterRecord) => Maybe<string>
  taskLabelLookup: Record<string, string>
  showClearCmOption?: boolean
}

const HistoryEditRemoveClientMatterDialog: React.FC<
  HistoryEditRemoveClientMatterDialogProps
> = ({
  type,
  modalOpen,
  setModalOpen,
  event,
  getClientMatterName,
  taskLabelLookup,
  showClearCmOption = true,
}) => {
  const [selected, setSelected] = React.useState<string>('')
  const currentClientMatterName = getClientMatterName(event) ?? ''
  const updateEventClientMatter = useHistoryMetadataStore(
    (s) => s.updateEventClientMatter
  )
  const { trackEvent } = useAnalytics()
  const clientMatters = useClientMattersStore((s) => s.clientMatters)

  React.useEffect(() => {
    if (type === 'remove') {
      setSelected('')
    } else {
      setSelected(currentClientMatterName)
    }
  }, [type, setSelected, currentClientMatterName])

  const onEditRemoveSubmit = React.useCallback(async () => {
    const clientMatterId =
      clientMatters.find((cm) => cm.name === selected)?.id ?? null
    await updateEventClientMatter(event.id, clientMatterId)
    setModalOpen(false)
    Services.HoneyComb.Record({
      metric: `ui.history_${type}_client_matter_dialog_submitted`,
      event_id: event.id,
      client_matter: selected,
    })
    trackEvent(`History ${type} Client Matter Dialog Submitted`, {
      event_id: event.id,
      client_matter: selected,
    })
  }, [
    clientMatters,
    updateEventClientMatter,
    event.id,
    setModalOpen,
    type,
    selected,
    trackEvent,
  ])

  const title =
    selected || type === 'edit' ? 'Edit client matter' : 'Remove client matter'
  const isSubmitButtonDisabled =
    type !== 'remove' && currentClientMatterName === selected
  const submitButtonLabel =
    selected || !currentClientMatterName
      ? 'Select client matter'
      : 'Remove client matter'

  const containerRef = React.useRef<HTMLDivElement>(null)

  return (
    <Dialog
      open={modalOpen}
      onOpenChange={(open) => {
        setModalOpen(open)
        Services.HoneyComb.Record({
          metric: `ui.history_${type}_client_matter_dialog_${
            open ? 'opened' : 'dismissed'
          }`,
          event_id: event.id,
        })
        trackEvent(
          `History ${type} Client Matter Dialog ${
            open ? 'Opened' : 'Dismissed'
          }`,
          {
            event_id: event.id,
          }
        )
      }}
    >
      <DialogContent showCloseIcon={false}>
        <div>
          <p className="text-xl">{title}</p>
          <div className="mt-6 space-y-4">
            <div className="space-y-2">
              <p className="font-semibold">Type</p>
              <Badge variant="secondary" className="w-fit">
                <p>{taskLabelLookup[event.kind]}</p>
              </Badge>
            </div>
            <div className="space-y-2">
              <p className="font-semibold">Query</p>
              {event.query && event.query.trim().length > 0 && (
                <p className="line-clamp-3">{getQueryForDisplay(event)}</p>
              )}
              {DocumentsRow({ documents: event.documents || [] })}
            </div>
            <div className="space-y-2">
              <p className="font-semibold">Response</p>
              <p className="line-clamp-5">{event.response}</p>
            </div>
          </div>
          <div className="mt-6" ref={containerRef}>
            <ClientMatterSelect
              className={cn('w-auto', {
                'text-muted': _.isEmpty(selected),
              })}
              selectedValue={selected}
              setSelectedValue={setSelected}
              allowAddNewItem={false}
              containerRef={containerRef}
              showClearOption={showClearCmOption}
            />
          </div>
          <div className="mt-6 flex flex-row justify-end space-x-2">
            <Button
              variant="ghost"
              onClick={() => {
                setModalOpen(false)
                Services.HoneyComb.Record({
                  metric: `ui.history_${type}_client_matter_dialog_cancelled`,
                  event_id: event.id,
                })
                trackEvent(`History ${type} Client Matter Dialog Cancelled`, {
                  event_id: event.id,
                })
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitButtonDisabled}
              onClick={onEditRemoveSubmit}
            >
              {submitButtonLabel}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default HistoryEditRemoveClientMatterDialog
