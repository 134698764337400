import { v4 as uuidv4 } from 'uuid'

import { HistoryItem } from 'types/history'

export const assistantV1ToV2 = (historyItem: HistoryItem): HistoryItem => {
  return {
    ...historyItem,
    messages: [
      {
        // generate uuid
        messageId: uuidv4(),
        ...historyItem,
      },
    ],
  }
}
