import React, { useState, useRef, useEffect } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Divider, Input, Select, Space, Button } from 'antd'
import type { InputRef } from 'antd'

let index = 0

const FlexiSelect: React.FC<{
  options: string[]
  onSelect: (option: string) => void
  label: string
  value: string
  virtual: boolean
}> = ({ options, onSelect, label, value, virtual = true }) => {
  const [items, setItems] = useState(options)
  const [name, setName] = useState('')
  const inputRef = useRef<InputRef>(null)

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ): void => {
    e.preventDefault()
    setItems([...items, name ?? `New item ${index++}`])
    setName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  useEffect(() => {
    setItems(options)
  }, [options])

  return (
    <Select
      style={{ width: 300, marginRight: '8px' }}
      placeholder={`Select ${label}s`}
      virtual={virtual}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px' }} />
          <Space style={{ padding: '8px' }}>
            <Input
              placeholder={`Enter new ${label}`}
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              data-testid={`new-${label}-input`}
            />
            <Button
              type="text"
              icon={<PlusOutlined rev="1.0" />}
              onClick={addItem}
              data-testid={`new-${label}-add-button`}
            >
              Add
            </Button>
          </Space>
        </>
      )}
      options={items.map((item) => ({ label: item, value: item }))}
      onSelect={onSelect}
      value={value}
    />
  )
}

export default FlexiSelect
