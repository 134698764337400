import { useShallow } from 'zustand/react/shallow'

import { PermissionLevel } from 'types/sharing'

import { doesUserHavePermission } from 'utils/sharing-helpers'

import { useAuthUser } from 'components/common/auth-context'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

type UseSharingPermissionsProps = {
  projectId?: string
}

const useSharingPermissions = ({ projectId }: UseSharingPermissionsProps) => {
  const userInfo = useAuthUser()

  const currentUserPermissionByProjectId = useVaultSharingStore(
    useShallow((s) => s.currentUserPermissionByProjectId)
  )
  const projectsMetadata = useVaultStore(useShallow((s) => s.projectsMetadata))
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))

  if (!projectId) {
    return {
      doesCurrentUserHaveEditPermission: false,
      doesCurrentUserHaveFullAccessPermission: false,
    }
  }

  const currentUserPermissionLevel = currentUserPermissionByProjectId[projectId]

  const currentProjectMetadata = projectsMetadata[projectId]
  if (!currentProjectMetadata) {
    return {
      doesCurrentUserHaveEditPermission: false,
      doesCurrentUserHaveFullAccessPermission: false,
    }
  }

  // If the current user is the owner of the project, they have full access
  const isOwner =
    currentProjectMetadata.userId === userInfo.dbId ||
    currentProject?.userId === userInfo.dbId
  if (isOwner) {
    return {
      doesCurrentUserHaveEditPermission: true,
      doesCurrentUserHaveFullAccessPermission: true,
    }
  }

  const doesCurrentUserHaveEditPermission = doesUserHavePermission({
    currentPermissionLevel: currentUserPermissionLevel,
    requiredPermissionLevel: PermissionLevel.EDIT,
    isOwner,
  })

  const doesCurrentUserHaveFullAccessPermission = doesUserHavePermission({
    currentPermissionLevel: currentUserPermissionLevel,
    requiredPermissionLevel: PermissionLevel.FULL_ACCESS,
    isOwner,
  })

  return {
    doesCurrentUserHaveEditPermission,
    doesCurrentUserHaveFullAccessPermission,
  }
}

export default useSharingPermissions
