import _ from 'lodash'

import { DiligenceDocument } from 'openapi/models/DiligenceDocument'
import { DiligenceSection } from 'openapi/models/DiligenceSection'
import { DiligenceTask } from 'openapi/models/DiligenceTask'

import { exportWordWithSections } from 'utils/markdown'
import { TaskType } from 'utils/task'

import { FollowUpQAPair } from './diligence-store'

interface ExportParams {
  documents: DiligenceDocument[]
  sections: DiligenceSection[]
  followUpQAPairs?: FollowUpQAPair[]
}

export const handleExport = async ({
  sections,
  followUpQAPairs,
}: ExportParams) => {
  const sectionHasAnsweredTasks = (section: DiligenceSection) =>
    section.tasks.some((task) => task.answer?.response.length)

  const taskContent = (task: DiligenceTask) => [
    { content: `### ${task.title}`, type: 'markdown' },
    { content: '#### Answer', type: 'markdown' },
    { content: task.answer?.response || '', type: 'markdown' },
  ]

  const exportSections = sections
    .filter(sectionHasAnsweredTasks)
    .flatMap((section) => [
      { content: `## ${section.title}`, type: 'markdown' },
      ...section.tasks
        .filter((t) => t.answer?.response.length)
        .flatMap(taskContent),
    ]) as { content: string; type: 'markdown' }[]

  const followUpContent: { content: string; type: 'markdown' }[] = []

  if (!_.isNil(followUpQAPairs) && !_.isEmpty(followUpQAPairs)) {
    followUpQAPairs.forEach((qaPair, index) => {
      followUpContent.push(
        {
          content: `## Follow-up Question ${index + 1}: ${
            qaPair.selectedSection
          }`,
          type: 'markdown',
        },
        { content: `### Question`, type: 'markdown' },
        { content: qaPair.question, type: 'markdown' },
        { content: `### Answer`, type: 'markdown' },
        { content: qaPair.answer.response || '', type: 'markdown' }
      )
    })
  }

  const allContent = [...exportSections, ...followUpContent]

  await exportWordWithSections({
    title: 'Discovery Report',
    taskType: TaskType.PWC_DEALS,
    includeAnnotation: false,
    queryId: '0',
    sections: allContent,
    filePrefixOverride: 'DISCOVERY',
  })
}
