import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import VaultExportDialog from 'components/vault/dialogs/vault-export-dialog'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultExportHandler = () => {
  const queryId = useVaultStore(useShallow((s) => s.queryId))
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const setIsExportDialogOpen = useVaultStore(
    useShallow((s) => s.setIsExportDialogOpen)
  )

  const selectedRows = useVaultDataGridFilterStore(
    useShallow((s) => s.selectedRows)
  )

  const isLoading = queryIdToState[queryId]?.isLoading

  const handleExport = () => {
    setIsExportDialogOpen(true)
  }

  return (
    <>
      <VaultExportDialog />
      {!!queryId && !isLoading && (
        <Button onClick={handleExport} disabled={isLoading}>
          Export{' '}
          {selectedRows.length > 0 && (
            <Badge
              variant="secondary"
              className="ml-1.5 flex h-4 min-w-4 items-center justify-center p-0"
            >
              {selectedRows.length}
            </Badge>
          )}
        </Button>
      )}
    </>
  )
}

export default VaultExportHandler
