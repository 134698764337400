import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'

import _ from 'lodash'

interface LoadingBarProps {
  maxDurationSeconds?: number
  progress: number
  show: boolean
}

const LoadingBar: React.FC<LoadingBarProps> = ({
  maxDurationSeconds,
  progress,
  show,
}) => {
  const [speed, setSpeed] = useState<number>(1) // Initial speed
  const [currentProgress, setCurrentProgress] = useState<number>(0) // Initial progress

  const totalTimeSec = maxDurationSeconds || 300 // Desired time for the loading bar to complete
  const intervalTime = 1000 // 1 second interval
  const intervalRate = 100 / (totalTimeSec * (1000 / intervalTime)) // Amount to increment each second

  // Update speed every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setSpeed(intervalRate * (0.5 + Math.random() * 1.5)) // Random speed adjusted for intervalRate
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [intervalRate])

  const props = useSpring({
    width: `${currentProgress}%`,
    from: { width: '0%' },
    config: { tension: 280, friction: 60 },
  })

  useEffect(() => {
    const interval: NodeJS.Timeout | null = null

    if (show) {
      if (currentProgress < progress) {
        setCurrentProgress(progress)
      } else if (currentProgress < 100) {
        const interval = setInterval(() => {
          setCurrentProgress((prev) => {
            if (prev < 100) {
              return prev + speed
            }
            return prev
          })
        }, intervalTime)

        return () => {
          clearInterval(interval)
        }
      } else {
        // progress=100%, stop any ongoing animations
        setCurrentProgress(100)
      }
    }
    // Clear animations on unmount
    return () => {
      if (!_.isNull(interval)) {
        clearInterval(interval)
      }
    }
  }, [show, currentProgress, speed, progress])

  if (!show) {
    return null
  }

  return (
    <div
      data-testid="loading-bar"
      className="relative h-2 w-full overflow-hidden rounded bg-muted"
    >
      <animated.div
        className="absolute h-full bg-gradient-to-r from-neutral-700 to-neutral-400"
        style={{
          animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
          ...props,
        }}
      />
    </div>
  )
}

export default LoadingBar
