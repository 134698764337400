import _ from 'lodash'

import { UserInfo } from 'models/user-info'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'

import { TaskType } from 'utils/task'

import { LibraryMetadataAction } from './library-metadata-store'
import { Example, LibraryItem, LibraryItemKind, Prompt } from './library-types'

export const VISIBILITY_SCOPE_TITLES: Record<LibraryVisbilityScope, string> = {
  [LibraryVisbilityScope.PRIVATE]: 'Private',
  [LibraryVisbilityScope.WORKSPACE]: 'Team',
  [LibraryVisbilityScope.HARVEY]: 'Harvey',
}

export const isExample = (item: LibraryItem): item is Example => {
  return item.kind === LibraryItemKind.EXAMPLE
}

export const isPrompt = (item: LibraryItem): item is Prompt => {
  return item.kind === LibraryItemKind.PROMPT
}

export const getPrivateItems = <T extends LibraryItem>(
  items: Record<string, T>
): T[] => {
  return Object.values(items).filter(
    (item) => item.visibilityScope === LibraryVisbilityScope.PRIVATE
  )
}

export const getTeamItems = <T extends LibraryItem>(
  items: Record<string, T>
): T[] => {
  return Object.values(items).filter(
    (item) => item.visibilityScope === LibraryVisbilityScope.WORKSPACE
  )
}

export const getHarveyItems = <T extends LibraryItem>(
  items: Record<string, T>
): T[] => {
  return Object.values(items).filter(
    (item) => item.visibilityScope === LibraryVisbilityScope.HARVEY
  )
}

export const getEventKinds = (items: Record<string, Prompt>): string[] => {
  return _.uniq(Object.values(items).map((item) => item.eventKind))
}

export const getCategories = (items: Record<string, LibraryItem>): string[] => {
  return _.uniq(Object.values(items).map((item) => item.category)).filter(
    (category) => !_.isEmpty(category)
  )
}

export const getPracticeAreas = (
  items: Record<string, LibraryItem>
): string[] => {
  return _.uniq(Object.values(items).map((item) => item.practiceArea)).filter(
    (practiceArea) => !_.isEmpty(practiceArea)
  ) as string[]
}

export const getDocumentTypes = (items: Record<string, Example>): string[] => {
  return _.uniq(Object.values(items).map((item) => item.documentType)).filter(
    (documentType) => !_.isEmpty(documentType)
  ) as string[]
}

export const shouldShowDelete = (
  currentTab: LibraryVisbilityScope,
  userInfo: UserInfo
) => {
  return (
    (currentTab === LibraryVisbilityScope.HARVEY &&
      userInfo.IsLibraryCreateHarveyItemsUser) ||
    (currentTab === LibraryVisbilityScope.WORKSPACE &&
      userInfo.IsLibraryAdmin) ||
    (currentTab === LibraryVisbilityScope.PRIVATE && userInfo.IsLibraryUser)
  )
}

export const shouldShowHide = (
  currentTab: LibraryVisbilityScope,
  userInfo: UserInfo
) => {
  return userInfo.IsLibraryAdmin && currentTab === LibraryVisbilityScope.HARVEY
}

export const shouldShowEdit = (
  currentTab: LibraryVisbilityScope,
  userInfo: UserInfo
) => {
  return (
    (currentTab === LibraryVisbilityScope.HARVEY &&
      (userInfo.IsLibraryCreateHarveyItemsUser || userInfo.IsLibraryAdmin)) ||
    (currentTab === LibraryVisbilityScope.WORKSPACE &&
      userInfo.IsLibraryAdmin) ||
    (currentTab === LibraryVisbilityScope.PRIVATE && userInfo.IsLibraryUser)
  )
}

export const shouldShowCreator = (
  currentTab: LibraryVisbilityScope,
  userInfo: UserInfo
) => {
  return (
    currentTab === LibraryVisbilityScope.WORKSPACE && userInfo.IsLibraryAdmin
  )
}

export const sortByStarred = (
  a: LibraryItem,
  b: LibraryItem,
  getFavoriteStatus: LibraryMetadataAction['getFavoriteStatus']
) => {
  if (getFavoriteStatus(a) !== getFavoriteStatus(b)) {
    return getFavoriteStatus(b) ? 1 : -1
  }
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
}

export const LIBRARY_ITEM_PREFERRED_MIN = 1

// Prefer first set with >= LIBRARY_ITEM_PREFERRED_MIN items.
// If none have that many, fall back to first set that has *any* items.
// If there are no items in any set, then fall back to first set.
export const getDefaultItems = <T extends LibraryItem>(
  orderedItems: T[][]
): T[] => {
  const items =
    orderedItems.find((arr) => arr.length >= LIBRARY_ITEM_PREFERRED_MIN) ||
    orderedItems.find((arr) => !!arr.length) ||
    orderedItems[0]

  return items
}

export const eventKindToQueryLimit = (eventKind: string) => {
  switch (eventKind) {
    case TaskType.OPEN_ENDED:
      return 100000
    case TaskType.RESEARCH:
      return 4000
    default:
      return 4000
  }
}

export const getNavigateOptionsState = (item: LibraryItem) => {
  return {
    state: {
      query: item.kind === LibraryItemKind.PROMPT ? item.query : undefined,
      caption: item.kind === LibraryItemKind.EXAMPLE ? item.name : undefined,
    },
  }
}
