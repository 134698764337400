import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'

import { useGeneralStore } from 'stores/general-store'

import { HIGHLIGHT_END_ID, MarkdownHighlighter } from 'utils/markdown-highlight'
import { calculateNoWhitespaceOffset } from 'utils/text-selection'
import { isAnchorOrButton, isInPopup } from 'utils/utils'

import { AssistantHighlightPopover } from './components/assistant-highlight-popover'
import { AssistantInlineEditPopover } from './components/assistant-inline-edit-popover'
import Markdown, { MarkdownProps } from 'components/common/markdown/markdown'
import { getMarkdownComponents } from 'components/common/markdown/markdown.helpers'
import { Popover, PopoverAnchor } from 'components/ui/popover'

import {
  findMarkdownHighlightIndices,
  findMarkdownHighlightOffsets,
  getMarkdownTextOffset,
  getZeroIndexOffset,
  getHasDeletions,
} from './drafting-text-selection'
import { useAssistantAnalytics } from './hooks/use-assistant-analytics'
import {
  AssistantDraftInlineEditQuery,
  AssistantDraftInlineEditSelectedText,
} from './types'

enum InlineEditPopover {
  ACTIONS = 'actions',
  EDIT = 'edit',
}

type InlineEditParams = {
  onInlineEdit: (params: AssistantDraftInlineEditQuery) => void
  disabled: boolean
  disabledReason: string | undefined
  originalMarkdown?: string
}

type Params = Pick<MarkdownProps, 'content' | 'getHrvyInfoMetadata'> & {
  inlineEdit: InlineEditParams
  currentMessageId: string
  threadNumber: number
  messageNumber: number
}

const DELETE_QUERY = 'Delete this selected text'

export const DraftingMarkdownWithInlineEdits: React.FC<Params> = ({
  content,
  getHrvyInfoMetadata,
  inlineEdit: {
    originalMarkdown,
    onInlineEdit,
    disabled: inlineEditDisabled,
    disabledReason: inlineEditDisabledReason,
  },
  currentMessageId,
  threadNumber,
  messageNumber,
}) => {
  const trackEvent = useAssistantAnalytics()
  const [selectedText, setSelectedText] = useState('')
  const [hasDeletions, setHasDeletions] = useState(false)
  const [visiblePopover, setVisiblePopover] = useState<
    InlineEditPopover | undefined
  >(undefined)
  const handleHidePopover = () => {
    setVisiblePopover(undefined)
    setMdContent(content)
    setHasDeletions(false)
    setSelectedText('')
    if (!inlineEditDisabled) {
      trackEvent('Inline Edit Canceled', {
        event_id: currentMessageId,
        content_length: content.length,
        snippet_length: selectedTextInputParams?.length,
        snippet_offset: selectedTextInputParams?.offset,
        thread_number: threadNumber,
        message_number: messageNumber,
      })
    }
  }

  const [selectedTextInputParams, setSelectedTextInputParams] =
    useState<AssistantDraftInlineEditSelectedText | null>(null)
  const markdownRef = useRef<HTMLDivElement>(null)
  const highlighter = useRef<MarkdownHighlighter | null>(null)
  const [mdContent, setMdContent] = useState(content)
  useEffect(() => setMdContent(content), [content])

  useMount(() => {
    if (markdownRef.current) {
      highlighter.current = new MarkdownHighlighter()
    }
  })

  useEffect(() => {
    setVisiblePopover(undefined)
  }, [content])

  const removeHighlights = useCallback(() => {
    if (highlighter.current) {
      setMdContent(content)
    }
  }, [content])

  const resetMdContent = useCallback(() => {
    setMdContent(content)
  }, [content])

  const isHighlighting = useRef(false)
  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      const target = event.target as Element
      if (isAnchorOrButton(target) || isInPopup(target)) return

      if (inlineEditDisabled) return

      isHighlighting.current = true
      removeHighlights()
    },
    [removeHighlights, inlineEditDisabled]
  )

  /**
   * Given the selected text and the offset of the selection, this function
   * will find the indices of the selected text in the content and set the
   * selected text input params.
   */
  const setSelectedTextInMarkdownParams = useCallback(
    (selectedText: string, markdownOffset: number) => {
      // find the start and end indices of the selected text in the content
      const { startIndex, endIndex, selection } = findMarkdownHighlightOffsets(
        content,
        selectedText,
        markdownOffset
      )

      if (startIndex === -1 || endIndex === -1 || startIndex >= endIndex) {
        // TODO: Can we introduce a fallback where we just pass the raw text
        // and let the model try to figure it out?
        console.warn('Could not find selected text in content')
        removeHighlights()
        setVisiblePopover(undefined)
        return
      }

      if (!highlighter.current) return

      // given raw markdown, highlight the selected text (exact substring) starting from the startIndex
      const highlightedContent = highlighter.current.highlight(
        content,
        selection,
        startIndex
      )
      setMdContent(highlightedContent)

      // remap to get the start and end indices of the selected text in the raw content
      let indices = findMarkdownHighlightIndices(
        content,
        selection,
        markdownOffset
      )
      // means we're in diff view
      if (originalMarkdown) {
        const deletions = getHasDeletions(content, startIndex, endIndex)
        if (deletions) {
          setHasDeletions(true)
        }
        const { startIndex: zeroIdxStart } = getZeroIndexOffset({
          diffMarkdown: content,
          selection,
          startIndex,
        })
        const startIdxFromOriginalMarkdown = getMarkdownTextOffset(
          originalMarkdown,
          zeroIdxStart
        )
        indices = findMarkdownHighlightIndices(
          originalMarkdown,
          selection,
          startIdxFromOriginalMarkdown
        )
      }

      setSelectedTextInputParams({
        length: indices.endIndex - indices.startIndex,
        offset: indices.startIndex,
      })
      setVisiblePopover(InlineEditPopover.ACTIONS)
      trackEvent('Inline Edit Actions Initiated', {
        event_id: currentMessageId,
        content_length: content.length,
        indices: indices,
        snippet_length: indices.endIndex - indices.startIndex,
        snippet_offset: indices.startIndex,
        thread_number: threadNumber,
        message_number: messageNumber,
      })
    },
    [
      content,
      originalMarkdown,
      trackEvent,
      currentMessageId,
      removeHighlights,
      threadNumber,
      messageNumber,
    ]
  )

  const handleMouseUp = useCallback(() => {
    if (!markdownRef.current || !isHighlighting.current) return
    isHighlighting.current = false

    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const selectedText = range.toString()
      setSelectedText(selectedText)

      if (selectedText.length > 0) {
        const fullOffset = calculateNoWhitespaceOffset(
          markdownRef.current,
          range
        )

        // get the offset of the markdown text
        const markdownOffset = getMarkdownTextOffset(content, fullOffset)
        setSelectedTextInMarkdownParams(selectedText, markdownOffset)
      } else {
        setHasDeletions(false)
        setVisiblePopover(undefined)
        resetMdContent()
      }
      selection.removeAllRanges()
    }
  }, [
    content,
    markdownRef,
    setVisiblePopover,
    setSelectedTextInMarkdownParams,
    resetMdContent,
  ])

  const handleShowInlineActions = () => {
    setVisiblePopover(InlineEditPopover.ACTIONS)
  }

  const handleShowInlineEdit = () => {
    setVisiblePopover(InlineEditPopover.EDIT)
  }

  const internalHandleInlineEdit = useCallback(
    (query: string) => {
      if (!content || !selectedTextInputParams) return

      trackEvent('Inline Edit Confirmed', {
        event_id: currentMessageId,
        content_length: content.length,
        snippet_length: selectedTextInputParams.length,
        query_length: query.length,
        thread_number: threadNumber,
        message_number: messageNumber,
      })
      onInlineEdit({
        query,
        selectedText: selectedTextInputParams,
      })
      removeHighlights()
      setHasDeletions(false)
      setVisiblePopover(undefined)
    },
    [
      content,
      selectedTextInputParams,
      onInlineEdit,
      removeHighlights,
      trackEvent,
      currentMessageId,
      threadNumber,
      messageNumber,
    ]
  )

  const handleDeleteSelection = () => {
    internalHandleInlineEdit(DELETE_QUERY)
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp, true)
    return () => document.removeEventListener('mouseup', handleMouseUp, true)
  }, [handleMouseUp])

  const isSidebarOpen = useGeneralStore((s) => s.isSidebarOpen)
  const markdownComponents = getMarkdownComponents({})
  const highlightAnchor = (children: React.ReactNode) => {
    // Boundary to avoid running into header/sidebar
    const contentProps = {
      collisionPadding: { left: isSidebarOpen ? 214 : 92, top: 96 },
      hideWhenDetached: true,
    }
    return (
      <Popover open={!!visiblePopover}>
        <PopoverAnchor asChild>
          <span id={HIGHLIGHT_END_ID} className="font-inherit">
            {children}
          </span>
        </PopoverAnchor>
        {visiblePopover === InlineEditPopover.ACTIONS && (
          <AssistantHighlightPopover
            hasDeletions={hasDeletions}
            inlineEditDisabledReason={
              inlineEditDisabled ? inlineEditDisabledReason : undefined
            }
            onAddRevision={handleShowInlineEdit}
            onDelete={handleDeleteSelection}
            onHide={handleHidePopover}
            selectedText={selectedText}
            {...contentProps}
          />
        )}
        {visiblePopover === InlineEditPopover.EDIT && (
          <AssistantInlineEditPopover
            onHide={handleShowInlineActions}
            onSubmit={internalHandleInlineEdit}
            disabled={inlineEditDisabled}
            disabledReason={inlineEditDisabledReason}
            selectedText={selectedText}
            {...contentProps}
          />
        )}
      </Popover>
    )
  }

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div ref={markdownRef} onMouseDown={handleMouseDown}>
        <Markdown
          components={{
            mark: ({ children, id }) => {
              if (id === HIGHLIGHT_END_ID) {
                return highlightAnchor(children)
              }
              return markdownComponents.mark({ children })
            },
          }}
          content={mdContent}
          getHrvyInfoMetadata={getHrvyInfoMetadata}
          width="100%"
        />
      </div>
    </div>
  )
}
