import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'

import { FileUploadingState } from 'components/assistant-v2/stores/assistant-store'
import { AssistantMessage } from 'components/assistant-v2/types'
import { DOC_QA_QUERY_LIMIT } from 'components/assistant/assistant-utils'

export const ASSISTANT_QUERY_LIMIT = 20000
export const FOLLOW_UP_QUERY_LIMIT = 4000

export const FILE_ID_PARAM = 'fileId'
export const HOMEPAGE_TAB_PARAM = 'tab'
export const MESSAGE_ID_PARAM = 'messageId'
export const PROMPT_PARAM = 'prompt'
export const RESET_PARAM = 'reset'
export const REVISION_ID_PARAM = 'revisionId'
export const SOURCE_ID_PARAM = 'sourceId'

export const EXAMPLE_MESSAGE_ID = 'example'

export const REMOVE_PARAMS = [
  HOMEPAGE_TAB_PARAM,
  MESSAGE_ID_PARAM,
  REVISION_ID_PARAM,
  FILE_ID_PARAM,
  SOURCE_ID_PARAM,
  RESET_PARAM,
]

export const FORK_EVENT_STATE_KEY = 'forkEventId'

export enum StreamingState {
  PROCESSING = 'processing',
  STREAMING = 'streaming',
  SOURCING = 'sourcing',
  DONE = 'done',
}

// TODO: Backend should send these instead of FE deriving from copy
export const getStreamingState = ({
  headerText,
  progress,
}: Pick<AssistantMessage, 'headerText' | 'progress'>): StreamingState => {
  const headerLower = headerText ? headerText.toLowerCase() : ''
  if (/source|citation/.test(headerLower)) return StreamingState.SOURCING
  if (progress === 100) return StreamingState.DONE
  if (progress >= 75) return StreamingState.STREAMING
  return StreamingState.PROCESSING
}

export const getIsFinalizingStream = (message: Maybe<AssistantMessage>) => {
  if (!message) return false
  const streamingState = getStreamingState(message)
  return (
    streamingState === StreamingState.SOURCING ||
    streamingState === StreamingState.DONE
  )
}

export function getMessageQuery(message: Maybe<AssistantMessage>) {
  const query = message?.query
  if (!query) return ''
  return query
}

export function getMessageThreadFromMessages(
  allMessages: AssistantMessage[],
  currentMessageId: string
) {
  // Convert array to dictionary for faster lookup
  const messageDict: { [key: string]: AssistantMessage } = {}
  allMessages.forEach((message) => {
    messageDict[message.messageId] = message
  })

  const currentMessage = messageDict[currentMessageId]
  if (!currentMessage) return allMessages

  // Traverse from the current message to the first message in the thread
  // crawling the prevMessageId references
  const threadMessages = [currentMessage]
  let prevMessageId = currentMessage.prevMessageId
  while (prevMessageId) {
    const prevMessage = messageDict[prevMessageId]
    if (!prevMessage) break

    threadMessages.unshift(prevMessage)
    prevMessageId = prevMessage.prevMessageId
  }

  return threadMessages
}

// Some workspaces should not have access to open ended. Meaning they
// have to always upload a document before asking harvey
export const getQueryLimit = (hasDocuments: boolean, isReply?: boolean) => {
  if (isReply) return FOLLOW_UP_QUERY_LIMIT
  if (hasDocuments) return DOC_QA_QUERY_LIMIT
  return ASSISTANT_QUERY_LIMIT
}

export const isFileUploading = (
  file: UploadedFile | FileUploadingState
): file is FileUploadingState => {
  return !!(file as FileUploadingState).promise
}

// If a user clicks on the same source they were already viewing,
// force query param reset to re-scroll to source
export const incrementReset = (param: Maybe<string>) => {
  return param ? String(parseInt(param, 10) + 1) : '0'
}
