import React, { useEffect, useState } from 'react'

import { Select as AntSelect } from 'antd'
import _ from 'lodash'

import {
  FetchWorkspaces,
  setStatusMessageForWorkspaces,
} from 'models/workspace'
import { useGeneralStore } from 'stores/general-store'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { StatusVariant } from 'components/common/status-bar'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { Checkbox } from 'components/ui/checkbox'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

import CreateIncidentConfirmationDialog from './create-incident-confirmation-dialog'
import { useIncidentManagementStore } from './incident-management-store'
import {
  CTA_TEXT_PLACEHOLDER,
  CTA_URL_PLACEHOLDER,
  OFFLINE_PLACEHOLDER,
  OPEN_AI_DOWN_PLACEHOLDER,
  STATUS_NAME_PLACEHOLDER,
} from './utils'

export const CreateIncidentStatusCard = () => {
  const [
    name,
    setName,
    header,
    setHeader,
    description,
    setDescription,
    ctaText,
    setCtaText,
    ctaUrl,
    setCtaUrl,
    workspacesLoading,
    setWorkspacesLoading,
    variant,
    setVariant,
    workspaces,
    setWorkspaces,
    excludedWorkspaces,
    setExcludedWorkspaces,
    includedWorkspaces,
    setIncludedWorkspaces,
    applyToAllWorkspaces,
    setApplyToAllWorkspaces,
    fetchAllStatusMessages,
  ] = useIncidentManagementStore((s) => [
    s.name,
    s.setName,
    s.header,
    s.setHeader,
    s.description,
    s.setDescription,
    s.ctaText,
    s.setCtaText,
    s.ctaUrl,
    s.setCtaUrl,
    s.workspacesLoading,
    s.setWorkspacesLoading,
    s.variant,
    s.setVariant,
    s.workspaces,
    s.setWorkspaces,
    s.excludedWorkspaces,
    s.setExcludedWorkspaces,
    s.includedWorkspaces,
    s.setIncludedWorkspaces,
    s.applyToAllWorkspaces,
    s.setApplyToAllWorkspaces,
    s.fetchAllStatusMessages,
  ])

  const [setStatusBar, setIsStatusBarPreview] = useGeneralStore((s) => [
    s.setStatusBar,
    s.setIsStatusBarPreview,
  ])

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

  useEffect(() => {
    const fetchWorkspaces = async (): Promise<void> => {
      setWorkspacesLoading(true)
      const data = await FetchWorkspaces()
      const filteredData = data.filter(
        (workspace) =>
          !workspace.deletedAt &&
          !(workspace.settings.onlyCustomStatusMessage ?? false)
      )
      const sortedData = _.orderBy(
        filteredData,
        [(workspace) => workspace.id],
        ['asc']
      )
      setWorkspaces(sortedData)
      setWorkspacesLoading(false)
    }
    void fetchWorkspaces()
  }, [setWorkspacesLoading, setWorkspaces])

  const updateIncludedWorkspaces = (selectedIds: number[]) => {
    const newIncludedWorkspaces = new Set(selectedIds)
    const newExcludedWorkspaces = new Set(
      Array.from(excludedWorkspaces).filter(
        (id) => !newIncludedWorkspaces.has(id)
      )
    )

    setIncludedWorkspaces(newIncludedWorkspaces)
    setExcludedWorkspaces(newExcludedWorkspaces)
  }

  const updateExcludedWorkspaces = (selectedIds: number[]) => {
    const newExcludedWorkspaces = new Set(selectedIds)
    const newIncludedWorkspaces = new Set(
      Array.from(includedWorkspaces).filter(
        (id) => !newExcludedWorkspaces.has(id)
      )
    )

    setExcludedWorkspaces(newExcludedWorkspaces)
    setIncludedWorkspaces(newIncludedWorkspaces)
  }

  const previewStatusBar = () => {
    setStatusBar({
      variant,
      description,
      header,
      cta:
        ctaText && ctaUrl
          ? {
              text: ctaText,
              link: ctaUrl,
            }
          : null,
    })
    setIsStatusBarPreview(true)
  }

  const saveStatusBar = async () => {
    try {
      const { workspacesStatusSet, workspacesFailed } =
        await setStatusMessageForWorkspaces({
          name,
          includedWorkspaces: Array.from(includedWorkspaces),
          excludedWorkspaces: Array.from(excludedWorkspaces),
          applyToAllWorkspaces,
          statusBarMeta: {
            variant,
            description,
            header,
            cta:
              ctaText && ctaUrl ? { text: ctaText, link: ctaUrl } : undefined,
          },
        })
      displaySuccessMessage(
        `Status message saved for ${workspacesStatusSet.length} workspaces.
        ${
          workspacesFailed.length
            ? `Failed to save for ${workspacesFailed.length} workspaces.`
            : ''
        }`,
        30
      )
      await fetchAllStatusMessages()
    } catch (e) {
      displayErrorMessage(`Failed to save status bar, ${e}`)
      console.error(e)
    }
  }

  const getSaveDisabledTooltip = () => {
    if (!description) {
      return 'Description is required'
    } else if (!includedWorkspaces.size && !applyToAllWorkspaces) {
      return 'At least one workspace must be included'
    } else if (ctaText && !ctaUrl) {
      return 'CTA URL is required if CTA Text is provided'
    } else if (!ctaText && ctaUrl) {
      return 'CTA Text is required if CTA URL is provided'
    }

    return undefined
  }

  const numWorkspaces = applyToAllWorkspaces
    ? workspaces.length - excludedWorkspaces.size
    : includedWorkspaces.size

  return (
    <>
      <Card>
        <CardHeader className="text-lg font-semibold">
          Create a new status message
        </CardHeader>
        <CardContent>
          {/* convert this to form once we have searchable multi tag input */}
          <div className="space-y-5 p-4">
            <div>
              <Label>
                Incident Name<span className="text-destructive">*</span> (this
                wont be shown to users)
              </Label>
              <Input
                type="text"
                className="mt-1 w-full"
                placeholder={STATUS_NAME_PLACEHOLDER}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <Label>Header</Label>
              <Input
                type="text"
                className="mt-1 w-full"
                placeholder={OFFLINE_PLACEHOLDER}
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
            </div>
            <div>
              <Label>
                Description<span className="text-destructive">*</span>
              </Label>
              <Input
                type="text"
                className="mt-1 w-full"
                placeholder={OPEN_AI_DOWN_PLACEHOLDER}
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="flex justify-between space-x-8">
              <div className="w-1/2">
                <Label>CTA Text</Label>
                <Input
                  type="text"
                  className="mt-1 w-full"
                  placeholder={CTA_TEXT_PLACEHOLDER}
                  value={ctaText}
                  onChange={(e) => setCtaText(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <Label>CTA URL</Label>
                <Input
                  type="text"
                  className="mt-1 w-full"
                  placeholder={CTA_URL_PLACEHOLDER}
                  value={ctaUrl}
                  onChange={(e) => setCtaUrl(e.target.value)}
                />
              </div>
            </div>
            <div>
              <Label>
                Intent<span className="text-destructive">*</span>
              </Label>
              <Select
                value={variant}
                onValueChange={(value) => {
                  setVariant(value as StatusVariant)
                }}
              >
                <SelectTrigger className="mt-1 h-8 min-w-[200px]">
                  <SelectValue placeholder="Select an intent" />
                </SelectTrigger>
                <SelectContent>
                  {Object.values(StatusVariant).map((statusType) => (
                    <SelectItem key={statusType} value={statusType}>
                      {_.startCase(statusType)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Checkbox
              label="Apply to all workspaces"
              labelClassName="font-semibold"
              checked={applyToAllWorkspaces}
              onCheckedChange={() => {
                if (!applyToAllWorkspaces) {
                  setIncludedWorkspaces(new Set())
                } else {
                  setExcludedWorkspaces(new Set())
                }
                setApplyToAllWorkspaces(!applyToAllWorkspaces)
              }}
            />
            <div>
              <Label>Include workspaces</Label>
              <AntSelect
                mode="multiple"
                placeholder="To include workspaces, select them here"
                virtual={false}
                optionFilterProp="label"
                className="mt-1 w-full"
                loading={workspacesLoading}
                options={workspaces
                  .filter((workspace) => !excludedWorkspaces.has(workspace.id))
                  .map((workspace) => {
                    return {
                      label: workspace.clientName,
                      value: workspace.id,
                    }
                  })}
                onChange={(value) => updateIncludedWorkspaces(value)}
                disabled={applyToAllWorkspaces}
                value={Array.from(includedWorkspaces)}
              />
            </div>
            <div>
              <Label>Exclude workspaces</Label>
              <AntSelect
                mode="multiple"
                placeholder="To exclude workspaces, select them here"
                virtual={false}
                optionFilterProp="label"
                className="mt-1 w-full"
                loading={workspacesLoading}
                options={workspaces
                  .filter((workspace) => !includedWorkspaces.has(workspace.id))
                  .map((workspace) => {
                    return {
                      label: workspace.clientName,
                      value: workspace.id,
                    }
                  })}
                onChange={(value) => updateExcludedWorkspaces(value)}
                disabled={!applyToAllWorkspaces}
                value={Array.from(excludedWorkspaces)}
              />
            </div>
          </div>
          <div className="mr-4 flex justify-end">
            <Button
              className="mr-2"
              onClick={previewStatusBar}
              variant="secondary"
              tooltip={getSaveDisabledTooltip()}
              disabled={!_.isNil(getSaveDisabledTooltip())}
              tooltipSide="left"
            >
              Preview
            </Button>
            <Button
              onClick={() => setOpenConfirmationDialog(true)}
              tooltip={getSaveDisabledTooltip()}
              disabled={!_.isNil(getSaveDisabledTooltip())}
              tooltipSide="left"
            >
              Save
            </Button>
          </div>
        </CardContent>
      </Card>
      <CreateIncidentConfirmationDialog
        open={openConfirmationDialog}
        onOpenChange={setOpenConfirmationDialog}
        message={description}
        header={header}
        numWorkspaces={numWorkspaces}
        onConfirm={saveStatusBar}
        name={name}
      />
    </>
  )
}

export default CreateIncidentStatusCard
