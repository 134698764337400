import { useCallback, useEffect, useRef } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { HTTPError } from 'ky'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { PermissionsByUser, PermissionsByWorkspace } from 'types/sharing'

import { getUserPermissionLevel } from 'utils/sharing-helpers'

import { useAuthUser } from 'components/common/auth-context'

import { NUM_ALL_QUERIES_TO_FETCH } from './vault'
import { SubscribeToVaultFolderUpdates } from './vault-fetcher'
import { useVaultSharingStore } from './vault-sharing-store'
import { useVaultStore } from './vault-store'

export const useVaultFolderUpdatesSubscription = (projectId: string) => {
  const userInfo = useAuthUser()
  const queryClient = useQueryClient()
  const exampleProjectIds = useVaultStore((state) => state.exampleProjectIds)
  const projectsMetadata = useVaultStore(
    useShallow((state) => state.projectsMetadata)
  )
  const isExampleProject = exampleProjectIds.has(projectId)
  const isProjectLayoutLoading = useVaultStore(
    (state) => state.isProjectLayoutLoading
  )
  const upsertVaultFiles = useVaultStore((state) => state.upsertVaultFiles)
  const deleteVaultFiles = useVaultStore((state) => state.deleteVaultFiles)
  const upsertVaultFolders = useVaultStore((state) => state.upsertVaultFolders)
  const deleteVaultFolders = useVaultStore((state) => state.deleteVaultFolders)
  const addToProjectsMetadata = useVaultStore(
    (state) => state.addToProjectsMetadata
  )
  const setPermissionsForProjectId = useVaultSharingStore(
    (state) => state.setPermissionsForProjectId
  )
  const setCurrentUserPermissionByProjectId = useVaultSharingStore(
    (state) => state.setCurrentUserPermissionByProjectId
  )

  const revokeProjectPermission = useCallback(() => {
    setCurrentUserPermissionByProjectId(projectId, null)
    deleteVaultFiles(
      projectsMetadata[projectId]?.descendantFiles?.map((file) => file.id) ?? []
    )
    deleteVaultFolders(
      projectsMetadata[projectId]?.descendantFolders?.map(
        (folder) => folder.id
      ) ?? []
    )
    deleteVaultFolders([projectId])
  }, [
    projectId,
    deleteVaultFiles,
    deleteVaultFolders,
    projectsMetadata,
    setCurrentUserPermissionByProjectId,
  ])

  // TODO: Move this logic to setPermissionsForProjectId
  const updateCurrentUserPermissionLevelForProject = useCallback(
    (
      permissionsByWorkspace: PermissionsByWorkspace,
      permissionsByUser: PermissionsByUser,
      projectId: string
    ) => {
      // get the current user's permission level for the project based on share status
      const currentUserPermissionLevel = getUserPermissionLevel({
        userId: userInfo.dbId,
        workspaceId: userInfo.workspace.id,
        permissionsByWorkspace: permissionsByWorkspace,
        permissionsByUser: permissionsByUser,
      })

      if (currentUserPermissionLevel) {
        setCurrentUserPermissionByProjectId(
          projectId,
          currentUserPermissionLevel
        )
      } else if (
        projectsMetadata[projectId] &&
        userInfo.dbId !== projectsMetadata[projectId]!.userId
      ) {
        revokeProjectPermission()
      }
    },
    [
      userInfo.dbId,
      userInfo.workspace.id,
      setCurrentUserPermissionByProjectId,
      projectsMetadata,
      revokeProjectPermission,
    ]
  )

  const lastUpdatedAt = useRef(new Date())
  const subscribeToVaultFolderUpdates = useCallback(async () => {
    try {
      const response = await SubscribeToVaultFolderUpdates(
        projectId,
        lastUpdatedAt.current
      )
      if (
        !response.hasContentUpdates &&
        !response.hasEventsUpdates &&
        !response.hasSharingUpdates
      ) {
        // No updates found, so we can skip the rest of the logic
        return
      }
      lastUpdatedAt.current = new Date()
      if (response.hasContentUpdates) {
        upsertVaultFiles(
          (response.updatedFiles ?? []).filter((file) => !file.deletedAt)
        )
        upsertVaultFolders(
          (response.updatedFolders ?? []).filter((folder) => !folder.deletedAt),
          userInfo.dbId,
          isExampleProject
        )
        deleteVaultFiles(
          (response.updatedFiles ?? [])
            .filter((file) => file.deletedAt)
            .map((file) => file.id)
        )
        deleteVaultFolders(
          (response.updatedFolders ?? [])
            .filter((folder) => folder.deletedAt)
            .map((folder) => folder.id)
        )
        addToProjectsMetadata(
          (response.updatedProjectMetadata ?? []).reduce(
            (acc, metadata) => {
              acc[metadata.id] = metadata
              return acc
            },
            {} as Record<string, VaultFolderMetadata>
          )
        )
      }
      if (response.hasEventsUpdates) {
        await queryClient.invalidateQueries({
          queryKey: [
            HarvQueryKeyPrefix.VaultHistoryQuery,
            projectId,
            NUM_ALL_QUERIES_TO_FETCH,
          ],
        })
      }
      if (response.hasSharingUpdates && response.updatedSharing) {
        if (response.updatedSharing.folderId === projectId) {
          setPermissionsForProjectId(
            projectId,
            response.updatedSharing.shareStatus
          )
          updateCurrentUserPermissionLevelForProject(
            response.updatedSharing.shareStatus
              .permissionsByWorkspace as PermissionsByWorkspace,
            response.updatedSharing.shareStatus
              .permissionsByUser as PermissionsByUser,
            projectId
          )
        } else {
          console.error('Updated sharing folder id is not the project id')
        }
      }
    } catch (error) {
      if (error instanceof HTTPError && error.response.status === 403) {
        revokeProjectPermission()
        return
      }
      throw error
    }
  }, [
    projectId,
    userInfo.dbId,
    isExampleProject,
    upsertVaultFiles,
    upsertVaultFolders,
    deleteVaultFiles,
    deleteVaultFolders,
    addToProjectsMetadata,
    queryClient,
    setPermissionsForProjectId,
    updateCurrentUserPermissionLevelForProject,
    revokeProjectPermission,
  ])

  // Track failures and stop polling after 5 consecutive failures
  const failureCount = useRef(0)
  const isPolling = useRef(false)
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        userInfo.IsVaultUser &&
        !isPolling.current &&
        !isProjectLayoutLoading
      ) {
        isPolling.current = true
        try {
          // This call might take up to 45 seconds to finish if no updates are found.
          await subscribeToVaultFolderUpdates()
        } catch (error) {
          console.error('Error subscribing to vault folder updates', error)
          failureCount.current += 1
          if (failureCount.current >= 5) {
            console.info(
              'Stopping vault folder updates polling due to repeated failures'
            )
            clearInterval(intervalId)
          }
        }
        isPolling.current = false
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [
    subscribeToVaultFolderUpdates,
    userInfo.IsVaultUser,
    isProjectLayoutLoading,
  ])
}
