import React, { useState } from 'react'

import pluralize from 'pluralize'

import { Maybe } from 'types'

import { Alert, AlertTitle } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogDescription,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from 'components/ui/dialog'

interface CreateIncidentConfirmationDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  numWorkspaces: number
  message: string
  header: Maybe<string>
  name: string
  onConfirm: () => void
}

const CreateIncidentConfirmationDialog = ({
  open,
  onOpenChange,
  numWorkspaces,
  message,
  header,
  name,
  onConfirm,
}: CreateIncidentConfirmationDialogProps) => {
  const [acknowledgeCreateIncident, setAcknowledgeCreateIncident] =
    useState(false)

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogTitle>
          Are you sure you want to create incident {name}?
        </DialogTitle>
        <DialogDescription>
          <div className="space-y-2">
            <p>
              This will broadcast this status message to all users of{' '}
              {numWorkspaces} {pluralize('selected workspaces', numWorkspaces)}.
            </p>

            {header && (
              <p>
                <span className="font-semibold">Header</span>: {header}
              </p>
            )}
            <p>
              <span className="font-semibold">Message</span>: {message}
            </p>

            <Alert variant="destructive" className="mt-2">
              <AlertTitle className="flex">
                <p>
                  Please confirm you want to post this message to all users of{' '}
                  {numWorkspaces} {pluralize('workspaces', numWorkspaces)}.
                </p>
                <Checkbox
                  checked={acknowledgeCreateIncident}
                  onCheckedChange={() =>
                    setAcknowledgeCreateIncident(!acknowledgeCreateIncident)
                  }
                />
              </AlertTitle>
            </Alert>
          </div>
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              variant="destructive"
              onClick={() => {
                setAcknowledgeCreateIncident(false)
                onOpenChange(false)
              }}
            >
              Close
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              onClick={() => {
                setAcknowledgeCreateIncident(false)
                onConfirm()
              }}
              disabled={!acknowledgeCreateIncident}
            >
              Confirm
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CreateIncidentConfirmationDialog
