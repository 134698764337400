import React from 'react'

import { useQuery } from '@tanstack/react-query'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { getWorkspaceQueryCapRules } from 'models/query-cap-rule'
import { Workspace } from 'models/workspace'

import QueryCapRulesTable from './query-cap-rules-table'

interface WorkspaceDetailsQueryCapsProps {
  workspace: Workspace
}

const WorkspaceDetailsQueryCaps = ({
  workspace,
}: WorkspaceDetailsQueryCapsProps) => {
  const { data: queryCapRules } = useQuery({
    queryKey: [HarvQueryKeyPrefix.QueryCapRulesQuery, workspace.id],
    queryFn: () => getWorkspaceQueryCapRules(workspace.id),
  })

  return (
    <div className="p-4">
      <QueryCapRulesTable
        queryCapRules={queryCapRules ?? []}
        workspace={workspace}
      />
    </div>
  )
}

export default WorkspaceDetailsQueryCaps
