import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Event } from 'models/event'
import { Maybe } from 'types'

import { SafeRecord } from 'utils/safe-types'

import {
  DefaultDateRange,
  parseStringToDateRange,
} from './instances/date-range-filter'
import { RecordBase } from './types/record-base'

// The FilterKey enum defines keys that correspond to different types of filters.
// These keys are used as search parameters in the URL to control the visibility and filtering of data.
// The aim is to keep these keys unique to avoid conflicts and ensure accurate filtering,
// while also keeping them short to maintain concise and readable URLs.
export enum FilterKey {
  SEARCH = 'qs',
  DATE_RANGE = 'qdr',
  FAVORITE = 'qf',
  CLIENT_MATTER = 'qcm',
  TASK_TYPE = 'qtt',
  DOCUMENT_COUNT = 'qdc',
  SHARED = 'qsh',
}

interface FilterFunction<T> {
  (record: T): boolean
}

interface State<T extends RecordBase> {
  filterFunctions: Record<string, FilterFunction<T>>
  filterValues: SafeRecord<string, string>
  workspaceRetentionPolicyInSeconds: number
}

interface Action<T extends RecordBase> {
  registerFilter: (
    key: string,
    filterFunction: FilterFunction<T> | null
  ) => void
  setFilterValue: (key: string, value: string) => void
  setFilterValues: (values: SafeRecord<string, string>) => void
  setWorkspaceRetentionPolicyInSeconds: (
    retentionPolicyInSeconds: number
  ) => void
  // This allows us to determine the earliest date within the date range filter value.
  // When present, this information is used to adjust our queries accordingly, ensuring
  // that the data fetched encompasses all records from this earliest date forward.
  getEarliestDateFromDateRangeFilter: () => Maybe<Date>
}

const createFilterStore = <T extends RecordBase>() =>
  create(
    devtools(
      immer<State<T> & Action<T>>((set, get) => ({
        filterFunctions: {},
        registerFilter: (
          key: string,
          filterFunction: FilterFunction<T> | null
        ) => {
          set((state) => {
            if (filterFunction === null) {
              const newFilters = { ...state.filterFunctions }
              delete newFilters[key]
              return { filterFunctions: newFilters }
            } else {
              return {
                filterFunctions: {
                  ...state.filterFunctions,
                  [key]: filterFunction,
                },
              }
            }
          })
        },
        filterValues: {},
        setFilterValue: (key: string, value: string) => {
          set((state) => {
            if ((state.filterValues[key] ?? '') === value.trim()) {
              // No value changes, no need to update
              return {}
            }
            if (value.trim() === '') {
              const newValues = { ...state.filterValues }
              delete newValues[key]
              return { filterValues: newValues }
            } else {
              return {
                filterValues: {
                  ...state.filterValues,
                  [key]: value,
                },
              }
            }
          })
        },
        setFilterValues: (values: SafeRecord<string, string>) => {
          set((state) => {
            if (_.isEqual(state.filterValues, values)) {
              // No value changes, no need to update
              return {}
            } else {
              return { filterValues: values }
            }
          })
        },
        workspaceRetentionPolicyInSeconds: 0,
        setWorkspaceRetentionPolicyInSeconds: (
          retentionPolicyInSeconds: number
        ) => {
          set({ workspaceRetentionPolicyInSeconds: retentionPolicyInSeconds })
        },
        getEarliestDateFromDateRangeFilter: () => {
          const { filterValues } = get()
          const dateRangeValue = filterValues[FilterKey.DATE_RANGE]
          if (!_.isNil(dateRangeValue) && dateRangeValue.trim() !== '') {
            const dateRange = parseStringToDateRange(dateRangeValue)
            if (dateRange.from) {
              return dateRange.from
            } else {
              return null
            }
          }
          const workspaceRetentionPolicyInSeconds =
            get().workspaceRetentionPolicyInSeconds
          if (workspaceRetentionPolicyInSeconds > 0) {
            const retentionDate = new Date(
              Date.now() - workspaceRetentionPolicyInSeconds * 1000
            )
            if (retentionDate >= DefaultDateRange.from) {
              return retentionDate
            }
          }
          return DefaultDateRange.from
        },
      }))
    )
  )

export const useEventFilterStore = createFilterStore<Event>()
