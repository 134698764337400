import React from 'react'
import { useKey } from 'react-use'

import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

import { useAuthUser } from './auth-context'

interface ClientMatterSelectionDialogProps {
  isClientMatterDialogOpen: boolean
  setIsClientMatterDialogOpen: (isOpen: boolean) => void
  onContinue: ((e: React.FormEvent) => void) | (() => Promise<void>)
  onCancel?: () => void
  continueButtonText: string
}

const ClientMatterSelectionDialog = ({
  isClientMatterDialogOpen,
  setIsClientMatterDialogOpen,
  onContinue,
  onCancel,
  continueButtonText,
}: ClientMatterSelectionDialogProps) => {
  const userInfo = useAuthUser()
  const { canCmUserQuery } = useClientMattersStore()
  const userRequiresClientMatter =
    userInfo.isClientMattersReadUser && !canCmUserQuery()

  const containerRef = React.useRef<HTMLDivElement>(null)

  const onClose = () => {
    containerRef.current?.blur()
    onCancel?.()
    setIsClientMatterDialogOpen(false)
  }

  useKey('Escape', onClose)

  return (
    <Dialog open={isClientMatterDialogOpen}>
      <DialogContent
        showCloseIcon={false}
        // need to prevent clicks from triggering useClickAway hook
        // that might unfocus other components
        onPointerDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <DialogHeader>
          <DialogTitle>Client matter number selection</DialogTitle>
          <DialogDescription>
            Please select a client matter number to continue with your query.
          </DialogDescription>
        </DialogHeader>
        {/* need to blur popover before dialog closes or we get resize observer errors */}
        <div ref={containerRef}>
          <ClientMatterSelect className="w-60" containerRef={containerRef} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              variant="default"
              onClick={(e) => {
                onContinue(e)
                setIsClientMatterDialogOpen(false)
              }}
              disabled={userRequiresClientMatter}
            >
              {continueButtonText}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ClientMatterSelectionDialog
