import React, { memo, useCallback, useMemo } from 'react'

import {
  FilterChangedEvent,
  SortChangedEvent,
  RowGroupOpenedEvent,
  RowHeightParams,
  ColumnMovedEvent,
  HeaderClassParams,
} from 'ag-grid-community'
import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage } from 'utils/toast'

import { DataGrid } from 'components/ui/data-grid/data-grid'
import RowNumberCell from 'components/vault/components/data-grid/cells/row-number-cell'
import DocumentCell from 'components/vault/components/data-grid/cells/vault-document-cell'
import VaultHeaderCell from 'components/vault/components/data-grid/cells/vault-header-cell'
import VaultGroupRowRenderer from 'components/vault/components/data-grid/cells/vault-row-group-renderer'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { ReorderVaultReviewQueryColumns } from 'components/vault/utils/vault-fetcher'
import { getDisplayedRows } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { EXCLUDED_HEADER_NAMES_FROM_RESIZE } from './vault-query-result-table'

interface VaultDataGridProps {
  queryId: string
}

const VaultDataGrid = ({ queryId }: VaultDataGridProps) => {
  const bulkRemoveSelectedRows = useVaultDataGridFilterStore(
    (state) => state.bulkRemoveSelectedRows
  )
  const setDisplayedRows = useVaultDataGridFilterStore(
    (state) => state.setDisplayedRows
  )
  const setGridApi = useVaultStore((state) => state.setGridApi)

  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const setColumnOrder = useVaultStore((s) => s.setColumnOrder)

  const queryState = queryIdToState[queryId]

  const isExampleProject = useMemo(
    () => currentProject?.id && exampleProjectIds.has(currentProject.id),
    [currentProject?.id, exampleProjectIds]
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const computeHeaderClass = useCallback(
    (params: HeaderClassParams) => {
      const colId = params.column?.getColId() ?? ''
      if (EXCLUDED_HEADER_NAMES_FROM_RESIZE.includes(colId)) {
        return ''
      }
      return queryState?.isLoading ? 'border-r' : ''
    },
    [queryState?.isLoading]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const numVisibleRows = e.api.getDisplayedRowCount()
      if (numVisibleRows === 0) {
        e.api.showNoRowsOverlay()
      } else {
        e.api.hideOverlay()
        e.api.refreshCells()
      }

      setDisplayedRows(getDisplayedRows(e.api))

      // after the filter is applied we need to update the selected rows
      // we do this by getting the selected rows and then updating the selected rows
      // this is necessary because the selected rows are not updated automatically
      // when the filter is applied
      const selectedNodes = e.api.getSelectedNodes()
      const nodesToUnselect: string[] = []
      selectedNodes.forEach((node) => {
        if (!node.displayed && node.id) {
          nodesToUnselect.push(node.id)
          node.setSelected(false)
        }
      })
      bulkRemoveSelectedRows(nodesToUnselect)
    },
    [setDisplayedRows, bulkRemoveSelectedRows]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      e.api.refreshCells()
      setDisplayedRows(getDisplayedRows(e.api))
    },
    [setDisplayedRows]
  )

  const onRowGroupOpened = useCallback((e: RowGroupOpenedEvent) => {
    e.api.refreshCells()
  }, [])

  const onColumnMoved = useCallback(
    async (e: ColumnMovedEvent) => {
      if (e.finished && e.source === 'uiColumnMoved') {
        const allGridColumns = e.api.getAllGridColumns()
        const updatedQuestionIdOrder = allGridColumns
          .map((col) => col.getColDef())
          // only question columns are moveable
          .filter((col) => !col.suppressMovable)
          .map((col) => col.field)
          .filter((field): field is string => field !== undefined)

        try {
          await ReorderVaultReviewQueryColumns(queryId, updatedQuestionIdOrder)
          setColumnOrder(queryId, updatedQuestionIdOrder)
        } catch (error) {
          displayErrorMessage('Error moving column, please try again.')
        }
      }
    },
    [queryId, setColumnOrder]
  )

  const questionColumnType = {
    useValueFormatterForExport: false,
    cellRenderer: DocumentCell,
    pinned: false,
    lockPinned: true,
    // do not allow moving question columns for example projects or when the query is still loading
    suppressMovable:
      isExampleProject ||
      !doesCurrentUserHaveEditPermission ||
      queryState?.isLoading,
    lockPosition: false,
  }

  return (
    <DataGrid
      gridOptions={{
        // allowing reactive components
        reactiveCustomComponents: true,
        // allowing multiple row selection
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        // setting up the grid options to allow for row grouping
        // we set groupDefaultExpanded to -1 so that all of the groups will be expanded by default
        animateRows: false,
        groupDefaultExpanded: -1,
        groupDisplayType: 'groupRows',
        groupRowRenderer: VaultGroupRowRenderer,
        // we need this to prevent the grid from scrolling to the top when new data is loaded
        // new data is loaded when we stream responses and update rowData
        // or when we toggle short/long responses
        // https://stackoverflow.com/questions/55723337/ag-grid-how-to-scroll-to-last-known-position
        suppressScrollOnNewData: true,
        suppressColumnMoveAnimation: true,
        suppressCellFocus: true,
        suppressHeaderFocus: true,
        suppressDragLeaveHidesColumns: true,
      }}
      defaultColDef={{
        headerComponent: VaultHeaderCell,
        suppressMovable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          maxNumConditions: Number.MAX_SAFE_INTEGER,
        },
        lockPosition: true,
        resizable: !queryState?.isLoading,
        headerClass: computeHeaderClass,
      }}
      columnTypes={{
        hidden: {
          hide: true,
          lockVisible: true,
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        number: {
          useValueFormatterForExport: false,
          cellRenderer: RowNumberCell,
          sortable: false,
        },
        document: {
          useValueFormatterForExport: false,
          cellRenderer: DocumentCell,
        },
        text: questionColumnType,
        date: questionColumnType,
        gutter: {
          headerComponent: () => (
            <div className="h-full w-full bg-neutral-50" />
          ),
          useValueFormatterForExport: false,
          rowSpan: (params) => {
            return params.node &&
              params.node.parent &&
              params.node.parent.allChildrenCount
              ? params.node.parent.allChildrenCount - params.node.childIndex
              : 1
          },
          cellRenderer: () => <div className="h-full w-full bg-neutral-50" />,
          resizable: false,
          lockPosition: 'right',
        },
      }}
      setGridApi={setGridApi}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      onRowGroupOpened={onRowGroupOpened}
      // for the grouped rows we want to set the height to 48px
      // otherwise return null to accept the default height
      getRowHeight={(e: RowHeightParams) => {
        const node = e.node
        return node.group ? 48 : null
      }}
      onColumnMoved={onColumnMoved}
    />
  )
}

VaultDataGrid.displayName = 'VaultDataGrid'
export default memo(VaultDataGrid)
