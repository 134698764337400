import { EventKind } from 'openapi/models/EventKind'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'

// Model types
export enum LibraryItemKind {
  PROMPT = 'PROMPT',
  EXAMPLE = 'EXAMPLE',
}

export interface LibraryItem {
  id: string
  userId: string
  createdByUserEmail: string | null
  workspaceId: string
  eventId: number
  eventKind: EventKind
  name: string
  query: string
  kind: LibraryItemKind
  starred: boolean
  workspaceHidden: boolean
  visibilityScope: LibraryVisbilityScope
  category: string
  practiceArea: string
  documentType?: string
  created: string
  updatedAt: string
}

export interface Prompt extends LibraryItem {
  kind: LibraryItemKind.PROMPT
}

export interface PromptDetail extends Prompt {
  meta: object
}

export interface Example extends LibraryItem {
  kind: LibraryItemKind.EXAMPLE
  documentType: string
}

export interface ExampleDetail extends Example {
  meta: object
}

export const LibraryItemInterfaceToKind = {
  Prompt: LibraryItemKind.PROMPT,
  Example: LibraryItemKind.EXAMPLE,
}
