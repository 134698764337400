import React from 'react'

import { FileText } from 'lucide-react'

import { UserInfo } from 'models/user-info'

import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'

export enum FileSource {
  FILES = 'files',
  VAULT = 'vault',
}

export enum DatabaseSource {
  MEMOS = 'memos',
}

export type KnowledgeSource = FileSource | DatabaseSource

export type VaultKnowledgeSource = {
  vaultFolderId: string
  fileIds: string[]
}

export const getKnowledgeSources = (
  userInfo: UserInfo
): Set<KnowledgeSource> => {
  const sources: Set<KnowledgeSource> = new Set()
  if (userInfo.isDocumentUser) {
    sources.add(FileSource.FILES)
  }
  if (userInfo.IsVaultKnowledgeSourceUser) {
    sources.add(FileSource.VAULT)
  }
  return sources
}

export const KnowledgeSourceConfig: Partial<
  Record<
    KnowledgeSource,
    {
      icon: React.ReactElement
      name: string
      label: string
      hideFooter?: boolean
    }
  >
> = {
  [FileSource.VAULT]: {
    icon: <FolderShieldIcon />,
    name: 'Vault',
    label: 'Choose from Vault',
    hideFooter: true,
  },
  [DatabaseSource.MEMOS]: {
    icon: <FileText />,
    name: 'Memos',
    label: 'Memos',
  },
}
