/* eslint-disable max-params */
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { Dict } from 'mixpanel-browser'
import pluralize from 'pluralize'

import {
  ClientMatterWithStats,
  ClientMattersSettingsStore,
  addClientMatters,
  deleteClientMatters,
  editClientMatters,
} from 'models/client-matters'
import { UserInfo } from 'models/user-info'
import Services from 'services'
import { Maybe } from 'types'

import {
  displayErrorMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'

export const CLIENT_MATTER_SIDEBAR_HELP_TEXT = `Associate a client matter number with every query`

export const MAX_CLIENT_MATTER_NAME_LENGTH = 25
export const MAX_CLIENT_MATTER_DESC_LENGTH = 50

export const CLIENT_MATTER_URL_PARAM = 'cm'

export const CLIENT_MATTER_SELECT_ADD_METRIC = 'ui.client_matter_added_select'
export const CLIENT_MATTER_SETTINGS_ADD_METRIC =
  'ui.client_matter_added_settings'
export const CLIENT_MATTER_SETTINGS_BULK_ADD_METRIC =
  'ui.client_matter_bulk_added_settings'
export const CLIENT_MATTER_SETTINGS_DELETE_METRIC =
  'ui.client_matter_deleted_settings'
export const CLIENT_MATTER_SETTINGS_READD_METRIC =
  'ui.client_matter_readded_settings'
export const CLIENT_MATTER_ADD_FAILURE_METRIC = 'ui.client_matter_add_failure'
export const CLIENT_MATTER_SELECTED_METRIC = 'ui.client_matter_selected'
export const CLIENT_MATTER_EDIT_METRIC = 'ui.client_matter_edit'
export const CLIENT_MATTER_EDIT_FAILURE_METRIC = 'ui.client_matter_edit_failure'

export type ClientMatterAdd = {
  cmName: string
  cmDesc: Maybe<string>
}

export const handleAddClientMatter = async (
  addCms: ClientMatterAdd[],
  successCallback: (successClientMatters: ClientMatterWithStats[]) => void,
  metricName: string,
  userInfo: Maybe<UserInfo>,
  workspaceId: number,
  trackEvent: (event: string, properties: Dict) => void
) => {
  let successClientMatters: ClientMatterWithStats[] = []
  let erroredClientMatters: string[] = []
  try {
    const response = await addClientMatters(addCms, workspaceId)
    successClientMatters = response.successClientMatters
    erroredClientMatters = response.erroredClientMatters
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError({
      metric: CLIENT_MATTER_ADD_FAILURE_METRIC,
      numFailed: addCms.length,
      user_id: userInfo?.id,
      workspace: userInfo?.workspace.id,
    })
  }

  let message = ''

  if (!_.isEmpty(successClientMatters)) {
    const lenAdded = successClientMatters.length

    if (lenAdded > 1) {
      // bulk
      Services.HoneyComb.Record({
        metric: metricName,
        numAdded: lenAdded,
        user_id: userInfo?.id,
        workspace: userInfo?.workspace.id,
      })
      message += `${lenAdded} ${pluralize(
        'client matter',
        lenAdded
      )} added successfully. `
    } else if (lenAdded === 1) {
      // single
      Services.HoneyComb.Record({
        metric: metricName,
        client_matter: successClientMatters[0].name,
        desc: successClientMatters[0].description,
        user_id: userInfo?.id,
        workspace: userInfo?.workspace.id,
      })
      message += `Client matter ${successClientMatters[0].name} added successfully. `
    }
    trackEvent('Client Matter(s) Added', {
      num_added: lenAdded,
      client_matters: successClientMatters.map((cm) => cm.name),
    })
    successCallback(successClientMatters)
  }

  if (!_.isEmpty(erroredClientMatters)) {
    const lenErrored = erroredClientMatters.length
    if (lenErrored > 1) {
      // bulk
      message += `${lenErrored} ${pluralize(
        'client matter',
        lenErrored
      )} failed to add. `
    } else if (lenErrored === 1) {
      // single
      message += `Client matter ${erroredClientMatters[0]} failed to add. `
    }
    Services.HoneyComb.Record({
      metric: CLIENT_MATTER_ADD_FAILURE_METRIC,
      numFailed: lenErrored,
      user_id: userInfo?.id,
      workspace: userInfo?.workspace.id,
    })
  }

  if (message !== '') {
    if (!_.isEmpty(erroredClientMatters)) {
      displayWarningMessage(message.trim(), 10)
    } else {
      displaySuccessMessage(message.trim(), 5)
    }
  } else {
    displayErrorMessage(
      `Failed to add ${pluralize('client matter', addCms.length)}`,
      15
    )
  }
}

export const handleClientMatterEdit = async (
  editCmId: string,
  cmName: string,
  cmDesc: Maybe<string>,
  workspaceId: number,
  successCallback: (editedClientMatter: ClientMatterWithStats) => void,
  userInfo: Maybe<UserInfo>,
  trackEvent: (event: string, properties: Dict) => void
) => {
  try {
    if (_.isEmpty(cmDesc)) {
      // empty strings are stored as null
      cmDesc = null
    }
    const response = await editClientMatters(
      editCmId,
      cmName,
      cmDesc,
      workspaceId
    )
    if (_.isNil(response)) {
      displayErrorMessage(`Failed to update client matter ${cmName}`, 15)
    } else {
      Services.HoneyComb.Record({
        metric: CLIENT_MATTER_EDIT_METRIC,
        client_matter: cmName,
        workspaceId: workspaceId,
        user_id: userInfo?.id,
      })
      trackEvent('Client Matter Edited', {
        client_matter: cmName,
      })
      displaySuccessMessage(`Client matter ${cmName} updated successfully`, 5)
      successCallback(response)
    }
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.Record({
      metric: CLIENT_MATTER_EDIT_FAILURE_METRIC,
      client_matter: cmName,
      workspaceId: workspaceId,
      user_id: userInfo?.id,
    })
    displayErrorMessage(`Failed to update client matter ${cmName}`, 15)
  }
}

export const handleDeleteClientMatter = async (
  deleteClientMatter: string,
  userInfo: Maybe<UserInfo>,
  workspaceId: number,
  successCallback: (deletedCm: ClientMatterWithStats) => void,
  trackEvent: (event: string, properties: Dict) => void
) => {
  let successClientMatters: ClientMatterWithStats[] = []
  let erroredClientMatters: string[] = []
  try {
    const response = await deleteClientMatters(
      [deleteClientMatter],
      workspaceId
    )
    successClientMatters = response.successClientMatters
    erroredClientMatters = response.erroredClientMatters
  } catch (e) {
    Sentry.captureException(e)
  }
  let message = ''

  // can only delete a single CM for now
  if (!_.isEmpty(successClientMatters)) {
    Services.HoneyComb.Record({
      metric: CLIENT_MATTER_SETTINGS_DELETE_METRIC,
      client_matter: successClientMatters[0].name,
      user_id: userInfo?.id,
      workspace: userInfo?.workspace.id,
    })
    trackEvent('Client Matter Deleted', {
      client_matter: successClientMatters[0].name,
    })
    const deletedClientMatter = successClientMatters[0]
    message += `Client matter ${deletedClientMatter.name} deleted successfully. `
    successCallback(deletedClientMatter)
  }

  if (!_.isEmpty(erroredClientMatters)) {
    const erroredClientMatter = erroredClientMatters[0]
    message += `Failed to delete client matter ${erroredClientMatter}. `
  }

  if (message !== '') {
    if (!_.isEmpty(erroredClientMatters)) {
      displayWarningMessage(message.trim(), 10)
    } else {
      displaySuccessMessage(message.trim(), 5)
    }
  } else {
    displayErrorMessage('Failed to delete client matter', 15)
  }
}

export const mergeAddedClientMatters = (
  oldData: ClientMattersSettingsStore,
  successClientMatters: ClientMatterWithStats[]
) => {
  if (!oldData)
    return {
      clientMattersWithStats: successClientMatters,
      workspaces: [],
    }

  // Create a map for quick access to the updated/new items by their ID
  const updateMap = new Map(successClientMatters.map((item) => [item.id, item]))

  // Iterate through the existing cache to update items or leave them unchanged
  const updatedClientMattersWithStats = oldData.clientMattersWithStats.map(
    (item) => {
      return updateMap.get(item.id) || item // Replace if updated, otherwise keep original
    }
  )

  // Find new items that are not in the existing cache
  const newItems = successClientMatters.filter(
    (newItem) =>
      !oldData.clientMattersWithStats.some((item) => item.id === newItem.id)
  )

  // Combine the updated existing items with any new items
  return {
    ...oldData,
    clientMattersWithStats: [...updatedClientMattersWithStats, ...newItems],
  }
}
