import _ from 'lodash'

import { UserInfo } from 'models/user-info'

import { LibraryItemKind } from 'components/library/library-types'

export interface FetchLibraryArgs {
  libraryType: LibraryItemKind
  pageNumber: number
  pageSize: number
  updatedBefore?: string | null
  updatedAfter?: string | null
}

export const makeLibraryUrl = ({
  libraryType,
  pageNumber,
  pageSize,
}: FetchLibraryArgs) => {
  const params = new URLSearchParams()
  if (!_.isNil(pageSize)) params.append('page_size', pageSize.toString())
  if (!_.isNil(pageNumber)) params.append('page_number', pageNumber.toString())
  return `library/${_.toLower(libraryType)}?${params.toString()}`
}

export const getLibraryTeamLabel = (userInfo: UserInfo) => {
  if (userInfo.workspace.friendlyName === 'Harvey') return 'Harvey (team)'
  return userInfo.workspace.friendlyName || 'Team'
}
