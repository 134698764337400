import { GridApi } from 'ag-grid-community'
import CryptoJS from 'crypto-js'
import {
  addMinutes,
  addSeconds,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  max,
} from 'date-fns'
import _, { isUndefined } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import { FileSpreadsheet, FileText } from 'lucide-react'
import pluralize from 'pluralize'
import { v4 as uuidv4 } from 'uuid'

import { Event } from 'models/event'
import { EventKind } from 'openapi/models/EventKind'
import { EventStatus } from 'openapi/models/EventStatus'
import { FileContainerType } from 'openapi/models/FileContainerType'
import type { VaultFile } from 'openapi/models/VaultFile'
import type { VaultFolder } from 'openapi/models/VaultFolder'
import type { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { VaultFolderMetadataAllOfDescendantFiles } from 'openapi/models/VaultFolderMetadataAllOfDescendantFiles'
import { Maybe } from 'types'
import { FileType, FileTypeReadableName } from 'types/file'
import { HistoryItem } from 'types/history'

import { createFileName } from 'utils/file-utils'
import { getFileDelimeterCharacter, parsedName } from 'utils/file-utils'
import { SafeRecord } from 'utils/safe-types'
import { TaskStatus, TaskType } from 'utils/task'
import {
  LONG_TOAST_DURATION,
  displayErrorMessage,
  displayInfoMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'
import { EM_DASH, parseIsoString } from 'utils/utils'

import {
  ColumnDataType,
  GenerateNNResponseProps,
  ReviewAnswer,
  VaultItem,
  VaultItemStatus,
  VaultItemType,
  VaultItemWithIndex,
} from 'components/vault/utils/vault'
import { FileHierarchy, FileToUpload } from 'components/vault/utils/vault'

import {
  ClearQueryErrors,
  CreateVaultFolder,
  FetchVaultProjectsMetadata,
  UploadVaultFiles,
} from './vault-fetcher'
import {
  VaultCurrentStreamingState,
  VaultExtraSocketState,
  VaultReviewSocketState,
  VaultSocketSetter,
  VaultState,
} from './vault-store'
import { pluralizeDocuments, pluralizeFiles } from './vault-text-utils'

export const getNumSelectedRows = (
  gridApi: GridApi
): { numSelectedRows: number; numRows: number } => {
  let numRows = 0
  let numSelectedRows = 0
  gridApi.forEachLeafNode((node) => {
    numRows++
    if (node.isSelected()) numSelectedRows++
  })
  return { numSelectedRows, numRows }
}

/**
 * Retrieves a list of folders starting from a specified folder and moving up the hierarchy to the root.
 * Each folder is found by its ID, starting with the `startingFolderId`, and moving up the hierarchy by accessing
 * each folder's `parentId` until no parent is found (i.e., the root folder is reached).
 */
export const getFoldersOnPath = (
  startingFolderId: string,
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
) => {
  const foldersOnPath = []
  let folderId: Maybe<string> = startingFolderId
  while (folderId) {
    const folder: VaultFolder | undefined = folderIdToVaultFolder[folderId]
    if (!folder) return undefined
    foldersOnPath.push(folder)
    folderId = folder.parentId
  }
  return foldersOnPath.reverse()
}

type GetFileCountHeaderStringProps = {
  numFiles: number
  processedFileIds?: string[]
  isFilterActive?: boolean
  visibleChildrenLength?: number
}

export const getFileCountHeaderString = ({
  numFiles,
  processedFileIds,
  isFilterActive,
  visibleChildrenLength,
}: GetFileCountHeaderStringProps): string => {
  const totalFileText = pluralizeDocuments(numFiles)
  if (isFilterActive && !isNil(visibleChildrenLength)) {
    return `Viewing ${visibleChildrenLength} of ${totalFileText}`
  }
  return processedFileIds && processedFileIds.length < numFiles
    ? `Processed ${processedFileIds.length} of ${totalFileText}`
    : numFiles > 0
    ? totalFileText
    : ''
}

export const getEtaDisplayString = (
  eta: Date,
  strict: boolean = false,
  prefix: string | null = 'Estimated'
): string => {
  const now = new Date()
  const etaText =
    eta < addMinutes(now, 1)
      ? formatDistanceToNow(addSeconds(now, 1))
      : strict
      ? formatDistanceToNowStrict(eta, { unit: 'minute' })
      : formatDistanceToNow(eta)
  return _.upperFirst([prefix, etaText, 'left'].filter(Boolean).join(' '))
}

export const isUrlExpired = (url: string, expirationKey: string): boolean => {
  if (isEmpty(url)) return true

  const urlObj = new URL(url)
  const expirationParam = urlObj.searchParams.get(expirationKey)
  if (!expirationParam) return true

  // Decode URL-encoded characters and convert to a Date object
  const expirationDate = new Date(decodeURIComponent(expirationParam))

  // Get the current date and time in UTC
  const currentDate = new Date()

  // Check if the current date is past the expiration date
  return currentDate > expirationDate
}

export const sumFileSizesInBytes = async (files: File[]): Promise<number> => {
  const getFileSize = async (file: File) => {
    return file.size
  }

  const fileSizes = await Promise.all(files.map(getFileSize))
  return fileSizes.reduce((a, b) => a + b, 0)
}

export const getVaultProjects = ({
  folderIdToVaultFolder,
  rootVaultFolderIds,
  userId,
  exampleProjectIds,
  sharedProjectIds,
}: {
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  rootVaultFolderIds: string[]
  userId: string
  exampleProjectIds?: Set<string>
  sharedProjectIds?: Set<string>
}): VaultFolder[] => {
  return (
    rootVaultFolderIds
      .map((folderId) => folderIdToVaultFolder[folderId])
      .filter(Boolean) as VaultFolder[]
  )
    .filter(
      (folder) =>
        sharedProjectIds?.has(folder.id) ||
        folder.userId === userId ||
        (exampleProjectIds && exampleProjectIds.has(folder.id))
    )
    .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
}

export const filterAndSortFolders = ({
  folderIdToVaultFolder,
  parentIdToVaultFolderIds,
  userId,
  parentId,
  projectId,
  exampleProjectIds,
  isSharedProject,
}: {
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  parentIdToVaultFolderIds: SafeRecord<string, string[]>
  userId: string
  parentId: string
  projectId: string
  exampleProjectIds: Set<string>
  isSharedProject: boolean
}): VaultFolder[] => {
  return (
    (parentIdToVaultFolderIds[parentId] ?? [])
      .map((folderId) => folderIdToVaultFolder[folderId])
      .filter(Boolean) as VaultFolder[]
  )
    .filter(
      (folder) =>
        folder.userId === userId ||
        exampleProjectIds.has(projectId) ||
        isSharedProject
    )
    .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
}

export const computeQueryDisabledReason = (
  currentProjectMetadata: VaultFolderMetadata,
  pendingQueryFileIds: string[] | null
) => {
  if (currentProjectMetadata.totalFiles === 0) {
    return 'No files uploaded'
  }
  if (pendingQueryFileIds) {
    const pendingQueryFiles = currentProjectMetadata.descendantFiles?.filter(
      (file) => pendingQueryFileIds.includes(file.id)
    )
    if (
      pendingQueryFiles?.every(
        (file) => !file.readyToQuery && file.failureReason
      )
    ) {
      if (pendingQueryFiles.length > 1) {
        return 'All selected files failed to be processed'
      } else {
        return 'The selected file failed to be processed'
      }
    }
    if (pendingQueryFiles?.some((file) => !file.readyToQuery)) {
      if (pendingQueryFiles.length > 1) {
        return 'Selected files are still processing…'
      } else {
        return 'The selected file is still processing…'
      }
    }
  } else {
    if (
      currentProjectMetadata.failedFiles === currentProjectMetadata.totalFiles
    ) {
      if (currentProjectMetadata.totalFiles > 1) {
        return 'All files failed to be processed'
      } else {
        return 'The file failed to be processed'
      }
    }
    if (
      currentProjectMetadata.completedFiles !==
      currentProjectMetadata.totalFiles
    ) {
      if (currentProjectMetadata.totalFiles > 1) {
        return 'Files are still processing…'
      } else {
        return 'The file is still processing…'
      }
    }
  }
  return undefined
}

export const isFileFinishProcessing = (file: VaultFile): boolean => {
  const status = determineFileStatus(file)
  return (
    status === VaultItemStatus.readyToQuery ||
    status === VaultItemStatus.failedToUpload ||
    status === VaultItemStatus.recoverableFailure ||
    status === VaultItemStatus.unrecoverableFailure
  )
}

export const determineFolderStatus = (
  folder: VaultFolder,
  projectsMetadata: SafeRecord<string, VaultFolderMetadata>
): VaultItemStatus => {
  const folderMetadata = projectsMetadata[folder.id] as
    | VaultFolderMetadata
    | undefined
  if (!folderMetadata) {
    return VaultItemStatus.default
  }
  const { completedFiles, totalFiles, failedFiles } = folderMetadata
  if (completedFiles === totalFiles) {
    if (failedFiles > 0) {
      if (failedFiles === totalFiles) {
        // We don't differentiate between recoverable and unrecoverable failures for folders
        return VaultItemStatus.allFilesFailed
      } else {
        // If there are some failed files, but not all, we show the folder as ready to query with failed files
        return VaultItemStatus.readyToQueryWithFailedFiles
      }
    }
    return VaultItemStatus.readyToQuery
  }
  if (
    folderMetadata.descendantFiles?.some(
      (file) => !file.path && !file.failureReason
    )
  ) {
    return VaultItemStatus.uploading
  }
  return VaultItemStatus.processing
}

export const determineFileStatus = (file: VaultFile): VaultItemStatus => {
  if (file.readyToQuery) {
    return VaultItemStatus.readyToQuery
  }
  if (file.failureReason && !isEmpty(file.failureReason.trim())) {
    if (!file.path) {
      // If the file has no path, it's not uploaded yet
      return VaultItemStatus.failedToUpload
    } else if (file.failureRecoverable) {
      return VaultItemStatus.recoverableFailure
    } else {
      return VaultItemStatus.unrecoverableFailure
    }
  }
  if (file.path.length === 0) {
    // If the file has no path, it's still uploading
    return VaultItemStatus.uploading
  }
  return VaultItemStatus.processing
}

const findOrCreateNode = (
  current: FileHierarchy,
  part: string,
  prefix: string
) => {
  let child = current.children.find((c) => c.name === part)
  if (!child) {
    child = {
      id: uuidv4(),
      prefix: prefix,
      name: part,
      files: [],
      children: [],
    }
    current.children.push(child)
  }
  return child
}

type HandleDroppedFilesOrDeletedFilesProps = {
  latestDroppedFiles: File[]
  hasFolderName: boolean
  currentTotalSizeInBytes: number
  setTotalFileSizeInBytes: (size: number) => void
  setFilesToUpload: (files: FileToUpload[]) => void
  checkForDuplicates?: boolean
  namesForExistingVaultFilesAndFilesToUpload?: string[]
  existingFilesToUpload?: FileToUpload[]
}

export const handleDroppedFilesOrDeletedFiles = async ({
  latestDroppedFiles,
  hasFolderName,
  currentTotalSizeInBytes,
  setTotalFileSizeInBytes,
  setFilesToUpload,
  checkForDuplicates = true,
  namesForExistingVaultFilesAndFilesToUpload = [],
  existingFilesToUpload = [],
}: HandleDroppedFilesOrDeletedFilesProps) => {
  setTotalFileSizeInBytes(
    currentTotalSizeInBytes + (await sumFileSizesInBytes(latestDroppedFiles))
  )
  const existingNamesSet = new Set(
    hasFolderName
      ? existingFilesToUpload.map((file) => file.name)
      : namesForExistingVaultFilesAndFilesToUpload
  )
  let renamed = false
  const filesToUpload = latestDroppedFiles.map((file: File) => {
    const parsedFileName = parsedName(
      (file as { path?: string; name: string }).path ?? file.name
    )
    const newFileName = createFileName(parsedFileName, existingNamesSet)
    if (newFileName !== parsedFileName) {
      renamed = true
    }

    // Add the new file name to the set to avoid future duplicates
    existingNamesSet.add(newFileName)

    return {
      file,
      name: newFileName,
    }
  })

  // eslint-disable-next-line
  if (checkForDuplicates && renamed) {
    displayInfoMessage('Duplicate file name detected, files will be renamed.')
  }
  setFilesToUpload([...filesToUpload, ...existingFilesToUpload])
}

type HandleFolderNameChangeProps = {
  hasFolderName: boolean
  existingVaultFileNames: string[]
  existingFilesToUpload: FileToUpload[]
  setFilesToUpload: (files: FileToUpload[]) => void
}

export const handleFolderNameChange = async ({
  hasFolderName,
  existingVaultFileNames,
  existingFilesToUpload,
  setFilesToUpload,
}: HandleFolderNameChangeProps) => {
  const filesToUpload = existingFilesToUpload.map((file) => ({
    file: file.file,
    name: file.file.name,
  }))
  const existingNamesSet = new Set<string>()
  if (hasFolderName) {
    existingVaultFileNames.forEach((name) => existingNamesSet.add(name))
  }
  for (let i = 0; i < filesToUpload.length; i++) {
    const currentFile = filesToUpload[i]
    filesToUpload.slice(0, i).forEach((file) => {
      existingNamesSet.add(file.name)
    })
    currentFile.name = createFileName(currentFile.file.name, existingNamesSet)
    existingNamesSet.add(currentFile.name)
  }

  setFilesToUpload(filesToUpload)
}

export const computeFileHierarchy = (files: FileToUpload[]): FileHierarchy => {
  const delimiterCharacter = getFileDelimeterCharacter()
  const children: FileHierarchy[] = []
  const root = {
    id: uuidv4(),
    name: '',
    prefix: '',
    files: files.filter(
      (file) => file.name.split(delimiterCharacter).length === 1
    ),
    children: children,
  }
  files
    .filter((file) => file.name.split(delimiterCharacter).length > 1)
    .map((file: FileToUpload) => {
      const parts = file.name.split(delimiterCharacter)
      let current = root
      let prefix = root.prefix

      // Process all parts except the last one as directories
      parts.slice(0, -1).map((part: string) => {
        prefix += part + delimiterCharacter
        current = findOrCreateNode(current, part, prefix)
      })

      current.files.push(file)
    })

  return root
}

export const createVaultFoldersHierarchy = async (
  fileHierarchy: FileHierarchy,
  rootFolderId: string
): Promise<{
  folderIdToVaultFolderId: Record<string, string>
  createdFolders: VaultFolder[]
}> => {
  const folderIdToVaultFolderId: Record<string, string> = {}
  folderIdToVaultFolderId[fileHierarchy.id] = rootFolderId

  const currChildrenStack = fileHierarchy.children.map((child) => ({
    id: child.id,
    name: child.name,
    parentVaultFolderId: rootFolderId,
    children: child.children,
  }))

  const createdFolders = []

  while (currChildrenStack.length > 0) {
    const currChild = currChildrenStack.shift()
    if (currChild) {
      const newFolder = await CreateVaultFolder(
        currChild.name,
        currChild.parentVaultFolderId
      )
      createdFolders.push(newFolder)
      const newFolderId = newFolder.id
      folderIdToVaultFolderId[currChild.id] = newFolderId
      currChild.children.map((child) => {
        currChildrenStack.push({
          id: child.id,
          name: child.name,
          parentVaultFolderId: newFolderId,
          children: child.children,
        })
      })
    }
  }

  return { folderIdToVaultFolderId, createdFolders }
}

type OptimisticallyCreateVaultFilesProps = {
  files: FileToUpload[]
  folderId: string
  prefix: string
  upsertVaultFiles: (files: VaultFile[]) => void
  updateProjectMetadata: () => void
}
export const optimisticallyCreateVaultFiles = ({
  files,
  folderId,
  prefix,
  upsertVaultFiles,
  updateProjectMetadata,
}: OptimisticallyCreateVaultFilesProps) => {
  // if we're still submitting, we'll close the dialog and continue in the background
  // optimistically update the UI to show the files being uploaded
  upsertVaultFiles(
    files.map((file: FileToUpload) => {
      const newFileName = file.name.replace(prefix, '')
      return {
        id: `${folderId}-${newFileName}`, // we don't have a file id, so we'll use the folder id + file name
        name: newFileName,
        vaultFolderId: folderId,
        containerType: FileContainerType.VAULT,
        path: '', // we don't have a path, so we'll leave it empty
        url: '', // we don't have a url, so we'll leave it empty
        size: file.file.size,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        contentType: file.file.type,
        tags: [],
      }
    }) as VaultFile[]
  )
  updateProjectMetadata()
}

type OptimisticallyCreateVaultHierarchyFilesProps = {
  rootFolderId: string
  fileHierarchy: FileHierarchy
  folderIdToVaultFolderId: Record<string, string>
  upsertVaultFiles: (files: VaultFile[]) => void
  updateProjectMetadata: () => void
}

export const optimisticallyCreateVaultHierarchyFiles = ({
  rootFolderId,
  fileHierarchy,
  folderIdToVaultFolderId,
  upsertVaultFiles,
  updateProjectMetadata,
}: OptimisticallyCreateVaultHierarchyFilesProps) => {
  const filesGroupedByVaultFolderId: Record<string, FileToUpload[]> = {}
  const vaultFolderIdToPrefix: Record<string, string> = {}
  vaultFolderIdToPrefix[rootFolderId] = ''

  const stack = [fileHierarchy]
  while (stack.length > 0) {
    const currNode = stack.shift()
    if (currNode) {
      const vaultFolderId = folderIdToVaultFolderId[currNode.id]
      // we are not really creating files here
      // instead we are saving them to the local zustand store so that we can optimistically update UI later
      optimisticallyCreateVaultFiles({
        files: currNode.files,
        folderId: vaultFolderId,
        prefix: currNode.prefix,
        upsertVaultFiles,
        updateProjectMetadata,
      })
      if (currNode.files.length > 0) {
        filesGroupedByVaultFolderId[vaultFolderId] = currNode.files
        vaultFolderIdToPrefix[vaultFolderId] = currNode.prefix
      }
      currNode.children.map((child: FileHierarchy) => {
        stack.push(child)
      })
    }
  }
  return { filesGroupedByVaultFolderId, vaultFolderIdToPrefix }
}

const upsertFailedFilesAndDisplayError = (
  folderId: string,
  failedFileInfos: {
    name: string
    error: string
    backingFile?: FileToUpload
  }[],
  successCount: number
) => {
  const failedFiles: VaultFile[] = failedFileInfos.map((failedFileInfo) => {
    return {
      id: `${folderId}-${failedFileInfo.name}`, // we don't have a file id, so we'll use the folder id + file name
      name: failedFileInfo.name,
      vaultFolderId: folderId,
      containerType: FileContainerType.VAULT,
      path: '', // we don't have a path, so we'll leave it empty
      url: '', // we don't have a url, so we'll leave it empty
      size: failedFileInfo.backingFile?.file.size ?? 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      contentType: failedFileInfo.backingFile?.file.type ?? '',
      failureReason: failedFileInfo.error,
      failureRecoverable: false,
      tags: [],
    }
  })

  if (successCount === 0) {
    displayErrorMessage(
      `Failed to upload ${pluralizeFiles(
        failedFiles.length
      )}, please delete and try again`,
      LONG_TOAST_DURATION
    )
  } else {
    displayWarningMessage(
      `${pluralizeFiles(successCount)} uploaded, ${pluralizeFiles(
        failedFiles.length
      )} failed to upload, please delete and try again`,
      LONG_TOAST_DURATION
    )
  }
  return failedFiles
}

type UploadVaultFilesProps = {
  accessToken: string
  files: FileToUpload[]
  folderId: string
  prefix: string
  shouldSetUploadTimestamp: boolean
}

type UploadVaultFilesResult = {
  files: VaultFile[]
  failedFiles: VaultFile[]
}

export const uploadVaultFiles = async ({
  accessToken,
  files,
  folderId,
  prefix,
  shouldSetUploadTimestamp,
}: UploadVaultFilesProps) => {
  try {
    const response = await UploadVaultFiles({
      accessToken,
      files,
      vaultFolderId: folderId,
      prefix,
      shouldSetUploadTimestamp,
    })
    const result: UploadVaultFilesResult = {
      files: response.files as VaultFile[],
      failedFiles: [],
    }

    if (
      response.failedFilesWithErrors &&
      !isEmpty(response.failedFilesWithErrors)
    ) {
      const failedFiles = upsertFailedFilesAndDisplayError(
        folderId,
        response.failedFilesWithErrors.map((failedFile) => ({
          name: failedFile.name,
          error: failedFile.error,
          backingFile: files.find(
            (file) => file.name === prefix + failedFile.name
          ),
        })),
        response.files.length
      )
      result.failedFiles = failedFiles as VaultFile[]
    }
    return result
  } catch (error) {
    const failedFiles = upsertFailedFilesAndDisplayError(
      folderId,
      files.map((file) => ({
        name: file.name.replace(prefix, ''),
        error: 'Failed to upload',
        backingFile: file,
      })),
      0
    )
    return {
      files: [],
      failedFiles: failedFiles as VaultFile[],
    }
  }
}

type CreateFoldersAndFilesProps = {
  accessToken: string
  filesToUpload: FileToUpload[]
  rootFolderId: string
  prefix: string
  areAllFilesProcessed: boolean
  upsertVaultFolders: (folders: VaultFolder[], currentUserId: string) => void
  upsertVaultFiles: (files: VaultFile[]) => void
  updateProjectMetadata: () => void
  addFolderIdToFilesUploading: (folderId: string) => void
  removeFolderIdFromFilesUploading: (folderId: string) => void
  navigateHandler: (hierarchyRootFolderId: string) => void
  currentUserId: string
}

export const createFoldersAndFiles = async ({
  accessToken,
  filesToUpload,
  rootFolderId,
  prefix,
  areAllFilesProcessed,
  upsertVaultFolders,
  upsertVaultFiles,
  updateProjectMetadata,
  addFolderIdToFilesUploading,
  removeFolderIdFromFilesUploading,
  navigateHandler,
  currentUserId,
}: CreateFoldersAndFilesProps) => {
  const fileHierarchy = computeFileHierarchy(filesToUpload)
  if (fileHierarchy.children.length === 0) {
    optimisticallyCreateVaultFiles({
      files: fileHierarchy.files,
      folderId: rootFolderId,
      prefix,
      upsertVaultFiles,
      updateProjectMetadata,
    })
    addFolderIdToFilesUploading(rootFolderId)
    navigateHandler(rootFolderId)
    const { files, failedFiles } = await uploadVaultFiles({
      accessToken,
      files: fileHierarchy.files,
      folderId: rootFolderId,
      prefix,
      shouldSetUploadTimestamp: areAllFilesProcessed,
    })
    removeFolderIdFromFilesUploading(rootFolderId)
    upsertVaultFiles([...files, ...failedFiles])
  } else {
    // going to do two passes through the tree because
    // 1 - we need to create folders for the files to upload to first (we need the folder.id) & preserve the file hierarchy when uploading later
    // 2 - we want to optimistically upload the files in the background

    // first lets traverse the hierarchy and create the necessary vault folders
    // then we are able to map the local folderIds to the actual vault folder ids
    const { folderIdToVaultFolderId, createdFolders } =
      await createVaultFoldersHierarchy(fileHierarchy, rootFolderId)
    upsertVaultFolders(createdFolders, currentUserId)

    // next traverse the hierarchy to create necessary files
    // return the files grouped by vault folder id - needed to then upload files to the right vault folder
    // returns the prefix for each vault folder id - needed to clean up the file name before uploading
    const { filesGroupedByVaultFolderId, vaultFolderIdToPrefix } =
      optimisticallyCreateVaultHierarchyFiles({
        rootFolderId,
        fileHierarchy,
        folderIdToVaultFolderId,
        upsertVaultFiles,
        updateProjectMetadata,
      })
    addFolderIdToFilesUploading(rootFolderId)
    navigateHandler(rootFolderId)
    const vaultFolderIds = Object.keys(filesGroupedByVaultFolderId)
    let isFirstRequest = true
    const files: VaultFile[] = []
    const failedFiles: VaultFile[] = []
    await Promise.all(
      vaultFolderIds.map(async (vaultFolderId) => {
        // Only set the upload timestamp on the first request
        const shouldSetUploadTimestamp = isFirstRequest && areAllFilesProcessed
        isFirstRequest = false

        const {
          files: uploadedFileIdsForFolder,
          failedFiles: failedFilesForFolder,
        } = await uploadVaultFiles({
          accessToken,
          files: filesGroupedByVaultFolderId[vaultFolderId],
          folderId: vaultFolderId,
          prefix: vaultFolderIdToPrefix[vaultFolderId],
          shouldSetUploadTimestamp,
        })
        files.push(...uploadedFileIdsForFolder)
        failedFiles.push(...failedFilesForFolder)
      })
    )
    upsertVaultFiles([...files, ...failedFiles])
    removeFolderIdFromFilesUploading(rootFolderId)
  }
}

export const fetchProjectMetadata = async (vaultFolder: VaultFolder) => {
  const metadata: Record<string, VaultFolderMetadata> = {}
  const folderId = vaultFolder.id
  const response = await FetchVaultProjectsMetadata(folderId)
  if (!Array.isArray(response) || response.length === 0) {
    metadata[folderId] = generateEmptyMetadata(folderId)
  } else {
    response.map((item) => {
      metadata[item.id] = item
    })
  }
  return metadata
}

export const generateEmptyMetadata = (
  folderId: string
): VaultFolderMetadata => {
  return {
    id: folderId,
    userId: '',
    workspaceId: '',
    totalFiles: 0,
    folderSize: 0,
    completedFiles: 0,
    failedFiles: 0,
    latestUnprocessedFileUpdatedAt: undefined,
    fileNames: [],
    descendantFolders: [],
    descendantFiles: [],
    name: '',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }
}

const mergeProjectsMetadata = ({
  state,
  folderIdToVaultFolder,
  fileIdToVaultFile,
  localFileIds,
}: {
  state: VaultState
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  localFileIds: Set<string>
}) => {
  // first flatten all of the filenames to be used later for deduplication check
  const optimisticFileIds: Set<string> = new Set()
  Object.keys(state.projectsMetadata).forEach((folderId) => {
    // @ts-expect-error: we know that folderId is defined
    state.projectsMetadata[folderId]?.fileNames.forEach((fileName: string) => {
      optimisticFileIds.add(`${folderId}-${fileName}`)
    })
  })

  localFileIds.forEach((localFileId) => {
    const vaultFile = fileIdToVaultFile[localFileId]
    if (!vaultFile) return
    // do a deduplication check - if it doesn't exist then its an optimistically created file and should be counted
    const hasNoFilePath = !vaultFile.path
    const isFileNotUploaded = !optimisticFileIds.has(localFileId)

    // first let's handle the case where the folder exists in our project metadata
    // happens when uploading files to an existing project
    if (isFileNotUploaded && hasNoFilePath) {
      // This file is optimistically uploaded and not yet in the database
      let folderId: string | undefined = vaultFile.vaultFolderId
      state.projectsMetadata[folderId] ??= generateEmptyMetadata(folderId)
      // @ts-expect-error: we know that folderId is defined
      state.projectsMetadata[folderId]!.fileNames.push(vaultFile.name)

      // Update metadata for all parent folders
      while (folderId) {
        state.projectsMetadata[folderId] ??= generateEmptyMetadata(folderId)
        state.projectsMetadata[folderId]!.descendantFiles ??= []
        state.projectsMetadata[folderId]!.descendantFiles!.push(
          vaultFile as VaultFolderMetadataAllOfDescendantFiles
        )
        state.projectsMetadata[folderId]!.totalFiles += 1
        if (vaultFile.failureReason) {
          // If the file has a failure reason, it's failed to upload
          state.projectsMetadata[folderId]!.failedFiles += 1
          state.projectsMetadata[folderId]!.completedFiles += 1
        }
        state.projectsMetadata[folderId]!.folderSize += vaultFile.size || 0
        const previousLatestUnprocessedFileUpdatedAt =
          state.projectsMetadata[folderId]!.latestUnprocessedFileUpdatedAt
        state.projectsMetadata[folderId]!.latestUnprocessedFileUpdatedAt =
          previousLatestUnprocessedFileUpdatedAt
            ? max([
                parseIsoString(previousLatestUnprocessedFileUpdatedAt),
                parseIsoString(vaultFile.updatedAt),
              ]).toISOString()
            : vaultFile.updatedAt
        // Preserve lastFileUploadedAt for current project in case it has been optimistically set
        if (state.currentProjectMetadata.id === folderId) {
          state.projectsMetadata[folderId]!.lastFileUploadedAt =
            state.currentProjectMetadata.lastFileUploadedAt
        }

        const currentFolder: VaultFolder | undefined =
          folderIdToVaultFolder[folderId]
        folderId = currentFolder?.parentId
      }
    }
  })
}

export function updateProjectMetadata(state: VaultState) {
  mergeProjectsMetadata({
    state,
    folderIdToVaultFolder: state.folderIdToVaultFolder,
    fileIdToVaultFile: state.fileIdToVaultFile,
    localFileIds: state.localFileIds,
  })

  state.currentProjectMetadata = state.currentProject
    ? state.projectsMetadata[state.currentProject.id] ??
      generateEmptyMetadata(state.currentProject.id)
    : generateEmptyMetadata('')

  if (
    state.currentProject &&
    (state.currentProjectMetadata.completedFiles <
      state.currentProjectMetadata.totalFiles ||
      state.currentProjectMetadata.failedFiles > 0)
  ) {
    // Show processing progress if the project has files that are not processed
    state.showProcessingProgress[state.currentProject.id] = true
  }
}

export const generateEmptyVaultCurrentStreamingState = (
  queryId: string
): VaultCurrentStreamingState => {
  return {
    query: '',
    title: '',
    isLoading: false,
    headerText: '',
    response: '',
    queryId: queryId,
    progress: 0,
    sources: [],
    annotations: {},
    isFromHistory: false,
    dryRun: false,
    taskType: null,
    vaultFolderId: null,
    numFiles: 0,
    numQuestions: 0,
    fileIds: [],
    sourcedFileIds: [],
    createdAt: null,
    startedAt: null,
    completedAt: null,
    cancelledAt: null,
    failedAt: null,
    maxTokenLimitReached: false,
    creatorUserEmail: null,
  }
}

export const generateEmptyVaultReviewSocketState =
  (): VaultReviewSocketState => {
    return {
      followUpQueries: [],
      questions: [],
      questionsNotAnswered: [],
      questionsLimit: 0,
      filesLimit: null,
      maxQuestionCharacterLength: 0,
      minQuestionCharacterLength: 0,
      columnHeaders: [],
      answers: {},
      errors: {},
      fileIdToSources: {},
      processedFileIds: [],
      suppressedFileIds: [],
      eta: null,
      columnOrder: [],
      isWorkflowRepsWarranties: false,
    }
  }

export const isAnswerEmpty = (answer: string): boolean => {
  return answer.trim() === '' || answer.trim().toLocaleLowerCase() === 'n/a'
}

export const getDisplayAnswer = (answer?: ReviewAnswer): string => {
  if (!answer || isAnswerEmpty(answer.text)) {
    return EM_DASH
  }
  if (answer.columnDataType === ColumnDataType.date) {
    const utcDate = parseIsoString(answer.text)
    if (isNaN(utcDate.getTime()) || utcDate.getTime() === 0) {
      // If the date is invalid, return a placeholder
      return EM_DASH
    }
    const dateFormat = new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    }).format(utcDate)
    return dateFormat
  }
  return answer.text
}

type Row = {
  [key: string]: any
}

export const computeRowDataDiff = (gridApi: GridApi, rowData: Row[]): Row[] => {
  const rowsToUpdate: Row[] = []
  rowData.forEach((row) => {
    const currentRowInGrid = gridApi.getRowNode(row.id)
    if (isEmpty(currentRowInGrid)) {
      rowsToUpdate.push(row)
      return
    }
    const currentRowInGridData = currentRowInGrid.data
    if (!isEqual(currentRowInGridData, row)) {
      rowsToUpdate.push(row)
    }
  })
  return rowsToUpdate
}

export const clearReviewErrors = async ({
  setReviewTask,
  queryId,
  fileIds,
  queryIdToReviewState,
}: {
  setReviewTask: VaultSocketSetter
  queryId: string
  fileIds: string[]
  queryIdToReviewState: SafeRecord<string, VaultReviewSocketState>
}) => {
  const reviewState = queryIdToReviewState[queryId]
  if (!reviewState) {
    throw new Error('Review state not found')
  }

  // Filter out the processed file ids that are being cleared to show the new progress.
  const filteredOutProcessedFileIds = reviewState.processedFileIds.filter(
    (id) => !fileIds.includes(id)
  )
  setReviewTask({
    queryId,
    isLoading: true,
    processedFileIds: filteredOutProcessedFileIds,
    // Showing new start time to indicate that the task is in progress
    startedAt: new Date(),
  })

  try {
    const response = await ClearQueryErrors(queryId, fileIds)
    const suppressedFileIds = [
      ...(queryIdToReviewState[queryId]?.suppressedFileIds ?? []),
      ...response.clearedFileIds,
    ]
    setReviewTask({
      queryId,
      isLoading: false,
      suppressedFileIds,
      processedFileIds: reviewState.processedFileIds,
    })
    if (response.clearedFileIds.length === fileIds.length) {
      displaySuccessMessage(
        `${fileIds.length} ${pluralize('error', fileIds.length)} cleared`
      )
    } else {
      const clearedFileCount = response.clearedFileIds.length
      const remainingFileCount = fileIds.length - clearedFileCount
      displayWarningMessage(
        `${clearedFileCount} ${pluralize(
          'error',
          clearedFileCount
        )} cleared, ${remainingFileCount} ${pluralize(
          'error',
          remainingFileCount
        )} failed to clear`
      )
    }
  } catch (e) {
    console.error(e)
    setReviewTask({
      queryId,
      isLoading: false,
      processedFileIds: reviewState.processedFileIds,
    })
    displayErrorMessage(`Failed to clear ${pluralize('error', fileIds.length)}`)
  }
}

export const retryReview = async ({
  generateNNResponse,
  setReviewTask,
  markHistoryTaskAsFromStreaming,
  projectId,
  queryId,
  fileIds,
  queryIdToState,
  queryIdToReviewState,
  requestType = 'retry',
}: {
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  setReviewTask: VaultSocketSetter
  markHistoryTaskAsFromStreaming: (queryId: string) => void
  projectId: string
  queryId: string
  fileIds: string[]
  queryIdToState: SafeRecord<string, VaultCurrentStreamingState>
  queryIdToReviewState: SafeRecord<string, VaultReviewSocketState>
  requestType?: 'retry' | 'extra_files'
}) => {
  const state = queryIdToState[queryId]
  const reviewState = queryIdToReviewState[queryId]
  if (!state || !reviewState) {
    throw new Error('State not found')
  }

  // Instead of markInProgressTaskAsFromHistory(TaskType.VAULT_REVIEW)
  // We currently only support one review query at a time
  const inProgressQuery = getInProgressQuery(
    queryIdToState,
    TaskType.VAULT_REVIEW
  )
  if (inProgressQuery) {
    displayWarningMessage(
      'There is already a review query in progress, please wait for it to finish before making a new one'
    )
    return false
  }
  markHistoryTaskAsFromStreaming(queryId)

  try {
    await generateNNResponse({
      requestType,
      queryId,
      query: state.query,
      folderId: projectId,
      fileIds: fileIds,
      answers: reviewState.answers,
      existingFileIds: state.fileIds,
      existingProcessedFileIds: reviewState.processedFileIds,
      questions: reviewState.questions,
      questionsNotAnswered: reviewState.questionsNotAnswered,
      questionsLimit: reviewState.questionsLimit,
      filesLimit: reviewState.filesLimit,
      maxQuestionCharacterLength: reviewState.maxQuestionCharacterLength,
      minQuestionCharacterLength: reviewState.minQuestionCharacterLength,
      onAuthCallback: (queryId) => {
        setReviewTask({
          isFromHistory: false,
          queryId,
          headerText: 'Processing',
        })
      },
    })
    return true
  } catch (e) {
    console.error(e)
    setReviewTask({
      isFromHistory: false,
      queryId,
      isLoading: false,
      fileIds: state.fileIds,
      processedFileIds: reviewState.processedFileIds,
      answers: reviewState.answers,
    })
    displayErrorMessage(`Failed to retry ${pluralize('error', fileIds.length)}`)
    return false
  }
}

export const updateQueryStateForHistoryItem = (
  historyItem: HistoryItem,
  setTask: VaultSocketSetter,
  setReviewTask: VaultSocketSetter
) => {
  const taskData = {
    isFromHistory: true,
    query: historyItem.query,
    isLoading: historyItem.status === 'IN_PROGRESS',
    headerText: historyItem.headerText ?? '',
    response: historyItem.response,
    queryId: historyItem.id,
    progress:
      historyItem.status === 'IN_PROGRESS'
        ? 50
        : historyItem.status === 'COMPLETED'
        ? 100
        : 0,
    sources: historyItem.sources ?? [],
    annotations: historyItem.annotations,
    taskType: historyItem.kind as TaskType,
    vaultFolderId: historyItem.vaultFolderId,
    numFiles: historyItem.numFiles ?? 0,
    numQuestions: historyItem.numQuestions ?? 0,
    fileIds: historyItem.fileIds ?? [],
    createdAt: historyItem.created,
    startedAt: historyItem.startedAt ?? historyItem.created,
    completedAt:
      historyItem.status === 'COMPLETED' ? historyItem.updatedAt : null,
    failedAt: historyItem.status === 'ERRORED' ? historyItem.updatedAt : null,
    cancelledAt:
      historyItem.status === 'CANCELLED' ? historyItem.updatedAt : null,
    metadata: historyItem.metadata,
  }
  if (historyItem.kind === TaskType.VAULT) {
    setTask(taskData)
  } else if (historyItem.kind === TaskType.VAULT_REVIEW) {
    setReviewTask(taskData)
  }
}

export const updateQueryStateForEvent = (
  event: Event,
  setTask: VaultSocketSetter,
  setReviewTask: VaultSocketSetter
) => {
  const historyItem = {
    ...event,
    created: new Date(event.created),
    updatedAt: new Date(event.updatedAt),
    // The response for event is sanitized in the user/history endpoint, we don't want to use this to override
    // markdown response version from the history item in the user/history/:id endpoint.
    response: undefined,
    // The following fields will not be returned from the user/history endpoint, we don't want to use these
    // to override the history item in the user/history/:id endpoint.
    sources: undefined,
    annotations: undefined,
    fileIds: undefined,
  }
  const taskData = {
    isFromHistory: true,
    query: historyItem.query,
    isLoading: historyItem.status === 'IN_PROGRESS',
    // This is a number in the backend, but we're using it as a string for the query ID
    queryId: historyItem.id.toString(),
    progress:
      historyItem.status === 'IN_PROGRESS'
        ? 50
        : historyItem.status === 'COMPLETED'
        ? 100
        : 0,
    taskType: historyItem.kind as TaskType,
    vaultFolderId: historyItem.vaultFolderId,
    numFiles: historyItem.numFiles ?? 0,
    numQuestions: historyItem.numQuestions ?? 0,
    createdAt: historyItem.created,
    startedAt: historyItem.created,
    completedAt:
      historyItem.status === 'COMPLETED' ? historyItem.updatedAt : null,
    failedAt: historyItem.status === 'ERRORED' ? historyItem.updatedAt : null,
    cancelledAt:
      historyItem.status === 'CANCELLED' ? historyItem.updatedAt : null,
    metadata: historyItem.metadata,
    creatorUserEmail: historyItem.userId,
  }
  if (historyItem.kind === TaskType.VAULT) {
    setTask(taskData)
  } else if (historyItem.kind === TaskType.VAULT_REVIEW) {
    setReviewTask(taskData)
  }
}

export const convertVaultStateToEvent = (
  state: VaultCurrentStreamingState
): Event => {
  const createdAt = state.createdAt ?? state.startedAt
  if (!createdAt) {
    throw new Error('Query createdAt is missing')
  }

  return {
    id: Number(state.queryId!),
    status: state.isLoading
      ? TaskStatus.IN_PROGRESS
      : state.failedAt
      ? TaskStatus.ERRORED
      : state.cancelledAt
      ? TaskStatus.CANCELLED
      : TaskStatus.COMPLETED,
    // Trim query and response for each event
    query: _.trim(state.query),
    response: _.trim(state.response),
    kind: state.taskType!,
    created: createdAt.toISOString(),
    updatedAt: (getQueryUpdatedAt(state) ?? createdAt).toISOString(),
  }
}

export const getInProgressQuery = (
  queryIdToState: SafeRecord<string, VaultCurrentStreamingState>,
  taskType: TaskType
) => {
  return Object.values(queryIdToState).find(
    (state) =>
      state &&
      !state.isFromHistory &&
      state.queryId !== '' &&
      state.isLoading &&
      state.taskType === taskType
  )
}

export const getQueryUpdatedAt = (query: Maybe<VaultExtraSocketState>) => {
  if (!query) return null
  const allDates = [
    query.completedAt,
    query.failedAt,
    query.cancelledAt,
    query.startedAt,
    query.createdAt,
  ].filter(Boolean) as Date[]
  if (allDates.length === 0) return null
  // Gets the latest date, sometimes completedAt/failedAt/cancelledAt might be the latest,
  // sometimes startedAt might be the latest (retry or add more files)
  return max(allDates)
}

export const hasReviewErrors = (
  projectFileIds: Set<string>,
  reviewState?: VaultReviewSocketState
) => {
  if (!reviewState) return false

  const errorFileIds = Object.keys(reviewState.errors)
  const suppressedFileIds = reviewState.suppressedFileIds
  const processedFileIds = reviewState.processedFileIds
  return errorFileIds.some((id) =>
    isError({ projectFileIds, processedFileIds, suppressedFileIds, id })
  )
}

export const isError = ({
  projectFileIds,
  processedFileIds,
  suppressedFileIds,
  id,
}: {
  projectFileIds: Set<string>
  processedFileIds: string[]
  suppressedFileIds: string[]
  id: string
}) =>
  projectFileIds.has(id) &&
  processedFileIds.includes(id) &&
  !suppressedFileIds.includes(id)

export const getItemsInFolder = (
  folderId: string,
  currentProjectMetadata: VaultFolderMetadata
) => {
  let files = (currentProjectMetadata.descendantFiles ?? []).filter(
    (file) => file.vaultFolderId === folderId
  )
  let folders = (currentProjectMetadata.descendantFolders ?? []).filter(
    (folder) => folder.parentId === folderId
  )
  folders.forEach((subFolder) => {
    const { nestedFiles, nestedFolders } = getItemsInFolder(
      subFolder.id,
      currentProjectMetadata
    )
    files = files.concat(nestedFiles)
    folders = folders.concat(nestedFolders)
  })
  return { nestedFiles: files, nestedFolders: folders }
}

interface FileQueryInfo {
  file: VaultFile
  folderPath: string
}

export const getSelectedFiles = (
  selectedRows: VaultItemWithIndex[],
  existingFileIds: Set<string>
) => {
  return selectedRows
    .filter((row) => row.type === VaultItemType.file)
    .filter((row) => !existingFileIds.has(row.id))
    .map((item) => item.data as VaultFile)
    .filter(Boolean)
}

export const getSortedFilesBasedOnReviewQueryOrder = (
  readyToQueryFiles: VaultFile[],
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
) => {
  const fileQueryInfos: FileQueryInfo[] = []
  readyToQueryFiles.forEach((file) => {
    const foldersOnPath =
      getFoldersOnPath(file.vaultFolderId, folderIdToVaultFolder) ?? []
    const folderPath = foldersOnPath
      .slice(1)
      .map((f) => f.name)
      .join('/')
    fileQueryInfos.push({
      file,
      folderPath,
    })
  })

  // sort the files by the folderPath because that is how the group will be determined in the result table
  // we want files in the root directory to be at the bottom
  fileQueryInfos.sort((a, b) => {
    if (a.folderPath === '' && b.folderPath !== '') return 1
    if (a.folderPath !== '' && b.folderPath === '') return -1
    if (a.folderPath === b.folderPath)
      return a.file.name.localeCompare(b.file.name)
    return a.folderPath.localeCompare(b.folderPath)
  })

  return fileQueryInfos.map((fileQueryInfo) => fileQueryInfo.file)
}

export const getExistingCompletedVaultReviewQueries = (
  events: Event[],
  selectedReadyToQueryFileIds: Set<string>
) =>
  events
    .filter((event) => event.status === EventStatus.COMPLETED)
    .filter((event) => event.kind === EventKind.VAULT_REVIEW)
    .filter((event) => !_.isEmpty(event.metadata))
    .filter((event) => (event.metadata as VaultExtraSocketState).fileIds)
    .filter((event) => {
      const fileIds = (event.metadata as VaultExtraSocketState).fileIds
      return !fileIds.some((fileId) => selectedReadyToQueryFileIds.has(fileId))
    })
    .filter((event) => {
      const fileIds = (event.metadata as VaultExtraSocketState).fileIds
      const numFiles =
        isEmpty(fileIds) && event.numFiles ? event.numFiles : fileIds.length
      const filesLimit = (event.metadata as VaultReviewSocketState).filesLimit
      if (
        !!filesLimit &&
        selectedReadyToQueryFileIds.size + numFiles > filesLimit
      ) {
        return false
      }
      return true
    })
    .filter((event) => {
      const questions = (event.metadata as VaultReviewSocketState).questions
      return !isUndefined(questions) && questions.length > 0
    })
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    )

export const getOriginalQuery = (
  queryId: string,
  query: string,
  queryIdToReviewState: SafeRecord<string, VaultReviewSocketState>
) => {
  return queryIdToReviewState[queryId] &&
    queryIdToReviewState[queryId]!.followUpQueries.length > 0
    ? [
        query,
        ...queryIdToReviewState[queryId]!.followUpQueries.map(
          (fuq) => fuq.query
        ),
      ].join('\n')
    : query
}

export const getQuestionsLimit = (
  existingQuestionsLimit: Maybe<number>,
  reviewQuestionsPerQueryLimit: number
) => {
  return existingQuestionsLimit && existingQuestionsLimit > 0
    ? existingQuestionsLimit
    : reviewQuestionsPerQueryLimit
}

export const projectAsItem = (
  project: VaultFolder,
  folderSize: number | undefined,
  totalFiles: number | undefined
): VaultItem => ({
  type: VaultItemType.project,
  ...project,
  data: project,
  status: VaultItemStatus.default,
  size: folderSize,
  totalFiles: totalFiles,
})

export const folderAsItem = ({
  folder,
  projectsMetadata,
  shouldExpand,
  folderIdToVaultFolder,
  parentIdToVaultFolderIds,
  fileIdToVaultFile,
  folderIdToVaultFileIds,
  existingSelectedFileIds,
}: {
  folder: VaultFolder
  projectsMetadata: SafeRecord<string, VaultFolderMetadata>
  shouldExpand: boolean
  folderIdToVaultFolder?: SafeRecord<string, VaultFolder>
  parentIdToVaultFolderIds?: SafeRecord<string, string[]>
  fileIdToVaultFile?: SafeRecord<string, VaultFile>
  folderIdToVaultFileIds?: SafeRecord<string, string[]>
  existingSelectedFileIds?: Set<string>
}): VaultItem => {
  const subFolders = (parentIdToVaultFolderIds?.[folder.id] ?? [])
    .map((id) => folderIdToVaultFolder?.[id])
    .filter(Boolean) as VaultFolder[]
  const subFiles = (folderIdToVaultFileIds?.[folder.id] ?? [])
    .map((fileId) => fileIdToVaultFile?.[fileId])
    .filter(Boolean) as VaultFile[]
  const children = shouldExpand
    ? [
        ...subFolders.map((subFolder) =>
          folderAsItem({
            folder: subFolder,
            projectsMetadata,
            shouldExpand,
            folderIdToVaultFolder,
            parentIdToVaultFolderIds,
            fileIdToVaultFile,
            folderIdToVaultFileIds,
            existingSelectedFileIds,
          })
        ),
        ...subFiles.map((file) => fileAsItem(file, existingSelectedFileIds)),
      ]
    : undefined

  return {
    type: folder.parentId ? VaultItemType.folder : VaultItemType.project,
    ...folder,
    data: folder,
    status: determineFolderStatus(folder, projectsMetadata),
    size: projectsMetadata[folder.id]?.folderSize,
    children: children,
    isAlreadySelected:
      children &&
      children.length > 0 &&
      children.every((child) => child.isAlreadySelected),
    isSomeAlreadySelected:
      children &&
      children.length > 0 &&
      children.some((child) => child.isAlreadySelected),
  }
}

export const fileAsItem = (
  file: VaultFile,
  existingSelectedFileIds?: Set<string>
): VaultItem => {
  return {
    type: VaultItemType.file,
    ...file,
    parentId: file.vaultFolderId,
    data: file,
    status: determineFileStatus(file),
    failureReason: file.failureReason,
    disabled: existingSelectedFileIds?.has(file.id) ?? false,
    isAlreadySelected: existingSelectedFileIds?.has(file.id) ?? false,
    tags: file.tags,
  }
}

export const getFileIcon = (file: VaultFile) => {
  if (
    file.contentType === FileType.EXCEL ||
    file.contentType === FileType.EXCEL_LEGACY ||
    file.contentType === FileType.CSV
  ) {
    return FileSpreadsheet
  }
  return FileText
}

export const getContentTypeDisplayString = (vaultItem: VaultItem) => {
  const rowType = vaultItem.type
  return rowType === VaultItemType.folder || rowType === VaultItemType.project
    ? _.upperFirst(rowType)
    : FileTypeReadableName[
        vaultItem.data.contentType as keyof typeof FileTypeReadableName
      ] || EM_DASH
}

export const getRandomSkeletonSize = (string1: string, string2: string) => {
  const fileIdHashValue = CryptoJS.SHA256(string1).toString(CryptoJS.enc.Hex)
  const numericHash =
    parseInt(fileIdHashValue.slice(0, 8), 16) +
    parseInt(string2.slice(0, 8), 16)
  const randomWidth = (numericHash % 61) + 20
  return `${randomWidth}%`
}

export const getDisplayedRows = (gridApi: GridApi) => {
  const displayedRows: string[] = []
  gridApi.forEachNodeAfterFilterAndSort((node) => {
    if (node.data && node.data.file) {
      displayedRows.push(node.data.file.id)
    } else if (node.id) {
      displayedRows.push(node.id)
    }
  })
  return displayedRows
}
