import React from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Table, Typography } from 'antd'
import { formatDistanceToNow } from 'date-fns'
import _ from 'lodash'

import { Perm, PermSource } from 'models/perms'
import { Permission } from 'models/user-info'

import { parseIsoString } from 'utils/utils'

import Tag from 'components/ui/tag'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

const DisabledButton = () => {
  return (
    <Tooltip>
      <TooltipTrigger style={{ float: 'right' }}>
        <Button disabled icon={<DeleteOutlined />} />
      </TooltipTrigger>
      <TooltipContent side="left">Cannot revoke non user perm</TooltipContent>
    </Tooltip>
  )
}

interface UserPermissionsTableProps {
  perms: Perm[]
  onDeletePerm: (permId: Permission) => Promise<void>
}

const READABLE_SOURCE_NAME: Record<PermSource, string> = {
  [PermSource.USER]: 'User',
}

const UserPermissionsTable: React.FC<UserPermissionsTableProps> = ({
  perms,
  onDeletePerm,
}) => {
  const columns = [
    {
      title: 'Permission name',
      dataIndex: 'name',
      key: 'permissionName',
      width: '20%',
      render: (text: string) => (
        <Typography.Title level={5}>{text}</Typography.Title>
      ),
    },
    {
      title: 'Permission ID',
      dataIndex: 'permId',
      key: 'permissionId',
      width: '20%',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'description',
      width: '50%',
    },
    {
      title: 'Source',
      dataIndex: 'permSources',
      key: 'description',
      width: '50%',
      render: (sources: string[]) => (
        <div className="flex flex-wrap">
          {sources.map((source, idx) => {
            const readableSource =
              READABLE_SOURCE_NAME[source as PermSource] || source
            return (
              <div key={idx} className="m-0.5">
                <Tag text={readableSource} />
              </div>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Expires At',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      width: '30%',
      render: (expiresAt: string) => {
        if (!expiresAt) {
          return <Typography.Text>Never</Typography.Text>
        }
        const expiresAtDate = parseIsoString(expiresAt)
        const timeDifference = _.startCase(
          formatDistanceToNow(expiresAtDate, { addSuffix: true })
        )

        return <div className="w-20">{timeDifference}</div>
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: Perm) =>
        !(record.permSources ?? []).includes(PermSource.USER) ? (
          <DisabledButton />
        ) : (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              void onDeletePerm(record.permId as Permission)
            }}
            style={{ float: 'right' }}
          />
        ),
    },
  ]

  const data = perms.map((perm: Perm) => {
    return {
      key: perm.permId,
      name: perm.name,
      desc: perm.desc,
      permId: perm.permId,
      permSources: perm.permSources,
      expiresAt: perm.expiresAt,
    }
  })

  return (
    <Table
      columns={columns}
      dataSource={_.orderBy(
        _.sortBy(data, 'name'),
        [(item) => (item.permSources ?? []).includes(PermSource.USER)],
        ['desc']
      )}
      pagination={false}
      size="small"
      bordered
      footer={() => (
        <div className="flex justify-end">
          <p>{data.length} permissions</p>
        </div>
      )}
    />
  )
}

export default UserPermissionsTable
