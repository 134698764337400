import React from 'react'

import Services from 'services'

import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { SharedRecord } from 'components/filter/types/shared-record'
import { Switch } from 'components/ui/switch'

const SharedFilter = (props: {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  isShared?: (record: SharedRecord) => boolean
}) => {
  const { filterKey, value, setValue, isShared } = props
  const { trackEvent } = useAnalytics()

  const render: React.FC = () => {
    return (
      <Switch
        defaultChecked={false}
        key={filterKey}
        checked={value === 'true'}
        onCheckedChange={(checked) => {
          setValue(checked ? 'true' : '')
          Services.HoneyComb.Record({
            metric: 'ui.history_shared_filter',
            shared: checked,
          })
          trackEvent(FILTER_CHANGED_EVENT_NAME, {
            entity_name: 'history table',
            field_changed: 'shared',
            shared: checked,
          })
        }}
      />
    )
  }

  const filterLogic = (record: SharedRecord) =>
    value !== 'true' || !!isShared?.(record)

  return { filterKey, displayName: 'Shared only', render, filterLogic }
}

export default SharedFilter
