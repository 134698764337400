import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { useRunFollowUpQuestion } from 'components/workflows/workflow/discovery/common/use-run-follow-up-question'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'

import FollowUpInput from './follow-up-input'

export const FollowUpSectionWrapper = () => {
  const [
    sections,
    selectedTaxonomies,
    selectedSectionForFollowUp,
    setSelectedSectionForFollowUp,
    isStreamingFollowUp,
  ] = useDiligenceStore(
    useShallow((s) => [
      s.sections,
      s.selectedTaxonomies,
      s.selectedSectionForFollowUp,
      s.setSelectedSectionForFollowUp,
      s.isStreamingFollowUp,
    ])
  )

  const runFollowUp = useRunFollowUpQuestion()

  const sectionsWithAnswers = sections.filter((section) =>
    selectedTaxonomies.find((taxonomy) => taxonomy.title === section.title)
  )

  return (
    <div className="mt-3 w-[90%] max-w-[936px] px-8">
      <div className="mb-3">
        <Select
          value={selectedSectionForFollowUp}
          onValueChange={setSelectedSectionForFollowUp}
          disabled={isStreamingFollowUp}
        >
          <SelectTrigger className="mb-4 w-[280px]">
            <SelectValue placeholder="Choose a Section" />
          </SelectTrigger>
          <SelectContent>
            {sectionsWithAnswers.map((oneSection) => (
              <SelectItem key={oneSection.title} value={oneSection.title}>
                {oneSection.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <FollowUpInput onAsk={runFollowUp} />
    </div>
  )
}
