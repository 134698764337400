import React, { useState } from 'react'

import { Folder, Users } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { useSharingStore } from 'stores/sharing-store'

import { bytesToPreciseReadable } from 'utils/file-utils'
import { backendToReadable } from 'utils/utils'

import AssistantFiles from 'components/assistant-v2/components/assistant-files'
import {
  FileUploadingState,
  useAssistantStore,
} from 'components/assistant-v2/stores/assistant-store'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import Link from 'components/ui/link/link'
import { ScrollArea } from 'components/ui/scroll-area'
import { SkeletonBlock } from 'components/ui/skeleton'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  getSortedFilesBasedOnReviewQueryOrder,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

interface Props {
  onClose: () => void
}

const AssistantVaultKnowledgeSource = ({ onClose }: Props) => {
  const [fileIdToVaultFile, folderIdToVaultFolder] = useVaultStore(
    useShallow((s) => [
      s.fileIdToVaultFile,
      s.folderIdToVaultFolder,
      s.rootVaultFolderIds,
      s.sharedProjectIds,
    ])
  )

  const [vaultSource, setVaultSource] = useAssistantStore(
    useShallow((s) => [s.vaultSource, s.setVaultSource])
  )

  const { projects: allVaultProjects, isFetching } = useVaultProjects(
    undefined,
    { includeAll: true, includeExamples: false }
  )

  const activeProject = vaultSource
    ? allVaultProjects.find(
        (project) => project.id === vaultSource.vaultFolderId
      )
    : null
  const activeFileIds = new Set(vaultSource?.fileIds || [])
  const activeFiles = Array.from(activeFileIds)
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]

  const [showDialog, setShowDialog] = useState(!activeFiles.length)

  const selectedRows = useVaultFileExplorerStore((s) => s.selectedRows)
  const selectedFiles = getSelectedFiles(selectedRows, activeFileIds)

  const handleClose = () => {
    setVaultSource(null)
    onClose()
  }

  const handleOpenChange = (isOpen: boolean) => {
    setShowDialog(isOpen)
    if (!isOpen && !activeFiles.length) handleClose()
  }
  const handleSelectMore = () => {
    setShowDialog(true)
  }

  const handleSelectProject = (project: VaultFolder) => {
    setVaultSource({ vaultFolderId: project.id, fileIds: [] })
  }
  const handleResetFolder = () => {
    setVaultSource({ vaultFolderId: '', fileIds: [] })
  }

  const handleAddFiles = () => {
    if (!vaultSource) return
    const sortedFiles = getSortedFilesBasedOnReviewQueryOrder(
      selectedFiles,
      folderIdToVaultFolder
    )
    setVaultSource({
      ...vaultSource,
      fileIds: [...vaultSource.fileIds, ...sortedFiles.map((file) => file.id)],
    })
    setShowDialog(false)
  }

  const handleRemoveFile = (file: UploadedFile | FileUploadingState) => {
    if (!vaultSource) return
    const newFiles = activeFiles.filter((f) => f && f.name !== file.name)
    setVaultSource({
      ...vaultSource,
      fileIds: newFiles.map((file) => file.id),
    })
  }

  if (!showDialog) {
    const separator = <span className="mx-1.5 text-xs text-muted/50">•</span>
    return (
      <div className="flex h-full grow flex-col">
        <ScrollArea className="min-h-0 grow">
          <p className="px-6 pt-4 font-medium">Files</p>
          <AssistantFiles
            files={activeFiles as UploadedFile[]}
            handleRemoveFile={handleRemoveFile}
          />
        </ScrollArea>
        <div className="flex shrink-0 items-center justify-between border-t px-4 py-3">
          <div className="flex items-center text-xs">
            <FolderShieldIcon className="mr-2 h-4 w-4 shrink-0" />
            Vault
            {separator}
            {activeProject?.name ?? 'Project'}
            {separator}
            {pluralizeFiles(activeFiles.length)}
          </div>
          <div className="flex items-center space-x-2">
            <Button size="sm" variant="outline" onClick={handleSelectMore}>
              Select more files
            </Button>
            <Button size="sm" variant="outline" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const dialogTitle = activeProject ? 'Add files' : 'Choose project'
  const dialogDescription = activeProject
    ? 'Select files to add to this query for review'
    : 'Select a Vault project to browse files'

  return (
    <div className="flex grow items-center justify-center">
      <p className="text-muted">Selecting files from Vault…</p>
      <Dialog open={showDialog} onOpenChange={handleOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogDescription>{dialogDescription}</DialogDescription>
          </DialogHeader>
          {activeProject ? (
            <>
              <VaultFileExplorer
                isAddingFilesToQuery
                existingSelectedFileIds={activeFileIds}
                projectId={activeProject.id}
              />
              <div className="flex items-center justify-end space-x-2">
                {!activeFiles.length && (
                  <Button variant="outline" onClick={handleResetFolder}>
                    Back
                  </Button>
                )}
                <Button
                  disabled={!selectedFiles.length}
                  onClick={handleAddFiles}
                >
                  Add
                </Button>
              </div>
            </>
          ) : (
            <ProjectChooser
              isFetching={isFetching}
              onSelectProject={handleSelectProject}
              projects={allVaultProjects}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AssistantVaultKnowledgeSource

const ProjectChooser = ({
  isFetching,
  onSelectProject,
  projects,
}: {
  isFetching: boolean
  onSelectProject: (project: VaultFolder) => void
  projects: VaultFolder[]
}) => {
  const sharedProjectIds = useVaultStore((s) => s.sharedProjectIds)

  if (!isFetching && projects.length === 0) {
    return (
      <div className="flex w-full flex-col items-center justify-center">
        <div>No projects found</div>
        <p className="mb-4 mt-2 text-muted">
          You do not have any Vault projects to query over
        </p>
        <Link to="/vault/new">
          <Button>Create a new Vault Project</Button>
        </Link>
      </div>
    )
  }

  const displayProjects = isFetching
    ? [...new Array(2)].map(
        (_, i) => ({ id: `vault-placeholder-${i}` }) as VaultFolder
      )
    : projects

  return (
    <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2">
      {displayProjects.map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          onClick={() => onSelectProject(project)}
          isLoading={isFetching}
          isShared={sharedProjectIds.has(project.id)}
        />
      ))}
    </div>
  )
}

const ProjectCard = ({
  project,
  onClick,
  isLoading,
  isShared,
}: {
  project: VaultFolder
  onClick: () => void
  isLoading?: boolean
  isShared?: boolean
}) => {
  const projectsMetadata = useVaultStore((s) => s.projectsMetadata)
  const projectData = projectsMetadata[project.id]
  const numProjectFiles = projectData?.totalFiles || 0
  const projectSize = bytesToPreciseReadable(
    projectData?.folderSize || 0,
    2,
    true
  )

  const sharingUsersForWorkspace = useSharingStore(
    (s) => s.sharingUsersForWorkspace
  )
  const sharedByUserEmail = sharingUsersForWorkspace.find(
    (user) => user.userId === project.userId
  )?.userEmail

  return (
    <Button
      className="flex h-32 w-full flex-col items-start justify-start p-4"
      disabled={isLoading}
      onClick={onClick}
      variant="outline"
    >
      <div className="text-xs text-muted">
        {isLoading ? (
          <SkeletonBlock className="h-4 w-24" hasDarkBackground />
        ) : (
          `Updated ${backendToReadable(project.updatedAt)}`
        )}
      </div>
      {isLoading ? (
        <SkeletonBlock className="mt-1 h-4 w-48" hasDarkBackground />
      ) : (
        project.name
      )}
      <div className="mt-auto space-y-1">
        {isShared && (
          <div className="flex items-center space-x-1">
            <Icon icon={Users} size="small" className="text-muted" />
            <p className="text-xs text-muted">
              Shared{sharedByUserEmail && `by ${sharedByUserEmail}`}
            </p>
          </div>
        )}
        <div className="flex items-center space-x-1">
          <Icon icon={Folder} size="small" />
          {isLoading ? (
            <SkeletonBlock className="h-4 w-32" hasDarkBackground />
          ) : (
            <p className="text-xs">
              {numProjectFiles === 0
                ? 'No files uploaded'
                : `${pluralizeFiles(numProjectFiles)} (${projectSize})`}
            </p>
          )}
        </div>
      </div>
    </Button>
  )
}
