/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Internal Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PWCTaxFeedbackExportRequest
 */
export interface PWCTaxFeedbackExportRequest {
    /**
     * 
     * @type {string}
     * @memberof PWCTaxFeedbackExportRequest
     */
    taskKind: PWCTaxFeedbackExportRequestTaskKindEnum;
    /**
     * The number of days of recent feedback to include in the export
     * @type {number}
     * @memberof PWCTaxFeedbackExportRequest
     */
    numDays: number;
}


/**
 * @export
 */
export const PWCTaxFeedbackExportRequestTaskKindEnum = {
    HMRC_QA: 'HMRC_QA',
    JAPAN_TAX_QA: 'JAPAN_TAX_QA',
    NETHERLANDS_TAX_QA: 'NETHERLANDS_TAX_QA',
    INDIA_TAX_QA: 'INDIA_TAX_QA',
    AUSTRALIA_TAX_QA: 'AUSTRALIA_TAX_QA',
    IRELAND_TAX_QA: 'IRELAND_TAX_QA',
    SWEDEN_TAX_QA: 'SWEDEN_TAX_QA',
    SWITZERLAND_TAX_QA: 'SWITZERLAND_TAX_QA',
    WWTS_QA: 'WWTS_QA',
    PILLAR_TWO_TAX_QA: 'PILLAR_TWO_TAX_QA'
} as const;
export type PWCTaxFeedbackExportRequestTaskKindEnum = typeof PWCTaxFeedbackExportRequestTaskKindEnum[keyof typeof PWCTaxFeedbackExportRequestTaskKindEnum];


/**
 * Check if a given object implements the PWCTaxFeedbackExportRequest interface.
 */
export function instanceOfPWCTaxFeedbackExportRequest(value: object): value is PWCTaxFeedbackExportRequest {
    if (!('taskKind' in value) || value['taskKind'] === undefined) return false;
    if (!('numDays' in value) || value['numDays'] === undefined) return false;
    return true;
}

export function PWCTaxFeedbackExportRequestFromJSON(json: any): PWCTaxFeedbackExportRequest {
    return PWCTaxFeedbackExportRequestFromJSONTyped(json, false);
}

export function PWCTaxFeedbackExportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PWCTaxFeedbackExportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'taskKind': json['task_kind'],
        'numDays': json['num_days'],
    };
}

export function PWCTaxFeedbackExportRequestToJSON(value?: PWCTaxFeedbackExportRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task_kind': value['taskKind'],
        'num_days': value['numDays'],
    };
}

