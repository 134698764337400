import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { groupBy } from 'lodash'
import { ChevronDown, ChevronUp } from 'lucide-react'
import pluralize from 'pluralize'

import { Source } from 'openapi/models/Source'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'

import { cn } from 'utils/utils'

import { AssistantMessage } from 'components/assistant-v2/types'
import {
  FILE_ID_PARAM,
  MESSAGE_ID_PARAM,
} from 'components/assistant-v2/utils/assistant-helpers'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import Citation from 'components/ui/citation'
import Icon from 'components/ui/icon/icon'
import { SkeletonBlock } from 'components/ui/skeleton'

import {
  AssistantThreadSidebarSection,
  AssistantThreadSidebarSubSection,
} from './assistant-thread-layout'

interface Props {
  className?: string
  isStreaming?: boolean
  documents: UploadedFile[]
  sources: Maybe<Source[]>
  message?: AssistantMessage
}

const AssistantSources = ({
  className,
  isStreaming,
  documents,
  sources,
  message,
}: Props) => {
  const [, setSearchParams] = useSearchParams()

  const handleSetActiveFileId = (fileId: string | undefined) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams)
      if (fileId) {
        newParams.set(FILE_ID_PARAM, fileId)
        if (message) newParams.set(MESSAGE_ID_PARAM, message.messageId)
      } else {
        newParams.delete(FILE_ID_PARAM)
        newParams.delete(MESSAGE_ID_PARAM)
      }
      return newParams
    })
  }

  const sourcesByName = groupBy(sources, 'documentName')
  const hasSources = Object.keys(sourcesByName).length > 0
  const unreferencedDocuments = documents.filter(
    (document) => !sourcesByName[document.name]
  )
  const hasUnreferencedDocuments =
    !isStreaming && unreferencedDocuments.length > 0

  return (
    <AssistantThreadSidebarSection
      className={cn(className, {
        'pb-2': hasUnreferencedDocuments,
      })}
      title="Sources"
    >
      {isStreaming ? (
        <div className="space-y-1 px-2">
          <SkeletonBlock className="h-4 w-4/5" hasDarkBackground />
          <SkeletonBlock className="h-4 w-11/12" hasDarkBackground />
          <SkeletonBlock className="h-4 w-3/5" />
          <SkeletonBlock className="h-4 w-4/5" />
        </div>
      ) : !hasSources ? (
        <p className="p-2 pb-4 text-xs text-muted">
          No sources were cited in this response
        </p>
      ) : (
        Object.keys(sourcesByName).map((documentName) => {
          const sources = sourcesByName[documentName]
          return (
            <AssistantSource
              key={documentName}
              documentName={documentName}
              documentId={
                sources[0].documentId ?? sources[0].documentName ?? ''
              }
              sources={sources}
              handleSetActiveFileId={handleSetActiveFileId}
            />
          )
        })
      )}
      {hasUnreferencedDocuments && (
        <UnreferencedSources
          documents={unreferencedDocuments}
          handleSetActiveFileId={handleSetActiveFileId}
        />
      )}
    </AssistantThreadSidebarSection>
  )
}

export default AssistantSources

const AssistantSource = ({
  documentName,
  documentId,
  sources,
  handleSetActiveFileId,
}: {
  documentName: string
  documentId: string
  sources: Source[]
  handleSetActiveFileId: (fileId: string | undefined) => void
}) => {
  const { trackEvent } = useAnalytics()
  const handleSetActiveFileIdWithAnalytics = (fileId: string | undefined) => {
    trackEvent('Source Clicked', {
      source_id: documentId,
    })
    handleSetActiveFileId(fileId)
  }
  return (
    <button
      className="w-full break-all rounded p-2 text-left text-xs hover:bg-button-secondary"
      onClick={() =>
        handleSetActiveFileIdWithAnalytics(documentId || documentName)
      }
    >
      {documentName}
      {sources.length > 0 && (
        <span className="mt-1 flex flex-wrap gap-1">
          {sources.map((source) => (
            <Citation key={source.footnote}>{source.footnote}</Citation>
          ))}
        </span>
      )}
    </button>
  )
}

const UnreferencedSources = ({
  documents,
  handleSetActiveFileId,
}: {
  documents: UploadedFile[]
  handleSetActiveFileId: (fileId: string | undefined) => void
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => {
    setIsExpanded((prevExpanded) => !prevExpanded)
  }

  return (
    <AssistantThreadSidebarSubSection className="px-0 py-2">
      <Button
        className="group w-full text-left"
        onClick={toggleExpanded}
        variant="ghost"
      >
        <span className="grow text-xs text-secondary">Unreferenced</span>
        <span className="text-xs text-muted">
          {documents.length} {pluralize('source', documents.length)}
        </span>
        <Icon
          icon={isExpanded ? ChevronUp : ChevronDown}
          className="ml-2 text-muted group-hover:text-secondary"
        />
      </Button>
      <BasicTransition
        show={isExpanded}
        entryTransitionDuration="fade"
        exitTransitionDuration="fade"
      >
        {documents.map((document) => (
          <AssistantSource
            key={document.id}
            documentName={document.name}
            documentId={document.id}
            sources={[]}
            handleSetActiveFileId={handleSetActiveFileId}
          />
        ))}
      </BasicTransition>
    </AssistantThreadSidebarSubSection>
  )
}
