import React, { useMemo, useCallback, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { isEmpty, isEqual } from 'lodash'
import { Filter, PlusCircle, X } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'

import { SafeRecord } from 'utils/safe-types'
import { displayErrorMessage } from 'utils/toast'
import { cn, parseIsoString } from 'utils/utils'

import {
  FILTER_CHANGED_EVENT_NAME,
  useAnalytics,
} from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { MultiSelect } from 'components/ui/multi-select'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from 'components/ui/select'
import { FILTER_HEADER_NAMES } from 'components/vault/components/data-grid/vault-query-result-table'
import {
  ColumnDataType,
  fileIdSearchParamKey,
  filtersSearchParamKey,
  QueryQuestions,
  ReviewAnswer,
} from 'components/vault/utils/vault'
import {
  useVaultDataGridFilterStore,
  ColumnFilter,
  FilterModel,
  TypeOperator,
  operatorLabels,
  JoinOperator,
  FilterModelValue,
  FilterType,
} from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const displayDateFormatter = new Intl.DateTimeFormat(navigator.language, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  timeZone: 'UTC',
})

const FilterSelection = ({
  filterId,
  questionIdToColumnHeader,
  applyFilterHandler,
}: {
  filterId: string
  questionIdToColumnHeader: SafeRecord<
    string,
    { header: string; dataType: ColumnDataType }
  >
  applyFilterHandler: ({
    nextFilters,
    dismissDropdown,
  }: {
    nextFilters?: ColumnFilter[]
    dismissDropdown?: boolean
  }) => void
}) => {
  const { trackEvent } = useAnalytics()
  const gridApi = useVaultStore((state) => state.gridApi)
  const filters = useVaultDataGridFilterStore((state) => state.filters)
  const isShowingLongResponses = useVaultDataGridFilterStore(
    (state) => state.isShowingLongResponses
  )
  const currentAppliedFilters = useVaultDataGridFilterStore(
    (state) => state.currentAppliedFilters
  )
  const updateFilter = useVaultDataGridFilterStore(
    (state) => state.updateFilter
  )
  const removeFilter = useVaultDataGridFilterStore(
    (state) => state.removeFilter
  )

  const currentFilter = filters.find((f) => f.id === filterId)
  const showEqualsOption = !isShowingLongResponses
  const columnHeaderNames = [
    ...FILTER_HEADER_NAMES,
    ...(gridApi?.getColumnDefs()?.map((column) => column.headerName) || []),
  ]

  const uniqueColumnValues = useMemo(() => {
    if (!gridApi) return []

    const currentFilterQuestionId = currentFilter?.questionId as string
    const isDateColumn =
      questionIdToColumnHeader[currentFilterQuestionId]?.dataType ===
      ColumnDataType.date

    if (isDateColumn) {
      const uniqueDatesSet = new Set<number>()
      gridApi.forEachNode((node) => {
        // when grouping by folder, we want to ignore the rows that are the group rows
        // because they do not have any unique value
        if (node.group) return
        const answer = (node.data.answers as ReviewAnswer[]).find(
          (answer) => answer.columnId === currentFilterQuestionId
        )
        if (answer != null) {
          const utcDate = parseIsoString(answer.text)
          if (isNaN(utcDate.getTime()) || utcDate.getTime() === 0) {
            // If the date is invalid, skip it
            return
          }
          uniqueDatesSet.add(utcDate.getTime())
        }
      })
      return Array.from(uniqueDatesSet)
        .sort((a, b) => a - b)
        .map((date) => displayDateFormatter.format(new Date(date)))
    } else {
      // we are only going to support unique file names for non long columns
      const uniqueValuesSet = new Set<string>()
      gridApi.forEachNode((node) => {
        // when grouping by folder, we want to ignore the rows that are the group rows
        // because they do not have any unique value
        if (node.group) return
        const value = node.data[currentFilterQuestionId]
        if (value != null) uniqueValuesSet.add(value.toString())
      })
      return Array.from(uniqueValuesSet).sort((a, b) => a.localeCompare(b))
    }
  }, [currentFilter, gridApi, questionIdToColumnHeader])

  const onColumnChange = (questionId: string) => {
    updateFilter(filterId, {
      headerName: questionIdToColumnHeader[questionId]?.header,
      questionId: questionId,
      value: [],
    })
  }

  const operatorChangeHandler = (newValue: TypeOperator) => {
    updateFilter(filterId, {
      operator: newValue,
      value: [],
    })
  }

  const setSelectedValuesHandler = (selectedValues: string[]) => {
    trackEvent(FILTER_CHANGED_EVENT_NAME, {
      entity_name: 'vault review query table',
      field_changed: currentFilter?.headerName,
    })
    updateFilter(filterId, {
      value: selectedValues,
    })
  }

  const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    const newValue = typeof e === 'string' ? e : e.target.value
    updateFilter(filterId, {
      value: [newValue],
    })
  }

  const removeFilterHandler = () => {
    const nextFilters = filters.filter((f) => f.id !== filterId)
    const currentAppliedFilterIds = currentAppliedFilters.map((f) => f.id)

    if (currentAppliedFilterIds.includes(filterId)) {
      applyFilterHandler({ nextFilters, dismissDropdown: false })
    }
    removeFilter(filterId)
  }

  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-wrap items-center gap-2">
        <Select
          onValueChange={onColumnChange}
          value={currentFilter?.questionId ?? undefined}
        >
          <SelectTrigger className="h-8 min-w-48">
            <SelectValue placeholder="Select column" />
          </SelectTrigger>
          <SelectContent>
            {Object.keys(questionIdToColumnHeader)
              .sort(
                (a, b) =>
                  columnHeaderNames.indexOf(
                    questionIdToColumnHeader[a]?.header ?? ''
                  ) -
                  columnHeaderNames.indexOf(
                    questionIdToColumnHeader[b]?.header ?? ''
                  )
              )
              .map((questionId) => (
                <SelectItem
                  key={questionId}
                  value={questionId}
                  className="max-w-96 hover:cursor-pointer hover:bg-secondary-hover"
                >
                  <p className="truncate">
                    {questionIdToColumnHeader[questionId]?.header ?? ''}
                  </p>
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
        <Select
          onValueChange={operatorChangeHandler}
          value={currentFilter?.operator}
          disabled={currentFilter?.questionId === undefined}
        >
          <SelectTrigger className="h-8 min-w-40">
            <SelectValue placeholder="Contains" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={TypeOperator.CONTAINS}>
              {operatorLabels[TypeOperator.CONTAINS]}
            </SelectItem>
            {showEqualsOption && (
              <SelectItem value={TypeOperator.EQUALS}>
                {operatorLabels[TypeOperator.EQUALS]}
              </SelectItem>
            )}
            {showEqualsOption && (
              <SelectItem value={TypeOperator.NOT_EQUALS}>
                {operatorLabels[TypeOperator.NOT_EQUALS]}
              </SelectItem>
            )}
          </SelectContent>
        </Select>
        {currentFilter?.operator === TypeOperator.EQUALS ||
        currentFilter?.operator === TypeOperator.NOT_EQUALS ? (
          <MultiSelect
            placeholder="Select values"
            selectedValues={currentFilter.value}
            setSelectedValues={setSelectedValuesHandler}
            sortedEntries={uniqueColumnValues.map((value) => ({
              text: value,
              value: value,
            }))}
            className="w-[320px]"
            disabled={currentFilter.questionId === undefined}
          />
        ) : (
          <Input
            placeholder="Enter contains value"
            className="h-8 w-[320px]"
            value={currentFilter?.value || ''}
            onChange={inputChangeHandler}
          />
        )}
      </div>
      <Button
        size="xsIcon"
        variant="ghost"
        onClick={removeFilterHandler}
        className="shrink-0"
      >
        <Icon icon={X} size="small" />
      </Button>
    </div>
  )
}

const FilterPopoverContent = ({
  applyFilterHandler,
  columnIdToHeader,
  columnIdToDataType,
}: {
  applyFilterHandler: ({
    nextFilters,
    dismissDropdown,
  }: {
    nextFilters?: ColumnFilter[]
    dismissDropdown?: boolean
  }) => void
  columnIdToHeader: (columnId: string, question: QueryQuestions) => string
  columnIdToDataType: (columnId: string) => ColumnDataType
}) => {
  const { queryId } = useParams()

  const queryIdToReviewState = useVaultStore(
    (state) => state.queryIdToReviewState
  )
  const filters = useVaultDataGridFilterStore((state) => state.filters)
  const addFilter = useVaultDataGridFilterStore((state) => state.addFilter)

  const shouldDisableFilterActions =
    filters.some((f) => !f.headerName) ||
    filters.some((f) => f.value.length === 0)

  const questionIdToColumnHeader = useMemo(() => {
    const reviewQueryQuestions =
      (queryId && queryIdToReviewState[queryId]?.questions) || []

    const questionIdToColumnHeader: Map<
      string,
      { header: string; dataType: ColumnDataType }
    > = new Map()
    reviewQueryQuestions.forEach((question: QueryQuestions) => {
      questionIdToColumnHeader.set(question.id as string, {
        header: columnIdToHeader(question.id, question),
        dataType: columnIdToDataType(question.id),
      })
    })

    // add filter header names (e.g. Name column) that not have a question id
    FILTER_HEADER_NAMES.forEach((headerName) => {
      questionIdToColumnHeader.set(headerName.toLocaleLowerCase(), {
        header: headerName,
        dataType: ColumnDataType.string,
      })
    })
    return Object.fromEntries(questionIdToColumnHeader)
  }, [queryIdToReviewState, queryId, columnIdToHeader, columnIdToDataType])

  const addFilterHandler = () => {
    addFilter()
  }

  return (
    <div>
      <div className="flex flex-1 flex-col">
        <div className="space-y-2 px-4 py-3">
          <p className="text-xs font-semibold">Filter by Column</p>

          {filters.map((filter: ColumnFilter) => {
            return (
              <FilterSelection
                key={filter.id}
                filterId={filter.id}
                questionIdToColumnHeader={questionIdToColumnHeader}
                applyFilterHandler={applyFilterHandler}
              />
            )
          })}
        </div>
      </div>
      <div className="flex items-center justify-between border-t px-4 py-3">
        <Button
          variant="outline"
          size="sm"
          disabled={
            shouldDisableFilterActions ||
            filters.length === Object.keys(questionIdToColumnHeader).length
          }
          onClick={addFilterHandler}
        >
          <PlusCircle className="mr-1 h-3 w-3" />
          Add filter
        </Button>
        <Button
          size="sm"
          disabled={shouldDisableFilterActions}
          onClick={() => applyFilterHandler({ dismissDropdown: true })}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

const FilterDropdown = ({
  clearFilters,
  columnIdToHeader,
  columnIdToDataType,
}: {
  clearFilters: () => void
  columnIdToHeader: (columnId: string, question: QueryQuestions) => string
  columnIdToDataType: (columnId: string) => ColumnDataType
}) => {
  const { queryId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const queryParamFilters = searchParams.get(filtersSearchParamKey)

  const gridApi = useVaultStore((s) => s.gridApi)
  const queryIdToState = useVaultStore((state) => state.queryIdToState)
  const queryIdToReviewState = useVaultStore(
    (state) => state.queryIdToReviewState
  )
  const filters = useVaultDataGridFilterStore((s) => s.filters)
  const hasRowDataApplied = useVaultDataGridFilterStore(
    (s) => s.hasRowDataApplied
  )
  const currentAppliedFilters = useVaultDataGridFilterStore(
    (s) => s.currentAppliedFilters
  )
  const isFilterDropdownOpen = useVaultDataGridFilterStore(
    (s) => s.isFilterDropdownOpen
  )
  const setIsFilterDropdownOpen = useVaultDataGridFilterStore(
    (s) => s.setIsFilterDropdownOpen
  )
  const setFilters = useVaultDataGridFilterStore((s) => s.setFilters)
  const resetFilterState = useVaultDataGridFilterStore(
    (s) => s.resetFilterState
  )
  const setCurrentAppliedFilters = useVaultDataGridFilterStore(
    (s) => s.setCurrentAppliedFilters
  )

  const isDropdownDisabled =
    !gridApi ||
    !queryId ||
    isEmpty(queryIdToState[queryId]) ||
    queryIdToState[queryId]?.isLoading ||
    !isEmpty(fileId)

  const onDropdownOpenChangeHandler = (open: boolean) => {
    setIsFilterDropdownOpen(open)
    // if the dropdown is closed, we want to set the filters to the current applied filters
    // so the view of the data-grid matches the applied filters
    if (!open) {
      if (currentAppliedFilters.length > 0) {
        setFilters(currentAppliedFilters)
      } else {
        resetFilterState()
      }
    }
  }

  const applyFilterHandler = useCallback(
    ({
      nextFilters,
      dismissDropdown,
      updateSearchParams = true,
    }: {
      nextFilters?: ColumnFilter[]
      dismissDropdown?: boolean
      updateSearchParams?: boolean
    }) => {
      if (!gridApi) return
      if (filters.length === 0) {
        clearFilters()
        return
      }
      const filtersToApply = nextFilters ?? filters

      const filterModel: FilterModel = {}
      filtersToApply.forEach((filter) => {
        const filterType =
          queryIdToReviewState[queryId!]?.columnHeaders.find(
            (columnHeader) => columnHeader.id === filter.questionId
          )?.columnDataType === ColumnDataType.date
            ? FilterType.DATE
            : FilterType.TEXT
        if (!filter.questionId || !Array.isArray(filter.value)) return
        if (filter.value.length > 1) {
          const conditions = filter.value.map(
            (selectedValue) =>
              ({
                filterType,
                type: filter.operator,
                filter: selectedValue,
              }) as FilterModelValue
          )
          filterModel[filter.questionId] = {
            filterType,
            operator:
              filter.operator === TypeOperator.EQUALS
                ? JoinOperator.OR
                : JoinOperator.AND,
            conditions: conditions,
          }
        } else {
          filterModel[filter.questionId] = {
            filterType,
            type: filter.operator,
            filter: filter.value[0],
          }
        }
      })

      setCurrentAppliedFilters(filtersToApply)
      gridApi.setFilterModel(filterModel)

      if (dismissDropdown) {
        setIsFilterDropdownOpen(false)
      }
      if (updateSearchParams) {
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)

          const filtersToApplyStripped = filtersToApply.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({ id, ...rest }) => rest
          )
          if (filtersToApplyStripped.length > 0) {
            newParams.set(
              filtersSearchParamKey,
              JSON.stringify(filtersToApplyStripped)
            )
          } else {
            newParams.delete(filtersSearchParamKey)
          }
          return newParams
        })
      }
    },
    [
      gridApi,
      filters,
      clearFilters,
      setIsFilterDropdownOpen,
      setCurrentAppliedFilters,
      setSearchParams,
      queryIdToReviewState,
      queryId,
    ]
  )

  useEffect(() => {
    if (!gridApi || !hasRowDataApplied) return
    try {
      const parsedQueryParamFilters = JSON.parse(queryParamFilters || '[]')
      const parsedCurrentAppliedFilters = currentAppliedFilters.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ id, ...rest }) => rest
      )
      if (!isEqual(parsedQueryParamFilters, parsedCurrentAppliedFilters)) {
        const enhancedQueryParamFilters = parsedQueryParamFilters.map(
          (filter: ColumnFilter) => ({
            ...filter,
            id: uuidv4(), // Assign a new UUID to each filter object
          })
        )
        if (enhancedQueryParamFilters.length > 0) {
          setFilters(enhancedQueryParamFilters)
        } else {
          resetFilterState()
        }
        applyFilterHandler({
          nextFilters: enhancedQueryParamFilters,
          updateSearchParams: false,
        })
      }
    } catch (e) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        newParams.delete(filtersSearchParamKey)
        return newParams
      })
      displayErrorMessage(
        'There was an error applying the filters. Please try again.',
        5
      )
    }
  }, [
    gridApi,
    hasRowDataApplied,
    queryParamFilters,
    currentAppliedFilters,
    applyFilterHandler,
    setSearchParams,
    setFilters,
    resetFilterState,
  ])

  return (
    <DropdownMenu
      open={isFilterDropdownOpen}
      onOpenChange={onDropdownOpenChangeHandler}
    >
      <DropdownMenuTrigger
        asChild
        disabled={isDropdownDisabled}
        className={cn('data-[state=open]:bg', {
          'cursor-not-allowed': isDropdownDisabled,
        })}
      >
        <Button className="flex items-center gap-1" variant="outline" size="sm">
          <Icon icon={Filter} size="small" />
          <p className="text-xs">Filter</p>
          {currentAppliedFilters.length > 0 && (
            <Badge
              variant="secondary"
              className="flex h-4 w-4 items-center justify-center p-0"
            >
              {currentAppliedFilters.length}
            </Badge>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="mr-2 max-w-[calc(100vw-1rem)]"
        align="start"
      >
        <FilterPopoverContent
          applyFilterHandler={applyFilterHandler}
          columnIdToHeader={columnIdToHeader}
          columnIdToDataType={columnIdToDataType}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default FilterDropdown
