/* eslint-disable */
import React, { useState } from 'react'

import * as _ from 'lodash'
import { ChevronDown, ChevronUp } from 'lucide-react'

import { ResearchFilter } from 'openapi/models/ResearchFilter'
import { TaxCorpusFilterIDs } from 'openapi/models/TaxCorpusFilterIDs'
import Services from 'services'

import { useAuthUser } from 'components/common/auth-context'
import FeedbackSubmittedBanner from 'components/common/feedback-submitted-banner'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import * as Card from 'components/ui/card'
import { Label } from 'components/ui/label'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { Textarea } from 'components/ui/text-area'

import { anyNodeHasL0Ancestor } from '../research-helpers'

interface FeedbackTaxMeta {
  comments: string
  helpfulness:
    | 'fully_helpful'
    | 'very_helpful'
    | 'partially_helpful'
    | 'not_helpful'
    | null
  positiveReferences: string
}

export interface FeedbackTaxProps {
  queryId: string
  selectedFilters: ResearchFilter[]
  allFilterNodes: ResearchFilter[]
}

const FeedbackTax: React.FC<FeedbackTaxProps> = ({
  queryId,
  selectedFilters,
  allFilterNodes,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [meta, setMeta] = useState<FeedbackTaxMeta>({
    comments: '',
    helpfulness: null,
    positiveReferences: '',
  })
  const userInfo = useAuthUser()
  const options = [
    {
      key: 'fully_helpful',
      text: 'Fully helpful - The answer fully answered my question.',
      onClick: () => setMeta({ ...meta, helpfulness: 'fully_helpful' }),
    },
    {
      key: 'very_helpful',
      text: 'Very helpful - The answer saved me significant time with a comprehensive starting point.',
      onClick: () => setMeta({ ...meta, helpfulness: 'very_helpful' }),
    },
    {
      key: 'partially_helpful',
      text: 'Partially helpful - The answer saved me some time with useful information to get started.',
      onClick: () => setMeta({ ...meta, helpfulness: 'partially_helpful' }),
    },
    {
      key: 'not_helpful',
      text: 'Not helpful - The answer was not helpful.',
      onClick: () => setMeta({ ...meta, helpfulness: 'not_helpful' }),
    },
  ]

  const handleSubmit = async (): Promise<void> => {
    const sentiment =
      meta.helpfulness !== 'not_helpful' && meta.helpfulness !== null ? 1 : -1
    const { comments, ...feedbackMeta } = meta
    const data = {
      sentiment,
      comments,
      feedbackMeta,
    }

    setIsSubmitted(true)
    await Services.Backend.Post<any>(`feedback/${queryId}`, data, {
      throwOnError: true,
    })
      .then((res) => res !== true && setError(true))
      .catch(() => setError(true))
  }

  const collapse = () => {
    setIsExpanded((prev) => !prev)
    setMeta({
      comments: '',
      helpfulness: null,
      positiveReferences: '',
    })
  }
  // don't show more extensive feedback options to downstream clients of PWC
  const isInternalOrPwcUser = userInfo.IsInternalUser || userInfo.IsPwcUser
  // TODO: UI would change if the user changes their selected filters, so we should freeze the filters that were submitted with the query
  const isUkTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.UK_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isJapanTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.JAPAN_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isAustraliaTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.AUSTRALIA_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isPillarTwoTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.PILLAR_TWO_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isNetherlandsTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.NETHERLANDS_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isWWTSTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.WWTS,
    selectedFilters,
    allFilterNodes
  )
  const isIndiaTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.INDIA_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isIrelandTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.IRELAND_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isSwitzerlandTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.SWITZERLAND_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isSwedenTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.SWEDEN_TAX,
    selectedFilters,
    allFilterNodes
  )

  // KEEP THIS IN SYNC with RETAIN_FOREVER_FOR_PWC in backend. We can only accept written feedback if we're actually storing the data.
  const canStoreWrittenFeedback =
    isJapanTaxFilterSelected ||
    isNetherlandsTaxFilterSelected ||
    isWWTSTaxFilterSelected ||
    isIndiaTaxFilterSelected ||
    isIrelandTaxFilterSelected ||
    isSwitzerlandTaxFilterSelected ||
    isSwedenTaxFilterSelected
  return (
    <Card.Card
      className="relative z-50 space-y-2 p-4 transition"
      data-testid="input-panel--tax-feedback-card"
    >
      {!isSubmitted && (
        <>
          <div
            className="flex w-full cursor-pointer items-center justify-between"
            onClick={collapse}
          >
            <h3 className="text-sm">What did you think of the response?</h3>
            <Button
              variant="ghost"
              size="xsIcon"
              data-testid="tax-expand-feedback-btn"
            >
              {isExpanded ? (
                <ChevronUp className="inline-block h-4 w-4" />
              ) : (
                <ChevronDown className="inline-block h-4 w-4" />
              )}
            </Button>
          </div>
          <BasicTransition
            className="flex flex-col space-y-2"
            show={isExpanded}
          >
            <div data-testid="tax-feedback-checkbox">
              <RadioGroup>
                {options.map(({ key, text, onClick }) => (
                  <RadioGroupItem
                    className="h-6 space-x-2"
                    key={key}
                    id={key}
                    label={text}
                    value={key}
                    checked={meta.helpfulness === key}
                    onClick={onClick}
                  />
                ))}
              </RadioGroup>
            </div>

            {canStoreWrittenFeedback && (
              <div className="flex flex-col space-y-2">
                <Label htmlFor="feedback-explain" className="font-normal">
                  Explain why you chose your answer
                </Label>
                <Textarea
                  id="feedback-explain"
                  placeholder="Explain why you chose your answer"
                  onChange={(e) => {
                    setMeta({ ...meta, comments: e.target.value })
                  }}
                />
              </div>
            )}

            {canStoreWrittenFeedback && (
              <div className="flex flex-col space-y-2">
                <Label
                  htmlFor="feedback-positive-references"
                  className="font-normal"
                >
                  If there are any references you would have expected to see in
                  your answer, please paste the links here.
                </Label>
                <Textarea
                  id="feedback-positive-references"
                  placeholder="Please paste links to the relevant sources, one per line."
                  onChange={(e) => {
                    setMeta({ ...meta, positiveReferences: e.target.value })
                  }}
                />
              </div>
            )}

            {isUkTaxFilterSelected && isInternalOrPwcUser && (
              <p className="h-6 text-sm">
                If you would like to provide more detailed feedback or suggest
                new tax sources, please use this{' '}
                <a
                  href="https://forms.gle/BTp3deRDApHcwRRBA"
                  className="text-sm text-blue-600"
                  rel="noreferrer"
                  target="_blank"
                >
                  Google form
                </a>
                .
              </p>
            )}
            {isAustraliaTaxFilterSelected && isInternalOrPwcUser && (
              <p className="h-6 text-sm">
                If you would like to provide more detailed feedback or suggest
                new tax sources, please use{' '}
                <a
                  href="https://forms.office.com/r/9FpSdQR7Ev"
                  className="text-sm text-blue-600"
                  rel="noreferrer"
                  target="_blank"
                >
                  this form
                </a>
                .
              </p>
            )}
            {isPillarTwoTaxFilterSelected && isInternalOrPwcUser && (
              <p className="h-6 text-sm">
                If you would like to provide more detailed feedback or suggest
                new tax sources, please use{' '}
                <a
                  href="https://forms.office.com/pages/responsepage.aspx?id=oJQyUSA-skGpcG0wvxVG-qed4MlCKIlOouGIdRbDGHNUM0RVTFRPV0JMRk43MkhHUjRIMjRTVVhCNCQlQCN0PWcu&route=shorturl"
                  className="text-sm text-blue-600"
                  rel="noreferrer"
                  target="_blank"
                >
                  this form
                </a>
                .
              </p>
            )}
            <div className="flex w-full justify-end">
              <Button
                onClick={() => {
                  void handleSubmit()
                }}
              >
                Submit
              </Button>
            </div>
          </BasicTransition>
        </>
      )}
      {isSubmitted && <FeedbackSubmittedBanner error={error} />}
    </Card.Card>
  )
}

export default FeedbackTax
