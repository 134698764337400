import React, { useEffect, useMemo, useState } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'

import { HistoryTypeEnum } from 'models/helpers/history-helper'
import { AuditLogType } from 'openapi/models/AuditLogType'
import Services from 'services'

import { AUDIT_LOG_DEBOUNCE_TIME, usePostAuditLog } from 'utils/audit-log'

import { AppHeader } from 'components/common/app-header'
import useWorkspacesForUser from 'components/settings/hooks/use-workspaces-for-user'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import { Button } from 'components/ui/button'

import HistoryBulkDeleteDialog from './history-bulk-delete-dialog'
import HistoryFilterRow from './history-filter-row'
import HistoryStatus from './history-status'
import { useHistoryStore } from './history-store'
import HistoryTable from './history-table'
import { useWorkspaceHistoryMetadataStore } from './workspace-history-metadata-store'

const WorkspaceHistory = (): JSX.Element => {
  const settingsUser = useSettingsState((s) => s.settingsUser)
  const { workspaces: allWorkspaces } = useWorkspacesForUser()

  const selectedEventIds = useHistoryStore((s) => s.selectedEventIds)
  const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false)

  const workspaces = useMemo(
    () =>
      allWorkspaces.some((workspace) => workspace.couldHistory)
        ? allWorkspaces.filter((workspace) => workspace.couldHistory)
        : allWorkspaces,
    [allWorkspaces]
  )

  const workspace = settingsUser?.workspace ?? workspaces[0]

  useEffect(() => {
    const fetchData = async () => {
      if (_.isEmpty(workspace)) return
      await useWorkspaceHistoryMetadataStore.getState().fetchData(workspace)
    }
    void fetchData()
  }, [workspace])

  const { postAuditLog } = usePostAuditLog()

  useEffect(() => {
    const timer = setTimeout(() => {
      void (async () => {
        try {
          await postAuditLog(
            AuditLogType.ADMINCLIENT_VIEW_WORKSPACE_HISTORY,
            workspace.id,
            {}
          )
        } catch (error) {
          console.error('Error posting audit log:', error)
        }
      })()
    }, AUDIT_LOG_DEBOUNCE_TIME) // Delay the log posting by 1 second

    // Cleanup function to clear the timeout if the component unmounts before the timeout is reached
    return () => clearTimeout(timer)
  }, [postAuditLog, workspace])

  if (_.isNil(settingsUser) || !settingsUser.IsHistoryUser) {
    return (
      <SettingsError
        description={`You don't have access to workspace history for ${settingsUser?.workspace.clientName}`}
      />
    )
  }

  const canBulkDeleteHistory = settingsUser.IsDeleteWorkspaceHistoryBulkUser
  const showBulkDelete = canBulkDeleteHistory && selectedEventIds.length > 0

  const handleDeleteSelectedRowsClick = () => {
    setBulkDeleteModalOpen(true)
    Services.HoneyComb.Record({
      metric: 'ui.history_bulk_delete_events_dialog_opened',
      events_count: selectedEventIds.length,
      type: HistoryTypeEnum.WORKSPACE,
      workspace_slug: workspace.slug,
    })
  }

  const BulkDeleteButton = () => {
    return (
      <Button
        disabled={selectedEventIds.length === 0}
        type="submit"
        variant="destructive"
        className="hover:bg-destructive-foreground/50"
        onClick={handleDeleteSelectedRowsClick}
      >
        {`Delete ${selectedEventIds.length} ${pluralize(
          'event',
          selectedEventIds.length
        )}`}
      </Button>
    )
  }

  return (
    <>
      {canBulkDeleteHistory && (
        <HistoryBulkDeleteDialog
          modalOpen={bulkDeleteModalOpen}
          setModalOpen={setBulkDeleteModalOpen}
        />
      )}
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
        actions={
          <HistoryStatus
            type={HistoryTypeEnum.WORKSPACE}
            workspace={workspace}
          />
        }
      />
      <SettingsLayout>
        <div className="space-y-4" data-testid="workspace-history-container">
          <div className="flex justify-between">
            {showBulkDelete && <BulkDeleteButton />}
            <div className="grow justify-end">
              <HistoryFilterRow
                type={HistoryTypeEnum.WORKSPACE}
                workspace={workspace}
              />
            </div>
          </div>
          <HistoryTable
            type={HistoryTypeEnum.WORKSPACE}
            workspace={workspace}
          />
        </div>
      </SettingsLayout>
    </>
  )
}

export default WorkspaceHistory
