// Functions to interact with backend for examples model
import { Event } from 'models/event'
import { filterExamples } from 'models/helpers/examples-helper'
import Services from 'services'

import { getCleanedEvent } from 'utils/task-definitions'

// This fuctions fetches examples from backend, then uses a helper to filter them
async function FetchExamples(
  taskLabelLookup: Record<string, any>
): Promise<Event[]> {
  const result = await Services.Backend.Get<any>('examples')
  const _events =
    result?.events?.map((exampleEvent: Event) => {
      return getCleanedEvent(exampleEvent)
    }) ?? []
  const events = filterExamples(taskLabelLookup, _events)
  return events
}

// exports
export { FetchExamples }
