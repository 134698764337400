import React from 'react'

import { ResearchFilter } from 'openapi/models/ResearchFilter'

import ExplorerItem from 'components/research/explorer-item'
import { ScrollArea } from 'components/ui/scroll-area'

interface ExplorerColumnProps {
  taxonomy: ResearchFilter[]
  filters: ResearchFilter[]
  depth: number
}

const ExplorerColumn: React.FC<ExplorerColumnProps> = ({
  taxonomy,
  filters,
  depth,
}) => {
  return (
    <div className="w-72 min-w-72 border-r">
      <ScrollArea className="h-56">
        <div className="h-2" />
        {filters.map((filter) => (
          <ExplorerItem
            taxonomy={taxonomy}
            filter={filter}
            key={filter.id}
            depth={depth}
          />
        ))}
        <div className="h-2" />
      </ScrollArea>
    </div>
  )
}

export default ExplorerColumn
