import React from 'react'

import Services from 'services'

import { cn } from 'utils/utils'

import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { SearchRecord } from 'components/filter/types/search-record'
import { MultiSelect } from 'components/ui/multi-select'

const CategoryFilter = (props: {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  sortedCategories?: { text: string; value: string }[]
}) => {
  const { filterKey, setValue } = props
  const selectedCategories = props.value?.split(',') || []
  const sortedCategories = props.sortedCategories || []
  const { trackEvent } = useAnalytics()

  const render: React.FC = () => (
    <div className="relative" key={filterKey}>
      <MultiSelect
        placeholder="Select"
        sortedEntries={sortedCategories}
        selectedValues={selectedCategories}
        setSelectedValues={(selectedValues) => {
          setValue(selectedValues.join(','))
          Services.HoneyComb.Record({
            metric: 'ui.library_category_filter_change',
            categories: selectedValues.join(','),
          })
          trackEvent(FILTER_CHANGED_EVENT_NAME, {
            entity_name: 'library table',
            field_changed: 'category',
            categories: selectedValues.join(','),
          })
        }}
        className={cn('w-40', {
          'text-muted': selectedCategories.length === 0,
        })}
        align="end"
      />
    </div>
  )

  const filterLogic = (record: SearchRecord) => {
    if (selectedCategories.length === 0) {
      return true
    }

    return selectedCategories.includes(record.category ?? '')
  }

  return { filterKey, displayName: 'Category', render, filterLogic }
}

export default CategoryFilter
