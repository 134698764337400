import React from 'react'

import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import PWCUserAdd from 'components/settings/pwc/pwc-user-add'
import RoleInspector from 'components/settings/roles/role-inspector'
import SettingsLayout from 'components/settings/settings-layout'

import MultiUserAddRemove from './multi-user-add-remove'
import SettingsBulkManagePerms from './settings-bulk-manage-permissions'
import SettingsBulkMoveUsers from './settings-bulk-move-users'
import SettingsUserPermissionsCard from './settings-user-pemissions-card'

const SettingsUserManagement: React.FC = () => {
  const userInfo = useAuthUser()

  return (
    <>
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
        isInternalAdmin
      />
      <SettingsLayout>
        <div>
          <SettingsUserPermissionsCard />
        </div>
        <div className="mt-4">
          <RoleInspector />
        </div>
        {userInfo.isUserManagement && (
          <div className="space-y-4">
            <div className="my-4">
              <SettingsBulkManagePerms type="grant" />
            </div>
            <div className="my-4">
              <SettingsBulkManagePerms type="revoke" />
            </div>
            {userInfo.IsInternalAdminWriter && (
              <>
                <div>
                  <MultiUserAddRemove
                    type="remove"
                    collapsible
                    accordionTitle="Bulk remove users"
                    displayDownloadButton
                    maxNumUsers={500}
                  />
                </div>
                <div>
                  <SettingsBulkMoveUsers />
                </div>
                <div>
                  <PWCUserAdd />
                </div>
              </>
            )}
          </div>
        )}
      </SettingsLayout>
    </>
  )
}

export default SettingsUserManagement
