import { DestinationLanguageCode } from 'openapi/models/DestinationLanguageCode'
import { Language } from 'openapi/models/Language'

// TODO: should we make this a map instead of an array?
export const TRANSLATION_OPTIONS: Language[] = [
  { code: DestinationLanguageCode.DA, value: 'Danish', label: 'Danish' },
  { code: DestinationLanguageCode.NL, label: 'Dutch', value: 'Dutch' },
  { code: DestinationLanguageCode.EN, label: 'English', value: 'English' },
  { code: DestinationLanguageCode.FR, label: 'French', value: 'French' },
  { code: DestinationLanguageCode.DE, label: 'German', value: 'German' },
  { code: DestinationLanguageCode.IT, label: 'Italian', value: 'Italian' },
  {
    code: DestinationLanguageCode.PT_BR,
    label: 'Portuguese (Brazil)',
    value: 'Brazilian Portuguese',
  },
  {
    code: DestinationLanguageCode.PT_PT,
    label: 'Portuguese (Portugal)',
    value: 'European Portuguese',
  },
  {
    code: DestinationLanguageCode.ZH_CN,
    label: 'Simplified Chinese',
    value: 'Simplified Chinese',
  },
  { code: DestinationLanguageCode.ES, label: 'Spanish', value: 'Spanish' },
  { code: DestinationLanguageCode.MS, label: 'Malay', value: 'Malay' },
]

export function labelFromCode(code: string): string {
  const language = TRANSLATION_OPTIONS.find((lang) => lang.code === code)
  if (!language) {
    throw new Error(`No matching label found for code: ${code}`)
  }
  return language.label
}
