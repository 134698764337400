import React, { useCallback, useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { X } from 'lucide-react'
import { Instance } from 'pspdfkit'

import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { navigateToPage } from 'components/assistant/pspdfkit-helpers'
import PdfDocumentHeader from 'components/common/pdf-viewer/pdf-document-header'
import { PdfViewerPushSheet } from 'components/common/pdf-viewer/pdf-viewer-push-sheet'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Spinner } from 'components/ui/spinner'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'

export const PdfDrawer: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [instance, isAnnotating] = usePDFViewerStore((s) => [
    s.instance,
    s.isAnnotating,
  ])

  const setViewingDocument = useDiligenceStore(
    (state) => state.setViewingDocument
  )
  const viewingDocument = useDiligenceStore((state) => state.viewingDocument)

  const applyAnnotations = useCallback(
    async (instance: Instance) => {
      if (!viewingDocument) {
        return
      }

      void navigateToPage(viewingDocument.source.page, instance)
    },
    [viewingDocument]
  )

  useEffect(() => {
    if (instance) {
      void applyAnnotations(instance)
    }
  }, [instance, applyAnnotations])

  useHotkeys(
    'esc',
    () => {
      setViewingDocument(null)
    },
    {
      enabled: !!viewingDocument,
    },
    [viewingDocument, setViewingDocument]
  )

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-start justify-between border-b p-4">
        <PdfDocumentHeader
          name={viewingDocument?.document.file.name}
          size={viewingDocument?.document.file.size}
        />
        <Button
          className="ml-auto shrink-0"
          onClick={() => setViewingDocument(null)}
          size="xsIcon"
          variant="ghost"
        >
          <Icon icon={X} />
        </Button>
      </div>
      <div className="flex grow flex-col justify-center">
        <div className="relative h-full w-full">
          {isAnnotating && (
            <div
              className="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-white bg-opacity-75"
              style={{ pointerEvents: 'none' }}
            >
              <Spinner />
            </div>
          )}
          {viewingDocument && (
            <PdfViewerPushSheet
              document={viewingDocument.document.file}
              containerRef={containerRef}
            />
          )}
        </div>
      </div>
    </div>
  )
}
