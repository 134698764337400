import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'

import {
  ClientMatterWithStats,
  ClientMattersSettingsStore,
} from 'models/client-matters'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useClientMattersQuery } from 'models/queries/use-client-matters-query'
import Services from 'services'
import { Maybe } from 'types'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import Combobox from 'components/ui/combobox/combobox'

import {
  CLIENT_MATTER_SELECTED_METRIC,
  CLIENT_MATTER_SELECT_ADD_METRIC,
  CLIENT_MATTER_URL_PARAM,
  MAX_CLIENT_MATTER_NAME_LENGTH,
  handleAddClientMatter,
  mergeAddedClientMatters,
} from './client-matter-utils'
import { ClientMatter, useClientMattersStore } from './client-matters-store'

interface ClientMatterSelectProps {
  className?: string
  providedClientMatters?: ClientMatter[]
  selectedValue?: string
  setSelectedValue?: (value: string) => void
  allowAddNewItem?: boolean
  containerRef?: React.RefObject<HTMLDivElement>
  align?: 'start' | 'end'
  showClearOption?: boolean
}

const ClientMatterSelect: React.FC<ClientMatterSelectProps> = ({
  className,
  providedClientMatters,
  selectedValue,
  setSelectedValue,
  allowAddNewItem = true,
  containerRef,
  align = 'start',
  showClearOption = true,
}) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()

  const {
    clientMatters: clientMattersFromStore,
    selectedClientMatter,
    clientMatterSelectDisabled,
    setClientMatters,
    setSelectedClientMatter,
    setShouldCmLockQueries,
    setCanUsersManageCm,
    canCmUserManage,
  } = useClientMattersStore()

  const { cmData, isCmPending } = useClientMattersQuery()

  useEffect(() => {
    if (!_.isEmpty(cmData.clientMatters)) {
      setClientMatters(cmData.clientMatters)
    }
    if (!_.isNil(userInfo.workspace.settings.clientMatterQueryLock)) {
      setShouldCmLockQueries(userInfo.workspace.settings.clientMatterQueryLock)
    }
    if (!_.isNil(userInfo.workspace.settings.canUsersManageCm)) {
      setCanUsersManageCm(userInfo.workspace.settings.canUsersManageCm)
    }
  }, [
    userInfo,
    cmData,
    cmData.clientMatters,
    setClientMatters,
    setShouldCmLockQueries,
    setCanUsersManageCm,
  ])

  const clientMatters = providedClientMatters || clientMattersFromStore

  // read the url query param to get the selected client matter
  // and set it in the store if it exists
  // only set it theres no currently selected client matter set
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const clientMatter = searchParams.get(CLIENT_MATTER_URL_PARAM)
    if (!clientMatterSelectDisabled) {
      const selectedValue = clientMatters.find((cm) => cm.name === clientMatter)
      if (
        !_.isNil(selectedValue) &&
        (_.isNil(selectedClientMatter) ||
          selectedValue.name !== selectedClientMatter.name)
      ) {
        setSelectedClientMatter(selectedValue)
      } else if (_.isNil(selectedValue) && !_.isNil(selectedClientMatter)) {
        setSelectedClientMatter(null)
      }
    }
  }, [
    location.search,
    clientMatters,
    selectedClientMatter,
    clientMatterSelectDisabled,
    setSelectedClientMatter,
  ])

  if (_.isNil(userInfo) || !userInfo.isClientMattersReadUser) return <></>

  const urlNavigateWithClientMatter = (clientMatter: string) => {
    const searchParams = new URLSearchParams(location.search)
    if (clientMatter === '') {
      searchParams.delete(CLIENT_MATTER_URL_PARAM)
    } else {
      searchParams.set(CLIENT_MATTER_URL_PARAM, clientMatter)
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
  }

  const handleSelect = (value: Maybe<string>) => {
    const selectedValue = clientMatters.find((cm) => cm.name === value)
    if (setSelectedValue) {
      // If setSelectedValue is provided, we use it to set the selected value
      setSelectedValue(selectedValue?.name || '')
    } else {
      // Otherwise, we set the selected client matter in the store
      setSelectedClientMatter(selectedValue)

      if (clientMatters.length > 0) {
        // Update the URL with the selected client matter only if there are client matters
        urlNavigateWithClientMatter(selectedValue?.name || '')
      }
      Services.HoneyComb.Record({
        metric: CLIENT_MATTER_SELECTED_METRIC,
        client_matter: selectedValue?.name,
        user_id: userInfo.id,
        workspace: userInfo.workspace.id,
      })
      trackEvent('Client Matter Selected', {
        clientMatter: selectedValue?.name,
      })
    }
  }

  const handleAddNewItem = async (value: string) => {
    const successCallback = (successClientMatters: ClientMatterWithStats[]) => {
      urlNavigateWithClientMatter(value)
      const addedClientMatter = successClientMatters[0]
      setClientMatters([...clientMatters, addedClientMatter])
      setSelectedClientMatter(addedClientMatter)
      queryClient.setQueryData(
        [HarvQueryKeyPrefix.SettingsClientMatters],
        (oldData: ClientMattersSettingsStore) =>
          mergeAddedClientMatters(oldData, successClientMatters)
      )
      urlNavigateWithClientMatter(addedClientMatter.name)
    }
    await handleAddClientMatter(
      [{ cmName: value, cmDesc: undefined }],
      successCallback,
      CLIENT_MATTER_SELECT_ADD_METRIC,
      userInfo,
      userInfo.workspace.id,
      trackEvent
    )
  }

  const cmSelectData = _.orderBy(
    clientMatters,
    [
      (cm) => cm.userEmail !== userInfo.id, // Sort by email
      'createdAt', // Then sort by createdAt
    ],
    [
      'asc', // Sort email in ascending order (false first)
      'desc', // Sort createdAt in descending order (newest first)
    ]
  ).map((cm) => {
    return { label: cm.name, value: cm.name }
  })

  // If selectedValue is undefined, we use the selectedClientMatter from the store
  const initialValue =
    selectedValue !== undefined
      ? selectedValue
      : selectedClientMatter?.name || ''

  const canAddNewCm =
    !providedClientMatters && allowAddNewItem && canCmUserManage(userInfo)

  return (
    <Combobox
      align={align}
      className={cn('h-8 w-full', className)}
      containerRef={containerRef}
      options={cmSelectData}
      value={initialValue}
      setValue={handleSelect}
      defaultText="Select"
      inputPlaceholder="Search"
      hasCreateNewCommand={canAddNewCm}
      onCreateNew={handleAddNewItem}
      popoverMenuItemTextClassName="truncate"
      prefix={<span className="text-[10px]">CM#</span>}
      emptyStateText={
        canAddNewCm
          ? 'Not found. Click “Create new” to add a new client matter.'
          : 'Client matter not found.'
      }
      maxLength={MAX_CLIENT_MATTER_NAME_LENGTH}
      disabled={clientMatterSelectDisabled || isCmPending}
      showClearOption={showClearOption}
      virtual={cmSelectData.length > 100}
      id="client-matter-select"
    />
  )
}

export default ClientMatterSelect
