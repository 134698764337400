import React from 'react'

import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'

import EvaluationQuestionCard from './evaluation-question-card'

const EvaluationQuestionSelect = ({
  availableEvaluationQuestionsRef,
  handleNewSelectedQuestion,
}: {
  availableEvaluationQuestionsRef: React.MutableRefObject<EvaluationQuestion[]>
  handleNewSelectedQuestion: (
    question: EvaluationQuestion,
    shouldClose: boolean
  ) => void
}) => {
  return (
    <div>
      {availableEvaluationQuestionsRef.current.map((question) => (
        <EvaluationQuestionCard
          key={question.id}
          evaluationQuestion={question}
          onClick={(question) => handleNewSelectedQuestion(question, false)}
        />
      ))}
    </div>
  )
}

export default EvaluationQuestionSelect
