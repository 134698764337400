import React, { useState } from 'react'

import { ChevronDown, ChevronUp, ListEndIcon, ListPlusIcon } from 'lucide-react'

import { EventKind } from 'openapi/models/EventKind'

import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import LoadPromptButton from 'components/library/load-prompt-button'
import { SavePromptButton } from 'components/library/save-prompt-button'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

type Props = {
  query: string
  setQuery: (query: string) => void
  setQueryPreview: (query: string | null) => void
  eventKind: EventKind
}

export const AssistantPromptButtonGroup = ({
  query,
  setQuery,
  setQueryPreview,
  eventKind,
}: Props) => {
  const userInfo = useAuthUser()
  const [isPromptsOpen, setIsPromptsOpen] = useState(false)
  const handlePromptsPopoverChange = (isOpen: boolean) =>
    setIsPromptsOpen(isOpen)
  const handleSelectPrompt = (prompt: string) => {
    setQueryPreview(null)
    setIsPromptsOpen(false)
    setQuery(prompt)
  }

  if (!userInfo?.IsLibraryUser) {
    return null
  }

  return (
    <div className="flex flex-wrap gap-2">
      <LoadPromptButton
        onPopoverOpen={handlePromptsPopoverChange}
        setQuery={handleSelectPrompt}
        setQueryPreview={setQueryPreview}
        triggerComponent={(disabled, buttonTooltipText) => (
          <Button
            className="pointer-events-auto w-40 justify-start whitespace-nowrap"
            variant="outline"
            disabled={disabled}
            tooltip={buttonTooltipText}
            tooltipClassName={cn({
              'cursor-default': disabled,
            })}
          >
            <Icon icon={ListEndIcon} className="mr-2" />
            Load prompt
            <Icon
              icon={isPromptsOpen ? ChevronUp : ChevronDown}
              className="ml-auto"
            />
          </Button>
        )}
        eventKind={eventKind}
      />
      {userInfo.IsLibraryPrivatePromptUser && (
        <SavePromptButton
          query={query}
          triggerComponent={(disabled, buttonTooltipText, onMouseEnter) => (
            <Button
              className="pointer-events-auto justify-start whitespace-nowrap"
              variant="outline"
              disabled={disabled}
              onMouseEnter={onMouseEnter}
              tooltip={buttonTooltipText}
              tooltipClassName={cn({
                'cursor-default': disabled,
              })}
            >
              <Icon icon={ListPlusIcon} className="mr-2" />
              Save prompt
            </Button>
          )}
          eventKind={eventKind}
        />
      )}
    </div>
  )
}
