import { useQuery } from '@tanstack/react-query'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'

import { fetchRecentAssistantQueries } from 'components/assistant-v2/utils/assistant-api'

export const useAssistantRecentQueriesHistory = (isEnabled: boolean) => {
  return useQuery({
    queryKey: [HarvQueryKeyPrefix.AssistantRecentQueries],
    queryFn: () => fetchRecentAssistantQueries(),
    enabled: isEnabled,
  })
}
