import React from 'react'
import { useSearchParams, useParams } from 'react-router-dom'

import { Download, RotateCcw, X } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import Toolbelt, {
  ToolbeltButton,
  ToolbeltDivider,
  ToolbeltMenu,
} from 'components/ui/toolbelt'
import {
  GenerateNNResponseProps,
  fileIdSearchParamKey,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import {
  exportExcelWithReviewState,
  exportWordWithReviewState,
  downloadFiles,
} from 'components/vault/utils/vault-exporter'
import { retryReview } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeDocuments } from 'components/vault/utils/vault-text-utils'

const VaultDataGridToolbar = ({
  generateNNResponse,
  className,
}: {
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  className?: string
}) => {
  const { projectId, queryId } = useParams()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)

  const gridApi = useVaultStore((s) => s.gridApi)
  const currentProject = useVaultStore((s) => s.currentProject)
  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const queryIdToReviewState = useVaultStore(
    useShallow((s) => s.queryIdToReviewState)
  )
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const setReviewTask = useVaultStore((s) => s.setReviewTask)
  const markHistoryTaskAsFromStreaming = useVaultStore(
    (s) => s.markHistoryTaskAsFromStreaming
  )

  const isShowingLongResponses = useVaultDataGridFilterStore(
    (s) => s.isShowingLongResponses
  )
  const selectedRows = useVaultDataGridFilterStore(
    useShallow((s) => s.selectedRows)
  )
  const clearSelectedRows = useVaultDataGridFilterStore(
    (s) => s.clearSelectedRows
  )

  const isToolbarVisible = selectedRows.length > 0 && !fileId
  const displayText = `${pluralizeDocuments(selectedRows.length)} selected`

  if (!isToolbarVisible) {
    return null
  }

  const downloadHandler = async () => {
    await downloadFiles({
      fileIdsToDownload: selectedRows,
      fileIdToVaultFile,
      downloadFileName: currentProject?.name || '',
      upsertVaultFiles,
    })
  }

  const exportWordHandler = async () => {
    if (!gridApi || !queryId) return
    const reviewState = queryIdToReviewState[queryId]
    if (!reviewState) {
      displayErrorMessage(
        'Sorry we could not export the word file at this time. Please try again later.'
      )
      return
    }

    await exportWordWithReviewState({
      taskType: TaskType.VAULT_REVIEW,
      gridApi,
      queryId,
      onlySelected: true,
      exportAll: false,
      reviewState,
      fileIdToVaultFile,
    })
  }

  const exportExcelHandler = async () => {
    if (!gridApi || !queryId) return
    const sheetName = currentProject?.name ?? 'Untitled'
    const reviewState = queryIdToReviewState[queryId]
    if (!reviewState) {
      displayErrorMessage(
        'Sorry we could not export the excel file at this time. Please try again later.'
      )
      return
    }

    await exportExcelWithReviewState({
      taskType: TaskType.VAULT_REVIEW,
      sheetName,
      queryId,
      reviewState,
      fileIdToVaultFile,
      gridApi,
      exportAll: false,
      isShowingLongResponses,
      onlySelected: true,
    })
  }

  const onDeselectAllRows = () => {
    gridApi?.deselectAll()
    clearSelectedRows()
  }

  const onRetrySelectedRows = async () => {
    await retryReview({
      generateNNResponse,
      setReviewTask,
      markHistoryTaskAsFromStreaming,
      projectId: projectId!,
      queryId: queryId!,
      fileIds: selectedRows,
      queryIdToState,
      queryIdToReviewState,
    })
    onDeselectAllRows()
  }

  return (
    <Toolbelt
      className={cn(
        'flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0',
        className
      )}
    >
      <div className="flex items-center">
        <ToolbeltButton className="truncate rounded-l-md rounded-r-none border border-r-0 border-dotted border-neutral-500 hover:bg-neutral-900">
          {displayText}
        </ToolbeltButton>
        <ToolbeltButton
          icon={X}
          className="rounded-l-none rounded-r-md border border-dotted border-neutral-500"
          onClick={onDeselectAllRows}
        />
      </div>
      <ToolbeltDivider className="hidden md:block" />
      <div className="flex flex-wrap items-center justify-center gap-1 md:flex-nowrap md:gap-2">
        <ToolbeltButton icon={RotateCcw} onClick={onRetrySelectedRows}>
          Retry
        </ToolbeltButton>
        <ToolbeltMenu
          items={[
            {
              label: 'Export selected rows to Word',
              onClick: exportWordHandler,
            },
            {
              label: 'Export selected rows to Excel',
              onClick: exportExcelHandler,
            },
            { label: 'Download selected files', onClick: downloadHandler },
          ]}
        >
          <ToolbeltButton id="download" data-testid="download" icon={Download}>
            Export
          </ToolbeltButton>
        </ToolbeltMenu>
      </div>
    </Toolbelt>
  )
}

export default VaultDataGridToolbar
