import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useClickAway, useKey } from 'react-use'

import { isNil } from 'lodash'
import { Copy, PanelRight } from 'lucide-react'

import { MessageFeedback } from 'openapi/models/MessageFeedback'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { unloadPDFDiv } from 'utils/pspdfkit'
import { TaskType } from 'utils/task'
import { displayInfoMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { FeedbackButton } from 'components/common/feedback/feedback'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { useUpsertEventFeedback } from 'components/vault/hooks/use-feedback'
import {
  fileIdSearchParamKey,
  sourceIdSearchParamKey,
  questionIdSearchParamKey,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import useVaultFeedbackStore from 'components/vault/utils/vault-feedback-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const LEFT_PADDING = 24

const CellViewer: React.FC = () => {
  const userInfo = useAuthUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryParamFileId = searchParams.get(fileIdSearchParamKey)
  const queryParamSourceId = searchParams.get(sourceIdSearchParamKey)
  const queryParamQuestionId = searchParams.get(questionIdSearchParamKey)
  const { trackEvent } = useAnalytics()

  const popoverPosition = useVaultDataGridFilterStore((s) => s.popoverPosition)
  const popoverData = useVaultDataGridFilterStore((s) => s.popoverData)
  const setPopoverData = useVaultDataGridFilterStore((s) => s.setPopoverData)

  const feedbacks = useVaultFeedbackStore((s) => s.feedbacks)

  const setInstance = usePDFViewerStore((s) => s.setInstance)
  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)

  const ref = useRef<HTMLDivElement>(null)
  const [adjustedPosition, setAdjustedPosition] = useState<{
    top: number
    left: number
  }>({ top: 0, left: 0 })

  const {
    displayText = '',
    queryId,
    vaultFolderId,
    file: fileToOpen,
    fileId: fileIdToOpen,
    sourceId: sourceIdToOpen,
    questionId: questionIdToOpen,
    isTextType,
    error,
  } = popoverData || {}

  const openPanel = () => {
    if (!popoverData || !fileToOpen || !fileIdToOpen || error) return

    const isExistingFile = queryParamFileId === fileIdToOpen
    const shouldApplyQuestionQueryParam =
      isTextType && queryParamQuestionId !== questionIdToOpen
    const shouldApplySourceQueryParam =
      isTextType && queryParamSourceId !== sourceIdToOpen

    // if the file is already opened and the source is the same, then we do not need to do anything
    if (
      isExistingFile &&
      !isNil(queryParamSourceId) &&
      queryParamSourceId === sourceIdToOpen
    )
      return

    // if the fileId is already open and the source is different, we just need to set the sourceId and the push sheet will take care of updating the annotations
    if (queryParamFileId === fileIdToOpen) {
      if (shouldApplyQuestionQueryParam || shouldApplySourceQueryParam) {
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)
          if (shouldApplyQuestionQueryParam && questionIdToOpen) {
            newParams.set(questionIdSearchParamKey, questionIdToOpen)
          }
          if (shouldApplySourceQueryParam && sourceIdToOpen) {
            newParams.set(sourceIdSearchParamKey, sourceIdToOpen)
          }
          return newParams
        })
      }
      setPopoverData(null)
      return
    }

    // before we set the activeDocument we are going to clear the instance and unload the existing pdf
    // in case there is an existing pdf open (in the push sheet) we need to setInstance to null so the annotations do not apply (useEffect in push sheet)
    // we also want to unload the pdf immediately so that we can display a loader, otherwise the old pdf will be displayed while the new pdf is loading
    setInstance(null)
    unloadPDFDiv()
    setActiveDocument(fileToOpen)
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(sourceIdSearchParamKey)

      newParams.set(fileIdSearchParamKey, fileIdToOpen)
      // if the type of the cell is text, then we are clicking on a specific question
      // let's set the source param so that the push sheet is open with the current source
      if (questionIdToOpen) {
        newParams.set(questionIdSearchParamKey, questionIdToOpen)

        if (sourceIdToOpen) {
          newParams.set(sourceIdSearchParamKey, sourceIdToOpen)
        }
      }
      return newParams
    })
    setPopoverData(null)
  }

  const handleCopyToClipboard = async () => {
    await window.navigator.clipboard.writeText(displayText)
    displayInfoMessage('Copied to clipboard')
  }

  useClickAway(ref, () => {
    setPopoverData(null)
  })

  useKey('Escape', () => {
    setPopoverData(null)
  })

  useEffect(() => {
    if (ref.current) {
      const popoverHeight = ref.current.getBoundingClientRect().height
      const viewportHeight = window.innerHeight

      const horizontalScrollBar = document.getElementsByClassName(
        'ag-body-horizontal-scroll'
      )
      const scrollBarHeight =
        horizontalScrollBar.length > 0 ? horizontalScrollBar[0].clientHeight : 0

      let newTop = popoverPosition.top
      if (
        popoverPosition.top + popoverHeight + scrollBarHeight >
        viewportHeight
      ) {
        const diff =
          popoverPosition.top + popoverHeight + scrollBarHeight - viewportHeight
        newTop = popoverPosition.top - diff
      }
      const leftPosition =
        popoverPosition.left + popoverPosition.width + LEFT_PADDING >
        window.innerWidth
          ? popoverPosition.left - popoverPosition.width - LEFT_PADDING
          : popoverPosition.left
      setAdjustedPosition({ top: newTop, left: leftPosition })
    }
  }, [popoverPosition])

  const { upsertEventFeedback } = useUpsertEventFeedback()

  const handleSubmitFeedback = async (newFeedback: MessageFeedback) => {
    if (!queryId || !vaultFolderId || !fileIdToOpen || !questionIdToOpen)
      return false
    const task_type = TaskType.VAULT_REVIEW
    trackEvent('Feedback Submitted', {
      event_id: queryId,
      event_kind: task_type,
      sentiment: newFeedback.sentiment,
      comments: newFeedback.selectedComments,
      vaultFolderId: vaultFolderId,
      fileId: fileIdToOpen,
      questionId: questionIdToOpen,
    })
    const submittedFeedback = await upsertEventFeedback({
      eventId: Number(queryId),
      vaultFolderId: vaultFolderId,
      fileId: fileIdToOpen,
      questionId: questionIdToOpen,
      feedbackData: newFeedback,
    })
    return submittedFeedback !== null
  }

  if (isNil(popoverData)) return

  return (
    <div
      ref={ref}
      className="fixed left-24 top-24 z-50 border border-l-0 border-t-0 bg-primary px-4 py-2 shadow-sm ring-[1px] ring-inset ring-primary"
      style={{
        left: adjustedPosition.left,
        top: adjustedPosition.top,
        width: popoverPosition.width + LEFT_PADDING,
      }}
    >
      <p
        className="break-words text-xs"
        dangerouslySetInnerHTML={{
          __html: displayText.replace(/\n/g, '<br />'),
        }}
      />
      <div className="mt-8 flex justify-end space-x-1">
        {userInfo.IsFeedbackUser && (
          <>
            <FeedbackButton
              hasDocuments
              onSubmit={handleSubmitFeedback}
              sentiment={
                feedbacks[queryId ?? '']?.[fileIdToOpen ?? '']?.[
                  questionIdToOpen ?? ''
                ]?.sentiment ?? 0
              }
              sentimentValue={1}
              buttonSize="xsIcon"
            />
            <FeedbackButton
              hasDocuments
              onSubmit={handleSubmitFeedback}
              sentiment={
                feedbacks[queryId ?? '']?.[fileIdToOpen ?? '']?.[
                  questionIdToOpen ?? ''
                ]?.sentiment ?? 0
              }
              sentimentValue={-1}
              buttonSize="xsIcon"
            />
          </>
        )}
        <Tooltip>
          <TooltipTrigger>
            <Button
              size="xsIcon"
              variant="ghost"
              onClick={handleCopyToClipboard}
            >
              <Icon icon={Copy} variant="secondary" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Copy to clipboard</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <Button size="xsIcon" variant="ghost" onClick={openPanel}>
              <Icon icon={PanelRight} variant="secondary" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Open inspector panel</TooltipContent>
        </Tooltip>
      </div>
    </div>
  )
}

export default CellViewer
