import React, { useCallback, useState } from 'react'

import { InputNumber, Select } from 'antd'
import _ from 'lodash'

import { PWCTaxFeedbackExportRequestTaskKindEnum } from 'openapi/models/PWCTaxFeedbackExportRequest'
import { PWCTaxFeedbackExportResponse } from 'openapi/models/PWCTaxFeedbackExportResponse'
import Services from 'services'

import { displayErrorMessage } from 'utils/toast'

import { AppHeader } from 'components/common/app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { Button as TailwindButton } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'

const PWCTaxFeedbackExport: React.FC = () => {
  const availableTaskKinds = Object.values(
    PWCTaxFeedbackExportRequestTaskKindEnum
  )
  const [taskKind, setTaskKind] =
    useState<PWCTaxFeedbackExportRequestTaskKindEnum>('HMRC_QA')
  const [numDays, setNumDays] = useState<number>(7)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onClickDownload = useCallback(async () => {
    setIsLoading(true)

    const response = await Services.Backend.Post<PWCTaxFeedbackExportResponse>(
      `pwc_tax_feedback_export/download`,
      { taskKind, numDays }
    )
    if (_.isEmpty(response) || _.isNil(response) || !response.fileDataB64) {
      displayErrorMessage('Error getting export')
    } else {
      // Convert base64 to array buffer
      const binaryString = window.atob(response.fileDataB64)
      const bytes = new Uint8Array(binaryString.length)
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      const arrayBuffer = bytes.buffer

      const blob = new Blob([arrayBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      const currentDate = new Date().toISOString().split('T')[0]
      link.download = `feedback-export-${taskKind}-${currentDate}.xlsx`
      link.click()
      window.URL.revokeObjectURL(url)
    }
    setIsLoading(false)
  }, [taskKind, numDays, setIsLoading])

  return (
    <>
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
        isInternalAdmin
      />
      <SettingsLayout>
        <div className="space-x-4">
          <Select
            options={availableTaskKinds.map((val) => {
              return { value: val, label: val }
            })}
            value={taskKind}
            onChange={setTaskKind}
          />
          <span>
            <span className="mr-1">Number of days:</span>
            <InputNumber
              min={1}
              max={60}
              value={numDays}
              onChange={(val) => setNumDays(val || 1)}
            />
          </span>
          <div className="float-right flex flex-row space-x-2">
            {isLoading && <Spinner className="mt-2 h-4 w-4" />}
            <TailwindButton
              onClick={onClickDownload}
              disabled={isLoading}
              variant="default"
              size="sm"
            >
              Download export
            </TailwindButton>
          </div>
        </div>
      </SettingsLayout>
    </>
  )
}
export default PWCTaxFeedbackExport
