import React from 'react'

import _ from 'lodash'

import Services from 'services'
import { Maybe } from 'types'

import { cn } from 'utils/utils'

import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { ClientMatter } from 'components/client-matters/client-matters-store'
import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { ClientMatterRecord } from 'components/filter/types/client-matter-record'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

const ClientMatterFilter = (props: {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  allEventClientMatters?: ClientMatter[]
  getClientMatterName?: (record: ClientMatterRecord) => Maybe<string>
}) => {
  const { trackEvent } = useAnalytics()
  const { filterKey, setValue, allEventClientMatters } = props
  const selectedValue = props.value || ''
  const getClientMatterName = props.getClientMatterName || (() => undefined)

  const render: React.FC = () => {
    const clientMatterSelect = (
      <ClientMatterSelect
        key={filterKey}
        className={cn('w-40', {
          'text-muted': _.isEmpty(selectedValue),
        })}
        providedClientMatters={allEventClientMatters}
        selectedValue={selectedValue}
        setSelectedValue={(value) => {
          Services.HoneyComb.Record({
            metric: 'ui.history_client_matter_filter',
            client_matter: value,
          })
          trackEvent(FILTER_CHANGED_EVENT_NAME, {
            entity_name: 'client matter selector',
            field_changed: 'client matter',
            client_matter: value,
          })
          setValue(value)
        }}
        align="end"
      />
    )

    if (_.isEmpty(selectedValue)) return clientMatterSelect

    return (
      <Tooltip delayDuration={1000} key={filterKey}>
        <TooltipTrigger asChild>
          <div>{clientMatterSelect}</div>
        </TooltipTrigger>
        <TooltipContent className="w-64" side="top">
          {selectedValue} is selected to filter queries
        </TooltipContent>
      </Tooltip>
    )
  }

  const filterLogic = (record: ClientMatterRecord) => {
    return selectedValue === '' || getClientMatterName(record) === selectedValue
  }

  return { filterKey, displayName: 'Client matter', render, filterLogic }
}

export default ClientMatterFilter
