import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

import { ContractsDocument } from 'openapi/models/ContractsDocument'

import { backendFormat } from 'utils/utils'

export const exportToExcel = (documents: ContractsDocument[]) => {
  const dateTime = backendFormat(new Date())
  const fileName = `CONTRACTS_${dateTime}.xlsx`
  const wb = XLSX.utils.book_new()
  const standardWidth = 20

  // Organize documents by type
  const docsByType = documents
    .filter((doc) => !!doc.type)
    .reduce(
      (acc, doc) => {
        const type = doc.type as string
        if (!acc[type]) acc[type] = []
        acc[type].push(doc)
        return acc
      },
      {} as Record<string, ContractsDocument[]>
    )

  // Process each type
  for (const type in docsByType) {
    const docs = docsByType[type]
    const uniqueKeys = new Set<string>()

    // Collect all unique keys
    docs.forEach((doc) =>
      Object.keys(doc.extractedTerms as Record<string, any>).forEach((key) =>
        uniqueKeys.add(key)
      )
    )

    const data = docs.map((doc) => {
      const row: any = { File: doc.file.name }
      uniqueKeys.forEach((key) => {
        row[key] = (doc.extractedTerms as Record<string, any>)[key]?.text || ''
      })

      return row
    })

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(data)

    ws['!cols'] = Array.from({ length: uniqueKeys.size + 1 }, () => ({
      wch: standardWidth,
    }))

    XLSX.utils.book_append_sheet(wb, ws, type.slice(0, 31))
  }

  // Write workbook and save
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  saveAs(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
    fileName
  )
}

export const s2ab = (s: string): ArrayBuffer => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff
  }
  return buf
}
