import React, { useEffect, useRef, useState } from 'react'

import _ from 'lodash'
import { BookmarkPlus } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import Combobox from 'components/ui/combobox/combobox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Spinner } from 'components/ui/spinner'

import {
  useExampleItemsStore,
  usePromptItemsStore,
} from './library-items-store'
import { Example, LibraryItemKind } from './library-types'
import {
  getDocumentTypes,
  getCategories,
  getPracticeAreas,
} from './library.helpers'
import { useLibraryDataProvider } from './use-library-data-provider'

type Props = {
  eventId: string
  dialogOnly?: boolean
  onOpenChange?: (isOpen: boolean) => void
  saveExampleDisabled?: boolean
}

export const LibrarySaveExample: React.FC<Props> = ({
  eventId,
  dialogOnly,
  onOpenChange,
  saveExampleDisabled,
}) => {
  const { trackEvent } = useAnalytics()
  const [dialogOpen, setDialogOpen] = useState(!!dialogOnly)
  const [saveLoading, setSaveLoading] = useState(false)
  const onDialogOpenChange = (isOpen: boolean) => {
    setDialogOpen(isOpen)
    onOpenChange?.(isOpen)
  }

  const handleSaveExample = async (
    params: Partial<
      Pick<
        Example,
        | 'name'
        | 'category'
        | 'practiceArea'
        | 'documentType'
        | 'visibilityScope'
      >
    >
  ) => {
    const { name, documentType, category, practiceArea, visibilityScope } =
      params
    setSaveLoading(true)
    let result = false
    try {
      const example = await Services.Backend.Post<Example>('library/example', {
        name: name?.trim(),
        sourceEventId: eventId,
        category: category?.trim() || undefined,
        practiceArea: practiceArea?.trim() || undefined,
        documentType: documentType?.trim() || undefined,
        visibilityScope: visibilityScope,
      })

      if (!_.isEmpty(example)) {
        Services.HoneyComb.Record({
          metric: 'ui.library_example_saved',
          item_id: example.id,
          task_type: example.eventKind,
          starred: example.starred,
          workspace_id: example.workspaceId,
          user_id: example.userId,
          visibility_scope: example.visibilityScope,
          category: example.category,
          practice_area: example.practiceArea,
          document_type: example.documentType,
        })
        trackEvent('Library Item Created', {
          item_id: example.id,
          event_id: example.eventId,
          kind: 'example',
          task_type: example.eventKind,
          category: example.category,
          practice_area: example.practiceArea,
          document_type: example.documentType,
          visibility_scope: example.visibilityScope,
          entry_point: example.eventKind,
        })
        displaySuccessMessage('Saved example successfully', 1)
        onDialogOpenChange(false)
        result = true
      }
    } finally {
      setSaveLoading(false)
    }
    return result
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={onDialogOpenChange}>
      {!dialogOnly && (
        <DialogTrigger disabled={saveExampleDisabled} asChild>
          <Button data-testid="library-save-example" variant="outline">
            <Icon icon={BookmarkPlus} className="mr-1" />
            Save example
          </Button>
        </DialogTrigger>
      )}
      {dialogOpen && (
        <LibrarySaveExampleDialog
          handleSaveExample={handleSaveExample}
          saveLoading={saveLoading}
        />
      )}
    </Dialog>
  )
}

type SaveExampleDialogProps = {
  saveLoading: boolean
  handleSaveExample: (
    params: Partial<
      Pick<
        Example,
        | 'name'
        | 'category'
        | 'practiceArea'
        | 'documentType'
        | 'visibilityScope'
      >
    >
  ) => Promise<boolean>
  editExample?: Partial<
    Pick<
      Example,
      | 'id'
      | 'name'
      | 'category'
      | 'practiceArea'
      | 'documentType'
      | 'visibilityScope'
    >
  >
}

export const LibrarySaveExampleDialog: React.FC<SaveExampleDialogProps> = (
  props: SaveExampleDialogProps
) => {
  const { editExample, handleSaveExample, saveLoading } = props

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [practiceArea, setPracticeArea] = useState('')
  const [createdCategories, setCreatedCategories] = useState<string[]>([])
  const [createdPracticeAreas, setCreatedPracticeAreas] = useState<string[]>([])
  const [createdDocumentTypes, setCreatedDocumentTypes] = useState<string[]>([])
  const [visibilityScope, setVisibilityScope] = useState<LibraryVisbilityScope>(
    LibraryVisbilityScope.WORKSPACE
  )

  const [
    examples,
    isExamplesLoading,
    setIsExamplesLoading,
    setExamples,
    updateExamplesLastUpdatedTime,
  ] = useExampleItemsStore(
    useShallow((s) => [
      s.items,
      s.isLoading,
      s.setIsLoading,
      s.setItems,
      s.updateLastUpdatedTime,
    ])
  )

  const [
    prompts,
    isPromptsLoading,
    setIsPromptsLoading,
    setPrompts,
    updatePromptsLastUpdatedTime,
  ] = usePromptItemsStore(
    useShallow((s) => [
      s.items,
      s.isLoading,
      s.setIsLoading,
      s.setItems,
      s.updateLastUpdatedTime,
    ])
  )

  useLibraryDataProvider({
    setItems: setExamples,
    setIsLoading: setIsExamplesLoading,
    type: LibraryItemKind.EXAMPLE,
    updateLastUpdatedTime: updateExamplesLastUpdatedTime,
  })

  useLibraryDataProvider({
    setItems: setPrompts,
    setIsLoading: setIsPromptsLoading,
    type: LibraryItemKind.PROMPT,
    updateLastUpdatedTime: updatePromptsLastUpdatedTime,
  })

  // Don't reset example on poll refresh
  const prevEditExampleId = useRef<string>('')
  useEffect(() => {
    const editExampleId = editExample?.id ?? ''
    if (prevEditExampleId.current !== editExampleId) {
      setName(editExample?.name ?? '')
      setCategory(editExample?.category ?? '')
      setDocumentType(editExample?.documentType ?? '')
      setPracticeArea(editExample?.practiceArea ?? '')
      setVisibilityScope(
        editExample?.visibilityScope ?? LibraryVisbilityScope.WORKSPACE
      )
    }
    prevEditExampleId.current = editExampleId
  }, [editExample])

  const hasChanges = editExample
    ? name !== editExample.name ||
      category !== editExample.category ||
      practiceArea !== editExample.practiceArea ||
      documentType !== editExample.documentType
    : name || category || practiceArea || documentType

  const isSaveDisabled =
    isExamplesLoading ||
    !name.trim() ||
    !category.trim() ||
    !practiceArea.trim()
  let saveDisabledTooltip = ''
  if (isExamplesLoading) {
    saveDisabledTooltip = 'Loading examples'
  } else if (!name.trim()) {
    saveDisabledTooltip = 'Name is required'
  } else if (!category.trim()) {
    saveDisabledTooltip = 'Category is required'
  } else if (!practiceArea.trim()) {
    saveDisabledTooltip = 'Practice area is required'
  } else {
    saveDisabledTooltip = ''
  }

  const internalSaveExample = async () => {
    if (saveDisabledTooltip) {
      displayErrorMessage(saveDisabledTooltip)
      return
    }
    await handleSaveExample({
      name,
      category,
      documentType,
      practiceArea,
      visibilityScope,
    })
    trackEvent(`Library Item ${editExample ? 'Edited' : 'Created'}`, {
      itemType: LibraryItemKind.EXAMPLE,
      practice_area: practiceArea,
      category: category,
      document_type: documentType,
      visibility_scope: visibilityScope,
    })
  }

  const handleCreateNewCategory = (newCategory: string) => {
    setCreatedCategories(_.uniq([...createdCategories, newCategory.trim()]))
    setCategory(newCategory)
  }

  const handleCreateNewPracticeArea = (newPracticeArea: string) => {
    setCreatedPracticeAreas(
      _.uniq([...createdPracticeAreas, newPracticeArea.trim()])
    )
    setPracticeArea(newPracticeArea)
  }

  const handleCreateNewDocumentType = (newDocumentType: string) => {
    setCreatedDocumentTypes(
      _.uniq([...createdDocumentTypes, newDocumentType.trim()])
    )
    setDocumentType(newDocumentType)
  }

  const categoryOptions = _.uniq([
    ...getCategories(examples),
    ...getCategories(prompts),
    ...createdCategories,
  ]).map((category) => ({
    label: category,
    value: category,
  }))

  const practiceAreaOptions = _.uniq([
    ...getPracticeAreas(examples),
    ...getPracticeAreas(prompts),
    ...createdPracticeAreas,
  ]).map((practiceArea) => ({
    label: practiceArea,
    value: practiceArea,
  }))

  const documentTypeOptions = _.uniq([
    ...getDocumentTypes(examples),
    ...createdDocumentTypes,
  ]).map((documentType) => ({
    label: documentType,
    value: documentType,
  }))

  const containerRef = useRef<HTMLDivElement>(null)

  const disableContentEdit =
    editExample &&
    editExample.visibilityScope === LibraryVisbilityScope.HARVEY &&
    !userInfo.IsLibraryCreateHarveyItemsUser

  const disableTagEdit = saveLoading || isExamplesLoading || isPromptsLoading

  const isGlobalExample = visibilityScope === LibraryVisbilityScope.HARVEY
  const dialogTitle = `${editExample ? 'Edit' : 'Save'} example`

  return (
    <DialogContent ref={containerRef} preventOutsideClose={!!hasChanges}>
      <h2 className="text-lg font-semibold">{dialogTitle}</h2>
      <div>
        <Label>Name</Label>
        <Input
          data-testid="save-example-name"
          className="h-8"
          placeholder="Enter a short, descriptive name"
          value={name}
          maxLength={128}
          onChange={(e) => setName(e.target.value)}
          disabled={disableContentEdit}
        />
      </div>

      <div>
        <Label>Category</Label>
        <Combobox
          className="w-full"
          containerRef={containerRef}
          defaultText="Select or create a category"
          disabled={disableTagEdit}
          inputPlaceholder="Start typing to search or create"
          emptyStateText="No existing categories"
          hasCreateNewCommand
          onCreateNew={handleCreateNewCategory}
          maxLength={64}
          options={categoryOptions}
          setValue={setCategory}
          value={category}
        />
      </div>

      <div>
        <Label>Practice area</Label>
        <Combobox
          className="w-full"
          containerRef={containerRef}
          defaultText="Select or create a practice area"
          disabled={disableTagEdit}
          inputPlaceholder="Start typing to search or create"
          emptyStateText="No existing practice areas"
          hasCreateNewCommand
          onCreateNew={handleCreateNewPracticeArea}
          maxLength={64}
          options={practiceAreaOptions}
          setValue={setPracticeArea}
          value={practiceArea}
        />
      </div>

      <div>
        <Label>Document type</Label>
        <Combobox
          className="w-full"
          containerRef={containerRef}
          defaultText="Select or create a document type (optional)"
          disabled={disableTagEdit}
          inputPlaceholder="Start typing to search or create"
          emptyStateText="No existing document type"
          hasCreateNewCommand
          onCreateNew={handleCreateNewDocumentType}
          options={documentTypeOptions}
          setValue={setDocumentType}
          value={documentType}
          maxLength={64}
        />
      </div>

      {userInfo.IsLibraryCreateHarveyItemsUser && !editExample && (
        <div>
          <Checkbox
            id="example-visibility"
            onClick={() =>
              setVisibilityScope(
                isGlobalExample
                  ? LibraryVisbilityScope.WORKSPACE
                  : LibraryVisbilityScope.HARVEY
              )
            }
            label="Global Harvey example"
            checked={isGlobalExample}
          />
        </div>
      )}

      <div className="flex justify-between space-x-2 pt-4">
        <div>
          <BasicTransition show={saveLoading}>
            <div className="flex items-center">
              <Spinner className="h-3 w-3" />
              Saving…
            </div>
          </BasicTransition>
        </div>

        {/* Action Buttons */}
        <div className="flex space-x-2">
          <DialogClose disabled={saveLoading} asChild>
            <Button variant="ghost">Cancel</Button>
          </DialogClose>
          <Button
            data-testid="save-example-button"
            onClick={internalSaveExample}
            disabled={isSaveDisabled}
            tooltip={saveDisabledTooltip}
          >
            Save
          </Button>
        </div>
      </div>
    </DialogContent>
  )
}
