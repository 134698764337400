import React from 'react'
import { useLocation } from 'react-router-dom'

import { LucideIcon } from 'lucide-react'

import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'

type Props = {
  title: React.ReactNode
  description: React.ReactNode
  area?: string
  isLoading?: boolean
  isPendingRelease?: boolean
  icon: LucideIcon
}

export const ResearchAreaCard = ({
  title,
  description,
  area,
  isLoading,
  isPendingRelease,
  icon,
}: Props) => {
  const location = useLocation()
  const destination = {
    pathname: area ? `/research/${area}` : '/research',
    search: location.search, // Retains current query parameters
  }
  return (
    <Link
      to={destination}
      className={cn('rounded-lg', {
        'research-area-card': !isLoading,
        'cursor-disabled pointer-events-none opacity-50': isPendingRelease,
        'animate-pulse': isLoading,
      })}
    >
      <Card className="flex h-64 flex-col justify-between transition hover:border-input-focused">
        <CardHeader className="flex">
          {isPendingRelease && (
            <Badge className="z-20 h-fit" variant="outline">
              Coming soon
            </Badge>
          )}

          <Icon icon={icon} size="largeWithBackground" />
        </CardHeader>
        <CardContent>
          <p className="text-base">{title}</p>
          <p className="mt-1 text-xs">{description}</p>
        </CardContent>
      </Card>
    </Link>
  )
}
