import React from 'react'

import { cn } from 'utils/utils'

interface HarveyWordMarkProps {
  className?: string
}

const HarveyWordMark: React.FC<HarveyWordMarkProps> = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3162 961"
      preserveAspectRatio="xMidYMid meet"
      className={cn('fill-primary-inverse', className)}
    >
      <path
        fill="#191816"
        d="M2821 961h-116l167-255-216-444-73-61v-4h251v4l-77 50 160 336 131-334-80-52v-4h194v4l-69 62-272 698Zm-594-540c2 143 78 234 181 234 59 0 107-21 153-76l26 24c-66 88-126 121-208 121-138 0-238-111-238-267 0-148 112-271 246-271 100 0 196 71 196 219v16h-356ZM862 572c0 50 35 84 84 84 37 0 78-18 121-48V442l-110 26c-63 14-95 54-95 104Zm205-173v-47c0-82-40-125-101-125-60 0-120 45-180 133h-4V222c58-19 131-36 191-36 111 0 188 47 188 154v310l80 60v4h-177v-68c-36 40-92 78-151 78-83 0-153-59-153-142 0-74 54-123 173-151l134-32ZM720 714H436v-4l90-60V368H194v282l90 60v4H0v-4l90-60V64L0 4V0h284v4l-90 60v257h332V64L436 4V0h284v4l-90 60v586l90 60v4Zm1510-337h250v-7c0-95-42-143-111-143-83 0-128 74-139 150Zm-577-85c-29-13-57-19-86-19-44 0-88 17-132 49v328l100 60v4h-274v-4l80-60V277l-80-40v-4l174-42v92c41-43 100-86 178-86h200v4l-74 47 159 331 138-325-80-53v-4h200v4l-73 62-201 465h-18l-211-436Z"
      />
    </svg>
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 510 152"
      preserveAspectRatio="xMidYMid meet"
      className={cn('fill-primary-inverse', className)}
    >
      <g>
        <path d="M0 115.315V0h15.66v49.604h57.165V0h15.639v115.315H72.825V64.127H15.66v51.209H0v-.021ZM108.902 93.621c0-14.36 10.697-23.278 29.061-24.72l24.587-1.91v-2.234c0-13.081-7.83-17.713-18.364-17.713-12.609 0-19.808 5.586-19.808 14.991h-13.096c0-16.27 13.422-26.955 33.535-26.955 20.112 0 32.416 10.217 32.416 31.261v48.974h-12.771l-1.607-12.594c-3.986 8.938-14.683 14.524-27.312 14.524-16.757 0-26.661-9.405-26.661-23.603l.02-.02Zm53.811-11.964v-4.144l-19.971 1.605c-13.259 1.28-18.689 6.378-18.689 14.036 0 8.288 6.06 12.431 15.801 12.431 14.216 0 22.838-9.242 22.838-23.928h.021ZM242.697 50.396h-6.711c-14.216 0-23.001 8.612-23.001 23.603v41.316h-15.008V37.477h14.052l.956 11.802c3.193-8.125 11.022-13.549 21.719-13.549 2.705 0 4.942.325 7.993.955v13.71ZM249.57 37.315h15.965l17.082 43.55c2.705 7.333 4.942 13.874 6.223 18.667 1.281-5.098 3.823-11.801 6.549-18.667l17.408-43.55h15.659l-32.579 78h-14.846l-31.461-78ZM331.791 76.396c0-24.395 15.496-41.316 38.172-41.316 22.675 0 36.728 15.316 36.728 38.432v5.586l-60.359.162c1.118 16.433 9.741 25.513 24.587 25.513 11.652 0 19.319-4.794 21.882-13.711h14.052c-3.823 16.758-16.92 26.163-36.239 26.163-23.001 0-38.803-16.596-38.803-40.829h-.02Zm14.846-7.17h45.025c0-12.92-8.46-21.532-21.72-21.532-13.259 0-21.394 7.82-23.305 21.532ZM487.162 106.073c0-6.216 5.267-11.334 11.49-11.334S510 99.837 510 106.073s-5.267 11.172-11.348 11.172c-6.08 0-11.49-5.098-11.49-11.172ZM444.292 84.034l-16.859-46.72h-15.801l25.421 66.382 1.301-3.534 5.938-16.128ZM473.272 37.315h15.497L445.981 152h-15.497l42.788-114.685Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h510v152H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HarveyWordMark
