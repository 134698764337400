import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { NAV_STATE_PARAM } from 'utils/routing'

// This hook manages setting a value based on search params state.
// This is useful for opening new tabs, where historyState is lost, so we instead
// store the navigation state from query params and clear them afterward.
export const useNavigationQueryState = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [storedState, setStoredState] = useState<
    Record<string, string> | undefined
  >()
  const searchState = searchParams.get(NAV_STATE_PARAM)

  useEffect(() => {
    if (searchState) {
      try {
        const parsedState = JSON.parse(searchState)
        setStoredState(parsedState)
      } finally {
        setSearchParams(
          (prevParams) => {
            const newParams = new URLSearchParams(prevParams)
            newParams.delete(NAV_STATE_PARAM)
            return newParams
          },
          { replace: true }
        )
      }
    }
  }, [searchState, setSearchParams])

  return storedState
}
