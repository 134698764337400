import React from 'react'

import { Row } from '@tanstack/react-table'

import { UserInfo } from 'models/user-info'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'

import { Example, LibraryItem } from 'components/library/library-types'

import {
  LibraryCategoryCell,
  LibraryCreatorCell,
  LibraryDocumentTypeCell,
  LibraryMenuCell,
  LibraryNameCell,
  LibraryPracticeAreaCell,
  LibraryStarredCell,
  LibraryTaskTypeCell,
  LibraryTimeCell,
  LibraryWorkspaceHiddenCell,
} from './library-cells'
import {
  shouldShowDelete as checkShouldShowDelete,
  shouldShowHide as checkShouldShowHide,
  shouldShowEdit as checkShouldShowEdit,
  shouldShowCreator,
} from './library.helpers'

export const getExamplesTableColumns = (params: {
  userInfo: UserInfo
  currentTab: LibraryVisbilityScope
  onDeleteItem: (example: LibraryItem) => void
  onEditItem?: (example: LibraryItem) => void
  onHideItem: (example: LibraryItem) => void
}) => {
  const { userInfo, currentTab, onDeleteItem, onHideItem, onEditItem } = params
  const showDelete = checkShouldShowDelete(currentTab, userInfo)
  const showHide = checkShouldShowHide(currentTab, userInfo)
  const showEdit = checkShouldShowEdit(currentTab, userInfo)
  const showCreator = shouldShowCreator(currentTab, userInfo)

  return [
    ...[
      {
        header: '',
        accessorKey: 'starred',
        cell: ({ row }: { row: Row<LibraryItem> }) => (
          <LibraryStarredCell row={row} />
        ),
        size: 64,
      },
      {
        header: 'Created',
        accessorKey: 'created',
        cell: ({ row }: { row: Row<LibraryItem> }) => (
          <LibraryTimeCell row={row} />
        ),
        size: 104, // https://github.com/TanStack/table/discussions/4179#discussioncomment-7142606
      },
    ],
    ...(showCreator
      ? [
          {
            header: 'Creator',
            accessorKey: 'createdByUserEmail',
            cell: ({ row }: { row: Row<LibraryItem> }) => (
              <LibraryCreatorCell row={row} />
            ),
            size: 104,
          },
        ]
      : []),
    ...[
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row }: { row: Row<LibraryItem> }) => (
          <LibraryNameCell row={row} />
        ),
        size: 'auto' as unknown as number, // https://github.com/TanStack/table/discussions/4179#discussioncomment-7142606
      },
      {
        header: 'Type',
        accessorKey: 'eventKind',
        cell: ({ row }: { row: Row<LibraryItem> }) => (
          <LibraryTaskTypeCell row={row} userInfo={userInfo} />
        ),
        size: 144,
      },
      {
        header: 'Category',
        accessorKey: 'category',
        cell: ({ row }: { row: Row<LibraryItem> }) => (
          <LibraryCategoryCell row={row} />
        ),
        size: 160,
      },
      {
        header: 'Practice area',
        accessorKey: 'practiceArea',
        cell: ({ row }: { row: Row<LibraryItem> }) => (
          <LibraryPracticeAreaCell row={row} />
        ),
        size: 160,
      },
      {
        header: 'Document type',
        accessorKey: 'documentType',
        cell: ({ row }: { row: Row<Example> }) => (
          <LibraryDocumentTypeCell row={row} />
        ),
        size: 160,
      },
      ...(showHide
        ? [
            {
              header: 'Team visibility',
              accessorKey: 'hidden',
              cell: ({ row }: { row: Row<LibraryItem> }) => (
                <LibraryWorkspaceHiddenCell row={row} />
              ),
              size: 128,
            },
          ]
        : []),
      {
        header: '',
        accessorKey: 'id',
        cell: ({ row }: { row: Row<LibraryItem> }) => (
          <LibraryMenuCell
            row={row}
            userInfo={userInfo}
            onDeleteItem={showDelete ? onDeleteItem : undefined}
            onHideItem={showHide ? onHideItem : undefined}
            onEditItem={showEdit ? onEditItem : undefined}
          />
        ),
        size: 48,
      },
    ],
  ]
}
