import React from 'react'

import { Tabs, TabsProps, Typography } from 'antd'

import { RawUser } from 'models/users'
import {
  isPwcWorkspace,
  PermUserCountByWorkspace,
  Workspace,
} from 'models/workspace'

import { useAuthUser } from 'components/common/auth-context'
import WorkspaceDetailsRoles from 'components/settings/roles/workspace-details-roles'
import MultiUserAddRemove from 'components/settings/user-management/multi-user-add-remove'

import WorkspacePermUserCounts from './workspace-perm-user-counts'
import WorkspaceUsersInternal from './workspace-users-internal'

interface WorkspaceDetailsUsersProps {
  workspace: Workspace
  workspaces: Workspace[]
  users: RawUser[]
  permUserCountByWorkspace: PermUserCountByWorkspace
  fetchData: () => void
}

const WorkspaceDetailsUsers = ({
  workspace,
  workspaces,
  users,
  permUserCountByWorkspace,
  fetchData,
}: WorkspaceDetailsUsersProps) => {
  const userInfo = useAuthUser()

  const onTabClick = (key: string) => {
    if (key === '1' || key === '4') {
      void fetchData()
    }
  }

  const workspaceUsersTabDisabled =
    (!userInfo.isUserManagement ||
      (isPwcWorkspace(workspace.slug) && !userInfo.IsInternalAdminWriter) ||
      workspace.deletedAt != null) ??
    true

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Users',
      children: (
        <WorkspaceUsersInternal
          users={users}
          fetchData={fetchData}
          workspace={workspace}
        />
      ),
    },
    {
      key: '2',
      label: 'Add new users',
      children: (
        <MultiUserAddRemove
          type="add"
          workspace={workspace}
          workspaces={workspaces}
          helpMessage={
            <Typography>
              Add new user emails to create them in Harvey and Auth0. Get a csv
              of new users.
              <br /> Users will be added to <b>{workspace?.clientName}</b>{' '}
              workspace.
            </Typography>
          }
          displayDownloadButton
        />
      ),
      disabled: workspaceUsersTabDisabled,
    },
    {
      key: '3',
      label: 'Perm User Counts',
      children: (
        <WorkspacePermUserCounts
          permUserCountByWorkspace={permUserCountByWorkspace}
          workspace={workspace}
          workspaces={workspaces}
        />
      ),
    },
    {
      key: '4',
      label: 'Roles',
      children: <WorkspaceDetailsRoles workspace={workspace} />,
    },
  ]
  return (
    <Tabs
      defaultActiveKey="1"
      items={tabItems}
      type="card"
      onTabClick={onTabClick}
    />
  )
}

export default WorkspaceDetailsUsers
