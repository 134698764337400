import React, {
  useState,
  useEffect,
  ChangeEvent,
  useRef,
  useCallback,
} from 'react'

import AskHarveyButton from 'components/common/ask-harvey-button'
import { Button } from 'components/ui/button'
import { PopoverContent } from 'components/ui/popover'
import { Textarea } from 'components/ui/text-area'

type Props = Pick<
  React.ComponentPropsWithoutRef<typeof PopoverContent>,
  'collisionPadding' | 'hideWhenDetached'
> & {
  disabled: boolean
  disabledReason: string | undefined
  selectedText: string
  onHide: () => void
  onSubmit: (query: string) => void
}

const MIN_QUERY_LENGTH = 3
const MAX_QUERY_LENGTH = 400

export const AssistantInlineEditPopover: React.FC<Props> = ({
  onHide,
  onSubmit,
  disabled,
  disabledReason,
  selectedText,
  ...popoverContentProps
}) => {
  const wiggle = useRef(true)
  const [selectedInlineEditText, setSelectedInlineEditText] = useState('')
  const [inlineEditTextInput, setInlineEditTextInput] = useState('')
  const popoverRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    wiggle.current = true
  }, [inlineEditTextInput])

  const submitDisabled =
    disabled ||
    inlineEditTextInput.length < MIN_QUERY_LENGTH ||
    inlineEditTextInput.length > MAX_QUERY_LENGTH

  const onTextInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInlineEditTextInput(e.target.value)
  }

  const internalOnSubmit = () => {
    onSubmit(inlineEditTextInput)
    setInlineEditTextInput('')
  }

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        if (!inlineEditTextInput.length) {
          onHide()
        } else {
          event.stopPropagation()
          if (wiggle.current) {
            popoverRef.current.classList.remove('animate-wiggle-important')
            void popoverRef.current.offsetWidth
            popoverRef.current.classList.add('animate-wiggle-important')
          }
          wiggle.current = false
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside, true)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [onHide, inlineEditTextInput])

  const onCancel = () => {
    setInlineEditTextInput('')
    onHide()
  }

  const overrideCopy = useCallback(() => {
    const textToCopy = selectedInlineEditText.length
      ? selectedInlineEditText
      : selectedText
    navigator.clipboard
      .writeText(textToCopy)
      .catch(() => console.error('Failed to copy text'))
  }, [selectedText, selectedInlineEditText])

  const handleSelect = () => {
    const selection = window.getSelection()
    const text = selection?.toString()
    setSelectedInlineEditText(text ?? '')
  }

  const handleOpenAutoFocus = (e: Event) => {
    e.preventDefault()
    textareaRef.current?.focus()
  }

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const tooltip = submitDisabled ? disabledReason : 'Submit'
  return (
    <PopoverContent
      className="p-2"
      ref={popoverRef}
      onClick={stopPropagation}
      onCopy={overrideCopy}
      onEscapeKeyDown={onCancel}
      onOpenAutoFocus={handleOpenAutoFocus}
      {...popoverContentProps}
    >
      <Textarea
        ref={textareaRef}
        className="resize-none border-0 p-2 text-xs placeholder:text-xs"
        placeholder="Describe how to change the selected text"
        value={inlineEditTextInput}
        onChange={onTextInputChange}
        maxLength={MAX_QUERY_LENGTH}
        overflowHintSide="end"
        onSelect={handleSelect}
      />
      <div className="flex justify-end space-x-2 pt-1">
        <Button onClick={onCancel} size="sm" variant="ghost">
          Cancel
        </Button>
        <AskHarveyButton
          className="w-auto text-xs font-semibold"
          handleSubmit={internalOnSubmit}
          disabled={submitDisabled}
          {...(tooltip && { tooltip })}
          size="sm"
          inputRef={textareaRef}
          // don't enforce client matter selection since this is a follow up revision
          shouldEnforceClientMatterSelection={false}
        />
      </div>
    </PopoverContent>
  )
}
