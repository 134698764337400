import React, { useEffect, useMemo, useState } from 'react'

import {
  InfoIcon,
  MessageSquarePlus,
  Move,
  RotateCw,
  Trash,
  X,
} from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { Event } from 'models/event'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'
import { VaultFile } from 'openapi/models/VaultFile'

import { TodayOption, readableFormat } from 'utils/date-utils'
import { useNavigateWithQueryParams } from 'utils/routing'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import Combobox from 'components/ui/combobox/combobox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Spinner } from 'components/ui/spinner'
import Toolbelt, {
  ToolbeltButton,
  ToolbeltDivider,
  ToolbeltMenu,
} from 'components/ui/toolbelt'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import useRecentQueries from 'components/vault/hooks/use-recent-queries'
import useRetryHandler from 'components/vault/hooks/use-retry-handler'
import {
  DOT_SEPARATOR,
  GenerateNNResponseProps,
  NUM_ALL_QUERIES_TO_FETCH,
  REMOVE_PARAMS,
  VaultItemType,
  projectsPath,
  queriesPath,
} from 'components/vault/utils/vault'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  retryReview,
  getSortedFilesBasedOnReviewQueryOrder,
  getExistingCompletedVaultReviewQueries,
} from 'components/vault/utils/vault-helpers'
import {
  VaultExtraSocketState,
  VaultReviewSocketState,
  useVaultStore,
} from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'
import {
  getQueryUsageStringWithUnit,
  useVaultUsageStore,
} from 'components/vault/utils/vault-usage-store'

const VaultFileExplorerToolbelt = ({
  generateNNResponse,
  className,
}: {
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  className?: string
}) => {
  const { trackEvent } = useAnalytics()
  const folderIdToVaultFolder = useVaultStore(
    useShallow((s) => s.folderIdToVaultFolder)
  )
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const setDeleteRecords = useVaultStore((s) => s.setDeleteRecords)
  const setIsDeleteDialogOpen = useVaultStore((s) => s.setIsDeleteDialogOpen)
  const setMoveRecords = useVaultStore((s) => s.setMoveRecords)
  const setIsMoveDialogOpen = useVaultStore((s) => s.setIsMoveDialogOpen)
  const setIsTextAreaFocused = useVaultStore((s) => s.setIsTextAreaFocused)
  const setPendingQueryFileIds = useVaultStore((s) => s.setPendingQueryFileIds)
  const setRequiresProjectDataRefetch = useVaultStore(
    (state) => state.setRequiresProjectDataRefetch
  )

  const selectedRows = useVaultFileExplorerStore(
    useShallow((s) => s.selectedRows)
  )
  const setSelectedRows = useVaultFileExplorerStore((s) => s.setSelectedRows)

  const [
    isAddFilesToExistingQueryDialogOpen,
    setIsAddFilesToExistingQueryDialogOpen,
  ] = useState(false)

  // Normalize the selected rows to remove descendants of selected folders if all descendants are selected
  const normalizedSelectedRows = useMemo(() => {
    return selectedRows.filter((row) => {
      if (row.type !== VaultItemType.file && !row.isAllDescendantsSelected) {
        // If the row is a folder and not all descendants are selected, we don't want to include it in the normalized selected rows
        return false
      }
      if (row.type === VaultItemType.file && !row.data.path) {
        // Check that the path for the file exists, otherwise it is being uploaded (we cannot move or delete an uploading file)
        return false
      }
      const path = row.index.split('.')
      return !path.some((_, idx) => {
        if (idx === 0) {
          return false
        }
        const ancestorPath = path.slice(0, idx).join('.')
        return selectedRows.some(
          (r) => r.index === ancestorPath && r.isAllDescendantsSelected
        )
      })
    })
  }, [selectedRows])
  const selectedFiles = useMemo(() => {
    const files = selectedRows
      .filter((row) => row.type === VaultItemType.file)
      .map((item) => item.data as VaultFile)
      .filter(Boolean)
    return getSortedFilesBasedOnReviewQueryOrder(files, folderIdToVaultFolder)
  }, [selectedRows, folderIdToVaultFolder])

  const selectedReadyToQueryFiles = selectedFiles.filter(
    (file) => file.readyToQuery
  )
  const hasReadyToQueryFiles = selectedReadyToQueryFiles.length > 0

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setPendingQueryFileIds(selectedFiles.map((file) => file.id))
    } else {
      setPendingQueryFileIds(null)
    }
  }, [selectedFiles, setPendingQueryFileIds])

  const { historyData } = useRecentQueries({
    projectId: currentProjectMetadata.id,
    maxQueries: NUM_ALL_QUERIES_TO_FETCH,
    hasInProgressHistoryEvents: false,
  })
  const selectedReadyToQueryFileIds = new Set(
    selectedReadyToQueryFiles.map((file) => file.id)
  )
  const existingCompletedVaultReviewQueries =
    getExistingCompletedVaultReviewQueries(
      historyData?.events ?? [],
      selectedReadyToQueryFileIds
    )

  const isToolbarVisible = selectedRows.length > 0
  const displayText = `${pluralizeFiles(selectedFiles.length)} selected`
  // Only show the ready to query files if not all selected files are ready to query
  const displayTextForReadyToQueryFiles =
    selectedReadyToQueryFiles.length < selectedFiles.length
      ? `${pluralizeFiles(selectedReadyToQueryFiles.length)} ready to query`
      : ''

  const onDeselectAllRows = () => {
    setSelectedRows([])
  }

  const onStartQuery = () => {
    trackEvent('Vault File Selection Query Button Clicked', {
      action: 'start_query',
      num_files: selectedFiles.length,
    })
    setIsTextAreaFocused(true)
  }

  const onAddToExistingQuery = () => {
    trackEvent('Vault File Selection Query Button Clicked', {
      action: 'add_to_existing_query',
      num_files: selectedFiles.length,
    })
    setIsAddFilesToExistingQueryDialogOpen(true)
  }

  const selectedFailedFiles = selectedFiles.filter(
    // Only retry failed files that have a path (uploaded successfully)
    (file) => !file.readyToQuery && file.failureReason && file.path
  )
  const { onRetryHandler } = useRetryHandler()
  const onRetrySelectedRows = async () => {
    trackEvent('Vault Row Selection Retry Button Clicked', {
      num_files: selectedFailedFiles.length,
    })
    await onRetryHandler(selectedFailedFiles.map((file) => file.id))
    setSelectedRows([])
    setRequiresProjectDataRefetch(true)
  }
  const shouldShowRetryButton = selectedFailedFiles.length > 0

  const shouldShowMoveButton = normalizedSelectedRows.length > 0
  const onMoveSelectedRows = () => {
    trackEvent('Vault Row Selection Move Button Clicked', {
      num_files: normalizedSelectedRows.filter(
        (row) => row.type === VaultItemType.file
      ).length,
      num_folders: normalizedSelectedRows.filter(
        (row) =>
          row.type === VaultItemType.folder ||
          row.type === VaultItemType.project
      ).length,
    })
    setMoveRecords(normalizedSelectedRows)
    setIsMoveDialogOpen(true)
  }

  const shouldShowDeleteButton = normalizedSelectedRows.length > 0
  const onDeleteSelectedRows = () => {
    trackEvent('Vault Row Selection Delete Button Clicked', {
      num_files: normalizedSelectedRows.filter(
        (row) => row.type === VaultItemType.file
      ).length,
      num_folders: normalizedSelectedRows.filter(
        (row) =>
          row.type === VaultItemType.folder ||
          row.type === VaultItemType.project
      ).length,
    })
    setDeleteRecords(normalizedSelectedRows)
    setIsDeleteDialogOpen(true)
  }

  if (!isToolbarVisible) {
    return null
  }

  return (
    <>
      <Toolbelt
        className={cn(
          'flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0',
          className
        )}
      >
        <div className="flex items-center">
          <ToolbeltButton className="truncate rounded-l-md rounded-r-none border border-r-0 border-dotted border-neutral-500 hover:bg-neutral-900">
            {[displayText, displayTextForReadyToQueryFiles]
              .filter(Boolean)
              .join(DOT_SEPARATOR)}
          </ToolbeltButton>
          <ToolbeltButton
            icon={X}
            className="rounded-l-none rounded-r-md border border-dotted border-neutral-500"
            onClick={onDeselectAllRows}
          />
        </div>
        <ToolbeltDivider className="hidden md:block" />
        <div className="flex flex-wrap items-center justify-center gap-1 md:flex-nowrap md:gap-2">
          {hasReadyToQueryFiles &&
            (existingCompletedVaultReviewQueries.length > 0 ? (
              <ToolbeltMenu
                items={[
                  { label: 'Start a new query', onClick: onStartQuery },
                  {
                    label: 'Add to existing query',
                    onClick: onAddToExistingQuery,
                  },
                ]}
              >
                <ToolbeltButton
                  data-testid="vault-file-explorer-toolbar-query"
                  icon={MessageSquarePlus}
                >
                  Query
                </ToolbeltButton>
              </ToolbeltMenu>
            ) : (
              <ToolbeltButton
                data-testid="vault-file-explorer-toolbar-query"
                icon={MessageSquarePlus}
                onClick={onStartQuery}
              >
                Query
              </ToolbeltButton>
            ))}
          {shouldShowRetryButton && (
            <ToolbeltButton
              data-testid="vault-file-explorer-toolbar-retry"
              icon={RotateCw}
              onClick={onRetrySelectedRows}
            >
              Retry
            </ToolbeltButton>
          )}
          {shouldShowMoveButton && (
            <ToolbeltButton
              data-testid="vault-file-explorer-toolbar-move"
              icon={Move}
              onClick={onMoveSelectedRows}
            >
              Move
            </ToolbeltButton>
          )}
          {shouldShowDeleteButton && (
            <ToolbeltButton
              data-testid="vault-file-explorer-toolbar-delete"
              icon={Trash}
              onClick={onDeleteSelectedRows}
            >
              Delete
            </ToolbeltButton>
          )}
        </div>
      </Toolbelt>
      <AddFilesToExistingQueryDialog
        selectedReadyToQueryFiles={selectedReadyToQueryFiles}
        existingCompletedVaultReviewQueries={
          existingCompletedVaultReviewQueries
        }
        open={isAddFilesToExistingQueryDialogOpen}
        onOpenChange={setIsAddFilesToExistingQueryDialogOpen}
        generateNNResponse={generateNNResponse}
      />
    </>
  )
}

const AddFilesToExistingQueryDialog = ({
  selectedReadyToQueryFiles,
  existingCompletedVaultReviewQueries,
  open,
  onOpenChange,
  generateNNResponse,
}: {
  selectedReadyToQueryFiles: VaultFile[]
  existingCompletedVaultReviewQueries: Event[]
  open: boolean
  onOpenChange: (open: boolean) => void
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const navigate = useNavigateWithQueryParams()
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const queryIdToReviewState = useVaultStore((s) => s.queryIdToReviewState)
  const setReviewTask = useVaultStore((s) => s.setReviewTask)
  const markHistoryTaskAsFromStreaming = useVaultStore(
    (s) => s.markHistoryTaskAsFromStreaming
  )
  const reviewQueryCapExplanation = useVaultUsageStore(
    (state) => state.reviewQueryCapExplanation
  )
  const reviewQueryDenominator = useVaultUsageStore(
    (state) => state.reviewQueryDenominator
  )
  const reviewQueryUnit = useVaultUsageStore((state) => state.reviewQueryUnit)
  const reviewQueryLimitUnitLevel = useVaultUsageStore(
    (state) => state.reviewQueryLimitUnitLevel
  )

  const [selectedQueryId, setSelectedQueryId] = useState<string>('')
  const [isAddingToQuery, setIsAddingToQuery] = useState(false)

  const onDismiss = () => {
    setSelectedQueryId('')
    setIsAddingToQuery(false)
    onOpenChange(false)
  }
  const onAddToQuerySubmit = async () => {
    if (!selectedQueryId) {
      return
    }

    setIsAddingToQuery(true)
    const success = await retryReview({
      generateNNResponse,
      setReviewTask,
      markHistoryTaskAsFromStreaming,
      projectId: currentProjectMetadata.id,
      queryId: selectedQueryId,
      fileIds: selectedReadyToQueryFiles.map((file) => file.id),
      queryIdToState,
      queryIdToReviewState,
      requestType: 'extra_files',
    })
    if (success) {
      const href = `${BaseAppPath.Vault}${projectsPath}${currentProjectMetadata.id}${queriesPath}${selectedQueryId}`
      navigate(href, {}, REMOVE_PARAMS)
    }
    onDismiss()
  }

  const options = existingCompletedVaultReviewQueries.map((query) => {
    const title = (query.metadata as VaultExtraSocketState).title
    const questions = (query.metadata as VaultReviewSocketState).questions
    return {
      label: title,
      value: String(query.id),
      extras: [
        `${questions.length} ${pluralize('question', questions.length)}`,
        readableFormat(new Date(query.updatedAt), TodayOption.showTime),
      ],
    }
  })
  const selectedQuery = existingCompletedVaultReviewQueries.find(
    (query) => String(query.id) === selectedQueryId
  )
  const currentQueryUsage =
    selectedQuery && reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.CELL
      ? selectedReadyToQueryFiles.length *
        (selectedQuery.metadata as VaultReviewSocketState).questions.length
      : reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.FILE
      ? selectedReadyToQueryFiles.length
      : 0
  const currentQueryUsageText =
    !!selectedQuery && currentQueryUsage > 0
      ? `Uses ${getQueryUsageStringWithUnit(
          currentQueryUsage,
          reviewQueryUnit,
          reviewQueryDenominator
        )}`
      : ''
  const containerRef = React.useRef<HTMLDivElement>(null)

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent ref={containerRef}>
        <DialogHeader>
          <DialogTitle>Add to existing query</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Select an existing query to add these files to
        </DialogDescription>
        <Combobox
          options={options}
          value={selectedQueryId}
          setValue={setSelectedQueryId}
          defaultText={
            options.length === 0
              ? 'No queries can be added to'
              : 'Select a query'
          }
          inputPlaceholder="Type to search queries"
          align="start"
          className="w-full"
          containerRef={containerRef}
        />
        <div className="mt-6 flex items-center justify-between gap-2">
          <div className="flex items-center gap-1">
            {currentQueryUsageText.length > 0 && (
              <>
                <p>{currentQueryUsageText}</p>
                <Tooltip delayDuration={200}>
                  <TooltipTrigger>
                    <Icon icon={InfoIcon} className="text-muted" />
                  </TooltipTrigger>
                  <TooltipContent className="max-w-sm text-start">
                    {reviewQueryCapExplanation}
                  </TooltipContent>
                </Tooltip>
              </>
            )}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="ghost"
              disabled={isAddingToQuery}
              onClick={onDismiss}
            >
              Cancel
            </Button>
            <Button disabled={isAddingToQuery} onClick={onAddToQuerySubmit}>
              {isAddingToQuery ? (
                <div className="flex items-center">
                  <Spinner className="top-3 mr-2 h-3 w-3" />
                  <p>Adding to query…</p>
                </div>
              ) : (
                <p>Add to query</p>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultFileExplorerToolbelt
