import React from 'react'
import { useMemo } from 'react'

import { Area, AreaChart, XAxis, YAxis } from 'recharts'

import { UsageData } from 'models/usage-data'

import { readableNumber } from 'utils/utils'

import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  DATA_VIZ_COLORS,
} from 'components/ui/chart-components/chart'
import Skeleton from 'components/ui/skeleton'

import { ChartConfigType, CHART_CONFIGS, TOTAL_KEY } from './dashboard-page'

export interface AreaChartCardProps {
  usageData: UsageData | undefined
  isLoading: boolean
  unit: ChartConfigType
  selectedKey: string
}

const AreaChartCard = ({
  usageData,
  unit,
  isLoading,
  selectedKey,
}: AreaChartCardProps) => {
  const totalGraphCount = useMemo(() => {
    if (!usageData) {
      return 0
    }
    switch (unit) {
      case CHART_CONFIGS.users:
        if (selectedKey === TOTAL_KEY) {
          return usageData.aggregate.userCountTotal
        }
        return usageData.aggregate.userCountByType[selectedKey] ?? 0
      case CHART_CONFIGS.queries:
        if (selectedKey === TOTAL_KEY) {
          return usageData.aggregate.queryCountTotal
        }
        return usageData.aggregate.queryCountByType[selectedKey] ?? 0
      default:
        console.error(`Unknown unit: ${unit}`)
        return 0
    }
  }, [selectedKey, usageData, unit])

  // NOTE maybe we just let area chart handle this with data keys
  const chartData = useMemo(() => {
    if (!usageData) {
      return []
    }
    switch (unit) {
      case CHART_CONFIGS.users:
        if (selectedKey === TOTAL_KEY) {
          return usageData.timeseries.map((data) => ({
            timestamp: data.timestamp,
            parsedDateShortened: data.parsedDateShortened,
            parsedDateString: data.parsedDateString,
            count: data.userCountTotal,
          }))
        }
        return usageData.timeseries.map((data) => ({
          timestamp: data.timestamp,
          parsedDateShortened: data.parsedDateShortened,
          parsedDateString: data.parsedDateString,
          count: data.userCountByType[selectedKey] ?? 0,
        }))
      case CHART_CONFIGS.queries:
        if (selectedKey === TOTAL_KEY) {
          return usageData.timeseries.map((data) => ({
            timestamp: data.timestamp,
            parsedDateShortened: data.parsedDateShortened,
            parsedDateString: data.parsedDateString,
            count: data.queryCountTotal,
          }))
        }
        return usageData.timeseries.map((data) => ({
          timestamp: data.timestamp,
          parsedDateShortened: data.parsedDateShortened,
          parsedDateString: data.parsedDateString,
          count: data.queryCountByType[selectedKey] ?? 0,
        }))
    }
  }, [usageData, selectedKey, unit])

  const chartConfig = {
    count: {
      label: unit.tooltipLabel,
    },
  }

  return (
    <Card className="rounded-lg border">
      <CardHeader>
        <CardTitle>
          <div className="pl-6">
            <span className="mr-2 text-xl">
              {readableNumber(totalGraphCount)}
            </span>
            <span className="text-base text-muted">{unit.label}</span>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="h-80">
        {isLoading && <Skeleton />}
        {!isLoading && (
          <ChartContainer config={chartConfig}>
            <AreaChart
              data={chartData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="parsedDateShortened"
                interval="equidistantPreserveStart"
              />
              <YAxis />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent payloadKey="parsedDateString" />}
              />
              <Area
                dataKey="count"
                stroke={DATA_VIZ_COLORS[0]}
                fill={DATA_VIZ_COLORS[0]}
                type="monotone"
              />
            </AreaChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  )
}

export default AreaChartCard
