import React from 'react'

import { isEqual, noop } from 'lodash'

import { WorkflowType } from 'types/workflows'

import {
  COMPANY_PROFILE_EMPTY_HELP_TEXT,
  COMPANY_PROFILE_SELECT_FIELD_HELP_TEXT,
  IS_LOADING_HELP_TEXT,
} from 'utils/tooltip-texts'
import { isUserInputEmpty } from 'utils/utils'

import { AppHeaderActions } from 'components/common/app-header-actions'
import AskHarveyButton from 'components/common/ask-harvey-button'
import ExportDialog from 'components/common/export/export-dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { WorkflowTaxonomySelector } from 'components/workflows/workflow/components/workflow-taxonomy-selector'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import { useCompanyProfileStore } from './store'

type Props = {
  onSubmit: () => void
  onReset: () => void
}

export const CompanyProfileInputForm: React.FC<Props> = ({
  onSubmit,
  onReset,
}) => {
  const {
    setTask,
    isLoading,
    query,
    setSelectedTaxonomies,
    selectedTaxonomies,
    availableTaxonomies,
  } = useCompanyProfileStore()

  const onInputChanged = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setTask({ query: e.target.value })
  }

  const isSubmitDisabled =
    query.trim().length === 0 ||
    selectedTaxonomies.flatMap((t) => t.rows).length === 0 ||
    isLoading

  const getButtonTooltip = () => {
    if (query.trim().length === 0) {
      return COMPANY_PROFILE_EMPTY_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else if (selectedTaxonomies.flatMap((t) => t.rows).length === 0) {
      return COMPANY_PROFILE_SELECT_FIELD_HELP_TEXT
    } else {
      return ''
    }
  }

  const allSelected = isEqual(selectedTaxonomies, availableTaxonomies)
  const emptyUserInput = isUserInputEmpty([query]) && allSelected
  const resetDisabled = emptyUserInput || isLoading

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  return (
    <WorkflowLayout
      workflowType={WorkflowType.COMPANY_PROFILE}
      title="Company profile"
      appHeaderActions={
        <>
          <AppHeaderActions
            handleReset={onReset}
            resetDisabled={resetDisabled}
          />
          <ExportDialog hasSources onExport={noop} disabled />
        </>
      }
    >
      <div>
        <Label>Stock ticker</Label>
        <Input
          ref={textareaRef as React.RefObject<any>}
          className="mr-1 max-w-xs"
          placeholder="E.g. AAPL…"
          value={query}
          maxLength={32}
          // @ts-expect-error TODO
          onChange={onInputChanged}
          data-testid="prompt-input"
        />
      </div>

      <Label className="mb-1 mt-4">Fields</Label>
      <WorkflowTaxonomySelector
        availableTaxonomies={availableTaxonomies}
        selectedTaxonomies={selectedTaxonomies}
        setSelectedTaxonomies={setSelectedTaxonomies}
      />
      <div className="mt-4 flex flex-col rounded-lg bg-muted px-4 py-3 lg:flex-row lg:items-center lg:justify-between">
        <p className="font-semibold">
          Harvey will return the company profile based on the selected fields
        </p>
        <div className="flex items-center">
          <AskHarveyButton
            handleSubmit={onSubmit}
            disabled={isSubmitDisabled}
            tooltip={getButtonTooltip()}
            isLoading={isLoading}
            inputRef={textareaRef}
          />
        </div>
      </div>
    </WorkflowLayout>
  )
}
