/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DefaultAdminPermission = {
    VIEWWORKSPACE_INFO: 'view:workspace_info',
    VIEWUSAGE_DASHBOARD: 'view:usage_dashboard',
    READWORKSPACE_HISTORY: 'read:workspace_history',
    DELETEWORKSPACE_HISTORY: 'delete:workspace_history',
    CLIENT_ADMINVIEW_USERS: 'client_admin:view_users',
    CLIENT_ADMINADD_USERS: 'client_admin:add_users',
    ADMINTERRITORY: 'admin:territory'
} as const;
export type DefaultAdminPermission = typeof DefaultAdminPermission[keyof typeof DefaultAdminPermission];


export function instanceOfDefaultAdminPermission(value: any): boolean {
    for (const key in DefaultAdminPermission) {
        if (Object.prototype.hasOwnProperty.call(DefaultAdminPermission, key)) {
            if (DefaultAdminPermission[key as keyof typeof DefaultAdminPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DefaultAdminPermissionFromJSON(json: any): DefaultAdminPermission {
    return DefaultAdminPermissionFromJSONTyped(json, false);
}

export function DefaultAdminPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultAdminPermission {
    return json as DefaultAdminPermission;
}

export function DefaultAdminPermissionToJSON(value?: DefaultAdminPermission | null): any {
    return value as any;
}

