import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'

import { Event } from 'models/event'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'

import { FetchVaultHistoryByPage } from 'components/vault/utils/vault-fetcher'
import { updateQueryStateForEvent } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

type UseRecentQueriesProps = {
  projectId: string
  maxQueries: number
  hasInProgressHistoryEvents: boolean
}

const useRecentQueries = ({
  projectId,
  maxQueries,
  hasInProgressHistoryEvents,
}: UseRecentQueriesProps) => {
  const setTask = useVaultStore((s) => s.setTask)
  const setReviewTask = useVaultStore((s) => s.setReviewTask)

  const { data: historyData, isPending: isLoadingHistory } = useQuery({
    queryKey: [HarvQueryKeyPrefix.VaultHistoryQuery, projectId, maxQueries],
    queryFn: () =>
      FetchVaultHistoryByPage({
        currentPage: 1,
        // TODO: support more than 100 queries for a vault project
        // https://linear.app/harveyai/issue/PLT-1501/scale-vault-queries-page-to-support-100-queries-per-project
        pageSize: maxQueries + 1,
        vaultFolderId: projectId,
      }),
    // Poll for history updates every 10 seconds if there are any in progress
    refetchInterval: hasInProgressHistoryEvents ? 10_000 : false,
    // Disable refetch on window focus to prevent unnecessary re-fetches
    refetchOnWindowFocus: false,
    enabled: !!projectId,
  })
  useEffect(() => {
    if (historyData && !isEmpty(historyData.events)) {
      historyData.events.forEach((event: Event) => {
        updateQueryStateForEvent(event, setTask, setReviewTask)
      })
    }
  }, [historyData, setTask, setReviewTask])

  return { historyData, isLoadingHistory }
}

export default useRecentQueries
