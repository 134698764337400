import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { useNavigateWithQueryParams } from 'utils/routing'
import { displayErrorMessage, displayWarningMessage } from 'utils/toast'
import {
  QUERY_MUST_HAVE_X_LEN_HELP_TEXT,
  UPLOAD_DOCUMENT_HELP_TEXT,
} from 'utils/tooltip-texts'
import { cn, isAnchorOrButton, isInPopup } from 'utils/utils'

import { AssistantChatStreamHandler } from 'components/assistant-v2/hooks/use-assistant-chat'
import { AssistantDraftStreamHandler } from 'components/assistant-v2/hooks/use-assistant-draft'
import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { forkEventDocuments } from 'components/assistant-v2/utils/assistant-api'
import {
  FORK_EVENT_STATE_KEY,
  PROMPT_PARAM,
  getQueryLimit,
} from 'components/assistant-v2/utils/assistant-helpers'
import { getKnowledgeSources } from 'components/assistant-v2/utils/assistant-knowledge-sources'
import {
  ASSISTANT_V2_MODE_HELP,
  ASSISTANT_V2_OPEN_ENDED_HELP,
  ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP,
} from 'components/assistant/assistant-constants'
import {
  MIN_QUERY_LENGTH,
  isQueryValid,
} from 'components/assistant/assistant-utils'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import Markdown from 'components/common/markdown/markdown'
import useChangeHistory from 'components/common/use-change-history'
import { useNavigationQueryState } from 'components/common/use-navigation-query-state'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import Link from 'components/ui/link/link'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
  SheetTitle,
  SheetDescription,
} from 'components/ui/sheet'
import { Textarea } from 'components/ui/text-area'

import AssistantKnowledgeSourceInput from './assistant-knowledge-source-input'
import { AssistantPromptButtonGroup } from './assistant-library-prompt-button-group'
import AssistantModeSelect, { AssistantMode } from './assistant-mode-select'
import AssistantQueryLimitTooltip from './assistant-query-limit-tooltip'

interface Props {
  defaultMode?: AssistantMode
  useChat?: AssistantChatStreamHandler
  useDraft?: AssistantDraftStreamHandler
}

const AssistantInput = ({ defaultMode, useChat, useDraft }: Props) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const isDocumentUser = userInfo.isDocumentUser
  const [mode, setMode] = useState(defaultMode ?? AssistantMode.ASSIST)
  const knowledgeSources = getKnowledgeSources(userInfo)

  const [filesAskHarveyDisabled, setFilesAskHarveyDisabled] = useState(false)

  const [
    query,
    eventId,
    documentsUploading,
    documents,
    setDocuments,
    setEventId,
    setQuery,
  ] = useAssistantStore(
    useShallow((s) => [
      s.query,
      s.eventId,
      s.documentsUploading,
      s.documents,
      s.setDocuments,
      s.setEventId,
      s.setQuery,
    ])
  )

  const hasDocuments = !!(documents.length || documentsUploading.length)
  const queryLimit = getQueryLimit(hasDocuments)

  const [queryPreview, setQueryPreview] = useState<string | null>(null)
  const handleSetQuery = useCallback(
    (q: string) => {
      if (q.length > queryLimit && q.length >= query.length) {
        displayWarningMessage(
          `Query length cannot exceed ${queryLimit} characters`
        )
      } else {
        setQuery(q)
        setQueryPreview('')
      }
    },
    [query, queryLimit, setQuery, setQueryPreview]
  )

  const navigate = useNavigateWithQueryParams()

  const { eventId: currentEventId } = useParams()
  const authCallback = useCallback(
    (eventId: string) => {
      if (eventId && eventId !== currentEventId) {
        navigate(`/assistant/${mode}/${eventId}`, {}, [PROMPT_PARAM])
      }
      setQuery('')
      setQueryPreview('')
    },
    [mode, navigate, currentEventId, setQuery, setQueryPreview]
  )

  const handleAsk = useCallback(
    async (prompt: string) => {
      // Navigate early if we have documents that are still uploading.
      // If we do this, then don't call navigate again after socket auth.
      let socketAuthCallback: ((eventId: string) => void) | undefined
      if (eventId) authCallback(eventId)
      else socketAuthCallback = authCallback

      if (mode === AssistantMode.ASSIST) {
        if (!useChat) throw new Error('useChat is required')
        await useChat.initChat(prompt, eventId, socketAuthCallback)
      } else {
        if (!useDraft) throw new Error('useDraft is required')
        await useDraft.initDraft(prompt, eventId, socketAuthCallback)
      }
    },
    [eventId, mode, authCallback, useChat, useDraft]
  )

  const handleAskClick = () => {
    void handleAsk(query)
  }

  const [isForking, setIsForking] = useState(false)

  const isRequiredDocumentMissing = !userInfo.IsBaseUser && !hasDocuments
  const isAskHarveyDisabled =
    !isQueryValid(query, queryLimit) ||
    isRequiredDocumentMissing ||
    filesAskHarveyDisabled ||
    isForking

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  const handleSelectPrompt = (prompt: string) => {
    setValue(prompt)
    setTimeout(() => textareaRef.current?.focus(), 0)
  }

  const { handleKeyDown, setValue } = useChangeHistory(handleSetQuery)

  const navigationState = useNavigationQueryState()
  const initializedNavState = useRef(false)
  useEffect(() => {
    if (initializedNavState.current) return
    if (navigationState?.query) {
      setValue(navigationState.query)
    }
    if (navigationState?.mode) {
      setMode(navigationState.mode as AssistantMode)
    }
    if (navigationState?.[FORK_EVENT_STATE_KEY]) {
      const forkEvent = async () => {
        setIsForking(true)
        const forkedEvent = await forkEventDocuments(
          navigationState[FORK_EVENT_STATE_KEY]
        )
        setEventId(forkedEvent.eventId)
        setDocuments(forkedEvent.documents)
      }
      forkEvent()
        .catch(() =>
          displayErrorMessage(
            'Sorry, something wrong creating a thread with documents'
          )
        )
        .finally(() => setIsForking(false))
    }
    if (navigationState) {
      initializedNavState.current = true
    }
  }, [navigationState, setDocuments, setEventId, setValue])

  const placeholder =
    queryPreview ||
    (mode === AssistantMode.DRAFT
      ? 'Ask Harvey to write a draft…'
      : 'Ask Harvey anything…')

  const getDisabledText = () => {
    if (query.trim().length < MIN_QUERY_LENGTH) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`at least ${MIN_QUERY_LENGTH}`)
    } else if (query.trim().length > queryLimit) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`fewer than ${queryLimit}`)
    } else if (isRequiredDocumentMissing) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (isForking) {
      return 'Waiting for documents to be duplicated'
    } else {
      return ''
    }
  }

  const handleActionsWhiteSpaceClick = (e: React.MouseEvent) => {
    const target = e.target as Element | null
    if (isAnchorOrButton(target) || isInPopup(target)) {
      e.stopPropagation()
    } else {
      textareaRef.current?.focus()
    }
  }

  const queryValue = queryPreview ? '' : query
  const textareaDisabled = !!queryPreview
  const hasUploadedFiles =
    isForking || documents.length > 0 || documentsUploading.length > 0

  let tipsText = isDocumentUser
    ? ASSISTANT_V2_OPEN_ENDED_HELP
    : ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP

  if (!userInfo.IsAssistantDraftUser) {
    tipsText = tipsText.replace(ASSISTANT_V2_MODE_HELP, '')
  }

  const bannerText =
    mode === AssistantMode.ASSIST
      ? 'Quickly search, analyze, or understand material, then ask follow-up questions'
      : 'Generate content like emails, contract clauses, or sections of briefs, then revise your draft'

  const eventKind =
    mode === AssistantMode.DRAFT
      ? EventKind.ASSISTANT_DRAFT
      : EventKind.ASSISTANT_CHAT

  const [searchParams, setSearchParams] = useSearchParams()
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useMount(async () => {
    // Automatically run a query for a prompt provided in query params
    const prompt = searchParams.get(PROMPT_PARAM)

    if (prompt === null) return

    // setTimeout is needed in development due to StrictMode. The second call to useMount interrupts the first call's
    // webSocket connection, and the first setQuery(prompt) call is cleared out by the second component mount.
    timeoutRef.current = setTimeout(async () => {
      if (!isQueryValid(prompt, queryLimit)) {
        setQuery(prompt)
        searchParams.delete(PROMPT_PARAM)
        setSearchParams(searchParams)
        return
      }

      // Delete the prompt search param in authCallback's call to navigate.
      await handleAsk(prompt)
    }, 100)
  })

  useUnmount(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  })

  return (
    <Card className="rounded bg-secondary" id="assistant-input">
      <CardHeader className="flex h-12 justify-end p-0 px-2">
        <div className="relative -bottom-px px-4">
          <AssistantModeSelect mode={mode} setMode={setMode} />
        </div>
      </CardHeader>
      <CardContent
        className={cn(
          'relative mx-2 rounded p-0',
          'before:pointer-events-none before:absolute before:inset-0 before:z-10 before:rounded before:ring-ring has-[textarea:focus]:before:ring-1',
          {
            'mb-2': !isDocumentUser,
          }
        )}
        id="assistant-text-input"
      >
        <div className="flex w-full items-center justify-between rounded rounded-b-none border bg-primary px-4 py-2">
          <p className="text-xs">{bannerText}</p>
          <Sheet>
            <SheetTrigger asChild>
              <Button
                onClick={() => trackEvent('Assistant Tips Viewed')}
                size="sm"
                variant="outline"
                className="shrink-0 text-nowrap"
              >
                View tips
              </Button>
            </SheetTrigger>
            <SheetContent className="w-full sm:max-w-3xl">
              <SheetHeader className="border-none bg-transparent">
                <SheetTitle className="text-xl">Tips</SheetTitle>
              </SheetHeader>
              <SheetDescription className="p-6">
                <Markdown content={tipsText} />
                {userInfo.IsViewHelpCenterUser && (
                  <p className="mt-6 text-primary">
                    For more information, please visit the{' '}
                    <Link
                      className="underline underline-offset-4"
                      target="_blank"
                      to="https://help.harvey.ai/en/articles/9740801-using-assistant-s-assist-and-draft-features"
                    >
                      Help Center
                    </Link>
                    .
                  </p>
                )}
              </SheetDescription>
            </SheetContent>
          </Sheet>
        </div>
        <Textarea
          className={cn(
            'max-h-96 resize-none rounded-none border-0 border-l border-r p-6 pb-0 ring-inset focus-visible:ring-0',
            {
              'disabled:cursor-default': queryPreview,
            }
          )}
          disabled={textareaDisabled}
          isFluid={!queryPreview}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={textareaRef}
          value={queryValue}
        />
        <div className="absolute right-6 -mt-1">
          <AssistantQueryLimitTooltip query={query} queryLimit={queryLimit} />
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={cn(
            'flex w-full cursor-text justify-between rounded-b border border-t-0 bg-primary p-6 pt-5',
            {
              'rounded-b-none': hasUploadedFiles,
            }
          )}
          onClick={handleActionsWhiteSpaceClick}
        >
          <AssistantPromptButtonGroup
            query={query}
            eventKind={eventKind}
            setQuery={handleSelectPrompt}
            setQueryPreview={setQueryPreview}
          />
          <AskHarveyButton
            className="pointer-events-auto ml-auto w-auto shrink-0 whitespace-nowrap font-semibold"
            id="assistant-submit"
            handleSubmit={handleAskClick}
            tooltip={getDisabledText()}
            disabled={isAskHarveyDisabled}
            inputRef={textareaRef}
            shouldEnforceClientMatterSelection
          />
        </div>
      </CardContent>
      {knowledgeSources.size > 0 && (
        <CardContent id="assistant-document-input" className="p-2 pt-0">
          <AssistantKnowledgeSourceInput
            knowledgeSources={knowledgeSources}
            isForkingFiles={isForking}
            mode={mode}
            setFilesAskHarveyDisabled={setFilesAskHarveyDisabled}
          />
        </CardContent>
      )}
    </Card>
  )
}

export default AssistantInput
