import { create } from 'zustand'

import { Event } from 'models/event'

interface Examples {
  examples: Event[]
  useCases: string[]
  documentTypes: string[]
  setExamples: (examples: Event[]) => void
}

export const useExampleStore = create<Examples>()((set) => ({
  examples: [],
  useCases: [],
  documentTypes: [],
  setExamples: (examples) => {
    set(() => {
      const useCaseMap = new Map()
      const documentTypeMap = new Map()
      examples.forEach((e) => {
        if (e.useCase) useCaseMap.set(e.useCase, true)
        if (e.documentType) documentTypeMap.set(e.documentType, true)
      })

      return {
        examples,
        useCases: Array.from(useCaseMap.keys()),
        documentTypes: Array.from(documentTypeMap.keys()),
      }
    })
  },
}))
