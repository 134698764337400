import React, { useEffect, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import {
  createQueryCapRule,
  getUserQueryCapRules,
  QueryCapRule,
  updateQueryCapRule,
} from 'models/query-cap-rule'
import { addVaultReviewPackUsers, VaultSeats, VaultUser } from 'models/vault'
import { Workspace } from 'models/workspace'
import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'

import { TaskType } from 'utils/task'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

export enum ManageType {
  PROMOTE = 'promote',
  UPDATE = 'update',
}

interface VaultUserManageDialogProps {
  workspace: Workspace
  open: boolean
  onOpenChange: (open: boolean) => void
  user: VaultUser | undefined
  feature: WorkspaceFeature
  onAddOnUserUpdate: (vaultUser: VaultUser) => void
  onReviewPackUpdate: (seatsData: VaultSeats) => void
  manageType: ManageType
}

const VaultUserManageDialog = ({
  workspace,
  open,
  onOpenChange,
  user,
  feature,
  onAddOnUserUpdate,
  onReviewPackUpdate,
  manageType,
}: VaultUserManageDialogProps) => {
  const queryClient = useQueryClient()
  const [reviewLimit, setReviewLimit] = useState<number>(0)
  const manageVaultAddOn =
    feature === WorkspaceFeature.VAULT_ADD_ON &&
    manageType === ManageType.UPDATE

  const queryKey = [
    HarvQueryKeyPrefix.QueryCapRulesQuery,
    user?.userId,
    workspace.id,
  ]
  const { data: queryCapRules, isPending } = useQuery({
    queryKey,
    queryFn: () => getUserQueryCapRules(user?.userId, workspace.id),
    select: (data) =>
      data.filter(
        (rule) =>
          rule.unitLevel === user?.unitLevel &&
          rule.timeFrame === user.timeFrame &&
          rule.taskType === TaskType.VAULT_REVIEW &&
          rule.level === QueryCapRuleLevel.PER_USER
      ),
  })

  const queryCapRule =
    queryCapRules && queryCapRules.length > 0
      ? queryCapRules.find((rule) => rule.userId === user?.userId) ||
        queryCapRules[0]
      : undefined

  useEffect(() => {
    if (queryCapRule && manageVaultAddOn) {
      setReviewLimit(queryCapRule.value)
    } else {
      setReviewLimit(0)
    }
  }, [queryCapRule, manageVaultAddOn])

  const onManage = async () => {
    if (!user) return
    try {
      if (manageVaultAddOn) {
        const newQueryCapRule: QueryCapRule = {
          id: queryCapRule?.id || '',
          level: QueryCapRuleLevel.PER_USER,
          workspaceId: workspace.id,
          userId: user.userId,
          taskType: TaskType.VAULT_REVIEW,
          timeFrame: user.timeFrame,
          unitLevel: user.unitLevel,
          value: reviewLimit,
        }
        if (queryCapRule && queryCapRule.userId === user.userId) {
          await updateQueryCapRule(newQueryCapRule)
        } else {
          await createQueryCapRule(newQueryCapRule)
        }
        await queryClient.invalidateQueries({ queryKey })
        onAddOnUserUpdate({ ...user, reviewLimit: reviewLimit })
      } else {
        const resp = await addVaultReviewPackUsers(workspace.id, {
          userEmail: user.userEmail,
          reviewQueryFileLimit: reviewLimit,
        })
        onReviewPackUpdate(resp)
      }
      displaySuccessMessage('User updated successfully', 5)
      onOpenChange(false)
    } catch (error) {
      const errMsg =
        error instanceof Error ? error.message : 'Failed to update user'
      displayErrorMessage(errMsg, 10)
    }
  }

  if (!user) return null

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogTitle>
          {manageType === ManageType.PROMOTE
            ? `Promote ${user.userEmail} to review pack`
            : `Update ${user.userEmail} ${_.lowerCase(user.unitLevel)} limit`}
        </DialogTitle>
        <div>
          <Label htmlFor="value">
            Review query {_.lowerCase(user.unitLevel)} limit
          </Label>
          <Input
            id="value"
            type="number"
            value={reviewLimit}
            onChange={(e) => setReviewLimit(parseInt(e.target.value))}
            placeholder="Enter value"
            min={0}
            disabled={manageVaultAddOn && isPending}
            className="mt-1 w-[200px]"
          />
        </div>
        <DialogFooter>
          <Button variant="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={onManage}
            disabled={reviewLimit <= 0 || (manageVaultAddOn && isPending)}
          >
            {_.startCase(manageType)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultUserManageDialog
