import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import _ from 'lodash'
import { ListFilter } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { EventKind } from 'openapi/models/EventKind'
import { ResearchArea } from 'openapi/models/ResearchArea'

import { exportWordWithQuery, replaceSpanWithLink } from 'utils/markdown'
import { useNavigateWithQueryParams } from 'utils/routing'
import { TaskType } from 'utils/task'
import useHarveySocket from 'utils/use-harvey-socket'
import { hasCompletedStreaming } from 'utils/use-harvey-socket-utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeader } from 'components/common/app-header'
import { AppHeaderActions } from 'components/common/app-header-actions'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import ExportDialog from 'components/common/export/export-dialog'
import { ExportOptionValues } from 'components/common/export/types'
import FullscreenLoading from 'components/common/fullscreen-loading'
import { ResearchDatePicker } from 'components/research/date-picker'
import Help from 'components/research/help'
import InputCard from 'components/research/input-card'
import { getTitle, isDoneStreaming } from 'components/research/research-helpers'
import { ResearchResponse } from 'components/research/research-response'
import { ResearchSheet } from 'components/research/research-sheet'
import { useResearchStore } from 'components/research/research-store'
import { Sources } from 'components/research/sources'
import { Button } from 'components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'

import { UsCaseLawFeedback } from './feedback'
import { JurisdictionFilter, isFederal } from './jurisdiction-filter'

const UsaCaseLaw = () => {
  const userInfo = useAuthUser()

  const { state } = useLocation()
  const { id: historyId } = useParams()

  const { taxonomy, isFetched: isTaxonomyFetched } = useResearchTaxonomyQuery(
    ResearchArea.USCASELAW,
    true /* isEnabled*/,
    false /* refetchOnWindowFocus */
  )

  const isHistoryFetchEnabled =
    !state ||
    state?.fetchHistoryItem === undefined ||
    state?.fetchHistoryItem === true
  const { historyItem, isFetched: isHistoryItemFetched } = useHistoryItemQuery({
    id: historyId,
    isEnabled: isHistoryFetchEnabled,
  })

  const hasHistoryId = !_.isEmpty(historyId)
  const isHistoryFetched =
    hasHistoryId && isHistoryFetchEnabled ? isHistoryItemFetched : true
  const isDataFetched = isTaxonomyFetched && isHistoryFetched

  const [
    annotations,
    completionStatus,
    dateRange,
    feedback,
    headerText,
    isLoading,
    query,
    queryId,
    resetState,
    response,
    selectedFilters,
    setFeedback,
    setSelectedFilters,
    setTask,
    sources,
  ] = useResearchStore(
    useShallow((s) => [
      s.annotations,
      s.completionStatus,
      s.dateRange,
      s.feedback,
      s.headerText,
      s.isLoading,
      s.query,
      s.queryId,
      s.resetState,
      s.response,
      s.selectedFilters,
      s.setFeedback,
      s.setSelectedFilters,
      s.setTask,
      s.sources,
    ])
  )

  const [setClientMatterSelectDisabled, selectedClientMatter] =
    useClientMattersStore(
      useShallow((s) => [
        s.setClientMatterSelectDisabled,
        s.selectedClientMatter,
      ])
    )

  const [error, setError] = useState<{
    message: string
    cta: { redirectUri: string; message: string }
  } | null>(null)

  useEffect(() => {
    // Set a default for jurisdiction filter if no filters are set
    if (selectedFilters.length) return

    const firstFederal = taxonomy.find(isFederal)
    if (firstFederal) {
      setSelectedFilters([firstFederal])
    }
  }, [selectedFilters, setSelectedFilters, taxonomy])

  useEffect(() => {
    if (!isDataFetched) return
    if (!_.isNil(historyItem)) {
      setTask({ ...historyItem, queryId: historyItem.id })
      const filters = taxonomy.filter((filter) =>
        (historyItem.filterIds ?? ([] as string[])).includes(filter.id)
      )
      setSelectedFilters(filters)
    } else if (!_.isEmpty(historyId) && isHistoryFetchEnabled) {
      setError({
        message: `The requested history item /${historyId} you are trying to access does not exist.\nContact support@harvey.ai if this issue persists.`,
        cta: {
          redirectUri: `/research/${ResearchArea.USCASELAW}`,
          message: `Back to ${getTitle(ResearchArea.USCASELAW)}`,
        },
      })
    }
  }, [
    isDataFetched,
    isHistoryFetchEnabled,
    historyId,
    historyItem,
    setSelectedFilters,
    setTask,
    taxonomy,
  ])

  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: 'research/query',
    setter: setTask,
    endCallback: () => {
      setClientMatterSelectDisabled(false)
    },
  })

  const {
    setQueryViewed,
    recordExport,
    recordQueryCancel,
    recordQuerySubmitted,
    recordQueryCompletion,
    recordReset,
  } = useQueryAnalytics(EventKind.USA_CASELAW)

  useMount(() => {
    if (historyId) {
      setQueryViewed()
    }
  })

  const navigate = useNavigateWithQueryParams()

  useEffect(() => {
    if (
      userInfo.IsHistoryUser &&
      queryId &&
      hasCompletedStreaming(completionStatus) &&
      !!response
    ) {
      // When query completes with a response, navigate to that query's page
      navigate(`/research/${ResearchArea.USCASELAW}/${queryId}`, {
        replace: true,
        state: { fetchHistoryItem: false },
      })

      const sourceDocumentIds = new Set(sources.map((s) => s.documentId))
      recordQueryCompletion({ num_sources: sourceDocumentIds.size })
    }
  }, [
    completionStatus,
    navigate,
    queryId,
    recordQueryCompletion,
    response,
    sources,
    userInfo,
  ])

  const handleResetPage = () => {
    resetState()
    recordReset()
    navigate(`/research/${ResearchArea.USCASELAW}`, { replace: true })
  }

  const handleCancel = () => {
    recordQueryCancel()
    sendCancelRequest()
  }

  const handleExport = async (exportValues: ExportOptionValues) => {
    if (!queryId) return
    const titleText = `Research – ${getTitle(ResearchArea.USCASELAW)}`
    const cleanedResponse = replaceSpanWithLink(response, annotations)
    const includeAnnotation = !!exportValues.includeAnnotation
    await exportWordWithQuery({
      taskType: TaskType.RESEARCH,
      includeAnnotation: includeAnnotation,
      titleText,
      queryId,
      query,
      response: cleanedResponse,
      sources: sources,
    })
    recordExport(String(queryId), 'word', includeAnnotation)
  }

  const handleAskHarvey = () => {
    recordQuerySubmitted({
      event_kind: EventKind.USA_CASELAW,
    })
    setFeedback(null)
    const filterIds = selectedFilters.map((filter) => filter.id)
    const client_matter_id = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null

    setClientMatterSelectDisabled(true)
    initSocketAndSendQuery({
      query,
      additionalAuthParams: {
        filter_ids: filterIds,
        client_matter_id,
      },
      additionalRequestParams: {
        // adding source_event_id if we have a query id
        // https://www.notion.so/harveyai/Make-sure-we-keep-honoring-event_source_id-04edcaaae0d8440a9b51808aef7af349?pvs=4
        source_event_id: queryId,
        date_range: dateRange,
      },
    })
  }

  useUnmount(() => {
    handleCancel()
    resetState()
  })

  if (error) {
    return (
      <ErrorPage
        title={ErrorPageTitle.PAGE_NOT_FOUND}
        description={error.message}
        primaryCTA={{
          text: error.cta.message,
          onClick: () => {
            setError(null)
            navigate(error.cta.redirectUri)
          },
        }}
      />
    )
  }

  const hasResponse = isDoneStreaming(response, headerText)
  const resetDisabled = isLoading && !hasCompletedStreaming(completionStatus)

  const filterTitle = selectedFilters.length
    ? selectedFilters[0].name
    : 'Jurisdictions'

  return (
    <AppMain hasContainer id="research-container">
      <FullscreenLoading isLoading={!isDataFetched} />
      <AppHeader
        title={getTitle(ResearchArea.USCASELAW)}
        subtitle="Explore AI-powered Case Law insights"
        actions={
          <div className="inline-flex space-x-2">
            <AppHeaderActions
              handleReset={handleResetPage}
              resetDisabled={resetDisabled}
              saveExample={{
                show: userInfo.canSeeSaveExample ?? false,
                disabled: !hasResponse,
                params: {
                  eventId: String(queryId),
                },
              }}
            />
            <ExportDialog
              hasSources
              onExport={handleExport}
              disabled={!hasResponse}
              variant="outline"
            />
          </div>
        }
      />
      <ResearchSheet />
      <div className="h-full space-y-4">
        <InputCard
          area={ResearchArea.USCASELAW}
          onAskHarvey={handleAskHarvey}
          placeholder="Select a jurisdiction and enter a research query"
          taxonomy={taxonomy}
        >
          <div className="absolute left-4 top-[124px] flex items-center space-x-2">
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="secondary" size="sm">
                  <ListFilter size={16} className="mr-1" />
                  <span className="text-xs">{filterTitle}</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full" align="start">
                <JurisdictionFilter isOpen taxonomy={taxonomy} />
              </PopoverContent>
            </Popover>
            <ResearchDatePicker />
          </div>
        </InputCard>
        {response && !feedback?.closed && queryId && (
          <UsCaseLawFeedback queryId={queryId} saveFeedback={setFeedback} />
        )}
        <ResearchResponse
          area={ResearchArea.USCASELAW}
          sendCancelRequest={handleCancel}
        />
        <Sources area={ResearchArea.USCASELAW} />
        <div className="h-8" />
      </div>
      <div className="fixed bottom-2 right-2 z-10">
        <Help area={ResearchArea.USCASELAW} />
      </div>
    </AppMain>
  )
}

export default UsaCaseLaw
