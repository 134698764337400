import React from 'react'

import _ from 'lodash'

import { ResearchFilter } from 'openapi/models/ResearchFilter'

import ExplorerColumn from 'components/research/explorer-column'
import { useResearchStore } from 'components/research/research-store'
import { ScrollArea } from 'components/ui/scroll-area'

interface ExplorerProps {
  isOpen: boolean
  taxonomy: ResearchFilter[]
}

const Explorer: React.FC<ExplorerProps> = ({ isOpen, taxonomy }) => {
  const openFilters = useResearchStore((s) => s.openFilters)
  if (!isOpen) return

  return (
    <ScrollArea hasHorizontalScroll>
      <div className="research-explorer flex overflow-clip border-t">
        <ExplorerColumn taxonomy={taxonomy} filters={taxonomy} depth={0} />
        {!_.isNil(openFilters[0]) && (
          <ExplorerColumn
            taxonomy={taxonomy}
            filters={openFilters[0].children}
            depth={1}
          />
        )}
        {!_.isNil(openFilters[1]) &&
          openFilters[1].parentId === openFilters[0].id &&
          openFilters[1].children.length > 0 && (
            <ExplorerColumn
              taxonomy={taxonomy}
              filters={openFilters[1].children}
              depth={2}
            />
          )}
      </div>
    </ScrollArea>
  )
}

export default Explorer
