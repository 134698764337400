/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SourceBadge } from './SourceBadge';
import {
    SourceBadgeFromJSON,
    SourceBadgeFromJSONTyped,
    SourceBadgeToJSON,
} from './SourceBadge';
import type { Source } from './Source';
import {
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
} from './Source';

/**
 * 
 * @export
 * @interface ExternalWebSource
 */
export interface ExternalWebSource extends Source {
    /**
     * 
     * @type {string}
     * @memberof ExternalWebSource
     */
    documentUrl: string;
}

/**
 * Check if a given object implements the ExternalWebSource interface.
 */
export function instanceOfExternalWebSource(value: object): value is ExternalWebSource {
    if (!('documentUrl' in value) || value['documentUrl'] === undefined) return false;
    return true;
}

export function ExternalWebSourceFromJSON(json: any): ExternalWebSource {
    return ExternalWebSourceFromJSONTyped(json, false);
}

export function ExternalWebSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalWebSource {
    if (json == null) {
        return json;
    }
    return {
        ...SourceFromJSONTyped(json, ignoreDiscriminator),
        'documentUrl': json['document_url'],
    };
}

export function ExternalWebSourceToJSON(value?: ExternalWebSource | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SourceToJSON(value),
        'document_url': value['documentUrl'],
    };
}

