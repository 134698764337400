import React, { useRef, useState } from 'react'

import { ChevronUp, ChevronDown } from 'lucide-react'

import { DiligenceSection } from 'openapi/models/DiligenceSection'

import { cn } from 'utils/utils'

import FullscreenLoading from 'components/common/fullscreen-loading'
import { Button } from 'components/ui/button'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { useMeasureNodeHeight } from 'components/workflows/workflow/discovery/common/use-measure-node-height'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import { getSizeInPercentage } from 'components/workflows/workflow/discovery/util'

import { FollowUpSectionWrapper } from './follow-up-input-section-wrapper'
import { ManySections } from './many-sections'
import { PdfDrawer } from './pdf-drawer'

interface Props {
  sectionsWithAnswers: DiligenceSection[]
  progress: number
}

export const ManySectionsWithPDF: React.FC<Props> = ({
  sectionsWithAnswers,
  progress,
}) => {
  const isLoading = (sectionsWithAnswers: DiligenceSection[]) => {
    return sectionsWithAnswers.some((section) => section.isLoading)
  }

  const viewingDocument = useDiligenceStore((state) => state.viewingDocument)

  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)

  const innerResizeablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)

  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout([100, 0])
    }
  }

  const { containerHeight, containerRef } = useMeasureNodeHeight()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // We need to set the min, max, and default sizes for the drawer when it is open or closed
  // Below, we choose the size in pixels, and then compute the percentage size to use for the ResizablePanel
  const drawerOpenHeight = 280 // pixels
  const drawerClosedHeight = 40 // pixels
  const defaultDrawerSize = isDrawerOpen
    ? getSizeInPercentage(drawerOpenHeight, containerHeight)
    : getSizeInPercentage(drawerClosedHeight, containerHeight)
  const minDrawerSize = defaultDrawerSize
  const maxDrawerSize = isDrawerOpen ? undefined : defaultDrawerSize
  const defaultReportPanelSize = 100 - defaultDrawerSize

  // This handler changes the layout from closed drawer to open drawer and vice versa
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
    if (innerResizeablePanelGroupRef.current && containerHeight) {
      innerResizeablePanelGroupRef.current.setLayout([
        defaultReportPanelSize,
        minDrawerSize,
      ])
    }
  }

  return (
    <div className="flex h-full w-full" ref={containerRef}>
      {isLoading(sectionsWithAnswers) && (
        <FullscreenLoading
          isLoading={isLoading(sectionsWithAnswers)}
          progress={progress}
          zIndex="z-1"
        />
      )}
      <ResizablePanelGroup direction="horizontal" ref={resizablePanelGroupRef}>
        {/* The report and drawer inner resizable panel */}
        <ResizablePanel defaultSize={100} minSize={20}>
          <ResizablePanelGroup
            direction="vertical"
            ref={innerResizeablePanelGroupRef}
          >
            <ResizablePanel defaultSize={defaultReportPanelSize} minSize={20}>
              <ManySections sections={sectionsWithAnswers} />
            </ResizablePanel>

            {/* The drawer is below */}
            {isDrawerOpen && <ResizableHandle withHandle />}

            <ResizablePanel
              defaultSize={defaultDrawerSize}
              minSize={minDrawerSize}
              maxSize={maxDrawerSize}
              className="transition-all ease-in-out"
            >
              <div
                className={cn('relative h-full border bg-neutral-200', {
                  'bg-primary': isDrawerOpen,
                })}
              >
                <Button
                  className="left-2 top-2 h-6 min-w-[100px] rounded-t-md p-2 hover:bg-neutral-100 focus:outline-none focus-visible:ring focus-visible:ring-ring"
                  variant="ghost"
                  size="sm"
                  onClick={toggleDrawer}
                >
                  {isDrawerOpen ? (
                    <>
                      <ChevronDown className="" />
                      <p className="text-md">Follow-up questions</p>
                    </>
                  ) : (
                    <>
                      <ChevronUp className="" />
                      <p className="text-md">Follow-up questions</p>
                    </>
                  )}
                </Button>
                {/* Drawer Content */}
                <div className="flex flex-col items-center overflow-auto p-4">
                  {isDrawerOpen && <FollowUpSectionWrapper />}
                </div>
                {/* End Drawer Content */}
              </div>
            </ResizablePanel>
            {/* End drawer */}
          </ResizablePanelGroup>
        </ResizablePanel>

        {/* The side panel for viewing documents */}
        {viewingDocument && (
          <>
            <ResizableHandle withHandle onDoubleClick={resetLayout} />
            <ResizablePanel defaultSize={0} minSize={40}>
              <PdfDrawer />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </div>
  )
}
