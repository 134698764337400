/* tslint:disable */
/* eslint-disable */
/**
 * Audit Logs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration of audit log types, don't change values without migrating DB data and API code.
 * @export
 */
export const AuditLogType = {
    APIAUDIT_LOG_FETCH: 'api:audit_log_fetch',
    APICREATE_QUERY: 'api:create_query',
    APICLIENT_MATTER_MANAGEMENT: 'api:client_matter_management',
    APIPWC_HR_WORKFORCE: 'api:pwc_hr_workforce',
    APIHISTORY_FETCH: 'api:history_fetch',
    APITRANSLATION: 'api:translation',
    APIICERTIS_FIELD_EXTRACTION: 'api:icertis_field_extraction',
    APIICERTIS_BATCH_FIELD_EXTRACTION: 'api:icertis_batch_field_extraction',
    APIICERTIS_REVIEW_DOCUMENT: 'api:icertis_review_document',
    AUTHLOGIN: 'auth:login',
    AUTHLOGOUT: 'auth:logout',
    AUTHFAILED: 'auth:failed',
    AUTOMATIONEVENT_DELETION: 'automation:event_deletion',
    AUTOMATIONEVENT_SANITIZATION: 'automation:event_sanitization',
    ADMINCLIENT_VIEW_WORKSPACE_HISTORY: 'admin:client_view_workspace_history',
    ADMINFETCH_WORKSPACE_HISTORY: 'admin:fetch_workspace_history',
    ADMINCLIENT_VIEW_WORKSPACE_HISTORY_ITEM: 'admin:client_view_workspace_history_item',
    ADMINFETCH_WORKSPACE_HISTORY_ITEM: 'admin:fetch_workspace_history_item',
    ADMINDELETE_WORKSPACE_HISTORY_ITEM: 'admin:delete_workspace_history_item',
    ADMINDELETE_WORKSPACE_HISTORY_ITEMS: 'admin:delete_workspace_history_items',
    ADMINEXPORT_WORKSPACE_HISTORY: 'admin:export_workspace_history',
    ADMINEXPORT_QUERY_USAGE: 'admin:export_query_usage',
    ADMINCLIENT_EXPORT_WORKSPACE_USERS: 'admin:client_export_workspace_users',
    ADMINADD_USERS: 'admin:add_users',
    ADMINREMOVE_USERS: 'admin:remove_users',
    ADMINFETCH_CLIENT_MATTERS: 'admin:fetch_client_matters',
    ADMINADD_CLIENT_MATTERS: 'admin:add_client_matters',
    ADMINDELETE_CLIENT_MATTERS: 'admin:delete_client_matters',
    ADMINUPDATE_CLIENT_MATTERS: 'admin:update_client_matters',
    ADMINMANAGE_CLIENT_MATTERS: 'admin:manage_client_matters',
    ADMINFETCH_STATS: 'admin:fetch_stats',
    ADMINFETCH_WORKSPACE_USERS: 'admin:fetch_workspace_users',
    ADMINUPDATE_SHARING_SETTINGS: 'admin:update_sharing_settings',
    INTERNAL_ADMINCLIENT_VIEW_WORKSPACE_HISTORY: 'internal_admin:client_view_workspace_history',
    INTERNAL_ADMINFETCH_WORKSPACE_HISTORY: 'internal_admin:fetch_workspace_history',
    INTERNAL_ADMINCLIENT_VIEW_WORKSPACE_HISTORY_ITEM: 'internal_admin:client_view_workspace_history_item',
    INTERNAL_ADMINFETCH_WORKSPACE_HISTORY_ITEM: 'internal_admin:fetch_workspace_history_item',
    INTERNAL_ADMINDELETE_WORKSPACE_HISTORY_ITEM: 'internal_admin:delete_workspace_history_item',
    INTERNAL_ADMINEXPORT_WORKSPACE_HISTORY: 'internal_admin:export_workspace_history',
    INTERNAL_ADMINEXPORT_QUERY_USAGE: 'internal_admin:export_query_usage',
    INTERNAL_ADMINCLIENT_EXPORT_WORKSPACE_USERS: 'internal_admin:client_export_workspace_users',
    INTERNAL_ADMINFETCH_CLIENT_MATTERS: 'internal_admin:fetch_client_matters',
    INTERNAL_ADMINADD_CLIENT_MATTERS: 'internal_admin:add_client_matters',
    INTERNAL_ADMINDELETE_CLIENT_MATTERS: 'internal_admin:delete_client_matters',
    INTERNAL_ADMINUPDATE_CLIENT_MATTERS: 'internal_admin:update_client_matters',
    INTERNAL_ADMINMANAGE_CLIENT_MATTERS: 'internal_admin:manage_client_matters',
    INTERNAL_ADMINFETCH_STATS: 'internal_admin:fetch_stats',
    INTERNAL_ADMINFETCH_WORKSPACE_USERS: 'internal_admin:fetch_workspace_users',
    INTERNAL_ADMINADD_SENSITIVE_DATA_PERMS: 'internal_admin:add_sensitive_data_perms',
    INTERNAL_ADMINREMOVE_SENSITIVE_DATA_PERMS: 'internal_admin:remove_sensitive_data_perms',
    MICROSOFTCREATE_QUERY: 'microsoft:create_query',
    MICROSOFTCREATE_QUERY_CUSTOM_ENGINE_COPILOT: 'microsoft:create_query_custom_engine_copilot',
    USERCREATE_QUERY: 'user:create_query',
    USERCLIENT_VIEW_HISTORY: 'user:client_view_history',
    USERFETCH_HISTORY: 'user:fetch_history',
    USERCLIENT_VIEW_HISTORY_ITEM: 'user:client_view_history_item',
    USERFETCH_HISTORY_ITEM: 'user:fetch_history_item',
    USERUPDATE_HISTORY_ITEM: 'user:update_history_item',
    USERDELETE_HISTORY_ITEM: 'user:delete_history_item',
    USERCANCEL_HISTORY_ITEM: 'user:cancel_history_item',
    USERFETCH_CLIENT_MATTERS: 'user:fetch_client_matters',
    USERADD_CLIENT_MATTERS: 'user:add_client_matters',
    USERDELETE_CLIENT_MATTERS: 'user:delete_client_matters',
    USERUPDATE_CLIENT_MATTERS: 'user:update_client_matters',
    USERFETCH_VAULT_TOP_LEVEL_FOLDERS: 'user:fetch_vault_top_level_folders',
    USERFETCH_VAULT_EXAMPLE_PROJECT: 'user:fetch_vault_example_project',
    USERSET_VAULT_EXAMPLE_PROJECT: 'user:set_vault_example_project',
    USERUNSET_VAULT_EXAMPLE_PROJECT: 'user:unset_vault_example_project',
    USERFETCH_VAULT_FOLDER_PATH: 'user:fetch_vault_folder_path',
    USERFETCH_PROJECT_METADATA: 'user:fetch_project_metadata',
    USERFETCH_VAULT_FOLDER: 'user:fetch_vault_folder',
    USERFETCH_VAULT_FILE: 'user:fetch_vault_file',
    USERFETCH_VAULT_FILES: 'user:fetch_vault_files',
    USERFETCH_VAULT_FOLDERS_BY_PATH: 'user:fetch_vault_folders_by_path',
    USERCREATE_VAULT_FOLDER: 'user:create_vault_folder',
    USERCREATE_VAULT_REVIEW_QUERY: 'user:create_vault_review_query',
    USERUPLOAD_VAULT_FILES: 'user:upload_vault_files',
    USERUPDATE_VAULT_FILE_METADATA: 'user:update_vault_file_metadata',
    USERUPDATE_VAULT_FOLDER_METADATA: 'user:update_vault_folder_metadata',
    USERDELETE_VAULT_FILES: 'user:delete_vault_files',
    USERDELETE_VAULT_FOLDER: 'user:delete_vault_folder',
    USERFETCH_QUERY_QUESTIONS: 'user:fetch_query_questions',
    USERSEMANTIC_SEARCH_WITH_VAULT_FOLDER: 'user:semantic_search_with_vault_folder',
    USERRETRY_VAULT_FILES: 'user:retry_vault_files',
    USERRERUN_VAULT_REVIEW_QUERIES: 'user:rerun_vault_review_queries',
    USERCLEAR_VAULT_QUERY_ERRORS: 'user:clear_vault_query_errors',
    USERFETCH_VAULT_REVIEW_QUERY_USAGE: 'user:fetch_vault_review_query_usage',
    USERFETCH_VAULT_FOLDER_HISTORY_STATS: 'user:fetch_vault_folder_history_stats',
    USERCREATE_VAULT_FOLDER_SHARING_PERMISSIONS: 'user:create_vault_folder_sharing_permissions',
    USERUPDATE_VAULT_FOLDER_SHARING_PERMISSIONS: 'user:update_vault_folder_sharing_permissions',
    USERDELETE_VAULT_FOLDER_SHARING_PERMISSIONS: 'user:delete_vault_folder_sharing_permissions',
    USERCREATE_EVENT_SHARING_PERMISSIONS: 'user:create_event_sharing_permissions',
    USERUPDATE_EVENT_SHARING_PERMISSIONS: 'user:update_event_sharing_permissions',
    INTERNALINVALID_HEADER: 'internal:invalid_header',
    INTERNAL_MGMTCREATE_WORKSPACE: 'internal_mgmt:create_workspace',
    INTERNAL_MGMTEDIT_WORKSPACE: 'internal_mgmt:edit_workspace',
    INTERNAL_MGMTDELETE_WORKSPACE: 'internal_mgmt:delete_workspace',
    INTERNAL_MGMTMERGE_WORKSPACE: 'internal_mgmt:merge_workspace',
    INTERNAL_MGMTUPDATE_USER_WORKSPACE: 'internal_mgmt:update_user_workspace',
    INTERNAL_MGMTADD_USERS: 'internal_mgmt:add_users',
    INTERNAL_MGMTREMOVE_USERS: 'internal_mgmt:remove_users',
    INTERNAL_MGMTGRANT_PERMS: 'internal_mgmt:grant_perms',
    INTERNAL_MGMTREVOKE_PERMS: 'internal_mgmt:revoke_perms',
    INTERNAL_MGMTCREATE_API_TOKEN: 'internal_mgmt:create_api_token',
    INTERNAL_MGMTDELETE_API_TOKEN: 'internal_mgmt:delete_api_token',
    INTERNAL_MGMTADD_PWC_USERS: 'internal_mgmt:add_pwc_users',
    INTERNAL_MGMTEXPORT_PWC_TAX: 'internal_mgmt:export_pwc_tax',
    INTERNAL_MGMTFETCH_USER_INFO: 'internal_mgmt:fetch_user_info',
    INTERNAL_MGMTFETCH_WORKSPACE_DETAILS: 'internal_mgmt:fetch_workspace_details',
    INTERNAL_MGMTUPDATE_EVENT_WORKSPACE: 'internal_mgmt:update_event_workspace',
    INTERNAL_MGMTUPDATE_USER_EVENTS_WORKSPACE: 'internal_mgmt:update_user_events_workspace',
    INTERNAL_MGMTDELETE_WORKSPACE_HISTORY: 'internal_mgmt:delete_workspace_history',
    INTERNAL_MGMTDELETE_WORKSPACE_HISTORY_BULK: 'internal_mgmt:delete_workspace_history_bulk',
    INTERNAL_MGMTCREATE_WORKSPACE_OFFBOARD: 'internal_mgmt:create_workspace_offboard',
    INTERNAL_MGMTEDIT_WORKSPACE_OFFBOARD: 'internal_mgmt:edit_workspace_offboard',
    INTERNAL_MGMTCANCEL_WORKSPACE_OFFBOARD: 'internal_mgmt:cancel_workspace_offboard',
    INTERNAL_MGMTEXECUTE_WORKSPACE_OFFBOARD: 'internal_mgmt:execute_workspace_offboard',
    INTERNAL_MGMTCOPY_LIBRARY_EVENT_CONTENT: 'internal_mgmt:copy_library_event_content',
    INTERNAL_MGMTGET_ROLE_PERMS: 'internal_mgmt:get_role_perms',
    INTERNAL_MGMTADD_ROLE_PERMS: 'internal_mgmt:add_role_perms',
    INTERNAL_MGMTDELETE_ROLE_PERMS: 'internal_mgmt:delete_role_perms',
    INTERNAL_MGMTGET_ROLE_USERS: 'internal_mgmt:get_role_users',
    INTERNAL_MGMTADD_ROLE_USERS: 'internal_mgmt:add_role_users',
    INTERNAL_MGMTDELETE_ROLE_USERS: 'internal_mgmt:delete_role_users'
} as const;
export type AuditLogType = typeof AuditLogType[keyof typeof AuditLogType];


export function instanceOfAuditLogType(value: any): boolean {
    for (const key in AuditLogType) {
        if (Object.prototype.hasOwnProperty.call(AuditLogType, key)) {
            if (AuditLogType[key as keyof typeof AuditLogType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AuditLogTypeFromJSON(json: any): AuditLogType {
    return AuditLogTypeFromJSONTyped(json, false);
}

export function AuditLogTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogType {
    return json as AuditLogType;
}

export function AuditLogTypeToJSON(value?: AuditLogType | null): any {
    return value as any;
}

