import React, { useCallback, useEffect, useState } from 'react'

import { BulkRevokePermsFromUsers } from 'models/perms'
import { RawUser, fetchAllWorkspaceVaultUsers } from 'models/users'
import { Workspace } from 'models/workspace'
import { Permission } from 'openapi/models/Permission'

import { useNavigateWithQueryParams } from 'utils/routing'
import { displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import Skeleton from 'components/ui/skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

export const VaultUsersLegacy = ({ workspace }: { workspace: Workspace }) => {
  const navigate = useNavigateWithQueryParams()
  const [isLoading, setIsLoading] = useState(false)
  const [vaultUsers, setVaultUsers] = useState<
    (RawUser & { vaultPermSources: string[] })[]
  >([])

  const fetchUsers = useCallback(async () => {
    setIsLoading(true)
    const users = await fetchAllWorkspaceVaultUsers(workspace.id)
    setVaultUsers(users)
    setIsLoading(false)
  }, [workspace.id])

  useEffect(() => {
    void fetchUsers()
  }, [fetchUsers])

  const userInspectorNavigate = (email: string) => {
    navigate(`/settings/internal_admin/user-inspector`, {
      state: { email },
    })
  }

  const onClickCopyUserEmails = async () => {
    const emails = vaultUsers.map((user) => user.email).join('\n')
    await navigator.clipboard.writeText(emails)
    displaySuccessMessage('Copied user emails to clipboard')
  }

  const onClickRevokeAllVaultUserPerms = async () => {
    if (
      window.confirm(
        'Are you sure you want to remove all vault permissions from users with source “USER”?'
      )
    ) {
      await BulkRevokePermsFromUsers(
        vaultUsers
          .filter((user) => user.vaultPermSources.includes('USER'))
          .map((user) => user.email),
        [Permission.CREATEVAULT, Permission.CREATEVAULT_REVIEW]
      )
      await fetchUsers()
    }
  }

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="px-1 py-2.5">Email</TableHead>
            <TableHead className="px-1 py-2.5">Sources</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={2}>
                <Skeleton rows={6} rowHeight="h-5" className="mt-2" />
              </TableCell>
            </TableRow>
          ) : (
            vaultUsers
              .sort((a, b) => a.email.localeCompare(b.email))
              .sort((a, b) => {
                const order = (sources: string[]) => {
                  if (sources.includes('USER')) return 0
                  if (sources.includes('WORKSPACE')) return 1
                  if (sources.some((source) => source.includes('add-on')))
                    return 3
                  if (sources.some((source) => source.includes('review-pack')))
                    return 4
                  return 2 // Any other strings
                }
                const aOrder = order(a.vaultPermSources)
                const bOrder = order(b.vaultPermSources)
                return aOrder - bOrder
              })
              .map((user) => (
                <TableRow
                  key={user.id}
                  onClick={() => userInspectorNavigate(user.email)}
                  className="cursor-pointer"
                >
                  <TableCell className="px-1 py-2.5">{user.email}</TableCell>
                  <TableCell className="px-1 py-2.5">
                    {user.vaultPermSources.join(', ')}
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>
      {vaultUsers.length > 0 && (
        <div className="mt-2 flex justify-end space-x-2">
          <Button variant="secondary" onClick={onClickCopyUserEmails}>
            Copy user emails
          </Button>
          {vaultUsers.some((user) =>
            user.vaultPermSources.includes('USER')
          ) && (
            <Button
              variant="secondary"
              onClick={onClickRevokeAllVaultUserPerms}
            >
              Remove all Vault Permissions from source “USER”
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
