import React, { useMemo, useState } from 'react'

import {
  Column,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import pluralize from 'pluralize'

import { UsageData } from 'models/usage-data'

import { cn } from 'utils/utils'

import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import { TOTAL_KEY } from './dashboard-page'

interface UserStatsTableProps {
  usageData: UsageData | undefined
  isLoading: boolean
  selectedKey: string
}

const UserStatsTable = ({
  usageData,
  isLoading,
  selectedKey,
}: UserStatsTableProps) => {
  const isFiltered = selectedKey !== TOTAL_KEY

  const filteredData = useMemo(() => {
    if (_.isEmpty(usageData)) return []

    if (isFiltered) {
      return usageData?.userStats.map((userStats) => {
        // exclude all keys that are not selected key
        return {
          userEmail: userStats.userEmail,
          [selectedKey]: userStats[selectedKey],
        }
      })
    }
    return usageData?.userStats
  }, [selectedKey, usageData, isFiltered])

  const processedData = useMemo(() => {
    return filteredData.map((userStats) => {
      return {
        ...userStats,
        userEmail: userStats.userEmail,
        [TOTAL_KEY]: Object.values(userStats).reduce(
          (acc: number, curr) => (typeof curr === 'number' ? acc + curr : acc),
          0
        ),
      }
    })
  }, [filteredData])

  const columns: ColumnDef<{
    userEmail: string
    [key: string]: number | string
  }>[] = [
    {
      accessorKey: 'userEmail',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="User" />
      ),
      cell: ({ getValue }) => (
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <div className="ml-3 w-full truncate text-sm">
              {String(getValue())}
            </div>
          </TooltipTrigger>
          <TooltipContent>{String(getValue())}</TooltipContent>
        </Tooltip>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: TOTAL_KEY,
      header: ({ column }) => (
        <DataTableSortHeader column={column} header={TOTAL_KEY} />
      ),
      cell: ({ row }) => (
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <div className="ml-3 w-1/2 text-sm">{row.getValue(TOTAL_KEY)}</div>
          </TooltipTrigger>
          <TooltipContent>
            {`${row.getValue(TOTAL_KEY)} ${pluralize(
              'query',
              row.getValue(TOTAL_KEY)
            )} submitted`}
          </TooltipContent>
        </Tooltip>
      ),
      enableGlobalFilter: false,
    },
    ...(isFiltered ? [selectedKey] : usageData?.distinctTypes || []).map(
      (type) => ({
        accessorKey: type,
        header: ({
          column,
        }: {
          column: Column<
            { userEmail: string; [key: string]: number | string },
            unknown
          >
        }) => <DataTableSortHeader column={column} header={type} />,
        cell: ({
          row,
        }: {
          row: Row<{ userEmail: string; [key: string]: number | string }>
        }) => (
          <Tooltip delayDuration={250}>
            <TooltipTrigger asChild>
              <div className="ml-3 w-1/2 text-sm">
                {_.isNil(row.getValue(type)) ? '-' : row.getValue(type)}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {_.isNil(row.getValue(type))
                ? 'User does not have access to this product'
                : `${row.getValue(type)} ${pluralize(
                    'query',
                    row.getValue(type)
                  )} submitted`}
            </TooltipContent>
          </Tooltip>
        ),
        enableGlobalFilter: false,
      })
    ),
  ]

  const [filter, setFilter] = useState<string>('')

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: TOTAL_KEY,
      desc: true,
    },
  ])

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 20,
    })

  const table = useReactTable({
    data: processedData,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    state: {
      sorting: sorting,
      globalFilter: filter,
      pagination: tablePaginationState,
    },
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'user',
    table.getFilteredRowModel().rows.length
  )}`

  return (
    <Card className="rounded-lg border">
      <CardHeader>
        <CardTitle>Usage breakdown by user</CardTitle>
      </CardHeader>
      <CardContent>
        <DataTableHeader
          searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
          className="mb-2"
        >
          <p>Number of queries by user for each product</p>
        </DataTableHeader>
        <DataTable
          table={table}
          className={cn({ 'h-[420px]': !isLoading })}
          //   useVirtual={processedData.length > 100}
          isLoading={isLoading}
          emptyStateText="No users found"
          tableCellClassName="max-w-[320px]"
        />
        <DataTableFooter table={table} isLoading={isLoading}>
          <p>{rowCountCopy}</p>
        </DataTableFooter>
      </CardContent>
    </Card>
  )
}

export default UserStatsTable
