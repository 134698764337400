import React from 'react'

import { Row } from '@tanstack/react-table'
import { formatDistanceToNowStrict } from 'date-fns'
import { capitalize } from 'lodash'
import { MoreHorizontal } from 'lucide-react'
import pluralize from 'pluralize'

import { EventKind } from 'openapi/models/EventKind'
import { HistoryItem } from 'types/history'

import { parseIsoString } from 'utils/utils'

import {
  getMessageQuery,
  getMessageThreadFromMessages,
} from 'components/assistant-v2/utils/assistant-helpers'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import {
  Popover,
  PopoverContent,
  PopoverMenuItem,
  PopoverTrigger,
} from 'components/ui/popover'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface CellProps {
  row: Row<HistoryItem>
}

const BaseCell: React.FC<{
  children: React.ReactNode
  tooltip?: string
}> = ({ children, tooltip }) => {
  const content = (
    <span className="truncate text-sm text-secondary">{children}</span>
  )
  if (tooltip) {
    return (
      <Tooltip>
        <TooltipTrigger>{content}</TooltipTrigger>
        <TooltipContent>{tooltip}</TooltipContent>
      </Tooltip>
    )
  }
  return content
}

export const AssistantQueryCell: React.FC<CellProps> = ({ row }) => {
  let messages = row.original.messages ?? []
  messages = row.original.currentMessageId
    ? getMessageThreadFromMessages(messages, row.original.currentMessageId)
    : messages
  const numFollowUps = messages.length - 1

  const followUpNoun =
    row.original.kind === EventKind.ASSISTANT_DRAFT ? 'revision' : 'follow-up'

  return (
    <span className="flex h-6 items-center space-x-2">
      <span className="line-clamp-1 break-all text-sm ">
        {row.original.userCaption ||
          messages[0]?.caption ||
          getMessageQuery(messages[0])}
      </span>
      {numFollowUps > 0 && (
        <span className="shrink-0 rounded-full bg-muted px-2 py-1 text-xs text-secondary">
          {numFollowUps} {pluralize(followUpNoun, numFollowUps)}
        </span>
      )}
    </span>
  )
}

export const AssistantOwnerCell: React.FC<CellProps> = ({ row }) => {
  return (
    <BaseCell tooltip={row.original.userId}>{row.original.userId}</BaseCell>
  )
}

export const AssistantKindCell: React.FC<CellProps> = ({ row }) => {
  return (
    <BaseCell>
      {row.original.kind === EventKind.ASSISTANT_DRAFT ? 'Draft' : 'Assist'}
    </BaseCell>
  )
}

export const AssistantSourcesCell: React.FC<CellProps> = ({ row }) => {
  const numDocs =
    row.original.messages?.[0].knowledgeSources?.[0]?.fileIds?.length ||
    row.original.documents?.length ||
    0
  return (
    <BaseCell>
      {numDocs > 0 ? `${numDocs} ${pluralize('document', numDocs)}` : '-'}
    </BaseCell>
  )
}

export const AssistantTimeCell: React.FC<CellProps> = ({ row }) => {
  return (
    <BaseCell>
      {capitalize(
        formatDistanceToNowStrict(
          parseIsoString(row.original.created as unknown as string),
          { addSuffix: true }
        )
      )}
    </BaseCell>
  )
}

export const AssistantMenuCell: React.FC<CellProps> = () => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="xsIcon"
          onClick={(e) => e.stopPropagation()}
        >
          <Icon icon={MoreHorizontal} />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-40 p-2">
        <PopoverMenuItem>Option</PopoverMenuItem>
      </PopoverContent>
    </Popover>
  )
}
