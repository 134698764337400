import React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import { LucideIcon, type LucideProps } from 'lucide-react'

import { cn } from 'utils/utils'

const iconVariants = cva('shrink-0', {
  variants: {
    size: {
      small: 'w-3 h-3',
      default: 'w-4 h-4',
      large: 'w-6 h-6',
      defaultWithBackground: 'w-6 h-6 p-1 bg-muted rounded-sm text-primary',
      largeWithBackground: 'w-8 h-8 p-2 bg-muted rounded-sm text-primary',
    },
    variant: {
      // Leave default as empty string to inherit parent color (e.g. if Icon is
      // used inside a Button, where Button will have 'text' or
      // 'text-primary-inverse' depending on the button variant)
      default: '',
      secondary: 'text-muted',
    },
  },
  defaultVariants: {
    size: 'default',
    variant: 'default',
  },
})

interface Props extends VariantProps<typeof iconVariants> {
  icon:
    | LucideIcon
    | React.ForwardRefExoticComponent<
        Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
      >
  className?: string
}

export const Icon: React.FC<Props> = ({
  icon: Icon,
  size,
  className,
  variant,
}) => {
  return <Icon className={cn(iconVariants({ size, variant }), className)} />
}

export default Icon
