// remap errors to user friendly message
import { HTTPError } from 'ky'

const unknownErrorMessage = 'Sorry, something went wrong with your operation'

const userFriendlyMap: { [k: string]: string } = {
  // default error message from server
  'Internal server error': unknownErrorMessage,
}

// check if intentionally thrown by server
export const isKnownError = (message: string) => message !== unknownErrorMessage

export const getUserFriendlyError = async (e: unknown) => {
  // other errors can be handled here as well but we'll need to check their type

  // HTTPError is thrown by ky
  if (!(e instanceof HTTPError)) {
    return unknownErrorMessage
  }

  // handling ky errors, this can ONLY be read ONCE b/c it's a stream
  const { error } = await e.response.json()
  // we know the shape of the error object
  if (error && (error instanceof String || typeof error === 'string')) {
    const message = error.toString()
    return userFriendlyMap[message] ?? message
  }

  return unknownErrorMessage
}
