import React from 'react'

import { Row } from '@tanstack/react-table'
import { Trash } from 'lucide-react'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'

import { DocumentTable } from './file-explorer-columns'

interface CellProps {
  row: Row<DocumentTable>
}

interface ActionsCellProps extends CellProps {
  removeDocument: (e: React.MouseEvent<HTMLButtonElement>, row: any) => void
}

// TODO: Move to a shared location
export const IsLoadingCell: React.FC = () => (
  <div className="h-4 w-full animate-pulse rounded bg-muted" />
)

export const DocumentCell: React.FC<CellProps> = ({ row }) => {
  return <p className="line-clamp-1 max-w-48 pl-3">{row.original.file.name}</p>
}

export const TypeCell: React.FC<CellProps> = ({ row }) => {
  const { isLoading, type } = row.original

  return (
    <div className="shrink">
      {!type && isLoading ? (
        <IsLoadingCell />
      ) : (
        <Badge variant="secondary">{type}</Badge>
      )}
    </div>
  )
}

export const ExtractedTermsCell: React.FC<CellProps> = ({ row }) => {
  const parties = row.original.extractedTerms['Parties']?.text
  const { isLoading } = row.original

  return (
    <div className="grow">
      {!parties && isLoading ? (
        <IsLoadingCell />
      ) : (
        <p className="line-clamp-1 pl-3">{parties}</p>
      )}
    </div>
  )
}

export const DateCell: React.FC<CellProps> = ({ row }) => {
  const effectiveDate = row.original.extractedTerms['Effective Date']?.text
  const { isLoading } = row.original

  return (
    <div className="w-28">
      {!effectiveDate && isLoading ? (
        <IsLoadingCell />
      ) : (
        <p className="line-clamp-1 ">{effectiveDate}</p>
      )}
    </div>
  )
}

export const FieldsCell: React.FC<CellProps> = ({ row }) => {
  const isLoading = row.original.isLoading

  return (
    <div className="shrink">
      {isLoading ? (
        <IsLoadingCell />
      ) : (
        <p className="line-clamp-1">{row.original.extractedTermsCount}</p>
      )}
    </div>
  )
}

export const ActionsCell: React.FC<ActionsCellProps> = ({
  row,
  removeDocument,
}) => {
  const isLoading = row.original.isLoading
  return (
    <Button
      variant="ghost"
      onClick={(e) => {
        removeDocument(e, row.original)
      }}
      disabled={isLoading}
      size="smIcon"
    >
      <Trash size={16} />
    </Button>
  )
}
