import React, { useRef } from 'react'
import { useClickAway, useKey } from 'react-use'

import { isNil } from 'lodash'
import { Copy } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { displayInfoMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { useCompetitiveAnalysisDataGridStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-data-grid-store'

const CellViewer = () => {
  const popoverPosition = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.popoverPosition)
  )
  const popoverData = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.popoverData)
  )
  const setPopoverData = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.setPopoverData)
  )

  const ref = useRef<HTMLDivElement>(null)

  const { displayText = '', numYears = 1 } = popoverData || {}

  const handleCopyToClipboard = async () => {
    await window.navigator.clipboard.writeText(displayText)
    displayInfoMessage('Copied to clipboard')
  }

  useClickAway(ref, () => {
    setPopoverData(null)
  })

  useKey('Escape', () => {
    setPopoverData(null)
  })

  if (isNil(popoverData)) return null

  return (
    <div
      ref={ref}
      className="fixed left-24 top-24 z-50 flex flex-col border border-l-0 border-t-0 bg-primary px-4 py-2 shadow-sm ring-[1px] ring-inset ring-primary"
      style={{
        left: popoverPosition.left,
        top: popoverPosition.top,
        width: popoverPosition.width + 24,
        minHeight: 30 * numYears + 24,
      }}
    >
      <div className="flex-1">
        <p className="break-words text-xs">{displayText}</p>
      </div>
      <div className="mt-8 flex justify-end space-x-1">
        <Tooltip>
          <TooltipTrigger>
            <Button
              size="xsIcon"
              variant="ghost"
              onClick={handleCopyToClipboard}
            >
              <Icon icon={Copy} variant="secondary" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Copy to clipboard</TooltipContent>
        </Tooltip>
      </div>
    </div>
  )
}

export default CellViewer
