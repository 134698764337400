import React from 'react'
import { DateRange } from 'react-day-picker'

import { HARVEY_START_DATE } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import DateRangePicker from 'components/ui/date-range-picker'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

interface DateRangeInputProps {
  selectedDateRange: DateRange | undefined
  setSelectedDateRange: (selectedDateRange: DateRange | undefined) => void
}

interface HistoryExportDialogProps {
  isDialogOpen: boolean
  setIsDialogOpen: (isDialogOpen: boolean) => void
  dialogTitle: string
  dialogDescription: string
  selectedTimezone?: string
  setSelectedTimezone?: (selectedTimezone: string) => void
  timezones?: string[]
  dateRangePickerProps?: DateRangeInputProps
  onExport: (...args: any[]) => Promise<void>
  exportDisabled?: boolean
}

const HistoryExportDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  selectedTimezone,
  setSelectedTimezone,
  onExport,
  dialogTitle,
  dialogDescription,
  timezones,
  dateRangePickerProps,
  exportDisabled,
}: HistoryExportDialogProps) => {
  const userInfo = useAuthUser()
  return (
    <Dialog open={isDialogOpen}>
      <DialogContent showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogDescription>{dialogDescription}</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col items-start gap-2">
          {dateRangePickerProps && (
            <>
              <Label className="mt-2 text-sm text-muted">Date range</Label>
              <DateRangePicker
                selectedDateRange={dateRangePickerProps.selectedDateRange}
                onSelectDateRange={dateRangePickerProps.setSelectedDateRange}
                disableFutureDates
                disablePastDatesBeforeDate={HARVEY_START_DATE}
              />
            </>
          )}
          {timezones &&
            selectedTimezone &&
            setSelectedTimezone &&
            userInfo.canSelectTimezoneOnExport && (
              <>
                <Label className="mt-2 text-sm text-muted">Timezone</Label>
                <Select
                  value={selectedTimezone}
                  onValueChange={setSelectedTimezone}
                >
                  <SelectTrigger className="h-[32px] min-w-[200px]">
                    <SelectValue>{selectedTimezone}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {timezones.map((timezone) => (
                      <SelectItem key={timezone} value={timezone}>
                        {timezone}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </>
            )}
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Close
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              variant="default"
              onClick={async () => {
                setIsDialogOpen(false)
                await onExport()
              }}
              disabled={exportDisabled}
            >
              Export
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default HistoryExportDialog
