import React, { useState } from 'react'

import { Maximize2, Minimize2 } from 'lucide-react'

import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'

interface Props {
  children: React.ReactNode
  width?: string
}
const Table = ({ children, width }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const handleExpand = () => setIsExpanded(true)
  const handleClose = () => setIsExpanded(false)
  const handleOpenChange = (isOpen: boolean) => {
    setIsExpanded(isOpen)
  }

  return (
    <div
      className="-mx-4 rounded-md bg-accent p-1"
      style={{ width: `calc(${width ?? '100%'} + 32px)` }}
    >
      <div className="flex items-center justify-between px-1 pb-1">
        <p className="not-prose px-2 pt-1 text-xs font-medium text-secondary">
          Table
        </p>
        <Button
          className="text-muted hover:bg-button-secondary-hover hover:text-muted"
          onClick={handleExpand}
          size="xsIcon"
          variant="ghost"
        >
          <Icon icon={Maximize2} size="small" />
        </Button>
      </div>
      <ScrollArea className="rounded-sm border bg-primary" hasHorizontalScroll>
        <table className="[&_td:first-child]:pl-4 [&_td:last-child]:pr-4 [&_th:first-child]:pl-4 [&_th:last-child]:pr-4">
          {children}
        </table>
      </ScrollArea>
      <Dialog open={isExpanded} onOpenChange={handleOpenChange}>
        <DialogContent
          className="flex h-full max-h-[calc(100vh-64px)] w-full max-w-[calc(100vw-64px)] flex-col gap-0 p-0"
          onOpenAutoFocus={(e) => e.preventDefault()}
          showCloseIcon={false}
        >
          <div className="flex shrink-0 items-center justify-between border-b py-3">
            <div className="px-8 text-xl font-medium">Table</div>
            <Button
              className="text-secondary hover:text-primary"
              onClick={handleClose}
              variant="unstyled"
              size="smIcon"
            >
              <Icon icon={Minimize2} />
            </Button>
          </div>
          <div className="virtualized-scrollbar prose prose-harvey w-full max-w-none grow pb-4">
            <table className="[&_td:first-child]:pl-8 [&_td:last-child]:pr-8 [&_td]:p-2.5 [&_th:first-child]:pl-8 [&_th:last-child]:pr-8 [&_th]:p-2.5">
              {children}
            </table>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Table
