import React from 'react'

import _ from 'lodash'
import { AlertTriangle } from 'lucide-react'

import { useAuthUser } from 'components/common/auth-context'
import {
  AddUsersToExperiment,
  RemoveUsersFromExperiment,
} from 'components/compare/compare-fetcher'
import ExperimentMultiUserManagement from 'components/settings/experiment/experiment-user-management-old'
import { Card, CardTitle, CardHeader, CardContent } from 'components/ui/card'
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'components/ui/tabs'

interface ExperimentUserManagementProps {
  experimentId: string
}

const ExperimentUserManagementCard: React.FC<ExperimentUserManagementProps> = ({
  experimentId,
}) => {
  const userInfo = useAuthUser()
  if (_.isNil(userInfo) || !userInfo.IsResponseComparisonAdmin) return <></>
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-lg font-semibold">
          {`Manage experiment ${experimentId} users`}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="add">
          <TabsList className="justify-start">
            <TabsTrigger value="add">Add</TabsTrigger>
            <TabsTrigger value="remove">Remove</TabsTrigger>
          </TabsList>
          <TabsContent value="add">
            <ExperimentMultiUserManagement
              experimentId={experimentId}
              buttonText="Add"
              modifyExperimentUsers={AddUsersToExperiment}
            />
          </TabsContent>
          <TabsContent value="remove">
            <>
              <div className="mb-3 flex justify-start rounded-lg border bg-gold">
                <AlertTriangle size={18} className="m-2" />
                <span className="mt-2">
                  Removing a user from an experiment permanently discards their
                  votes
                </span>
              </div>
              <ExperimentMultiUserManagement
                experimentId={experimentId}
                buttonText="Remove"
                modifyExperimentUsers={RemoveUsersFromExperiment}
              />
            </>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  )
}

export default ExperimentUserManagementCard
