import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import pluralize from 'pluralize'

import { WorkspaceRole } from 'models/roles'

import { getTableDateString } from 'utils/date-utils'
import { useNavigateWithQueryParams } from 'utils/routing'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'

interface WorkspaceRolesTableProps {
  workspaceRoles: WorkspaceRole[]
  workspaceName: string
}

const WorkspaceRolesTable = ({
  workspaceRoles,
  workspaceName,
}: WorkspaceRolesTableProps) => {
  const [filter, setFilter] = useState<string>('')
  const [isLoading] = useState<boolean>(false)

  const navigate = useNavigateWithQueryParams()

  const columns: ColumnDef<WorkspaceRole>[] = [
    {
      accessorKey: 'rolePk',
    },
    {
      accessorKey: 'roleId',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Role ID" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Name" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'desc',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Description" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Date added"
            tooltipText="Date when this client matter # was added"
          />
        )
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: workspaceRoles,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        rolePk: false,
      },
    },

    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'roles',
    table.getFilteredRowModel().rows.length
  )}`

  const rowClickHandler = (row: Row<WorkspaceRole>) => {
    navigate(`${BaseAppPath.Settings}/internal_admin/role-inspector`, {
      state: {
        roleId: workspaceRoles.find((r) => r.rolePk === row.original.rolePk)
          ?.roleId,
      },
    })
  }

  return (
    <>
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
        className="mb-2"
      >
        <p>
          Manage roles for <b>{workspaceName}</b>, add permissions to a role or
          grant roles to users by clicking on the role below
        </p>
      </DataTableHeader>
      <DataTable
        table={table}
        className={cn({ 'min-h-[420px]': !isLoading })}
        useVirtual={workspaceRoles.length > 100}
        isLoading={isLoading}
        emptyStateText="No roles found"
        onRowClick={(row) => rowClickHandler(row)}
      />
      <DataTableFooter table={table} isLoading={isLoading}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
    </>
  )
}

export default WorkspaceRolesTable
