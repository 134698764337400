import React, { useMemo, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { Folder, Info, Plus, SquareAsterisk, Users } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { useSharingStore } from 'stores/sharing-store'

import { bytesToPreciseReadable } from 'utils/file-utils'
import { cn, backendToReadable } from 'utils/utils'

import { MenuDropdown } from './components/file-explorer/vault-cells'
import { VaultProjectTabTypes } from './components/vault-app-header/vault-project-tabs'
import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import { Badge } from 'components/ui/badge'
import { Icon } from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import { ScrollArea } from 'components/ui/scroll-area'
import { SkeletonBlock } from 'components/ui/skeleton'
import { Spinner } from 'components/ui/spinner'
import {
  DOT_SEPARATOR,
  homePageTabSearchParamKey,
  newProjectPath,
  projectsPath,
} from 'components/vault/utils/vault'
import { FetchVaultFolderHistoryStats } from 'components/vault/utils/vault-fetcher'
import {
  getVaultProjects,
  projectAsItem,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import useSharingPermissions from './hooks/use-sharing-permissions'
import { pluralizeFiles } from './utils/vault-text-utils'
import { useVaultUsageStore } from './utils/vault-usage-store'

const DEFAULT_PROJECT_CARD_HEIGHT = 246

const NoProjects = ({ tab }: { tab?: VaultProjectTabTypes }) => {
  const displayText =
    tab === VaultProjectTabTypes.SHARED_WITH_YOU
      ? `No Vault projects have been shared with you yet`
      : `You haven’t created any Vault projects yet`
  return (
    <div className="flex h-full w-full flex-col items-center justify-center space-y-6">
      <Icon icon={Folder} size="large" />
      <p>{displayText}</p>
    </div>
  )
}

const ProjectCard = ({
  vaultProject,
  isShared,
}: {
  vaultProject: VaultFolder
  isShared?: boolean
}) => {
  const location = useLocation()
  const { trackEvent } = useAnalytics()
  const projectsMetadata = useVaultStore((state) => state.projectsMetadata)
  const setCurrentProject = useVaultStore((s) => s.setCurrentProject)
  const setShowProcessingProgress = useVaultStore(
    (s) => s.setShowProcessingProgress
  )
  const projectData = projectsMetadata[vaultProject.id]

  const projectRow = projectAsItem(
    vaultProject,
    projectData?.folderSize,
    projectData?.totalFiles
  )

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )
  const sharingUsersForWorkspace = useSharingStore(
    useShallow((state) => state.sharingUsersForWorkspace)
  )
  const isExampleProject = useMemo(
    () => exampleProjectIds.has(vaultProject.id),
    [vaultProject.id, exampleProjectIds]
  )
  const { doesCurrentUserHaveFullAccessPermission } = useSharingPermissions({
    projectId: vaultProject.id,
  })

  const { data: historyStats } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [HarvQueryKeyPrefix.VaultHistoryStatsQuery, vaultProject.id],
    queryFn: () => FetchVaultFolderHistoryStats(vaultProject.id),
    refetchInterval: (query) => {
      // Poll for history stats updates every 10 seconds if there are any in progress
      if (
        query.state.status === 'success' &&
        query.state.data &&
        query.state.data.inProgressCount > 0
      ) {
        return 10_000
      }
      return false
    },
  })

  const sizeDisplayText = useMemo(() => {
    const numProjectFiles = projectData?.totalFiles || 0
    const projectSize = bytesToPreciseReadable(
      projectData?.folderSize || 0,
      2,
      true
    )
    if (numProjectFiles === 0) {
      return `No files uploaded`
    }
    return `${pluralizeFiles(numProjectFiles)} (${projectSize})`
  }, [projectData])

  const destination = useMemo(() => {
    return {
      pathname: `${BaseAppPath.Vault}${projectsPath}${vaultProject.id}`,
      search: location.search,
    }
  }, [location.search, vaultProject.id])

  const projectUpdatedAt = useMemo(() => {
    return `Updated ${backendToReadable(vaultProject.updatedAt)}`
  }, [vaultProject.updatedAt])

  const shouldShowMenuDropdown =
    (isHovered || isDropdownOpen) &&
    !isExampleProject &&
    doesCurrentUserHaveFullAccessPermission

  const sharedByUserEmail = useMemo(() => {
    return sharingUsersForWorkspace.find(
      (user) => user.userId === vaultProject.userId
    )?.userEmail
  }, [vaultProject.userId, sharingUsersForWorkspace])

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        height: DEFAULT_PROJECT_CARD_HEIGHT,
      }}
      className={cn(
        'relative flex w-full flex-col justify-between rounded-lg border p-6 shadow-sm',
        {
          'cursor-pointer border-input-focused': isHovered || isDropdownOpen,
        }
      )}
    >
      <div className="max-h-12 space-y-1">
        <p className="text-xs text-muted">{projectUpdatedAt}</p>
        <Link
          to={destination}
          onClick={() => {
            setCurrentProject(vaultProject)
            if (
              projectData &&
              (projectData.failedFiles !== 0 ||
                projectData.completedFiles !== projectData.totalFiles)
            ) {
              setShowProcessingProgress(vaultProject.id, true)
            }
            trackEvent('Vault Project Loaded', {
              is_example_project: isExampleProject,
              is_shared_project: isShared,
            })
          }}
          className="apply-click-on-parent"
        >
          {vaultProject.name}
        </Link>
        {isExampleProject && (
          <p>
            <Badge variant="secondary" className="border-0 text-muted" isPill>
              Example
            </Badge>
          </p>
        )}
      </div>
      {shouldShowMenuDropdown && (
        <div className="absolute right-2 top-2">
          <MenuDropdown
            dropdownAlign="start"
            row={projectRow}
            onMenuDropdownChangeHandler={setIsDropdownOpen}
          />
        </div>
      )}
      <div className="space-y-2">
        {isShared && (
          <div className="flex items-center space-x-1">
            <Icon icon={Users} size="small" className="text-muted" />
            {sharedByUserEmail ? (
              <p className="text-xs text-muted">
                Shared by {sharedByUserEmail}
              </p>
            ) : (
              <SkeletonBlock className="h-4 w-24" />
            )}
          </div>
        )}
        <div className="space-x-2">
          <div className="flex items-center space-x-1">
            <Icon icon={Folder} size="small" />
            {projectData ? (
              <p className="text-xs">{sizeDisplayText}</p>
            ) : (
              <SkeletonBlock className="h-4 w-24" />
            )}
          </div>
        </div>
        {(!historyStats || historyStats.totalCount > 0) && (
          <div className="flex items-center space-x-1">
            <Icon icon={SquareAsterisk} size="small" />
            {!historyStats && <SkeletonBlock className="h-4 w-16" />}
            {historyStats &&
              historyStats.totalCount - historyStats.inProgressCount > 0 && (
                <p className="text-xs">
                  {historyStats.totalCount - historyStats.inProgressCount}{' '}
                  {historyStats.totalCount - historyStats.inProgressCount > 1
                    ? 'queries'
                    : 'query'}
                </p>
              )}
            {historyStats && historyStats.inProgressCount > 0 && (
              <Badge
                variant="secondary"
                className="rounded-full border-0 px-1 text-muted"
              >
                <Spinner className="mx-0 mr-1 h-3 w-3 shrink-0" />
                <p className="text-xs">
                  {historyStats.inProgressCount}{' '}
                  {historyStats.inProgressCount > 1 ? 'queries' : 'query'}{' '}
                  processing
                </p>
              </Badge>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const VaultHome = () => {
  const userInfo = useAuthUser()
  const [searchParams] = useSearchParams()

  const defaultTab = VaultProjectTabTypes.YOUR_PROJECTS
  const tabParam = searchParams.get(
    homePageTabSearchParamKey
  ) as VaultProjectTabTypes | null
  const selectedTab = tabParam ?? defaultTab

  const isLayoutLoading = useVaultStore(
    useShallow((state) => state.isLayoutLoading)
  )
  const folderIdToVaultFolder = useVaultStore(
    useShallow((state) => state.folderIdToVaultFolder)
  )
  const rootVaultFolderIds = useVaultStore(
    useShallow((state) => state.rootVaultFolderIds)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )
  const sharedProjectIds = useVaultStore(
    useShallow((state) => state.sharedProjectIds)
  )

  const isVaultSharingEnabled = userInfo.IsVaultSharingUser

  const vaultProjectsToDisplay = useMemo(() => {
    const allVaultProjects = getVaultProjects({
      folderIdToVaultFolder,
      rootVaultFolderIds,
      userId: userInfo.dbId,
      exampleProjectIds,
      sharedProjectIds,
    })
    if (!isVaultSharingEnabled) {
      return allVaultProjects
    }

    if (selectedTab === VaultProjectTabTypes.SHARED_WITH_YOU) {
      return allVaultProjects.filter(
        (vaultProject) =>
          sharedProjectIds.has(vaultProject.id) &&
          !exampleProjectIds.has(vaultProject.id)
      )
    }

    return allVaultProjects.filter(
      (vaultProject) =>
        vaultProject.userId === userInfo.dbId ||
        exampleProjectIds.has(vaultProject.id)
    )
  }, [
    isVaultSharingEnabled,
    userInfo.dbId,
    selectedTab,
    folderIdToVaultFolder,
    rootVaultFolderIds,
    exampleProjectIds,
    sharedProjectIds,
  ])

  return (
    <AppMain>
      <FullscreenLoading
        isLoading={isLayoutLoading && isEmpty(vaultProjectsToDisplay)}
        zIndex="z-50"
      />
      <ProjectsDisplay
        vaultProjects={vaultProjectsToDisplay}
        tab={selectedTab}
      />
    </AppMain>
  )
}

const ProjectsDisplay = ({
  vaultProjects,
  tab,
}: {
  vaultProjects: VaultFolder[]
  tab?: VaultProjectTabTypes
}) => {
  const userInfo = useAuthUser()
  const reviewQueryLimit = useVaultUsageStore((state) => state.reviewQueryLimit)
  const reviewQueryLimitUnitDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryLimitUnitDisplayString
  )
  const reviewQueryUsageDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryUsageDisplayString
  )
  const reviewQueryLimitDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryLimitDisplayString
  )
  const reviewQueryLimitTimeFrame = useVaultUsageStore(
    (state) => state.reviewQueryLimitTimeFrame
  )
  const reviewQueryLimitLevel = useVaultUsageStore(
    (state) => state.reviewQueryLimitLevel
  )
  const sharedProjectIds = useVaultStore(
    useShallow((state) => state.sharedProjectIds)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )

  const projectsCountLimit = userInfo.workspace.getVaultProjectsCountLimit(
    userInfo.vaultFeature
  )
  const isViewingSharedProjects = tab === VaultProjectTabTypes.SHARED_WITH_YOU
  const isViewingYourProjects = tab === VaultProjectTabTypes.YOUR_PROJECTS

  const showProjectBadge =
    (userInfo.IsVaultSharingUser &&
      isViewingYourProjects &&
      vaultProjects.length > 0) ||
    (isViewingSharedProjects && vaultProjects.length > 0)

  const getProjectBadgeDisplayText = useMemo(() => {
    return () => {
      if (isViewingSharedProjects) {
        return `Projects shared with you do not count towards your project limit`
      }
      const nonExampleProjectsCount =
        vaultProjects.length - exampleProjectIds.size
      const projectDisplayText = `${nonExampleProjectsCount} of ${projectsCountLimit} project limit`

      const reviewQueryDisplayText =
        userInfo.IsVaultReviewUser && reviewQueryLimit !== null
          ? `${reviewQueryUsageDisplayString} of ${reviewQueryLimitDisplayString} total ${reviewQueryLimitUnitDisplayString}${
              reviewQueryLimitTimeFrame === QueryCapRuleTimeFrame.CALENDAR_MONTH
                ? ' this month'
                : ''
            }${
              reviewQueryLimitLevel === QueryCapRuleLevel.PER_WORKSPACE
                ? ' for entire workspace'
                : ''
            }`
          : ''
      return [projectDisplayText, reviewQueryDisplayText]
        .filter(Boolean)
        .join(DOT_SEPARATOR)
    }
  }, [
    isViewingSharedProjects,
    vaultProjects.length,
    projectsCountLimit,
    reviewQueryLimit,
    reviewQueryLimitDisplayString,
    reviewQueryLimitLevel,
    reviewQueryLimitTimeFrame,
    reviewQueryUsageDisplayString,
    userInfo.IsVaultReviewUser,
    reviewQueryLimitUnitDisplayString,
    exampleProjectIds,
  ])

  return (
    <ScrollArea className="h-full" isFullHeight>
      <div
        data-testid="vault-container"
        className="container mx-auto h-full space-y-6 px-6 py-4"
      >
        {showProjectBadge && (
          <Badge
            variant="outline"
            className="w-full gap-x-1 bg-neutral-50 px-4 py-1.5 text-muted"
          >
            <Icon icon={Info} size="small" />
            <span className="text-xs">{getProjectBadgeDisplayText()}</span>
          </Badge>
        )}
        {vaultProjects.length === 0 &&
          tab !== VaultProjectTabTypes.YOUR_PROJECTS && (
            <NoProjects tab={tab} />
          )}
        {(vaultProjects.length > 0 ||
          tab === VaultProjectTabTypes.YOUR_PROJECTS) && (
          <div className="grid grid-cols-1 gap-6 pb-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {tab === VaultProjectTabTypes.YOUR_PROJECTS && <NewProjectCard />}
            {vaultProjects.map((vaultProject) => (
              <ProjectCard
                key={vaultProject.id}
                vaultProject={vaultProject}
                isShared={sharedProjectIds.has(vaultProject.id)}
              />
            ))}
          </div>
        )}
      </div>
    </ScrollArea>
  )
}

const NewProjectCard = () => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const folderIdToVaultFolder = useVaultStore((s) => s.folderIdToVaultFolder)
  const rootVaultFolderIds = useVaultStore((s) => s.rootVaultFolderIds)

  const vaultProjects = getVaultProjects({
    folderIdToVaultFolder,
    rootVaultFolderIds,
    userId: userInfo.dbId,
  })
  const projectsCountLimit = userInfo.workspace.getVaultProjectsCountLimit(
    userInfo.vaultFeature
  )
  const projectFilesCountLimitDisplayText = `Each project can contain up to ${userInfo.workspace
    .getVaultFilesCountLimit(userInfo.vaultFeature)
    .toLocaleString()} files`
  if (vaultProjects.length >= projectsCountLimit) {
    return null
  }

  return (
    <div
      style={{
        height: DEFAULT_PROJECT_CARD_HEIGHT,
      }}
      className="relative flex w-full flex-col justify-between rounded-lg border border-dashed p-6 shadow-sm hover:cursor-pointer hover:border-input-focused"
    >
      <div className="absolute inset-0 m-auto flex h-full flex-col items-center justify-center">
        <Icon icon={Plus} size="large" className="text-muted" />
        <Link
          to={`${BaseAppPath.Vault}${newProjectPath}`}
          onClick={() => {
            trackEvent('Vault New Project Button Clicked', {
              source: 'new-project-card',
            })
          }}
          className="apply-click-on-parent mt-2"
          data-testid="vault-new-project-card-link"
        >
          New project
        </Link>
        <p className="mt-1 text-xs text-muted">
          {projectFilesCountLimitDisplayText}
        </p>
      </div>
    </div>
  )
}

export default VaultHome
