import React, { useState } from 'react'

import { X } from 'lucide-react'

import { cn } from 'utils/utils'

import { AssistantChangeLogMenu } from './components/assistant-change-log-menu'
import AssistantQuestion from './components/assistant-question'
import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from 'components/ui/hover-card/hover-card'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'

import { useAssistantStore } from './stores/assistant-store'
import { AssistantDraftMessage, AssistantMessage } from './types'

interface Props {
  messages: AssistantDraftMessage[]
  handleCancel: () => void
  handleClick: (messageId: string) => void
  selectedMessageId?: string
  handleDeleteMessage: (message: AssistantDraftMessage) => void
  handleRegenerateMessage: (message: AssistantDraftMessage) => void
}

const AssistantChangeLog = ({
  messages,
  handleCancel,
  handleClick,
  selectedMessageId,
  handleRegenerateMessage,
  handleDeleteMessage,
}: Props) => {
  const isGeneratingNewRevision = messages.some((message) => message.isLoading)
  const isEventOwner = useAssistantStore((state) => state.isEventOwner)

  if (!messages.length) {
    return <p className="p-2 text-xs text-muted">No relevant changes</p>
  }
  let prevResponse = ''
  return messages.map((message, i) => {
    const isSelected = message.messageId === selectedMessageId
    let selectedText = null
    if (message.selectedText && prevResponse) {
      const { offset, length } = message.selectedText
      selectedText = (prevResponse || '').slice(offset, offset + length)
    }
    prevResponse = message.response
    const isLast = i === messages.length - 1
    const showMenu = isEventOwner && isLast && !message.isLoading

    return (
      <ChangeLogEntry
        key={message.messageId}
        isSelected={isSelected}
        message={message}
        isGeneratingNewRevision={isGeneratingNewRevision}
        showMenu={showMenu}
        selectedText={selectedText}
        handleCancel={handleCancel}
        handleClick={handleClick}
        handleDeleteMessage={handleDeleteMessage}
        handleRegenerateMessage={handleRegenerateMessage}
      />
    )
  })
}

export default AssistantChangeLog

const ChangeLogCardSection = ({
  children,
  title,
}: {
  children: React.ReactNode
  title: string
}) => {
  return (
    <div>
      <div className="mb-1 text-xs font-semibold text-muted">{title}</div>
      <p className="whitespace-pre-wrap break-words text-xs">{children}</p>
    </div>
  )
}

const ChangeLogEntry = ({
  isSelected,
  message,
  isGeneratingNewRevision,
  showMenu,
  selectedText,
  handleCancel,
  handleClick,
  handleDeleteMessage,
  handleRegenerateMessage,
}: {
  selectedText: string | null
  showMenu: boolean
  isSelected: boolean
  message: AssistantDraftMessage
  isGeneratingNewRevision: boolean
  handleCancel: () => void
  handleClick: (messageId: string) => void
  handleDeleteMessage: (message: AssistantDraftMessage) => void
  handleRegenerateMessage: (message: AssistantDraftMessage) => void
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const getMessageLabel = (message: AssistantMessage) => {
    if (!message.prevMessageId) {
      if (message.isLoading) {
        return 'Generating draft'
      } else {
        return 'Initial draft'
      }
    }

    if (message.isLoading) {
      return 'Generating revision'
    }

    return message.caption
  }
  const handleMouseEnter = () => {
    setIsOpen(true)
  }
  const handleOnMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
    const relatedTarget = e.relatedTarget as HTMLElement | null
    if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !relatedTarget?.closest?.(`#assistant-change-log-${message.messageId}`)
    ) {
      setIsOpen(false)
    }
  }

  return (
    <HoverCard key={message.messageId} openDelay={250} open={isOpen}>
      <HoverCardTrigger asChild>
        <Button
          variant="unstyled"
          className={cn(
            'flex h-fit w-full items-center rounded-sm px-2 py-1.5 text-left text-secondary underline underline-offset-1 hover:bg-button-secondary',
            // eslint-disable-next-line prefer-smart-quotes/prefer
            'relative before:absolute before:-inset-y-0.5 before:-left-4 before:w-8 before:content-[""]',
            {
              'bg-button-secondary text-primary':
                (isSelected && !isGeneratingNewRevision) || message.isLoading,
            }
          )}
          onClick={() => handleClick(message.messageId)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          {message.isLoading && (
            <Spinner className="h-3 w-3 shrink-0 fill-primary" />
          )}
          <span className="grow truncate text-xs">
            {getMessageLabel(message)}
          </span>
          {message.isLoading && (
            <Button
              className="shrink-0 text-secondary hover:bg-neutral-200 hover:text-primary"
              onClick={(e) => {
                e.stopPropagation()
                handleCancel()
              }}
              variant="ghost"
              size="xsIcon"
              tooltip="Cancel"
            >
              <Icon icon={X} size="small" />
            </Button>
          )}
          {!!showMenu && (
            <AssistantChangeLogMenu
              message={message}
              handleRegenerateMessage={handleRegenerateMessage}
              handleDeleteMessage={handleDeleteMessage}
            />
          )}
        </Button>
      </HoverCardTrigger>
      <HoverCardPortal>
        <HoverCardContent
          className={cn(
            'flex w-80 flex-col p-0',
            // eslint-disable-next-line prefer-smart-quotes/prefer
            'relative before:absolute before:-inset-x-1 before:-inset-y-1 before:content-[""]'
          )}
          side="left"
          id={`assistant-change-log-${message.messageId}`}
          onMouseLeave={handleOnMouseLeave}
        >
          <div className="p-4 text-xs font-semibold">{message.caption}</div>
          <ScrollArea className="flex min-h-0 grow flex-col" isFullHeight>
            <div className="space-y-4 p-4 pt-0">
              <ChangeLogCardSection title="Query">
                <AssistantQuestion message={message} size="sm" />
              </ChangeLogCardSection>
              {selectedText && (
                <ChangeLogCardSection title="Selection">
                  <Markdown
                    className="prose-xs"
                    content={selectedText}
                    width="100%"
                  />
                </ChangeLogCardSection>
              )}
            </div>
          </ScrollArea>
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCard>
  )
}
