import React from 'react'

import { Trash, Plus } from 'lucide-react'

import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'

import { readableFormat } from 'utils/date-utils'
import { parseIsoString } from 'utils/utils'

import { Button } from 'components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'components/ui/card'
import { Separator } from 'components/ui/separator'

interface Props {
  evaluationQuestion: EvaluationQuestion
  orderRank?: number
  onDelete?: (id: string) => void
  onClick?: (q: EvaluationQuestion) => void
  footerChildren?: React.ReactNode
}

const EvaluationQuestionCard = ({
  evaluationQuestion,
  orderRank,
  onDelete,
  onClick,
  footerChildren,
}: Props) => {
  return (
    <Card className="mb-5 mt-5 p-2">
      <CardHeader>
        {orderRank && (
          <>
            <CardDescription>
              <p>Question # {orderRank}</p>
            </CardDescription>
            <Separator className="my-1" />
          </>
        )}
        <div className="flex items-center justify-between">
          <CardTitle>{evaluationQuestion.question}</CardTitle>
          {onDelete && (
            <Button
              variant="outline"
              size="icon"
              onClick={() => onDelete(evaluationQuestion.id)}
            >
              <Trash size={14} />
            </Button>
          )}
          {onClick && (
            <Button
              variant="outline"
              size="icon"
              onClick={() => onClick(evaluationQuestion)}
            >
              <Plus size={14} />
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent>
        <CardDescription className="pb-4">
          {evaluationQuestion.questionDescription}
        </CardDescription>
        <CardDescription className="pb-4">
          <div className="font-semibold">Question Details</div>
          <div>ID: {evaluationQuestion.id}</div>
          <div>Question Type: {evaluationQuestion.questionType}</div>
          <div>Response Type: {evaluationQuestion.responseType}</div>
          {evaluationQuestion.meta && (
            <div>Metadata: {JSON.stringify(evaluationQuestion.meta)}</div>
          )}
          <div>
            <span className="mr-1">Created At:</span>
            {readableFormat(
              new Date(parseIsoString(evaluationQuestion.createdAt))
            )}
          </div>
          <div>
            <span className="mr-1">Updated At:</span>
            {readableFormat(
              new Date(parseIsoString(evaluationQuestion.updatedAt))
            )}
          </div>
        </CardDescription>
        {footerChildren && (
          <>
            <Separator className="my-4" />
            {footerChildren}
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default EvaluationQuestionCard
