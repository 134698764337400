import React from 'react'

import { Search } from 'lucide-react'

import Services from 'services'

import { cn } from 'utils/utils'

import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { SearchRecord } from 'components/filter/types/search-record'
import { DebouncedInput } from 'components/ui/debounced-input'

const SearchFilter = (props: {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  placeholderText?: string
}) => {
  const { trackEvent } = useAnalytics()
  const { filterKey, setValue } = props
  const value = props.value || ''

  const render: React.FC = () => (
    <div className="relative" key={filterKey}>
      <div className="absolute flex h-full items-center px-3">
        <Search
          size={16}
          className={cn('justify-between', {
            'text-muted': value.trim().length === 0,
          })}
        />
      </div>
      <DebouncedInput
        className="h-8 w-[216px] pl-9"
        placeholder={props.placeholderText || 'Search'}
        type="text"
        value={value}
        onChange={(e) => {
          const newValue = e.target.value
          if (value !== newValue) {
            setValue(newValue)
            Services.HoneyComb.Record({
              metric: 'ui.table_search_filter_change',
              query: newValue,
            })
            trackEvent(FILTER_CHANGED_EVENT_NAME, {
              entity_name: 'generic search component',
              field_changed: 'search',
              placeholder_text: props.placeholderText,
            })
          }
        }}
      />
    </div>
  )

  const filterLogic = (record: SearchRecord) => {
    const cleanQuery = value.trim().toLowerCase()
    if (cleanQuery.length === 0) {
      return true
    }

    return (
      (record.userId ?? '').toLowerCase().includes(cleanQuery) ||
      (record.query ?? '').toLowerCase().includes(cleanQuery) ||
      (record.userCaption ?? '').toLowerCase().includes(cleanQuery) ||
      (record.caption ?? '').toLowerCase().includes(cleanQuery) ||
      (record.name ?? '').toLowerCase().includes(cleanQuery) ||
      (record.category ?? '').toLowerCase().includes(cleanQuery) ||
      (record.response ?? '')
        .replace(/#/g, '')
        .toLowerCase()
        .includes(cleanQuery) ||
      record.kind.toLowerCase().includes(cleanQuery) ||
      (record.documents ?? []).some((doc) =>
        doc.name.toLowerCase().includes(cleanQuery)
      )
    )
  }

  return { filterKey, displayName: 'Search', render, filterLogic }
}

export default SearchFilter
