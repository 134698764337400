import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { CustomCellRendererProps } from 'ag-grid-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { Checkbox } from 'components/ui/checkbox'
import { fileIdSearchParamKey } from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'

const RowNumberCell = (props: CustomCellRendererProps) => {
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const { node, value } = props

  const selectedRows = useVaultDataGridFilterStore(
    useShallow((s) => s.selectedRows)
  )

  const addSelectedRow = useVaultDataGridFilterStore((s) => s.addSelectedRow)
  const removeSelectedRow = useVaultDataGridFilterStore(
    (s) => s.removeSelectedRow
  )
  const popoverData = useVaultDataGridFilterStore((s) => s.popoverData)

  const isSelected = selectedRows.includes(node.data.id)
  const shouldHighlightRow =
    node.data.id === fileId ||
    (popoverData !== null && node.data.id === popoverData?.fileId)

  const onCheckedChange = () => {
    const nextIsSelectedValue = !isSelected
    if (nextIsSelectedValue) {
      addSelectedRow(node.data.id)
    } else {
      removeSelectedRow(node.data.id)
    }
    node.setSelected(nextIsSelectedValue)
  }

  return (
    <div
      className={cn(
        'group relative flex h-full w-full items-center justify-center px-4 text-left text-xs text-muted',
        {
          'bg-muted': shouldHighlightRow,
        }
      )}
    >
      <Checkbox
        checked={isSelected}
        onCheckedChange={onCheckedChange}
        className={cn(
          'absolute inset-0 flex h-full w-full items-center justify-center',
          {
            'opacity-100': isSelected,
            'opacity-0 group-hover:opacity-100': !isSelected,
          }
        )}
      />
      <p
        className={cn(
          'pointer-events-none absolute inset-0 flex h-full w-full items-center justify-center text-xs group-hover:opacity-0',
          {
            'opacity-100': !isSelected,
            'opacity-0 group-hover:opacity-0': isSelected,
          }
        )}
      >
        {value}
      </p>
    </div>
  )
}

export default RowNumberCell
