import React, { useCallback, useState, useRef, FormEvent } from 'react'
import { useDropzone } from 'react-dropzone'

import classNames from 'classnames'
import emailAddresses from 'email-addresses'
import _ from 'lodash'
import { UploadCloud } from 'lucide-react'

import {
  displayErrorMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import { CreateExperiment } from 'components/compare/compare-fetcher'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'

const SettingsExperimentCreatorCard: React.FC = () => {
  const userInfo = useAuthUser()

  const experimentIdRef = useRef<HTMLInputElement>(null)
  const emailsRef = useRef<HTMLTextAreaElement>(null)
  const [fileList, setFileList] = useState<File[]>([])
  const [isModifying, setIsModifying] = useState(false)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFileList(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const onSubmit = async (event: FormEvent) => {
    setIsModifying(true)
    event.preventDefault()

    const experimentId = experimentIdRef.current?.value
    const emails = emailsRef.current?.value
    const csvFile = fileList[0]

    // Ensure a file is selected before calling CreateExperiment
    if (!csvFile) {
      displayErrorMessage('Please upload an experiment CSV file.')
      setIsModifying(false)
      return
    }

    // Ensure the file is a CSV file
    if (!csvFile.name.endsWith('.csv')) {
      displayErrorMessage('Invalid file format. Please upload a CSV file.')
      setIsModifying(false)
      return
    }

    // Validate emails
    const emailList = emails
      ?.split('\n')
      .map((email: string) => email.trim())
      .filter((email: string) => !_.isEmpty(email))
    const [validEmails, invalidEmails] = _.partition(
      emailList,
      (email: string) => emailAddresses.parseOneAddress(email)
    )
    if (_.isEmpty(experimentId) || experimentId === '') {
      displayErrorMessage('Please input an experiment ID')
      setIsModifying(false)
      return
    }
    if (!_.isEmpty(invalidEmails)) {
      displayWarningMessage(
        `Ignoring invalid emails: ${invalidEmails.join(', ')}`
      )
    }
    if (_.isEmpty(validEmails)) {
      displayErrorMessage('Please input valid user emails')
      setIsModifying(false)
      return
    }

    const isExperimentCreated = await CreateExperiment(
      validEmails,
      experimentId!,
      csvFile
    )
    if (isExperimentCreated) {
      displaySuccessMessage('Experiment created successfully')
      if (experimentIdRef.current) {
        experimentIdRef.current.value = ''
      }
      if (emailsRef.current) {
        emailsRef.current.value = ''
      }
      setFileList([])
    }
    setIsModifying(false)
  }

  if (_.isNil(userInfo) || !userInfo.IsResponseComparisonAdmin) return null

  return (
    <div className="w-full">
      <div
        className="rounded-lg border p-4"
        style={{ width: '100%', marginBottom: '24px' }}
      >
        <h2 className="mb-4 text-lg font-semibold">Experiment creator</h2>
        <form className="m-2 w-full">
          <label className="mb-4 block">
            Experiment ID <span className="text-destructive">*</span>
            <input
              ref={experimentIdRef}
              className="w-full rounded border p-2"
              required
            />
          </label>
          <label className="mb-4 block">
            User Emails <span className="text-destructive">*</span>
            <textarea
              ref={emailsRef}
              className="h-20 w-full rounded border p-2"
              placeholder="Enter user emails, one email per line"
              required
            />
          </label>
          <BasicTransition show={!isModifying}>
            <label className="mb-4 block">
              Experiment CSV <span className="text-destructive">*</span>
              <div
                className={classNames({
                  'relative flex h-full cursor-pointer items-center justify-center rounded-lg border border-dashed bg-neutral-50 py-8 shadow-inner transition-colors hover:bg-neutral-50':
                    true,
                  'bg-muted': isDragActive,
                  'border-4 border-primary': isDragActive,
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="flex h-full w-full flex-col items-center justify-center space-y-2 text-muted">
                  {fileList.length == 0 && <UploadCloud />}
                  {isDragActive ? (
                    <span>Drop file here</span>
                  ) : fileList.length > 0 ? (
                    <span>File uploaded: {fileList[0].name}</span>
                  ) : (
                    <div className="flex flex-col items-center">
                      <div className="mt-2 block font-semibold text-primary">
                        Click to upload or drag and drop a CSV
                      </div>
                      <div className="mt-2 block text-primary">
                        expected columns: query, response_a, response_b,
                        model_a, model_b, tax_network (optional),
                        comparison_metadata (optional)
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </label>
          </BasicTransition>
          <Button type="submit" disabled={isModifying} onClick={onSubmit}>
            Create
          </Button>
        </form>
      </div>
    </div>
  )
}

export default SettingsExperimentCreatorCard
