import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Maybe } from 'types'
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from 'types/ui-constants'

import { StatusBarProps } from 'components/common/status-bar'

interface State {
  filesUploading: string[]
  prevSidebarOpen: boolean
  isSidebarOpen: boolean
  sidebarWidth: number
  statusBar: Maybe<StatusBarProps>
  isStatusBarPreview: boolean
}

interface Action {
  setIsSidebarOpen: (isOpen: boolean) => void
  setIsSidebarOpenAndToggle: (isOpen: boolean) => void
  revertSidebarOpen: () => void
  addFolderIdToFilesUploading: (folderId: string) => void
  removeFolderIdFromFilesUploading: (folderId: string) => void
  setStatusBar: (statusBar: Maybe<StatusBarProps>) => void
  setIsStatusBarPreview: (isStatusBarPreview: boolean) => void
}

export const useGeneralStore = create(
  devtools(
    immer<State & Action>((set) => ({
      filesUploading: [],
      prevSidebarOpen: true,
      isSidebarOpen: true,
      sidebarWidth: SIDEBAR_OPEN_WIDTH,
      statusBar: null,
      isStatusBarPreview: false,
      addFolderIdToFilesUploading: (folderId: string) =>
        set((state) => {
          return {
            filesUploading: [...state.filesUploading, folderId],
          }
        }),
      removeFolderIdFromFilesUploading: (folderId: string) =>
        set((state) => {
          const filteredFilesUploading = state.filesUploading.filter(
            (id: string) => id !== folderId
          )
          return {
            filesUploading: filteredFilesUploading,
          }
        }),
      setIsSidebarOpen: (isSidebarOpen) => {
        set(() => {
          return {
            isSidebarOpen,
            sidebarWidth: isSidebarOpen
              ? SIDEBAR_OPEN_WIDTH
              : SIDEBAR_CLOSED_WIDTH,
          }
        })
      },
      setIsSidebarOpenAndToggle: (isSidebarOpen) => {
        set((state) => ({
          prevSidebarOpen: state.isSidebarOpen,
          isSidebarOpen,
          sidebarWidth: isSidebarOpen
            ? SIDEBAR_OPEN_WIDTH
            : SIDEBAR_CLOSED_WIDTH,
        }))
      },
      revertSidebarOpen: () => {
        set((state) => ({
          isSidebarOpen: state.prevSidebarOpen,
          sidebarWidth: state.prevSidebarOpen
            ? SIDEBAR_OPEN_WIDTH
            : SIDEBAR_CLOSED_WIDTH,
        }))
      },
      setStatusBar: (statusBar) => {
        set(() => {
          return {
            statusBar,
          }
        })
      },
      setIsStatusBarPreview: (isStatusBarPreview) => {
        set(() => {
          return {
            isStatusBarPreview,
          }
        })
      },
    }))
  )
)
