import React from 'react'
import { useParams } from 'react-router-dom'

import { useNavigateWithQueryParams } from 'utils/routing'

import { AssistantMode } from 'components/assistant-v2/components/assistant-mode-select'
import { AssistantChatStreamHandler } from 'components/assistant-v2/hooks/use-assistant-chat'
import { AssistantDraftStreamHandler } from 'components/assistant-v2/hooks/use-assistant-draft'
import { useAuthUser } from 'components/common/auth-context'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'

import AssistantChat from './assistant-chat-page'
import AssistantDraft from './assistant-draft-page'

type Props = {
  useChat: AssistantChatStreamHandler
  useDraft: AssistantDraftStreamHandler
}

const AssistantModeSwitcher = ({ useChat, useDraft }: Props) => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()
  const { mode } = useParams()

  if (mode === AssistantMode.ASSIST) {
    return <AssistantChat useChat={useChat} />
  }
  if (mode === AssistantMode.DRAFT) {
    if (!userInfo.IsAssistantDraftUser) {
      return (
        <ErrorPage
          title={ErrorPageTitle.NOT_AUTHORIZED}
          primaryCTA={{
            text: 'Back to Assistant',
            onClick: () => navigate('/assistant'),
          }}
        />
      )
    }
    return <AssistantDraft useDraft={useDraft} />
  }
  return null
}

export default AssistantModeSwitcher
