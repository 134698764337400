import React, { useState } from 'react'

import { Star } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import Services from 'services'

import { getRouteForEvent } from 'utils/routing'
import { useAllTaskLabelLookup } from 'utils/task-definitions'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import { SkeletonBlock } from 'components/ui/skeleton'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'

import { useLibraryMetadataStore } from './library-metadata-store'
import { LibraryItem } from './library-types'
import { getNavigateOptionsState } from './library.helpers'

interface LibraryPreviewProps {
  items: LibraryItem[]
  isLoading: boolean
  showHeaders?: boolean
}

const LibraryPreview = ({
  items,
  isLoading,
  showHeaders,
}: LibraryPreviewProps) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const [getFavoriteStatus, updateLibraryItemFavorite] =
    useLibraryMetadataStore(
      useShallow((s) => [s.getFavoriteStatus, s.updateLibraryItemFavorite])
    )
  const [, setFavoriteStatuses] = useState<{ [itemId: string]: boolean }>({})

  const handleToggleFavorite = async (
    e: React.MouseEvent,
    item: LibraryItem
  ) => {
    e.preventDefault()
    const newValue = !getFavoriteStatus(item)
    Services.HoneyComb.Record({
      metric: 'ui.library_preview_favorite',
      task_type: item.eventKind,
      item_id: item.id,
      library_item_kind: item.kind,
      starred: newValue,
      workspace_id: item.workspaceId,
      user_id: item.userId,
      visibility_scope: item.visibilityScope,
      category: item.category,
      practice_area: item.practiceArea,
      document_type: item.documentType,
    })
    trackEvent('Library Item Starred', {
      item_id: item.id,
      event_id: item.eventId,
      kind: item.kind,
      task_type: item.eventKind,
      category: item.category,
      practice_area: item.practiceArea,
      document_type: item.documentType,
      visibility_scope: item.visibilityScope,
      starred: newValue,
      entry_point: 'library preview',
    })
    await updateLibraryItemFavorite(item.id, newValue)
    setFavoriteStatuses((prevStatuses) => ({
      ...prevStatuses,
      [item.id]: newValue,
    }))
  }

  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const renderedItems = isLoading ? [...new Array(3)] : items

  return (
    <div className="divide-y">
      {showHeaders && (
        <div className="flex h-12 items-center space-x-2 px-1 py-2">
          <div className="grow text-xs font-semibold text-muted">Name</div>
          <div className="w-1/6 max-w-32 shrink-0 text-right text-xs font-semibold text-muted">
            Type
          </div>
        </div>
      )}
      {renderedItems.map((item: LibraryItem | undefined, i) => {
        if (!item && !isLoading) return null

        let rowKind: React.ReactNode = (
          <SkeletonBlock className="ml-auto h-5 w-4/5" />
        )
        let rowName: React.ReactNode = <SkeletonBlock className="h-5 w-4/5" />
        let to: string | undefined = undefined

        if (item) {
          const isStarred = getFavoriteStatus(item)
          rowKind = taskLabelLookup[item.eventKind]
          rowName = (
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex min-w-20 grow items-center">
                  <div className="line-clamp-1 text-sm">{item.name}</div>
                  <Button
                    className={cn(
                      'ml-2 shrink-0 transition-opacity hover:bg-neutral-200',
                      {
                        'opacity-0 group-hover/row:opacity-100': !isStarred,
                      }
                    )}
                    onClick={(e) => handleToggleFavorite(e, item)}
                    size="xsIcon"
                    variant="ghost"
                  >
                    <Icon
                      icon={Star}
                      className={cn({
                        'fill-gold stroke-gold': isStarred,
                        'stroke-primary': !isStarred,
                      })}
                    />
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent
                className="max-w-2xl"
                align="start"
                alignOffset={-12}
              >
                {item.query}
              </TooltipContent>
            </Tooltip>
          )
          to = getRouteForEvent(
            {
              id: item.eventId,
              kind: item.eventKind,
              libraryItemKind: item.kind,
            },
            userInfo,
            userInfo.IsAssistantV2User
              ? getNavigateOptionsState(item)
              : undefined
          )
        }

        const Comp = to ? Link : 'div'

        return (
          <Comp
            key={item?.id || i}
            className="group/row flex items-center space-x-2 rounded-none px-1 py-2 hover:bg-muted/50"
            to={
              to as string /* TS can't infer that `to` is truthy if Comp = Link */
            }
          >
            <div className="grow">{rowName}</div>
            <div className="w-1/6 max-w-32 shrink-0 truncate text-right text-sm text-muted">
              {rowKind}
            </div>
          </Comp>
        )
      })}
    </div>
  )
}

export default LibraryPreview
