import { UserInfo } from 'models/user-info'

export const assistantV2Announcement = (userInfo: UserInfo) => {
  if (userInfo.IsAssistantDraftUser) {
    return `## Try out the new Assistant
Assistant has been redesigned and now features two different modes: **Assist** and **Draft.**

Assist mode is built for conversational questions like quickly learning about something, getting a high-level overview or finding specific information within a document.

Draft mode is best for generating content like emails, contract clauses, and sections in briefs.
`
  } else {
    return `## Try out the new Assistant
Assistant has been redesigned and built for conversational questions like quickly learning about something, getting a high-level overview or finding specific information within a document.
`
  }
}
