import { DiligenceDocument } from 'openapi/models/DiligenceDocument'
import { DiligenceSection } from 'openapi/models/DiligenceSection'
import { DiligenceTask } from 'openapi/models/DiligenceTask'
import { SocketMessageResponse } from 'openapi/models/SocketMessageResponse'

import { Source } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'

import { ViewingDocumentState } from './types'
import { DiligenceTaxonomy } from './types'

export const hasSomeSectionsWithAnswers = (sections: DiligenceSection[]) => {
  return sections.some((section) =>
    section.tasks.some((task) => task.answer?.response.length)
  )
}

export const taxonomiesWithTasks = (taxonomies: DiligenceTaxonomy[]) => {
  return taxonomies.filter((taxonomy) => !!taxonomy.rows.length)
}

export const sectionsToTaxonomy = (
  data: DiligenceSection[]
): DiligenceTaxonomy[] => {
  const transformedData: DiligenceTaxonomy[] = []

  data.forEach((section) => {
    const title = section.title
    const description = section.description
    const rows: string[] = []

    section.tasks.forEach((task) => {
      rows.push(task.title)
    })

    transformedData.push({
      title,
      description,
      rows,
    })
  })

  return transformedData
}

export const taxonomyToSections = (
  taxonomyData: DiligenceTaxonomy[],
  sections: DiligenceSection[]
): DiligenceSection[] => {
  const reconstructedData: DiligenceSection[] = []

  taxonomyData.forEach((item) => {
    const sectionTitle = item.title
    const foundSection = sections.find(
      (section) => section.title === sectionTitle
    )

    if (foundSection) {
      // Deep copy the found section to avoid mutating the original 'sections' variable
      const sectionCopy: DiligenceSection = JSON.parse(
        JSON.stringify(foundSection)
      )
      sectionCopy.tasks = sectionCopy.tasks.filter((task) =>
        item.rows.includes(task.title)
      )
      reconstructedData.push(sectionCopy)
    }
  })

  return reconstructedData
}

export const findDocumentBySource = (
  documents: DiligenceDocument[],
  citation: string,
  task: DiligenceTask
): ViewingDocumentState | undefined => {
  const source = task.answer?.sources.find((s) => s.id === citation) as Source
  const document = documents.find((d) => d.file.name === source?.documentName)

  if (document && source) {
    return { document, page: source.page, source: source }
  } else {
    console.warn('Could not find document for source')
    displayErrorMessage('Could not find document for source')
    return undefined
  }
}

export const findDocumentBySourceV2 = (
  documents: DiligenceDocument[],
  citation: string,
  answer: Partial<SocketMessageResponse>
): ViewingDocumentState | undefined => {
  const source = answer?.sources?.find((s) => s.id === citation) as Source
  const document = documents.find((d) => d.file.name === source?.documentName)

  if (document && source) {
    return { document, page: source.page, source: source }
  } else {
    console.warn('Could not find document for source')
    displayErrorMessage('Could not find document for source')
    return undefined
  }
}

export const generateSectionId = (section: DiligenceSection) =>
  `section-id-${section.title}`

const roundToTwoDecimals = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const getSizeInPercentage = (
  desiredPixelHeight: number,
  containerPixelHeight: number,
  defaultPercentageHeight: number = 5
) => {
  const percentSize = roundToTwoDecimals(
    (desiredPixelHeight / containerPixelHeight) * 100
  )
  if (percentSize > 100 || percentSize < 0) {
    return defaultPercentageHeight
  } else {
    return percentSize
  }
}
