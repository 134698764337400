import React, { useEffect, useRef, useState } from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { getLibraryTeamLabel } from 'models/helpers/library-helper'
import { EventKind } from 'openapi/models/EventKind'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { ASSISTANT_SAVE_PROMPT_TOOLTIP_HELP } from 'components/assistant/assistant-constants'
import { OPEN_ENDED_QUERY_LIMIT } from 'components/assistant/assistant-utils'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import Combobox from 'components/ui/combobox/combobox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { Textarea } from 'components/ui/text-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import {
  useExampleItemsStore,
  usePromptItemsStore,
} from './library-items-store'
import { LibraryItemKind, Prompt } from './library-types'
import { getCategories, getPracticeAreas } from './library.helpers'
import { useLibraryDataProvider } from './use-library-data-provider'

interface SavePromptButtonProps {
  query: string
  queryLimit?: number
  enabledTooltipText?: string
  triggerComponent: (
    disabled: boolean | undefined,
    tooltipText: string,
    onMouseEnter: React.MouseEventHandler
  ) => React.ReactNode
  disabled?: boolean
  eventKind: EventKind
  showMoreOptions?: boolean
}

export type SavePromptParams = Pick<Prompt, 'name' | 'query'> &
  Partial<Pick<Prompt, 'visibilityScope' | 'category' | 'practiceArea'>> & {
    trackEvent: (event: string, properties: Record<string, any>) => void
  }

export const SavePromptButton: React.FC<SavePromptButtonProps> = ({
  query: startingQuery,
  queryLimit = OPEN_ENDED_QUERY_LIMIT,
  disabled,
  enabledTooltipText = ASSISTANT_SAVE_PROMPT_TOOLTIP_HELP,
  triggerComponent,
  eventKind,
}) => {
  const { trackEvent } = useAnalytics()
  const setIsLoading = usePromptItemsStore((s) => s.setIsLoading)
  const setItems = usePromptItemsStore((s) => s.setItems)
  const updateLastUpdatedTime = usePromptItemsStore(
    (s) => s.updateLastUpdatedTime
  )
  // Hack to not show the tooltip again when closing dialog
  // https://github.com/radix-ui/primitives/issues/617
  const [showTooltip, setShowTooltip] = useState(false)
  const handleMouseEnter = () => {
    setShowTooltip(true)
  }
  const [isDialogOpen, setDialogOpen] = useState(false)
  const handleDialogOpenChange = (open: boolean) => {
    setDialogOpen(open)
    setShowTooltip(false)
    if (open) {
      trackEvent('Save Prompt Dialog Opened', { entry_point: eventKind })
    }
  }

  const { fetchUpdate: fetchPromptsUpdate } = useLibraryDataProvider({
    setIsLoading,
    setItems,
    updateLastUpdatedTime,
    type: LibraryItemKind.PROMPT,
  })

  let buttonTooltipText = ''
  if (!showTooltip) {
    buttonTooltipText = ''
  } else if (!startingQuery) {
    buttonTooltipText = 'Enter a query to save as a prompt'
  } else if (disabled) {
    buttonTooltipText = 'Saving prompts is disabled'
  } else {
    buttonTooltipText = enabledTooltipText
  }

  const handleSavePrompt = async (
    params: SavePromptParams
  ): Promise<boolean> => {
    const { name, query, category, practiceArea, visibilityScope } = params

    try {
      if (!query) {
        displayErrorMessage('No query supplied')
        return false
      }
      if (!name) {
        displayErrorMessage('No name supplied')
        return false
      }
      const prompt = await Services.Backend.Post<Prompt>('library/prompt', {
        query: query.trim(),
        category: category?.trim() || undefined,
        practiceArea: practiceArea?.trim() || undefined,
        name: name.trim(),
        eventKind,
        visibilityScope,
      })
      if (!_.isEmpty(prompt)) {
        fetchPromptsUpdate()
        setDialogOpen(false)
        Services.HoneyComb.Record({
          metric: 'ui.library_prompt_saved',
          item_id: prompt.id,
          task_type: prompt.eventKind,
          starred: prompt.starred,
          workspace_id: prompt.workspaceId,
          user_id: prompt.userId,
          visibility_scope: prompt.visibilityScope,
          category: prompt.category,
          practice_area: prompt.practiceArea,
          document_type: prompt.documentType,
        })
        displaySuccessMessage('Saved prompt successfully', 1)
        return true
      }
      return false
    } catch (error) {
      displayErrorMessage('Failed to save prompt')
      return false
    }
  }

  const savePromptButtonDisabled = !startingQuery.trim().length || disabled

  return (
    <Dialog open={isDialogOpen} onOpenChange={handleDialogOpenChange}>
      <DialogTrigger disabled={savePromptButtonDisabled} asChild>
        {triggerComponent(disabled, buttonTooltipText, handleMouseEnter)}
      </DialogTrigger>
      <LibrarySavePromptDialogContent
        handleSavePrompt={handleSavePrompt}
        query={startingQuery}
        queryLimit={queryLimit}
        eventKind={eventKind}
        trackEvent={trackEvent}
      />
    </Dialog>
  )
}

type LibrarySavePromptDialogContentProps = {
  handleSavePrompt: (params: SavePromptParams) => Promise<boolean>
  query: string
  queryLimit: number
  editPrompt?: Partial<
    Pick<
      Prompt,
      'id' | 'name' | 'visibilityScope' | 'category' | 'practiceArea'
    >
  >
  eventKind: EventKind
  trackEvent: (event: string, properties: Record<string, unknown>) => void
}

export const LibrarySavePromptDialogContent: React.FC<
  LibrarySavePromptDialogContentProps
> = (props: LibrarySavePromptDialogContentProps) => {
  const { editPrompt } = props
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const [name, setName] = useState('')
  const [visibilityScope, setVisibilityScope] = useState<LibraryVisbilityScope>(
    LibraryVisbilityScope.PRIVATE
  )
  const [createdCategories, setCreatedCategories] = useState<string[]>([])
  const [createdPracticeAreas, setCreatedPracticeAreas] = useState<string[]>([])
  const [category, setCategory] = useState('')
  const [practiceArea, setPracticeArea] = useState('')
  const [query, setQuery] = useState('')

  const [prompts, isPromptsLoading] = usePromptItemsStore(
    useShallow((s) => [s.items, s.isLoading])
  )
  const [
    examples,
    isExamplesLoading,
    setIsExamplesLoading,
    setExamples,
    updateExamplesLastUpdatedTime,
  ] = useExampleItemsStore(
    useShallow((s) => [
      s.items,
      s.isLoading,
      s.setIsLoading,
      s.setItems,
      s.updateLastUpdatedTime,
    ])
  )

  useLibraryDataProvider({
    setItems: setExamples,
    setIsLoading: setIsExamplesLoading,
    type: LibraryItemKind.EXAMPLE,
    updateLastUpdatedTime: updateExamplesLastUpdatedTime,
  })

  const isLoading = isPromptsLoading || isExamplesLoading

  useEffect(() => {
    setQuery(props.query)
  }, [props.query])

  const prevEditPromptId = useRef<string>('')
  useEffect(() => {
    const editPromptId = editPrompt?.id ?? ''
    if (prevEditPromptId.current !== editPromptId) {
      setName(editPrompt?.name ?? '')
      setVisibilityScope(
        editPrompt?.visibilityScope ?? LibraryVisbilityScope.PRIVATE
      )
      setCategory(editPrompt?.category ?? '')
      setPracticeArea(editPrompt?.practiceArea ?? '')
    }
    prevEditPromptId.current = editPromptId
  }, [editPrompt])

  const resetState = () => {
    setName('')
    setQuery(props.query)
    setVisibilityScope(LibraryVisbilityScope.PRIVATE)
    setCategory('')
    setPracticeArea('')
  }

  const handleCreateCategory = (newCategory: string) => {
    setCreatedCategories(_.uniq([...createdCategories, newCategory]))
    setCategory(newCategory)
  }

  const handleCreatePracticeArea = (newPracticeArea: string) => {
    setCreatedPracticeAreas(_.uniq([...createdPracticeAreas, newPracticeArea]))
    setPracticeArea(newPracticeArea)
  }

  const categoryOptions = _.uniq([
    ...getCategories(examples),
    ...getCategories(prompts),
    ...createdCategories,
  ]).map((category) => ({
    label: category,
    value: category,
  }))

  const practiceAreaOptions = _.uniq([
    ...getPracticeAreas(examples),
    ...getPracticeAreas(prompts),
    ...createdPracticeAreas,
  ]).map((practiceArea) => ({
    label: practiceArea,
    value: practiceArea,
  }))

  const requiresTags = (
    [LibraryVisbilityScope.HARVEY, LibraryVisbilityScope.WORKSPACE] as string[]
  ).includes(visibilityScope)

  let saveDialogTooltip = ''
  if (!query.trim()) {
    saveDialogTooltip = 'Query is required'
  } else if (!name.trim()) {
    saveDialogTooltip = 'Name is required'
  } else if (requiresTags && !category.trim()) {
    saveDialogTooltip = 'Category is required'
  } else if (requiresTags && !practiceArea.trim()) {
    saveDialogTooltip = 'Practice area is required'
  } else {
    saveDialogTooltip = ''
  }
  const isSaveDialogButtonDisabled = saveDialogTooltip !== ''

  const internalSavePrompt = async () => {
    if (saveDialogTooltip) {
      displayErrorMessage(saveDialogTooltip)
      return
    }
    const result = await props.handleSavePrompt({
      name,
      visibilityScope,
      query,
      category,
      practiceArea,
      trackEvent,
    })
    trackEvent(`Library Item ${editPrompt ? 'Edited' : 'Created'}`, {
      item_type: LibraryItemKind.PROMPT,
      task_type: props.eventKind,
      practice_area: practiceArea,
      category: category,
      visibility_scope: visibilityScope,
      entry_point: props.eventKind,
    })
    if (result) {
      resetState()
    }
  }

  const hasChanges =
    query !== props.query ||
    (editPrompt
      ? name !== editPrompt.name ||
        category !== editPrompt.category ||
        practiceArea !== editPrompt.practiceArea
      : name || category || practiceArea)

  const disableContentEdit =
    editPrompt &&
    visibilityScope === LibraryVisbilityScope.HARVEY &&
    !userInfo.IsLibraryCreateHarveyItemsUser

  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <DialogContent ref={containerRef} preventOutsideClose={!!hasChanges}>
      <div className="space-y-4">
        <h2 className="text-lg font-semibold">
          {editPrompt ? 'Edit' : 'New'} prompt
        </h2>
        <div>
          <Label>Name</Label>
          <Input
            data-testid="save-prompt-name"
            className="h-8"
            placeholder="Enter a short, descriptive name"
            value={name}
            maxLength={128}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={disableContentEdit}
          />
        </div>
        <div>
          <Label>Prompt text</Label>
          <Textarea
            data-testid="save-prompt-textarea"
            className="mb-1 resize-none"
            rows={8}
            placeholder="Enter the text for the prompt"
            value={query}
            maxLength={props.queryLimit}
            onChange={(e) => setQuery(e.target.value)}
            required
            disabled={disableContentEdit}
          />
          <Tooltip>
            <TooltipTrigger asChild>
              <p
                className={cn('absolute right-6 cursor-default text-muted', {
                  'text-destructive': query.length > props.queryLimit,
                })}
              >
                {query.length} / {props.queryLimit}
              </p>
            </TooltipTrigger>
            <TooltipContent>
              {query.length > props.queryLimit
                ? 'Maximum query length exceeded'
                : 'Maximum query length'}
            </TooltipContent>
          </Tooltip>
        </div>

        {userInfo.IsLibraryAdmin && !props.editPrompt && (
          <div>
            <Label>Visibility</Label>
            <RadioGroup defaultValue="Private" className="mt-1">
              <RadioGroupItem
                value="Private"
                id="private"
                onClick={() =>
                  setVisibilityScope(LibraryVisbilityScope.PRIVATE)
                }
                label="Private"
              />
              <RadioGroupItem
                value="Workspace"
                id="workspace"
                onClick={() =>
                  setVisibilityScope(LibraryVisbilityScope.WORKSPACE)
                }
                label={getLibraryTeamLabel(userInfo)}
              />

              {userInfo.IsLibraryCreateHarveyItemsUser && (
                <RadioGroupItem
                  value="Harvey"
                  id="harvey"
                  onClick={() =>
                    setVisibilityScope(LibraryVisbilityScope.HARVEY)
                  }
                  label="Harvey"
                />
              )}
            </RadioGroup>
          </div>
        )}
        <div>
          <Label>Category</Label>
          <Combobox
            className="w-full"
            containerRef={containerRef}
            defaultText="Select or create a category"
            inputPlaceholder="Start typing to search or create"
            isLoading={isLoading}
            emptyStateText="No existing category"
            hasCreateNewCommand={userInfo.IsLibraryAdmin}
            onCreateNew={handleCreateCategory}
            maxLength={32}
            options={categoryOptions}
            setValue={setCategory}
            value={category}
          />
        </div>
        <div>
          <Label>Practice area</Label>
          <Combobox
            className="w-full"
            containerRef={containerRef}
            defaultText="Select or create a practice area"
            inputPlaceholder="Start typing to search or create"
            isLoading={isLoading}
            emptyStateText="No existing practice area"
            hasCreateNewCommand={userInfo.IsLibraryAdmin}
            onCreateNew={handleCreatePracticeArea}
            maxLength={32}
            options={practiceAreaOptions}
            setValue={setPracticeArea}
            value={practiceArea}
          />
        </div>
        {/* Action Buttons */}
        <div className="flex justify-end space-x-2 pt-6">
          <DialogClose asChild>
            <Button variant="ghost" onClick={resetState}>
              Cancel
            </Button>
          </DialogClose>
          <Button
            data-testid="save-prompt-button"
            onClick={internalSavePrompt}
            disabled={isSaveDialogButtonDisabled}
            tooltip={saveDialogTooltip}
          >
            Save
          </Button>
        </div>
      </div>
    </DialogContent>
  )
}
