import React, { useEffect, useState, useMemo } from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { getSourceClicked } from 'utils/source'
import { isAzureBlobPdf } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import CitationPopover from 'components/common/citation/citation-popover'
import Response from 'components/common/response/response'

import {
  AUS_BREACH_REPORTING_HELP,
  EURLEX_HELP,
  MEMOS_HELP,
  SEC_HELP_SHORT,
  USA_CASELAW_HELP_SHORT,
  FRANCE_CASE_LAW_HELP_SHORT,
  TAX_HELP_TEXT,
  CUATRECASAS_HELP,
  FROM_COUNSEL_HELP,
} from './constants'
import { getEventForResearchArea } from './research-helpers'
import { onDocumentSourceClick } from './research-sheet'
import { useResearchStore } from './research-store'
import { addFootnoteToUrl } from './util'

interface Props {
  area: ResearchArea
  sendCancelRequest: () => void
}

export const ResearchResponse: React.FC<Props> = ({
  area,
  sendCancelRequest,
}) => {
  const userInfo = useAuthUser()

  const [
    annotations,
    response,
    headerText,
    selectedFilters,
    isLoading,
    caption,
    queryId,
    progress,
    setActiveDocument,
  ] = useResearchStore(
    useShallow((s) => [
      s.annotations,
      s.response,
      s.headerText,
      s.selectedFilters,
      s.isLoading,
      s.caption,
      s.queryId,
      s.progress,
      s.setActiveDocument,
    ])
  )
  const [filterNamesJoined, setFilterNamesJoined] = useState('')
  const [filtered, setFiltered] = useState([] as ResearchFilter[])

  const leaves = useMemo(
    () => selectedFilters.filter((filter) => filter.children.length === 0),
    [selectedFilters]
  )

  const joinFilters = (filters: ResearchFilter[]) =>
    filters
      .filter((filter) => filter.children.length === 0)
      .map((filter) => filter.name)
      .join(', ')

  // this useEffect happens specifically for history items so that we see the filter names
  useEffect(() => {
    if (filterNamesJoined !== '') {
      return
    }

    setFiltered(leaves)
    setFilterNamesJoined(joinFilters(selectedFilters))
  }, [
    filterNamesJoined,
    leaves, // memoized this value as it was causing infinite loop of rerenders
    selectedFilters,
  ])

  // otherwise we only want to set the filter names once we are in a "loading" state or submittted to harvey
  useEffect(() => {
    if (!isLoading) {
      return
    }
    setFiltered(leaves)
    setFilterNamesJoined(joinFilters(selectedFilters))
  }, [
    isLoading,
    leaves, // memoized this value as it was causing infinite loop of rerenders
    selectedFilters,
  ])

  const generateDescription = () => {
    // Hide not very useful description since there's already a loading bar
    if (caption === 'Searching...') return ''
    if (!_.isEmpty(caption)) {
      return caption
    }
    if (area === ResearchArea.MEMOS && filtered.length > 3) {
      return `Based on research from: ${filtered.length} Industries`
    }
    if (filtered.length > 5) {
      const firstFiveFilters = filtered
        .slice(0, 5)
        .map((f) => f.name)
        .join(', ')
      const filtersLeft = filtered.length - 5
      return `Based on research from: ${firstFiveFilters} and ${filtersLeft} more filters.`
    }
    if (!_.isEmpty(filterNamesJoined)) {
      return `Based on research from: ${filterNamesJoined}`
    }
    return ''
  }

  const isLoadingResponse = !!(isLoading && headerText)
  const title = response
    ? 'Response'
    : isLoadingResponse
    ? ''
    : 'Getting started'

  const getShortHelpText = () => {
    const eventKind = getEventForResearchArea(area)
    const customHelpText = userInfo.GetHelpPanel(eventKind)?.content
    if (customHelpText) {
      return customHelpText
    }

    if (area === ResearchArea.TAX) {
      return TAX_HELP_TEXT
    } else if (area === ResearchArea.EDGAR) {
      return SEC_HELP_SHORT
    } else if (area === ResearchArea.MEMOS) {
      return MEMOS_HELP
    } else if (area === ResearchArea.EURLEX) {
      return EURLEX_HELP
    } else if (
      area === ResearchArea.USACASELAW ||
      area === ResearchArea.USCASELAW
    ) {
      return USA_CASELAW_HELP_SHORT
    } else if (area === ResearchArea.FRANCECASELAW) {
      return FRANCE_CASE_LAW_HELP_SHORT
    } else if (area === ResearchArea.AUSBREACHREPORTING) {
      return AUS_BREACH_REPORTING_HELP
    } else if (area === ResearchArea.CUATRECASAS) {
      return CUATRECASAS_HELP
    } else if (area === ResearchArea.FROMCOUNSEL) {
      return FROM_COUNSEL_HELP
    }
    return ''
  }
  const helpText = getShortHelpText()

  const getHrvyInfoMetadata = (identifier: string) => {
    const source = getSourceClicked(identifier, annotations)
    if (_.isNil(source) || _.isEmpty(source.documentUrl)) {
      return
    }

    const onHostedPdfClick = () =>
      onDocumentSourceClick(source, setActiveDocument)

    let url = source.documentUrl
    let hoverContent = (
      <CitationPopover
        source={source}
        onClick={
          isAzureBlobPdf(url) && !_.isNil(source.page)
            ? onHostedPdfClick
            : undefined
        }
      />
    )

    if (area === ResearchArea.USACASELAW || area === ResearchArea.USCASELAW) {
      url = addFootnoteToUrl({ documentUrl: url, footnote: source.footnote })
      hoverContent = (
        <CitationPopover source={{ ...source, documentUrl: url }} />
      )
    }

    if (!isAzureBlobPdf(url)) {
      return { url, hoverContent }
    }

    return {
      hoverContent,
      onClick: onHostedPdfClick,
    }
  }

  return (
    <Response
      headerText={headerText}
      handleCancel={sendCancelRequest}
      markdown={response}
      isLoading={isLoadingResponse}
      progress={progress}
      title={title}
      description={generateDescription()}
      emptyStateText={helpText}
      key={queryId}
      skeleton="short"
      disableSetExample
      getHrvyInfoMetadata={getHrvyInfoMetadata}
      hideSpinner
    />
  )
}
