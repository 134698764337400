import { HeadingLevel, convertMillimetersToTwip } from 'docx'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { WordSection } from 'utils/docx'
import { exportWordWithSections, replaceSpanWithCitation } from 'utils/markdown'
import { Source, TaskType } from 'utils/task'

import { AssistantMessage } from 'components/assistant-v2/types'

import { getMessageQuery } from './assistant-helpers'

export const handleExport = async (params: {
  eventId: string
  messages: AssistantMessage[]
  documents?: UploadedFile[]
  includeAnnotation: boolean
  taskType: TaskType
  exportTitle?: string
}) => {
  const { messages, documents, includeAnnotation, taskType, exportTitle } =
    params

  const taskPrefix = taskType === TaskType.ASSISTANT_DRAFT ? 'Draft' : 'Assist'

  await exportWordWithSections({
    filePrefixOverride: `${taskPrefix}${exportTitle ? '_' + exportTitle : ''}`,
    title: `${taskPrefix} – ${
      exportTitle || messages[0]?.caption || getMessageQuery(messages[0])
    }`,
    taskType: taskType,
    includeAnnotation,
    queryId: params.eventId,
    useRemark: true,
    sections: [
      ...(documents && documents.length > 0
        ? ([
            {
              content: `## Files\n\n${documents
                .map((doc) => `- ${doc.name}`)
                .join('\n')}`,
              type: 'markdown',
            },
            { content: `<br/>`, type: 'html' },
          ] as WordSection[])
        : []),
      ...messages.flatMap((message) => {
        if (!message) return []

        const responseWithCitation = replaceSpanWithCitation(message.response)
        const sections: WordSection[] = [
          {
            content: `## Query\n\n${getMessageQuery(message)}`,
            type: 'markdown',
          },
          { content: `<br/>`, type: 'html' },
          {
            content: `## Response\n\n${responseWithCitation}`,
            type: 'markdown',
          },
        ]
        if (includeAnnotation) {
          sections.push({
            content: message.sources as Source[],
            options: {
              heading: HeadingLevel.HEADING_3,
              spacing: {
                before: convertMillimetersToTwip(1),
                after: convertMillimetersToTwip(1),
              },
            },
            type: 'sources',
          })
        }
        sections.push({ content: `<br/>`, type: 'html' })

        return sections
      }),
    ],
  })
}
