import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import { GenerateNNResponseProps } from 'components/vault/utils/vault'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  getSortedFilesBasedOnReviewQueryOrder,
  retryReview,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import {
  getQueryUsageStringWithUnit,
  useVaultUsageStore,
} from 'components/vault/utils/vault-usage-store'

export const VaultAddFilesDialog: React.FC<{
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}> = ({ generateNNResponse }) => {
  const { projectId, queryId } = useParams()
  const isAddFilesDialogOpen = useVaultStore((s) => s.isAddFilesDialogOpen)
  const folderIdToVaultFolder = useVaultStore(
    useShallow((s) => s.folderIdToVaultFolder)
  )
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const queryIdToReviewState = useVaultStore(
    useShallow((s) => s.queryIdToReviewState)
  )
  const setIsAddFilesDialogOpen = useVaultStore(
    (s) => s.setIsAddFilesDialogOpen
  )
  const selectedRows = useVaultFileExplorerStore((s) => s.selectedRows)
  const setReviewTask = useVaultStore(useShallow((s) => s.setReviewTask))
  const markHistoryTaskAsFromStreaming = useVaultStore(
    useShallow((s) => s.markHistoryTaskAsFromStreaming)
  )

  const reviewQueryLimitUnitLevel = useVaultUsageStore(
    (s) => s.reviewQueryLimitUnitLevel
  )
  const reviewQueryUnit = useVaultUsageStore((s) => s.reviewQueryUnit)
  const reviewQueryDenominator = useVaultUsageStore(
    (s) => s.reviewQueryDenominator
  )
  const reviewQueryUsage = useVaultUsageStore((state) => state.reviewQueryUsage)
  const reviewQueryLimit = useVaultUsageStore((state) => state.reviewQueryLimit)
  const reviewQueryRemaining = reviewQueryLimit
    ? reviewQueryLimit - reviewQueryUsage
    : null

  const [isAddingToQuery, setIsAddingToQuery] = useState(false)

  const existingFileIds = useMemo(
    () => new Set(queryIdToState[queryId!]?.fileIds ?? []),
    [queryId, queryIdToState]
  )
  const selectedFiles = useMemo(() => {
    const files = getSelectedFiles(selectedRows, existingFileIds)
    return getSortedFilesBasedOnReviewQueryOrder(files, folderIdToVaultFolder)
  }, [selectedRows, folderIdToVaultFolder, existingFileIds])

  const selectedFilesUsage = useMemo(() => {
    if (!queryId) return 0
    const query = queryIdToState[queryId]
    if (!query) return 0
    if (reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.CELL) {
      return query.numQuestions * selectedFiles.length
    } else if (reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.FILE) {
      return selectedFiles.length
    }
    return 0
  }, [queryId, queryIdToState, selectedFiles.length, reviewQueryLimitUnitLevel])

  const selectedFilesUsageString =
    selectedFilesUsage > 0
      ? `Uses ${getQueryUsageStringWithUnit(
          selectedFilesUsage,
          reviewQueryUnit,
          reviewQueryDenominator
        )}`
      : ''

  const areSelectedFilesUsageExceedingLimit = reviewQueryRemaining
    ? selectedFilesUsage > reviewQueryRemaining
    : false

  const isAddFilesDisabled =
    areSelectedFilesUsageExceedingLimit ||
    selectedFiles.length === 0 ||
    isAddingToQuery

  const getAddFilesDisabledTooltip = () => {
    if (areSelectedFilesUsageExceedingLimit) {
      return 'Selected files exceed your review query limit'
    } else if (selectedFiles.length === 0) {
      return 'Please select at least one file to add'
    }
    return undefined
  }

  const onSubmitExtraFiles = async () => {
    setIsAddingToQuery(true)
    await retryReview({
      generateNNResponse,
      setReviewTask,
      markHistoryTaskAsFromStreaming,
      projectId: projectId!,
      queryId: queryId!,
      fileIds: selectedFiles.map((file) => file.id),
      queryIdToState,
      queryIdToReviewState,
      requestType: 'extra_files',
    })
    setIsAddingToQuery(false)
    setIsAddFilesDialogOpen(false)
  }

  return (
    <Dialog open={isAddFilesDialogOpen} onOpenChange={setIsAddFilesDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Files</DialogTitle>
          <DialogDescription>
            Add new files to this query for review
          </DialogDescription>
        </DialogHeader>
        <VaultFileExplorer
          isAddingFilesToQuery
          existingSelectedFileIds={existingFileIds}
          projectId={projectId}
        />
        <div className="mt-4 flex items-center justify-between">
          <p>{selectedFilesUsageString}</p>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              onClick={() => setIsAddFilesDialogOpen(false)}
              disabled={isAddingToQuery}
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmitExtraFiles}
              disabled={isAddFilesDisabled}
              tooltip={getAddFilesDisabledTooltip()}
              isLoading={isAddingToQuery}
            >
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
