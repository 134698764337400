import { EventShareCreateRequest } from 'openapi/models/EventShareCreateRequest'
import { EventShareStatusApiResponse } from 'openapi/models/EventShareStatusApiResponse'
import { EventShareUpdateRequest } from 'openapi/models/EventShareUpdateRequest'
import { SharingWorkspaceUsersApiResponseData } from 'openapi/models/SharingWorkspaceUsersApiResponseData'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

const FetchSharingWorkspaceUsers = async (
  workspaceId: number
): Promise<SharingWorkspaceUsersApiResponseData> => {
  const requestPath = `sharing/workspaces/${workspaceId}/users`
  const response =
    await Services.Backend.Get<SharingWorkspaceUsersApiResponseData>(
      requestPath,
      { throwOnError: true }
    )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const FetchEventSharingStatus = async (
  eventId: string
): Promise<EventShareStatusApiResponse> => {
  return Services.Backend.Get<EventShareStatusApiResponse>(
    `sharing/event/${eventId}/status`,
    { throwOnError: true }
  )
}

const CreateEventShares = async (
  eventId: string,
  status: EventShareCreateRequest
): Promise<EventShareStatusApiResponse> => {
  const res = await Services.Backend.Post<EventShareStatusApiResponse>(
    `sharing/event/${eventId}/share`,
    status
  )
  if (res instanceof RequestError) throw res
  return res
}

const UpdateEventSharingStatus = async (
  eventId: string,
  status: EventShareUpdateRequest
): Promise<EventShareStatusApiResponse> => {
  const res = await Services.Backend.Patch<EventShareStatusApiResponse>(
    `sharing/event/${eventId}/share`,
    status
  )
  if (res instanceof RequestError) throw res
  return res
}

export {
  FetchSharingWorkspaceUsers,
  FetchEventSharingStatus,
  CreateEventShares,
  UpdateEventSharingStatus,
}
