import React, { useMemo } from 'react'

import { GridApi } from 'ag-grid-community'
import { CustomHeaderProps } from 'ag-grid-react'
import { useShallow } from 'zustand/react/shallow'

import { Checkbox } from 'components/ui/checkbox'
import { getNumSelectedRows } from 'components/vault/utils/vault-helpers'
import { useCompetitiveAnalysisDataGridStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-data-grid-store'

const RowNumberColumnHeader = ({ gridApi }: { gridApi: GridApi }) => {
  const selectedRows = useCompetitiveAnalysisDataGridStore(
    useShallow((state) => state.selectedRows)
  )
  const bulkAddSelectedRows = useCompetitiveAnalysisDataGridStore(
    (state) => state.bulkAddSelectedRows
  )
  const bulkRemoveSelectedRows = useCompetitiveAnalysisDataGridStore(
    (state) => state.bulkRemoveSelectedRows
  )

  const numRows = useMemo(() => {
    const { numRows } = getNumSelectedRows(gridApi)
    return numRows
  }, [gridApi])

  const isHeaderChecked = selectedRows.length > 0

  const onCheckedChange = () => {
    const nextChecked = !isHeaderChecked
    const rowsToUpdate: string[] = []
    gridApi.forEachNode((node) => {
      if (node.group) return
      // if the next state is checked and the node is not displayed, we don't want to select it
      // if the next state is unchecked we want to unselect it regardless of whether it is displayed
      if (nextChecked && !node.displayed) return
      node.setSelected(nextChecked)
      rowsToUpdate.push(node.data.id)
    })
    if (nextChecked) {
      bulkAddSelectedRows(rowsToUpdate)
    } else {
      bulkRemoveSelectedRows(rowsToUpdate)
    }
  }
  return (
    <div className="flex h-full w-full cursor-default items-center justify-center border-r border-neutral-300 px-4">
      <Checkbox
        checked={isHeaderChecked}
        onCheckedChange={onCheckedChange}
        isIndeterminate={selectedRows.length < numRows}
      />
    </div>
  )
}

const CompetitiveAnalysisHeaderCell = (props: CustomHeaderProps) => {
  const colDef = props.column.getColDef()
  const isRowNumberColumn = colDef.headerName === '#'

  if (isRowNumberColumn) {
    return <RowNumberColumnHeader gridApi={props.api} />
  }

  return (
    <div className="flex w-full items-center justify-between px-4">
      <p className="truncate text-xs">{props.displayName}</p>
    </div>
  )
}

export default CompetitiveAnalysisHeaderCell
