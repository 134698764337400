import { QueryClient } from '@tanstack/react-query'

import { FetchLibraryArgs, makeLibraryUrl } from 'models/helpers/library-helper'
import { AdditionalQueryOptions } from 'models/queries/lib'
import { usePollingAndOlderQuery } from 'models/queries/lib'
import Services from 'services'

import { getOneYearPrior } from 'utils/date-utils'

import { LibraryItem, LibraryItemKind } from 'components/library/library-types'

import { HarvQueryKeyPrefix } from './all-query-keys'
import { prefetchInfiniteWrapper } from './lib/prefetch-wrapper'

export const LIBRARY_POLL_FREQUENCY_SECONDS = 600
export const LIBRARY_BATCH_SIZE = 25

const getItemId = (item: LibraryItem) => item.id

const getArrayFromResults = (results: {
  items: LibraryItem[]
  total: number
}) => results.items

interface LibraryPollingParams {
  fetchLibraryArgs: FetchLibraryArgs
  oldestDate?: Date
  batchSize?: number
  queryOptions?: AdditionalQueryOptions<{ pages: LibraryItem[][] }>
  retentionPolicyInSeconds?: number
  updateLastUpdatedTime?: () => void
}

export const useLibraryPollingQuery = ({
  fetchLibraryArgs,
  oldestDate = getOneYearPrior(),
  queryOptions,
  retentionPolicyInSeconds,
  updateLastUpdatedTime,
}: LibraryPollingParams) => {
  const url = makeLibraryUrl(fetchLibraryArgs)

  return usePollingAndOlderQuery({
    cacheKeyPrefix: HarvQueryKeyPrefix.LibraryPollingQuery,
    url: url,
    getArrayFromResults: getArrayFromResults,
    oldestDateRequested: oldestDate,
    createdKey: 'created',
    createdBeforeKey: 'created_before_exclusive',
    modifiedKey: 'updatedAt',
    modifiedAfterKey: 'updated_after_exclusive',
    deletedKey: 'deletedAt',
    idFunc: getItemId,
    queryOptions: queryOptions,
    pollTimeInSeconds: LIBRARY_POLL_FREQUENCY_SECONDS,
    batchSize: fetchLibraryArgs.pageSize,
    retentionPolicyInSeconds: retentionPolicyInSeconds ?? Infinity,
    updateLastUpdatedTime,
  })
}

export const libraryPollingQueryPrefetch = (
  queryClient: QueryClient,
  libraryType: LibraryItemKind
) => {
  const requestPath = makeLibraryUrl({
    pageSize: LIBRARY_BATCH_SIZE,
    pageNumber: 1,
    libraryType,
  })
  const queryKey = [HarvQueryKeyPrefix.LibraryPollingQuery, requestPath]
  const queryFn = async () =>
    // TODO: types
    getArrayFromResults(
      await Services.Backend.Get<any>(requestPath, {
        throwOnError: true,
      })
    )

  // The results of this query will be cached like a normal query
  // The difference is they will not refetch on each render, obey the stale time, etc. it is just a one time prefetch
  prefetchInfiniteWrapper(
    queryClient,
    {
      queryKey: queryKey,
      queryFn: queryFn,
      initialPageParam: 0,
    },
    true
  )
}
